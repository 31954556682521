import React from 'react';
import AppWrapper from 'components/apps/AppWrapper';
import BusinessDetailsForm from 'components/settings/BusinessDetailsForm';

export default function BusinessDetailsApp({ contextProps, wrappedProps }) {
  return (
    <AppWrapper {...contextProps}>
      <BusinessDetailsForm {...wrappedProps} />
    </AppWrapper>
  );
}
