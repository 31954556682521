import useForm from 'components/hooks/useForm';

export default function usePersonnelForm(initialState) {
  const [
    formPersonnel,
    setFormPersonnel,
    handlePersonnelInputChange,
    handlePersonnelOptionChange,
    ,
    handlePersonnelDateChange,
  ] = useForm(initialState);

  const handleFieldValueInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const newValue = target.value;

    setFormPersonnel({
      ...formPersonnel,
      fieldValues: {
        ...formPersonnel.fieldValues,
        [name]: {
          ...formPersonnel.fieldValues[name],
          value: newValue,
          valueType: 'text',
        },
      },
    });
  };

  const handleDateFieldChange = (name, date) => {
    setFormPersonnel({
      ...formPersonnel,
      fieldValues: {
        ...formPersonnel.fieldValues,
        [name]: {
          ...formPersonnel.fieldValues[name],
          value: date,
          valueType: 'date',
        },
      },
    });
  };

  const handleFieldOptionChange = (selected, actionMeta) => {
    const name = actionMeta.name;
    const newValue =
      actionMeta.action === 'clear' ? '' : selected.value.optionId;
    setFormPersonnel({
      ...formPersonnel,
      fieldValues: {
        ...formPersonnel.fieldValues,
        [name]: { ...formPersonnel.fieldValues[name], fieldOptionId: newValue },
      },
    });
  };

  const handleToggle = (name) => {
    setFormPersonnel((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleProfilePhotoRemoval = () =>
    setFormPersonnel({
      ...formPersonnel,
      profilePhoto: null,
    });

  const handleProfilePhotoMediaChange = ({ naturalHeight, naturalWidth }) => {
    setFormPersonnel({
      ...formPersonnel,
      profilePhoto: {
        ...formPersonnel.profilePhoto,
        mediaWidth: naturalWidth,
        mediaHeight: naturalHeight,
      },
    });
  };

  const handleNewProfilePhotoUploaded = (profilePhotoData) => {
    setFormPersonnel((prevState) => {
      return {
        ...prevState,
        profilePhoto: {
          id: profilePhotoData.id,
          ...profilePhotoData.attributes,
        },
      };
    });
  };

  // updates all values needed for the profile photo preview
  const handleProfilePhotoCropReady = ({
    croppedAreaPercentage,
    croppedAreaPixels,
    rotation,
  }) => {
    const dataToUpdate = {};

    if (
      croppedAreaPercentage &&
      (croppedAreaPercentage.x ||
        croppedAreaPercentage.x === 0 ||
        croppedAreaPercentage.y ||
        croppedAreaPercentage.y === 0)
    ) {
      dataToUpdate.croppedAreaPercentageX = croppedAreaPercentage.x;
      dataToUpdate.croppedAreaPercentageY = croppedAreaPercentage.y;
      dataToUpdate.croppedAreaPercentageWidth = croppedAreaPercentage.width;
      dataToUpdate.croppedAreaPercentageHeight = croppedAreaPercentage.height;
    }

    if (
      croppedAreaPixels &&
      (croppedAreaPixels.x ||
        croppedAreaPixels.x === 0 ||
        croppedAreaPixels.y ||
        croppedAreaPixels.y === 0)
    ) {
      dataToUpdate.cropX = croppedAreaPixels.x;
      dataToUpdate.cropY = croppedAreaPixels.y;
      dataToUpdate.cropDimension = croppedAreaPixels.width;
    }

    setFormPersonnel({
      ...formPersonnel,
      profilePhoto: {
        ...formPersonnel.profilePhoto,
        ...dataToUpdate,
        rotation,
      },
    });
  };

  return [
    formPersonnel,
    setFormPersonnel,
    handlePersonnelInputChange,
    handlePersonnelOptionChange,
    handlePersonnelDateChange,
    handleFieldValueInputChange,
    handleDateFieldChange,
    handleFieldOptionChange,
    handleToggle,
    handleProfilePhotoRemoval,
    handleProfilePhotoMediaChange,
    handleNewProfilePhotoUploaded,
    handleProfilePhotoCropReady,
  ];
}
