import React from 'react';
import PropTypes from 'prop-types';
import useEscapeKey from 'components/hooks/useEscapeKey';
import useBodyOverflowToggle from 'components/hooks/useBodyOverflowToggle';
import TextButton from 'components/application/buttons/TextButton';
import classNames from 'classnames';

export default function DownloadModal(props) {
  const { actionButton, body, bodyIcon, header, isFailed, isOpen, onClose } =
    props;

  const openCloseClassName = isOpen ? 'modalbox--open' : 'modalbox--close';

  const handleWrapperClick = (event) => {
    if (event.target.classList.contains('popup__backdrop')) {
      onClose();
    }
  };

  useBodyOverflowToggle(isOpen);
  useEscapeKey(onClose, [isOpen]);

  const closeButton = (
    <TextButton className='m-r-12' color='blue' onClick={onClose} size='md'>
      Cancel
    </TextButton>
  );

  return (
    <div
      className={classNames(
        'download_modal modal_report-retrieval',
        openCloseClassName,
      )}
    >
      <div className='modalbox__scroller'>
        <div
          className='popup__backdrop tw-bg-grey-900 tw-opacity-75'
          onClick={handleWrapperClick}
        ></div>
        <div className='modalbox__box-wrapper'>
          <div className='download_modal__content tw-border-grey-100 tw-bg-white'>
            <div className='download_modal__header tw-text-l tw-font-semibold tw-text-grey-900'>
              {header}
            </div>
            <div
              className={classNames(
                'download_modal__body tw-border-t-grey-100',
                isFailed && 'tw-text-red-600',
              )}
            >
              {bodyIcon}
              {body}
            </div>
            <div className='download_modal__footer tw-border-t-grey-100'>
              {closeButton}
              {actionButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DownloadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
