import React from 'react';
import PropTypes from 'prop-types';
import RequestErrorMessage from 'components/application/RequestErrorMessage';
import classNames from 'classnames';

export default function ErrorMessage({
  isFallback = false,
  modifiers = '',
  validationErrors,
  withSeparateNestedErrors = false,
  wrapperClassName,
}) {
  return (
    (isFallback || Object.keys(validationErrors).length > 0) && (
      <div className={wrapperClassName}>
        <div
          className={classNames(
            'tw-rounded-lg tw-border-0 tw-bg-red-025 tw-p-3 tw-text-red-800',
            modifiers,
          )}
        >
          <RequestErrorMessage
            isFallback={isFallback}
            validationErrors={validationErrors}
            withSeparateNestedErrors={withSeparateNestedErrors}
          />
        </div>
      </div>
    )
  );
}

ErrorMessage.propTypes = {
  wrapperClassName: PropTypes.string,
  modifiers: PropTypes.string,
  isFallback: PropTypes.bool,
  validationErrors: PropTypes.object,
  withSeparateNestedErrors: PropTypes.bool,
};
