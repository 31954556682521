import UrlHelper from './url-helper';

export default (function () {
  function switchTabFromUrl() {
    goToTab(UrlHelper.queryStringParams()['switch_tab']);
  }

  function goToTab(tabTarget) {
    $('a[href="#' + tabTarget + '"]').tab('show');
  }

  $(function () {
    if ($('.auto-tab-switch')) {
      switchTabFromUrl();
    }
  });

  return {
    goToTab: goToTab,
    switchTabFromUrl: switchTabFromUrl,
  };
})();
