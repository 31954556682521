import React from 'react';
import RowBar from 'components/application/RowBar';
import SearchField from 'components/application/SearchField';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import AddIcon from '-!svg-react-loader?name=AddIcon!icons/add.svg';

export default function SubcontractorsBar(props) {
  const {
    onNewSubcontractor,
    onSearchInputChange,
    onSearchReset,
    subcontractorsCount,
    subcontractorsSearch,
  } = props;

  return (
    <RowBar
      actions={
        props.actionButtonsVisible && (
          <OutlinedButton color='grey' onClick={onNewSubcontractor} size='sm'>
            <AddIcon className='m-l--8' height={24} width={24} />
            <span>Add new sub-contractor</span>
          </OutlinedButton>
        )
      }
      content={
        subcontractorsCount > 0 && (
          <SearchField
            additionalClasses='search-field__lg'
            name='subcontractorsSearch'
            onChange={onSearchInputChange}
            onReset={onSearchReset}
            placeholder='Search your sub-contractors...'
            value={subcontractorsSearch}
          />
        )
      }
      modifiers={[
        'border-top-curved',
        'border-bottom-none',
        'flex-align-items-ie-fix',
      ]}
    />
  );
}
