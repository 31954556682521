import voca from 'voca';

function humanize(word) {
  return word.replace(/_/g, ' ');
}

function humanizeCamel(word) {
  const camelMatch = /([A-Z])/g;
  return word.replace(camelMatch, ' $1').toLowerCase();
}

function titleize(string) {
  return voca.titleCase(voca.kebabCase(string).replace(/-/g, ' '));
}

function escapeDoubleQuotes(word) {
  return word.replace(/&ldquo;|&rdquo;/g, '"');
}

export { humanize, humanizeCamel, titleize, escapeDoubleQuotes };
