import React from 'react';

import TrashIcon from '-!svg-react-loader?name=TrashIcon!icons/ic-trash.svg';
import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';
import OutlinedButton from 'components/application/buttons/OutlinedButton';

export default function CoshhRow({
  addedToProject,
  masterCoshhDocument,
  onAdd,
  onDelete,
  onEdit,
}) {
  return (
    <>
      <tr className='handshq__tr--taller'>
        <td className='tw-border-grey-100'>
          {addedToProject && (
            <div className='fh-32 flex flex--vertically-centered'>
              <div className='svg-circle__icon tw-bg-blue-400'>
                <TickIcon
                  className='[&_polygon]:tw-fill-white'
                  height={20}
                  width={20}
                />
              </div>
            </div>
          )}
        </td>
        <td className='py-4 tw-border-grey-100 tw-text-grey-900'>
          <div className='fh-40 flex flex--vertically-centered'>
            <span className='truncated-text-container--wrapped truncated-text-container--two-lines'>
              {masterCoshhDocument.attributes.title}
            </span>
          </div>
        </td>
        <td className='py-4 tw-border-grey-100'>
          <div className='fh-40 flex flex--vertically-centered'>
            <span className='truncated-text-container--wrapped truncated-text-container--two-lines'>
              {masterCoshhDocument.attributes.manufacturer}
            </span>
          </div>
        </td>
        <td className='tw-border-grey-100'>
          <div className='fh-32 flex flex--vertically-centered'>
            {masterCoshhDocument.attributes.reference}
          </div>
        </td>
        <td className='handshq__td-button-cell--align-right tw-border-grey-100'>
          <div className='flex flex--vertically-centered flex--justify-content__flex-end'>
            {addedToProject ?
              <div className='flex fh-32 fw-122'>
                <OutlinedButton
                  className='m-r-8 tw-h-full tw-w-full tw-justify-center'
                  color='grey'
                  onClick={() => onEdit(masterCoshhDocument)}
                >
                  Edit
                </OutlinedButton>
                <button
                  className='tw-inline-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-lg tw-border-0 tw-bg-transparent tw-p-1 hover:tw-bg-red-025 active:tw-bg-red-050 [&_svg_path]:tw-fill-grey-500 [&_svg_path]:hover:tw-fill-red-600 [&_svg_path]:active:tw-fill-red-600'
                  onClick={() => onDelete(masterCoshhDocument)}
                >
                  <TrashIcon height={24} width={24} />
                </button>
              </div>
            : <div className='fh-32 fw-122'>
                <OutlinedButton
                  className='tw-h-full tw-w-full tw-justify-center'
                  color='grey'
                  onClick={() => onAdd(masterCoshhDocument)}
                >
                  Add COSHH
                </OutlinedButton>
              </div>
            }
          </div>
        </td>
      </tr>
    </>
  );
}
