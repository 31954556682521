import { useState } from 'react';

export default function useDownloadPolling(download) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [downloadState, setDownloadState] = useState({
    downloadInProgress: false,
    downloadSucceeded: false,
    downloadFailed: false,
    downloadLink: '',
  });

  const onClick = () => {
    startDownload();
    setModalIsOpen(true);
  };

  const startDownload = () => {
    setDownloadState({
      ...downloadState,
      downloadInProgress: true,
      downloadFailed: false,
    });
    download.start();
  };

  const resetAction = () => {
    setDownloadState({
      downloadFailed: false,
      downloadSucceeded: false,
      downloadLink: '',
      downloadInProgress: false,
    });
  };

  const closeModal = () => {
    setModalIsOpen(false);
    download.end();
  };

  return [
    onClick,
    startDownload,
    resetAction,
    closeModal,
    modalIsOpen,
    downloadState,
    setDownloadState,
  ];
}
