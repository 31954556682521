import { resourceAsParams } from 'components/helpers/resources/shared';

export function editableBrandingAsValues(editableBranding) {
  return {
    handshqLabel: editableBranding.handshqLabel,
    primaryBgColour: editableBranding.primaryBgColour.value,
    buttonBgColour: editableBranding.buttonBgColour.value,
    buttonTextColour: editableBranding.buttonTextColour.value,
    textLinkColour: editableBranding.textLinkColour.value,
  };
}

export function persistBranding(
  brandingSaveStrategy,
  editableBranding,
  logoOptions = { state: 'unaltered', data: null },
) {
  if (!['create', 'update'].includes(brandingSaveStrategy))
    throw new Error('Branding state not supported for persistance');
  const httpMethod = brandingSaveStrategy === 'create' ? 'post' : 'patch';

  return axios[httpMethod](
    '/branding',
    brandingAsFormData(editableBranding, logoOptions),
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );

  function brandingAsFormData() {
    const formData = new FormData();
    appendBrandingFormValues();
    appendLogoValues();
    return formData;

    function appendBrandingFormValues() {
      Object.entries(
        resourceAsParams('branding', editableBrandingAsValues(editableBranding))
          .branding,
      ).forEach((entry) => {
        const [key, value] = entry;
        formData.append(`branding[${key}]`, value);
      });
    }

    function appendLogoValues() {
      switch (logoOptions.state) {
        case 'removed':
          formData.append('branding[logo_attributes[_destroy]]', 1);
          break;
        case 'new':
        case 'edited':
          formData.append(
            'branding[logo_attributes[attached]]',
            logoOptions.data,
          );
          break;
      }
    }
  }
}

export function getLogoState(domainLogo, editableLogo) {
  const isLogoNew = () => !!(!domainLogo && editableLogo.src);
  const isLogoEdited = () =>
    !!(
      domainLogo &&
      editableLogo.src &&
      domainLogo.attributes.attachedUrl !== editableLogo.src
    );
  const isLogoBeingRemoved = () => editableLogo.markedForDeletion;

  let logoState;
  switch (true) {
    case isLogoNew():
      logoState = 'new';
      break;
    case isLogoEdited():
      logoState = 'edited';
      break;
    case isLogoBeingRemoved():
      logoState = 'removed';
      break;
    default:
      logoState = 'unaltered';
      break;
  }
  return logoState;
}
