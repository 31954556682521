import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import useForm from 'components/hooks/useForm';
import useToggle from 'components/hooks/useToggle';
import useDebounce from 'components/hooks/useDebounce';
import useRequestError from 'components/hooks/useRequestError';
import useModal from 'components/hooks/useModal';
import useSidePanel from 'components/hooks/useSidePanel';
import usePersonnelForm from 'components/hooks/usePersonnelForm';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { useBreadBoard } from 'components/contexts/Toaster';
import useTrackedPersonnel from 'components/hooks/useTrackedPersonnel';
import useWindowStorage from 'components/hooks/useWindowStorage';

import ArchiveModal from 'components/archive/ArchiveModal';
import UnarchiveBlockedModal from 'components/archive/UnarchiveBlockedModal';
import UndoableToast from 'components/application/UndoableToast';
import PersonnelBar from 'components/personnel/PersonnelBar';
import PersonnelTable from 'components/personnel/PersonnelTable';
import PersonnelSidePanel from 'components/personnel/PersonnelSidePanel';
import AddPersonnelSection from 'components/personnel/AddPersonnelSection';
import BlankPersonnelSearchResults from 'components/personnel/BlankPersonnelSearchResults';
import BlankPersonnelSetSection from 'components/personnel/BlankPersonnelSetSection';
import ResourceChangedToast from 'components/application/ResourceChangedToast';
import SuccessToast from 'components/application/SuccessToast';
import Paginator from 'components/application/Paginator';
import DestroyModal from 'components/application/DestroyModal';
import PersonnelFilter from 'components/personnel/PersonnelFilter';
import PersonnelRow from 'components/personnel/PersonnelRow';
import TrackedPersonnelUsageBanner from 'components/personnel/TrackedPersonnelUsageBanner';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import ProfilePhotoModal from 'components/personnel/ProfilePhotoModal';
import Upload from 'components/helpers/Upload';

import { personDisplayName } from 'components/helpers/users';
import {
  personnelParams,
  sortRolesByPrimaryAndPosition,
  sortRoleIdsByPosition,
  haveProfilePhotoAttributesChanged,
} from 'components/helpers/personnel';
import { sendAnalytics } from 'components/helpers/analytics';

import { z } from 'zod';
import useValidatedStore from 'components/hooks/useValidatedStore';
import setupPolling from 'utilities/setupPolling';

const initialTabStore = {
  currentSearch: '',
  page: 1,
  teamFilter: { value: 'all_personnel', label: 'All personnel' },
  archivedPersonnelToggle: false,
};

const tabSchema = z.object({
  page: z.number().or(z.null()),
  teamFilter: z.object({
    value: z.string(),
    label: z.string(),
  }),
  archivedPersonnelToggle: z.boolean(),
  currentSearch: z.string(),
});

const initialSubcontratorState = { subcontractorName: '' };
const initialStatusCounts = {
  statusGreyCount: 0,
  statusLowCount: 0,
  statusMediumCount: 0,
  statusHighCount: 0,
};
const initialMetaDataState = {
  currentPage: null,
  totalPages: null,
  scopedCount: 0,
  totalCount: 0,
  unscopedCount: 0,
  trainingStatusCounts: initialStatusCounts,
};
const initialPersonnelState = {
  loaded: false,
  collection: [],
  includedRoles: [],
  includedTeams: [],
  includedUserCompanyRoles: [],
  includedLineManagers: [],
  includedFieldValues: [],
  includedUsers: [],
  includedProfilePhotos: [],
  metaData: initialMetaDataState,
};

export default function PersonnelTab(props) {
  const currentActor = useCurrentActor();
  const breadBoard = useBreadBoard();
  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();

  const initialCurrentPersonnelState = {
    id: '',
    firstName: '',
    lastName: '',
    externalId: '',
    email: '',
    lineManagerId: '',
    company: {
      companyId: currentActor.division.id,
      subcontractorId: '',
    },
    primaryCompanyRoleId: '',
    companyRoleIds: [],
    fieldValues: {},
    isArchived: false,
    isParticipatingInOngoingProjects: false,
    eLearningBookingsCount: 0,
    trackableBlockers: [],
    trackableQualities: [],
    isEditable: false,
    profileAccessEnabled: false,
  };

  const defaultPersonnelTypeFilters =
    currentActor.isAllowedFeature('training_register_only') ?
      { employeeFilter: true, subFilter: true }
    : { employeeFilter: true, activeSubFilter: true, inActiveSubFilter: true };
  const locallyPersistedPersonnelTypeFilters = JSON.parse(
    window.sessionStorage.getItem('personnelTypeFilters') || null,
  );
  const initialPersonnelTypeFilters =
    locallyPersistedPersonnelTypeFilters || defaultPersonnelTypeFilters;

  const defaultTrainingStatusFilters = {
    highTrainingFilter: true,
    mediumTrainingFilter: true,
    lowTrainingFilter: true,
    greyTrainingFilter: true,
  };

  // local storage
  const [getStore, setStore] = useWindowStorage(
    `trainingRegister|${props.label}`,
    { store: window.sessionStorage },
  );
  const [_getPersonnelId, setPersonnelId] = useWindowStorage(
    'personnelTab|personnelId',
    { store: window.sessionStorage },
  );
  const tabStore = useValidatedStore({
    getStore,
    initialStore: initialTabStore,
    schema: tabSchema,
  });

  const initialArchivedPersonnelToggle = tabStore.archivedPersonnelToggle;
  const initialCurrentSearch = {
    personnelSearch: tabStore.currentSearch || props.emailFilter || '',
  };
  const initialTeamFilter = tabStore.teamFilter;

  const locallyPersistedTrainingStatusFilters = JSON.parse(
    window.sessionStorage.getItem('trainingStatusFilters') || null,
  );
  const initialTrainingFilterProvided =
    Object.values(props.initialTrainingFilters).filter(Boolean).length > 0;
  const initialTrainingStatusFilters =
    initialTrainingFilterProvided ?
      props.initialTrainingFilters
    : locallyPersistedTrainingStatusFilters || defaultTrainingStatusFilters;

  // state
  const [isSubcontractor, setIsSubcontractor] = useState(false);
  const [personnel, setPersonnel] = useState(initialPersonnelState);
  const [
    requestError,
    submitDisabled,
    removeErrorStyling,
    resetRequestError,
    handleRequestError,
  ] = useRequestError();
  const [assignableCompanies, setAssignableCompanies] = useState(
    initialPersonnelState,
  );
  const [assignableRoles, setAssignableRoles] = useState({
    loaded: false,
    collection: [],
  });
  const [availableFields, setAvailableFields] = useState({
    loaded: false,
    collection: [],
  });
  const [availableFieldOptions, setAvailableFieldOptions] = useState({
    loaded: false,
    collection: [],
  });
  const [
    sidePanelIsOpen,
    setSidePanelIsOpen,
    openSidePanel,
    closeSidePanel,
    resetSidePanelContext,
    sidePanelContext,
    setSidePanelContext,
  ] = useSidePanel(false, 'new');
  const [
    personnelTypeFilters,
    setPersonnelTypeFilters,
    handlePersonnelTypeFilterChange,
  ] = useForm(initialPersonnelTypeFilters);
  const [
    trainingStatusFilters,
    setTrainingStatusFilters,
    handleTrainingStatusFilterChange,
  ] = useForm(initialTrainingStatusFilters);
  const [teamFilter, setTeamFilter] = useState(initialTeamFilter);
  const [currentSearch, setCurrentSearch, handleSearchInputChange] =
    useForm(initialCurrentSearch);
  const [debouncedCurrentSearch] = useDebounce(currentSearch, 250);
  const [currentSubcontractor, setCurrentSubcontractor] = useForm(
    initialSubcontratorState,
  );
  const [
    currentPersonnel,
    setCurrentPersonnel,
    handlePersonnelInputChange,
    handlePersonnelOptionChange,
    handlePersonnelDateChange,
    handleFieldValueInputChange,
    handleDateFieldChange,
    handleFieldOptionChange,
    handleToggle,
    handleProfilePhotoRemoval,
    handleProfilePhotoMediaChange,
    handleNewProfilePhotoUploaded,
    handleProfilePhotoCropReady,
  ] = usePersonnelForm(initialCurrentPersonnelState);
  const [archivedPersonnel, setArchivedPersonnel] = useState(
    initialPersonnelState,
  );
  const [archivedPersonnelToggle, toggleArchivedPersonnelVisibility] =
    useToggle(initialArchivedPersonnelToggle);
  const [destroyModalIsOpen, setDestroyModalIsOpen] = useState(false);
  const [isProfilePhotoModalOpen, setIsProfilePhotoModalOpen] = useState(false);
  const [uploadedDomainProfilePhotos, setUploadedDomainProfilePhotos] =
    useState(null);
  const [isProfilePhotoUploading, setIsProfilePhotoUploading] = useState(false);

  const [
    personnelIdsOfProfilePhotoUnderProgress,
    setPersonnelIdsOfProfilePhotoUnderProgress,
  ] = useState(new Set());
  const photoUploadLoading = useRef(false);

  const [archiveModalIsOpen, , openArchiveModal, closeArchiveModal] =
    useModal(false);
  const [
    unarchiveBlockedModalIsOpen,
    ,
    openUnarchiveBlockedModal,
    closeUnarchiveBlockedModal,
  ] = useModal(false);
  const [newCompanyRoles, setNewCompanyRoles] = useForm([]);

  const currentPersonnelIdRef = useRef(null);

  // calculated data
  const viewableCollection =
    archivedPersonnelToggle ? archivedPersonnel : personnel;
  const viewableMetaData =
    archivedPersonnelToggle ?
      {
        ...archivedPersonnel.metaData,
        trainingStatusCounts: initialStatusCounts,
      }
    : personnel.metaData;
  const totalPersonnelCount = personnel.metaData.unscopedCount;
  const areAllFiltersApplied = [
    personnelTypeFilters,
    trainingStatusFilters,
  ].every((filterCollection) => {
    return Object.entries(filterCollection).every(
      ([_filterName, filterValue]) => filterValue,
    );
  });
  const [
    trackedPersonnelUsage,
    refreshTrackedPersonnelUsage,
    hasReachedTrackedPersonnelUsageThreshold,
    hasReachedTrackedPersonnelLimit,
  ] = useTrackedPersonnel();

  const mappedUserCompanyRoles =
    viewableCollection.includedUserCompanyRoles.reduce((acc, ucr) => {
      acc[ucr.id] = ucr;
      return acc;
    }, {});

  const mappedCompanyRoles = viewableCollection.includedRoles.reduce(
    (acc, role) => {
      acc[role.id] = role;
      return acc;
    },
    {},
  );

  const mappedTeams = viewableCollection.includedTeams.reduce((acc, team) => {
    acc[team.id] = team;
    return acc;
  }, {});

  const currentPersonnelRoles =
    currentPersonnel.id ?
      currentPersonnel.companyRoleIds
        .map((id) => mappedCompanyRoles[id])
        .sort((a, b) => {
          if (a.id == currentPersonnel.primaryCompanyRoleId) return -1;
          if (b.id == currentPersonnel.primaryCompanyRoleId) return 1;
          return a.attributes.position > b.attributes.position ? 1 : -1;
        })
    : [];

  const isActionsColumnVisible =
    (archivedPersonnelToggle &&
      trainingRegisterResourceManagementContext.hasPersonnelEditableAccess) ||
    (currentActor.isAllowedFeature('training_register') &&
      trainingRegisterResourceManagementContext.hasTrainingWriteAccess);
  const selectedPersonnel = viewableCollection.collection.find(
    (personnel) => personnel.id === currentPersonnel.id,
  );
  const selectedProfilePhotoFromRelationship =
    personnel.includedProfilePhotos.find(
      (photo) =>
        photo.id === selectedPersonnel?.relationships?.profilePhoto?.data?.id,
    );

  const domainProfilePhoto = personnel.includedProfilePhotos.find(
    (photo) => photo.id === currentPersonnel.profilePhoto?.id,
  );

  const uploadedProfilePhoto =
    !!uploadedDomainProfilePhotos && !!currentPersonnel?.profilePhoto?.id ?
      uploadedDomainProfilePhotos[currentPersonnel?.profilePhoto?.id]
    : null;
  const selectedPersonnelPhoto = uploadedProfilePhoto || domainProfilePhoto;

  // action handlers
  const createPersonnel = () => {
    const params = personnelParams({
      currentPersonnel: currentPersonnel,
      currentSubcontractor: currentSubcontractor,
      isSubcontractor: isSubcontractor,
      newCompanyRoles: newCompanyRoles,
      profilePhotos: {
        domain: selectedPersonnelPhoto,
        existingFromRelationship: selectedProfilePhotoFromRelationship,
      },
    });

    axios
      .post('/personnel', params)
      .then((response) => {
        closeSidePanel();

        const resourceLinkPath =
          currentActor.isAllowedFeature('training_register') ?
            `/personnel/${response.data.data.id}`
          : null;
        const resourceLinkLabel =
          currentActor.isAllowedFeature('training_register') ?
            `View profile for ${response.data.data.attributes.firstName}`
          : null;

        breadBoard.addToast(
          <SuccessToast
            additionalContent={
              resourceLinkPath && resourceLinkLabel ?
                <div className='p-l-32 p-t-12'>
                  <OutlinedButton
                    color='grey'
                    href={resourceLinkPath}
                    size='sm'
                  >
                    {resourceLinkLabel}
                  </OutlinedButton>
                </div>
              : undefined
            }
            message={
              <>
                <span className='tw-font-medium'>
                  {response.data.data.attributes.firstName}{' '}
                  {response.data.data.attributes.lastName}
                </span>{' '}
                was added
                {response.data.meta.userInvited ?
                  ' and sent an invitation email'
                : undefined}
              </>
            }
            onBurnToast={breadBoard.handleBurnToast}
          />,
        );
        fetchPersonnel();
        fetchAssignableCompanies();
        fetchAssignableCompanyRoles();
        setCurrentPersonnel(initialCurrentPersonnelState);
        resetSubcontractor();
        setNewCompanyRoles([]);
        resetRequestError();
      })
      .catch(handleRequestError);
  };

  const handlePersonnelUpdateSubmit = () => {
    addPersonnelToPersonnelIdsOfProfilePhotoUnderProgress(currentPersonnel.id);

    const originalPersonnel = personnel.collection.find(
      (p) => p.id === currentPersonnel.id,
    );
    const originalUserCompanyRoles =
      originalPersonnel.relationships.userCompanyRoles.data.map(
        (ucr) => mappedUserCompanyRoles[ucr.id],
      );
    const originalCompanyRoleIds =
      originalPersonnel.relationships.companyRoles.data.map((role) => role.id);

    const params = personnelParams({
      currentPersonnel: currentPersonnel,
      fieldValues: currentPersonnel.fieldValues,
      currentSubcontractor: currentSubcontractor,
      isSubcontractor: isSubcontractor,
      newCompanyRoles: newCompanyRoles,
      personnelCollection: personnel,
      originalUserCompanyRoles,
      originalCompanyRoleIds,
      profilePhotos: {
        domain: selectedPersonnelPhoto,
        existingFromRelationship: selectedProfilePhotoFromRelationship,
      },
    });

    axios
      .patch(`/personnel/${currentPersonnel.id}`, params)
      .then((response) => {
        closeSidePanel();
        breadBoard.addToast(
          <SuccessToast
            message={
              <>
                <span className='tw-font-medium'>
                  {response.data.data.attributes.firstName}{' '}
                  {response.data.data.attributes.lastName}
                </span>{' '}
                was edited
                {response.data.meta.userInvited ?
                  ' and sent an invitation email'
                : undefined}
              </>
            }
            onBurnToast={breadBoard.handleBurnToast}
          />,
        );
        fetchPersonnel(personnel.metaData.currentPage);
        fetchAssignableCompanies();
        fetchAssignableCompanyRoles();
        setCurrentPersonnel(initialCurrentPersonnelState);
        resetSubcontractor();
        setNewCompanyRoles([]);
        resetRequestError();

        // there is a current profile photo and there are changes to it
        if (
          !!currentPersonnel.profilePhoto &&
          haveProfilePhotoAttributesChanged(
            selectedPersonnelPhoto,
            currentPersonnel.profilePhoto,
          )
        ) {
          // poll for processed photo
          setupPolling({
            requestFn: () =>
              axios.get(
                `/profile_photos/${currentPersonnel.profilePhoto.hashid}`,
              ),
            testFn: (response) => response.data.data.attributes.processed,
            intervalTime: 1000,
          })
            .startPolling()
            .then((response) => {
              // only update if current personnel is still the same
              if (
                currentPersonnelIdRef.current ===
                response.data.data.relationships.personnel.data.id
              ) {
                handleNewProfilePhotoUploaded(response.data.data);
              }

              removePersonnelFromPersonnelIdsOfProfilePhotoUnderProgress(
                currentPersonnel.id,
              );
            })
            .catch(() => {
              removePersonnelFromPersonnelIdsOfProfilePhotoUnderProgress(
                currentPersonnel.id,
              );
            });
        } else {
          removePersonnelFromPersonnelIdsOfProfilePhotoUnderProgress(
            currentPersonnel.id,
          );
        }
      })
      .catch(handleRequestError);
  };

  const destroyPersonnel = () => {
    axios
      .delete(`/personnel/${currentPersonnel.id}`)
      .then((_response) => {
        closeSidePanel();
        breadBoard.addToast(
          <ResourceChangedToast
            onBurnToast={breadBoard.handleBurnToast}
            resource={`${currentPersonnel.firstName} ${currentPersonnel.lastName}`}
            status={'deleted'}
          />,
        );
        const isLastResource =
          personnel.collection.length === 1 &&
          personnel.metaData.currentPage === personnel.metaData.totalPages &&
          personnel.metaData.totalPages > 0;
        fetchPersonnel(
          isLastResource ?
            personnel.metaData.currentPage - 1
          : personnel.metaData.currentPage,
        );
        setCurrentPersonnel(initialCurrentPersonnelState);
        resetSubcontractor();
      })
      .catch((_error) => {
        closeSidePanel();
        setCurrentPersonnel(initialCurrentPersonnelState);
        resetSubcontractor();
      });
  };

  const fetchPersonnel = function (
    page = 1,
    fetchOptions = { archived: false },
  ) {
    resetSidePanelContext();

    const filterOptions = {
      archived: fetchOptions.archived,
      search: currentSearch.personnelSearch,
      page: page,
    };

    filterOptions.incl_employees = personnelTypeFilters.employeeFilter;
    if (currentActor.isAllowedFeature('training_register_only')) {
      filterOptions.incl_sub = personnelTypeFilters.subFilter;
    } else {
      filterOptions.incl_active_sub = personnelTypeFilters.activeSubFilter;
      filterOptions.incl_inactive_sub = personnelTypeFilters.inActiveSubFilter;
    }

    filterOptions.with_tracked_status =
      currentActor.isAllowedFeature('training_register');

    const statusFiltersAreAltered = Object.values(trainingStatusFilters).some(
      (filter) => filter !== true,
    );

    if (statusFiltersAreAltered) {
      filterOptions.statuses = [];
      if (trainingStatusFilters.highTrainingFilter) {
        filterOptions.statuses.push('high');
      }
      if (trainingStatusFilters.mediumTrainingFilter) {
        filterOptions.statuses.push('medium');
      }
      if (trainingStatusFilters.lowTrainingFilter) {
        filterOptions.statuses.push('low');
      }
      if (trainingStatusFilters.greyTrainingFilter) {
        filterOptions.statuses.push('grey');
      }
    }

    if (teamFilter.value !== 'all_personnel') {
      filterOptions.team = teamFilter.value;
    }

    axios
      .get('/personnel', { params: filterOptions })
      .then((response) => {
        const metaData = {
          currentPage: response.data.meta.currentPage,
          totalPages: response.data.meta.totalPages,
          totalCount: response.data.meta.totalCount,
          unscopedCount: response.data.meta.unscopedCount,
          scopedCount: response.data.meta.scopedCount,
          trainingStatusCounts: response.data.meta.trainingStatusCounts,
        };

        const personnelData = {
          loaded: true,
          collection: response.data.data,
          includedLineManagers: response.data.included.filter(
            (object) => object.type === 'lineManager',
          ),
          includedRoles: response.data.included.filter(
            (object) => object.type === 'companyRole',
          ),
          includedUserCompanyRoles: response.data.included.filter(
            (object) => object.type === 'userCompanyRole',
          ),
          includedTeams: response.data.included.filter(
            (object) => object.type === 'team',
          ),
          includedFieldValues: response.data.included.filter(
            (object) => object.type === 'fieldValue',
          ),
          includedUsers: response.data.included.filter(
            (object) => object.type === 'user',
          ),
          includedProfilePhotos: response.data.included.filter(
            (object) => object.type === 'profilePhoto',
          ),
          metaData: metaData,
        };

        response.data.meta.archivedSet ?
          setArchivedPersonnel(personnelData)
        : setPersonnel(personnelData);
      })
      .catch(breadBoard.addInedibleToast);
  };

  const fetchFields = () => {
    axios
      .get('/custom_fields/available_field_attributes')
      .then((response) => {
        setAvailableFieldOptions({
          loaded: true,
          collection: response.data.included.filter(
            (object) => object.type === 'fieldOption',
          ),
        });
        setAvailableFields({ loaded: true, collection: response.data.data });
      })
      .catch(breadBoard.addInedibleToast);
  };

  const fetchAssignableCompanies = function () {
    axios.get('/assignable_companies').then((response) => {
      setAssignableCompanies({ loaded: true, collection: response.data.data });
    });
  };

  const fetchAssignableCompanyRoles = function () {
    axios
      .get('/assignable_company_roles')
      .then((response) =>
        setAssignableRoles({ loaded: true, collection: response.data.data }),
      );
  };

  const applyAllFilters = () => {
    setPersonnelTypeFilters(defaultPersonnelTypeFilters);
    setTrainingStatusFilters({
      greyTrainingFilter: true,
      lowTrainingFilter: true,
      mediumTrainingFilter: true,
      highTrainingFilter: true,
    });
  };

  const resetSubcontractor = () => {
    setCurrentSubcontractor(initialSubcontratorState);
    setIsSubcontractor(false);
  };

  const fetchArchivedPersonnel = (page = 1) => {
    fetchPersonnel(page, { archived: true });
  };

  const fetchContextualPersonnel = (page) => {
    archivedPersonnelToggle ?
      fetchArchivedPersonnel(page)
    : fetchPersonnel(page);
  };

  const refreshAllPersonnel = (page = personnel.metaData.currentPage) => {
    if (personnel.loaded) fetchPersonnel(page);
    if (archivedPersonnel.loaded) fetchArchivedPersonnel(page);
  };

  const handleArchive = ({
    isToastMessageDisplayed = true,
    personnelId = currentPersonnel.id,
  }) => {
    axios
      .patch(`/personnel/${personnelId}`, {
        personnel: { archived_at: moment().format('DD/MM/YYYY') },
      })
      .then((response) => {
        refreshAllPersonnel();
        refreshTrackedPersonnelUsage();
        if (isToastMessageDisplayed) {
          breadBoard.addToast(
            <UndoableToast
              onBurnToast={breadBoard.handleBurnToast}
              onUndoAction={() =>
                handleUnarchive({
                  personnelId: response.data.data.id,
                  isToastMessageDisplayed: false,
                })
              }
              toastText={
                <React.Fragment>
                  <span className='tw-font-medium'>
                    {personDisplayName(response.data.data.attributes)}
                  </span>{' '}
                  <span>is now archived</span>
                </React.Fragment>
              }
            />,
          );
        }
        closeArchiveModal();
        sendAnalytics('Personnel archived', {
          currentUser: currentActor.user,
          personnelId: personnelId,
        });
      })
      .catch((_error) => {
        closeArchiveModal();
        breadBoard.addInedibleToast({
          fullMessage: `${personDisplayName(currentPersonnel)} was not archived. Please try again.`,
        });
      });
  };

  const handleUnarchive = ({
    isToastMessageDisplayed = true,
    personnelId = currentPersonnel.id,
  }) => {
    const unarchiveParams = {
      personnel: { archived_at: null },
    };

    axios
      .patch(`personnel/${personnelId}`, unarchiveParams)
      .then((response) => {
        fetchPersonnel(personnel.metaData.currentPage);
        const isLastPersonnelOnPage = archivedPersonnel.collection.length === 1;
        const isCurrentlyOnProgressedPage =
          archivedPersonnel.metaData.totalPages > 1 &&
          archivedPersonnel.metaData.currentPage > 1;
        const archivedPageToRefresh =
          isLastPersonnelOnPage && isCurrentlyOnProgressedPage ?
            archivedPersonnel.metaData.currentPage - 1
          : archivedPersonnel.metaData.currentPage;
        fetchArchivedPersonnel(archivedPageToRefresh);
        refreshTrackedPersonnelUsage();
        sendAnalytics('Personnel unarchived', {
          currentUser: currentActor.user,
          personnelId: personnelId,
        });
        if (isToastMessageDisplayed) {
          breadBoard.addToast(
            <UndoableToast
              onBurnToast={breadBoard.handleBurnToast}
              onUndoAction={() =>
                handleArchive({
                  personnelId: response.data.data.id,
                  isToastMessageDisplayed: false,
                })
              }
              toastText={
                <React.Fragment>
                  <span className='tw-font-medium'>
                    {personDisplayName(response.data.data.attributes)}
                  </span>{' '}
                  <span>is now active</span>
                </React.Fragment>
              }
            />,
          );
        }
        closeArchiveModal();
      })
      .catch((_error) => {
        closeArchiveModal();
        breadBoard.addInedibleToast({
          fullMessage: `${personDisplayName(currentPersonnel)} was not unarchived. Please try again.`,
        });
      });
  };

  const handleUnarchiveClick = ({ personnelId }) => {
    handleSelectPersonnel(personnelId);
    handleArchiveButtonClick({ personnelId });
  };

  const handleArchiveButtonClick = ({ personnelId }) => {
    if (!currentActor.isAllowedFeature('training_register')) {
      return openArchiveModal();
    }

    const personnelIsBlockedFromUnarchive = () => {
      const personnelToBeUnarchived = archivedPersonnel.collection.find(
        (personnel) => personnel.id == personnelId,
      );
      const personnelToBeUnarchivedHasOtherBlockers =
        personnelToBeUnarchived.attributes.trackableBlockers.some(
          (blocker) => blocker != 'archived',
        );
      const personnelToBeUnarchivedHasTraining =
        personnelToBeUnarchived.attributes.trackableQualities.includes(
          'has_training',
        );

      return (
        personnelToBeUnarchivedHasTraining &&
        !personnelToBeUnarchivedHasOtherBlockers
      );
    };

    if (hasReachedTrackedPersonnelLimit && personnelIsBlockedFromUnarchive()) {
      openUnarchiveBlockedModal();
    } else {
      openArchiveModal();
    }
  };

  const openArchiveToggleConfirmation = ({ personnelId }) => {
    setSidePanelIsOpen(false);
    handleArchiveButtonClick({ personnelId });
  };

  // interaction handlers

  const handleNewPersonnel = (event) => {
    event.currentTarget.blur();
    setCurrentPersonnel(initialCurrentPersonnelState);
    resetSubcontractor();
    resetRequestError();
    resetSidePanelContext();
    openSidePanel();
  };

  const onAssignableRoleAdd = (role) => {
    setAssignableRoles({
      collection: [...assignableRoles.collection, role],
      loaded: true,
    });
  };

  const handleNewCompanyRoleAdd = (newRole) => {
    setNewCompanyRoles(newCompanyRoles.concat([newRole]));
  };

  const handlePageChange = (event) => {
    const page = event.currentTarget.getAttribute('data-page');
    fetchContextualPersonnel(page);
  };

  const handleSearchReset = (event) => {
    const name = event.target.getAttribute('data-attr-name');
    setCurrentSearch({ ...currentSearch, [name]: '' });
  };

  const setFieldValuesOnPersonnel = (currentPersonnelId) => {
    const personnelValues = personnel.includedFieldValues.filter(
      (value) => value.relationships.entity.data.id === currentPersonnelId,
    );

    return personnelValues.reduce((accumulator, currentValue) => {
      if (currentValue.relationships.fieldAttribute.data) {
        accumulator[currentValue.relationships.fieldAttribute.data.id] = {
          id: currentValue.id,
          value:
            currentValue.attributes.valueType == 'date' ?
              moment.parseZone(currentValue.attributes.value).toDate()
            : currentValue.attributes.value,
          valueType: currentValue.attributes.valueType,
          fieldAttributeId: currentValue.relationships.fieldAttribute.data.id,
          fieldOptionId:
            currentValue.relationships.fieldOption.data &&
            currentValue.relationships.fieldOption.data.id,
          personnelId: currentValue.relationships.entity.data.id,
        };
      }
      return accumulator;
    }, {});
  };

  const handleSelectPersonnel = (id) => {
    const viewablePersonnel = viewableCollection.collection.find(
      (p) => p.id == id,
    );
    const attributes = viewablePersonnel.attributes;
    const meta = viewablePersonnel.meta;
    const lineManagerData = viewablePersonnel.relationships.lineManager.data;
    const companyRolesData = viewablePersonnel.relationships.companyRoles.data;
    const userCompanyRolesData =
      viewablePersonnel.relationships.userCompanyRoles.data;
    const userData = viewablePersonnel.relationships.user.data;

    const userCompanyRoles = userCompanyRolesData.map(
      (ucr) => mappedUserCompanyRoles[ucr.id],
    );
    const primaryUserCompanyRole = userCompanyRoles.find(
      (ucr) => ucr.attributes.primary,
    );
    const user = personnel.includedUsers.find(
      (person) => person.id === userData?.id,
    );
    const profilePhoto = personnel.includedProfilePhotos.find(
      (photo) => photo.relationships.personnel.data.id === id,
    );

    currentPersonnelIdRef.current = id;

    setCurrentPersonnel({
      id: id,
      firstName: attributes.firstName || '',
      lastName: attributes.lastName || '',
      externalId: attributes.externalId || '',
      email: attributes.email || '',
      lineManagerId: lineManagerData ? lineManagerData.id : '',
      fieldValues: setFieldValuesOnPersonnel(viewablePersonnel.id),
      company: {
        companyId: attributes.companyId || '',
        subcontractorId: attributes.subcontractorId || '',
      },
      primaryCompanyRoleId:
        primaryUserCompanyRole ?
          primaryUserCompanyRole.relationships.companyRole.data.id
        : '',
      companyRoleIds:
        companyRolesData ?
          companyRolesData.map((companyRole) => companyRole.id)
        : [],
      isArchived: !!attributes.archivedAt,
      isParticipatingInOngoingProjects:
        attributes.isParticipatingInOngoingProjects,
      eLearningBookingsCount: meta.eLearningBookingsCount || 0,
      isEditable: viewablePersonnel.meta.isEditable,
      profileAccessEnabled: !!user?.attributes?.profileAccessEnabled,
      profilePhoto: { ...profilePhoto?.attributes, id: profilePhoto?.id },
    });

    resetSubcontractor();
    resetRequestError();
  };

  const handleRowClick = (personnelId) => {
    if (
      currentActor.isAllowedFeature('training_register_only') ||
      currentActor.isAllowedFeature('training_register')
    ) {
      setPersonnelId(personnelId);
      window.location.replace(`/personnel/${personnelId}`);
    } else {
      handleShowPersonnel(personnelId);
    }
  };

  const handleShowPersonnel = (personnelId) => {
    handleSelectPersonnel(personnelId);
    setSidePanelContext('show');
    openSidePanel();
  };

  const handleCompanyRolesOptionChange = (values) => {
    if (
      currentPersonnel.primaryCompanyRoleId === '' &&
      currentPersonnel.companyRoleIds.length === 0
    ) {
      setCurrentPersonnel({
        ...currentPersonnel,
        primaryCompanyRoleId: values[0].value,
        companyRoleIds: values.map((v) => v.value),
      });
    } else {
      setCurrentPersonnel({
        ...currentPersonnel,
        companyRoleIds: values.map((v) => v.value),
      });
    }
  };

  function handleSelectPrimary(id) {
    setCurrentPersonnel({ ...currentPersonnel, primaryCompanyRoleId: id });
  }

  function handleRemoveRole(id) {
    const filteredCompanyRoleIds = currentPersonnel.companyRoleIds.filter(
      (roleId) => roleId !== id,
    );
    const newPrimaryCompanyRoleId =
      filteredCompanyRoleIds.length > 0 ?
        sortRoleIdsByPosition(
          assignableRoles.collection,
          filteredCompanyRoleIds,
        )[0]
      : '';
    setCurrentPersonnel({
      ...currentPersonnel,
      companyRoleIds: filteredCompanyRoleIds,
      primaryCompanyRoleId:
        currentPersonnel.primaryCompanyRoleId === id ?
          newPrimaryCompanyRoleId
        : currentPersonnel.primaryCompanyRoleId,
    });
  }

  function handleTeamOptionChange(team) {
    setStore({ ...tabStore, page: 1 });
    setTeamFilter({ value: team.value, label: team.label });
  }

  function handleDeletePersonnelClick() {
    setDestroyModalIsOpen(true);
  }

  function handleSubcontractorSelect(selection) {
    setCurrentSubcontractor(selection);
  }

  const handleProcessProfilePhoto = (tempPhotoFile) => {
    setIsProfilePhotoUploading(true);
    photoUploadLoading.current = true;

    new Upload({
      file: tempPhotoFile,
      customType: 'profile_photo',
      onUpload: (_fileType, uploadKey) => {
        if (!photoUploadLoading.current) {
          return;
        }
        createAndProcessProfilePhotoForUploadedPhoto(uploadKey)
          .then((profilePhotoData) => {
            if (!photoUploadLoading.current) {
              return;
            }
            setUploadedDomainProfilePhotos({
              ...uploadedDomainProfilePhotos,
              [profilePhotoData.id]: profilePhotoData,
            });
            handleNewProfilePhotoUploaded(profilePhotoData);
            setIsProfilePhotoUploading(false);
            photoUploadLoading.current = false;
          })
          .catch((error) => {
            if (error) {
              breadBoard.addInedibleToast({ fullMessage: error.message });
            }
            setIsProfilePhotoUploading(false);
          });
      },
      onError: (error) => breadBoard.addInedibleToast({ fullMessage: error }),
    }).start();
  };

  const createAndProcessProfilePhotoForUploadedPhoto = (uploadKey) => {
    return new Promise((resolve, reject) => {
      const currentProfilePhoto = currentPersonnel.profilePhoto;
      axios
        .post(`/profile_photos`, {
          profile_photo: {
            upload_key: uploadKey,
            crop_x: currentProfilePhoto.cropX,
            crop_y: currentProfilePhoto.cropY,
            crop_dimension: currentProfilePhoto.cropDimension,
            rotation: currentProfilePhoto.rotation,
          },
        })
        .then((response) => {
          if (!photoUploadLoading.current) {
            reject();
          }
          // poll for processed photo after upload
          setupPolling({
            requestFn: () =>
              axios.get(
                `/profile_photos/${response.data.data.attributes.hashid}`,
              ),
            testFn: (response) => response.data.data.attributes.processed,
            intervalTime: 1000,
          })
            .startPolling()
            .then((response) => resolve(response.data.data))
            .catch(() => {
              reject(new Error('Profile photo processing failed'));
            });
        })
        .catch((error) => reject(error));
    });
  };

  const handlePersonnelSidePanelCancel = () => {
    closeSidePanel();
    removePersonnelFromPersonnelIdsOfProfilePhotoUnderProgress(
      currentPersonnel.id,
    );
    setIsProfilePhotoUploading(false);
    photoUploadLoading.current = false;
  };

  const handlePhotoModalOpen = () => {
    setIsProfilePhotoModalOpen(true);
  };

  const handlePhotoModalClose = () => {
    setIsProfilePhotoModalOpen(false);
    removePersonnelFromPersonnelIdsOfProfilePhotoUnderProgress(
      currentPersonnel.id,
    );
  };

  const addPersonnelToPersonnelIdsOfProfilePhotoUnderProgress = (
    currentPersonnelId,
  ) => {
    const newPersonnelIdsOfProfilePhotoUnderProgress = new Set(
      personnelIdsOfProfilePhotoUnderProgress,
    );
    newPersonnelIdsOfProfilePhotoUnderProgress.add(currentPersonnelId);
    setPersonnelIdsOfProfilePhotoUnderProgress(
      newPersonnelIdsOfProfilePhotoUnderProgress,
    );
  };

  const removePersonnelFromPersonnelIdsOfProfilePhotoUnderProgress = (
    currentPersonnelId,
  ) => {
    const newPersonnelIdsOfProfilePhotoUnderProgress = new Set(
      personnelIdsOfProfilePhotoUnderProgress,
    );
    newPersonnelIdsOfProfilePhotoUnderProgress.delete(currentPersonnelId);
    setPersonnelIdsOfProfilePhotoUnderProgress(
      newPersonnelIdsOfProfilePhotoUnderProgress,
    );
  };

  useEffect(() => {
    fetchPersonnel(tabStore.page || 1);
    fetchFields();
  }, [personnelTypeFilters, trainingStatusFilters, teamFilter]);

  useEffect(() => {
    if (debouncedCurrentSearch) {
      refreshAllPersonnel(1);
    }
  }, [debouncedCurrentSearch]);

  useEffect(() => {
    fetchAssignableCompanies();
    fetchAssignableCompanyRoles();
  }, []);

  useEffect(() => {
    setStore({
      currentSearch: currentSearch.personnelSearch,
      page: personnel.metaData.currentPage,
      teamFilter,
      archivedPersonnelToggle,
    });
  }, [
    currentSearch.personnelSearch,
    personnel.metaData.currentPage,
    teamFilter,
    archivedPersonnelToggle,
  ]);

  useEffect(() => {
    if (archivedPersonnelToggle && !archivedPersonnel.loaded) {
      fetchArchivedPersonnel();
    }
  }, [archivedPersonnelToggle, archivedPersonnel.loaded]);

  useEffect(() => {
    if (personnelTypeFilters) {
      window.sessionStorage.setItem(
        'personnelTypeFilters',
        JSON.stringify(personnelTypeFilters),
      );
    }
  }, [personnelTypeFilters]);

  useEffect(() => {
    if (trainingStatusFilters) {
      window.sessionStorage.setItem(
        'trainingStatusFilters',
        JSON.stringify(trainingStatusFilters),
      );
    }
  }, [trainingStatusFilters]);

  return (
    <>
      <PersonnelFilter
        isDisabled={archivedPersonnelToggle}
        metaData={viewableMetaData}
        personnelTypeFilters={{
          filters: personnelTypeFilters,
          onChange: handlePersonnelTypeFilterChange,
        }}
        scopedCount={viewableCollection.metaData.scopedCount}
        teamFilter={{
          filter: {
            value: teamFilter.value,
            label:
              mappedTeams[teamFilter.value] ?
                mappedTeams[teamFilter.value].attributes.name
              : teamFilter.label,
          },
          onChange: handleTeamOptionChange,
        }}
        totalCount={viewableCollection.metaData.totalCount}
        trainingStatusFilters={{
          filters: trainingStatusFilters,
          onChange: handleTrainingStatusFilterChange,
        }}
      />
      {assignableCompanies.loaded && personnel.loaded && (
        <>
          <div className={'col-sm-9 p-r-0'}>
            {hasReachedTrackedPersonnelUsageThreshold &&
              (['primary', 'administrator'].includes(
                currentActor.user.attributes.accessType,
              ) ||
                hasReachedTrackedPersonnelLimit) && (
                <TrackedPersonnelUsageBanner
                  hasReachedLimit={hasReachedTrackedPersonnelLimit}
                  usage={trackedPersonnelUsage.value}
                />
              )}
            {totalPersonnelCount > 0 && (
              <PersonnelBar
                actionButtonsVisible={
                  trainingRegisterResourceManagementContext.hasPersonnelEditableAccess
                }
                isArchivedToggled={archivedPersonnelToggle}
                onArchiveToggle={toggleArchivedPersonnelVisibility}
                onNewPersonnel={handleNewPersonnel}
                onSearchInputChange={handleSearchInputChange}
                onSearchReset={handleSearchReset}
                personnelSearch={currentSearch.personnelSearch}
              />
            )}
            {viewableCollection.collection.length > 0 ?
              <React.Fragment>
                <PersonnelTable
                  isActionsColumnVisible={isActionsColumnVisible}
                  isArchived={archivedPersonnelToggle}
                  rows={viewableCollection.collection.map(
                    (personnelIndividual) => {
                      const personnelUserCompanyRoles =
                        personnelIndividual.relationships.userCompanyRoles.data.map(
                          (ucr) => mappedUserCompanyRoles[ucr.id],
                        );
                      const personnelCompanyRoles =
                        personnelIndividual.relationships.companyRoles.data.map(
                          (role) => mappedCompanyRoles[role.id],
                        );
                      const personnelTeams =
                        personnelIndividual.relationships.teams.data.map(
                          (team) => mappedTeams[team.id],
                        );

                      const allProfilePhotos =
                        personnel.includedProfilePhotos.concat(
                          archivedPersonnel.includedProfilePhotos,
                        );
                      const personnelProfilePhoto = allProfilePhotos.find(
                        (photo) =>
                          photo.relationships.personnel.data.id ===
                          personnelIndividual.id,
                      );

                      const isActionsCountVisible =
                        (personnelIndividual.meta.isTrainingWritable &&
                          !hasReachedTrackedPersonnelLimit) ||
                        (hasReachedTrackedPersonnelLimit &&
                          personnelIndividual.attributes.isTracked);

                      const isProcessingProfilePhoto =
                        personnelIdsOfProfilePhotoUnderProgress.has(
                          personnelIndividual?.id,
                        );

                      return (
                        <PersonnelRow
                          division={assignableCompanies.collection.find(
                            (company) =>
                              personnelIndividual.attributes.companyId ==
                              company.id,
                          )}
                          isActionsColumnVisible={isActionsColumnVisible}
                          isActionsCountVisible={isActionsCountVisible}
                          isArchived={archivedPersonnelToggle}
                          isProcessingProfilePhoto={isProcessingProfilePhoto}
                          key={`personnelRow--${personnelIndividual.id}`}
                          onRowClick={handleRowClick}
                          onUnarchiveClick={handleUnarchiveClick}
                          personnel={personnelIndividual}
                          profilePhoto={personnelProfilePhoto}
                          roles={sortRolesByPrimaryAndPosition(
                            personnelUserCompanyRoles,
                            personnelCompanyRoles,
                          )}
                          subcontractor={assignableCompanies.collection.find(
                            (company) =>
                              personnelIndividual.attributes.subcontractorId ==
                              company.id,
                          )}
                          teams={personnelTeams}
                        />
                      );
                    },
                  )}
                />
                {viewableCollection.collection.length > 0 &&
                  viewableCollection.metaData.totalPages > 1 && (
                    <div className='m-t-80 text-center'>
                      <Paginator
                        currentPage={viewableCollection.metaData.currentPage}
                        onClick={handlePageChange}
                        totalPages={viewableCollection.metaData.totalPages}
                      />
                    </div>
                  )}
                {sidePanelContext === 'show' ?
                  <DestroyModal
                    additionalContent={
                      (
                        currentActor.isAllowedFeature('e_learning') &&
                        currentPersonnel.eLearningBookingsCount > 0
                      ) ?
                        <p className='alert alert-danger p-12 m-0 m-t-20'>
                          This personnel has eLearning booked which will be
                          cancelled. Any courses in progress will not be
                          refunded.
                        </p>
                      : null
                    }
                    confirmationText={`Delete ${currentPersonnel.firstName}`}
                    displayText={`Are you sure you want to delete ${currentPersonnel.firstName}?`}
                    isOpen={destroyModalIsOpen}
                    onClose={() => setDestroyModalIsOpen(false)}
                    onDestroy={destroyPersonnel}
                  />
                : null}
              </React.Fragment>
            : (
              totalPersonnelCount === 0 &&
              trainingRegisterResourceManagementContext.hasPersonnelEditableAccess
            ) ?
              <AddPersonnelSection onAddClick={handleNewPersonnel} />
            : debouncedCurrentSearch.personnelSearch ?
              <BlankPersonnelSearchResults
                allowAdditionalSearching={
                  currentActor.division.attributes.primary
                }
                allowFilterChange={
                  !areAllFiltersApplied && !archivedPersonnelToggle
                }
                alternatePersonnelSetName={
                  archivedPersonnelToggle ? 'active' : 'archived'
                }
                onFilterInclusionClick={applyAllFilters}
                onSetToggle={toggleArchivedPersonnelVisibility}
                search={debouncedCurrentSearch.personnelSearch}
              />
            : <BlankPersonnelSetSection
                setName={archivedPersonnelToggle ? 'archived' : 'active'}
              />
            }
          </div>
          <PersonnelSidePanel
            allowShowFooterContents={
              trainingRegisterResourceManagementContext.hasPersonnelEditableAccess &&
              currentPersonnel.isEditable
            }
            assignableCompanies={assignableCompanies.collection}
            assignableRoles={assignableRoles.collection}
            availableFieldOptions={availableFieldOptions}
            availableFields={availableFields}
            closeSidePanel={closeSidePanel}
            createPersonnel={createPersonnel}
            currentPersonnel={currentPersonnel}
            currentSubcontractor={currentSubcontractor}
            division={
              selectedPersonnel &&
              assignableCompanies.collection.find(
                (company) =>
                  company.attributes.resourceType === 'Company' &&
                  company.id == selectedPersonnel.attributes.companyId,
              )
            }
            domainProfilePhoto={selectedPersonnelPhoto}
            isProfilePhotoLoading={isProfilePhotoUploading}
            isSubcontractor={isSubcontractor}
            lineManager={
              selectedPersonnel?.relationships?.lineManager?.data &&
              personnel.includedLineManagers.find(
                (person) =>
                  person.id ===
                  selectedPersonnel.relationships.lineManager.data.id,
              )
            }
            lineManagers={personnel.includedLineManagers}
            onArchiveButtonClick={openArchiveToggleConfirmation}
            onAssignableRoleAdd={onAssignableRoleAdd}
            onCancel={handlePersonnelSidePanelCancel}
            onCompanyRolesOptionChange={handleCompanyRolesOptionChange}
            onDateFieldChange={handleDateFieldChange}
            onDeleteClick={handleDeletePersonnelClick}
            onEditClick={() => setSidePanelContext('edit')}
            onFieldOptionChange={handleFieldOptionChange}
            onNewCompanyRoleAdd={handleNewCompanyRoleAdd}
            onPersonnelDateChange={handlePersonnelDateChange}
            onPersonnelFieldChange={handleFieldValueInputChange}
            onPersonnelInputChange={handlePersonnelInputChange}
            onPersonnelOptionChange={handlePersonnelOptionChange}
            onPersonnelUpdateSubmit={handlePersonnelUpdateSubmit}
            onPhotoModalOpen={handlePhotoModalOpen}
            onRemoveRole={handleRemoveRole}
            onSelectPrimary={handleSelectPrimary}
            onSubcontractorSelect={handleSubcontractorSelect}
            onToggle={handleToggle}
            personnel={selectedPersonnel}
            removeErrorStyling={removeErrorStyling}
            requestError={requestError}
            roles={currentPersonnelRoles}
            setCurrentPersonnel={setCurrentPersonnel}
            setIsSubcontractor={setIsSubcontractor}
            setSidePanelContext={setSidePanelContext}
            sidePanelContext={sidePanelContext}
            sidePanelIsOpen={sidePanelIsOpen}
            subcontractor={
              selectedPersonnel &&
              assignableCompanies.collection.find(
                (company) =>
                  company.attributes.resourceType === 'Subcontractor' &&
                  company.id == selectedPersonnel.attributes.subcontractorId,
              )
            }
            submitDisabled={submitDisabled}
            teams={
              (
                trainingRegisterResourceManagementContext.hasTeamViewableAccess &&
                selectedPersonnel
              ) ?
                selectedPersonnel.relationships.teams.data.map(
                  (team) => mappedTeams[team.id],
                )
              : []
            }
            user={
              selectedPersonnel?.relationships?.user?.data &&
              personnel.includedUsers.find(
                (person) =>
                  person.id === selectedPersonnel.relationships.user.data.id,
              )
            }
          />
        </>
      )}
      <ArchiveModal
        closeModal={closeArchiveModal}
        hasELearningBookings={
          currentActor.isAllowedFeature('e_learning') &&
          currentPersonnel.eLearningBookingsCount > 0
        }
        isArchived={currentPersonnel.isArchived}
        isOpen={archiveModalIsOpen}
        longName={personDisplayName(currentPersonnel)}
        onArchive={handleArchive}
        onUnarchive={handleUnarchive}
        shortName={currentPersonnel.firstName}
        unarchiveBody={
          <p className='m-t-16'>
            You can also click on the row to view their profile without
            unarchiving them.
          </p>
        }
      />
      <UnarchiveBlockedModal
        closeModal={closeUnarchiveBlockedModal}
        isOpen={unarchiveBlockedModalIsOpen}
        usage={trackedPersonnelUsage.value}
      />
      {isProfilePhotoModalOpen && (
        <ProfilePhotoModal
          currentPersonnel={currentPersonnel}
          domainProfilePhoto={selectedPersonnelPhoto}
          key={currentPersonnel?.id}
          onCropReady={handleProfilePhotoCropReady}
          onMediaLoaded={handleProfilePhotoMediaChange}
          onPhotoModalClose={handlePhotoModalClose}
          onPhotoRemoved={handleProfilePhotoRemoval}
          onProcessProfilePhoto={(photoFile) => {
            setIsProfilePhotoModalOpen(false);

            // skip it if photo was removed.
            if (!!photoFile) {
              handleProcessProfilePhoto(photoFile);
            }
          }}
        />
      )}
    </>
  );
}

PersonnelTab.defaultProps = {
  initialTrainingFilters: {
    highTrainingFilter: true,
    mediumTrainingFilter: true,
    lowTrainingFilter: true,
    greyTrainingFilter: true,
  },
};

PersonnelTab.propTypes = {
  emailFilter: PropTypes.string,
  initialTrainingFilters: PropTypes.object,
};
