$(function () {
  if ($('#pending-default-pdfs').length) {
    pollJobStatus(
      $('#pending-default-pdfs').data('job-id'),
      'add_default_pdfs',
    );
  }

  function pollJobStatus(jobId, path) {
    new HandshqApp.Polling({
      url: '/jobs/status/' + jobId,
      responseCheck: function (response) {
        return response.status == 'completed';
      },
      successCallback: function (response) {
        $.ajax({
          data: response,
          type: 'GET',
          url: '/projects/' + response.project_id + '/' + path,
        });
      },
      failureCallback: function (_response) {},
    }).start();
  }
});
