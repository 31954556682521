import React from 'react';
import PropTypes from 'prop-types';

import { resourceShape } from 'components/helpers/serialisableResources';
import { personDisplayName } from 'components/helpers/users';

import TextButton from 'components/application/buttons/TextButton';

export default function SupportModalContent({ closeModal, lineManager }) {
  return (
    <>
      <div className='tw-border-0 tw-border-b-1 tw-border-solid tw-border-grey-100 tw-p-6'>
        <h2 className='tw-m-0 tw-text-l tw-font-semibold tw-text-grey-900'>
          Support
        </h2>
      </div>
      <div className='tw-p-6'>
        {lineManager?.attributes?.email && (
          <>
            <p className='tw-mb-0 tw-font-semibold'>
              Spotted something incorrect?
            </p>
            <p>
              We recommend reaching out to{' '}
              {personDisplayName(lineManager.attributes)}{' '}
              <span className='tw-whitespace-nowrap'>
                <span>(</span>
                <a
                  className='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
                  href={`mailto:${lineManager.attributes.email}`}
                >
                  {lineManager.attributes.email}
                </a>
                <span>)</span>
              </span>{' '}
              who will be able to help.
            </p>
          </>
        )}
        <p className='tw-mb-0 tw-font-semibold'>Having technical issues?</p>
        <p className='tw-mb-0'>
          For help with your training profile, please speak to your training
          manager or{' '}
          <a
            className='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
            href='https://intercom.help/hands-hq/en/articles/10213935-new-viewing-your-training-profile'
            target='blank'
          >
            read our guide
          </a>
          .
        </p>
      </div>
      <div className='tw-flex tw-justify-end tw-border-0 tw-border-t-1 tw-border-solid tw-border-grey-100 tw-p-4'>
        <TextButton color='blue' onClick={closeModal} size='md'>
          Close
        </TextButton>
      </div>
    </>
  );
}

SupportModalContent.propTypes = {
  lineManager: resourceShape('lineManager'),
  closeModal: PropTypes.func.isRequired,
};
