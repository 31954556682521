import titleCase from 'voca/title_case';

export const fieldSettingAttributes = (form) => {
  const attributes = {
    field_setting: {
      restricted: form.restricted,
      field_attribute_attributes: {
        type: `CustomFields::${titleCase(form.fieldType)}FieldAttribute`,
        name: form.name,
      },
    },
  };

  if (form.fieldAttributeId)
    attributes.field_setting.field_attribute_attributes.id =
      form.fieldAttributeId;

  if (form.fieldType === 'select' && form.options.allDataIds.length > 1) {
    const domainFormOptionIds = Object.values(form.options.data).reduce(
      (accumulator, formOption) => {
        if (!formOption.isNew) accumulator.push(formOption.id);

        return accumulator;
      },
      [],
    );

    const formOptionIds = [
      ...new Set([...form.options.allDataIds, ...domainFormOptionIds]),
    ];

    function addUpdateOrCreateAttribute(accumulator, formOption, index) {
      const obj = { value: formOption.value, position: index + 1 };

      if (!formOption.isNew) obj.id = formOption.id;
      accumulator[index] = obj;
    }

    function addDestroyAttribute(accumulator, formOption, index) {
      accumulator[index] = { id: formOption.id, _destroy: '1' };
    }

    attributes.field_setting.field_attribute_attributes.field_options_attributes =
      formOptionIds.reduce((accumulator, fieldOptionId, index) => {
        const formOption = form.options.data[fieldOptionId];

        if (form.options.allDataIds.includes(formOption.id)) {
          addUpdateOrCreateAttribute(accumulator, formOption, index);
        } else {
          addDestroyAttribute(accumulator, formOption, index);
        }

        return accumulator;
      }, {});
  }

  return attributes;
};
