$(document).ready(function () {
  $('.upgrading-account').hide();

  $('.plan-upgrade-btn').on('click', function () {
    $('.upgrading-account').show();
    $('.plan-upgrade-modal .plan-details').hide();
    $('.plan-details #extra-charging').hide();
    $('.plan-details p.plan-credits').hide();
    $('.modal-footer.plan-info button[data-dismiss="modal"]').prop(
      'disabled',
      true,
    );
  });
});
