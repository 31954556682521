import React from 'react';
import TooltipTrigger from 'react-popper-tooltip';

const Tooltip = ({
  children,
  className,
  hideArrow,
  tooltip,
  triggerProps,
  ...props
}) => (
  <TooltipTrigger
    {...props}
    tooltip={({
      arrowRef,
      getArrowProps,
      getTooltipProps,
      placement,
      tooltipRef,
    }) => (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: `tooltip-dark${className ? ` ${className}` : ''}`,
        })}
      >
        {!hideArrow && (
          <div
            {...getArrowProps({
              ref: arrowRef,
              className: `tooltip-dark-arrow`,
              'data-placement': placement,
            })}
          />
        )}
        {tooltip}
      </div>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <span
        {...getTriggerProps({
          ...{
            ref: triggerRef,
            className: 'tooltip-trigger',
          },
          ...triggerProps,
        })}
      >
        {children}
      </span>
    )}
  </TooltipTrigger>
);

export default Tooltip;
