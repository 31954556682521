import React from 'react';
import PropTypes from 'prop-types';

export default function CircleQuestion({ size = 'md' }) {
  return (
    <span
      className={`tw-apply-circle-question tw-apply-circle-question--${size}`}
    />
  );
}

CircleQuestion.propTypes = {
  size: PropTypes.oneOf(['sm', 'md']),
};
