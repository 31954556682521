import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/application/Tooltip';
import TextButton from 'components/application/buttons/TextButton';
import classNames from 'classnames';

export default function ShowFooter(props) {
  const buttonWrapperClassName = props.isCompact ? 'fh-48' : 'fh-58';

  return (
    <React.Fragment>
      {!props.isEditButtonHidden && (
        <div
          className={classNames(
            'tooltip-parent',
            props.editDisabled && 'tooltip-parent--disallowed',
          )}
        >
          <div
            className={classNames(
              'tw-border-0 tw-border-t-1 tw-border-solid tw-border-grey-100',
              buttonWrapperClassName,
            )}
          >
            <TextButton
              color='blue'
              disabled={props.editDisabled}
              onClick={props.onEditClick}
              size='full'
            >
              Edit {props.type || 'details'}
            </TextButton>
          </div>
          {props.editDisabled && (
            <Tooltip
              className={classNames(props.isCompact ? 'pos-t-12' : 'pos-t-16')}
              placement='top'
              tooltip={props.editDisabledTooltip}
              trigger='hover'
            />
          )}
        </div>
      )}
      {props.children}
      <div
        className={classNames(
          'tooltip-parent',
          props.deleteDisabled && 'tooltip-parent--disallowed',
        )}
      >
        <div
          className={classNames(
            'tw-border-0 tw-border-t-1 tw-border-solid tw-border-grey-100',
            buttonWrapperClassName,
          )}
        >
          <TextButton
            color='red'
            disabled={props.deleteDisabled}
            onClick={props.onDeleteClick}
            size='full'
          >
            {`Delete${props.type ? ` ${props.type}` : ''}`}
          </TextButton>
        </div>
        {props.deleteDisabled && (
          <Tooltip
            className={classNames(props.isCompact ? 'pos-t-12' : 'pos-t-16')}
            placement='top'
            tooltip={props.deleteDisabledTooltip}
            trigger='hover'
          />
        )}
      </div>
    </React.Fragment>
  );
}

ShowFooter.propTypes = {
  type: PropTypes.string,
  buttonProps: PropTypes.object,
  editDisabled: PropTypes.bool,
  editDisabledTooltip: PropTypes.string,
  deleteDisabled: PropTypes.bool,
  deleteDisabledTooltip: PropTypes.string,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func,
};

ShowFooter.defaultProps = {
  editDisabled: false,
  deleteDisabled: false,
  isCompact: false,
  isEditButtonHidden: false,
};
