import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';

import { oneTimePasswordShape } from 'components/hooks/useOneTimePassword';
import { validationErrorsShape } from 'components/hooks/useValidationErrors';

import ModalBox from 'components/application/ModalBox';
import OneTimePasswordSignInCard from 'components/users/twoFactorAuthentication/OneTimePasswordSignInCard';
import ValidationErrorsMessages from 'components/validationErrors/components/ValidationErrorsMessages';

import CrossIcon from '-!svg-react-loader?name=CrossIcon!icons/ic-close.svg';

export default function EnableModal({
  closeModal,
  isOpen,
  onFormSubmit,
  oneTimePasswordProps,
  provisioningUri,
  validationErrorsProps,
}) {
  useEffect(() => {
    oneTimePasswordProps.inputRefs[0].current.focus();
  }, [isOpen]);

  return (
    <ModalBox
      isOpen={isOpen}
      mode='flexible'
      onClose={closeModal}
      withFooter={false}
    >
      <div className='tw-flex tw-items-center tw-justify-between tw-p-6'>
        <h2 className='tw-m-0 tw-w-96 tw-text-xl tw-font-semibold tw-text-grey-900'>
          Set up two-factor authentication
        </h2>
        <CrossIcon
          className='tw-block tw-cursor-pointer tw-object-right'
          onClick={closeModal}
        />
      </div>

      <hr className='tw-my-0 tw-w-full' />

      {Object.keys(validationErrorsProps.validationErrors).length > 0 && (
        <ValidationErrorsMessages
          className='tw-m-6 tw-border-1 tw-border-solid tw-border-red-200'
          validationErrors={validationErrorsProps.validationErrors}
        />
      )}

      <div className='tw-p-6 tw-pb-10'>
        <div className='tw-block tw-text-center'>
          {provisioningUri && (
            <QRCode className='tw-h-40 tw-w-40' value={provisioningUri} />
          )}
        </div>

        <p className='tw-text-sm tw-font-small tw-my-5 tw-text-center tw-text-grey-700'>
          To get started, scan this code. Proceed using your authenticator app
          to get the verification code and enter below.
        </p>

        <OneTimePasswordSignInCard
          inputRefs={oneTimePasswordProps.inputRefs}
          onFormPasteCapture={oneTimePasswordProps.handleFormPasteCapture}
          onInputClick={oneTimePasswordProps.handleInputClick}
          onInputKeyUp={oneTimePasswordProps.handleInputKeyUp}
          onSubmit={onFormSubmit}
          otpCode={oneTimePasswordProps.otpCode}
        />
      </div>
    </ModalBox>
  );
}

EnableModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  provisioningUri: PropTypes.string.isRequired,
  oneTimePasswordProps: oneTimePasswordShape,
  validationErrorsProps: validationErrorsShape,
  closeModal: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};
