import React, { useState } from 'react';
import PropTypes from 'prop-types';

const maxList = 3;

export default function ExpandableList({ collection, renderItem }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const isBeyondShowableLimit = collection.length > maxList;

  return (
    <>
      <ul className='p-l-16 m-0'>
        {collection.slice(0, maxList).map(renderItem)}
        {isBeyondShowableLimit &&
          isExpanded &&
          collection.slice(maxList).map(renderItem)}
      </ul>
      {isBeyondShowableLimit && !isExpanded && (
        <a
          class='app-link tw-text-s tw-tracking-wide tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
          href='#0'
          onClick={() => setIsExpanded(true)}
        >{`+ ${collection.length - maxList} others`}</a>
      )}
    </>
  );
}

ExpandableList.propTypes = {
  collection: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
};
