import React, { useState } from 'react';
import PropTypes from 'prop-types';

const moreBreakpoint = 3;

export default function PersonnelTeamDetails({ teams = {} }) {
  const [showAllTeams, setShowAllTeams] = useState(false);
  const teamArray = Object.values(teams);
  const visibleTeams =
    showAllTeams ? teamArray : teamArray.slice(0, moreBreakpoint);

  return (
    <div>
      {teamArray.length > 0 && (
        <div className='tw-mb-6'>
          <ul className='tw-m-0 tw-list-none tw-p-0 tw-text-left'>
            {visibleTeams.map((team) => (
              <li className='tw-mb-2' key={team.id}>
                <span className='tw-inline-flex tw-items-center tw-rounded-[16px] tw-bg-grey-050'>
                  <div className='tw-px-3 tw-py-2 tw-text-s tw-font-normal tw-tracking-wide'>
                    {team.attributes.name}
                  </div>
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
      {teamArray.length > moreBreakpoint && !showAllTeams && (
        <div className='tw-mb-2'>
          <button
            className='app-link tw-bg-transparent tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
            onClick={() => setShowAllTeams(true)}
          >
            Show all ({teamArray.length - moreBreakpoint} more)
          </button>
        </div>
      )}
      {teamArray.length === 0 && (
        <div className='popup__body-message tw-text-grey-500'>
          Not a member of any teams
        </div>
      )}
    </div>
  );
}

PersonnelTeamDetails.propTypes = {
  teams: PropTypes.object,
};
