import React from 'react';
import PropTypes from 'prop-types';

import SubArrowIcon from '-!svg-react-loader?name=SubArrowIcon!icons/sub-arrow.svg';
import classNames from 'classnames';

export default function CountDisplay({
  count,
  countKey,
  dotClassName,
  highlightedKey,
  isSub = false,
  name,
  onClick,
  onKeyHighlight,
  onKeyUnhighlight,
}) {
  const isKeyHighlighted = countKey === highlightedKey;

  return (
    <div
      className={classNames(
        'tw-flex tw-h-8 tw-items-center tw-rounded-lg tw-px-3 tw-py-2 tw-transition-colors tw-duration-200',
        count ? 'tw-cursor-pointer' : 'tw-cursor-default',
        isKeyHighlighted ? 'tw-bg-blue-025' : 'tw-bg-transparent',
      )}
      data-element-name='count-display'
      onClick={count ? onClick : undefined}
      onMouseEnter={() =>
        count ? onKeyHighlight(countKey) : onKeyUnhighlight()
      }
    >
      {isSub && (
        <SubArrowIcon
          className='tw-mr-3 [&_path]:tw-fill-grey-700'
          height={9}
          width={10}
        />
      )}
      <span
        className={classNames(
          'tw-mr-3 tw-inline-flex tw-h-2.5 tw-w-2.5 tw-rounded-full',
          dotClassName,
        )}
      />
      <div className='tw-flex-1 tw-text-s tw-font-medium tw-tracking-wide'>
        {name}
      </div>
      <div className='tw-text-s tw-font-normal tw-tracking-wide'>{count}</div>
    </div>
  );
}

CountDisplay.propTypes = {
  countKey: PropTypes.string.isRequired,
  dotClassName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  isSub: PropTypes.bool,
  highlightedKey: PropTypes.string,
  onKeyHighlight: PropTypes.func.isRequired,
  onKeyUnhighlight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
