import React from 'react';
import PropTypes from 'prop-types';
import voca from 'voca';
import ActionModal from 'components/application/ActionModal';
import ActionModalContent from 'components/application/ActionModalContent';
import ErrorIcon from '-!svg-react-loader?name=ErrorIcon!icons/ic-error-large.svg';

export default function DuplicationFailedModal(props) {
  const { closeModal, isOpen, originalProjectName, ramsSingularName } = props;

  return (
    <React.Fragment>
      <ActionModal closeModal={closeModal} closeable={true} isOpen={isOpen}>
        <ActionModalContent
          bodyIcon={
            <ErrorIcon
              className='[&_path]:tw-fill-red-600'
              height={48}
              width={48}
            />
          }
          bodyText='Please try again or contact support for help.'
          bodyTitle={voca.capitalize(`${ramsSingularName} not created`)}
          closeModal={closeModal}
          closeable={true}
          headerText={`Duplicate ${originalProjectName}`}
        />
      </ActionModal>
    </React.Fragment>
  );
}

DuplicationFailedModal.defaultProps = {
  ramsSingularName: 'project',
};

DuplicationFailedModal.propTypes = {
  originalProjectName: PropTypes.string.isRequired,
  ramsSingularName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
