import React from 'react';
import PropTypes from 'prop-types';
import AddPersonIcon from '-!svg-react-loader?name=AddPersonIcon!icons/addperson-xl.svg';
import FilledButton from 'components/application/buttons/FilledButton';

export default function AddPersonnelSection(props) {
  return (
    <section className='section section--notice tw-border-grey-100 tw-bg-white'>
      <AddPersonIcon
        className='m-b--8 [&_path]:tw-fill-grey-500'
        height={64}
        width={64}
      />
      <h2 className='section__header tw-text-l tw-font-semibold tw-text-grey-900'>
        Add personnel
      </h2>
      <p className='section__content'>
        Add employees and sub-contractors to include on projects.
      </p>
      <p className='section__content'>
        Personnel will not be able to access the HandsHQ app.
      </p>
      <FilledButton color='mint' onClick={props.onAddClick}>
        Add new personnel
      </FilledButton>
    </section>
  );
}

AddPersonnelSection.propTypes = {
  onAddClick: PropTypes.func,
};
