import React, { useEffect } from 'react';

import {
  buildParams,
  buildLocalStorageItem,
} from 'components/trainingRegisterReports/helpers';
import { asyncScrollToTop } from 'components/helpers/scrolling';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import useReportGenerator from 'components/trainingRegisterReports/hooks/useReportGenerator';

import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';
import FilledButton from 'components/application/buttons/FilledButton';

import ValidationErrorsMessages from 'components/validationErrors/components/ValidationErrorsMessages';
import Estimates from 'components/trainingRegisterReports/components/Estimates';
import Filters from 'components/trainingRegisterReports/components/Filters';
import ReportGeneratorModal from 'components/trainingRegisterReports/components/ReportGeneratorModal';

export default function Report(props) {
  const {
    children,
    formValues,
    onEstimatesChange,
    onFilterAddition,
    onFilterChange,
    onFilterDeselection,
    onFilterRemoval,
    onFilterSelection,
    onValidate,
    onValidationErrorsAssignment,
    onValidationErrorsReset,
    onValidationErrorsUnhighlight,
    setLocalStorageItem,
    title,
    tooltip,
    type,
    validationErrors,
  } = props;

  const currentActor = useCurrentActor();
  const [
    reportGenerator,
    startReportGeneration,
    endReportGeneration,
    downloadReport,
  ] = useReportGenerator();

  const validationErrorsKeys = Object.keys(validationErrors);
  const reportGenerationDisabled = validationErrorsKeys.some(
    (key) => validationErrors[key].fieldHighlighted,
  );

  useEffect(() => {
    return () =>
      window.history.replaceState(null, '', window.location.pathname);
  }, []);

  const handleGenerateReport = () => {
    const errors = onValidate();

    onValidationErrorsReset();

    if (Object.keys(errors).length !== 0) {
      asyncScrollToTop().then(() => onValidationErrorsAssignment(errors));
    } else {
      startReportGeneration({
        params: buildParams({
          type,
          formValues,
          currentDivision: currentActor.division,
        }),
      });
      setLocalStorageItem(buildLocalStorageItem({ type, formValues }));
    }
  };

  return (
    <>
      <div className='tw-rounded-t-md tw-border-1 tw-border-b-0 tw-border-solid tw-border-grey-100 tw-bg-white tw-p-10'>
        {validationErrorsKeys.length !== 0 && (
          <div className='tw-mb-10'>
            <ValidationErrorsMessages validationErrors={validationErrors} />
          </div>
        )}
        <div className='tw-mb-10 tw-flex tw-items-center'>
          <h2 className='tw-m-0 tw-mr-2 tw-text-xl tw-font-semibold tw-tracking-tight'>
            {title}
          </h2>
          <Tooltip placement='top' tooltip={tooltip} trigger='hover'>
            <CircleQuestion />
          </Tooltip>
        </div>
        <div className='tw-mb-10 tw-max-w-[320px]'>
          {children}
          <Estimates
            estimates={formValues.estimates}
            onChange={onEstimatesChange}
          />
        </div>
        <div className='tw-max-w-[460px]'>
          <Filters
            filters={formValues.filters}
            onAddFilter={onFilterAddition}
            onChange={onFilterChange}
            onDeselect={onFilterDeselection}
            onFocus={onValidationErrorsUnhighlight}
            onRemoveFilter={(filter) => {
              onFilterRemoval(filter);
              onValidationErrorsUnhighlight(filter);
            }}
            onSelect={onFilterSelection}
            validationErrors={validationErrors}
          />
        </div>
      </div>
      <div className='tw-flex tw-justify-end tw-rounded-b-md tw-rounded-t-none tw-border-1 tw-border-solid tw-border-grey-100 tw-bg-white tw-p-5'>
        <FilledButton
          color='mint'
          disabled={reportGenerationDisabled}
          onClick={handleGenerateReport}
        >
          Generate report
        </FilledButton>
      </div>
      <ReportGeneratorModal
        isOpen={reportGenerator.isOpen}
        onCancel={endReportGeneration}
        onDownload={downloadReport}
        onTryAgain={handleGenerateReport}
        progress={reportGenerator.progress}
        type={type}
      />
    </>
  );
}
