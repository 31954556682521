import React from 'react';
import PropTypes from 'prop-types';

import ModalBox from 'components/application/ModalBox';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function ProjectCoshhDestroyModal(props) {
  const { currentCoshhDocument, deleteCoshhDocument, isOpen, setIsOpen } =
    props;

  const closeModalBox = () => {
    setIsOpen(false);
  };

  const handleDeleteClick = () => {
    closeModalBox();
    deleteCoshhDocument();
  };

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton
        className='m-r-12'
        color='blue'
        onClick={closeModalBox}
        size='md'
      >
        Cancel
      </TextButton>
      <FilledButton color='red' onClick={handleDeleteClick}>
        Delete
      </FilledButton>
    </div>
  );

  return (
    <ModalBox
      customFooter={customFooter}
      isOpen={isOpen}
      mode='flexible'
      onClose={closeModalBox}
      usingSlimDimensions={true}
      usingStandardDimensions={false}
    >
      <div className='modalbox-body p-32'>
        <h2 className='modalbox-header__sub-title p-b-16 tw-font-inter tw-text-l tw-font-semibold tw-tracking-auto tw-text-grey-900'>
          Are you sure you want to delete{' '}
          {currentCoshhDocument && currentCoshhDocument.title}?
        </h2>
        <div>The details will be lost and this action can’t be undone.</div>
      </div>
    </ModalBox>
  );
}

ProjectCoshhDestroyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  currentCoshhDocument: PropTypes.object,
  deleteCoshhDocument: PropTypes.func.isRequired,
};
