import moment from 'moment';

$(document).ready(function () {
  if ($('#project-review-dates-settings').length > 0) {
    // elements
    const reviewFrequencyInput = $('#review-frequency input');
    const reviewFirstReminder = $('#review-first-reminder input');
    const reviewFirstReminderHidden = $(
      '#review-first-reminder input[type=hidden]',
    );
    const reviewSecondReminder = $('#review-second-reminder input');
    const reviewSecondReminderHidden = $(
      '#review-second-reminder input[type=hidden]',
    );
    const reviewsEnabled = $('#project-reviews-enabled input');
    const reviewsEnabledCheckedOnLoad = reviewsEnabled.is(':checked');
    const hiddenReviewIntervalSelect = $(
      '#scheduled-review-interval input[type=hidden]',
    );
    const visibleReviewIntervalSelect = $('.collection-select input');

    const settings = $('#project-review-settings');
    const save = $('#next');

    const reviewFrequencyWarning = $('#review-frequency-warning');
    const reviewReminderWarningLonger = $('#review-reminder-warning-longer');
    const reviewReminderWarningSame = $('#review-reminder-warning-same');
    const removeReviewWarning = $('#remove-review-warning');

    // on load actions
    if (!reviewsEnabled.is(':checked')) {
      settings.addClass('hide');
    }

    // binding
    reviewFirstReminder.on('change', firstReminderFrequencyCheck);
    visibleReviewIntervalSelect.on('blur', firstReminderFrequencyCheck);
    reviewFrequencyInput.on('change', firstReminderFrequencyCheck);

    reviewFirstReminder.on('change', secondReminderFrequencyCheck);
    reviewSecondReminder.on('change', secondReminderFrequencyCheck);

    reviewsEnabled.on('change', showSettings);
    reviewsEnabled.on('change', removeReviewWarningCheck);

    save.on('click', makeChangesBeforeSave);

    // change handlers
    function makeChangesBeforeSave() {
      reviewFirstReminderHidden.val(`P${reviewFirstReminder.val()}D`);
      reviewSecondReminderHidden.val(`P${reviewSecondReminder.val()}D`);
      hiddenReviewIntervalSelect.val(
        hiddenReviewIntervalSelect.val().toLowerCase(),
      );
    }

    function removeReviewWarningCheck() {
      if (reviewsEnabledCheckedOnLoad && !reviewsEnabled.is(':checked')) {
        removeReviewWarning.removeClass('hide');
      } else if (reviewsEnabled.is(':checked')) {
        removeReviewWarning.addClass('hide');
      }
    }

    function showSettings() {
      if (reviewsEnabled.is(':checked')) {
        settings.removeClass('hide');
      } else if (!reviewsEnabled.is(':checked')) {
        settings.addClass('hide');
      }
    }

    function firstReminderFrequencyCheck() {
      const inputtedReviewIntervalSelect = hiddenReviewIntervalSelect.val();
      const inputtedReviewFrequency = reviewFrequencyInput.val();
      const inputtedReviewReminder = reviewFirstReminder.val();

      if (inputtedReviewFrequency.length == 0) return;

      const calculatedFrequency = moment().add(
        inputtedReviewFrequency,
        inputtedReviewIntervalSelect,
      );
      const calculatedReminder = moment().add(inputtedReviewReminder, 'day');

      if (calculatedReminder.isAfter(calculatedFrequency)) {
        reviewFrequencyWarning.removeClass('hide');
      } else if (!calculatedReminder.isAfter(calculatedFrequency)) {
        reviewFrequencyWarning.addClass('hide');
      }
    }

    function secondReminderFrequencyCheck() {
      const inputtedFirstReminder = reviewFirstReminder.val();
      const inputtedSecondReminder = reviewSecondReminder.val();

      if (parseInt(inputtedFirstReminder) < parseInt(inputtedSecondReminder)) {
        reviewReminderWarningSame.addClass('hide');
        reviewReminderWarningLonger.removeClass('hide');
      } else if (
        parseInt(inputtedFirstReminder) == parseInt(inputtedSecondReminder)
      ) {
        reviewReminderWarningLonger.addClass('hide');
        reviewReminderWarningSame.removeClass('hide');
      } else if (
        parseInt(inputtedFirstReminder) > parseInt(inputtedSecondReminder)
      ) {
        reviewReminderWarningLonger.addClass('hide');
        reviewReminderWarningSame.addClass('hide');
      }
    }
  }
});
