import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TableCell from 'components/application/TableCell';
import PersonnelTrainingStatusIndicator from 'components/personnel/PersonnelTrainingStatusIndicator';
import { personDisplayName } from 'components/helpers/users';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import useWindowStorage from 'components/hooks/useWindowStorage';
import { resourceShape } from 'components/helpers/serialisableResources';
import ProfilePhotoDisplay from 'components/application/ProfilePhotoDisplay';
import Tooltip from 'components/application/Tooltip';
import UnderProgress from '-!svg-react-loader?name=UnderProgress!icons/under-progress.svg';

export default function PersonnelRow({
  division,
  isActionsColumnVisible,
  isActionsCountVisible,
  isArchived,
  isProcessingProfilePhoto,
  onRowClick,
  onUnarchiveClick,
  personnel,
  profilePhoto,
  roles,
  subcontractor,
  teams,
}) {
  const currentActor = useCurrentActor();
  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();
  const [getStoredPersonnel, _setStoredPersonnel, removeStoredPersonnel] =
    useWindowStorage('personnelTab|personnelId', {
      store: window.sessionStorage,
    });

  const isViewingOwnProfile =
    currentActor.user.id === personnel.relationships.user.data?.id;

  const companyDisplayName = () => {
    const assignedCompany = subcontractor || division;
    return assignedCompany ? assignedCompany.attributes.name : '';
  };

  const handleRowClick = () => {
    onRowClick(personnel.id);
  };

  const rowRef = useRef(null);

  useEffect(() => {
    const storedPersonnelId = getStoredPersonnel();

    if (storedPersonnelId === personnel.id && rowRef.current) {
      rowRef.current.scrollIntoView({ block: 'center' });
      removeStoredPersonnel();
    }
  }, [personnel.id]);

  const actionsCount =
    isActionsColumnVisible && isActionsCountVisible ?
      personnel.meta.trainingRequiringEvidenceCount +
      personnel.meta.coursesRequiringBookingCount +
      (isViewingOwnProfile &&
        currentActor.isAllowedFeature('e_learning') &&
        personnel.meta.eLearningBookingsCount)
    : 0;

  return (
    <tr
      className='handshq__tr--clickable fh-49 hover:tw-bg-grey-025'
      ref={rowRef}
    >
      {currentActor.isAllowedFeature('training_register') && (
        <TableCell justifyContent='center' onClick={handleRowClick}>
          <PersonnelTrainingStatusIndicator
            status={
              isArchived ? 'archived' : personnel.attributes.trainingStatus
            }
          />
        </TableCell>
      )}
      <TableCell onClick={handleRowClick}>
        <div className='tw-mr-2'>
          {isProcessingProfilePhoto ?
            <Tooltip
              placement='bottom'
              tooltip='Update in progress'
              trigger='hover'
            >
              <div className='tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-grey-200'>
                <div
                  className='tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center'
                  data-element-name='under-progress'
                >
                  <UnderProgress />
                </div>
              </div>
            </Tooltip>
          : <ProfilePhotoDisplay
              componentSize='sm'
              photoUrl={profilePhoto?.links?.croppedUrl}
              profileInitials={`${personnel.attributes.firstName[0]}${personnel.attributes.lastName[0]}`}
            />
          }
        </div>

        <span className='truncated-text-container tw-text-m tw-font-medium tw-text-grey-900'>
          {personDisplayName(personnel.attributes)}
        </span>
      </TableCell>
      <TableCell onClick={handleRowClick}>
        <span className='truncated-text-container tw-text-m'>
          {roles.map((role) => role.attributes.position).join(' + ')}
        </span>
      </TableCell>
      {trainingRegisterResourceManagementContext.hasTeamViewableAccess && (
        <TableCell onClick={handleRowClick}>
          <span className='truncated-text-container tw-text-m'>
            {[...teams]
              .map((team) => team.attributes.name)
              .sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
              .join(', ')}
          </span>
        </TableCell>
      )}
      <TableCell onClick={handleRowClick}>
        <span className='truncated-text-container tw-text-m'>
          {companyDisplayName()}
        </span>
      </TableCell>
      {isActionsColumnVisible &&
        (isArchived ?
          <TableCell>
            <a
              className='app-link layout--ib va-middle tw-text-m tw-font-medium tw-tracking-auto tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
              onClick={() => onUnarchiveClick({ personnelId: personnel.id })}
            >
              Unarchive
            </a>
          </TableCell>
        : <TableCell justifyContent='center'>
            {isActionsCountVisible && actionsCount > 0 && (
              <div className='tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-sm tw-bg-cyan-025 tw-text-cyan-800'>
                {actionsCount}
              </div>
            )}
          </TableCell>)}
    </tr>
  );
}

PersonnelRow.propTypes = {
  personnel: PropTypes.object.isRequired,
  division: resourceShape(['company', 'assignableCompany']).isRequired,
  subcontractor: resourceShape(['company', 'assignableCompany']),
  roles: PropTypes.arrayOf(resourceShape('companyRole')).isRequired,
  teams: PropTypes.arrayOf(resourceShape('team')).isRequired,
  isArchived: PropTypes.bool,
  isActionsColumnVisible: PropTypes.bool.isRequired,
  isActionsCountVisible: PropTypes.bool.isRequired,
  onUnarchiveClick: PropTypes.func,
  onRowClick: PropTypes.func.isRequired,
  profilePhoto: PropTypes.object,
  isProcessingProfilePhoto: PropTypes.bool,
};
