import HandshqApp from 'handshq-app/handshq-app';

import RemoveTradeModal from './remove-trade-modal';

function TradeCheckboxes({ selector }) {
  const _this = this;
  this.checkboxes = $(selector);
  this.currentCheckbox = null;

  this.removeTradeModal = new RemoveTradeModal({
    selector: '#remove_trade_modal',
    onHidden: () => _this.setCheckboxesDisabled(false),
    onConfirm: () => _this.destroyProjectTrade(),
  });

  this.setup = () => {
    _this.checkboxes.on('click', _this.handleToggle);
    _this.removeTradeModal.setup();
  };

  this.setCheckboxesDisabled = (boolean) =>
    _this.checkboxes.prop('disabled', boolean);

  this.setCurrentCheckboxChecked = (boolean) =>
    _this.currentCheckbox.prop('checked', boolean);

  this.handleToggle = (event) => {
    _this.currentCheckbox = $(event.target);
    _this.setCheckboxesDisabled(true);

    if (_this.currentCheckbox.prop('checked')) {
      _this.createProjectTrade();
    } else {
      if (_this.currentCheckbox.data('initiallyChecked')) {
        event.preventDefault();
        _this.hydrateAndOpenRemoveTradeModal();
      } else {
        _this.destroyProjectTrade();
      }
    }
  };

  this.handleError = () => {
    HandshqApp.GlobalToastRackAPI.addInedibleToast();
    _this.removeTradeModal.setModalShown(false);
    _this.setCheckboxesDisabled(false);
  };

  this.hydrateAndOpenRemoveTradeModal = () => {
    const projectTradeId = _this.currentCheckbox.data('projectTradeId');

    if (projectTradeId) {
      $.ajax({
        url: `/project_trades/${projectTradeId}/method_texts`,
        type: 'GET',
        success: (response) => {
          const categoryName = _this.currentCheckbox.prop('name');
          const methodStatements = response.data.reduce(
            (accumulator, member) => {
              accumulator[
                member.attributes.hasUniqueKey ? 'unique' : 'nonUnique'
              ].push(member);
              return accumulator;
            },
            { unique: [], nonUnique: [] },
          );

          _this.removeTradeModal.hydrate({ categoryName, methodStatements });
          _this.removeTradeModal.setModalShown(true);
        },
        error: _this.handleError,
      });
    }
  };

  this.createProjectTrade = () => {
    const tradeId = _this.currentCheckbox.val();
    const projectId = _this.currentCheckbox.data('projectId');

    if (tradeId && projectId) {
      $.ajax({
        url: '/project_trades',
        type: 'POST',
        data: {
          project_trade: {
            project_id: projectId,
            trade_id: tradeId,
          },
        },
        error: () => {
          _this.handleError();
          _this.setCurrentCheckboxChecked(false);
        },
      });
    }
  };

  this.destroyProjectTrade = () => {
    const projectTradeId = _this.currentCheckbox.data('projectTradeId');

    if (projectTradeId) {
      $.ajax({
        url: `/project_trades/${projectTradeId}`,
        type: 'DELETE',
        success: () => {
          _this.currentCheckbox.prop('checked', false);
          _this.currentCheckbox.data('initiallyChecked', false);
          _this.removeTradeModal.setModalShown(false);
        },
        error: () => {
          _this.handleError();
          _this.setCurrentCheckboxChecked(true);
        },
      });
    }
  };
}

export default TradeCheckboxes;
