import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/application/Tooltip';
import classNames from 'classnames';

export default function RadioField({
  checked,
  className,
  disabled,
  label,
  labelId,
  name,
  onChange,
  tooltip,
  value,
}) {
  return (
    <div className={classNames('radio-container tooltip-parent', className)}>
      <label
        className={classNames(
          'radio-container__label tw-font-normal',
          checked && 'radio-container__label--active',
        )}
        id={labelId}
      >
        <input
          checked={checked}
          disabled={disabled}
          name={name}
          onChange={onChange}
          type='radio'
          value={value}
        />
        <div
          className={classNames(
            'radio-container__outer-circle tw-peer/outer-circle tw-cursor-pointer tw-bg-white',
            checked ?
              'tw-border-blue-400 hover:tw-border-blue-500 hover:tw-bg-blue-025'
            : 'tw-border-grey-300 hover:tw-border-blue-300',
            disabled && 'tw-opacity-30',
          )}
        ></div>
        <span
          className={classNames(
            'radio-container__checkmark after:tw-absolute after:tw-m-[6px] after:tw-h-2 after:tw-w-2 after:tw-cursor-pointer after:tw-rounded-full after:tw-bg-blue-400 after:tw-content-[""] peer-hover/outer-circle:after:tw-bg-blue-500',
            checked ? 'after:tw-block' : 'after:tw-hidden',
            disabled && 'tw-opacity-30',
          )}
        ></span>
        <div className='m-l-28 radio-container__text'>{label}</div>
      </label>
      {disabled && (
        <Tooltip
          className='tooltip-dark--max-w-xxs'
          placement='left'
          tooltip={tooltip}
          trigger='hover'
        />
      )}
    </div>
  );
}

RadioField.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
};
