import React from 'react';
import PropTypes from 'prop-types';
import TextArea from 'components/application/TextArea';
import DatePickerField from 'components/application/DatePickerField';

export default function BookingDetailsForm({
  currentBooking,
  dateLabel,
  onDateChange,
  onInputChange,
  removeErrorStyling,
  requestError,
}) {
  return (
    <>
      <div className='form-container'>
        <div className='flex flex--justify-content__flex-start'>
          <div className='m-r-12'>
            <DatePickerField
              earliestDate={new Date()}
              fieldError={requestError.validationErrors.date}
              isRequired={true}
              label={dateLabel}
              name='date'
              onChange={onDateChange}
              removeErrorStyling={removeErrorStyling}
              value={currentBooking.date}
            />
          </div>
        </div>
        <TextArea
          label='Booking notes'
          name='notes'
          onChange={onInputChange}
          tooltip='Booking notes are included in the email'
          value={currentBooking.notes}
        />
      </div>
    </>
  );
}

BookingDetailsForm.propTypes = {
  requestError: PropTypes.object,
  currentBooking: PropTypes.shape({
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    notes: PropTypes.string,
    personnelIds: PropTypes.array,
  }).isRequired,
  onDateChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  removeErrorStyling: PropTypes.func,
  dateLabel: PropTypes.string,
};
