import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ActionLink from 'components/buttons/ActionLink';

import UnsavedChangesModal from 'components/project/UnsavedChangesModal';

export default function SequenceOfOperationsFooter(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [action, setAction] = useState('');

  // find a better way to do this!
  const actionButton = () => {
    switch (action) {
      case 'Next':
        return (
          <ActionLink
            disableAfterClick
            linkText='Yes, leave page'
            styleClasses='tw-apply-filled-button tw-apply-filled-button--md tw-apply-filled-button--red tw-apply-filled-button--on-light'
            url={`method${props.isReviewing ? '?review=true' : ''}`}
          />
        );
      case 'Previous':
        return (
          <ActionLink
            disableAfterClick
            linkText='Yes, leave page'
            styleClasses='tw-apply-filled-button tw-apply-filled-button--md tw-apply-filled-button--red tw-apply-filled-button--on-light'
            url={`risk_assessment?previous=true${props.isReviewing ? '&review=true' : ''}`}
          />
        );
      case 'Save and exit':
        return (
          <ActionLink
            dataMethod={'put'}
            disableAfterClick
            linkText='Yes, leave page'
            rel={'no-follow'}
            styleClasses='tw-apply-filled-button tw-apply-filled-button--md tw-apply-filled-button--red tw-apply-filled-button--on-light'
            url={`/projects/${props.projectId}?save=true${props.isReviewing ? '&review=true' : ''}`}
          />
        );
    }
  };

  const openModal = (action) => {
    setAction(action);
    setModalIsOpen(true);
  };

  return (
    <React.Fragment>
      <PortaledActionButtons direction={'left'}>
        <ActionLink
          disableAfterClick
          linkText={'Previous'}
          openUnsavedChangesModal={openModal}
          performCheck={props.anyUnsaved}
          styleClasses={
            'tw-apply-outlined-button tw-apply-outlined-button--md tw-apply-outlined-button--blue-grey tw-apply-outlined-button--on-dark nav-button'
          }
          url={`risk_assessment?previous=true${props.isReviewing ? '&review=true' : ''}`}
        />
      </PortaledActionButtons>
      <PortaledActionButtons direction={'right'}>
        <ActionLink
          disableAfterClick
          linkText={'Next'}
          openUnsavedChangesModal={openModal}
          performCheck={props.anyUnsaved}
          styleClasses={
            'tw-apply-outlined-button tw-apply-outlined-button--md tw-apply-outlined-button--blue-grey tw-apply-outlined-button--on-dark nav-button m-r-12'
          }
          url={`method${props.isReviewing ? '?review=true' : ''}`}
        />
        <ActionLink
          dataMethod={'put'}
          disableAfterClick
          linkText={'Save and exit'}
          openUnsavedChangesModal={openModal}
          performCheck={props.anyUnsaved}
          rel={'no-follow'}
          styleClasses={
            'tw-apply-filled-button tw-apply-filled-button--md tw-apply-filled-button--mint tw-apply-filled-button--on-dark nav-button'
          }
          url={`/projects/${props.projectId}?save=true${props.isReviewing ? '&review=true' : ''}`}
        />
      </PortaledActionButtons>
      {props.anyUnsaved && modalIsOpen && (
        <UnsavedChangesModal
          actionButton={actionButton()}
          body={<p>Any unsaved changes will be lost</p>}
          header={<strong>Are you sure you want to leave the page?</strong>}
          isOpen={modalIsOpen}
          onClose={() => {
            setModalIsOpen(false);
          }}
        />
      )}
    </React.Fragment>
  );
}

const PortaledActionButtons = (props) => {
  const buttonLocation = document.getElementsByClassName(
    `project-actions-${props.direction}`,
  );
  return ReactDOM.createPortal(props.children, buttonLocation[0]);
};
