import React from 'react';
import { components } from 'react-select';
import AsyncCreatable from 'react-select/async-creatable';
import _ from 'lodash';

import Circle from 'components/application/Circle';
import {
  NoOptionsMessage,
  ClearIndicator,
  DropdownIndicator,
} from 'components/application/CollectionSelect';
import ProfilePhotoDisplay from 'components/application/ProfilePhotoDisplay';
import { personDisplayName } from 'components/helpers/users';
import { validEmailRegex } from 'constants/regex';

const invalidReasonDisplayText = {
  userExists: 'A user with this email address already exists',
  deletedUserExists:
    'This user has previously been deleted. If you still want to add this user, please contact us via the chat box.',
};

export default function SearchImportablePersonnelField({
  isUserEmail,
  onPersonnelSelection,
  removeErrorStyling,
}) {
  // mix of callback and promise approach, callback approach seems iffy to test
  const loadPersonnel = React.useCallback(
    _.debounce(
      (inputValue, callback) => {
        if (isUserEmail && isUserEmail(inputValue)) {
          onPersonnelSelection({ isNotDerived: true, value: inputValue });
        } else {
          axios
            .get(searchPersonnelPath(inputValue))
            .then((response) => parsePersonnelResponseToOptions(response))
            .then((options) => callback(options));
        }
      },
      500,
      { maxWait: 1000 },
    ),
    [],
  );

  return (
    <>
      <label className="collection-select__label collection-select__required tw-font-medium after:tw-absolute after:tw-right-0 after:tw-font-normal after:tw-text-grey-500 after:tw-content-['Required']">
        Email address
      </label>
      <AsyncCreatable
        cacheOptions={true}
        className={'collection-select__select-container'}
        classNamePrefix={'collection-select'}
        components={{
          NoOptionsMessage,
          ClearIndicator,
          DropdownIndicator,
          Option,
        }}
        id={'user_email'}
        isOptionDisabled={isOptionDisabled}
        loadOptions={loadPersonnel}
        onChange={onPersonnelSelection}
        onFocus={() => {
          removeErrorStyling({ target: { name: 'email' } });
        }}
        placeholder={''}
        value={null}
      />
    </>
  );
}

// helpers

function isOptionDisabled(option) {
  return (
    option.invalidReason ||
    (option.__isNew__ && !(option.value && validEmailRegex.test(option.value)))
  );
}

function parsePersonnelResponseToOptions(response) {
  let mapped;
  if (response.data.meta.blockingReason) {
    mapped = [{ invalidReason: response.data.meta.blockingReason }];
  } else {
    mapped = response.data.data.map((personnel) => ({
      label: personnel,
      value: {
        personnel,
        profilePhoto: response.data.included.find(
          (inclusion) =>
            inclusion.type === 'profilePhoto' &&
            inclusion.relationships.personnel.data.id === personnel.id,
        ),
      },
    }));
  }
  return mapped;
}

function searchPersonnelPath(search) {
  return `/importable_personnel?search=${search}`;
}

// Custom Options

function Option(props) {
  if (
    props.options.some((opt) => opt.invalidReason) &&
    !props.data.invalidReason
  ) {
    return null;
  }
  return (
    <components.Option {...props}>
      {props.data.__isNew__ ?
        <CreateOption isDisabled={props.isDisabled} value={props.data.value} />
      : props.data.invalidReason ?
        <InvalidOption
          reason={invalidReasonDisplayText[props.data.invalidReason]}
        />
      : <PersonnelOption
          personnel={props.data.value.personnel}
          profilePhoto={props.data.value.profilePhoto}
        />
      }
    </components.Option>
  );
}

const PersonnelOption = ({ personnel, profilePhoto }) => {
  return (
    <span className='l-if flex--vertically-centered'>
      <ProfilePhotoDisplay
        componentSize='sm'
        photoUrl={profilePhoto?.links?.croppedUrl}
        profileInitials={`${personnel.attributes.firstName[0]}${personnel.attributes.lastName[0]}`}
      />
      <span className='m-l-12'>
        <div className='tw-font-medium tw-text-grey-900'>
          {personnel.attributes.email}
        </div>
        <div className='tw-text-s tw-font-normal tw-tracking-wide'>
          {personDisplayName(personnel.attributes)}
        </div>
      </span>
    </span>
  );
};

const CreateOption = ({ isDisabled, value }) => {
  return (
    <span className='l-if flex--vertically-centered'>
      <Circle
        className={`tw-text-white ${isDisabled ? 'tw-bg-blue-200' : 'tw-bg-blue-400'}`}
      >
        <span className='m-l-1 m-t--2 tw-text-xl tw-font-semibold tw-tracking-tight'>
          +
        </span>
      </Circle>
      <span className='m-l-12'>
        Add "<span className='tw-font-medium'>{value}</span>"
      </span>
    </span>
  );
};

function InvalidOption({ reason }) {
  return (
    <span className='l-if flex--vertically-centered'>
      <Circle className='tw-bg-red-025 tw-text-white'>
        <span className='tw-font-medium tw-text-red-800'>X</span>
      </Circle>
      <span className='m-l-12 tw-text-red-800'>{reason}</span>
    </span>
  );
}
