import React from 'react';
import PropTypes from 'prop-types';

import { resourceShape } from 'components/helpers/serialisableResources';
import { courseTrainingStatusOrdering } from 'components/helpers/resources/training';
import { sortAlphabetically, sortByDate } from 'components/helpers/arrays';

import Table from 'components/application/Table';
import TrainingRow from 'components/mobileTrainingProfile/components/homeScreen/TrainingRow';

export default function AdditionalTrainingTable({
  attachmentsGroupedByTrainingId,
  bookingsIndexedByCourseId,
  courses,
  onViewCourseDetailsClick,
  registrationsIndexedByCourseId,
  trainingGroupedByCourseId,
  userCoursesIndexedByCourseId,
}) {
  const rows = courses.reduce((acc, course) => {
    const latestTraining =
      (trainingGroupedByCourseId[course.id] &&
        trainingGroupedByCourseId[course.id][0]) ||
      null;
    const attachments =
      attachmentsGroupedByTrainingId[latestTraining?.id] || [];
    const booking = bookingsIndexedByCourseId[course.id];
    const registration = registrationsIndexedByCourseId[course.id];

    const userCourse = userCoursesIndexedByCourseId[course.id];
    const isRequired = userCourse && userCourse.attributes.required;

    const orderingBand = courseTrainingStatusOrdering({
      course,
      isRequired,
      training: latestTraining,
    });

    acc.push({
      course,
      latestTraining,
      attachments,
      booking,
      registration,
      isRequired,
      orderingBand,
    });
    return acc;
  }, []);

  const orderedRows = rows.sort((a, b) => {
    const sortByOrderingBand = (a, b) => a.orderingBand - b.orderingBand;
    const orderingSort = sortByOrderingBand(a, b);
    if (orderingSort !== 0) {
      return orderingSort;
    }
    const dateSort = sortByDate(
      a.latestTraining && a.latestTraining.attributes.expiryDate,
      b.latestTraining && b.latestTraining.attributes.expiryDate,
    );
    if (dateSort !== 0) {
      return dateSort;
    }
    return sortAlphabetically(
      a.course.attributes.name,
      b.course.attributes.name,
    );
  });

  return (
    <Table
      headers={
        <tr>
          <th className='tw-px-9 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Courses
          </th>
        </tr>
      }
      rows={orderedRows.map((row) => {
        return (
          <TrainingRow
            attachments={row.attachments}
            booking={row.booking}
            course={row.course}
            isRequired={row.isRequired}
            key={`additionalCourseTraining--${row.course.id}`}
            latestTraining={row.latestTraining}
            onViewCourseDetailsClick={onViewCourseDetailsClick}
            registration={row.registration}
          />
        );
      })}
      tableClassName='tw-border-t-0'
    />
  );
}

AdditionalTrainingTable.propTypes = {
  courses: PropTypes.arrayOf(resourceShape('course')).isRequired,
  userCoursesIndexedByCourseId: PropTypes.object.isRequired,
  bookingsIndexedByCourseId: PropTypes.object.isRequired,
  registrationsIndexedByCourseId: PropTypes.object.isRequired,
  trainingGroupedByCourseId: PropTypes.object.isRequired,
  attachmentsGroupedByTrainingId: PropTypes.object.isRequired,
  onViewCourseDetailsClick: PropTypes.func.isRequired,
};
