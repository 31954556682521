import React from 'react';
import PropTypes from 'prop-types';

import { useRamsAnalyticsDashboardContext } from 'components/contexts/RamsAnalyticsDashboardContext';

import useSize from 'components/hooks/useSize';
import useBodyOverflowToggle from 'components/hooks/useBodyOverflowToggle';
import useEscapeKey from 'components/hooks/useEscapeKey';

import CloseIcon from '-!svg-react-loader?name=CloseIcon!icons/ic-close.svg';
import ProjectsTable from 'components/projects/components/ProjectsTable';
import classNames from 'classnames';

export default function ProjectsModal({
  closeModal,
  hasNextPage,
  isOpen,
  onFetchNextPage,
  onProjectClick,
  projects = [],
  subTitle,
  title,
}) {
  const ramsAnalyticsDashboardContext = useRamsAnalyticsDashboardContext();

  const { isResizing, size: bodySize } = useSize(document.body);

  useBodyOverflowToggle(isOpen);
  useEscapeKey(closeModal, [isOpen]);

  const handleModalWrapperClick = (event) => {
    if (event.target.dataset.elementName === 'projects-modal-modal-wrapper')
      closeModal();
  };

  const handleTableWrapperScroll = ({ target }) => {
    const isScrollBottom =
      Math.abs(target.scrollHeight - target.clientHeight - target.scrollTop) <
      1;

    if (isScrollBottom && hasNextPage) onFetchNextPage();
  };

  return (
    <div className={isOpen ? 'tw-block' : 'tw-hidden'}>
      <div className='tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[9998] tw-bg-grey-900 tw-opacity-75'></div>
      <div
        className={classNames(
          'tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[9999] tw-min-w-[594px] tw-justify-center tw-p-20',
          isResizing ? 'tw-hidden' : 'tw-flex',
        )}
        data-element-name='projects-modal-modal-wrapper'
        onClick={handleModalWrapperClick}
      >
        <div className='tw-flex tw-h-full tw-max-h-[640px] tw-w-full tw-max-w-[1140px] tw-flex-col tw-overflow-hidden tw-rounded-2xl tw-bg-white'>
          <div className='tw-flex tw-w-full tw-items-center tw-justify-between tw-px-5 tw-py-4'>
            <div>
              <div className='tw-mb-1 tw-text-l tw-font-semibold tw-tracking-auto tw-text-grey-900'>
                {title}
              </div>
              <div>{subTitle}</div>
            </div>
            <CloseIcon
              className='tw-cursor-pointer tw-rounded-md tw-border-1 tw-border-solid tw-border-white tw-bg-white hover:tw-border-grey-100 [&_polygon]:tw-fill-grey-700'
              data-element-name='projects-modal-close'
              height={24}
              onClick={closeModal}
              width={24}
            />
          </div>
          <div
            className='tw-h-full tw-w-full tw-overflow-y-auto tw-overflow-x-hidden'
            data-element-name='projects-modal-table-wrapper'
            onScroll={handleTableWrapperScroll}
          >
            <ProjectsTable
              bodySize={bodySize}
              isResizing={isResizing}
              onProjectClick={onProjectClick}
              projects={projects}
              ramsSingularName={ramsAnalyticsDashboardContext.ramsSingularName}
              withApproval={ramsAnalyticsDashboardContext.isApprovalsEnabled}
              withClient={ramsAnalyticsDashboardContext.isClientEnabled}
              withReviewDate={
                ramsAnalyticsDashboardContext.isProjectReviewDatesEnabled
              }
              withRiskAssessments={
                ramsAnalyticsDashboardContext.isRiskRegisterEnabled
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

ProjectsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  projects: PropTypes.array,
  hasNextPage: PropTypes.bool,
  onFetchNextPage: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onProjectClick: PropTypes.func.isRequired,
};
