import React from 'react';
import PropTypes from 'prop-types';
import ActionModal from 'components/application/ActionModal';
import TextField from 'components/application/TextField';
import ActionButton from 'components/buttons/ActionButton';
import TextButton from 'components/application/buttons/TextButton';
import ErrorMessage from 'components/application/ErrorMessage';

export default function DuplicationRequestedModal(props) {
  const {
    closeModal,
    duplicateProject,
    fieldError,
    isOpen,
    onChange,
    onDuplication,
    originalProjectName,
    ramsSingularName,
    removeErrorStyling,
  } = props;

  return (
    <>
      <ActionModal closeModal={closeModal} closeable={true} isOpen={isOpen}>
        <div className='action-modal__box tw-bg-white'>
          <section className='action-modal__header truncated-text-container tw-border-grey-100 tw-text-l tw-font-semibold tw-text-grey-900'>
            {`Duplicate ${originalProjectName}`}
          </section>
          <section className='action-modal__form-body tw-border-grey-100'>
            <div className='m-b-16'>
              {`Give the new ${ramsSingularName} a name to create a duplicate.`}
            </div>
            {fieldError.error.detail && (
              <ErrorMessage
                isFallback={false}
                validationErrors={{ projectName: fieldError }}
              />
            )}
            <TextField
              autoFocus={true}
              fieldError={fieldError}
              inputClassName='tw-px-3 tw-py-[9px]'
              isRequired={true}
              label={`Name of new ${ramsSingularName}`}
              name='projectName'
              onChange={onChange}
              removeErrorStyling={removeErrorStyling}
              value={duplicateProject.projectName}
            />
          </section>
          <section className='action-modal__form-footer'>
            <>
              <TextButton
                className='m-r-12'
                color='blue'
                onClick={closeModal}
                size='md'
              >
                Cancel
              </TextButton>
              <ActionButton
                buttonText={`Duplicate ${ramsSingularName}`}
                disabled={fieldError.fieldHighlighted}
                onClick={onDuplication}
                styleClasses='tw-apply-filled-button tw-apply-filled-button--md tw-apply-filled-button--mint tw-apply-filled-button--on-light confirm_project_duplicate'
              />
            </>
          </section>
        </div>
      </ActionModal>
    </>
  );
}

DuplicationRequestedModal.defaultProps = {
  ramsSingularName: 'project',
};

DuplicationRequestedModal.propTypes = {
  originalProjectName: PropTypes.string.isRequired,
  duplicateProject: PropTypes.object.isRequired,
  ramsSingularName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDuplication: PropTypes.func.isRequired,
  fieldError: PropTypes.object,
  removeErrorStyling: PropTypes.func,
};
