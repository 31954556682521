import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { oneTimePasswordShape } from 'components/hooks/useOneTimePassword';
import { validationErrorsShape } from 'components/hooks/useValidationErrors';

import ModalBox from 'components/application/ModalBox';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import ValidationErrorsMessages from 'components/validationErrors/components/ValidationErrorsMessages';
import OneTimePasswordSignInCard from 'components/users/twoFactorAuthentication/OneTimePasswordSignInCard';

export default function DisableModal({
  closeModal,
  isOpen,
  onFormSubmit,
  oneTimePasswordProps,
  validationErrorsProps,
}) {
  const [disableButton, setDisableButton] = useState(true);

  const handleFormValidate = (otpCode) => {
    // check if the code is valid, but doesn't disable it
    const otpCodeStr = otpCode.join('');
    axios
      .post('/settings/two_factor/validations', { otp_attempt: otpCodeStr })
      .then(() => {
        setDisableButton(false);
      })
      .catch(() => {
        oneTimePasswordProps.handleCleanupField();
        validationErrorsProps.handleValidationErrorsAssignment({
          otp_attempt: ['Incorrect verification code'],
        });
      });
  };

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton
        className='m-r-12'
        color='blue'
        href='#'
        onClick={closeModal}
        size='md'
      >
        Cancel
      </TextButton>

      <FilledButton
        color='red'
        disabled={disableButton}
        onClick={() => onFormSubmit(oneTimePasswordProps.otpCode)}
      >
        Turn off two-factor authentication
      </FilledButton>
    </div>
  );

  useEffect(() => {
    oneTimePasswordProps.inputRefs[0].current.focus();
  }, [isOpen]);

  return (
    <ModalBox
      customFooter={customFooter}
      isOpen={isOpen}
      mode='flexible'
      onClose={closeModal}
    >
      <h2 className='m-0 tw-px-8 tw-pt-8 tw-text-xl tw-font-semibold tw-text-grey-900'>
        Turn off two-factor authentication
      </h2>

      <hr className='tw-mb-0 tw-w-full' />

      {Object.keys(validationErrorsProps.validationErrors).length > 0 && (
        <ValidationErrorsMessages
          className='tw-m-6 tw-border-1 tw-border-solid tw-border-red-200'
          validationErrors={validationErrorsProps.validationErrors}
        />
      )}

      <div className='p-32'>
        <p className='tw-text-sm tw-font-small tw-mb-5 tw-text-center tw-text-grey-700'>
          Use your authenticator app to get the <br />
          verification code and enter below
        </p>

        <OneTimePasswordSignInCard
          inputRefs={oneTimePasswordProps.inputRefs}
          onFormPasteCapture={oneTimePasswordProps.handleFormPasteCapture}
          onInputClick={oneTimePasswordProps.handleInputClick}
          onInputKeyUp={oneTimePasswordProps.handleInputKeyUp}
          onSubmit={handleFormValidate}
          otpCode={oneTimePasswordProps.otpCode}
        />
      </div>
    </ModalBox>
  );
}

DisableModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  oneTimePasswordProps: oneTimePasswordShape,
  validationErrorsProps: validationErrorsShape,
  onFormSubmit: PropTypes.func.isRequired,
};
