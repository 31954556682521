import React, { useState } from 'react';
import SidePanel from 'components/application/SidePanel';
import FormFooter from 'components/sidepanels/FormFooter';
import CustomPersonsSidePanelBody from 'components/riskRegister/CustomPersonsSidePanelBody';

export default function CustomPersonsSidePanel(props) {
  const [scrolledAmount, setScrolledAmount] = useState(0);
  const {
    closeSidePanel,
    collection,
    collectionErrors,
    displayValidationErrors,
    isFallback,
    onAdd,
    onCancel,
    onDelete,
    onListInputChange,
    onSave,
    onSortEnd,
    removeErrorStyling,
    resetDefaults,
    sidePanelIsOpen,
    submitDisabled,
  } = props;

  return (
    <SidePanel
      bodyContent={
        <CustomPersonsSidePanelBody
          collection={collection}
          collectionErrors={collectionErrors}
          displayValidationErrors={displayValidationErrors}
          isFallback={isFallback}
          onAdd={onAdd}
          onDelete={onDelete}
          onListInputChange={onListInputChange}
          onSortEnd={onSortEnd}
          removeErrorStyling={removeErrorStyling}
          resetDefaults={resetDefaults}
          submitDisabled={submitDisabled}
        />
      }
      closeCallback={closeSidePanel}
      color='tertiary'
      contentContext={'edit'}
      footerContent={
        <FormFooter
          onCancel={onCancel}
          onSubmit={onSave}
          submitButtonDisabled={submitDisabled}
          text={'Save changes'}
        />
      }
      headerContent={
        <div className='popup__title popup__title--tertiary'>
          <h1 className='tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-900'>
            Edit person(s) at risk
          </h1>
        </div>
      }
      isOpen={sidePanelIsOpen}
      onBackdropClick={() => {}}
      scrolledAmount={scrolledAmount}
      setScrolledAmount={setScrolledAmount}
      submitDisabled={submitDisabled}
    />
  );
}
