import React from 'react';
import sequenceOfOperationsPageReducer, {
  initialState,
} from 'reducers/sequenceOfOperationsPageReducer';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import AvailableSequenceOfOperationsPage from 'containers/AvailableSequenceOfOperationsPage';

export default function SequenceOfOperationsApp({
  areAttachmentsAllowed,
  isReviewing,
  projectId,
}) {
  const store = createStore(sequenceOfOperationsPageReducer, {
    ...initialState,
    application: {
      ...initialState.application,
      areAttachmentsAllowed,
      isReviewing,
      projectId,
    },
  });

  return (
    <Provider store={store}>
      <AvailableSequenceOfOperationsPage projectId={projectId} />
    </Provider>
  );
}
