import HandshqApp from '../handshq-app';

HandshqApp.SubTasksContainer = function (parent) {
  if (this instanceof HandshqApp.SubTasksContainer) {
    const _this = this;

    this.parent = parent;
    this.dragHandle = _this.parent.root.find('.task-sub-task__drag-handle');
    this.subTaskText = _this.parent.root.find('.task-sub-task__text');
    this.footerNew = _this.parent.root.find(
      '.task-sub-tasks-footer__new-sub-task',
    );

    this.setup = function () {
      subTaskDragHandle(_this.dragHandle);

      HandshqApp.editableTextareaFactory.customTextAreas(
        _this.footerNew,
        setupAddSubTaskTextArea,
      );
      HandshqApp.domHelper.hoverToggleEvent(
        '.task-sub-task',
        '.task-sub-task__drag-handle img',
      );
      HandshqApp.categoryFormHelper.setupDeleteEvent({
        resource: 'task-sub-task',
        root: _this.parent.root,
        onDeleteCallback: subtractFromStepCounter,
      });
      HandshqApp.editableTextareaFactory.regularTextAreas(_this.subTaskText);

      return this;
    };

    this.setupSubTaskTextareas = function (node) {
      $(node)
        .find('.task-sub-task .editable-textarea')
        .each(function () {
          _this.parent.setupEditableTextArea.call(this);
        });

      $(node)
        .find('.task-sub-tasks-footer .editable-textarea')
        .each(function () {
          setupAddSubTaskTextArea.call(this);
        });
    };

    function subTaskDragHandle(dragHandle) {
      dragHandle.click(function (event) {
        event.stopPropagation();
      });
    }

    function setupAddSubTaskTextArea() {
      new HandshqApp.EditableTextarea({
        node: this,
        successCallback: addSubTaskSuccessCallback,
        cancelCallback: addSubTaskCancelCallback,
        textFieldFocusCallback: focusSubTaskTextFieldCallback,
        textFieldFocusTrigger: $(this)
          .closest('tbody')
          .find('.task-sub-task__new'),
      }).setup();
    }

    function focusSubTaskTextFieldCallback(editableTextarea) {
      const tbody = editableTextarea.node.closest('tbody');
      HandshqApp.domHelper.toggleElements(
        tbody.find('.task-sub-tasks-actions-footer'),
        tbody.find('.task-sub-tasks-footer'),
      );
      editableTextarea.node.find('.editable-textarea__text-editor').show();
    }

    function addSubTaskSuccessCallback(editableTextarea, response) {
      const subTaskCollection = editableTextarea.node.closest(
        '.category-task__body',
      );
      const subTask = $(response['data']['attributes']['htmlContent']);
      const deleteModal = $(response['data']['attributes']['htmlDelete']);

      addToStepCounter(subTaskCollection);
      subTask.insertBefore(subTaskCollection.find('tbody.task-sub-tasks__end'));
      deleteModal.insertBefore(
        subTaskCollection.find('table.task-sub-tasks__end'),
      );

      HandshqApp.categoryFormHelper.setupDeleteModal({
        resource: 'task-sub-task',
        contentNode: subTask,
        modalNode: deleteModal,
        onDeleteCallback: subtractFromStepCounter,
      });
      HandshqApp.domHelper.hoverToggleEvent(
        subTask.find('.task-sub-task'),
        subTask.find('.task-sub-task__drag-handle img'),
      );
      HandshqApp.editableTextareaFactory.regularTextArea.call(
        subTask.find('.task-sub-task__text .editable-textarea'),
      );
      HandshqApp.domHelper.toggleElements(
        editableTextarea.node.closest('.task-sub-tasks-footer'),
        editableTextarea.node
          .closest('tbody')
          .find('.task-sub-tasks-actions-footer'),
      );

      const subTaskRow = subTask.filter(
        (i, ele) => ele?.className?.indexOf('sortable-task-sub-task') > -1,
      );
      ReactRailsUJS.mountComponents(subTaskRow[0]);

      editableTextarea.reset();
    }

    function subtractFromStepCounter(target) {
      const stepCounter = $(target)
        .parents('.category-task__tbody')
        .find('.category-task__step-counter');
      updateStepCounter(stepCounter, -1);
    }

    function addToStepCounter(target) {
      const stepCounter = $(target)
        .parents('.category-task__tbody')
        .find('.category-task .category-task__step-counter');
      updateStepCounter(stepCounter, 1);
    }

    function updateStepCounter(counterElement, valueToAdd) {
      const newCount = counterElement.data('stepCount') + valueToAdd;
      counterElement.text(newCount);
      counterElement.data('stepCount', newCount);
    }

    function addSubTaskCancelCallback(editableTextarea) {
      const tbody = editableTextarea.node.closest('tbody');

      HandshqApp.domHelper.toggleElements(
        tbody.find('.task-sub-tasks-footer'),
        tbody.find('.task-sub-tasks-actions-footer'),
      );
    }
  } else {
    throw new Error('HandshqApp.SubTasksContainer invoked without new');
  }
};
