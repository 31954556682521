import React from 'react';
import CloseIcon from '-!svg-react-loader?name=CloseIcon!icons/ic-close.svg';
import ProfilePhotoDisplay from 'components/application/ProfilePhotoDisplay';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import { personDisplayName } from 'components/helpers/users';
import PropTypes from 'prop-types';

SelectedPersonnelCard.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default function SelectedPersonnelCard({
  email,
  firstName,
  lastName,
  onClose,
  onEdit,
  profilePhotoUrl,
}) {
  return (
    <>
      <span className='l-if p-16 b-standard br-3 w-100 m-b-32 tw-border-grey-100 tw-bg-white'>
        <ProfilePhotoDisplay
          componentSize='sm'
          photoUrl={profilePhotoUrl}
          profileInitials={`${firstName[0]}${lastName[0]}`}
        />
        <span className='flex-auto m-l-12'>
          <span className='tw-font-medium tw-text-grey-900'>
            {personDisplayName({ firstName, lastName })}
          </span>
          <CloseIcon
            className='handshq--hoverable pull-right [&_polygon]:tw-fill-grey-700'
            height={24}
            onClick={onClose}
            width={24}
          />
          <div className='m-b-12'>{email}</div>
          <OutlinedButton color='grey' onClick={onEdit} size='sm'>
            Edit
          </OutlinedButton>
        </span>
      </span>
    </>
  );
}
