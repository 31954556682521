import React from 'react';
import PropTypes from 'prop-types';

import useELearningCourse from 'components/hooks/useELearningCourse';

import CloseIcon from '-!svg-react-loader?name=CloseIcon!icons/ic-close.svg';
import Tooltip from 'components/application/Tooltip';
import classNames from 'classnames';

export default function NonCustomELearningCourseCard({
  disabledTooltip,
  eLearningCourseId,
  isDisabled,
  onClose,
  onError,
}) {
  const eLearningCourse = useELearningCourse(eLearningCourseId, onError);
  const { detailsUrl, duration, name } =
    eLearningCourse ? eLearningCourse.attributes : {};

  return (
    <div className={isDisabled ? 'tooltip-parent' : ''}>
      <div
        className={classNames(
          'content-box pos-rel p-t-8 p-r-40 p-b-8 p-l-12 tw-border-grey-100 tw-bg-white',
          !eLearningCourse && 'fh-90',
          isDisabled ? 'tw-bg-grey-025' : 'tw-bg-white',
        )}
      >
        {eLearningCourse && (
          <>
            <div className='m-b-4'>{name}</div>
            <div className='m-b-4 tw-text-s tw-tracking-wide'>
              {duration} &bull; 1 credit per course
            </div>
            <div className='flex'>
              <a
                className='app-link tw-text-s tw-tracking-wide tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
                href={detailsUrl}
                target='blank'
              >
                Learn more about this course
              </a>
            </div>
            <CloseIcon
              className={`handshq--hoverable pos-abs pos-t-8 pos-r-8 ${isDisabled ? '[&_polygon]:tw-fill-grey-300' : '[&_polygon]:tw-fill-grey-500'}`}
              height={24}
              onClick={isDisabled ? () => void 0 : onClose}
              width={24}
            />
          </>
        )}
      </div>
      <Tooltip placement='top' tooltip={disabledTooltip} trigger='hover' />
    </div>
  );
}

NonCustomELearningCourseCard.propTypes = {
  eLearningCourseId: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  disabledTooltip: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

NonCustomELearningCourseCard.defaultProps = {
  isDisabled: false,
  disabledTooltip: '',
};
