import React from 'react';
import Toast from 'components/application/Toast';
import CrossIcon from '-!svg-react-loader?name=CrossIcon!icons/ic-close.svg';

export default function InedibleToast({
  errorTrigger,
  fullMessage,
  onBurnToast,
  toastKey,
}) {
  return (
    <Toast
      burn={onBurnToast}
      contents={
        <React.Fragment>
          <div className='toast__icon tw-bg-red-600'>
            <CrossIcon
              className='[&_polygon]:tw-fill-white'
              height={20}
              width={20}
            />
          </div>
          <div className='toast__text'>
            {fullMessage ?
              fullMessage
            : `There appears to be a problem${errorTrigger ? ` with ${errorTrigger}` : ''}, please contact support if this continues.`
            }
          </div>
        </React.Fragment>
      }
      modifiers='no-undo'
      toastKey={toastKey}
    />
  );
}

InedibleToast.defaultProps = {
  fullMessage: null,
  errorTrigger: null,
};
