export function syncIntercomIconVisibility({ hidden }) {
  const intercomIcon =
    document.querySelector('#intercom-container') ||
    document.querySelector('.intercom-lightweight-app');

  if (!intercomIcon) return;

  hidden ?
    intercomIcon.classList.add('layout--n')
  : intercomIcon.classList.remove('layout--n');
}

export function launchIntercomWithMessage(message) {
  window.Intercom('showNewMessage', message);
}
