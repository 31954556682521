import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import voca from 'voca';

import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';
import classNames from 'classnames';

export default function TextArea({
  additionalLabel,
  additionalText,
  autoFocus,
  autoHeight,
  fieldError,
  label,
  labelVisible,
  name,
  onChange,
  onFocus,
  padded,
  placeholderText,
  resizeVertical,
  smallFontSize,
  tooltip,
  tooltipClassName,
  value,
}) {
  // this should be a helper
  const fieldRef = useCallback((node) => {
    autoFocus && node !== null && focusNode(node);
  }, []);
  const focusNode = (node) => {
    node.focus();
    node.setSelectionRange(value.length, value.length);
    node.scrollTop = node.scrollHeight;
  };

  const isInvalid = !!fieldError?.fieldHighlighted;

  const textareaClassName = (() => {
    switch (true) {
      case isInvalid:
        return 'tw-border-red-600 hover:tw-border-red-600 focus-within:tw-border-red-600 hover:focus-within:tw-border-red-600';
      default:
        return 'tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300 placeholder:tw-text-grey-300';
    }
  })();

  return (
    <div className='text-field'>
      <div className='form-group'>
        <label
          className={classNames(
            'field__label tw-font-medium',
            !labelVisible && 'hide',
            additionalLabel &&
              `after:tw-absolute after:tw-right-0 after:tw-font-normal after:tw-text-grey-500 after:tw-content-['${additionalLabel}']`,
            isInvalid && 'after:tw-text-red-600',
          )}
          htmlFor={name}
        >
          {label}
          {tooltip && (
            <Tooltip
              className={tooltipClassName}
              placement='top'
              tooltip={tooltip}
              trigger='hover'
            >
              <CircleQuestion />
            </Tooltip>
          )}
        </label>
        {additionalText && (
          <p className='tw-mb-2 tw-text-s tw-text-grey-500'>{additionalText}</p>
        )}
        <div className='flex flex--vertically-centered'>
          <textarea
            className={classNames(
              'field__input field__input--textarea tw-font-inter tw-text-m tw-font-normal tw-tracking-auto',
              textareaClassName,
              autoHeight && 'field__input--textarea-growable',
              padded && 'field__input--textarea-padded',
              smallFontSize && 'field__input--textarea-small-font-size',
              resizeVertical && 'field__input--resize-vertical',
            )}
            id={voca.snakeCase(name)}
            name={name}
            onChange={onChange}
            onFocus={onFocus}
            placeholder={placeholderText}
            ref={fieldRef}
            value={value}
          />
        </div>
      </div>
    </div>
  );
}

TextArea.defaultProps = {
  labelVisible: true,
  autoHeight: false,
  padded: false,
  tooltip: null,
  resizeVertical: false,
  placeholder: null,
  onFocus: () => {},
};

TextArea.propTypes = {
  autoFocus: PropTypes.bool,
  value: PropTypes.string,
  fieldError: PropTypes.object,
  labelVisible: PropTypes.bool,
  name: PropTypes.string,
  tooltip: PropTypes.node,
  tooltipClassName: PropTypes.string,
  label: PropTypes.node,
  additionalLabel: PropTypes.string,
  additionalText: PropTypes.node,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholderText: PropTypes.node,
  autoHeight: PropTypes.bool,
  padded: PropTypes.bool,
  smallFontSize: PropTypes.bool,
  resizeVertical: PropTypes.bool,
};
