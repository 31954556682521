import HandshqApp from '../handshq-app';

$(function () {
  HandshqApp.generateMasterActivitiesCollection = function () {
    HandshqApp.masterActivities = [];

    $('.master-activities-container .master-activity').each(function (i, el) {
      HandshqApp.masterActivities.push(new HandshqApp.MasterActivity(el));
    });
  };

  HandshqApp.generateHazardsCollection = function () {
    HandshqApp.hazards = [];

    $('.task__content .hazard').each(function (i, el) {
      HandshqApp.hazards.push(new HandshqApp.Hazard(el));
    });
  };
});
