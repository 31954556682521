import React from 'react';
import PropTypes from 'prop-types';

import SidePanel from 'components/application/SidePanel';
import FormFooter from 'components/sidepanels/FormFooter';
import FieldForm from 'components/settings/personnelInformation/FieldForm';

export default function FieldSidePanel(props) {
  const {
    closeCallback,
    form,
    onCreate,
    onFormOptionAppend,
    onFormOptionDelete,
    onFormOptionOrderChange,
    onFormOptionValueChange,
    onFormValueChange,
    onUpdate,
    removeErrorStyling,
    sidePanel,
  } = props;

  const onBackdropClick = () => {
    const panelLocked = ['new', 'edit'].includes(sidePanel.context);

    if (!panelLocked) {
      closeCallback();
    }
  };

  return (
    <SidePanel
      bodyContent={
        <FieldForm
          {...form}
          onFormOptionAppend={onFormOptionAppend}
          onFormOptionDelete={onFormOptionDelete}
          onFormOptionOrderChange={onFormOptionOrderChange}
          onFormOptionValueChange={onFormOptionValueChange}
          onFormValueChange={onFormValueChange}
          removeErrorStyling={removeErrorStyling}
          sidePanel={sidePanel}
        />
      }
      closeCallback={closeCallback}
      color='tertiary'
      footerContent={
        <FormFooter
          onCancel={closeCallback}
          onSubmit={sidePanel.context === 'new' ? onCreate : onUpdate}
          submitButtonDisabled={form.isSubmitDisabled}
          text={
            sidePanel.context === 'new' ? 'Add custom field' : 'Save changes'
          }
        />
      }
      headerContent={
        <div className='popup__title popup__title--tertiary'>
          <h1 className='tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-900'>
            {sidePanel.context === 'new' ? 'Add' : 'Edit'} custom field
          </h1>
        </div>
      }
      isOpen={sidePanel.isOpen}
      onBackdropClick={onBackdropClick}
    />
  );
}

FieldSidePanel.propTypes = {
  sidePanel: PropTypes.exact({
    context: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
  }).isRequired,
  form: PropTypes.exact({
    fieldSettingId: PropTypes.string,
    fieldAttributeId: PropTypes.string,
    name: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired,
    restricted: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
    requestError: PropTypes.object.isRequired,
    isSubmitDisabled: PropTypes.bool.isRequired,
  }).isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  onFormOptionValueChange: PropTypes.func.isRequired,
  onFormOptionAppend: PropTypes.func.isRequired,
  onFormOptionDelete: PropTypes.func.isRequired,
  onFormOptionOrderChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
};
