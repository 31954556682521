import HandshqApp from '../handshq-app';

HandshqApp.EditableContent = function (contentWrappers) {
  if (this instanceof HandshqApp.EditableContent) {
    const _this = this;
    const imageUploaderErrorsModal = $('.image-uploader-errors.modal');

    this.contentWrappers = contentWrappers;

    this.setup = function () {
      HandshqApp.imageHelper.deleteMethodTextImage();
      HandshqApp.imageHelper.pendingImageRotation();

      setupErrorsModalHideEvent();

      _this.contentWrappers.each(function (_index, elements) {
        setupElements(
          elements,
          '.editable-content .editable-textarea',
          _this.setupRichTextArea,
        );
        setupElements(
          elements,
          '.image-uploader',
          _this.setupUploaderImageArea,
        );
        setupElements(
          elements,
          '.caption.editable-textarea',
          setupCaptionTextArea,
        );
      });

      return this;
    };

    this.setupRichTextArea = function () {
      new HandshqApp.EditableTextarea({
        node: this,
        successCallback: richTextSuccessCallback,
        cancelCallback: richTextCancelCallback,
      }).setup();
    };

    this.setupUploaderImageArea = function () {
      new HandshqApp.ImageUploader({
        node: this,
        successCallback: imageUploaderSuccessCallback,
      }).setup();
    };

    function setupErrorsModalHideEvent() {
      imageUploaderErrorsModal.on('hide.bs.modal', function () {
        $(this).find('.image-uploader-errors__content').hide();
        $(this).find('ul.file-type-errors, ul.file-size-errors').empty();
      });
    }

    function setupElements(elements, selector, callback) {
      $(elements)
        .find(selector)
        .each(function () {
          callback.call(this);
        });
    }

    function imageUploaderSuccessCallback(imageUploader, response) {
      const imagesCollection = imageUploader.node
        .closest('.editable-content')
        .find('.images-collection');
      const image = $(response['html']);

      imagesCollection.append(image);

      setupCaptionTextArea.call(image.find('.caption.editable-textarea'));

      HandshqApp.imageHelper.deleteMethodTextImage();
      HandshqApp.imageHelper.pendingImageRotation();
      HandshqApp.imageHelper.renderImageWhenProcessed(response['image']['id']);
      addPopover(image.find('.hover-popover'));
      new HandshqApp.AutoSubmittingForm({
        form: image.find('form.auto-submit'),
      }).setup();
      new HandshqApp.SortableRecords(imagesCollection).setup();
    }

    function richTextSuccessCallback(editableTextarea, _response) {
      editableTextarea.submitButton.removeAttr('disabled');
      editableTextarea.richTextActions.hide();
    }

    function richTextCancelCallback(editableTextarea) {
      const original = editableTextarea.textField.val();
      editableTextarea.richTextEditor.setContent(original);
      editableTextarea.richTextActions.hide();
    }

    function setupCaptionTextArea() {
      new HandshqApp.EditableTextarea({
        node: this,
        successCallback: captionSuccessCallback,
        cancelCallback: captionCancelCallback,
      }).setup();
    }

    function captionSuccessCallback(editableTextarea, response) {
      const wrapperSpan = editableTextarea.textWrapper.find('span');

      if (editableTextarea.textField[0].value.length < 1) {
        wrapperSpan
          .addClass('caption__add')
          .html('<i class="glyphicon glyphicon-plus-sign"></i> Add caption');
      } else {
        wrapperSpan
          .removeClass()
          .text(response['data']['attributes'][editableTextarea.attribute]);
      }

      editableTextarea.submitButton.removeAttr('disabled');
      HandshqApp.domHelper.toggleElements(
        editableTextarea.textEditor,
        editableTextarea.textWrapper,
      );
    }

    function captionCancelCallback(editableTextarea) {
      HandshqApp.domHelper.toggleElements(
        editableTextarea.textEditor,
        editableTextarea.textWrapper,
      );
    }
  } else {
    throw new Error('HandshqApp.EditableContent invoked without new');
  }
};
