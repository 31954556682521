// NB: not used due to issue with Options being in charge of onClick when being used as a fully fledged component

import React from 'react';
import Tooltip from 'components/application/Tooltip';
import OptionChoice from 'components/application/OptionChoice';
import { useBreadBoard } from 'components/contexts/Toaster';

export default function OptionChoiceReviewReminder(props) {
  const breadBoard = useBreadBoard();

  const sendReviewReminders = () => {
    axios
      .post(`/projects/${props.projectId}/review_reminders`)
      .catch((_error) =>
        breadBoard.addInedibleToast({
          fullMessage: 'A reminder was not sent. Please try again.',
        }),
      );
  };

  const isDisabled =
    props.projectPersonnelReviews.haveSignedAnyVersion ||
    !props.requestedVersion;

  return isDisabled ?
      <OptionChoice disabled={true}>
        <Tooltip
          placement='top'
          tooltip='Reviews for the latest version have not been sent'
          trigger='hover'
        >
          <a className='tw-text-m tw-font-medium tw-tracking-auto tw-text-grey-700 hover:tw-text-grey-700'>
            Remind people who haven't signed
          </a>
        </Tooltip>
      </OptionChoice>
    : <OptionChoice
        onClick={(setOptionsOpen) => {
          sendReviewReminders();
          setOptionsOpen(false);
        }}
      >
        <a className='tw-text-m tw-font-medium tw-tracking-auto tw-text-grey-700 hover:tw-text-grey-700'>
          Remind people who haven't signed
        </a>
      </OptionChoice>;
}
