import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function ValidationErrorsMessages({
  className = '',
  validationErrors,
}) {
  return (
    <div
      className={classNames(
        'tw-rounded-lg tw-border-0 tw-bg-red-025 tw-p-3 tw-text-red-800',
        className,
      )}
    >
      <p className='tw-mb-2'>Please fix the following errors:</p>
      <ul className='tw-mb-0 tw-pl-6'>
        {Object.keys(validationErrors).map((key, errorIndex) => {
          return validationErrors[key].messages.map((message, messageIndex) => (
            <li key={`${errorIndex} ${messageIndex}`}>{message}</li>
          ));
        })}
      </ul>
    </div>
  );
}

ValidationErrorsMessages.propTypes = {
  validationErrors: PropTypes.object.isRequired,
};
