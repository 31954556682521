import React from 'react';

export default function ActivityTableHeaders(props) {
  return (
    <tr className='handshq__tr--grey tw-bg-grey-050'>
      {props.showVersionNumberColumn && (
        <th className='w-8 tw-text-center tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
          Version
        </th>
      )}
      <th className='w-20 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
        Date
      </th>
      <th className='w-62 tw-pl-12 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
        Change
      </th>
      <th className='w-15'></th>
    </tr>
  );
}
