$(function () {
  const renderableCharts = document.querySelectorAll('[data-chart]');

  if (renderableCharts.length > 0) {
    renderableCharts.forEach(function (renderableChart) {
      const data = JSON.parse(renderableChart.dataset.value);

      if (Object.keys(data).length > 1) {
        renderableChart.style['height'] = '300px';

        new Chartkick.LineChart(renderableChart.id, data, {
          colors: ['#5998F8'],
        });
      }
    });
  }
});
