import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import voca from 'voca';

import { useInfiniteQuery } from '@tanstack/react-query';
import Select, { components } from 'react-select';

import { getNextPageParam } from 'components/helpers/reactQuery';

import { useBreadBoard } from 'components/contexts/Toaster';

import { Option } from 'components/application/CollectionSelect';

import CloseIcon from '-!svg-react-loader?name=CloseIcon!icons/ic-close.svg';
import ChevronUpIcon from '-!svg-react-loader?name=ChevronUpIcon!icons/chevron-up.svg';
import ChevronDownIcon from '-!svg-react-loader?name=ChevronDownIcon!icons/chevron-down.svg';

import CheckboxFields from 'components/application/CheckboxFields';
import classNames from 'classnames';

const DropdownIndicator = (props) => {
  const chevronClassName =
    props.isDisabled ?
      '[&_polygon]:tw-fill-grey-300'
    : '[&_polygon]:tw-fill-grey-700';

  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ?
          <ChevronUpIcon className={chevronClassName} height={24} width={24} />
        : <ChevronDownIcon
            className={chevronClassName}
            height={24}
            onMouseDown={props.selectProps.onDropdownIndicatorOpen}
            onTouchEnd={props.selectProps.onDropdownIndicatorOpen}
            width={24}
          />
        }
      </components.DropdownIndicator>
    )
  );
};

// custom component required in order to stop the input from being hidden when selection is made
const Input = (props) => <components.Input {...props} isHidden={false} />;

const moreSelectedResourcesBreakpoint = 7;

export default function MultiSelectFilter(props) {
  const {
    autoFocus = false,
    checkboxes = [],
    fieldError,
    id,
    label,
    message,
    name,
    onDeselect,
    onFocus,
    onRemove,
    onSelect,
    placeholder,
    resourceLabel,
    resourceSort,
    selectedIds,
    type,
  } = props;

  const url = `/${type}_searches`;
  const paramKey = `${type}_search`;

  const [selectableResourcesEnabled, setSelectableResourcesEnabled] =
    useState(false);
  const [selectedResources, setSelectedResources] = useState([]);
  const [showMoreSelectedResources, setShowMoreSelectedResources] =
    useState(false);
  const [searchText, setSearchText] = useState('');
  const setSearchTextDebounced = useRef(
    _.debounce((searchText) => setSearchText(searchText), 500),
  ).current;

  const breadBoard = useBreadBoard();

  const isInvalid = !!fieldError?.fieldHighlighted;

  const resourceOption = (resource) => {
    return {
      value: resource.id,
      label: resourceLabel(resource),
      record: resource,
    };
  };

  const selectableResourcesParams = { search: searchText, filter: {} };
  if (selectedIds.length > 0)
    selectableResourcesParams.filter['ids'] = { nin: selectedIds };

  const fetchSelectableResources = (page) =>
    axios.post(url, {
      [paramKey]: { ...selectableResourcesParams, page: page, per: 25 },
    });

  const {
    data: selectableResources,
    fetchNextPage: selectableResourcesFetchNextPage,
    hasNextPage: selectableResourcesHasNextPage,
    isFetching: selectableResourcesIsFetching,
  } = useInfiniteQuery({
    queryKey: [
      `selectable_${voca.snakeCase(label)}`,
      selectableResourcesParams,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const selectableResourcesResponse =
        await fetchSelectableResources(pageParam);

      return selectableResourcesResponse.data;
    },
    getNextPageParam: getNextPageParam,
    enabled: selectableResourcesEnabled || !!searchText,
    onError: breadBoard.addInedibleToast,
  });

  const selectableResourceOptions = (resourceData) => {
    return resourceData.pages
      .map((page) => {
        return page.data.map((resource) => resourceOption(resource));
      })
      .flat();
  };

  const handleFilterSelection = ({ record }) => {
    onSelect(record.id);

    const newSelectedResources = [
      ...selectedResources,
      resourceOption(record),
    ].sort(resourceSort);
    setSelectedResources(newSelectedResources);
  };

  const handleFilterDeselection = ({ record }) => {
    onDeselect(record.id);

    const newSelectedResources = selectedResources.filter(
      (selectedResource) => selectedResource.record.id !== record.id,
    );
    setSelectedResources(newSelectedResources);
  };

  const visibleSelectedResources =
    (
      selectedResources.length < moreSelectedResourcesBreakpoint ||
      showMoreSelectedResources
    ) ?
      selectedResources
    : selectedResources.slice(0, moreSelectedResourcesBreakpoint);

  const fetchSelectedResources = () => {
    axios
      .post(url, { [paramKey]: { filter: { ids: { in: selectedIds } } } })
      .then((response) => {
        const initialSelectedResources = response.data.data.map((resource) =>
          resourceOption(resource),
        );

        setSelectedResources(initialSelectedResources);
      })
      .catch(breadBoard.addInedibleToast);
  };

  useEffect(() => {
    if (selectedIds.length > 0) fetchSelectedResources();
  }, []);

  return (
    <div
      className='tw-rounded-sm tw-border-1 tw-border-solid tw-border-grey-100 tw-bg-white tw-p-5'
      onFocus={onFocus}
    >
      <div className='tw-mb-4 tw-flex tw-items-center tw-justify-between'>
        <label className='tw-m-0 tw-font-medium'>{label}</label>
        <div
          className='tw-flex tw-h-5 tw-cursor-pointer tw-items-center tw-rounded-lg tw-border-0 tw-bg-white tw-px-2 tw-text-grey-500 hover:tw-bg-red-025 hover:tw-text-red-500'
          onClick={onRemove}
        >
          <span className='tw-text-s tw-font-medium tw-tracking-wide'>
            Remove filter
          </span>
        </div>
      </div>
      {message && (
        <p className='tw-m-0 tw-mb-4 tw-text-s tw-font-normal tw-tracking-wide'>
          {message}
        </p>
      )}
      <Select
        autoFocus={autoFocus}
        className={classNames(
          'collection-select__select-container',
          isInvalid && 'collection-select--invalid',
        )}
        classNamePrefix='collection-select'
        components={{ Option, DropdownIndicator, Input }}
        controlShouldRenderValue={false}
        filterOption={() => true}
        id={id}
        isLoading={selectableResourcesIsFetching}
        loadingMessage={() => null}
        name={name}
        noOptionsMessage={() => (!!searchText ? 'No matching results' : null)}
        onChange={handleFilterSelection}
        onDropdownIndicatorOpen={() => setSelectableResourcesEnabled(true)}
        onInputChange={setSearchTextDebounced}
        onMenuClose={() => setSelectableResourcesEnabled(false)}
        onMenuScrollToBottom={() => {
          if (selectableResourcesHasNextPage)
            selectableResourcesFetchNextPage();
        }}
        openMenuOnClick={false}
        openMenuOnFocus={false}
        options={
          selectableResources ?
            selectableResourceOptions(selectableResources)
          : []
        }
        placeholder={placeholder}
      />
      <ul
        className={classNames(
          'tw-m-0 tw-flex tw-flex-col tw-p-0',
          selectedResources.length > 0 && 'tw-mt-2',
        )}
      >
        {visibleSelectedResources.length > 0 &&
          visibleSelectedResources.map((selected) => {
            return (
              <li
                className='tw-relative tw-mt-2 tw-flex tw-min-h-[36px] tw-items-center tw-justify-between tw-rounded-lg tw-border-0 tw-bg-grey-050 tw-py-2 tw-pl-3 tw-pr-9 tw-text-grey-700'
                key={selected.value}
              >
                <div className='tw-text-s tw-font-medium tw-tracking-wide'>
                  {selected.label}
                </div>
                <div
                  className='tw-group tw-absolute tw-bottom-0 tw-right-1 tw-top-0 tw-m-[auto_0] tw-flex tw-h-9 tw-w-9 tw-cursor-pointer tw-items-center tw-justify-center'
                  onClick={() => handleFilterDeselection(selected)}
                >
                  <div className='tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-lg tw-border-0 tw-bg-grey-050 group-hover:tw-bg-grey-200'>
                    <CloseIcon
                      className='[&_polygon]:tw-fill-grey-500'
                      height={24}
                      width={24}
                    />
                  </div>
                </div>
              </li>
            );
          })}
        {selectedResources.length > moreSelectedResourcesBreakpoint &&
          !showMoreSelectedResources && (
            <div className='tw-mt-4'>
              <button
                className='app-link tw-bg-transparent tw-align-baseline tw-text-s tw-font-medium tw-tracking-wide tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
                onClick={() => setShowMoreSelectedResources(true)}
              >
                Show{' '}
                {selectedResources.length - moreSelectedResourcesBreakpoint}{' '}
                more
              </button>
            </div>
          )}
      </ul>
      {checkboxes.length !== 0 && (
        <div className='tw-mt-4'>
          <CheckboxFields
            checkboxes={checkboxes}
            inline={true}
            minChecked={0}
          />
        </div>
      )}
    </div>
  );
}

MultiSelectFilter.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  type: PropTypes.string.isRequired,
  message: PropTypes.string,
  selectedIds: PropTypes.array.isRequired,
  checkboxes: PropTypes.array,
  resourceLabel: PropTypes.func.isRequired,
  resourceSort: PropTypes.func.isRequired,
  fieldError: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
};
