import React from 'react';
import StatusIndicator from 'components/application/indicators/StatusIndicator';

export default function PersonnelRoleTrainingStatusIndicator(props) {
  const status = {
    archived: 'grey',
    noRequirements: 'grey',
    invalid: 'high',
    valid: 'low',
    expiring: 'medium',
  }[props.status];

  return <StatusIndicator status={status} />;
}
