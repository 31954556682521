import HandshqApp from '../handshq-app';

HandshqApp.authenticatePayment = function () {
  const form = $('form.security-check-form');
  const risk = recurly.Risk();
  const threeDSecure = risk.ThreeDSecure({
    actionTokenId: $(form).data('tds-token'),
  });
  const redirectUrl = $(form).data('redirect-url');
  const placeHolder = $('.sca-placeholder');

  threeDSecure.on('error', (_err) => {
    placeHolder.show();
    location.replace(redirectUrl + '?sca_failed=true');
  });

  threeDSecure.on('token', (token) => {
    placeHolder.show();
    $('#tds_result_token').val(token.id);
    form.submit();
  });

  placeHolder.hide();
  threeDSecure.attach(document.querySelector('#sca-container'));
};
