import React, { useEffect, useState } from 'react';
// components
import Paginator from 'components/application/Paginator';
import SubcontractorsBar from './SubcontractorsBar';
import SubcontractorsTable from './SubcontractorsTable';
import SubcontractorsSidePanel from './SubcontractorsSidePanel';
import DestroyModal from 'components/application/DestroyModal';
import ResourceChangedToast from 'components/application/ResourceChangedToast';
import ResourceBlankNotice from 'components/application/ResourceBlankNotice';
// hooks
import useRequestError from 'components/hooks/useRequestError';
import useDebounce from 'components/hooks/useDebounce';
import useForm from 'components/hooks/useForm';
import useSidePanel from 'components/hooks/useSidePanel';
import useWindowStorage from 'components/hooks/useWindowStorage';
import useValidatedStore from 'components/hooks/useValidatedStore';
// contexts
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { useBreadBoard } from 'components/contexts/Toaster';
// packages
import { z } from 'zod';

const defaultSubcontractor = {
  id: '',
  name: '',
  isDeleteProhibited: false,
};

const initialTabStore = {
  currentSearch: '',
  page: 1,
  currentResourceId: '',
  currentResourceName: '',
  sidePanelIsOpen: false,
};

const tabSchema = z.object({
  currentResourceId: z.string().nullable(),
  currentResourceName: z.string(),
  sidePanelIsOpen: z.boolean(),
  page: z.number().or(z.null()),
  currentSearch: z.string(),
});

export default function SubcontractorsTab({ label }) {
  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();
  const breadBoard = useBreadBoard();

  const [getStore, setStore] = useWindowStorage(`trainingRegister|${label}`, {
    store: window.sessionStorage,
  });
  const tabStore = useValidatedStore({
    getStore,
    initialStore: initialTabStore,
    schema: tabSchema,
  });

  const initialCurrentSearch = { subcontractorsSearch: tabStore.currentSearch };
  const initialDefaultSubcontractor = {
    ...defaultSubcontractor,
    id: tabStore.currentResourceId,
    name: tabStore.currentResourceName,
  };
  const initialSidePanelIsOpen = tabStore.sidePanelIsOpen || false;

  const [subcontractors, setSubcontractors] = useState({
    loaded: false,
    collection: [],
  });
  const [metaData, setMetaData] = useState({
    currentPage: null,
    totalPages: null,
    scopedCount: 0,
    totalCount: 0,
  });
  const [
    requestError,
    submitDisabled,
    removeErrorStyling,
    resetRequestError,
    handleRequestError,
  ] = useRequestError();
  const [currentSearch, setCurrentSearch, handleSearchInputChange] =
    useForm(initialCurrentSearch);
  const [debouncedCurrentSearch, _resetDebouncedCurrentSearch] = useDebounce(
    currentSearch,
    250,
  );
  const [
    currentSubcontractor,
    setCurrentSubcontractor,
    handleSubcontractorInputChange,
  ] = useForm(initialDefaultSubcontractor);
  const [
    sidePanelIsOpen,
    setSidePanelIsOpen,
    openSidePanel,
    closeSidePanel,
    resetSidePanelContext,
    sidePanelContext,
    setSidePanelContext,
  ] = useSidePanel(initialSidePanelIsOpen, 'show');
  const [destroyModalIsOpen, setDestroyModalIsOpen] = useState(false);

  useEffect(() => {
    fetchSubcontractors(tabStore.page);
  }, []);

  useEffect(() => {
    if (subcontractors.loaded && debouncedCurrentSearch) {
      fetchSubcontractors();
    }
  }, [debouncedCurrentSearch]);

  const fetchSubcontractors = (page = 1) => {
    axios
      .get('/subcontractors', {
        params: { search: currentSearch.subcontractorsSearch, page: page },
      })
      .then((response) => {
        setSubcontractors({ loaded: true, collection: response.data.data });
        setMetaData(response.data.meta);
        resetSidePanelContext();
      })
      .catch((_error) => breadBoard.addInedibleToast());
  };

  const createSubcontractor = () => {
    axios
      .post('/subcontractors', {
        subcontractor: { name: currentSubcontractor.name },
      })
      .then((response) => {
        closeSidePanel();
        resetRequestError();
        breadBoard.addToast(
          <ResourceChangedToast
            onBurnToast={breadBoard.handleBurnToast}
            resource={response.data.data.attributes.name}
            status={'added'}
          />,
        );
        fetchSubcontractors();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          handleRequestError(error);
        } else {
          breadBoard.addInedibleToast();
          closeSidePanel();
        }
      });
  };

  const updateSubcontractor = () => {
    axios
      .patch(`/subcontractors/${currentSubcontractor.id}`, {
        subcontractor: { name: currentSubcontractor.name },
      })
      .then((response) => {
        closeSidePanel();
        resetRequestError();
        breadBoard.addToast(
          <ResourceChangedToast
            onBurnToast={breadBoard.handleBurnToast}
            resource={response.data.data.attributes.name}
            status={'edited'}
          />,
        );
        fetchSubcontractors(metaData.currentPage);
      })
      .catch(handleRequestError);
  };

  const destroySubcontractor = () => {
    const resourceChangedToastAttributes = {
      resource: currentSubcontractor.name,
      onBurnToast: breadBoard.handleBurnToast,
      status: 'deleted',
      ...(currentSubcontractor.archivedPersonnelCount > 0 && {
        additionalText: `and ${currentSubcontractor.archivedPersonnelCount} personnel`,
        plural: true,
      }),
    };

    axios
      .delete(`/subcontractors/${currentSubcontractor.id}`)
      .then(() => {
        closeSidePanel();
        breadBoard.addToast(
          <ResourceChangedToast {...resourceChangedToastAttributes} />,
        );
        fetchSubcontractors(metaData.currentPage);
      })
      .catch(() => {
        breadBoard.addInedibleToast();
        closeSidePanel();
      });
  };

  const handleNewSubcontractor = () => {
    setCurrentSubcontractor(defaultSubcontractor);
    setSidePanelContext('new');
    resetRequestError();
    openSidePanel();
  };

  const handleSearchReset = (_event) => {
    setCurrentSearch({ ...currentSearch, subcontractorsSearch: '' });
  };

  const handlePageChange = (event) => {
    fetchSubcontractors(event.currentTarget.getAttribute('data-page'));
  };

  const viewSubcontractor = (subcon) => {
    setCurrentSubcontractor({
      id: subcon.id,
      name: subcon.attributes.name,
      isDeleteProhibited: subcon.meta.isDeleteProhibited,
      archivedPersonnelCount: subcon.meta.archivedPersonnelCount,
    });
    setSidePanelContext('show');
    resetRequestError();
    openSidePanel();
  };

  useEffect(() => {
    setStore({
      currentSearch: currentSearch.subcontractorsSearch,
      page: metaData.currentPage,
      currentResourceId: currentSubcontractor.id,
      currentResourceName: currentSubcontractor.name,
      sidePanelIsOpen: sidePanelIsOpen,
    });
  }, [
    currentSearch.subcontractorsSearch,
    metaData.currentPage,
    currentSubcontractor.id,
    currentSubcontractor.name,
    sidePanelIsOpen,
  ]);

  const blankMessage =
    trainingRegisterResourceManagementContext.hasPersonnelEditableAccess ?
      'Create a list of the sub-contractors used by your company.'
    : 'Any sub-contractors that have been created will be listed here';

  return (
    <React.Fragment>
      {subcontractors.loaded && (
        <>
          {metaData.totalCount > 0 && (
            <SubcontractorsBar
              actionButtonsVisible={
                trainingRegisterResourceManagementContext.hasPersonnelEditableAccess
              }
              onNewSubcontractor={handleNewSubcontractor}
              onSearchInputChange={handleSearchInputChange}
              onSearchReset={handleSearchReset}
              subcontractorsCount={metaData.totalCount}
              subcontractorsSearch={currentSearch.subcontractorsSearch}
            />
          )}
          {subcontractors.collection.length > 0 ?
            <>
              <SubcontractorsTable
                onRowClick={(event, subcontractor) => {
                  viewSubcontractor(subcontractor);
                }}
                subcontractors={subcontractors.collection}
              />
              {subcontractors.loaded &&
                subcontractors.collection.length > 0 &&
                metaData.totalPages > 1 && (
                  <div className='m-t-80 text-center'>
                    <Paginator
                      currentPage={metaData.currentPage}
                      onClick={handlePageChange}
                      totalPages={metaData.totalPages}
                    />
                  </div>
                )}
            </>
          : <ResourceBlankNotice
              addMessage={blankMessage}
              displayReadOnlyContents={
                !trainingRegisterResourceManagementContext.hasPersonnelEditableAccess
              }
              onAdd={handleNewSubcontractor}
              resource={'sub-contractor'}
              totalCount={metaData.totalCount}
            />
          }
          <SubcontractorsSidePanel
            allowShowFooterContents={
              trainingRegisterResourceManagementContext.hasPersonnelEditableAccess
            }
            closeSidePanel={closeSidePanel}
            createSubcontractor={createSubcontractor}
            currentSubcontractor={currentSubcontractor}
            onDeleteClick={() => setDestroyModalIsOpen(true)}
            onSubcontractorInputChange={handleSubcontractorInputChange}
            removeErrorStyling={removeErrorStyling}
            requestError={requestError}
            setCurrentSubcontractor={setCurrentSubcontractor}
            setSidePanelContext={setSidePanelContext}
            setSidePanelIsOpen={setSidePanelIsOpen}
            sidePanelContext={sidePanelContext}
            sidePanelIsOpen={sidePanelIsOpen}
            submitDisabled={submitDisabled}
            updateSubcontractor={updateSubcontractor}
          />
          {sidePanelContext === 'show' &&
            (currentSubcontractor.archivedPersonnelCount > 0 ?
              <DestroyModal
                additionalContent={
                  <p className='tw-mb-0 tw-mt-6'>
                    To avoid deleting these personnel, you will need to
                    unarchive and assign them to a different company before
                    deleting this company.
                  </p>
                }
                confirmationText={`Delete company and ${currentSubcontractor.archivedPersonnelCount} archived personnel`}
                displayText={`This company contains ${currentSubcontractor.archivedPersonnelCount} archived personnel who will also be deleted`}
                isOpen={destroyModalIsOpen}
                onClose={() => setDestroyModalIsOpen(false)}
                onDestroy={destroySubcontractor}
              />
            : <DestroyModal
                confirmationText='Delete sub-contractor'
                displayText={`Are you sure you want to delete ${currentSubcontractor.name}?`}
                isOpen={destroyModalIsOpen}
                onClose={() => setDestroyModalIsOpen(false)}
                onDestroy={destroySubcontractor}
              />)}
        </>
      )}
    </React.Fragment>
  );
}
