import React from 'react';
import ConditionalWrapper from 'components/application/ConditionalWrapper';
import Tooltip from 'components/application/Tooltip';
import CategoriesAffectedTooltip from 'components/riskRegister/sharedResourceNotices/shared/CategoriesAffectedTooltip';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';

export default function DivisionItem({
  division,
  riskAssessment,
  scopedCategories,
}) {
  return (
    <ConditionalWrapper
      condition={scopedCategories.length > 0}
      wrapper={(children) => (
        <Tooltip
          placement='top'
          tooltip={
            <CategoriesAffectedTooltip
              categories={scopedCategories}
              division={division}
              riskAssessment={riskAssessment}
            />
          }
          trigger='hover'
        >
          {children}
        </Tooltip>
      )}
    >
      {division.attributes.name}
    </ConditionalWrapper>
  );
}

export function prepareDivisionItemProps(division, riskAssessment, categories) {
  const categoryIds = division.relationships.trades.data.map((tr) => tr.id);
  const scopedCategories = categories.filter((cat) =>
    categoryIds.includes(cat.id),
  );
  return { riskAssessment, division, scopedCategories };
}

DivisionItem.propTypes = {
  riskAssessment: resourceShape('masterActivity'),
  division: resourceShape('company').isRequired,
  scopedCategories: PropTypes.arrayOf(resourceShape('trade')).isRequired,
};
