import React from 'react';

import { personDisplayName } from 'components/helpers/users';
import { resourceShape } from 'components/helpers/serialisableResources';
import PersonnelCourseTrainingStatusIndicator from 'components/personnel/PersonnelCourseTrainingStatusIndicator';

export default function PersonnelLabel({ personnel }) {
  return (
    <div className='tw-flex tw-h-8 tw-items-center tw-gap-2 tw-rounded-lg tw-bg-grey-050 tw-px-3'>
      <PersonnelCourseTrainingStatusIndicator
        status={personnel.attributes.trainingStatus}
      />
      <div className='tw-text-s tw-font-medium'>
        {personDisplayName(personnel.attributes)}
      </div>
      {personnel.attributes.externalId && (
        <div className='tw-text-s tw-font-normal tw-text-grey-500'>
          {personnel.attributes.externalId}
        </div>
      )}
    </div>
  );
}

PersonnelLabel.propTypes = {
  personnel: resourceShape('personnel').isRequired,
};
