$(function () {
  if (typeof analytics !== 'undefined') {
    analyticsEvents.trackVersionDownloading();
    analyticsEvents.trackRiskRegisterEvents();
    analyticsEvents.trackEmailDownloadLinkEvents();
    analyticsEvents.trackProjectShowEvents();
    analyticsEvents.trackProcoreActivity();
    analyticsEvents.trackUpgradeActivity();
  }
});

const analyticsEvents = (function () {
  return {
    trackVersionDownloading: function () {
      const untracked = $('.download-event-trigger').not('.download-tracked');
      if (untracked.length > 0) {
        // adding a class to avoid the callback being applied multiple times to the same element
        untracked.on('click', function () {
          const eventName = 'Project preview downloaded';
          const versionLink = $(this).closest('#preview-download');

          analytics.track(eventName, {
            projectId: versionLink.data('project-id'),
          });
        });
        untracked.addClass('download-tracked');
      }
    },

    trackRiskRegisterEvents: function () {
      $('#review_date').on('change', function () {
        analytics.track('Review date changed');
      });

      $('#default-review-date').on('click', function () {
        analytics.track('Review date set to future');
      });

      $('#assignee_id').on('change', function () {
        analytics.track('Risk assessment assignee set');
      });

      $('#add-nested-attributes').on('click', function () {
        analytics.track('Task added');
      });

      $('.risk_assessment__download').on('click', function () {
        analytics.track('Risk assessment downloaded');
      });

      $('.risk-assessment-snapshot__download').on('click', function () {
        analytics.track('Risk assessment version downloaded');
      });

      $('#master_activity_name').on('click', function () {
        analytics.track('Risk assessment name edited');
      });
    },

    trackEmailDownloadLinkEvents: function () {
      $('#email_download_link_logo').on('click', function () {
        analytics.track('Logo clicked on email download link page');
      });
    },
    trackProjectShowEvents: function () {
      $('#coshh_find_out_more_button').on('click', function () {
        analytics.track('COSHH upgrade viewed');
      });
    },
    trackProcoreActivity: function () {
      $('#log-in-with-procore-button-outside-iframe').on('click', function () {
        analytics.track(
          'Continue with Procore clicked from regular sign in page',
        );
      });

      $('#log-in-with-procore-button-within-iframe').on('click', function () {
        analytics.track(
          'Continue with Procore clicked from embedded app sign in page',
        );
      });

      $('#sign-in-button-from-embedded-app').on('click', function () {
        analytics.track('User signed into app via Procore embedded app');
      });
    },
    trackUpgradeActivity: function () {
      $('#upgrade_tr_limits_billing').on('click', function () {
        analytics.track('Upgrade training register clicked from billings page');
      });
    },
  };
})();

window.analyticsEvents = analyticsEvents;
