import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { toggleGrabbing } from 'components/helpers/document';

import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';
import FieldRow from 'components/settings/personnelInformation/FieldRow';

const SortableItem = SortableElement(
  ({
    formattedRow,
    onDestroyFieldAttribute,
    onDestroyFieldValues,
    onEdit,
    onIncludedToggle,
    onRestrictedToggle,
  }) => (
    <FieldRow
      fieldAttribute={formattedRow.fieldAttribute}
      fieldSetting={formattedRow.fieldSetting}
      key={formattedRow.fieldSetting.id}
      onDestroyFieldAttribute={onDestroyFieldAttribute}
      onDestroyFieldValues={onDestroyFieldValues}
      onEdit={onEdit}
      onIncludedToggle={onIncludedToggle}
      onRestrictedToggle={onRestrictedToggle}
    />
  ),
);

const SortableList = SortableContainer(
  ({
    formattedRows,
    isSorting,
    onDestroyFieldAttribute,
    onDestroyFieldValues,
    onEdit,
    onIncludedToggle,
    onRestrictedToggle,
  }) => {
    return (
      <div className='no-user-select pos-rel zi-1'>
        <div className='header-bar header-bar--border p-l-20 p-r-20 tw-border-grey-100 tw-bg-grey-050'>
          <div className='fw-52 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Include
          </div>
          <div className='flex-item flex-item--grow-but-truncate tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Name
          </div>
          <div className='fw-148 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Field type
          </div>
          <div className='fw-124 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            <div className='flex flex--vertically-centered'>
              <span className='m-r-4'>Visibility</span>
              <Tooltip
                placement='top'
                tooltip='Restricted information can only be seen by personnel editors'
                trigger='hover'
              >
                <CircleQuestion size='sm' />
              </Tooltip>
            </div>
          </div>
          <div className='fw-80 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            <div className='flex flex--vertically-centered'>
              <span className='m-r-4'>Records</span>
              <Tooltip
                placement='top'
                tooltip='Number of personnel with this information stored'
                trigger='hover'
              >
                <CircleQuestion size='sm' />
              </Tooltip>
            </div>
          </div>
          <div className='fw-40 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Actions
          </div>
        </div>
        <ul className='block-list block-list--border-bottom-none pos-rel zi-1'>
          {formattedRows.map((formattedRow, index) => (
            <Fragment key={`sortable-item-${formattedRow.fieldSetting.id}`}>
              {isSorting && (
                <div className='row-bar--ghost tw-border-grey-100'></div>
              )}
              <SortableItem
                formattedRow={formattedRow}
                index={index}
                onDestroyFieldAttribute={onDestroyFieldAttribute}
                onDestroyFieldValues={onDestroyFieldValues}
                onEdit={onEdit}
                onIncludedToggle={onIncludedToggle}
                onRestrictedToggle={onRestrictedToggle}
              />
            </Fragment>
          ))}
        </ul>
      </div>
    );
  },
);

export default function FieldTable({
  formattedRows,
  onDestroyFieldAttribute,
  onDestroyFieldValues,
  onEdit,
  onIncludedToggle,
  onRestrictedToggle,
  onSortEnd: propsOnSortEnd,
}) {
  const [isSorting, setIsSorting] = useState(false);

  function handleSortStart() {
    setIsSorting(true);
  }

  function handleSortEnd({ newIndex, oldIndex }) {
    propsOnSortEnd({ oldIndex, newIndex });
    setIsSorting(false);
  }

  useEffect(() => {
    toggleGrabbing(isSorting);
  }, [isSorting]);

  return (
    <SortableList
      axis='y'
      formattedRows={formattedRows}
      helperClass='sortable-item--active'
      isSorting={isSorting}
      lockAxis='y'
      onDestroyFieldAttribute={onDestroyFieldAttribute}
      onDestroyFieldValues={onDestroyFieldValues}
      onEdit={onEdit}
      onIncludedToggle={onIncludedToggle}
      onRestrictedToggle={onRestrictedToggle}
      onSortEnd={handleSortEnd}
      onSortStart={handleSortStart}
      useDragHandle={true}
      useWindowAsScrollContainer={true}
    />
  );
}

FieldTable.propTypes = {
  formattedRows: PropTypes.arrayOf(
    PropTypes.shape({
      fieldAttribute: PropTypes.object.isRequired,
      fieldSetting: PropTypes.object.isRequired,
    }),
  ).isRequired,
  onIncludedToggle: PropTypes.func.isRequired,
  onRestrictedToggle: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDestroyFieldAttribute: PropTypes.func.isRequired,
  onDestroyFieldValues: PropTypes.func.isRequired,
};
