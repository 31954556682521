import { useState } from 'react';

import { enableAllPlugins } from 'immer';
enableAllPlugins();
import { produce } from 'immer';

export const initialDateState = {
  period: '',
  withinTheNextFrequency: '',
  withinTheNextInterval: '',
  withinTheLastFrequency: '',
  withinTheLastInterval: '',
  before: null,
  after: null,
  onExactDate: null,
  customDateRangeFrom: null,
  customDateRangeTo: null,
};

export const initialTrainingStatusFilterState = {
  valid: true,
  expiring: true,
  expired: true,
  missing: true,
};

export const initialEstimatesState = {
  costAndTime: true,
};

export const initialFiltersState = {
  selectedFilters: [],
  divisionFilter: {
    selectedIds: [],
  },
  teamFilter: {
    selectedIds: [],
  },
  personnelTypeFilter: {
    employees: true,
    subcontractors: true,
  },
  roleFilter: {
    selectedIds: [],
    additionalTraining: true,
  },
  courseFilter: {
    selectedIds: [],
  },
  courseRequirementFilter: {
    required: true,
    optional: true,
  },
  lineManagerFilter: {
    selectedIds: [],
  },
};

export default function useReportForm(type, initialArg, init) {
  const initialState = init ? init(initialArg) : initialArg;

  const formValues = {};
  const returnValues = {};

  if (['completedTraining', 'expiringTraining'].includes(type)) {
    const dateKey =
      type === 'completedTraining' ? 'validFromDate' : 'expiryDate';

    const [date, setDate] = useState(() => {
      return initialState[dateKey];
    });

    function handleDateChange(key, value) {
      const newDate = { ...date, [key]: value };

      setDate(newDate);
    }

    function handleDateRangeChange(from, to) {
      const newDate = { ...date };

      if (from !== undefined) newDate.customDateRangeFrom = from;
      if (to !== undefined) newDate.customDateRangeTo = to;

      setDate(newDate);
    }

    formValues[dateKey] = date;
    returnValues.handleDateChange = handleDateChange;
    returnValues.handleDateRangeChange = handleDateRangeChange;
  }

  if (type === 'currentTrainingStatus') {
    const [trainingStatusFilter, setTrainingStatusFilter] = useState(() => {
      return initialState.trainingStatusFilter;
    });

    function handleTrainingStatusFilterChange(key, value) {
      const newTrainingStatusFilter = { ...trainingStatusFilter, [key]: value };

      setTrainingStatusFilter(newTrainingStatusFilter);
    }

    formValues.trainingStatusFilter = trainingStatusFilter;
    returnValues.handleTrainingStatusFilterChange =
      handleTrainingStatusFilterChange;
  }

  const [estimates, setEstimates] = useState(() => {
    return initialState.estimates;
  });

  function handleEstimatesChange(key, value) {
    const newEstimates = { ...estimates, [key]: value };

    setEstimates(newEstimates);
  }

  formValues.estimates = estimates;
  returnValues.handleEstimatesChange = handleEstimatesChange;

  const [filters, setFilters] = useState(() => {
    return initialState.filters;
  });

  function handleFilterAddition(filter) {
    const newFilters = produce(filters, (draftState) => {
      draftState.selectedFilters.push(filter);
    });

    setFilters(newFilters);
  }

  function handleFilterRemoval(filter) {
    const newFilters = produce(filters, (draftState) => {
      const filterIndex = draftState.selectedFilters.findIndex(
        (id) => id === filter,
      );
      if (filterIndex !== -1) draftState.selectedFilters.splice(filterIndex, 1);
      draftState[filter] = initialArg.filters[filter];
    });

    setFilters(newFilters);
  }

  function handleFilterSelection(filter, value) {
    const newFilters = produce(filters, (draftState) => {
      draftState[filter].selectedIds.push(value);
    });

    setFilters(newFilters);
  }

  function handleFilterDeselection(filter, value) {
    const newFilters = produce(filters, (draftState) => {
      const valueIndex = draftState[filter].selectedIds.findIndex(
        (id) => id === value,
      );
      if (valueIndex !== -1)
        draftState[filter].selectedIds.splice(valueIndex, 1);
    });

    setFilters(newFilters);
  }

  function handleFilterChange(filter, key, value) {
    const newFilters = produce(filters, (draftState) => {
      draftState[filter][key] = value;
    });

    setFilters(newFilters);
  }

  formValues.filters = filters;
  returnValues.handleFilterAddition = handleFilterAddition;
  returnValues.handleFilterRemoval = handleFilterRemoval;
  returnValues.handleFilterSelection = handleFilterSelection;
  returnValues.handleFilterDeselection = handleFilterDeselection;
  returnValues.handleFilterChange = handleFilterChange;

  returnValues.formValues = formValues;

  return returnValues;
}
