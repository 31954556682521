import React, { useEffect, useReducer } from 'react';
import reducer, { initialState } from 'reducers/brandingsSettingsReducer';
import {
  getLogoState,
  persistBranding,
  editableBrandingAsValues,
} from 'components/helpers/resources/brandings';
import useToasts from 'components/hooks/useToasts';

import Tabs from 'components/application/Tabs';
import Form from 'components/brandings/Form';
import EmailPreview from 'components/brandings/EmailPreview';
import DownloadLandingPreview from 'components/brandings/DownloadLandingPreview';
import ResourceChangedToast from 'components/application/ResourceChangedToast';
import ToastRack from 'components/application/ToastRack';

const removeBrandingHref =
  'https://intercom.help/hands-hq/en/articles/5058888-customise-the-look-branding-of-your-emails-remove-powered-by-handshq';

export default function Settings({ isPoweredByEditable }) {
  const [brandingsSettings, dispatch] = useReducer(reducer, initialState);
  // pending: toats inside reducer?
  const [toasts, , addToast, handleBurnToast, addInedibleToast] = useToasts();

  const handleBrandingFetch = () => {
    axios
      .get('/branding')
      .then((response) => {
        const branding = response.data.data;
        const logo = response.data.included.find(
          (inclusion) => inclusion.type === 'brandingLogo',
        );
        dispatch({
          type: 'FETCHED_BRANDING',
          payload: { branding: branding, logo: logo },
        });
      })
      .catch(() => addInedibleToast);
  };

  useEffect(() => {
    handleBrandingFetch();
  }, []);

  // short-circuit if still pending
  if (brandingsSettings.application.pending.brandingFetch) {
    return null;
  }

  // derived values
  const isPersisted = !!brandingsSettings.domain.branding.id;
  const editableBranding = brandingsSettings.ui.editableBranding;
  const editableBrandingValues = editableBrandingAsValues(editableBranding);
  const logoState = getLogoState(
    brandingsSettings.domain.logo,
    brandingsSettings.ui.logo,
  );
  const logoSrc =
    !brandingsSettings.ui.logo.markedForDeletion &&
    brandingsSettings.ui.logo.src;
  const isEditingDisabled =
    brandingsSettings.application.pending.brandingPersisting;
  const isCustomBranding = brandingsSettings.application.isCustomBranding;

  // handlers
  const handleBrandingPersistanceRequest = (persistanceStrategy) => {
    dispatch({ type: 'REQUESTED_BRANDING_PERSISTANCE' });
    persistBranding(persistanceStrategy, editableBranding, {
      state: logoState,
      data: brandingsSettings.ui.logo.data,
    })
      .then((response) => {
        dispatch({
          type: 'PERSISTED_BRANDING',
          payload: {
            branding: response.data.data,
            logo: response.data.included.find(
              (inclusion) => inclusion.type === 'brandingLogo',
            ),
          },
        });
      })
      .then(addBrandingSavedToast)
      .catch(addInedibleToast);
  };
  const handleCreate = () => handleBrandingPersistanceRequest('create');
  const handleUpdate = () => handleBrandingPersistanceRequest('update');
  const handleUpload = (fileData) =>
    dispatch({
      type: 'SELECTED_LOGO',
      payload: { fileData: fileData, src: URL.createObjectURL(fileData) },
    });
  const handleColourToggle = (attributeName) =>
    dispatch({ type: 'TOGGLED_COLOUR', payload: { attribute: attributeName } });
  const handleColourChange = ({ attributeName, colour }) =>
    dispatch({
      type: 'CHANGED_COLOUR',
      payload: { value: colour, attribute: attributeName },
    });
  const handleLabelToggle = () => dispatch({ type: 'TOGGLED_LABEL' });
  const handleRemoveLogoClick = () => {
    dispatch({ type: 'LOGO_MARKED_FOR_DELETION' });
  };
  const createOrUpdate = () => (isPersisted ? handleUpdate() : handleCreate());
  const handleSaveClick = () =>
    isCustomBranding && !brandingsSettings.ui.logo.src ?
      dispatch({ type: 'INVALID_LOGO' })
    : createOrUpdate();
  const resetDefaults = () => dispatch({ type: 'RESET_DEFAULTS' });

  const addBrandingSavedToast = () => {
    addToast(
      <ResourceChangedToast
        onBurnToast={handleBurnToast}
        resource={`Settings`}
        status={'updated'}
      />,
    );
  };

  return (
    <>
      <h3 className='m-t-0 m-b-24 tw-text-l tw-font-semibold tw-tracking-auto tw-text-grey-900'>
        Customise RAMS email downloads
      </h3>
      <div className='column-container'>
        <div className='column column--double column--guttered'>
          <div className='svg-preview m-b-16 tw-bg-grey-025'>
            <Tabs modifiers={['centered']}>
              <DownloadLandingPreview
                label='Downloading'
                {...editableBrandingValues}
                logoSrc={logoSrc}
              />
              <EmailPreview
                label='Emails'
                {...editableBrandingValues}
                logoSrc={logoSrc}
              />
            </Tabs>
          </div>
        </div>
        <div className='column'>
          <Form
            branding={editableBranding}
            disabled={isEditingDisabled}
            invalidLogo={brandingsSettings.ui.logo.invalid}
            isCustomBranding={isCustomBranding}
            isPoweredByEditable={isPoweredByEditable}
            logoSrc={logoSrc}
            onColourChange={handleColourChange}
            onColourToggle={handleColourToggle}
            onLabelToggle={handleLabelToggle}
            onRemoveLogoClick={handleRemoveLogoClick}
            onSaveClick={handleSaveClick}
            onUpload={handleUpload}
            removeBrandingHref={removeBrandingHref}
            resetDefaults={resetDefaults}
          />
        </div>
      </div>
      <ToastRack toasts={toasts} />
    </>
  );
}
