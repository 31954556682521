import { useQuery, useInfiniteQuery } from '@tanstack/react-query';

import {
  fetchPersonnel,
  fetchPersonnelTraining,
  fetchAvailableFields,
  fetchPersonnelSubordinates,
} from 'components/mobileTrainingProfile/helpers/requests';

const usePersonnel = ({ onError, personnelId, select }) => {
  return useQuery({
    queryKey: ['personnel', personnelId],
    queryFn: async () => {
      const response = await fetchPersonnel({ personnelId });
      return response.data;
    },
    select,
    onError,
  });
};

const usePersonnelTraining = ({ onError, personnelId, select }) => {
  return useQuery({
    queryKey: ['personnel', personnelId, 'trainings'],
    queryFn: async () => {
      const response = await fetchPersonnelTraining({ personnelId });
      return response.data;
    },
    select,
    onError,
  });
};

const useInfinitePersonnelSubordinates = ({ onError, personnelId, select }) => {
  return useInfiniteQuery({
    queryKey: ['personnel', personnelId, 'subordinates'],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await fetchPersonnelSubordinates({
        personnelId,
        pageParam,
      });
      return response.data;
    },
    getNextPageParam,
    select,
    onError,
  });
};

const useAvailableFields = ({ onError, select }) => {
  return useQuery({
    queryKey: ['availableFields'],
    queryFn: async () => {
      const response = await fetchAvailableFields();
      return response.data;
    },
    select,
    onError,
  });
};

const getNextPageParam = (lastPage, allPages) => {
  return lastPage.meta.isLastPage ? undefined : allPages.length + 1;
};

export {
  usePersonnel,
  usePersonnelTraining,
  useInfinitePersonnelSubordinates,
  useAvailableFields,
};
