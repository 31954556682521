import React from 'react';
import voca from 'voca';
import { renderToString } from 'react-dom/server';
import baseContentStyle from 'tinymce/skins/handshq/content.min.css';
import CheckmarkIcon from '-!svg-react-loader?name=CheckmarkIcon!icons/ic-added.svg';
import HighlightedAmberIcon from '-!svg-react-loader?name=HighlightedAmberIcon!tinymce/icons/color-presets/highlighted-amber.svg';
import HighlightedBlueIcon from '-!svg-react-loader?name=HighlightedBlueIcon!tinymce/icons/color-presets/highlighted-blue.svg';
import HighlightedBrightAmberIcon from '-!svg-react-loader?name=HighlightedBrightAmberIcon!tinymce/icons/color-presets/highlighted-bright-amber.svg';
import HighlightedBrightRedIcon from '-!svg-react-loader?name=HighlightedBrightRedIcon!tinymce/icons/color-presets/highlighted-bright-red.svg';
import HighlightedGreenIcon from '-!svg-react-loader?name=HighlightedGreenIcon!tinymce/icons/color-presets/highlighted-green.svg';
import HighlightedRedIcon from '-!svg-react-loader?name=HighlightedRedIcon!tinymce/icons/color-presets/highlighted-red.svg';
import TextColorBlueIcon from '-!svg-react-loader?name=TextColorBlueIcon!tinymce/icons/color-presets/text-color-blue.svg';
import TextColorDefaultIcon from '-!svg-react-loader?name=TextColorDefaultIcon!tinymce/icons/color-presets/text-color-default.svg';
import TextColorGreenIcon from '-!svg-react-loader?name=TextColorGreenIcon!tinymce/icons/color-presets/text-color-green.svg';
import TextColorRedIcon from '-!svg-react-loader?name=TextColorRedIcon!tinymce/icons/color-presets/text-color-red.svg';

const colorPresets = {
  'text-color': {
    default: {},
    red: { color: '#B62320' },
    green: { color: '#1A7553' },
    blue: { color: '#2D54A8' },
  },
  highlighted: {
    red: { backgroundColor: '#F7D6D0', color: '#77181A' },
    amber: { backgroundColor: '#FAECC2', color: '#60290B' },
    green: { backgroundColor: '#CCF5DC', color: '#10503D' },
    blue: { backgroundColor: '#CCE5FC', color: '#162B78' },
    'bright-red': { backgroundColor: '#B62320', color: '#FFFFFF' },
    'bright-amber': { backgroundColor: '#F9C04E', color: '#0A182E' },
  },
};

const defaultForecolor = '#33445E';
const handsGreenColor = '#008000';
const handsRedColor = '#FF0000';
const highlightedColorPresetStyles = Object.values(colorPresets['highlighted']);

const validTextColors = Object.values(colorPresets['text-color']).reduce(
  (acc, textColorPreset) => {
    if (textColorPreset.color) acc.push(textColorPreset.color);

    return acc;
  },
  [handsGreenColor, handsRedColor],
);

function addClassFilter({ editor }) {
  // Used to convert hands-green and hands-red classes to inline styling
  const filterClasses = (nodes) => {
    nodes.forEach((node) => {
      if (node.name === 'p' || node.name === 'span') {
        const classes = node.attr('class');

        if (classes.includes('hands-green') || classes.includes('hands-red')) {
          node.attr('class', null);

          node.attr(
            'style',
            editor.dom.serializeStyle(
              classes.includes('hands-green') ?
                { color: handsGreenColor }
              : { color: handsRedColor },
            ),
          );
        }
      }
    });
  };

  editor.parser.addAttributeFilter('class', filterClasses);
  editor.serializer.addAttributeFilter('class', filterClasses);
}

function addColorPresetMenuButton({ editor }) {
  editor.ui.registry.addMenuButton('colorpreset', {
    fetch: (callback) => callback(colorPresetMenuItems({ editor })),
    icon: 'text-color',
  });
}

function addStyleFilter({
  editor,
  filterBackgroundColorAndColor,
  filterTextDecoration,
}) {
  // Added to ensure that the background-color, color and text-decoration styles are all valid,
  // otherwise this function (provided by TinyMCE support) filters the style out
  const filterStyles = (nodes) => {
    nodes.forEach((node) => {
      if (node.name === 'p' || node.name === 'span') {
        const styles = editor.dom.parseStyle(node.attr('style'));

        if (filterBackgroundColorAndColor) {
          const backgroundColorStyle = styles['background-color'];
          const colorStyle = styles['color'];

          if (
            !colorStyle ||
            invalidBackgroundColorAndColorStyle({
              backgroundColorStyle,
              colorStyle,
            })
          ) {
            if (backgroundColorStyle) delete styles['background-color'];
            if (colorStyle) delete styles['color'];
          }
        }

        if (filterTextDecoration) {
          const textDecorationStyle = styles['text-decoration'];

          if (textDecorationStyle) {
            if (textDecorationStyle.includes('underline')) {
              styles['text-decoration'] = 'underline';
            } else {
              delete styles['text-decoration'];
            }
          }
        }

        node.attr('style', editor.dom.serializeStyle(styles));
      }
    });
  };

  editor.parser.addAttributeFilter('style', filterStyles);
  editor.serializer.addAttributeFilter('style', filterStyles);
}

function colorPresetMenuItem({ editor, preset, styles, type }) {
  return {
    icon: `${type}-${preset}`,
    onAction: () => {
      if (editor.selection.isCollapsed()) {
        const bookmark = editor.selection.getBookmark();
        editor.focus();
        editor.selection.moveToBookmark(bookmark);
      }

      editor.formatter.remove('forecolor', { value: null }, undefined, true);
      editor.formatter.remove('hilitecolor', { value: null }, undefined, true);
      if (styles.color)
        editor.formatter.apply('forecolor', { value: styles.color });
      if (styles.backgroundColor)
        editor.formatter.apply('hilitecolor', {
          value: styles.backgroundColor,
        });

      editor.undoManager.add();
    },
    text: voca.capitalize(preset.replace(/-/g, ' ')),
    type: 'menuitem',
  };
}

function colorPresetMenuItems({ editor }) {
  return Object.entries(colorPresets).reduce((acc, [type, presets]) => {
    acc.push(colorPresetMenuSubHeadingItem({ type }));

    Object.entries(presets).forEach(([preset, styles]) => {
      acc.push(colorPresetMenuItem({ editor, preset, styles, type }));
    });

    return acc;
  }, []);
}

function colorPresetMenuSubHeadingItem({ type }) {
  return { text: voca.capitalize(type.replace(/-/g, ' ')), type: 'separator' };
}

function invalidBackgroundColorAndColorStyle({
  backgroundColorStyle,
  colorStyle,
}) {
  if (backgroundColorStyle) {
    return !highlightedColorPresetStyles.find(
      (style) =>
        style.backgroundColor === backgroundColorStyle.toUpperCase() &&
        style.color === colorStyle.toUpperCase(),
    );
  } else {
    return !validTextColors.includes(colorStyle.toUpperCase());
  }
}

function registerColorPresetIcons({ editor }) {
  editor.ui.registry.addIcon(
    'highlighted-amber',
    renderToString(<HighlightedAmberIcon />),
  );
  editor.ui.registry.addIcon(
    'highlighted-blue',
    renderToString(<HighlightedBlueIcon />),
  );
  editor.ui.registry.addIcon(
    'highlighted-bright-amber',
    renderToString(<HighlightedBrightAmberIcon />),
  );
  editor.ui.registry.addIcon(
    'highlighted-bright-red',
    renderToString(<HighlightedBrightRedIcon />),
  );
  editor.ui.registry.addIcon(
    'highlighted-green',
    renderToString(<HighlightedGreenIcon />),
  );
  editor.ui.registry.addIcon(
    'highlighted-red',
    renderToString(<HighlightedRedIcon />),
  );
  editor.ui.registry.addIcon(
    'text-color-blue',
    renderToString(<TextColorBlueIcon />),
  );
  editor.ui.registry.addIcon(
    'text-color-default',
    renderToString(<TextColorDefaultIcon />),
  );
  editor.ui.registry.addIcon(
    'text-color-green',
    renderToString(<TextColorGreenIcon />),
  );
  editor.ui.registry.addIcon(
    'text-color-red',
    renderToString(<TextColorRedIcon />),
  );
}

function removeMenuOnScroll({ editor }) {
  editor.on('ScrollWindow', (_e) => {
    document.querySelector('.tox-menu')?.remove();
    document
      .querySelector('.tox-tbtn--enabled')
      ?.classList?.remove('tox-tbtn--enabled');
  });
}

function initOptions({
  additionalContentStyleString = '',
  autoFocus,
  height,
  inline = false,
  minHeight,
  onInit,
  onSetup,
  placeholder,
  selector,
  toolbar,
  useSpellChecker = true,
}) {
  const isBoldAllowed = !!toolbar?.includes('bold');
  const isBulletListAllowed = !!toolbar?.includes('bullist');
  const isColorPresetAllowed = !!toolbar?.includes('colorpreset');
  const isItalicAllowed = !!toolbar?.includes('italic');
  const isNumberedListAllowed = !!toolbar?.includes('numlist');
  const isTableAllowed = !!toolbar?.includes('table');
  const isUnderlineAllowed = !!toolbar?.includes('underline');

  const plugins = [];
  const validElements = ['br', 'p'];
  const validPAndSpanStyles = [];

  const options = {
    branding: false,
    content_css: false,
    content_style: baseContentStyle.toString() + additionalContentStyleString,
    custom_colors: false,
    elementpath: false,
    extended_valid_elements: '-span[class]',
    external_plugins: { powerpaste: '/powerpaste/plugin-7.3.0.js' },
    menubar: false,
    powerpaste_googledocs_import: 'merge',
    powerpaste_html_import: 'merge',
    powerpaste_word_import: 'merge',
    resize: true,
    setup: (editor) => {
      onSetup?.(editor);

      editor.ui.registry.addIcon(
        'checkmark',
        renderToString(<CheckmarkIcon />),
      );

      if (isColorPresetAllowed) {
        registerColorPresetIcons({ editor });
        addColorPresetMenuButton({ editor });
      }

      if (isColorPresetAllowed || isUnderlineAllowed) {
        editor.on('preinit', () => {
          addStyleFilter({
            editor,
            filterBackgroundColorAndColor: isColorPresetAllowed,
            filterTextDecoration: isUnderlineAllowed,
          });

          if (isColorPresetAllowed) addClassFilter({ editor });
        });
      }

      if (isColorPresetAllowed || isTableAllowed || onInit) {
        editor.on('init', (_e) => {
          onInit?.(editor);

          if (isColorPresetAllowed || isTableAllowed)
            removeMenuOnScroll({ editor });
        });
      }
    },
    skin: false,
    statusbar: true,
    toolbar_location: 'top',
    ui_mode: 'split',
    valid_classes: {},
    valid_styles: {},
  };

  if (autoFocus) options.auto_focus = autoFocus;
  if (height) options.height = height;
  if (inline) options.inline = inline;
  if (minHeight) options.min_height = minHeight;
  if (placeholder) options.placeholder = placeholder;
  if (selector) options.selector = selector;
  if (toolbar) options.toolbar = `${toolbar} | undo redo`;

  if (useSpellChecker) {
    plugins.push('tinymcespellchecker');
    options.spellchecker_language = 'en_uk';
  }

  if (isBoldAllowed) validElements.push('strong');

  if (isBulletListAllowed || isNumberedListAllowed) {
    plugins.push('lists');
    validElements.push('li');
    if (isBulletListAllowed) validElements.push('ul');
    if (isNumberedListAllowed) validElements.push('ol');
  }

  if (isColorPresetAllowed) {
    options.color_default_foreground = defaultForecolor;
    options.valid_classes.span = 'hands-green hands-red';
    validPAndSpanStyles.push('background-color', 'color');
  }

  if (isItalicAllowed) validElements.push('em');

  if (isTableAllowed) {
    plugins.push('table');
    options.table_advtab = false;
    options.table_cell_advtab = false;
    options.table_default_attributes = {
      border: '1',
      class: 'hands-default-table',
    };
    options.table_row_advtab = false;
    options.table_toolbar = 'tabledelete';
    options.valid_classes.table = 'hands-default-table';
    options.valid_styles.table = 'border-collapse,height,width';
    options.valid_styles.td = 'height,text-align,vertical-align,width';
    options.valid_styles.th = 'height,text-align,vertical-align,width';
    options.valid_styles.tr = 'height';
    validElements.push(
      'table[*]',
      'colgroup[*]',
      'col[*]',
      'tbody[*]',
      'tr[*]',
      'th[*]',
      'td[*]',
    );
  }

  if (isUnderlineAllowed) {
    options.extended_valid_elements = '-span[class|style]';
    validElements[validElements.indexOf('p')] = 'p[style]';
    validPAndSpanStyles.push('text-decoration');
  }

  if (plugins.length) options.plugins = plugins.join(' ');

  options.valid_elements = validElements.join(',');

  if (validPAndSpanStyles.length) {
    options.valid_styles.p = validPAndSpanStyles.join(',');
    options.valid_styles.span = validPAndSpanStyles.join(',');
  }

  return options;
}

export { initOptions };
