import React, { useState, cloneElement } from 'react';
import { v4 as uuidv4 } from 'uuid';
import InedibleToast from 'components/application/InedibleToast';

export default function useToasts() {
  const [toasts, setToasts] = useState([]);

  const addToast = (toast) => {
    const toastKey = uuidv4();
    const element = cloneElement(toast, { key: toastKey, toastKey: toastKey });

    setToasts((toasts) => toasts.concat(element));
  };

  const handleBurnToast = (toastKey) => {
    setToasts((toasts) => {
      return toasts.filter((rackedToast) => rackedToast.key !== toastKey);
    });
  };

  const addInedibleToast = (options = {}) => {
    addToast(<InedibleToast {...options} onBurnToast={handleBurnToast} />);
  };

  return [toasts, setToasts, addToast, handleBurnToast, addInedibleToast];
}
