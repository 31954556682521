import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function LimitedTextField(props) {
  const {
    fieldError,
    maxFailedAttempts,
    maxLength,
    name,
    onChange,
    removeErrorStyling,
    value,
  } = props;

  const inputDisplayRef = useRef();

  const [paddingRight, setPaddingRight] = useState(0);
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [isReadOnly, setIsReadOnly] = useState(true);

  const resetFailedAttempts = () => {
    setFailedAttempts(0);
  };

  const readOnlyValue = value.length < maxLength ? value : `${value}...`;

  const handleInputChange = (event) => {
    if (event.target.value.length <= maxLength) {
      onChange(event);
    }
  };

  const handleInputKeyDown = (event) => {
    if (event.target.value.length >= maxLength) {
      setFailedAttempts((prevState) => prevState + 1);
    }
  };

  const handleInputFocus = (event) => {
    setIsReadOnly(false);
    removeErrorStyling(event);
  };

  const handleInputBlur = (_event) => {
    setIsReadOnly(true);
    resetFailedAttempts();
  };

  const inputClassName = (() => {
    switch (true) {
      case fieldError && fieldError.fieldHighlighted:
        return 'tw-border-red-600 hover:tw-border-red-600 focus-within:tw-border-red-600 hover:focus-within:tw-border-red-600';
      default:
        return 'tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300 placeholder:tw-text-grey-300';
    }
  })();

  useEffect(() => {
    if (value.length < maxLength && failedAttempts !== 0) {
      resetFailedAttempts();
    }
  }, [value]);

  useEffect(() => {
    setPaddingRight(inputDisplayRef.current.offsetWidth);
  }, [value]);

  return (
    <div className='app-form__input-text-with-display'>
      <input
        className={`app-form__input-text ${inputClassName}`}
        maxLength={maxLength}
        name={name}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onKeyDown={handleInputKeyDown}
        readOnly={isReadOnly}
        style={{ paddingRight: paddingRight }}
        type='text'
        value={isReadOnly ? readOnlyValue : value}
      />
      <div
        className={classNames(
          'app-form__input-display',
          failedAttempts >= maxFailedAttempts && 'tw-text-red-600',
        )}
        ref={inputDisplayRef}
      >
        {`${value.length} / ${maxLength}`}
      </div>
    </div>
  );
}

LimitedTextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  maxFailedAttempts: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

LimitedTextField.defaultProps = {
  maxFailedAttempts: 5,
  fieldError: {},
};
