import React from 'react';
import PropTypes from 'prop-types';

export default function Toggleable(props) {
  return props.isToggled ?
      <div>{props.secondary}</div>
    : <div onClick={() => props.setIsToggled(!props.isToggled)}>
        {props.primary}
      </div>;
}

Toggleable.propTypes = {
  isToggled: PropTypes.bool,
  setIsToggled: PropTypes.func,
};

Toggleable.defaultProps = {
  isToggled: false,
};
