import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import voca from 'voca';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

import CheckboxFilter from 'components/filters/CheckboxFilter';
import MultiSelectFilter from 'components/filters/MultiSelectFilter';

import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';
import Options from 'components/application/Options';
import OptionChoice from 'components/application/OptionChoice';
import OutlinedButton from 'components/application/buttons/OutlinedButton';

import ChevronUpIcon from '-!svg-react-loader?name=ChevronUpIcon!icons/chevron-up.svg';
import ChevronDownIcon from '-!svg-react-loader?name=ChevronDownIcon!icons/chevron-down.svg';

const filterOptionMap = {
  divisionFilter: 'Divisions',
  teamFilter: 'Teams',
  personnelTypeFilter: 'Personnel type',
  roleFilter: 'Roles',
  courseFilter: 'Courses',
  courseRequirementFilter: 'Course requirement',
  lineManagerFilter: 'Line managers',
};

export function sortLabelsAlphabetically(a, b) {
  return voca.lowerCase(a.label) > voca.lowerCase(b.label) ? 1 : -1;
}

export function sortDivisions(a, b) {
  if (a.record.attributes.primary) {
    return -1;
  } else if (b.record.attributes.primary) {
    return 1;
  } else {
    return sortLabelsAlphabetically(a, b);
  }
}

function PersonnelTypeFilter({ filter, onChange, onRemoveFilter }) {
  return (
    <CheckboxFilter
      checkboxes={[
        {
          name: 'personnel_type_filter_employees',
          label: 'Employees',
          value: filter.employees,
          onChange: (event) =>
            onChange('personnelTypeFilter', 'employees', event.target.checked),
        },
        {
          name: 'personnel_type_filter_sub_contractors',
          label: 'Sub-contractors',
          value: filter.subcontractors,
          onChange: (event) =>
            onChange(
              'personnelTypeFilter',
              'subcontractors',
              event.target.checked,
            ),
        },
      ]}
      label='Personnel type'
      onRemove={() => onRemoveFilter('personnelTypeFilter')}
    />
  );
}

function RoleFilter({
  autoFocus,
  filter,
  onChange,
  onDeselect,
  onFocus,
  onRemoveFilter,
  onSelect,
  validationError,
}) {
  return (
    <MultiSelectFilter
      autoFocus={autoFocus}
      checkboxes={[
        {
          name: 'role_filter_additional_training',
          label: 'Include additional training',
          value: filter.additionalTraining,
          tooltip:
            'Not including additional training will only show courses linked to the role(s)',
          onChange: (event) =>
            onChange('roleFilter', 'additionalTraining', event.target.checked),
        },
      ]}
      fieldError={validationError}
      id='role_filter_selected_ids'
      label='Roles'
      name='role_filter_selected_ids'
      onDeselect={(id) => onDeselect('roleFilter', id)}
      onFocus={() => onFocus('roleFilter')}
      onRemove={() => onRemoveFilter('roleFilter')}
      onSelect={(id) => onSelect('roleFilter', id)}
      placeholder='Search roles...'
      resourceLabel={(resource) => resource.attributes.position}
      resourceSort={sortLabelsAlphabetically}
      selectedIds={filter.selectedIds}
      type='role'
    />
  );
}

function CourseFilter({
  autoFocus,
  filter,
  onDeselect,
  onFocus,
  onRemoveFilter,
  onSelect,
  validationError,
}) {
  return (
    <MultiSelectFilter
      autoFocus={autoFocus}
      fieldError={validationError}
      id='course_filter_selected_ids'
      label='Courses'
      name='course_filter_selected_ids'
      onDeselect={(id) => onDeselect('courseFilter', id)}
      onFocus={() => onFocus('courseFilter')}
      onRemove={() => onRemoveFilter('courseFilter')}
      onSelect={(id) => onSelect('courseFilter', id)}
      placeholder='Search courses...'
      resourceLabel={(resource) => resource.attributes.name}
      resourceSort={sortLabelsAlphabetically}
      selectedIds={filter.selectedIds}
      type='course'
    />
  );
}

function CourseRequirementFilter({ filter, onChange, onRemoveFilter }) {
  return (
    <CheckboxFilter
      checkboxes={[
        {
          name: 'course_requirement_filter_required_training',
          label: 'Required training',
          value: filter.required,
          onChange: (event) =>
            onChange(
              'courseRequirementFilter',
              'required',
              event.target.checked,
            ),
        },
        {
          name: 'course_requirement_filter_optional_training',
          label: 'Optional training',
          value: filter.optional,
          onChange: (event) =>
            onChange(
              'courseRequirementFilter',
              'optional',
              event.target.checked,
            ),
        },
      ]}
      label='Course requirement'
      onRemove={() => onRemoveFilter('courseRequirementFilter')}
    />
  );
}

function LineManagerFilter({
  autoFocus,
  division,
  filter,
  onDeselect,
  onFocus,
  onRemoveFilter,
  onSelect,
  validationError,
}) {
  return (
    <MultiSelectFilter
      autoFocus={autoFocus}
      fieldError={validationError}
      id='line_manager_filter_selected_ids'
      label='Line managers'
      message={
        division.attributes.primary ?
          null
        : 'If a line manager is in a different division to the personnel they manage, those personnel may not be included in the report.'
      }
      name='line_manager_filter_selected_ids'
      onDeselect={(id) => onDeselect('lineManagerFilter', id)}
      onFocus={() => onFocus('lineManagerFilter')}
      onRemove={() => onRemoveFilter('lineManagerFilter')}
      onSelect={(id) => onSelect('lineManagerFilter', id)}
      placeholder='Search line managers...'
      resourceLabel={(resource) =>
        `${resource.attributes.firstName} ${resource.attributes.lastName}`
      }
      resourceSort={sortLabelsAlphabetically}
      selectedIds={filter.selectedIds}
      type='line_manager'
    />
  );
}

function DivisionFilter({
  autoFocus,
  filter,
  onDeselect,
  onFocus,
  onRemoveFilter,
  onSelect,
  validationError,
}) {
  return (
    <MultiSelectFilter
      autoFocus={autoFocus}
      fieldError={validationError}
      id='division_filter_selected_ids'
      label='Divisions'
      name='division_filter_selected_ids'
      onDeselect={(id) => onDeselect('divisionFilter', id)}
      onFocus={() => onFocus('divisionFilter')}
      onRemove={() => onRemoveFilter('divisionFilter')}
      onSelect={(id) => onSelect('divisionFilter', id)}
      placeholder='Search divisions...'
      resourceLabel={(resource) => resource.attributes.name}
      resourceSort={sortDivisions}
      selectedIds={filter.selectedIds}
      type='division'
    />
  );
}

function TeamFilter({
  autoFocus,
  filter,
  isDivisionFilterSelected,
  onDeselect,
  onFocus,
  onRemoveFilter,
  onSelect,
  validationError,
}) {
  return (
    <MultiSelectFilter
      autoFocus={autoFocus}
      fieldError={validationError}
      id='team_filter_selected_ids'
      label='Teams'
      message={
        isDivisionFilterSelected ?
          "If a team is not in a division that is being used as a filter, it won't be included in the report."
        : null
      }
      name='team_filter_selected_ids'
      onDeselect={(id) => onDeselect('teamFilter', id)}
      onFocus={() => onFocus('teamFilter')}
      onRemove={() => onRemoveFilter('teamFilter')}
      onSelect={(id) => onSelect('teamFilter', id)}
      placeholder='Search teams...'
      resourceLabel={(resource) => resource.attributes.name}
      resourceSort={sortLabelsAlphabetically}
      selectedIds={filter.selectedIds}
      type='team'
    />
  );
}

export default function Filters(props) {
  const {
    filters,
    onAddFilter,
    onChange,
    onDeselect,
    onFocus,
    onRemoveFilter,
    onSelect,
    validationErrors = {},
  } = props;

  const isInitialMount = useRef(true);
  const currentActor = useCurrentActor();

  const autoFocus = !isInitialMount.current;
  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();
  const { hasLineManagerAccess, hasTRPersonnelAccess, hasTeamManagerAccess } =
    trainingRegisterResourceManagementContext;
  const hasLineManagerOnlyAccess =
    hasLineManagerAccess && !(hasTRPersonnelAccess || hasTeamManagerAccess);

  const availableFilters = [
    'personnelTypeFilter',
    'roleFilter',
    'courseFilter',
    'courseRequirementFilter',
  ];

  const filterComponents = {
    personnelTypeFilter: (
      <PersonnelTypeFilter
        filter={filters.personnelTypeFilter}
        onChange={onChange}
        onRemoveFilter={onRemoveFilter}
      />
    ),
    roleFilter: (
      <RoleFilter
        autoFocus={autoFocus}
        filter={filters.roleFilter}
        onChange={onChange}
        onDeselect={onDeselect}
        onFocus={onFocus}
        onRemoveFilter={onRemoveFilter}
        onSelect={onSelect}
        validationError={validationErrors.roleFilter}
      />
    ),
    courseFilter: (
      <CourseFilter
        autoFocus={autoFocus}
        filter={filters.courseFilter}
        onDeselect={onDeselect}
        onFocus={onFocus}
        onRemoveFilter={onRemoveFilter}
        onSelect={onSelect}
        validationError={validationErrors.courseFilter}
      />
    ),
    courseRequirementFilter: (
      <CourseRequirementFilter
        filter={filters.courseRequirementFilter}
        onChange={onChange}
        onRemoveFilter={onRemoveFilter}
      />
    ),
  };

  const selectedSet = new Set(filters.selectedFilters);

  if (!hasLineManagerOnlyAccess) {
    availableFilters.push('lineManagerFilter');

    filterComponents['lineManagerFilter'] = (
      <LineManagerFilter
        autoFocus={autoFocus}
        division={currentActor.division}
        filter={filters.lineManagerFilter}
        onDeselect={onDeselect}
        onFocus={onFocus}
        onRemoveFilter={onRemoveFilter}
        onSelect={onSelect}
        validationError={validationErrors.lineManagerFilter}
      />
    );
  }

  if (
    trainingRegisterResourceManagementContext.hasTeamViewableAccess &&
    !hasLineManagerOnlyAccess
  ) {
    availableFilters.push('teamFilter');
    filterComponents['teamFilter'] = (
      <TeamFilter
        autoFocus={autoFocus}
        filter={filters.teamFilter}
        isDivisionFilterSelected={selectedSet.has('divisionFilter')}
        onDeselect={onDeselect}
        onFocus={onFocus}
        onRemoveFilter={onRemoveFilter}
        onSelect={onSelect}
        validationError={validationErrors.teamFilter}
      />
    );
  }

  if (currentActor.division.attributes.primary) {
    availableFilters.unshift('divisionFilter');

    filterComponents['divisionFilter'] = (
      <DivisionFilter
        autoFocus={autoFocus}
        filter={filters.divisionFilter}
        onDeselect={onDeselect}
        onFocus={onFocus}
        onRemoveFilter={onRemoveFilter}
        onSelect={onSelect}
        validationError={validationErrors.divisionFilter}
      />
    );
  }

  const areAllFiltersSelected = availableFilters.every((filter) =>
    selectedSet.has(filter),
  );

  useEffect(() => {
    if (isInitialMount.current) isInitialMount.current = false;
  }, []);

  return (
    <>
      <div className='tw-flex tw-items-center'>
        <label className='tw-m-0 tw-mr-2 tw-text-l tw-font-semibold tw-tracking-auto'>
          Filters
        </label>
        <Tooltip
          placement='top'
          tooltip={
            <p className='tw-m-0'>
              Filters are combined e.g.{' '}
              <span className='tw-font-bold'>
                Engineers + First Aid at Work
              </span>{' '}
              will only include personnel with both that role and that course
            </p>
          }
          trigger='hover'
        >
          <CircleQuestion />
        </Tooltip>
      </div>
      <div className='tw-mt-6'>
        {filters.selectedFilters.map((filter) => {
          return (
            <div className='tw-mb-6' key={filter}>
              {filterComponents[filter]}
            </div>
          );
        })}
        <Options
          align='left'
          btnToggle={(isOpen) => {
            return (
              <div className='tooltip-parent'>
                <OutlinedButton
                  color='grey'
                  disabled={areAllFiltersSelected}
                  onClick={() => {}}
                  size='sm'
                >
                  <span>Add filter</span>
                  <ChevronUpIcon
                    className='-tw-mb-[1px] -tw-mr-[6px]'
                    height={24}
                    style={{ display: isOpen ? 'inline-block' : 'none' }}
                    width={24}
                  />
                  <ChevronDownIcon
                    className='-tw-mb-[1px] -tw-mr-[6px]'
                    height={24}
                    style={{ display: isOpen ? 'none' : 'inline-block' }}
                    width={24}
                  />
                </OutlinedButton>
                {areAllFiltersSelected && (
                  <Tooltip
                    placement='top'
                    tooltip='All filters have been added'
                    trigger='hover'
                  />
                )}
              </div>
            );
          }}
          buttonDisabled={areAllFiltersSelected}
          listStyle='tw-w-52 [&_li]:tw-border-none'
          toggleStyle='tw-inline-block'
        >
          {availableFilters.map((filter) => {
            return (
              !selectedSet.has(filter) && (
                <OptionChoice
                  key={filter}
                  onClick={(setOptionsOpen) => {
                    setOptionsOpen(false);
                    onAddFilter(filter);
                  }}
                >
                  <a className='tw-text-m tw-font-medium tw-tracking-auto tw-text-grey-700 hover:tw-text-grey-700'>
                    {filterOptionMap[filter]}
                  </a>
                </OptionChoice>
              )
            );
          })}
        </Options>
      </div>
    </>
  );
}

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  validationErrors: PropTypes.object,
  onAddFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
