import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useBreadBoard } from 'components/contexts/Toaster';
import { useInView } from 'react-intersection-observer';
import 'intersection-observer';

import { personDisplayName } from 'components/helpers/users.js';
import { resourceShape } from 'components/helpers/serialisableResources';
import ChevronRightIcon from '-!svg-react-loader?name=ChevronRightIcon!icons/chevron-right-no-padding.svg';
import Tooltip from 'components/application/Tooltip';

const moreBreakpoint = 3;
export default function PersonnelManagementDetails(props) {
  const { currentPersonnel, lineManager } = props;
  const [currentSubordinates, setSubordinates] = useState({
    loaded: false,
    collection: [],
    metaData: { currentPage: 0, isLastPage: false, totalCount: null },
  });
  const [showAllSubordinates, setShowAllSubordinates] = useState(false);
  const [loadMoreRef, loadMoreInView] = useInView();
  const breadBoard = useBreadBoard();

  const shouldViewAllSubordinates =
    (currentSubordinates.collection.length > moreBreakpoint &&
      showAllSubordinates) ||
    currentSubordinates.collection.length <= moreBreakpoint;
  const viewableCollection =
    shouldViewAllSubordinates ?
      currentSubordinates.collection
    : currentSubordinates.collection.slice(0, 3);

  useEffect(() => {
    if (
      !currentSubordinates.loaded ||
      (!currentSubordinates.metaData.isLastPage && loadMoreInView)
    )
      fetchSubordinates();
  }, [
    currentSubordinates.loaded,
    currentSubordinates.metaData.isLastPage,
    loadMoreInView,
  ]);

  const fetchSubordinates = () => {
    axios
      .get(`/personnel/${currentPersonnel.id}/subordinates`, {
        params: {
          page: parseInt(currentSubordinates.metaData.currentPage) + 1,
        },
      })
      .then((response) => {
        setSubordinates((prevState) => ({
          loaded: true,
          collection: prevState.collection.concat(response.data.data),
          metaData: response.data.meta,
        }));
      })
      .catch(breadBoard.addInedibleToast);
  };

  const lineManagerName =
    lineManager ? personDisplayName(lineManager.attributes) : 'Not set';

  return (
    <>
      <div className='tw-mb-6'>
        <h4 className='tw-mb-2 tw-text-m tw-font-semibold'>Line Manager</h4>
        {lineManager ?
          <PersonnelPill
            displayName={lineManagerName}
            isDisabled={!lineManager.meta.isReadable}
            personnel={lineManager}
          />
        : <div className='popup__body-message tw-text-grey-500'>Not set</div>}
      </div>
      <div className='tw-mb-6'>
        <h4 className='tw-mb-2 tw-text-m tw-font-semibold'>Direct Reports</h4>
        <ul className='tw-m-0 tw-list-none tw-p-0 tw-text-left'>
          {viewableCollection.map((subordinate) => (
            <li className='tw-mb-2' key={subordinate.id}>
              <PersonnelPill
                displayName={personDisplayName(subordinate.attributes)}
                isDisabled={!subordinate.meta.isReadable}
                personnel={subordinate}
              />
            </li>
          ))}
          {!currentSubordinates.metaData.isLastPage && showAllSubordinates && (
            <li className='popup__body--li' ref={loadMoreRef}>
              Loading subordinates...
            </li>
          )}
        </ul>
        {currentSubordinates.collection.length == 0 && (
          <div className='popup__body-message tw-text-grey-500'>Not set</div>
        )}

        {!showAllSubordinates &&
          currentSubordinates.loaded &&
          currentSubordinates.collection.length > moreBreakpoint && (
            <div className='flex flex--vertically-centered'>
              <button
                className='app-link tw-bg-transparent tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
                onClick={() => setShowAllSubordinates(true)}
              >
                Show all (
                {currentSubordinates.metaData.totalCount - moreBreakpoint} more)
              </button>
            </div>
          )}
      </div>
    </>
  );
}

export function PersonnelPill(props) {
  const { displayName, isDisabled, personnel } = props;

  return (
    <div
      className={classNames(
        'tw-inline-flex tw-items-center tw-rounded-[16px] tw-border-none tw-bg-grey-050',
        isDisabled ? 'tooltip-parent' : (
          'hover:tw-bg-grey-100 active:tw-bg-grey-200'
        ),
      )}
    >
      <a
        className='tw-min-h-8 tw-inline-flex tw-px-3 tw-py-2 tw-text-grey-700'
        data-disabled={isDisabled}
        href={`/personnel/${personnel.id}`}
      >
        <div className='tw-inline-flex tw-items-center'>
          <div className='tw-max-w-[188px] tw-text-s tw-font-normal tw-tracking-wide'>
            {displayName}
          </div>
          {!isDisabled && (
            <ChevronRightIcon
              className='tw-ml-2 tw-flex-shrink-0'
              height={10}
              width={6}
            />
          )}
        </div>
      </a>
      {isDisabled && (
        <Tooltip
          arrow
          className='tw-ml-6'
          placement='top'
          tooltip='You don’t have permission to view this personnel'
          trigger='hover'
        />
      )}
    </div>
  );
}
PersonnelManagementDetails.propTypes = {
  currentPersonnel: PropTypes.object.isRequired,
  lineManager: resourceShape('lineManager'),
};

PersonnelPill.propTypes = {
  personnel: PropTypes.object.isRequired,
  displayName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};
