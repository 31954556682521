import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export default function OneTimePasswordSignInCard({
  inputRefs,
  onFormPasteCapture,
  onInputClick,
  onInputKeyUp,
  onSubmit,
  otpCode,
}) {
  useEffect(() => {
    if (otpCode.join('').length < 6) {
      return;
    }
    onSubmit(otpCode);
  }, [otpCode]);

  useEffect(() => {
    inputRefs[0].current.focus();
  }, []);

  return (
    <form onPasteCapture={onFormPasteCapture}>
      <div className='tw-space-18 tw-flex tw-w-full tw-flex-col'>
        <div className='tw-mx-auto tw-flex tw-w-full tw-max-w-xs tw-flex-row tw-items-center tw-justify-between'>
          {inputRefs.map((ref, i) => (
            <input
              className='otp tw-text-lg tw-flex tw-h-10 tw-w-10 tw-flex-col tw-items-center tw-justify-center tw-rounded-xl tw-border-1 tw-border-solid tw-border-grey-300 tw-bg-white tw-py-2.5 tw-text-center tw-caret-transparent tw-outline-none focus:tw-border-blue-300'
              key={i}
              maxLength='1'
              onChange={() => {}}
              onClick={(e) => onInputClick(e, i)}
              onKeyUp={onInputKeyUp}
              ref={ref}
              type='text'
              value={otpCode[i]}
            />
          ))}
        </div>
      </div>
    </form>
  );
}

OneTimePasswordSignInCard.propTypes = {
  inputRefs: PropTypes.array.isRequired,
  otpCode: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onInputKeyUp: PropTypes.func.isRequired,
  onFormPasteCapture: PropTypes.func.isRequired,
  onInputClick: PropTypes.func.isRequired,
};
