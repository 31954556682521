import React, { useEffect, useState } from 'react';
import Options from 'components/application/Options';
import DocumentGenerator from 'components/application/DocumentGenerator';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import ChevronUpIcon from '-!svg-react-loader?name=ChevronUpIcon!icons/chevron-up.svg';
import ChevronDownIcon from '-!svg-react-loader?name=ChevronDownIcon!icons/chevron-down.svg';
import AttachmentIcon from '-!svg-react-loader?name=AttachmentIcon!icons/attachment.svg';
import Polling from 'handshq-app/utilities/polling';
import OptionChoice from 'components/application/OptionChoice';

export default function TrainingDownloadOptions(props) {
  const {
    disabled,
    documentGeneratorParams,
    documentGeneratorUrl,
    emailReportUrl,
    evidenceStatusUrl,
    setTrainingEvidenceProcessed,
    trainingEvidenceProcessed,
  } = props;

  const [mutableDocumentGeneratorParams, setMutableDocumentGeneratorParams] =
    useState(documentGeneratorParams);
  const [isGeneratingDocument, setIsGeneratingDocument] = useState(false);

  const statusPoller = new Polling({
    interval: 1000,
    url: evidenceStatusUrl,
    responseCheck: function (response) {
      return response.data.processed;
    },
    successCallback: (_response) => {
      setTrainingEvidenceProcessed(true);
    },
    failureCallback: (_response) => {
      console.error('could not load attachments status');
    },
  });

  useEffect(() => {
    if (!trainingEvidenceProcessed) {
      statusPoller.start();
    }
    return () => {
      if (statusPoller) {
        statusPoller.end();
      }
    };
  }, [trainingEvidenceProcessed]);

  const startReportDownload = (extraDocumentGeneratorParams) => {
    setMutableDocumentGeneratorParams({
      ...mutableDocumentGeneratorParams,
      ...extraDocumentGeneratorParams,
    });
    setIsGeneratingDocument(true);
  };

  return (
    <React.Fragment>
      <Options
        btnToggle={(isOpen) => {
          return (
            <OutlinedButton
              color='grey'
              disabled={disabled}
              onClick={() => {}}
              size='sm'
            >
              <AttachmentIcon className='m-l--4' height={18} width={18} />
              <span className='p-l-4 p-r-4'>Training report</span>
              <ChevronUpIcon
                className='m-r--8'
                height={24}
                style={{ display: isOpen ? 'inline-block' : 'none' }}
                width={24}
              />
              <ChevronDownIcon
                className='m-r--8'
                height={24}
                style={{ display: isOpen ? 'none' : 'inline-block' }}
                width={24}
              />
            </OutlinedButton>
          );
        }}
        buttonDisabled={disabled}
        key={'trainingDownload'}
      >
        <OptionChoice
          onClick={(setOptionsOpen) => {
            startReportDownload({ with_evidence: true });
            setOptionsOpen(false);
          }}
        >
          <a
            className={
              'download_button tw-text-m tw-font-medium tw-tracking-auto tw-text-grey-700 hover:tw-text-grey-700'
            }
          >
            {' '}
            Download with evidence{' '}
          </a>
        </OptionChoice>
        <OptionChoice
          onClick={(setOptionsOpen) => {
            startReportDownload({ with_evidence: false });
            setOptionsOpen(false);
          }}
        >
          <a
            className={
              'download_button tw-text-m tw-font-medium tw-tracking-auto tw-text-grey-700 hover:tw-text-grey-700'
            }
          >
            {' '}
            Download without evidence{' '}
          </a>
        </OptionChoice>
        {emailReportUrl && (
          <OptionChoice>
            <a
              className='tw-text-m tw-font-medium tw-tracking-auto tw-text-grey-700 hover:tw-text-grey-700'
              href={emailReportUrl}
            >
              {' '}
              Email report{' '}
            </a>
          </OptionChoice>
        )}
      </Options>
      <DocumentGenerator
        generateParams={mutableDocumentGeneratorParams}
        generateUrl={documentGeneratorUrl}
        isGenerating={isGeneratingDocument}
        modalFailureMessage='There was a problem creating the PDF. Please try again'
        modalHeader='Download training report'
        modalSuccessMessage='Training report ready to download'
        setIsGenerating={setIsGeneratingDocument}
      />
    </React.Fragment>
  );
}
