import React from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';

import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

import RowBar from 'components/application/RowBar';
import Tooltip from 'components/application/Tooltip';

import GreyCrossIcon from '-!svg-react-loader?name=GreyTickIcon!icons/status-ic-grey-cross.svg';
import PersonnelTrainingDownload from 'components/training/PersonnelTrainingDownload';
import GreyTickIcon from '-!svg-react-loader?name=GreyTickIcon!icons/status-ic-grey-tick.svg';
import RedCrossIcon from '-!svg-react-loader?name=RedCrossIcon!icons/status-ic-red.svg';
import GreenTick from '-!svg-react-loader?name=GreenTick!icons/status-ic-green.svg';
import AmberIcon from '-!svg-react-loader?name=AmberIcon!icons/status-ic-amber.svg';
import CircleQuestion from 'components/application/CircleQuestion';

import classNames from 'classnames';

const statusTooltip = (courses, status) => {
  if (!courses.length) return '';

  const { name } = courses[0].attributes;
  const otherCourseCount = courses.length - 1;
  const statusText =
    status === 'invalid' ? 'is missing or expired' : 'expires soon';

  switch (courses.length) {
    case 1:
      return `${name} ${statusText}`;
    case 2:
      return `${name} and ${otherCourseCount} other course ${status === 'invalid' ? 'is missing or expired' : 'expire soon'}`;
    default:
      return `${name} and ${otherCourseCount} other courses ${status === 'invalid' ? 'are missing or expired' : 'expire soon'}`;
  }
};

export default function RoleTrainingBar(props) {
  const { isViewingOwnProfile } = useTrainingRegisterResources();
  const { courses, status } = props.trainingStatusReport;

  const notHaveText =
    isViewingOwnProfile ? "You don't have" : `${props.name} doesn\'t have`;
  const haveText = isViewingOwnProfile ? 'You have' : `${props.name} has`;

  const shouldTruncateRoleName =
    props.role && props.role.attributes.position.length > 150;

  const activeContent = (() => {
    switch (status) {
      case 'noRequirements':
        return (
          <div className='flex flex--vertically-centered m-l--4'>
            <GreyTickIcon className='tw-h-7 [&_rect]:tw-fill-grey-300' />
            <span className='m-l-12'>{notHaveText} any required training</span>
          </div>
        );

      case 'expiring':
        return (
          <div className='flex flex--vertically-centered m-l--4'>
            <AmberIcon className='tw-h-7 [&_rect]:tw-fill-amber-400' />
            <span
              className={classNames(
                'truncated-text-container__two-lines tw-ml-3 tw-mr-2 tw-max-w-[545px]',
                shouldTruncateRoleName &&
                  'truncated-text-container__two-lines-overflow',
              )}
            >
              {haveText} all the required training but it expires soon
            </span>
            <Tooltip
              placement='top'
              tooltip={statusTooltip(courses, status)}
              trigger='hover'
            >
              <CircleQuestion />
            </Tooltip>
          </div>
        );

      case 'invalid':
        return (
          <div className='flex flex--vertically-centered m-l--4'>
            <RedCrossIcon className='tw-h-7 [&_rect]:tw-fill-red-500' />
            <span
              className={classNames(
                'truncated-text-container__two-lines tw-ml-3 tw-mr-2 tw-max-w-[545px]',
                shouldTruncateRoleName &&
                  'truncated-text-container__two-lines-overflow',
              )}
            >
              {notHaveText} all the required training
            </span>
            <Tooltip
              placement='top'
              tooltip={statusTooltip(courses, status)}
              trigger='hover'
            >
              <CircleQuestion />
            </Tooltip>
          </div>
        );

      case 'valid':
        return (
          <div className='flex flex--vertically-centered m-l--4'>
            <GreenTick className='tw-h-7 [&_rect]:tw-fill-green-400' />
            <span
              className={classNames(
                'truncated-text-container__two-lines tw-ml-3 tw-mr-2 tw-max-w-[545px]',
                shouldTruncateRoleName &&
                  'truncated-text-container__two-lines-overflow',
              )}
            >
              {haveText} the required training
            </span>
            <Tooltip
              placement='top'
              tooltip='All required courses are up to date'
              trigger='hover'
            >
              <CircleQuestion />
            </Tooltip>
          </div>
        );
    }
  })();

  const archivedContent = (() => {
    return (
      <div className='flex flex--vertically-centered m-l--4'>
        <GreyCrossIcon className='tw-h-7 [&_rect]:tw-fill-grey-300' />
        <span className='m-l-12'>{props.name} is archived</span>
      </div>
    );
  })();

  let disabledReason;
  if (props.isArchived) {
    disabledReason = 'isArchived';
  } else if (status === 'noRequirements' && props.training.length == 0) {
    disabledReason = 'noRecords';
  } else if (!props.trainingEvidenceProcessed) {
    disabledReason = 'processingAttachments';
  }

  const disabledTooltip = {
    isArchived: "Report can't be downloaded because the personnel is archived",
    noRecords: 'No records to download',
    processingAttachments: 'Currently uploading evidence',
  }[disabledReason];

  const content = props.isArchived ? archivedContent : activeContent;

  return (
    <React.Fragment>
      <RowBar
        actions={
          <div className='tooltip-parent'>
            <PersonnelTrainingDownload
              disabled={!!disabledReason}
              onTrainingEvidenceProcessed={props.onTrainingEvidenceProcessed}
              personnelId={props.personnelId}
              roleId={props.role ? props.role.id : null}
              trainingEvidenceProcessed={props.trainingEvidenceProcessed}
            />
            {disabledReason && (
              <Tooltip
                placement='top'
                tooltip={disabledTooltip}
                trigger='hover'
              />
            )}
          </div>
        }
        content={!props.isLoading && content}
        modifiers={
          !props.isLoading && props.roleHasRequiredCourses ?
            ['large', 'border-bottom-none']
          : ['large']
        }
      />
    </React.Fragment>
  );
}

RoleTrainingBar.propTypes = {
  role: PropTypes.object,
  name: PropTypes.string.isRequired,
  isArchived: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  statusReport: PropTypes.shape({
    courses: PropTypes.arrayOf(resourceShape('courses')),
    status: PropTypes.string,
  }),
  loaded: PropTypes.bool,
  onAddRole: PropTypes.func,
  roleHasRequiredCourses: PropTypes.bool,
};

RoleTrainingBar.defaultProps = {
  isLoading: false,
  roleHasRequiredCourses: true,
};
