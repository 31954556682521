import React from 'react';

export default function Table(props) {
  return (
    <>
      <table
        className={`table handshq__table handshq__table--spaced handshq__table--middled tw-border-grey-100 tw-bg-white ${props.tableClassName}`}
      >
        <thead className='handshq__thead tw-border-grey-100 tw-bg-grey-050'>
          {props.headers}
        </thead>
        <tbody
          className={`handshq__tbody tw-border-grey-100 ${props.tbodyClassName}`}
        >
          {props.rows}
        </tbody>
      </table>
      {props.rows.length === 0 && props.blankState}
    </>
  );
}

Table.defaultProps = {
  tableClassName: '',
  tbodyClassName: '',
};
