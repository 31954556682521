import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useBodyOverflowToggle from 'components/hooks/useBodyOverflowToggle';
import * as IntercomHelperModule from 'components/helpers/intercom';
import classNames from 'classnames';

export default function SidePanel(props) {
  const baseHeaderHeight = 120;
  const scrollableRange = 64;
  const [headerOptions, setHeaderOptions] = useState({});
  const [bodyStyleOptions, setBodyStyleOptions] = useState({});

  const bodyRef = props.bodyRef || React.useRef();

  const scrollToTop = () => {
    bodyRef.current.scrollTop = 0;
  };

  const allowedScrollDistance = () => {
    return props.scrolledAmount > scrollableRange ?
        scrollableRange
      : props.scrolledAmount;
  };

  const scrollableHeaderStyle = (height) => {
    return { height: height };
  };

  const scrollableBodyStyle = (spacing) => {
    return { top: -spacing, paddingBottom: spacing };
  };

  const handleScroll = (e) => {
    if (props.setScrolledAmount) props.setScrolledAmount(e.target.scrollTop);
  };

  useBodyOverflowToggle(props.isOpen);

  useEffect(() => {
    if (props.isOpen && bodyRef.current) {
      scrollToTop();
    }
  }, [props.contentContext, props.isOpen]);

  useEffect(() => {
    if (props.isOpen) {
      props.onOpen();
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (props.submitDisabled) {
      scrollToTop();
    }
  }, [props.submitDisabled]);

  useEffect(() => {
    if (props.scrollHeader) {
      const headerHeightRemaining = baseHeaderHeight - allowedScrollDistance();
      setHeaderOptions(scrollableHeaderStyle(headerHeightRemaining));
      setBodyStyleOptions(
        scrollableBodyStyle(baseHeaderHeight - headerHeightRemaining),
      );
    }
  }, [props.scrolledAmount]);

  useEffect(() => {
    if (!props.scrollHeader) {
      setHeaderOptions({});
      setBodyStyleOptions({});
    }
  }, [props.scrollHeader]);

  useEffect(() => {
    IntercomHelperModule.syncIntercomIconVisibility({ hidden: props.isOpen });
  }, [props.isOpen]);

  const colorClassName = {
    secondary: 'tw-bg-mint-500',
    tertiary: 'tw-bg-white tw-border-b-grey-200',
  };

  const closeIconColor =
    props.color == 'secondary' ?
      'before:tw-bg-white after:tw-bg-white'
    : 'before:tw-bg-grey-500 after:tw-bg-grey-500';

  return (
    <React.Fragment>
      {props.isOpen && (
        <div
          className='popup__backdrop'
          onClick={props.onBackdropClick || props.closeCallback}
        ></div>
      )}
      <div
        className={classNames(
          'side-panel',
          props.isOpen && 'side-panel__open tw-shadow-lg',
        )}
      >
        <div
          className={`popup__header popup__header--${props.color} ${colorClassName[props.color] ? colorClassName[props.color] : ''}`}
          style={headerOptions}
        >
          {props.displayClose && (
            <div
              className={classNames(
                "side-panel__close tw-h-[18px] tw-w-[18px] tw-cursor-pointer before:tw-absolute before:tw-left-2 before:tw-h-[18px] before:tw-w-[2px] before:tw-rotate-45 before:tw-content-[''] after:tw-absolute after:tw-left-2 after:tw-h-[18px] after:tw-w-[2px] after:-tw-rotate-45 after:tw-content-['']",
                closeIconColor,
              )}
              onClick={props.closeCallback}
            ></div>
          )}
          {props.headerContent}
        </div>
        <div
          className='popup__body tw-bg-white'
          onScroll={handleScroll}
          ref={bodyRef}
          style={bodyStyleOptions}
        >
          {props.bodyContent}
        </div>
        <div className='popup__footer tw-bg-white'>{props.footerContent}</div>
      </div>
    </React.Fragment>
  );
}

SidePanel.propTypes = {
  isOpen: PropTypes.bool,
  color: PropTypes.string,
  displayClose: PropTypes.bool,
  closeCallback: PropTypes.func.isRequired,
  headerContent: PropTypes.any.isRequired,
  bodyContent: PropTypes.any,
  footerContent: PropTypes.any.isRequired,
};

SidePanel.defaultProps = {
  isOpen: false,
  color: 'blue',
  displayClose: false,
  scrolledAmount: 0,
  contentContext: 'default',
  bodyRef: null,
  onOpen: () => void 0,
};
