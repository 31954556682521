import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const maxPageCount = 5;

export default function Paginator({ currentPage, onClick, totalPages }) {
  function initialPage() {
    if (currentPage <= Math.ceil(maxPageCount / 2) + 1) {
      return 1;
    } else if (currentPage >= totalPages - maxPageCount / 2) {
      return Math.floor(totalPages - maxPageCount + 1);
    } else {
      return currentPage - Math.floor(maxPageCount / 2);
    }
  }

  function renderPages() {
    const initialPageNum = initialPage();
    const pageCount = Math.min(initialPageNum + maxPageCount - 1, totalPages);
    const pages = [];

    for (let pageNum = initialPageNum; pageNum <= pageCount; pageNum++) {
      const isCurrentPage = pageNum === currentPage;

      pages.push(
        isCurrentPage ?
          <span className='tw-mx-[6px] tw-my-0 tw-text-grey-025' key={pageNum}>
            {pageNum}
          </span>
        : <a
            className='tw-mx-[6px] tw-my-0 tw-text-blue-200 hover:tw-text-blue-100 focus:tw-text-blue-200'
            data-page={pageNum}
            href='#0'
            key={pageNum}
            onClick={onClick}
          >
            {pageNum}
          </a>,
      );
    }
    return pages;
  }

  function renderStep(type) {
    const stepClassName =
      type === 'prev' ?
        'tw-mr-6 after:tw-right-[-12px]'
      : 'tw-ml-6 after:tw-left-[-12px]';

    return (
      <a
        className={classNames(
          "tw-relative tw-inline-flex tw-h-8 tw-items-center tw-rounded-sm tw-p-[6px] tw-text-blue-200 after:tw-absolute after:tw-h-5 after:tw-w-px after:tw-bg-grey-500 after:tw-content-[''] hover:tw-bg-grey-700 hover:tw-text-blue-100 focus:tw-text-blue-200",
          stepClassName,
        )}
        data-page={currentPage + (type === 'prev' ? -1 : +1)}
        href='#0'
        onClick={onClick}
      >
        {type === 'prev' ?
          <React.Fragment>&lt;&nbsp;&nbsp;Previous</React.Fragment>
        : <React.Fragment>Next&nbsp;&nbsp;&gt;</React.Fragment>}
      </a>
    );
  }

  return (
    <nav className='tw-inline-flex tw-h-10 tw-items-center tw-rounded-md tw-bg-grey-900 tw-p-1 tw-font-medium'>
      {currentPage > 1 && renderStep('prev')}
      {renderPages()}
      {currentPage < totalPages && renderStep('next')}
    </nav>
  );
}

Paginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};
