import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  Fragment,
} from 'react';
import moment from 'moment';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useBreadBoard } from 'components/contexts/Toaster';
import { TrackedPersonnelContext } from 'components/contexts/TrackedPersonnelContext';
import { TrainingRegisterResourceManagementContext } from 'components/contexts/TrainingRegisterResourceManagementContext';
import useForm from 'components/hooks/useForm';
import useRequestError from 'components/hooks/useRequestError';
import useSidePanel from 'components/hooks/useSidePanel';
import useModal from 'components/hooks/useModal';
import usePersonnelForm from 'components/hooks/usePersonnelForm';
import useTrackedPersonnel from 'components/hooks/useTrackedPersonnel';

import PersonnelPanel from 'components/personnel/PersonnelPanel';
import TrackedPersonnelUsageBanner from 'components/personnel/TrackedPersonnelUsageBanner';
import DestroyModal from 'components/application/DestroyModal';
import TrainingDestroyModal from 'components/training/TrainingDestroyModal';
import CourseDestroyModal from 'components/training/CourseDestroyModal';
import BookingDestroyModal from 'components/training/BookingDestroyModal';
import BookingELearningModal from 'components/training/BookingELearningModal';
import BookingELearningNoCreditsModal from 'components/training/BookingELearningNoCreditsModal';
import BookingReminderModal from 'components/training/BookingReminderModal';
import SendInvitationModal from 'components/training/SendInvitationModal';
import ArchiveModal from 'components/archive/ArchiveModal';
import UnarchiveBlockedModal from 'components/archive/UnarchiveBlockedModal';
import UndoableToast from 'components/application/UndoableToast';
import PersonnelSidePanel from 'components/personnel/PersonnelSidePanel';
import SuccessToast from 'components/application/SuccessToast';
import TrainingSidePanel from 'components/training/TrainingSidePanel';
import TrainingRequirementsSidePanel from 'components/training/TrainingRequirementsSidePanel';
import BookingSidePanel from 'components/training/BookingSidePanel';
import TrainingRecords from 'components/training/TrainingRecords';
import Portal from 'components/application/Portal';

import { personDisplayName } from 'components/helpers/users';
import {
  personnelParams,
  sortRolesByPrimaryAndPosition,
  sortRoleIdsByPosition,
  haveProfilePhotoAttributesChanged,
} from 'components/helpers/personnel';
import { sendAnalytics } from 'components/helpers/analytics';

import {
  personnelShowPageReducer,
  personnelShowPageInitialStore,
} from 'reducers/personnelShowPageReducer';
import {
  calculateTrainingExpiryDate,
  inferredTrainingExpiryDates,
  defaultTrainingStartDate,
} from 'components/helpers/resources/training';
import { useQuery } from '@tanstack/react-query';
import Upload from 'components/helpers/Upload';
import ProfilePhotoModal from 'components/personnel/ProfilePhotoModal';
import setupPolling from 'utilities/setupPolling';

const defaultSubcontractor = { subcontractorName: '' };

const defaultTraining = {
  id: null,
  personnelId: '',
  courseId: '',
  course: null,
  startDate: null,
  expiryDate: null,
  notes: '',
  attachmentIds: [],
};

const defaultTrainingRequirement = {
  userCourseId: null,
  isCourseRequired: true,
  isRecordingTraining: false,
};

const defaultBooking = {
  date: '',
  courseId: '',
  notes: '',
  autoEnrolled: null,
};

const defaultBookingELearningModal = {
  isOpen: false,
  course: null,
  eLearningCourse: null,
};
const defaultBookingELearningNoCreditsModal = { isOpen: false };

export default function PersonnelShowPage(props) {
  const currentActor = useCurrentActor();
  const breadBoard = useBreadBoard();

  const trainingRegisterContextValues = (({
    hasBookingEditableAccess,
    hasBookingGroupViewableAccess,
    hasBookingRemindableAccess,
    hasPersonnelEditableAccess,
    hasPersonnelProfileAccessEnabled,
    hasProfileAccessEditableAccess,
    hasRoleEditableAccess,
    hasTeamViewableAccess,
    hasTrainingEditableAccess,
    isAutoEnrolmentIncludingMissingTraining,
    isViewingOwnProfile,
    personnelId,
    trainingReportPermittedOptions,
  }) => {
    return {
      personnelId,
      isViewingOwnProfile,
      isAutoEnrolmentIncludingMissingTraining,
      trainingReportPermittedOptions,
      hasPersonnelProfileAccessEnabled,
      hasProfileAccessEditableAccess,
      hasPersonnelEditableAccess,
      hasRoleEditableAccess,
      hasTeamViewableAccess,
      hasBookingEditableAccess,
      hasBookingRemindableAccess,
      hasTrainingEditableAccess,
      hasBookingGroupViewableAccess,
    };
  })(props);

  const [store, dispatch] = useReducer(
    personnelShowPageReducer,
    personnelShowPageInitialStore,
  );
  const [coursesMeta, setCoursesMeta] = useState({ loaded: false, info: {} });
  const [trainingEvidenceProcessed, setTrainingEvidenceProcessed] =
    useState(false);

  // application
  const [currentSubcontractor, setCurrentSubcontractor] =
    useForm(defaultSubcontractor);
  const [isSubcontractor, setIsSubcontractor] = useState(false);
  const [availableFields, setAvailableFields] = useState({
    loaded: false,
    collection: [],
  });
  const [availableFieldOptions, setAvailableFieldOptions] = useState({
    loaded: false,
    collection: [],
  });
  const [newCompanyRoles, setNewCompanyRoles] = useForm([]);
  const [
    currentTraining,
    setCurrentTraining,
    handleTrainingInputChange,
    ,
    ,
    handleTrainingDateChange,
  ] = useForm(defaultTraining);
  const [
    currentTrainingRequirement,
    setCurrentTrainingRequirement,
    handleTrainingRequirementInputChange,
  ] = useForm(defaultTrainingRequirement);
  const [
    currentBooking,
    setCurrentBooking,
    handleBookingInputChange,
    ,
    ,
    handleBookingDateChange,
  ] = useForm(defaultBooking);
  const [
    formPersonnel,
    setFormPersonnel,
    handlePersonnelInputChange,
    handlePersonnelOptionChange,
    handlePersonnelDateChange,
    handleFieldValueInputChange,
    handleDateFieldChange,
    handleFieldOptionChange,
    handleToggle,
    handleProfilePhotoRemoval,
    handleProfilePhotoMediaChange,
    handleNewProfilePhotoUploaded,
    handleProfilePhotoCropReady,
  ] = usePersonnelForm({});
  const [
    requestError,
    submitDisabled,
    removeErrorStyling,
    resetRequestError,
    handleRequestError,
  ] = useRequestError();
  const [eLearningAllowance, setELearningAllowance] = useState({
    loaded: false,
    currentCredits: null,
    hasError: false,
  });
  const [
    trackedPersonnelUsage,
    refreshTrackedPersonnelUsage,
    hasReachedTrackedPersonnelUsageThreshold,
    hasReachedTrackedPersonnelLimit,
  ] = useTrackedPersonnel();

  const handleTrainingCalendarClose = (calendarName) => {
    setCurrentTraining({
      ...currentTraining,
      ...inferredTrainingExpiryDates({
        domainCourse: currentTraining.course,
        expiryDates: {
          startDate: currentTraining.startDate,
          expiryDate: currentTraining.expiryDate,
        },
        dateToInferFrom: calendarName,
      }),
    });
  };

  // ui
  const [
    trainingSidePanelIsOpen,
    setTrainingSidePanelIsOpen,
    openTrainingSidePanel,
    closeTrainingSidePanel,
    _resetTrainingSidePanelContext,
    trainingSidePanelContext,
    setTrainingSidePanelContext,
  ] = useSidePanel(false, 'new');
  const [
    personnelSidePanelIsOpen,
    _setPersonnelSidePanelIsOpen,
    openPersonnelSidePanel,
    closePersonnelSidePanel,
    _resetPersonnelSidePanelContext,
    _personnelSidePanelContext,
    setPersonnelSidePanelContext,
  ] = useSidePanel(false, 'new');
  const [
    trainingRequirementsSidePanelIsOpen,
    setTrainingRequirementsSidePanelIsOpen,
    openTrainingRequirementsSidePanel,
    closeTrainingRequirementsSidePanel,
    _resetTrainingRequirementsSidePanel,
    trainingRequirementsSidePanelContext,
  ] = useSidePanel(false, 'new');
  const [
    bookingSidePanelIsOpen,
    _setBookingSidePanelIsOpen,
    openBookingSidePanel,
    closeBookingSidePanel,
    _resetBookingSidePanelContext,
    bookingSidePanelContext,
    setBookingSidePanelContext,
  ] = useSidePanel(false, 'new');
  const [destroyModalIsOpen, setDestroyModalIsOpen] = useState(false);
  const [trainingDestroyModalIsOpen, setTrainingDestroyModalIsOpen] =
    useState(false);
  const [courseDestroyModalIsOpen, setCourseDestroyModalIsOpen] =
    useState(false);
  const [
    bookingDestroyModalIsOpen,
    ,
    openBookingDestroyModal,
    closeBookingDestroyModal,
  ] = useModal(false);
  const [bookingELearningModal, setBookingELearningModal] = useState(
    defaultBookingELearningModal,
  );
  const [bookingELearningNoCreditsModal, setBookingELearningNoCreditsModal] =
    useState(defaultBookingELearningNoCreditsModal);
  const [
    bookingReminderModalIsOpen,
    ,
    openBookingReminderModal,
    closeBookingReminderModal,
  ] = useModal(false);
  const [archiveModalIsOpen, , openArchiveModal, closeArchiveModal] =
    useModal(false);
  const [
    unarchiveBlockedModalIsOpen,
    ,
    openUnarchiveBlockedModal,
    closeUnarchiveBlockedModal,
  ] = useModal(false);
  const [
    sendInvitationModalIsOpen,
    ,
    openSendInivitationModal,
    closeSendInivitationModal,
  ] = useModal(false);
  const [selectedBookingId, setSelectedBookingId] = useState(null);

  const photoUploadLoading = useRef(false);

  // use effects

  useEffect(() => {
    if (currentActor.isAllowedFeature('e_learning')) {
      fetchELearningAllowance();
    }
  }, []);

  useEffect(() => {
    fetchAssignableCompanies();
    fetchAssignableCompanyRoles();
    fetchPersonnelTraining();
    fetchPersonnel();
    fetchPersonnelEvents();
    fetchCoursesMeta();
    fetchFields();
  }, []);

  useEffect(() => {
    setTrainingEvidenceProcessed(false);
  }, [JSON.stringify(store.domain.attachments)]);

  // data management

  const formatPersonnelData = ({ data, fieldValues, profilePhoto, user }) => {
    if (!data) return;
    const attributes = data.attributes;
    const lineManagerData = data.relationships.lineManager.data;
    const companyRolesData = data.relationships.companyRoles.data;
    const teamsData = data.relationships.teams.data;

    const formattedFieldValues = Object.values(fieldValues).reduce(
      (list, value) => {
        list[value.relationships.fieldAttribute.data.id] = {
          id: value.id,
          value:
            value.attributes.valueType == 'date' ?
              moment.parseZone(value.attributes.value).toDate()
            : value.attributes.value,
          valueType: value.attributes.valueType,
          fieldAttributeId: value.relationships.fieldAttribute.data.id,
          fieldOptionId:
            value.relationships.fieldOption.data &&
            value.relationships.fieldOption.data.id,
          personnelId: value.relationships.entity.data.id,
        };

        return list;
      },
      {},
    );

    return {
      id: data.id,
      firstName: attributes.firstName || '',
      lastName: attributes.lastName || '',
      externalId: attributes.externalId || '',
      email: attributes.email || '',
      lineManagerId: lineManagerData ? lineManagerData.id : '',
      fieldValues: formattedFieldValues,
      company: {
        companyId: attributes.companyId || '',
        subcontractorId: attributes.subcontractorId || '',
      },
      primaryCompanyRoleId: '',
      companyRoleIds:
        companyRolesData ?
          companyRolesData.map((companyRole) => companyRole.id)
        : [],
      teamsIds: teamsData ? teamsData.map((team) => team.id) : [],
      archivedAt: attributes.archivedAt,
      isParticipatingInOngoingProjects:
        attributes.isParticipatingInOngoingProjects,
      profileAccessEnabled: !!user?.attributes?.profileAccessEnabled,
      isFromAutoEnrollableDivision: data.meta?.isFromAutoEnrollableDivision,
      profilePhoto: { ...profilePhoto?.attributes, ...profilePhoto?.links },
    };
  };

  const handleNewCompanyRoleAdd = (newRole) => {
    setNewCompanyRoles(newCompanyRoles.concat([newRole]));
  };

  const assignResources = ({ data, included }) => {
    const fieldValues = included.reduce((object, resource) => {
      if (resource.type == 'fieldValue') {
        object[resource.id] = resource;
      }

      return object;
    }, {});

    const user = included.find(
      (resource) =>
        resource.type === 'user' &&
        resource.id === data.relationships.user.data.id,
    );
    const profilePhoto = included.find(
      (resource) =>
        resource.type === 'profilePhoto' &&
        resource.id === data.relationships.profilePhoto.data.id,
    );
    const personnelData = formatPersonnelData({
      data,
      fieldValues,
      user,
      profilePhoto,
    });

    const primaryRole = included.find(
      ({ attributes, type }) =>
        type === 'userCompanyRole' && attributes.primary,
    );

    const formPersonnelParams = {
      ...personnelData,
      primaryCompanyRoleId:
        primaryRole ? primaryRole.relationships.companyRole.data.id : '',
      profilePhoto: {
        ...profilePhoto?.attributes,
        id: profilePhoto?.id,
      },
    };
    setFormPersonnel(formPersonnelParams);

    dispatch({
      type: 'LOAD_PERSONNEL',
      payload: {
        member: data,
        included: {
          companyRoles: included.filter(
            (resource) => resource.type === 'companyRole',
          ),
          courseCompanyRoles: included.filter(
            (resource) => resource.type === 'courseCompanyRole',
          ),
          userCompanyRoles: included.filter(
            (resource) => resource.type === 'userCompanyRole',
          ),
          userCourses: included.filter(
            (resource) => resource.type === 'userCourse',
          ),
          autoEnrolmentConfigurations: included.filter(
            (resource) => resource.type === 'autoEnrolmentConfiguration',
          ),
          autoEnrolmentExclusions: included.filter(
            (resource) => resource.type === 'autoEnrolmentExclusion',
          ),
          lineManagers: included.filter(
            (resource) => resource.type === 'lineManager',
          ),
          companies: included.filter((resource) => resource.type === 'company'),
          subcontractors: included.filter(
            (resource) => resource.type === 'subcontractor',
          ),
          courses: included.filter((resource) => resource.type === 'course'),
          eLearningCourses: included.filter(
            (resource) => resource.type === 'eLearningCourse',
          ),
          bookings: included.filter((resource) => resource.type === 'booking'),
          teams: included.filter((resource) => resource.type === 'team'),
          registrations: included.filter(
            (resource) => resource.type === 'registration',
          ),
          fieldValues: included.filter(
            (resource) => resource.type === 'fieldValue',
          ),
          bookers: included.filter(
            (resource) => resource.type === 'simpleUser',
          ),
          users: included.filter((resource) => resource.type === 'user'),
          profilePhotos: included.filter(
            (resource) => resource.type === 'profilePhoto',
          ),
        },
      },
    });
  };

  const refreshResources = () => {
    fetchPersonnelTraining();
    fetchPersonnelEvents();
  };

  const handleAssignableRoleAdd = (role) => {
    dispatch({ type: 'UPDATE_ASSIGNABLE_ROLES', payload: { role: role } });
  };

  // computed
  const hasELearningBookings =
    currentActor.isAllowedFeature('e_learning') &&
    store.domain.personnel &&
    store.domain.personnel.meta.eLearningBookingsCount > 0;
  const currentBookings = Object.values(store.domain.bookings);
  const allTraining = store.domain.training.allDataIds.map(
    (trainingId) => store.domain.training.data[trainingId],
  );

  const displayPersonnel = formatPersonnelData({
    data: store.domain.personnel,
    fieldValues: store.domain.fieldValues,
    user: store.domain.users[
      store.domain.personnel?.relationships?.user?.data?.id
    ],
    profilePhoto:
      store.domain.profilePhotos[
        store.domain.personnel?.relationships?.profilePhoto?.data?.id
      ],
  });

  const selectedProfilePhoto =
    store.domain.profilePhotos[formPersonnel?.profilePhoto?.id];

  const isArchived = displayPersonnel && !!displayPersonnel.archivedAt;
  const isUnarchivingBlocked =
    isArchived && hasReachedTrackedPersonnelLimit && allTraining.length > 0;
  const isCurrentPersonnelTracked =
    !isArchived && (currentBookings.length > 0 || allTraining.length > 0);
  const selectedBooking = store.domain.bookings[selectedBookingId];
  const selectedBooker =
    selectedBooking && selectedBooking.relationships.booker.data ?
      store.domain.bookers[selectedBooking.relationships.booker.data.id]
    : null;
  const selectedBookingCourse =
    selectedBooking ?
      store.domain.courses[selectedBooking.relationships.course.data.id]
    : store.domain.courses[currentBooking.courseId];
  const selectedBookingGroupId =
    selectedBooking?.relationships?.bookingGroup?.data?.id;
  const selectedBookingAutoEnrolmentConfigurationId =
    selectedBookingCourse &&
    selectedBookingCourse.relationships?.autoEnrolmentConfiguration?.data?.id;
  const selectedBookingAutoEnrolmentExclusion =
    selectedBookingAutoEnrolmentConfigurationId &&
    Object.values(store.domain.autoEnrolmentExclusions).find(
      (exclusion) =>
        exclusion.relationships.autoEnrolmentConfiguration.data.id ===
        selectedBookingAutoEnrolmentConfigurationId,
    );
  const currentProfilePhoto = formPersonnel.profilePhoto;

  const fetchPersonnel = () => {
    axios
      .get(`/personnel/${props.personnelId}?with_course_connections=true`)
      .then((response) => assignResources(response.data))
      .catch(breadBoard.addInedibleToast);
  };

  const fetchAssignableCompanies = () => {
    axios
      .get('/assignable_companies')
      .then((response) =>
        dispatch({
          type: 'LOAD_ASSIGNABLE_COMPANIES',
          payload: { collection: response.data.data },
        }),
      )
      .catch(breadBoard.addInedibleToast);
  };

  const fetchAssignableCompanyRoles = () => {
    axios
      .get('/assignable_company_roles')
      .then((response) =>
        dispatch({
          type: 'LOAD_ASSIGNABLE_ROLES',
          payload: { collection: response.data.data },
        }),
      )
      .catch(breadBoard.addInedibleToast);
  };

  const { data: bookingGroupPersonnelMeta } = useQuery({
    queryKey: ['bookingGroup', selectedBookingGroupId, 'personnel'],
    queryFn: async () => {
      const response = await axios.get(
        `/booking_groups/${selectedBookingGroupId}/personnel?meta_only=true`,
        { params: { with_booking_write_eligibility: true } },
      );
      return response.data.meta;
    },
    enabled:
      currentActor.user.attributes.accessType !== 'personnel' &&
      !!selectedBookingGroupId,
  });

  const fetchPersonnelTraining = () => {
    axios
      .get(`/personnel/${props.personnelId}/trainings`)
      .then((response) => {
        dispatch({
          type: 'LOAD_TRAINING',
          payload: {
            collection: response.data.data,
            included: {
              courses: response.data.included.filter(
                (obj) => obj.type === 'course',
              ),
              attachments: response.data.included.filter(
                (obj) => obj.type === 'attachment',
              ),
              coverImages: response.data.included.filter(
                (obj) => obj.type === 'coverImage',
              ),
              eLearningCourses: response.data.included.filter(
                (obj) => obj.type === 'eLearningCourse',
              ),
              autoEnrolmentConfigurations: response.data.included.filter(
                (resource) => resource.type === 'autoEnrolmentConfiguration',
              ),
            },
          },
        });
      })
      .catch(breadBoard.addInedibleToast);
  };

  const fetchCoursesMeta = () => {
    axios
      .get('/courses/?meta_only=true')
      .then((response) => {
        setCoursesMeta({
          loaded: true,
          info: response.data.meta,
        });
      })
      .catch(breadBoard.addInedibleToast);
  };

  const fetchFields = () => {
    axios
      .get('/custom_fields/available_field_attributes')
      .then((response) => {
        setAvailableFieldOptions({
          loaded: true,
          collection: response.data.included.filter(
            (object) => object.type === 'fieldOption',
          ),
        });
        setAvailableFields({ loaded: true, collection: response.data.data });
      })
      .catch(breadBoard.addInedibleToast);
  };

  const fetchPersonnelEvents = (page = 1) => {
    if (props.hasPersonnelEventViewableAccess) {
      axios
        .get(`/personnel/${props.personnelId}/events`, {
          params: { page: page },
        })
        .then((response) => {
          dispatch({
            type: 'LOAD_PERSONNEL_EVENTS',
            payload: {
              collection: response.data.data,
              meta: response.data.meta,
              included: {
                users: response.data.included.filter(
                  (obj) => obj.type === 'user',
                ),
                profilePhotos: response.data.included.filter(
                  (obj) => obj.type === 'profilePhoto',
                ),
              },
            },
          });
        });
    } else {
      dispatch({
        type: 'LOAD_PERSONNEL_EVENTS',
        payload: { collection: [], meta: {}, included: {} },
      });
    }
  };

  const fetchELearningAllowance = () => {
    axios
      .get('/e_learning/allowance')
      .then((response) =>
        setELearningAllowance({
          ...eLearningAllowance,
          loaded: true,
          currentCredits: response.data.data.attributes.currentCredits,
        }),
      )
      .catch((_error) =>
        setELearningAllowance({ ...eLearningAllowance, hasError: true }),
      );
  };

  const createTraining = () => {
    axios
      .post('/trainings', {
        training: {
          personnel_id: currentTraining.personnelId,
          course_id: currentTraining.courseId,
          start_date: moment
            .parseZone(currentTraining.startDate)
            .format('DD/MM/YYYY'),
          expiry_date:
            currentTraining.expiryDate &&
            moment.parseZone(currentTraining.expiryDate).format('DD/MM/YYYY'),
          notes: currentTraining.notes,
          attachment_ids: currentTraining.attachmentIds,
        },
      })
      .then(() => {
        const trainingBooking = currentBookings.find(
          (booking) =>
            booking.relationships.course.data.id === currentTraining.courseId,
        );

        if (trainingBooking) {
          const course = store.domain.courses[currentTraining.courseId];
          const isELearningCourse = !!course.relationships.eLearningCourse.data;
          dispatch({
            type:
              isELearningCourse ?
                'DELETE_E_LEARNING_BOOKING'
              : 'DELETE_BOOKING',
            payload: { member: trainingBooking },
          });
          if (isELearningCourse) fetchELearningAllowance();
        }

        refreshResources();
        refreshTrackedPersonnelUsage();
        setCurrentBooking(defaultBooking);
        closeTrainingSidePanel();
      })
      .catch(handleRequestError);
  };

  const createTrainingRequirement = () => {
    const training_requirement_params = {
      personnel_id: currentTraining.personnelId,
      course_id: currentTraining.courseId,
      user_course_attributes: {
        required: currentTrainingRequirement.isCourseRequired,
      },
    };
    if (currentTrainingRequirement.isRecordingTraining === true) {
      training_requirement_params.training_attributes = {
        start_date: moment
          .parseZone(currentTraining.startDate)
          .format('DD/MM/YYYY'),
        expiry_date:
          currentTraining.expiryDate &&
          moment.parseZone(currentTraining.expiryDate).format('DD/MM/YYYY'),
        notes: currentTraining.notes,
        attachment_ids: currentTraining.attachmentIds,
      };
    }

    axios
      .post('/training_requirements', {
        training_requirement: training_requirement_params,
      })
      .then((response) => {
        dispatch({
          type: 'CREATE_TRAINING_REQUIREMENT',
          payload: {
            userCourse: response.data.included.find(
              (resource) => resource.type === 'userCourse',
            ),
            training: response.data.included.find(
              (resource) => resource.type === 'training',
            ),
            attachments: response.data.included.filter(
              (obj) => obj.type === 'attachment',
            ),
            coverImages: response.data.included.filter(
              (obj) => obj.type === 'coverImage',
            ),
            eLearningCourses: response.data.included.filter(
              (resource) => resource.type === 'eLearningCourse',
            ),
            course: response.data.included.find(
              (resource) => resource.type === 'course',
            ),
            autoEnrolmentConfiguration: response.data.included.find(
              (resource) => resource.type === 'autoEnrolmentConfiguration',
            ),
          },
        });
        fetchPersonnelEvents();
        refreshTrackedPersonnelUsage();
        closeTrainingRequirementsSidePanel();
      })
      .catch(handleRequestError);
  };

  const createAutoEnrolmentExclusion = ({
    autoEnrolmentConfigurationId,
    personnelId,
  }) => {
    axios
      .post('/auto_enrolment_exclusions', {
        auto_enrolment_exclusion: {
          personnel_id: personnelId,
          auto_enrolment_configuration_id: autoEnrolmentConfigurationId,
        },
      })
      .then((response) => {
        dispatch({
          type: 'CREATE_AUTO_ENROLMENT_EXCLUSION',
          payload: { member: response.data.data },
        });
      })
      .catch(handleRequestError);
  };

  const deleteAutoEnrolmentExclusion = ({ autoEnrolmentExclusion }) => {
    axios
      .delete(`/auto_enrolment_exclusions/${autoEnrolmentExclusion.id}`)
      .then((_response) => {
        dispatch({
          type: 'DELETE_AUTO_ENROLMENT_EXCLUSION',
          payload: { member: autoEnrolmentExclusion },
        });
      })
      .catch(breadBoard.addInedibleToast);
  };

  const updateTraining = () => {
    axios
      .patch(`/trainings/${currentTraining.id}`, {
        training: {
          start_date: moment
            .parseZone(currentTraining.startDate)
            .format('DD/MM/YYYY'),
          expiry_date: moment
            .parseZone(currentTraining.expiryDate)
            .format('DD/MM/YYYY'),
          notes: currentTraining.notes,
          attachment_ids: currentTraining.attachmentIds,
        },
      })
      .then((_response) => {
        refreshResources();
        closeTrainingSidePanel();
      })
      .catch(handleRequestError);
  };

  const deleteTraining = () => {
    axios
      .delete(`/trainings/${currentTraining.id}`)
      .then((_response) => {
        refreshResources();
        refreshTrackedPersonnelUsage();
      })
      .catch(breadBoard.addInedibleToast);
  };

  const createUserCourse = ({ course, params }) => {
    const joinParams = {
      ...params,
      personnel_id: displayPersonnel.id,
      course_id: course.id,
    };

    axios
      .post('/user_courses', { user_course: joinParams })
      .then((response) => {
        dispatch({
          type: 'CREATE_USER_COURSE',
          payload: {
            member: response.data.data,
            course: response.data.included.find(
              (resource) => resource.type === 'course',
            ),
          },
        });
      })
      .catch(breadBoard.addInedibleToast);
  };

  const updateUserCourse = ({ params, userCourse }) => {
    axios
      .patch(`/user_courses/${userCourse.id}`, { user_course: params })
      .then((response) => {
        dispatch({
          type: 'UPDATE_USER_COURSE',
          payload: { member: response.data.data },
        });
        fetchPersonnelEvents();
      })
      .catch(breadBoard.addInedibleToast);
  };

  const deleteUserCourse = () => {
    const userCourse =
      store.domain.userCourses[currentTrainingRequirement.userCourseId];

    axios
      .delete(`/user_courses/${userCourse.id}`)
      .then((_response) => {
        dispatch({
          type: 'DELETE_USER_COURSE',
          payload: { member: userCourse },
        });
        fetchPersonnelEvents();
      })
      .catch(breadBoard.addInedibleToast);
  };

  const createBooking = (courseName) => {
    axios
      .post(`/personnel/${props.personnelId}/bookings`, {
        booking: {
          course_id: currentBooking.courseId,
          date: moment.parseZone(currentBooking.date).format('DD/MM/YYYY'),
          notes: currentBooking.notes,
        },
      })
      .then((response) => {
        breadBoard.addToast(
          <SuccessToast
            message={
              <>
                <span className='tw-font-medium'>
                  {formPersonnel.firstName}
                </span>{' '}
                has been booked on the course{' '}
                <span className='tw-font-medium'>{courseName}</span>
              </>
            }
            onBurnToast={breadBoard.handleBurnToast}
          />,
        );
        fetchPersonnelEvents();
        refreshTrackedPersonnelUsage();
        handleCloseBookingSidePanel();
        const payload = {
          member: response.data.data,
          booker: response.data.included.find((i) => i.type == 'simpleUser'),
        };
        dispatch({ type: 'CREATE_BOOKING', payload: payload });
        setCurrentBooking(defaultBooking);
      })
      .catch(handleRequestError);
  };

  const createELearningBooking = ({ course }) => {
    axios
      .post(`/personnel/${props.personnelId}/bookings`, {
        booking: { course_id: course.id },
      })
      .then((response) => {
        breadBoard.addToast(
          <SuccessToast
            message={
              <>
                <span className='tw-font-medium'>
                  {formPersonnel.firstName}
                </span>{' '}
                has been enrolled on the eLearning course{' '}
                <span className='tw-font-medium'>{course.attributes.name}</span>
              </>
            }
            onBurnToast={breadBoard.handleBurnToast}
          />,
        );
        fetchPersonnelEvents();
        refreshTrackedPersonnelUsage();
        fetchELearningAllowance();
        dispatch({
          type: 'CREATE_E_LEARNING_BOOKING',
          payload: { member: response.data.data },
        });
      })
      .catch(breadBoard.addInedibleToast);
  };

  const deleteBooking = ({
    bookingId,
    isNotified = false,
    sendEmails = true,
  }) => {
    const booking = store.domain.bookings[bookingId];
    const course = store.domain.courses[booking.relationships.course.data.id];
    axios
      .delete(`/personnel/${props.personnelId}/bookings/${booking.id}`, {
        params: { send_emails: sendEmails },
      })
      .then((response) => {
        if (isNotified) {
          breadBoard.addToast(
            <SuccessToast
              message={
                <>
                  Booking has been removed for{' '}
                  <span className='tw-font-medium'>
                    {formPersonnel.firstName}
                  </span>{' '}
                  for{' '}
                  <span className='tw-font-medium'>
                    {course.attributes.name}
                  </span>
                </>
              }
              onBurnToast={breadBoard.handleBurnToast}
            />,
          );
        }
        dispatch({
          type:
            !!course.relationships.eLearningCourse.data ?
              'DELETE_E_LEARNING_BOOKING'
            : 'DELETE_BOOKING',
          payload: { member: booking },
        });

        const includedAutoEnrolmentExclusion =
          response.data.included &&
          response.data.included.find(
            (resource) => resource.type === 'autoEnrolmentExclusion',
          );
        if (!!includedAutoEnrolmentExclusion) {
          dispatch({
            type: 'CREATE_AUTO_ENROLMENT_EXCLUSION',
            payload: { member: includedAutoEnrolmentExclusion },
          });
        }

        fetchPersonnelEvents();
        setCurrentBooking(defaultBooking);
        setSelectedBookingId(null);
        refreshTrackedPersonnelUsage();
        handleCloseBookingSidePanel();
        if (!!course.relationships.eLearningCourse.data) {
          fetchELearningAllowance();
        }
      })
      .catch((_error) => breadBoard.addInedibleToast());
  };

  const handlePersonnelUpdateSubmit = () => {
    const originalUserCompanyRoles =
      store.domain.personnel.relationships.userCompanyRoles.data.map(
        (ucr) => store.domain.userCompanyRoles[ucr.id],
      );
    const originalCompanyRoleIds =
      store.domain.personnel.relationships.companyRoles.data.map(
        (cr) => store.domain.companyRoles[cr.id].id,
      );

    const domainProfilePhotoFromRelationship =
      store.domain.profilePhotos[
        store.domain.personnel.relationships.profilePhoto?.data?.id
      ];

    const params = personnelParams({
      currentPersonnel: formPersonnel,
      fieldValues: formPersonnel.fieldValues,
      isSubcontractor: isSubcontractor,
      currentSubcontractor: currentSubcontractor,
      newCompanyRoles: newCompanyRoles,
      originalUserCompanyRoles,
      originalCompanyRoleIds,
      profilePhotos: {
        domain: selectedProfilePhoto,
        existingFromRelationship: domainProfilePhotoFromRelationship,
      },
    });

    axios
      .patch(
        `/personnel/${formPersonnel.id}?with_course_connections=true`,
        params,
      )
      .then((response) => {
        closePersonnelSidePanel();
        setCurrentSubcontractor(defaultSubcontractor);
        setNewCompanyRoles([]);
        fetchAssignableCompanies();
        fetchAssignableCompanyRoles();
        assignResources(response.data);
        resetRequestError();

        dispatch({ type: 'PROFILE_PHOTO_SAVED' });
        handleDomainProfilePhotoChange(formPersonnel, displayPersonnel);

        breadBoard.addToast(
          <SuccessToast
            message={
              <>
                <span className='tw-font-medium'>
                  {personDisplayName(response.data.data.attributes)}
                </span>{' '}
                was edited
                {response.data.meta.userInvited ?
                  ' and sent an invitation email'
                : undefined}
              </>
            }
            onBurnToast={breadBoard.handleBurnToast}
          />,
        );
      })
      .catch(handleRequestError);
  };

  const handleDomainProfilePhotoChange = (formPersonnel, displayPersonnel) => {
    if (
      !!formPersonnel.profilePhoto &&
      haveProfilePhotoAttributesChanged(
        displayPersonnel.profilePhoto,
        formPersonnel.profilePhoto,
      )
    ) {
      // poll for processed photo
      setupPolling({
        requestFn: () =>
          axios.get(`/profile_photos/${formPersonnel.profilePhoto.hashid}`),
        testFn: (response) => response.data.data.attributes.processed,
        intervalTime: 1000,
      })
        .startPolling()
        .then((response) => {
          handleNewProfilePhotoUploaded(response.data.data);
          dispatch({
            type: 'PROFILE_PHOTO_PROCESSING_COMPLETED',
            payload: { profilePhoto: response.data.data },
          });
        })
        .catch(() => {
          dispatch({ type: 'PROFILE_PHOTO_CHANGE_COMPLETED' });
        });
    } else {
      dispatch({ type: 'PROFILE_PHOTO_CHANGE_COMPLETED' });
    }
  };

  const archivePersonnel = ({ isToastMessageDisplayed = true }) => {
    axios
      .patch(`/personnel/${formPersonnel.id}?with_course_connections=true`, {
        personnel: { archived_at: moment().format('DD/MM/YYYY') },
      })
      .then((response) => {
        closeArchiveModal();
        assignResources(response.data);
        refreshTrackedPersonnelUsage();
        fetchPersonnelEvents();
        sendAnalytics('Personnel archived', {
          currentUser: currentActor.user,
          personnelId: props.personnelId,
        });
        if (isToastMessageDisplayed) {
          breadBoard.addToast(
            <UndoableToast
              onBurnToast={breadBoard.handleBurnToast}
              onUndoAction={() =>
                unarchivePersonnel({ isToastMessageDisplayed: false })
              }
              toastText={
                <Fragment>
                  <span className='tw-font-medium'>
                    {personDisplayName(response.data.data.attributes)}
                  </span>{' '}
                  <span>is now archived</span>
                </Fragment>
              }
            />,
          );
        }
      })
      .catch((_error) => {
        closeArchiveModal();
        breadBoard.addInedibleToast({
          fullMessage: `${personDisplayName(displayPersonnel)} was not archived. Please try again.`,
        });
      });
  };

  const unarchivePersonnel = ({ isToastMessageDisplayed = true }) => {
    axios
      .patch(`/personnel/${formPersonnel.id}?with_course_connections=true`, {
        personnel: { archived_at: null },
      })
      .then((response) => {
        closeArchiveModal();
        assignResources(response.data);
        fetchPersonnelEvents();
        refreshTrackedPersonnelUsage();
        sendAnalytics('Personnel unarchived', {
          currentUser: currentActor.user,
          personnelId: props.personnelId,
        });
        if (isToastMessageDisplayed) {
          breadBoard.addToast(
            <UndoableToast
              onBurnToast={breadBoard.handleBurnToast}
              onUndoAction={() =>
                archivePersonnel({ isToastMessageDisplayed: false })
              }
              toastText={
                <Fragment>
                  <span className='tw-font-medium'>
                    {personDisplayName(response.data.data.attributes)}
                  </span>{' '}
                  <span>is now active</span>
                </Fragment>
              }
            />,
          );
        }
      })
      .catch((_error) => {
        closeArchiveModal();
        breadBoard.addInedibleToast({
          fullMessage: `${personDisplayName(displayPersonnel)} was not unarchived. Please try again.`,
        });
      });
  };

  const destroyPersonnel = () => {
    axios
      .delete(`/personnel/${formPersonnel.id}`)
      .then((_response) => window.location.replace('/personnel'))
      .catch(breadBoard.addInedibleToast);
  };

  function handleSendConfirmationClick() {
    closeSendInivitationModal();

    axios
      .post(`/personnel/${props.personnelId}/invites`)
      .then(() => {
        breadBoard.addToast(
          <SuccessToast
            message={
              <>
                <span className='tw-font-medium'>
                  {personDisplayName(displayPersonnel)}
                </span>{' '}
                was sent an invitation email
              </>
            }
            onBurnToast={breadBoard.onBurnToast}
          />,
        );
      })
      .catch(breadBoard.addInedibleToast);
  }

  // handlers

  const handleCancel = () => {
    photoUploadLoading.current = false;
    dispatch({ type: 'PROFILE_PHOTO_CANCELLED' });
    const profilePhoto =
      store.domain.profilePhotos[
        store.domain.personnel.relationships.profilePhoto?.data?.id
      ];
    setFormPersonnel({
      ...displayPersonnel,
      profilePhoto: {
        ...profilePhoto?.attributes,
        id: store.domain.personnel.relationships.profilePhoto?.data?.id,
      },
    });
    resetRequestError();
    closePersonnelSidePanel();
  };

  const handleCancelTraining = () => {
    closeTrainingSidePanel();
    resetRequestError();
  };

  const handleCloseBookingSidePanel = () => {
    resetRequestError();
    closeBookingSidePanel();
  };

  const handleTrainingEvidenceProcessed = () => {
    setTrainingEvidenceProcessed(true);
  };

  const handleNewTrainingClick = ({ booking, course }) => {
    closeBookingSidePanel();
    setTrainingSidePanelContext('new');
    resetRequestError();

    const startDate =
      booking?.attributes.date ? defaultTrainingStartDate({ booking }) : null;
    const expiryDate =
      startDate ? calculateTrainingExpiryDate({ course, startDate }) : null;

    setCurrentTraining({
      ...defaultTraining,
      personnelId: displayPersonnel.id,
      course,
      courseId: course.id,
      expiryDate,
      startDate,
    });

    openTrainingSidePanel();
  };

  const handleEditTrainingClick = ({ course, training }) => {
    setTrainingSidePanelContext('edit');
    resetRequestError();
    const trainingAttributes = training.attributes;
    setCurrentTraining({
      id: training.id,
      personnelId: trainingAttributes.personnelId,
      courseId: trainingAttributes.courseId,
      course: course,
      startDate: moment.parseZone(trainingAttributes.startDate).toDate(),
      expiryDate:
        trainingAttributes.expiryDate &&
        moment.parseZone(trainingAttributes.expiryDate).toDate(),
      notes: trainingAttributes.notes,
      attachmentIds: trainingAttributes.attachmentIds,
    });
    openTrainingSidePanel();
  };

  const handleAddBooking = ({ course }) => {
    setBookingSidePanelContext('new');
    resetRequestError();
    setCurrentBooking({
      ...defaultBooking,
      courseId: course.id,
      courseName: course.attributes.name,
      notes:
        course.attributes.defaultBookingNote ?
          course.attributes.defaultBookingNote
        : undefined,
    });
    setSelectedBookingId(null);
    openBookingSidePanel();
  };

  const handleViewBookingClick = (bookingId) => {
    setBookingSidePanelContext('show');
    resetRequestError();
    const editedBooking = store.domain.bookings[bookingId];
    setCurrentBooking({
      ...defaultBooking,
      id: editedBooking.id,
      date: moment.parseZone(editedBooking.attributes.date).toDate(),
      notes: editedBooking.attributes.notes,
    });
    setSelectedBookingId(bookingId);
    openBookingSidePanel();
  };

  function handleSendInvitationClick() {
    openSendInivitationModal();
  }

  const handleEditBookingClick = () => {
    setBookingSidePanelContext('edit');
  };

  const handleBookingUpdateSubmit = () => {
    axios
      .patch(`/personnel/${props.personnelId}/bookings/${currentBooking.id}`, {
        booking: {
          date: moment.parseZone(currentBooking.date).format('DD/MM/YYYY'),
          notes: currentBooking.notes,
        },
      })
      .then((response) => {
        breadBoard.addToast(
          <SuccessToast
            message={
              <>
                Booking has been edited for{' '}
                <span className='tw-font-medium'>
                  {personDisplayName(formPersonnel)}
                </span>
              </>
            }
            onBurnToast={breadBoard.handleBurnToast}
          />,
        );
        refreshResources();
        dispatch({
          type: 'UPDATE_BOOKING',
          payload: { member: response.data.data },
        });
        handleCloseBookingSidePanel();
      })
      .catch(handleRequestError);
  };

  const handleAddELearningBooking = ({ course, eLearningCourse }) => {
    if (
      !eLearningCourse.attributes.custom &&
      eLearningAllowance.loaded &&
      eLearningAllowance.currentCredits <= 0
    ) {
      setBookingELearningNoCreditsModal({ isOpen: true });
    } else {
      setBookingELearningModal({ isOpen: true, course, eLearningCourse });
    }
  };

  const handleRemoveBooking = (bookingId) => {
    if (bookingId && !selectedBookingId) {
      setSelectedBookingId(bookingId);
    }
    openBookingDestroyModal();
  };

  const handleBookingReminder = (bookingId) => {
    if (bookingId && !selectedBookingId) {
      setSelectedBookingId(bookingId);
    }
    openBookingReminderModal();
  };

  const handleDeleteTrainingClick = ({ course, training }) => {
    setCurrentTraining({
      ...defaultTraining,
      id: training.id,
      personnelId: displayPersonnel.id,
      courseId: course.id,
      course: course,
    });
    setTrainingDestroyModalIsOpen(true);
  };

  const handleDeleteCourseClick = ({ course, userCourse }) => {
    setCurrentTrainingRequirement({
      ...currentTrainingRequirement,
      userCourseId: userCourse.id,
    });
    setCurrentTraining({
      ...currentTraining,
      course: course,
    });
    setCourseDestroyModalIsOpen(true);
  };

  const sendBookingReminder = ({ booking, course }) => {
    const isCourseELearning = !!course.relationships.eLearningCourse.data;

    axios
      .post(`/bookings/${booking.id}/reminders`)
      .then(() => {
        breadBoard.addToast(
          <SuccessToast
            message={
              <>
                <span className='tw-font-medium'>
                  {formPersonnel.firstName}
                </span>{' '}
                has been sent a reminder for the
                {isCourseELearning && ' eLearning'} course{' '}
                <span className='tw-font-medium'>{course.attributes.name}</span>
              </>
            }
            onBurnToast={breadBoard.handleBurnToast}
          />,
        );
        fetchPersonnelEvents();
      })
      .catch(() =>
        breadBoard.addInedibleToast({
          fullMessage: 'Reminder could not be sent. Please try again.',
        }),
      );
  };

  const handleCourseSelectChange = ({ selected }) => {
    setCurrentTraining({
      ...defaultTraining,
      personnelId: displayPersonnel.id,
      courseId: selected.value.resource.id,
      course: selected.value.resource,
    });
    setCurrentTrainingRequirement(defaultTrainingRequirement);
    resetRequestError();
    openTrainingRequirementsSidePanel();
  };

  let personnelCompanyRoles = [];
  let courseCompanyRoles = [];
  if (!store.application.pending.personnelFetch) {
    const userCompanyRoles =
      store.domain.personnel.relationships.userCompanyRoles.data.map(
        (ucr) => store.domain.userCompanyRoles[ucr.id],
      );
    const companyRoles =
      store.domain.personnel.relationships.companyRoles.data.map(
        (cr) => store.domain.companyRoles[cr.id],
      );
    personnelCompanyRoles = sortRolesByPrimaryAndPosition(
      userCompanyRoles,
      companyRoles,
    );
    courseCompanyRoles =
      store.domain.personnel.relationships.courseCompanyRoles.data.map(
        (ccr) => store.domain.courseCompanyRoles[ccr.id],
      );
  }

  const handleArchiveButtonClick = () => {
    if (isUnarchivingBlocked) {
      openUnarchiveBlockedModal();
    } else {
      openArchiveModal();
    }
  };

  const handleCompanyRolesOptionChange = (values) => {
    if (
      formPersonnel.primaryCompanyRoleId === '' &&
      formPersonnel.companyRoleIds.length === 0
    ) {
      setFormPersonnel({
        ...formPersonnel,
        primaryCompanyRoleId: values[0].value,
        companyRoleIds: values.map((v) => v.value),
      });
    } else {
      setFormPersonnel({
        ...formPersonnel,
        companyRoleIds: values.map((v) => v.value),
      });
    }
  };

  function handleSelectPrimary(id) {
    setFormPersonnel({ ...formPersonnel, primaryCompanyRoleId: id });
  }

  function handleRemoveRole(id) {
    const filteredCompanyRoleIds = formPersonnel.companyRoleIds.filter(
      (roleId) => roleId !== id,
    );
    const newPrimaryCompanyRoleId =
      filteredCompanyRoleIds.length > 0 ?
        sortRoleIdsByPosition(
          store.domain.assignableRoles,
          filteredCompanyRoleIds,
        )[0]
      : '';
    setFormPersonnel({
      ...formPersonnel,
      companyRoleIds: filteredCompanyRoleIds,
      primaryCompanyRoleId:
        formPersonnel.primaryCompanyRoleId === id ?
          newPrimaryCompanyRoleId
        : formPersonnel.primaryCompanyRoleId,
    });
  }

  function handleDestroyModalClose() {
    if (selectedBookingCourse?.relationships?.eLearningCourse?.data?.id) {
      setCurrentBooking(defaultBooking);
      setSelectedBookingId(null);
    }
    closeBookingDestroyModal();
  }

  function handleReminderModalClose() {
    if (selectedBookingCourse?.relationships?.eLearningCourse?.data?.id) {
      setCurrentBooking(defaultBooking);
      setSelectedBookingId(null);
    }
    closeBookingReminderModal();
  }

  function handleSendInvitationModalClose() {
    closeSendInivitationModal();
  }

  function handleDeletePersonnelClick() {
    setDestroyModalIsOpen(true);
  }

  function handleSubcontractorSelect(selection) {
    setCurrentSubcontractor(selection);
  }

  const isProfilePhotoUploading = store.application.pending.profilePhotoUpload;
  const isProfilePhotoChanging = store.application.pending.profilePhotoChange;
  const isProfilePhotoModalOpen =
    store.application.pending.profilePhotoModalOpen;

  const handleProcessProfilePhoto = (tempPhotoFile) => {
    dispatch({ type: 'PROFILE_PHOTO_UPLOAD_STARTED' });
    photoUploadLoading.current = true;
    new Upload({
      file: tempPhotoFile,
      customType: 'profile_photo',
      onUpload: (_fileType, uploadKey) => {
        if (!photoUploadLoading.current) {
          return;
        }

        createAndProcessProfilePhotoForUploadedPhoto(uploadKey)
          .then((profilePhotoData) => {
            handleNewProfilePhotoUploaded(profilePhotoData);
            dispatch({
              type: 'PROFILE_PHOTO_UPLOAD_COMPLETED',
              payload: {
                profilePhoto: profilePhotoData,
              },
            });
          })
          .catch((error) => {
            if (error) {
              breadBoard.addInedibleToast({ fullMessage: error.message });
            }
            dispatch({ type: 'PROFILE_PHOTO_UPLOAD_CANCELLED' });
          });
      },
      onError: (error) => breadBoard.addInedibleToast({ fullMessage: error }),
    }).start();
  };

  const createAndProcessProfilePhotoForUploadedPhoto = (uploadKey) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/profile_photos`, {
          profile_photo: {
            upload_key: uploadKey,
            crop_x: currentProfilePhoto.cropX,
            crop_y: currentProfilePhoto.cropY,
            crop_dimension: currentProfilePhoto.cropDimension,
            rotation: currentProfilePhoto.rotation,
          },
        })
        .then((response) => {
          if (!photoUploadLoading.current) {
            // no error as it was cancelled by the user
            reject();
          }

          // poll for processed photo after upload
          setupPolling({
            requestFn: () =>
              axios.get(
                `/profile_photos/${response.data.data.attributes.hashid}`,
              ),
            testFn: (response) => response.data.data.attributes.processed,
            intervalTime: 1000,
          })
            .startPolling()
            .then((response) => {
              if (!photoUploadLoading.current) {
                reject();
              }
              resolve(response.data.data);
            })
            .catch(() => {
              reject(new Error('Profile photo processing failed'));
            });
        })
        .catch((error) => reject(error));
    });
  };

  const handlePhotoModalClose = () => {
    dispatch({ type: 'PROFILE_PHOTO_MODAL_CLOSE' });

    const profilePhoto =
      store.domain.profilePhotos[
        store.domain.personnel.relationships.profilePhoto?.data?.id
      ];
    setFormPersonnel({
      ...formPersonnel,
      profilePhoto: {
        ...profilePhoto?.attributes,
        id: store.domain.personnel.relationships.profilePhoto?.data?.id,
      },
    });
  };

  return (
    <Fragment>
      {!store.application.pending.assignableCompaniesFetch &&
        !store.application.pending.personnelFetch && (
          <TrackedPersonnelContext.Provider
            value={{
              isCurrentPersonnelTracked: isCurrentPersonnelTracked,
              usage: trackedPersonnelUsage,
              hasReachedLimit: hasReachedTrackedPersonnelLimit,
            }}
          >
            <div className='col-sm-3 p-l-0'>
              <TrainingRegisterResourceManagementContext.Provider
                value={trainingRegisterContextValues}
              >
                <PersonnelPanel
                  availableFieldOptions={availableFieldOptions}
                  availableFields={availableFields}
                  currentPersonnel={displayPersonnel}
                  division={
                    store.domain.companies[
                      store.domain.personnel?.relationships?.company?.data?.id
                    ]
                  }
                  isProfilePhotoLoading={isProfilePhotoChanging}
                  lineManager={
                    store.domain.lineManagers[displayPersonnel.lineManagerId]
                  }
                  onArchiveButtonClick={handleArchiveButtonClick}
                  onDeleteClick={handleDeletePersonnelClick}
                  onSendInvitationClick={handleSendInvitationClick}
                  openSidePanel={openPersonnelSidePanel}
                  personnel={store.domain.personnel}
                  roles={personnelCompanyRoles}
                  subcontractor={
                    store.domain.subcontractors[
                      store.domain.personnel?.relationships?.subcontractor?.data
                        ?.id
                    ]
                  }
                  teams={store.domain.teams}
                  user={
                    store.domain.users[
                      store.domain.personnel?.relationships?.user?.data?.id
                    ]
                  }
                />
              </TrainingRegisterResourceManagementContext.Provider>
            </div>
            {coursesMeta.loaded &&
              !store.application.pending.trainingFetch &&
              !store.application.pending.personnelEventsFetch && (
                <div className='col-sm-9 p-r-0'>
                  {hasReachedTrackedPersonnelUsageThreshold &&
                    (['primary', 'administrator'].includes(
                      currentActor.user.attributes.accessType,
                    ) ||
                      hasReachedTrackedPersonnelLimit) && (
                      <TrackedPersonnelUsageBanner
                        hasReachedLimit={hasReachedTrackedPersonnelLimit}
                        usage={trackedPersonnelUsage.value}
                      />
                    )}
                  <TrainingRegisterResourceManagementContext.Provider
                    value={trainingRegisterContextValues}
                  >
                    <TrainingRecords
                      attachments={Object.values(store.domain.attachments)}
                      autoEnrolmentConfigurations={
                        store.domain.autoEnrolmentConfigurations
                      }
                      autoEnrolmentExclusions={
                        store.domain.autoEnrolmentExclusions
                      }
                      bookers={store.domain.bookers}
                      courseCompanyRoles={courseCompanyRoles}
                      courses={store.domain.courses}
                      coursesMeta={coursesMeta}
                      creditInfoUnavailable={eLearningAllowance.hasError}
                      currentBookings={currentBookings}
                      currentPersonnel={displayPersonnel}
                      currentRegistrations={Object.values(
                        store.domain.registrations,
                      )}
                      eLearningAllowance={eLearningAllowance}
                      eLearningCourses={store.domain.eLearningCourses}
                      fetchPersonnelEvents={fetchPersonnelEvents}
                      hasPersonnelEventViewableAccess={
                        props.hasPersonnelEventViewableAccess
                      }
                      hasTrainingEditableAccess={
                        props.hasTrainingEditableAccess
                      }
                      onAddBooking={handleAddBooking}
                      onAddELearningBooking={handleAddELearningBooking}
                      onAddRole={openPersonnelSidePanel}
                      onBookingReminderClick={handleBookingReminder}
                      onCourseSelectChange={handleCourseSelectChange}
                      onCourseSelectError={breadBoard.addInedibleToast}
                      onCreateAutoEnrolmentExclusion={
                        createAutoEnrolmentExclusion
                      }
                      onCreateUserCourse={createUserCourse}
                      onDeleteAutoEnrolmentExclusion={
                        deleteAutoEnrolmentExclusion
                      }
                      onDeleteCourseClick={handleDeleteCourseClick}
                      onDeleteTrainingClick={handleDeleteTrainingClick}
                      onEditTrainingClick={handleEditTrainingClick}
                      onNewTrainingClick={handleNewTrainingClick}
                      onRemoveBooking={handleRemoveBooking}
                      onShowBooking={handleViewBookingClick}
                      onTrainingEvidenceProcessed={
                        handleTrainingEvidenceProcessed
                      }
                      onUpdateUserCourse={updateUserCourse}
                      personnelEvents={{
                        collection: store.domain.personnelEvents,
                        meta: store.domain.personnelEventsMeta,
                        profilePhotos: store.domain.profilePhotos,
                        users: store.domain.users,
                      }}
                      roles={personnelCompanyRoles}
                      training={allTraining}
                      trainingEvidenceProcessed={trainingEvidenceProcessed}
                      userCourses={Object.values(store.domain.userCourses)}
                    />
                  </TrainingRegisterResourceManagementContext.Provider>
                </div>
              )}
            <TrainingRegisterResourceManagementContext.Provider
              value={trainingRegisterContextValues}
            >
              <PersonnelSidePanel
                assignableCompanies={store.domain.assignableCompanies}
                assignableRoles={
                  store.application.pending.assignableRolesFetch ?
                    []
                  : store.domain.assignableRoles
                }
                availableFieldOptions={availableFieldOptions}
                availableFields={availableFields}
                closeSidePanel={closePersonnelSidePanel}
                currentPersonnel={formPersonnel}
                currentSubcontractor={currentSubcontractor}
                division={
                  store.domain.companies[
                    store.domain.personnel?.relationships?.company?.data?.id
                  ]
                }
                domainProfilePhoto={selectedProfilePhoto}
                isProfilePhotoLoading={isProfilePhotoUploading}
                isSubcontractor={isSubcontractor}
                lineManagers={Object.values(store.domain.lineManagers)}
                onAssignableRoleAdd={handleAssignableRoleAdd}
                onCancel={handleCancel}
                onCompanyRolesOptionChange={handleCompanyRolesOptionChange}
                onDateFieldChange={handleDateFieldChange}
                onDeleteClick={handleDeletePersonnelClick}
                onEditClick={() => setPersonnelSidePanelContext('edit')}
                onFieldOptionChange={handleFieldOptionChange}
                onNewCompanyRoleAdd={handleNewCompanyRoleAdd}
                onPersonnelDateChange={handlePersonnelDateChange}
                onPersonnelFieldChange={handleFieldValueInputChange}
                onPersonnelInputChange={handlePersonnelInputChange}
                onPersonnelOptionChange={handlePersonnelOptionChange}
                onPersonnelUpdateSubmit={handlePersonnelUpdateSubmit}
                onPhotoModalOpen={() =>
                  dispatch({ type: 'PROFILE_PHOTO_MODAL_OPEN' })
                }
                onRemoveRole={handleRemoveRole}
                onSelectPrimary={handleSelectPrimary}
                onSubcontractorSelect={handleSubcontractorSelect}
                onToggle={handleToggle}
                personnel={store.domain.personnel}
                removeErrorStyling={removeErrorStyling}
                requestError={requestError}
                roles={personnelCompanyRoles}
                setCurrentPersonnel={setFormPersonnel}
                setIsSubcontractor={setIsSubcontractor}
                sidePanelContext={'edit'}
                sidePanelIsOpen={personnelSidePanelIsOpen}
                submitDisabled={submitDisabled}
                user={
                  store.domain.users[
                    store.domain.personnel?.relationships?.user?.data?.id
                  ]
                }
              />
            </TrainingRegisterResourceManagementContext.Provider>
            <TrainingRegisterResourceManagementContext.Provider
              value={trainingRegisterContextValues}
            >
              <TrainingSidePanel
                closeSidePanel={closeTrainingSidePanel}
                createTraining={createTraining}
                currentTraining={currentTraining}
                domainCourse={currentTraining.course}
                includedAttachments={Object.values(store.domain.attachments)}
                includedCoverImages={Object.values(store.domain.coverImages)}
                isProfilePhotoUploading={isProfilePhotoUploading}
                onCancelTraining={handleCancelTraining}
                onError={breadBoard.addInedibleToast}
                onInputChange={handleTrainingInputChange}
                onTrainingCalendarClose={handleTrainingCalendarClose}
                onTrainingDateChange={handleTrainingDateChange}
                personnelId={props.personnelId}
                removeErrorStyling={removeErrorStyling}
                requestError={requestError}
                resetRequestError={resetRequestError}
                setSidePanelIsOpen={setTrainingSidePanelIsOpen}
                sidePanelContext={trainingSidePanelContext}
                sidePanelIsOpen={trainingSidePanelIsOpen}
                submitDisabled={submitDisabled}
                updateTraining={updateTraining}
              />
            </TrainingRegisterResourceManagementContext.Provider>

            <TrainingRequirementsSidePanel
              closeSidePanel={closeTrainingRequirementsSidePanel}
              createTrainingRequirement={createTrainingRequirement}
              currentTraining={currentTraining}
              currentTrainingRequirement={currentTrainingRequirement}
              domainCourse={currentTraining.course}
              includedAttachments={Object.values(store.domain.attachments)}
              includedCoverImages={Object.values(store.domain.coverImages)}
              onError={breadBoard.addInedibleToast}
              onTrainingCalendarClose={handleTrainingCalendarClose}
              onTrainingDateChange={handleTrainingDateChange}
              onTrainingInputChange={handleTrainingInputChange}
              onTrainingRequirementInputChange={
                handleTrainingRequirementInputChange
              }
              removeErrorStyling={removeErrorStyling}
              requestError={requestError}
              resetRequestError={resetRequestError}
              setSidePanelIsOpen={setTrainingRequirementsSidePanelIsOpen}
              sidePanelContext={trainingRequirementsSidePanelContext}
              sidePanelIsOpen={trainingRequirementsSidePanelIsOpen}
              submitDisabled={submitDisabled}
            />
            <TrainingRegisterResourceManagementContext.Provider
              value={trainingRegisterContextValues}
            >
              <BookingSidePanel
                currentBooking={currentBooking}
                domainBooker={selectedBooker}
                domainBookingSource={selectedBooking}
                domainCourse={selectedBookingCourse}
                domainPersonnel={formPersonnel}
                domainPersonnelMeta={bookingGroupPersonnelMeta}
                onBookingReminderClick={handleBookingReminder}
                onBookingUpdateSubmit={handleBookingUpdateSubmit}
                onCancel={handleCloseBookingSidePanel}
                onDateChange={handleBookingDateChange}
                onEditBookingClick={handleEditBookingClick}
                onInputChange={handleBookingInputChange}
                onRecordTraining={() =>
                  handleNewTrainingClick({
                    booking: selectedBooking,
                    course: selectedBookingCourse,
                  })
                }
                onRemoveBooking={handleRemoveBooking}
                onSubmit={createBooking}
                removeErrorStyling={removeErrorStyling}
                requestError={requestError}
                showBookingGroupView={false}
                sidePanelContext={bookingSidePanelContext}
                sidePanelIsOpen={bookingSidePanelIsOpen}
                submitDisabled={submitDisabled}
              />
            </TrainingRegisterResourceManagementContext.Provider>
            {bookingELearningModal.course && (
              <BookingELearningModal
                course={bookingELearningModal.course}
                createELearningBooking={createELearningBooking}
                currentCredits={eLearningAllowance.currentCredits}
                eLearningCourse={bookingELearningModal.eLearningCourse}
                identifier={displayPersonnel.firstName}
                isOpen={bookingELearningModal.isOpen}
                setIsClosed={() => {
                  setBookingELearningModal(defaultBookingELearningModal);
                }}
              />
            )}
            {eLearningAllowance.loaded &&
              eLearningAllowance.currentCredits <= 0 && (
                <BookingELearningNoCreditsModal
                  isOpen={bookingELearningNoCreditsModal.isOpen}
                  setIsClosed={() => {
                    setBookingELearningNoCreditsModal(
                      defaultBookingELearningNoCreditsModal,
                    );
                  }}
                />
              )}
            <DestroyModal
              additionalContent={
                hasELearningBookings ?
                  <p className='m-0 m-t-20 tw-rounded-lg tw-border-0 tw-bg-red-025 tw-p-3 tw-text-red-800'>
                    This personnel has eLearning booked which will be cancelled.
                    Any courses in progress will not be refunded.
                  </p>
                : null
              }
              confirmationText={`Delete ${displayPersonnel.firstName}`}
              displayText={`Are you sure you want to delete ${displayPersonnel.firstName}?`}
              isOpen={destroyModalIsOpen}
              onClose={() => setDestroyModalIsOpen(false)}
              onDestroy={destroyPersonnel}
            />
            <TrainingDestroyModal
              currentTraining={currentTraining}
              deleteTraining={deleteTraining}
              isOpen={trainingDestroyModalIsOpen}
              setIsOpen={setTrainingDestroyModalIsOpen}
            />
            <CourseDestroyModal
              currentTraining={currentTraining}
              deleteUserCourse={deleteUserCourse}
              isOpen={courseDestroyModalIsOpen}
              setIsOpen={setCourseDestroyModalIsOpen}
            />
            <BookingDestroyModal
              autoEnrolmentExclusion={selectedBookingAutoEnrolmentExclusion}
              booking={selectedBooking}
              course={selectedBookingCourse}
              isOpen={bookingDestroyModalIsOpen}
              onClose={handleDestroyModalClose}
              onDestroy={deleteBooking}
              personnel={displayPersonnel}
            />
            <BookingReminderModal
              booking={selectedBooking}
              course={selectedBookingCourse}
              isOpen={bookingReminderModalIsOpen}
              onClose={handleReminderModalClose}
              onSendReminderConfirmation={sendBookingReminder}
              personnel={displayPersonnel}
            />
            {!props.isViewingOwnProfile && (
              <SendInvitationModal
                isOpen={sendInvitationModalIsOpen}
                onClose={handleSendInvitationModalClose}
                onSendConfirmationClick={handleSendConfirmationClick}
                personnel={store.domain.personnel}
                user={
                  store.domain.users[
                    store.domain.personnel?.relationships?.user?.data?.id
                  ]
                }
              />
            )}
            {isUnarchivingBlocked ?
              <UnarchiveBlockedModal
                closeModal={closeUnarchiveBlockedModal}
                isOpen={unarchiveBlockedModalIsOpen}
                usage={trackedPersonnelUsage.value}
              />
            : <ArchiveModal
                closeModal={closeArchiveModal}
                hasELearningBookings={hasELearningBookings}
                isArchived={isArchived}
                isOpen={archiveModalIsOpen}
                longName={personDisplayName(displayPersonnel)}
                onArchive={archivePersonnel}
                onUnarchive={unarchivePersonnel}
                ramsPluralName={props.ramsPluralName}
                shortName={displayPersonnel.firstName}
              />
            }
            <Portal containerSelector='[data-portal="page-title"]'>
              {personDisplayName(displayPersonnel)}
            </Portal>

            {isProfilePhotoModalOpen && (
              <ProfilePhotoModal
                currentPersonnel={formPersonnel}
                domainProfilePhoto={selectedProfilePhoto}
                key={currentProfilePhoto?.id}
                onCropReady={handleProfilePhotoCropReady}
                onMediaLoaded={(mediaSize) =>
                  handleProfilePhotoMediaChange(mediaSize)
                }
                onPhotoModalClose={handlePhotoModalClose}
                onPhotoRemoved={handleProfilePhotoRemoval}
                onProcessProfilePhoto={(photoFile) => {
                  dispatch({ type: 'PROFILE_PHOTO_MODAL_CLOSE' });
                  // skip it if photo was removed.
                  if (!!currentProfilePhoto && !!photoFile) {
                    handleProcessProfilePhoto(photoFile);
                  }
                }}
              />
            )}
          </TrackedPersonnelContext.Provider>
        )}
    </Fragment>
  );
}
