import React from 'react';
import useToasts from 'components/hooks/useToasts';
import ToastRack from 'components/application/ToastRack';

export const BreadBoard = React.createContext({});
export const useBreadBoard = () => React.useContext(BreadBoard);

export default function Toaster({ children }) {
  const [toasts, setToasts, addToast, handleBurnToast, addInedibleToast] =
    useToasts();

  return (
    <BreadBoard.Provider
      value={{ setToasts, addToast, handleBurnToast, addInedibleToast }}
    >
      {children}
      <ToastRack toasts={toasts} />
    </BreadBoard.Provider>
  );
}
