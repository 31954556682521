import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/application/Tooltip';
import TextButton from 'components/application/buttons/TextButton';
import classNames from 'classnames';

export default function ArchiveButton(props) {
  const {
    disabled,
    disabledTooltip,
    isArchived,
    isCompact,
    onClick,
    onMouseEnter,
  } = props;

  return (
    <div
      className={classNames(
        'tooltip-parent',
        disabled && 'tooltip-parent--disallowed',
      )}
      onMouseEnter={onMouseEnter}
    >
      <div
        className={classNames(
          'tw-border-0 tw-border-t-1 tw-border-solid tw-border-grey-100',
          isCompact ? 'fh-48' : 'fh-58',
        )}
      >
        <TextButton
          color={isArchived ? 'blue' : 'red'}
          disabled={disabled}
          onClick={onClick}
          size='full'
        >
          {isArchived ? 'Unarchive' : 'Archive'}
        </TextButton>
      </div>
      {disabled && (
        <Tooltip
          className={isCompact ? 'pos-t-12' : 'pos-t-16'}
          placement='top'
          tooltip={disabledTooltip}
          trigger='hover'
        />
      )}
    </div>
  );
}

ArchiveButton.propTypes = {
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.node,
  isCompact: PropTypes.bool,
  isArchived: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
};

ArchiveButton.defaultProps = {
  disabled: false,
  disabledTooltip: null,
  isCompact: false,
  isArchived: false,
};
