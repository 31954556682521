import React from 'react';
import PropTypes from 'prop-types';
import TextButton from 'components/application/buttons/TextButton';

export default function ActionModalContent(props) {
  const {
    bodyIcon,
    bodyText,
    bodyTitle,
    closeButtonText,
    closeModal,
    closeable,
    headerText,
    iconStyling,
    modifiers,
    withHeader,
  } = props;

  return (
    <React.Fragment>
      <div
        className={`action-modal__box tw-bg-white${modifiers ? modifiers.map((modifier) => ` action-modal__box--${modifier}`).join('') : ''}`}
      >
        {withHeader && (
          <section className='action-modal__header truncated-text-container tw-border-grey-100 tw-text-l tw-font-semibold tw-text-grey-900'>
            {headerText}
          </section>
        )}
        <section className='action-modal__body'>
          <span
            className={`action-modal__icon${iconStyling ? ` ${iconStyling}` : ''}`}
          >
            {bodyIcon}
          </span>
          <div className='action-modal__body--title tw-text-l tw-font-semibold tw-text-grey-900'>
            {bodyTitle}
          </div>
          <div className='action-modal__body--text'>{bodyText}</div>
        </section>
        {closeable && (
          <section className='action-modal__footer tw-border-grey-100'>
            <TextButton color='blue' onClick={closeModal} size='md'>
              {closeButtonText}
            </TextButton>
          </section>
        )}
      </div>
    </React.Fragment>
  );
}

ActionModalContent.defaultProps = {
  closeable: true,
  withHeader: true,
  closeButtonText: 'Close',
};

ActionModalContent.propTypes = {
  headerText: PropTypes.string,
  bodyIcon: PropTypes.object,
  bodyTitle: PropTypes.string,
  bodyText: PropTypes.string,
  closeable: PropTypes.bool,
  closeModal: PropTypes.func,
  modifiers: PropTypes.array,
  iconStyling: PropTypes.string,
};
