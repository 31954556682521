import React from 'react';
import PropTypes from 'prop-types';

import ChevronRightIcon from '-!svg-react-loader?height=20px&width=20px&name=ChevronRightIcon!icons/chevron-right.svg';
import classNames from 'classnames';

export default function SelectionRow({
  content,
  isSelected,
  onSelection,
  onTraversal,
  showTraverse,
}) {
  return (
    <div
      className={classNames(
        'row-bar p-t-1 handshq--hoverable b-l-none b-r-none b-t-none tw-group/row tw-border-grey-100 hover:tw-bg-blue-025',
        isSelected ? 'tw-bg-blue-025' : 'tw-bg-white',
      )}
      onClick={onSelection}
    >
      <div className='row-bar__content'>
        <span className='trunc-standard tw-text-grey-900'>{content}</span>
      </div>
      <div className={'row-bar__actions'}>
        {showTraverse && (
          <span
            className={classNames(
              'tw-flex tw-rounded-sm tw-border-1 tw-border-solid tw-bg-white group-hover/row:tw-border-grey-100',
              isSelected ? 'tw-border-grey-100' : 'tw-border-white',
            )}
            onClick={(e) => {
              if (!onTraversal) return undefined;
              e.stopPropagation();
              onTraversal();
            }}
          >
            <ChevronRightIcon className='[&_polygon]:tw-fill-grey-700' />
          </span>
        )}
      </div>
    </div>
  );
}

SelectionRow.propTypes = {
  content: PropTypes.any,
  showTraverse: PropTypes.bool,
  onSelection: PropTypes.func,
  onTraversal: PropTypes.func,
  isSelected: PropTypes.bool,
};
