import { generateMeter } from '../shared/meter';

$(function () {
  $('[data-usage-meter]').each((_index, element) => {
    generateMeter($(element));
  });

  if ($('.primary-user-upgrade').length > 0) {
    fetchUpgradePlansCredits();
  }

  function fetchUpgradePlansCredits() {
    let data;
    let plan_id;

    $('.upgrade-credits').each(function () {
      const targetId = '#' + this.id + ' ';
      transactionSpinnerOn(targetId);
      plan_id = $(this).data('plan-id');
      data = { plan_id: plan_id };
      $.ajax({
        type: 'GET',
        url: '/plans/credits',
        data: data,
        success: function () {
          transactionSpinnerOff(targetId);
        },
      });
    });
  }
});

function transactionSpinnerOn(targetId) {
  $(targetId + '.plan-upgrade-modal .plan-details').hide();
  $(targetId + '.enable_coshh .plan-details').css('visibility', 'hidden');
  $(targetId + '.enable_coshh .plan-details .plan h3').hide();
  $(targetId + '.plan-details #extra-charging').hide();
  $(targetId + '.plan-info a').attr('disabled', 'disabled');
  $(targetId + '.upgrade-pending').show();
}

function transactionSpinnerOff(targetId) {
  $(targetId + '.upgrade-pending').hide();
  $(targetId + '.plan-details').css('visibility', 'visible');
  $(targetId + '.plan-info a').removeAttr('disabled');
}

window.transactionSpinnerOn = transactionSpinnerOn;
window.transactionSpinnerOff = transactionSpinnerOff;
