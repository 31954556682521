import React from 'react';
import PropTypes from 'prop-types';

import Options from 'components/application/Options';
import OptionChoice from '../application/OptionChoice';
import OutlinedButton from '../application/buttons/OutlinedButton';
import ChevronUpIcon from '-!svg-react-loader?name=ChevronUpIcon!icons/chevron-up.svg';
import ChevronDownIcon from '-!svg-react-loader?name=ChevronDownIcon!icons/chevron-down.svg';
import PersonIcon from '-!svg-react-loader?name=PersonIcon!icons/person.svg';
import Tooltip from '../application/Tooltip';
import classNames from 'classnames';

export default function SelectedMembersOptions({ member, onChange }) {
  return (
    <Options
      btnToggle={(isOpen) => {
        return (
          <div className='tooltip-parent tw-h-6'>
            <OutlinedButton
              className={`tw-h-6`}
              color='white'
              onClick={() => {}}
            >
              {member.isManager && <PersonIcon height={20} width={20} />}
              <span className='p-r-4 tw-text-s'>
                {member.isManager ? 'Manager' : 'Member'}
              </span>
              <ChevronUpIcon
                className='m-r--8'
                height={24}
                style={{ display: isOpen ? 'inline-block' : 'none' }}
                width={24}
              />
              <ChevronDownIcon
                className={classNames(
                  'm-r--8',
                  !member.isStatusChangeEnabled &&
                    '[&_polygon]:tw-fill-grey-300',
                )}
                height={24}
                style={{ display: isOpen ? 'none' : 'inline-block' }}
                width={24}
              />
            </OutlinedButton>
            {!member.isStatusChangeEnabled && (
              <Tooltip
                placement='top'
                tooltip={member.tooltip}
                trigger='hover'
              />
            )}
          </div>
        );
      }}
      buttonDisabled={!member.isStatusChangeEnabled}
      key={'memberStatus'}
      listStyle='[&_li]:tw-h-auto'
      toggleStyle={'[&_.options\_\_toggle]:tw-h-6'}
    >
      <OptionChoice
        onClick={(setOptionsOpen) => {
          onChange({ member, isManager: false });
          setOptionsOpen(false);
        }}
      >
        <div className='tw-flex tw-flex-col tw-px-3 tw-py-2.5'>
          <span className='tw-text-m tw-text-grey-700'>Member</span>
          <span className='tw-text-s tw-text-grey-500'>
            No view or edit access
          </span>
        </div>
      </OptionChoice>
      <OptionChoice
        onClick={(setOptionsOpen) => {
          onChange({ member, isManager: true });
          setOptionsOpen(false);
        }}
      >
        <div className='tw-flex tw-flex-col tw-px-3 tw-py-2.5'>
          <span className='tw-text-m tw-text-grey-700'>Manager</span>
          <span className='tw-text-s tw-text-grey-500'>
            Can edit members already in team
          </span>
        </div>
      </OptionChoice>
      <div className='tw-w-[284px] tw-border-t-grey-100 tw-bg-grey-025 tw-px-2.5 tw-py-3 tw-text-s tw-text-grey-500'>
        <div>Only users with personnel editor permission</div>
        <div>
          can add members to teams &nbsp;
          <a
            className='tw-w-0 tw-p-0'
            href='https://intercom.help/hands-hq/en/articles/9158540-how-to-create-and-manage-teams'
            target='_blank'
          >
            Learn more
          </a>
          .
        </div>
      </div>
    </Options>
  );
}

SelectedMembersOptions.propTypes = {
  member: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
