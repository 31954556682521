import React, { useEffect } from 'react';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { v4 as uuidv4 } from 'uuid';

import { collectionsDiffer } from 'components/helpers/objects';
import { resourceIdsAsPositionParams } from 'components/helpers/ordering';
import { genericErrorMessage } from 'components/helpers/errors';

import usePrevious from 'components/hooks/usePrevious';

import AvailableSequence from 'containers/AvailableSequence';

const SortableItem = SortableElement(
  ({ combinedSequence, containerRef, projectTradeId }) => {
    return (
      <AvailableSequence
        containerRef={containerRef}
        masterSequenceId={combinedSequence.masterSequenceId}
        projectTradeId={projectTradeId}
        sequenceId={combinedSequence.sequenceId}
      />
    );
  },
);

const SortableList = SortableContainer(
  ({ containerRef, isSorting, items, projectTradeId }) => {
    return (
      <ul className='block-list block-list--border-bottom-none pos-rel'>
        {items.map((combinedSequence, index) => {
          return (
            <React.Fragment key={`sequence--${index}`}>
              {isSorting && (
                <div className='row-bar--ghost row-bar__ghost--sequence tw-border-blue-100 tw-bg-blue-025'></div>
              )}
              <SortableItem
                combinedSequence={combinedSequence}
                containerRef={containerRef}
                index={index}
                projectTradeId={projectTradeId}
              />
            </React.Fragment>
          );
        })}
      </ul>
    );
  },
);

const collapsedItemSpace = 52;

export default function SequenceList(props) {
  const {
    addError,
    combinedSequences,
    containerRef,
    dispatch,
    projectId,
    projectTradeId,
    sequenceListIsOrdering,
    tradeId,
  } = props;

  // derived
  const _sequenceIds = combinedSequences.reduce(
    (sequenceIds, combinedSequence) => {
      if (combinedSequence.sequenceId)
        sequenceIds.push(combinedSequence.sequenceId);
      return sequenceIds;
    },
    [],
  );

  const wasPreviouslyMounted = usePrevious(true);
  const previousCombinedSequences = usePrevious(combinedSequences);

  useEffect(() => {
    const shouldReorder =
      !!wasPreviouslyMounted &&
      collectionsDiffer(previousCombinedSequences, combinedSequences);
    if (shouldReorder) requestMethodSequenceReorder();
  }, [wasPreviouslyMounted, previousCombinedSequences, combinedSequences]);

  const requestMethodSequenceReorder = () => {
    axios
      .post(
        `/projects/${projectId}/method_sequences_bulk_updates`,
        {
          method_sequences: {
            method_sequences_attributes: resourceIdsAsPositionParams(
              combinedSequences.map(
                (combinedSequence) => combinedSequence.sequenceId,
              ),
            ),
          },
        },
        { params: { trade_id: tradeId } },
      )
      .catch(() => addError(uuidv4(), { fullMessage: genericErrorMessage }));
  };

  const handleSequenceOrder = ({ newIndex, oldIndex }) => {
    const newlyOrderedCombinedSequences = arrayMove(
      combinedSequences,
      oldIndex,
      newIndex,
    );
    dispatch({
      type: 'FINISH_SEQUENCE_ORDERING',
      projectTradeId: projectTradeId,
      newOrdering: newlyOrderedCombinedSequences,
    });
  };

  const handleSortStart = ({ index, node }) => {
    dispatch({
      type: 'START_SEQUENCE_ORDERING',
      projectTradeId: projectTradeId,
      options: { containerRef, node, index, collapsedItemSpace },
    });
  };

  return (
    <SortableList
      containerRef={containerRef}
      getContainer={() => document.getElementById('main')}
      helperClass={'sortable-item--active'}
      isSorting={sequenceListIsOrdering}
      items={combinedSequences}
      lockAxis='y'
      lockToContainerEdges={true}
      onSortEnd={handleSequenceOrder}
      projectTradeId={projectTradeId}
      updateBeforeSortStart={handleSortStart}
      useDragHandle
    />
  );
}
