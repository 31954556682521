export function combinedSequencesList({
  existingMethodSequences,
  projectTradeMasterSequenceIds,
}) {
  const combinedSequences = [];
  const unselectedCustomSequences = [];

  const usedMasterIds = existingMethodSequences.reduce(
    (accumulator, methodSequence) => {
      const masterMethodSequenceId = (
        methodSequence.relationships.masterMethodSequence.data || {}
      ).id;

      if (masterMethodSequenceId) {
        accumulator.push(masterMethodSequenceId);
      }
      return accumulator;
    },
    [],
  );

  const addUnusedMasterMethodSequences = () => {
    projectTradeMasterSequenceIds
      .filter((masterMethodSequenceId) => {
        return !usedMasterIds.includes(masterMethodSequenceId);
      })
      .forEach((masterMethodSequenceId) => {
        combinedSequences.push({
          sequenceId: null,
          masterSequenceId: masterMethodSequenceId,
        });
      });
  };

  const insertSelectedMethodSequences = () => {
    existingMethodSequences.forEach((methodSequence) => {
      if (methodSequence.attributes.selected) {
        const masterMethodSequenceId = (
          methodSequence.relationships.masterMethodSequence.data || {}
        ).id;

        combinedSequences.splice(methodSequence.attributes.position, 0, {
          sequenceId: methodSequence.id,
          masterSequenceId: masterMethodSequenceId || null,
        });
      } else {
        unselectedCustomSequences.push({
          sequenceId: methodSequence.id,
          masterSequenceId: null,
        });
      }
    });
  };

  const addUnselectedCustomSequences = () => {
    combinedSequences.push(...unselectedCustomSequences);
  };

  addUnusedMasterMethodSequences();
  insertSelectedMethodSequences();
  addUnselectedCustomSequences();

  return combinedSequences;
}
