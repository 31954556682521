import classNames from 'classnames';
import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { useSpring, animated } from 'react-spring';
import { v4 as uuidv4 } from 'uuid';

import { genericErrorMessage } from 'components/helpers/errors';

import usePrevious from 'components/hooks/usePrevious';

import AvailableSequenceList from 'containers/AvailableSequenceList';
import AddButton from 'components/buttons/AddButton';
import DragIcon from '-!svg-react-loader?name=DragIcon!icons/ic-drag.svg';
import SubmittableField from 'components/application/SubmittableField';
import RowBar from 'components/application/RowBar';

const DragHandle = SortableHandle((_props) => (
  <div className={'drag-handle drag-handle--padded m-l-3 m-r-3'}>
    <DragIcon
      className={`drag-icon [&_path]:tw-fill-grey-300`}
      height={18}
      width={8}
    />
  </div>
));

export default function ProjectTrade(props) {
  const {
    addError,
    containerRef,
    dispatch,
    isAddingSequence,
    isFocused,
    isOrdering,
    newSequenceNameKey,
    newSequenceText,
    projectTradeId,
    tradeName,
  } = props;

  const modifiers = ['enlarged', 'border-top-curved'];

  if (isOrdering) modifiers.push('border-radius');

  const wasOrdering = usePrevious(isOrdering);

  const springProps = useSpring({
    transform: isOrdering ? `translateY(-150px)` : 'none',
    immediate: !(wasOrdering && !isOrdering),
  });

  const handleSequenceSubmit = (_event, sequenceText) => {
    axios
      .post(`/project_trades/${projectTradeId}/method_sequences`, {
        method_sequence: {
          name: sequenceText,
        },
      })
      .then((response) => {
        dispatch({ type: 'CLEAR_TOGGLE_CONTENT', key: newSequenceNameKey });
        dispatch({
          type: 'ADD_METHOD_SEQUENCE',
          payload: response.data,
          projectTradeId: projectTradeId,
        });
      })
      .catch(() => addError(uuidv4(), { fullMessage: genericErrorMessage }));
  };

  const handleNewSequenceNameChange = (e) => {
    dispatch({
      type: 'EDITING_CONTENT_CHANGE',
      key: `projectTradeNewSequence--${projectTradeId}`,
      value: e.target.value,
    });
  };

  return (
    <div
      className={classNames(
        'tw-mb-6',
        isFocused && !isOrdering && 'tw-relative tw-z-[1]',
      )}
    >
      <RowBar
        actions={
          <AddButton
            customStyleClasses='mw-unset'
            disabled={false}
            onClick={() =>
              dispatch({
                type: 'UI_TOGGLE',
                value: true,
                key: newSequenceNameKey,
              })
            }
            text='Add task'
          />
        }
        additionalClasses='drag-handle-container tw-pl-0 tw-static'
        content={
          <React.Fragment>
            <DragHandle />
            <h3
              className={classNames(
                'tw-m-0 tw-py-4.5 tw-text-l tw-font-semibold',
                isOrdering && 'truncated-text-container',
              )}
            >
              {tradeName}
            </h3>
          </React.Fragment>
        }
        modifiers={modifiers}
      />

      {!isOrdering && (
        <div className='header-bar header-bar--border tw-border-grey-100 tw-bg-grey-050 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
          Task
        </div>
      )}
      <div
        className={classNames(
          'collapsable-list',
          isOrdering ? 'tw-h-0 tw-overflow-hidden' : 'tw-h-auto',
        )}
      >
        <animated.div style={{ transform: springProps.transform }}>
          <div>
            {
              <React.Fragment>
                <AvailableSequenceList
                  containerRef={containerRef}
                  projectTradeId={projectTradeId}
                />
                {isAddingSequence && (
                  <div className='row-bar row-bar--border-top-none row-bar--additional-row layout--b tw-border-grey-100 tw-bg-white'>
                    <SubmittableField
                      autoFocus
                      onCancel={() =>
                        dispatch({
                          type: 'CLEAR_TOGGLE_CONTENT',
                          key: newSequenceNameKey,
                        })
                      }
                      onChange={handleNewSequenceNameChange}
                      onSubmit={handleSequenceSubmit}
                      placeholderText='Enter task name'
                      submitButtonText='Add this task'
                      value={newSequenceText}
                    />
                  </div>
                )}
              </React.Fragment>
            }
          </div>
        </animated.div>
      </div>
    </div>
  );
}
