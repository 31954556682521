import React from 'react';
import PropTypes from 'prop-types';

import OutlinedButton from 'components/application/buttons/OutlinedButton';
import ImagesIcon from '-!svg-react-loader?name=ImagesIcon!icons/add-image.svg';

export default function AttachmentsButton({ onClick }) {
  return (
    <OutlinedButton
      className='attachments__uploader-init'
      color='grey'
      onClick={onClick}
      size='sm'
    >
      <ImagesIcon className='m-r-4' height={20} width={20} />
      <span>Add images</span>
    </OutlinedButton>
  );
}

AttachmentsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
