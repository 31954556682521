import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SortableHandle } from 'react-sortable-hoc';

import { fieldTypeMap } from 'components/helpers/resources/fieldAttributes';

import CheckboxField from 'components/application/CheckboxField';
import Options from 'components/application/Options';
import OptionChoice from 'components/application/OptionChoice';
import Tooltip from 'components/application/Tooltip';
import Switch from 'components/application/Switch';

import DestroyModal from 'components/settings/personnelInformation/DestroyModal';

import DragIcon from '-!svg-react-loader?name=DragIcon!icons/ic-drag.svg';
import LockIcon from '-!svg-react-loader?name=LockIcon!icons/ic-lock-12.svg';
import RestrictedIcon from '-!svg-react-loader?name=RestrictedIcon!icons/ic-restricted-16.svg';
import VisibleIcon from '-!svg-react-loader?name=VisibleIcon!icons/ic-visible-16.svg';

const DragHandle = SortableHandle(() => (
  <div className={`drag-handle`}>
    <DragIcon
      className='drag-icon [&_path]:tw-fill-grey-300'
      height={18}
      width={8}
    />
  </div>
));

export default function FieldRow({
  fieldAttribute,
  fieldSetting,
  onDestroyFieldAttribute,
  onDestroyFieldValues,
  onEdit,
  onIncludedToggle,
  onRestrictedToggle,
}) {
  const [destroyModalIsOpen, setDestroyModalIsOpen] = useState(false);

  const destroyModalTitleText = `Are you sure you want to delete ${fieldAttribute.default ? 'the data for ' : ''}${fieldAttribute.name}?`;
  const destroyModalBodyText = `This will permanently delete the ${fieldAttribute.default ? '' : 'custom field and its '}data from any personnel${fieldAttribute.default ? ', but the field will not be deleted as it is a default' : ''}. This cannot be undone.`;
  const destroyModalCallback = () =>
    fieldAttribute.default ?
      onDestroyFieldValues({ fieldAttribute })
    : onDestroyFieldAttribute({ fieldSetting, fieldAttribute });

  return (
    <div
      className='row-bar row-bar--flex-align-items-ie-fix row-bar--border-top-none options-toggle-container drag-handle-container tw-group/row tw-border-grey-100 hover:tw-bg-grey-025'
      key={fieldSetting.id}
    >
      <div className='fw-52'>
        <div className='flex flex--vertically-centered'>
          <div className='flex flex--vertically-centered m-l--12'>
            <DragHandle />
          </div>
          <div className='m-l-8'>
            <CheckboxField
              checked={fieldSetting.included}
              height='short'
              labelTextProps={{ className: 'tw-font-medium' }}
              name='included'
              onChange={() => onIncludedToggle({ fieldSetting: fieldSetting })}
            />
          </div>
        </div>
      </div>
      <div className='flex-item flex-item--grow-but-truncate'>
        <div className='flex flex--vertically-centered'>
          <div className='truncated-text-container truncated-text-container--auto m-r-8 tw-text-m tw-font-medium tw-text-grey-900'>
            {fieldAttribute.name}
          </div>
          {fieldAttribute.default && (
            <div className='l-if flex--vertically-centered fh-20 br-3 p-t-2 p-r-4 p-b-2 p-l-4 m-r-8 tw-bg-grey-100 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-600'>
              <LockIcon
                className='m-r-2 [&_path]:tw-fill-grey-600'
                height={12}
                width={12}
              />
              <span>Default</span>
            </div>
          )}
        </div>
      </div>
      <div className='fw-148 tw-text-m'>
        {fieldTypeMap[fieldAttribute.formattedFieldType]}
      </div>
      <div className='fw-124 tw-text-m'>
        {fieldSetting.restricted ?
          <div className='flex flex--vertically-centered'>
            <RestrictedIcon className='m-r-8 [&_path]:tw-fill-red-400' />
            <span>Restricted</span>
          </div>
        : <div className='flex flex--vertically-centered'>
            <VisibleIcon className='m-r-8 [&_path]:tw-fill-grey-600' />
            <span>Visible</span>
          </div>
        }
      </div>
      <div className='fw-80 p-r-32 tw-text-m'>
        <div className='flex flex--vertically-centered flex--justify-content__center'>
          {fieldAttribute.fieldValueCount}
        </div>
      </div>
      <div className='fw-40'>
        <Options>
          {fieldAttribute.default ?
            <OptionChoice disabled={true}>
              <Tooltip
                className='tooltip-dark--max-w-xxs'
                placement='left'
                tooltip="Default fields can't be edited"
                trigger='hover'
              >
                <a className='tw-text-m tw-font-medium tw-tracking-auto tw-text-grey-700 hover:tw-text-grey-700'>
                  Edit field
                </a>
              </Tooltip>
            </OptionChoice>
          : <OptionChoice
              onClick={(setOptionsOpen) => {
                setOptionsOpen(false);
                onEdit({ id: fieldSetting.id });
              }}
            >
              <a className='tw-text-m tw-font-medium tw-tracking-auto tw-text-grey-700 hover:tw-text-grey-700'>
                Edit field
              </a>
            </OptionChoice>
          }
          <OptionChoice
            hoverClassName='hover:tw-bg-blue-025'
            onClick={() => onRestrictedToggle({ fieldSetting: fieldSetting })}
          >
            <a className='tw-group/option tw-text-m tw-font-medium tw-tracking-auto tw-text-grey-700 hover:tw-text-grey-700'>
              <Switch checked={fieldSetting.restricted} className='fl-r' />
              <span className='layout--ib fw-132'>Restricted?</span>
            </a>
          </OptionChoice>
          {fieldAttribute.default ?
            fieldAttribute.fieldValueCount === 0 ?
              <OptionChoice disabled={true} warning={true}>
                <Tooltip
                  className='tooltip-dark--max-w-xxs'
                  placement='left'
                  tooltip="Default fields can't be deleted and there is no data to delete"
                  trigger='hover'
                >
                  <a className='tw-text-m tw-font-medium tw-tracking-auto tw-text-red-600 hover:tw-text-red-600'>
                    Delete data
                  </a>
                </Tooltip>
              </OptionChoice>
            : <OptionChoice
                onClick={() => setDestroyModalIsOpen(true)}
                warning={true}
              >
                <a className='tw-text-m tw-font-medium tw-tracking-auto tw-text-red-600 hover:tw-text-red-600'>
                  Delete data
                </a>
              </OptionChoice>

          : <OptionChoice
              onClick={() => setDestroyModalIsOpen(true)}
              warning={true}
            >
              <a className='tw-text-m tw-font-medium tw-tracking-auto tw-text-red-600 hover:tw-text-red-600'>
                Delete
              </a>
            </OptionChoice>
          }
        </Options>
        <DestroyModal
          bodyText={destroyModalBodyText}
          fieldValueCount={fieldAttribute.fieldValueCount}
          isOpen={destroyModalIsOpen}
          onDestroy={destroyModalCallback}
          setClose={() => setDestroyModalIsOpen(false)}
          titleText={destroyModalTitleText}
        />
      </div>
    </div>
  );
}

FieldRow.propTypes = {
  fieldSetting: PropTypes.exact({
    id: PropTypes.string.isRequired,
    included: PropTypes.bool.isRequired,
    restricted: PropTypes.bool.isRequired,
    position: PropTypes.number.isRequired,
  }).isRequired,
  fieldAttribute: PropTypes.exact({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    default: PropTypes.bool.isRequired,
    formattedFieldType: PropTypes.string.isRequired,
    fieldValueCount: PropTypes.number.isRequired,
  }).isRequired,
  onIncludedToggle: PropTypes.func.isRequired,
  onRestrictedToggle: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDestroyFieldAttribute: PropTypes.func.isRequired,
  onDestroyFieldValues: PropTypes.func.isRequired,
};
