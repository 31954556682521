import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/application/Table';
import RolesRow from 'components/roles/RolesRow';
import { useCurrentActor } from 'components/contexts/CurrentActor';

export default function RolesTable(props) {
  const currentActor = useCurrentActor();

  return (
    <Table
      headers={
        <tr>
          {currentActor.isAllowedFeature('training_register') && (
            <th className='fw-66 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
              Status
            </th>
          )}
          <th className='tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Role
          </th>
          {currentActor.isAllowedFeature('training_register') && (
            <th className='fw-168 tw-text-center tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
              Courses
            </th>
          )}
          <th className='fw-168 tw-text-center tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Personnel
          </th>
        </tr>
      }
      rows={props.roles.map((role, index) => (
        <RolesRow
          key={index}
          openSidePanel={props.openSidePanel}
          resetRequestError={props.resetRequestError}
          role={role}
          setCurrentRole={props.setCurrentRole}
          setSidePanelContext={props.setSidePanelContext}
        />
      ))}
    />
  );
}

RolesTable.propTypes = {
  roles: PropTypes.array.isRequired,
  setSidePanelContext: PropTypes.func.isRequired,
  setCurrentRole: PropTypes.func.isRequired,
  openSidePanel: PropTypes.func.isRequired,
};
