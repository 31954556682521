import React, { useReducer, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import arrayMove from 'array-move';

import useToasts from 'components/hooks/useToasts';

import { fieldSettingAttributes } from 'components/helpers/resources/fieldSettings';
import { resourceIdsAsPositionParams } from 'components/helpers/ordering';

import RowBar from 'components/application/RowBar';
import AddButton from 'components/buttons/AddButton';
import ToastRack from 'components/application/ToastRack';
import ResourceChangedToast from 'components/application/ResourceChangedToast';

import personnelInformationReducer, {
  initialState,
  mapDispatch,
} from 'reducers/personnelInformationReducer';

import FieldToast from 'components/settings/personnelInformation/FieldToast';
import FieldTable from 'components/settings/personnelInformation/FieldTable';
import FieldSidePanel from 'components/settings/personnelInformation/FieldSidePanel';
import UpdateConfirmationModal from 'components/settings/personnelInformation/UpdateConfirmationModal';

export default function FieldSettings({ initialFieldSettings }) {
  const [state, dispatch] = useReducer(
    personnelInformationReducer,
    initialState,
  );
  const actions = mapDispatch(dispatch);

  const [
    fieldSettingUpdateConfirmationModal,
    setFieldSettingUpdateConfirmationModal,
  ] = useState({ isOpen: false, destroyFieldValueCount: 0 });
  const [toasts, , addToast, handleBurnToast, addInedibleToast] = useToasts();

  const formattedRows = state.domain.fieldSettingCollection.allDataIds.map(
    (fieldSettingId) => {
      const fieldSetting =
        state.domain.fieldSettingCollection.data[fieldSettingId];
      const fieldAttribute =
        state.domain.fieldAttributeCollection.data[
          fieldSetting.relationships.fieldAttribute.data.id
        ];

      return {
        fieldSetting: { id: fieldSetting.id, ...fieldSetting.attributes },
        fieldAttribute: { id: fieldAttribute.id, ...fieldAttribute.attributes },
      };
    },
  );

  const createFieldSetting = () => {
    actions.startSubmittingForm();

    axios
      .post(
        `/custom_fields/field_settings`,
        fieldSettingAttributes(state.application.form),
      )
      .then((response) => {
        const fieldAttribute = response.data.included.find(
          (inclusion) => inclusion.type === 'fieldAttribute',
        );
        actions.appendFieldSetting({ data: response.data });
        addToast(
          <ResourceChangedToast
            onBurnToast={handleBurnToast}
            resource={fieldAttribute.attributes.name}
            status={'added'}
          />,
        );
      })
      .catch((error) =>
        error.response.status === 422 ?
          actions.setValidationErrors({ data: error.response.data })
        : actions.setFallbackError(),
      );
  };

  const confirmUpdateFieldSetting = () => {
    actions.startSubmittingForm();

    axios
      .post(
        `/custom_fields/field_settings/${state.application.form.fieldSettingId}/update_confirmation`,
        fieldSettingAttributes(state.application.form),
      )
      .then((response) => {
        const { destroyFieldValueCount, required: isConfirmationRequired } =
          response.data.data.attributes;
        isConfirmationRequired ?
          setFieldSettingUpdateConfirmationModal({
            isOpen: true,
            destroyFieldValueCount: destroyFieldValueCount,
          })
        : updateFieldSetting();
      })
      .catch((_error) => actions.setFallbackError());
  };

  const updateFieldSetting = () => {
    axios
      .patch(
        `/custom_fields/field_settings/${state.application.form.fieldSettingId}`,
        fieldSettingAttributes(state.application.form),
      )
      .then((response) => {
        const fieldAttribute = response.data.included.find(
          (inclusion) => inclusion.type === 'fieldAttribute',
        );
        actions.updateFieldSetting({ data: response.data });
        addToast(
          <ResourceChangedToast
            onBurnToast={handleBurnToast}
            resource={fieldAttribute.attributes.name}
            status={'updated'}
          />,
        );
      })
      .catch((error) =>
        error.response.status === 422 ?
          actions.setValidationErrors({ data: error.response.data })
        : actions.setFallbackError(),
      );
  };

  const destroyFieldAttribute = ({ fieldAttribute, fieldSetting }) => {
    axios
      .delete(`/custom_fields/field_attributes/${fieldAttribute.id}`)
      .then((_response) => {
        actions.deleteFieldSetting({ id: fieldSetting.id });
        addToast(
          <FieldToast
            message='field and data were deleted'
            onBurnToast={handleBurnToast}
            resource={fieldAttribute.name}
          />,
        );
      });
  };

  const destroyFieldValues = ({ fieldAttribute }) => {
    axios
      .post(
        `/custom_fields/field_attributes/${fieldAttribute.id}/field_values_bulk_destroy`,
      )
      .then((_response) => {
        actions.zeroFieldValueCount({ id: fieldAttribute.id });
        addToast(
          <FieldToast
            message='data was deleted'
            onBurnToast={handleBurnToast}
            resource={fieldAttribute.name}
          />,
        );
      });
  };

  const updateFieldSettingIncluded = ({ fieldSetting }) => {
    axios
      .patch(`/custom_fields/field_settings/${fieldSetting.id}`, {
        field_setting: { included: !fieldSetting.included },
      })
      .then((response) => {
        actions.updateFieldSetting({ data: response.data });
      })
      .catch(() => addInedibleToast());
  };

  const updateFieldSettingRestricted = ({ fieldSetting }) => {
    axios
      .patch(`/custom_fields/field_settings/${fieldSetting.id}`, {
        field_setting: { restricted: !fieldSetting.restricted },
      })
      .then((response) => {
        actions.updateFieldSetting({ data: response.data });
      })
      .catch(() => addInedibleToast());
  };

  const updateFieldSettingsOrder = ({ newIndex, oldIndex }) => {
    if (oldIndex !== newIndex) {
      const orderedFieldSettingIds = arrayMove(
        state.domain.fieldSettingCollection.allDataIds,
        oldIndex,
        newIndex,
      );

      actions.reorderFieldSettings({ ids: orderedFieldSettingIds });
      axios
        .post(`/custom_fields/field_settings_bulk_update`, {
          field_settings_bulk_update: {
            field_settings_attributes: resourceIdsAsPositionParams(
              orderedFieldSettingIds,
            ),
          },
        })
        .catch(() => addInedibleToast());
    }
  };

  useEffect(() => actions.loadData({ data: initialFieldSettings }), []);

  return (
    <>
      <h3 className='m-t-0 m-b-24 tw-text-l tw-font-semibold tw-tracking-auto tw-text-grey-900'>
        Personnel information
      </h3>
      <p className='m-b-20'>Customise the information stored for personnel</p>
      <RowBar
        actions={
          <AddButton
            onClick={actions.openNewSidePanel}
            text='Add custom field'
          />
        }
        modifiers={['border-top-curved', 'large']}
      />
      <FieldTable
        formattedRows={formattedRows}
        onDestroyFieldAttribute={destroyFieldAttribute}
        onDestroyFieldValues={destroyFieldValues}
        onEdit={actions.openEditSidePanel}
        onIncludedToggle={updateFieldSettingIncluded}
        onRestrictedToggle={updateFieldSettingRestricted}
        onSortEnd={updateFieldSettingsOrder}
      />
      <FieldSidePanel
        closeCallback={actions.resetSidePanel}
        form={state.application.form}
        onCreate={createFieldSetting}
        onFormOptionAppend={actions.appendFormOption}
        onFormOptionDelete={actions.deleteFormOption}
        onFormOptionOrderChange={actions.updateFormOptionOrder}
        onFormOptionValueChange={actions.updateFormOptionValue}
        onFormValueChange={actions.updateFormValue}
        onUpdate={confirmUpdateFieldSetting}
        removeErrorStyling={actions.removeErrorStyling}
        sidePanel={state.ui.sidePanel}
      />
      <UpdateConfirmationModal
        destroyFieldValueCount={
          fieldSettingUpdateConfirmationModal.destroyFieldValueCount
        }
        isOpen={fieldSettingUpdateConfirmationModal.isOpen}
        onUpdate={updateFieldSetting}
        setClose={() => {
          setFieldSettingUpdateConfirmationModal({
            isOpen: false,
            destroyFieldValueCount: 0,
          });
          actions.stopSubmittingForm();
        }}
      />
      <ToastRack toasts={toasts} />
    </>
  );
}

FieldSettings.propTypes = {
  initialFieldSettings: PropTypes.exact({
    data: PropTypes.array.isRequired,
    included: PropTypes.array,
  }).isRequired,
};
