import React from 'react';
import PropTypes from 'prop-types';

import ModalBox from 'components/application/ModalBox';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function DestroyModal(props) {
  const { bodyText, fieldValueCount, isOpen, onDestroy, setClose, titleText } =
    props;

  const handleDeleteClick = () => {
    setClose();
    onDestroy();
  };

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton className='m-r-12' color='blue' onClick={setClose} size='md'>
        Cancel
      </TextButton>
      <FilledButton color='red' onClick={handleDeleteClick}>
        {`Delete${fieldValueCount ? ` ${fieldValueCount} record${fieldValueCount > 1 ? 's' : ''}` : ''}`}
      </FilledButton>
    </div>
  );

  return (
    <ModalBox
      customFooter={customFooter}
      isOpen={isOpen}
      mode='flexible'
      onClose={setClose}
    >
      <React.Fragment>
        <div className='modalbox-body'>
          <div className='m-b-16 tw-text-l tw-font-semibold tw-text-grey-900'>
            {titleText}
          </div>
          <div>{bodyText}</div>
        </div>
      </React.Fragment>
    </ModalBox>
  );
}

DestroyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  fieldValueCount: PropTypes.number.isRequired,
  titleText: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  onDestroy: PropTypes.func.isRequired,
};
