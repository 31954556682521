import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';
import moment from 'moment';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import useTrackedPersonnel from 'components/hooks/useTrackedPersonnel';
import { useCurrentActor } from 'components/contexts/CurrentActor';

import { subcontractorDisplayName } from 'components/helpers/companies';
import {
  prioritisedAutoEnrolmentBlockers as prioritisedAutoEnrolmentBlockersList,
  hasOnlyNonCriticalBlockers,
  autoEnrolmentNonCriticalBlockersStatusText,
} from 'components/helpers/resources/autoEnrolments';

import BlockList from 'components/application/BlockList';
import PersonnelCourseTrainingStatusIndicator from 'components/personnel/PersonnelCourseTrainingStatusIndicator';
import CourseBookedProgressPill from 'components/courses/pills/CourseBookedProgressPill';
import ELearningCourseAutoEnrolsPill from 'components/courses/pills/ELearningCourseAutoEnrolsPill';
import ProfilePhotoDisplay from 'components/application/ProfilePhotoDisplay';

export default function CourseSidePanelPersonnelTab({
  autoEnrolmentConfiguration,
  course,
  domainCourse,
  eLearningCourse,
  isLoadMorePersonnelActive,
  loadMoreRef,
  personnel,
}) {
  const [eLearningAllowance, setELearningAllowance] = useState({
    loaded: false,
    currentCredits: null,
    hasError: false,
  });
  const [, , , hasReachedTrackedPersonnelLimit] = useTrackedPersonnel();
  const currentActor = useCurrentActor();

  useEffect(() => {
    if (
      currentActor.isAllowedFeature([
        'e_learning_auto_enrol',
        'e_learning',
        'training_register',
      ])
    ) {
      fetchELearningAllowance();
    }
  }, []);

  const fetchELearningAllowance = () => {
    axios
      .get('/e_learning/allowance')
      .then((response) =>
        setELearningAllowance({
          ...eLearningAllowance,
          loaded: true,
          currentCredits: response.data.data.attributes.currentCredits,
        }),
      )
      .catch((_error) =>
        setELearningAllowance({ ...eLearningAllowance, hasError: true }),
      );
  };

  const personnelBookingsMap = personnel.includedBookings.reduce(
    (acc, booking) => {
      acc[booking.relationships.personnel.data.id] = booking;
      return acc;
    },
    {},
  );

  const personnelProfilePhotosMap = personnel.includedProfilePhotos.reduce(
    (acc, profilePhoto) => {
      acc[profilePhoto.relationships.personnel.data.id] = profilePhoto;
      return acc;
    },
    {},
  );

  let personnelRegistrationsMap = {};
  if (course.isELearningProviderSelected) {
    personnelRegistrationsMap = personnel.includedRegistrations.reduce(
      (acc, registration) => {
        acc[registration.relationships.personnel.data.id] = registration;
        return acc;
      },
      {},
    );
  }

  const personnelTrainingsMap = personnel.collection.reduce((acc, person) => {
    const matchingTraining = personnel.includedTrainings.find(
      (trainingItem) =>
        trainingItem.relationships.personnel.data.id === person.id,
    );
    acc[person.id] = matchingTraining;
    return acc;
  }, {});

  const autoEnrolmentExclusionsMap =
    personnel.includedAutoEnrolmentExclusions.reduce(
      (acc, autoEnrolmentExclusion) => {
        acc[autoEnrolmentExclusion.relationships.personnel.data.id] =
          autoEnrolmentExclusion;
        return acc;
      },
      {},
    );
  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();

  return (
    <div key='personnelTab' label='personnel'>
      {personnel.loaded && personnel.collection.length === 0 ?
        <li className='block-list__item tw-border-grey-100'>
          <div className='block-list__item--flex-container'>
            <div className='block-list__item--align-center'>
              <span>
                No active personnel require or have completed this course
              </span>
            </div>
          </div>
        </li>
      : <>
          <BlockList
            blockListItemClasses={(person) =>
              person.attributes.subcontractorId ?
                ' block-list__item--triple'
              : ''
            }
            collection={personnel.collection}
            onItemClick={
              (
                trainingRegisterResourceManagementContext.hasPersonnelViewableAccess
              ) ?
                (person) => window.location.replace(`personnel/${person.id}`)
              : undefined
            }
            onItemDisplay={(person) => {
              const booking = personnelBookingsMap[person.id];

              let registration, autoEnrolmentExclusion;
              if (course.isELearningProviderSelected) {
                registration = personnelRegistrationsMap[person.id];
                autoEnrolmentExclusion = autoEnrolmentExclusionsMap[person.id];
              }

              const latestTraining = personnelTrainingsMap[person.id];
              const profilePhoto = personnelProfilePhotosMap[person.id];

              const eLearningAutoEnrolmentPill =
                (function eLearningAutoEnrolmentPill() {
                  if (
                    currentActor.isAllowedFeature([
                      'e_learning_auto_enrol',
                      'e_learning',
                      'training_register',
                    ]) &&
                    eLearningCourse &&
                    !booking &&
                    !autoEnrolmentExclusion
                  ) {
                    const prioritisedAutoEnrolmentBlockers =
                      prioritisedAutoEnrolmentBlockersList({
                        isELearningNonCustom:
                          eLearningCourse && !eLearningCourse.attributes.custom,
                        isCreditInfoUnavailable: eLearningAllowance.hasError,
                        isPersonnelEmailUnavailable: !person.attributes.email,
                        currentELearningCredits:
                          eLearningAllowance.loaded &&
                          eLearningAllowance.currentCredits,
                        isAutoEnrolmentIncludingMissingTraining:
                          trainingRegisterResourceManagementContext.isAutoEnrolmentIncludingMissingTraining,
                        training: latestTraining,
                        isBookingAllowedWithinTrackedPersonnelLimits:
                          !hasReachedTrackedPersonnelLimit ||
                          person.attributes.isTracked,
                        autoEnrolmentConfiguration,
                        isExpiringCourse: domainCourse.attributes.expires,
                        isSubcontractor: !!person.attributes.subcontractorId,
                        isRequired: person.meta?.isCourseRequired,
                        isFromAutoEnrollableDivision:
                          person.meta?.isFromAutoEnrollableDivision,
                      });

                    const hasNoBlockers =
                      prioritisedAutoEnrolmentBlockers.length === 0;
                    const hasNonCriticalBlockers = hasOnlyNonCriticalBlockers({
                      prioritisedAutoEnrolmentBlockers,
                    });

                    if (hasNoBlockers || hasNonCriticalBlockers) {
                      return (
                        <ELearningCourseAutoEnrolsPill
                          color={hasNonCriticalBlockers ? 'amber' : 'green'}
                          tooltipText={autoEnrolmentNonCriticalBlockersStatusText(
                            {
                              priorityBlocker:
                                prioritisedAutoEnrolmentBlockers[0],
                              latestTraining: latestTraining,
                              courseExpiringDuration:
                                domainCourse.attributes.expiringDuration,
                            },
                          )}
                        />
                      );
                    }
                  }
                })();

              return (
                <div className='block-list__item--flex-container'>
                  <div className='tw-flex tw-items-center'>
                    <div className='tw-mr-3'>
                      <PersonnelCourseTrainingStatusIndicator
                        status={person.attributes.trainingStatus}
                      />
                    </div>
                    <ProfilePhotoDisplay
                      componentSize='sm'
                      photoUrl={profilePhoto?.links.croppedUrl}
                      profileInitials={`${person.attributes.firstName[0]}${person.attributes.lastName[0]}`}
                    />
                  </div>

                  <div className='block-list__item--text mw-300'>
                    <div className='flex mw-300'>
                      <span className='truncated-text-container truncated-text-container--width-auto'>
                        {`${person.attributes.firstName} ${person.attributes.lastName}`}
                      </span>
                      {booking && (
                        <CourseBookedProgressPill
                          booking={booking}
                          isELearningProviderSelected={
                            course.isELearningProviderSelected
                          }
                          registration={registration}
                        />
                      )}
                      {eLearningAutoEnrolmentPill}
                    </div>
                    {person.attributes.subcontractorId && (
                      <span className='block-list__item--sub-text-triple truncated-text-container tw-text-s tw-tracking-wide tw-text-grey-500'>
                        {subcontractorDisplayName(
                          person.attributes.subcontractorId,
                          personnel.includedSubcontractors,
                        )}
                      </span>
                    )}
                    <span className='block-list__item--sub-text-triple truncated-text-container tw-text-s tw-tracking-wide tw-text-grey-500'>
                      {getTrainingDescription(
                        person.attributes.trainingStatus,
                        course,
                        personnelTrainingsMap[person.id],
                      )}
                    </span>
                  </div>
                </div>
              );
            }}
          />
          {isLoadMorePersonnelActive && !personnel.meta.isLastPage && (
            <li
              className='block-list__item tw-border-grey-100'
              ref={loadMoreRef}
            >
              <div className='block-list__item--flex-container'>
                <div className='block-list__item--align-center'>
                  <span>Loading personnel...</span>
                </div>
              </div>
            </li>
          )}
        </>
      }
    </div>
  );
}

const trainingStatusDefinitionMap = {
  0: 'Required training is missing',
  1: 'Required training is missing expiry date',
  2: 'Required training has expired',
  3: 'Required training is expiring soon',
  4: 'Training is up to date',
  5: 'No required training',
};

function getTrainingDescription(trainingStatus, course, latestTraining) {
  function expiryDateText(prefixText) {
    return `${prefixText} ${moment.parseZone(latestTraining.attributes.expiryDate).format('DD MMM YYYY')}`;
  }

  switch (trainingStatusDefinitionMap[trainingStatus]) {
    case 'Required training is missing':
    case 'Required training is missing expiry date':
      return 'Missing';
    case 'Required training has expired':
      return course.expires ? expiryDateText('Expired') : 'Does not expire';
    case 'Required training is expiring soon':
      return course.expires ? expiryDateText('Expires') : 'Does not expire';
    case 'Training is up to date':
      return course.expires ? expiryDateText('Expires') : 'Does not expire';
    case 'No required training':
      if (!latestTraining) {
        return 'Missing but not required';
      } else {
        const isExpired =
          moment.parseZone(latestTraining.attributes.expiryDate) <=
          moment.now();
        if (course.expires && isExpired) {
          return `${expiryDateText('Expired')} but not required`;
        } else if (course.expires && !isExpired) {
          return `${expiryDateText('Expires')} but not required`;
        } else if (!course.expires) {
          return 'Does not expire';
        }
      }
  }
}

CourseSidePanelPersonnelTab.propTypes = {
  course: PropTypes.object.isRequired,
  domainCourse: resourceShape('course').isRequired,
  autoEnrolmentConfiguration: resourceShape('autoEnrolmentConfiguration'),
  personnel: PropTypes.object.isRequired,
  loadMoreRef: PropTypes.func.isRequired,
  isLoadMorePersonnelActive: PropTypes.bool.isRequired,
};
