import { useState, useEffect } from 'react';

export default function useAttachmentsVisibility(attachments) {
  const [isVisible, setIsVisible] = useState(false);

  const setVisible = () => setIsVisible(true);
  const setInvisible = () => setIsVisible(false);

  useEffect(() => {
    attachments.length === 0 ? setInvisible() : setVisible();
  }, [JSON.stringify(attachments)]);

  return [isVisible, setVisible, setInvisible];
}
