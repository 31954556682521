import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React from 'react';
import { resourceShape } from 'components/helpers/serialisableResources';
import AppWrapper from 'components/apps/AppWrapper';
import NearestMedicalLocationFormSection from 'components/medicalLocations/NearestMedicalLocationFormSection';

export default function NearestMedicalLocationWrapper({
  contextProps,
  wrappedProps,
}) {
  const queryClient = new QueryClient();

  return (
    <AppWrapper {...contextProps}>
      <QueryClientProvider client={queryClient}>
        <NearestMedicalLocationFormSection {...wrappedProps} />
      </QueryClientProvider>
    </AppWrapper>
  );
}

NearestMedicalLocationWrapper.propTypes = {
  wrappedProps: PropTypes.shape({
    domainMedicalLocation: resourceShape('medicalLocation'),
    domainMedicalLocationMapConfiguration: resourceShape(
      'medicalLocationMapConfiguration',
    ),
    domainMedicalLocationMapConfigurationImageUrl: PropTypes.string,
  }),
};
