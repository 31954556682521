import HandshqApp from '../handshq-app';

HandshqApp.configurePayment = function () {
  const blankFields = {};
  const euCountries = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'GB',
    'GR',
    'HR',
    'HU',
    'IE',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SE',
    'SI',
    'SK',
  ];
  const cvvTip = $('.cvv-tip').data('content');
  const otherCvvTip =
    'The CVV number is the 3 or 4 digit number printed on the signature strip on the reverse of your card.';
  const amexCvvTip =
    'The CVV number is the 4 digit number printed on the front of your card.';
  const paymentMessages = $('.payment-messages');
  const paymentSubmitButton = $('.payment-details__submit');
  const formControlClass = 'tw-border-grey-300 hover:tw-border-grey-400';
  const formControlErrorClass = 'tw-border-red-600';
  const recurlyFieldFocusClass = 'tw-border-blue-300';

  function submitHandler(_form) {
    $('.payment-form').on('submit', function (event) {
      paymentMessages.html('');
      paymentSubmitButton.attr('disabled', 'disabled');

      $('.form-control').removeClass(formControlErrorClass);
      $('.form-control').addClass(formControlClass);

      if (HandshqApp.rails.testEnv !== 'true') {
        const form = this;
        event.preventDefault();

        recurly.token(form, function (err, _token) {
          if (err) {
            errorHandler(err.fields);
          } else {
            form.submit();
          }
        });
      }
    });
  }

  function errorHandler(fields) {
    if (fields) {
      const alert =
        "<div class='tw-border-0 tw-rounded-lg tw-text-red-800 tw-bg-red-025 tw-p-3 tw-mb-10'><p>Please fix the following errors:</p><ul></ul></div>";
      paymentMessages.append(alert);
      const messageList = paymentMessages.find('ul');

      paymentSubmitButton.removeAttr('disabled');
      appendMessages(messageList, fields);

      $('html, body').animate({ scrollTop: 0 }, 'fast');
    }
  }

  function appendMessages(messageList, fields) {
    fields.forEach(function (field) {
      const fieldElement = $('[data-recurly="' + field + '"]');
      const labelText = fieldElement.parent().find('label').text();

      if (
        blankFields[field] ||
        (blankFields[field] === undefined && fieldElement.val().length === 0)
      ) {
        messageList.append('<li>' + labelText + " can't be blank</li>");
      } else {
        messageList.append('<li>' + labelText + ' is incorrect</li>');
      }

      $('[data-recurly="' + field + '"]').addClass(formControlErrorClass);
      $('[data-recurly="' + field + '"]').removeClass(formControlClass);
    });
  }

  function recurlyChangeHandler() {
    recurly.on('change', function (state) {
      focusField(state);
      checkCardType(state);
      emptyFields(state);
    });
  }

  function focusField(state) {
    const fields = {
      number: $('#payment_details_card_number'),
      month: $('#payment_details_card_month'),
      year: $('#payment_details_card_year'),
      cvv: $('#payment_details_card_cvv'),
    };

    Object.keys(state.fields).forEach(function (name) {
      if (state.fields[name].focus) {
        fields[name].removeClass(formControlClass);
        fields[name].addClass(recurlyFieldFocusClass);
      } else {
        fields[name].removeClass(recurlyFieldFocusClass);
        fields[name].addClass(formControlClass);
      }
    });
  }

  function checkCardType(state) {
    const cardType = state.fields.number.brand;

    if (cardType !== 'unknown') {
      $('.credit-cards')
        .not('#' + cardType)
        .addClass('grey-out');
      $('#' + cardType).removeClass('grey-out');
    } else {
      $('.credit-cards').removeClass('grey-out');
    }

    if (cardType === 'american_express') {
      $('.cvv-tip').attr(
        'data-content',
        cvvTip.replace(otherCvvTip, amexCvvTip),
      );
    } else {
      $('.cvv-tip').attr(
        'data-content',
        cvvTip.replace(amexCvvTip, otherCvvTip),
      );
    }
  }

  function emptyFields(state) {
    Object.keys(state.fields).forEach(function (name) {
      blankFields[name] = state.fields[name].empty;
    });
  }

  function vatHandler() {
    const signupSubtotal = $('.payment-subtotal dd').text();
    const signupTotal = $('.payment-total dd').text();

    vatNumberChangeHandler(signupSubtotal, signupTotal);
    countryChangeHandler(signupSubtotal, signupTotal);
  }

  function vatNumberChangeHandler(signupSubtotal, signupTotal) {
    $('#payment_details_vat_number').on('keyup', function () {
      const country = $('#payment_details_country').val();

      if (country !== 'GB') {
        if (this.value.length > 4) {
          costInfo('hide', signupSubtotal);
        } else {
          if (euCountries.includes(country)) {
            costInfo('show', signupTotal);
          }
        }
      }
    });
  }

  function countryChangeHandler(signupSubtotal, signupTotal) {
    $('#payment_details_country').on('change', function () {
      const vat = $('#payment_details_vat_number').val();

      if (!euCountries.includes(this.value)) {
        costInfo('hide', signupSubtotal);
      } else {
        if (vat.length <= 4 || this.value === 'GB') {
          costInfo('show', signupTotal);
        } else {
          costInfo('hide', signupSubtotal);
        }
      }
    });
  }

  function costInfo(toggleVisibility, signupCost) {
    $('.payment-vat')[toggleVisibility]();
    $('.payment-total dd').html(signupCost);
  }

  recurlyChangeHandler();
  submitHandler();
  vatHandler();
};
