import HandshqApp from '../handshq-app';

HandshqApp.imageHelper = {
  renderImageWhenProcessed: function (imageId) {
    const _this = this;

    $.ajax({
      type: 'GET',
      url: '/images/' + imageId,
      dataType: 'json',
      success: function (response) {
        if (response.attached_processed === true) {
          _this.renderProcessedImage(imageId);
        } else {
          setTimeout(function () {
            _this.renderImageWhenProcessed(imageId);
          }, 2000);
        }
      },
    });
  },

  renderProcessedImage: function (imageId) {
    $.ajax({ type: 'GET', dataType: 'script', url: '/images/' + imageId });
  },

  pendingImageRotation: function () {
    $('.method_text-image-container, .image-container').each(function () {
      const methodTextImage = $(this)
        .find('.method_text-image, .image-container__display')
        .eq(0);
      const rotateBtn = $(this)
        .find('.method-text-image-rotate, .image-container__rotate')
        .eq(0);

      rotateBtn.on('click', function () {
        methodTextImage.html(
          "<div class='loading-image'><img src='" +
            HandshqApp.rails.imageIconSvgPath +
            "' class='loading-image__picture-icon'><img src='" +
            HandshqApp.rails.loadingIconSvgPath +
            "' class='loading-image__loading-icon' alt='Loading icon'></div>",
        );
      });
    });
  },

  deleteMethodTextImage: function (image) {
    if (image !== undefined) {
      $('body').on(
        {
          click: function () {
            $('#image-' + image.id).fadeOut(function () {
              $(this).remove();
            });
          },
        },
        '#delete-image-' + image.id,
      );
    } else {
      $('.method-text-image-delete, .image-container__delete').click(
        function () {
          $('.popover').remove();

          $('#image-' + this.id.replace('delete-image-', '')).fadeOut(
            function () {
              $(this).remove();
            },
          );
        },
      );
    }
  },

  methodTextsImagesPosition: function (list) {
    const imageList = list.children;
    const positions = [];

    for (let i = 0; i < imageList.length; i++) {
      const imageId = imageList[i].id.replace('image-', '');
      positions.push({ id: parseInt(imageId), position: i + 1 });
    }

    return positions;
  },

  updateImagesPosition: function (methodTextId, images) {
    $.ajax({
      type: 'PUT',
      data: JSON.stringify({ method_text: { images: images } }),
      url: '/method_texts/images/positions',
      contentType: 'application/json',
    });
  },
};

$(function () {
  (function renderProcessingImagesWhenProcessed() {
    const processingImages = $(
      '.loading-image__loading-icon[data-image-id!=""]',
    );

    if (processingImages.length) {
      processingImages.each(function () {
        HandshqApp.imageHelper.renderImageWhenProcessed(
          $(this).attr('data-image-id'),
        );
      });
    }
  })();
});
