import React from 'react';
import PropTypes from 'prop-types';

import { resourceShape } from 'components/helpers/serialisableResources';
import { personDisplayName } from 'components/helpers/users';
import { useBreadBoard } from 'components/contexts/Toaster';
import { useCurrentActor } from 'components/contexts/CurrentActor';

import Toast from 'components/application/Toast';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import ProjectPersonnelAdditionUndoneToast from 'components/projectPersonnel/ProjectPersonnelAdditionUndoneToast';

import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';

export default function ProjectPersonnelAddedToast({
  destroyProjectPersonnel,
  displayName,
  personnel,
  projectPersonnelId,
  toastKey,
  userInvited = false,
}) {
  const breadBoard = useBreadBoard();
  const currentActor = useCurrentActor();

  const toastMessage = ` was successfully added to the ${currentActor.division.attributes.singularRAMSName || 'RAMS'}${userInvited ? ' and sent an invitation email' : ''}`;

  const onUndo = () => {
    // promise based chain ideally
    destroyProjectPersonnel(projectPersonnelId, displayName, true);
    breadBoard.addToast(
      <ProjectPersonnelAdditionUndoneToast
        onBurnToast={breadBoard.handleBurnToast}
        personnel={personnel}
      />,
    );
    breadBoard.handleBurnToast(toastKey);
  };

  return (
    <Toast
      burn={breadBoard.handleBurnToast}
      burnTimer={8000}
      contents={
        <React.Fragment>
          <div className='toast__icon tw-bg-green-500'>
            <TickIcon
              className='[&_polygon]:tw-fill-white'
              height={20}
              width={20}
            />
          </div>
          <div className='toast__text'>
            <span className='tw-font-medium'>
              {personDisplayName(personnel.attributes)}
            </span>
            {toastMessage}
          </div>
          <div className='p-l-32 p-t-12'>
            <OutlinedButton color='grey' onClick={onUndo} size='sm'>
              Undo
            </OutlinedButton>
          </div>
        </React.Fragment>
      }
      modifiers='with-undo'
      toastKey={toastKey}
    />
  );
}

ProjectPersonnelAddedToast.propTypes = {
  toastKey: PropTypes.string,
  projectPersonnelId: PropTypes.string.isRequired,
  personnel: resourceShape('personnel').isRequired,
  displayName: PropTypes.string.isRequired,
  userInvited: PropTypes.bool.isRequired,
  destroyProjectPersonnel: PropTypes.func.isRequired,
};
