import React from 'react';
import PropTypes from 'prop-types';
import PersonIcon from '-!svg-react-loader?name=PersonIcon!icons/person-lg.svg';

const componentSizeClassNames = new Map([
  [
    'sm',
    new Map([
      ['parentClassNames', 'tw-w-8 tw-h-8'],
      ['textClassNames', 'tw-text-sm'],
    ]),
  ],
  [
    'md',
    new Map([
      ['parentClassNames', 'tw-w-16 tw-h-16'],
      ['textClassNames', 'tw-text-2xl'],
    ]),
  ],
  [
    'lg',
    new Map([
      ['parentClassNames', 'tw-w-20 tw-h-20'],
      ['textClassNames', 'tw-text-3xl'],
    ]),
  ],
]);

export default function ProfilePhotoDisplay({
  componentSize,
  photoUrl,
  profileInitials,
}) {
  return (() => {
    if (photoUrl) {
      return (
        <img
          className={`tw-rounded-full ${componentSizeClassNames.get(componentSize).get('parentClassNames')}`}
          src={photoUrl}
        />
      );
    }

    if (profileInitials.length === 2) {
      return (
        <div
          className={`tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-blue-050 ${componentSizeClassNames.get(componentSize).get('parentClassNames')}`}
        >
          <span
            className={`tw-flex tw-items-center tw-justify-center tw-align-middle tw-font-semibold tw-uppercase tw-text-grey-600 ${componentSizeClassNames.get(componentSize).get('textClassNames')}`}
          >
            {profileInitials}
          </span>
        </div>
      );
    }

    return (
      <div
        className={`tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-grey-200 ${componentSizeClassNames.get(componentSize).get('parentClassNames')}`}
      >
        <PersonIcon className='[&_path]:tw-fill-white' height={40} width={40} />
      </div>
    );
  })();
}

ProfilePhotoDisplay.propTypes = {
  photoUrl: PropTypes.string,
  profileInitials: PropTypes.string,
  componentSize: PropTypes.oneOf(['sm', 'md', 'lg']).isRequired,
};
