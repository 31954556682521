import moment from 'moment';

const dateRegex =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;

function serialize(item) {
  return JSON.stringify(item);
}

function deserialize(item) {
  return JSON.parse(item, (_key, value) => {
    if (typeof value === 'string' && dateRegex.test(value)) {
      return moment.parseZone(value).toDate();
    }

    return value;
  });
}

export default function useWindowStorage(
  key,
  { store = window.localStorage } = {},
) {
  const getItem = () => {
    const storedItem = store.getItem(key);
    return deserialize(storedItem);
  };

  const setItem = (item) => {
    const newItem = serialize(item);
    store.setItem(key, newItem);
    return newItem;
  };

  const removeItem = () => {
    const removedItem = store.getItem(key);
    store.removeItem(key);
    return removedItem;
  };

  return [getItem, setItem, removeItem];
}
