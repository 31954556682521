function uniqueValuesInArray(array) {
  return array.filter((value, index, self) => self.indexOf(value) === index);
}

function sortAlphabetically(a, b) {
  return a > b ? 1 : -1;
}

function sortByDate(a, b) {
  return new Date(a) - new Date(b);
}

function sortByDateDesc(a, b) {
  return new Date(b) - new Date(a);
}

export { uniqueValuesInArray, sortAlphabetically, sortByDate, sortByDateDesc };
