import React from 'react';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import AddButton from 'components/buttons/AddButton';
import SortableCollection from 'components/collections/SortableCollection';
import ErrorMessage from 'components/application/ErrorMessage';

export default function CustomPersonSidePanelBody(props) {
  const {
    collection,
    displayValidationErrors,
    isFallback,
    onAdd,
    resetDefaults,
  } = props;

  return (
    <React.Fragment>
      <div className='m-0 m-20 tw-flex tw-rounded-lg tw-border-0 tw-bg-cyan-025 tw-p-3 tw-text-cyan-800'>
        <div className='m-r-12 fh-20'>
          <InfoIcon
            className='[&_path]:tw-fill-cyan-800'
            height={20}
            width={20}
          />
        </div>
        <p className='m-0'>Changes will not update existing risk assessments</p>
      </div>
      <ErrorMessage
        isFallback={isFallback}
        modifiers='side-panel__alert side-panel__alert--danger'
        validationErrors={displayValidationErrors}
        wrapperClassName='form-container'
      />
      <SortableCollection collection={collection} collectionProps={props} />
      <div className='side-panel__options-container'>
        <AddButton
          onClick={() => {
            onAdd();
          }}
          text='Add another'
        />
        <button
          className='app-link reset-defaults-button tw-bg-transparent tw-font-inter tw-text-m tw-font-medium tw-tracking-auto tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
          onClick={resetDefaults}
        >
          Reset to default list
        </button>
      </div>
    </React.Fragment>
  );
}
