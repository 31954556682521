import React from 'react';

import Options from 'components/application/Options';
import OptionChoice from 'components/application/OptionChoice';

export default function MinimalTrainingOptions(props) {
  const { onDeleteTrainingClick, onEditTrainingClick } = props;

  return (
    <Options>
      <OptionChoice>
        <a
          className='tw-text-m tw-font-medium tw-tracking-auto tw-text-grey-700 hover:tw-text-grey-700'
          onClick={onEditTrainingClick}
        >
          Edit training
        </a>
      </OptionChoice>
      <OptionChoice warning={true}>
        <a
          className='tw-text-m tw-font-medium tw-tracking-auto tw-text-grey-700 hover:tw-text-grey-700'
          onClick={onDeleteTrainingClick}
        >
          Delete training
        </a>
      </OptionChoice>
    </Options>
  );
}
