import React from 'react';
import ChevronLeftIcon from '-!svg-react-loader?name=ChevronLeftIcon!icons/chevron-left.svg';
import ChevronRightIcon from '-!svg-react-loader?name=ChevronRightIcon!icons/chevron-right.svg';
import classNames from 'classnames';

export default function Stepper({
  currentStep,
  onStepDown,
  onStepUp,
  totalSteps,
}) {
  const steppingDisabled = totalSteps === currentStep && totalSteps === 1;
  return (
    <div
      className={classNames(
        'stepper',
        steppingDisabled && 'tw-pointer-events-none tw-opacity-30',
      )}
    >
      <div
        className='stepper__nav stepper__left tw-bg-grey-050 hover:tw-bg-blue-050 [&_svg_polygon]:tw-fill-grey-700 [&_svg_polygon]:hover:tw-fill-grey-900'
        onClick={onStepDown}
      >
        <ChevronLeftIcon />
      </div>
      <div className='stepper__info tw-bg-grey-025 tw-font-medium tw-text-grey-600'>{`${currentStep} of ${totalSteps}`}</div>
      <div
        className='stepper__nav stepper__right tw-bg-grey-050 hover:tw-bg-blue-050 [&_svg_polygon]:tw-fill-grey-700 [&_svg_polygon]:hover:tw-fill-grey-900'
        onClick={onStepUp}
      >
        <ChevronRightIcon />
      </div>
    </div>
  );
}
