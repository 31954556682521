import React from 'react';
import DownloadPdfIcon from '-!svg-react-loader?name=DownloadPdfIcon!icons/ic-download-pdf.svg';
import classNames from 'classnames';

export default function PdfDownloadContainer(props) {
  const { BrandedLink, createdAt, downloadHref, fileSize, title, viewHref } =
    props;

  return (
    <div
      className={classNames(
        'document-container tw-bg-grey-050',
        title === 'Training report' && 'm-t-16',
      )}
    >
      <DownloadPdfIcon />
      <div className={'m-l-12'}>
        <div className='download-container__title tw-font-semibold tw-text-grey-900'>
          {title}
        </div>
        <div className='m-b-8 download-container__sub-text'>
          Created {createdAt} • {fileSize}
        </div>
        <span className={'m-r-20'}>
          <BrandedLink
            linkText={'View'}
            styleClasses={'tw-font-semibold'}
            target={'_blank'}
            url={viewHref}
          />
        </span>
        <BrandedLink
          linkText={'Download'}
          styleClasses={'tw-font-semibold'}
          url={downloadHref}
        />
      </div>
    </div>
  );
}
