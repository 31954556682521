function uniquePersonsAtRiskOptions(
  personsAtRiskOptions,
  selectedPersonsAtRiskValues = [],
  secondaryFieldLabel = 'Other (specify)',
) {
  const previouslySelectedValues = selectedPersonsAtRiskValues.map(
    (person) => person.value,
  );
  const optionsSet = new Set([
    ...personsAtRiskOptions,
    ...previouslySelectedValues,
    secondaryFieldLabel,
  ]);
  const formattedValues = Array.from(optionsSet).map((person) => ({
    label: person,
    value: person,
    name: person,
  }));

  return formattedValues;
}

function formatPersonsAtRiskValues(
  includedResources = [],
  resourceName,
  attrName,
) {
  const filteredIncludedResources = includedResources.filter(
    (obj) => obj.type === resourceName,
  );
  const mappedIncludedResources = filteredIncludedResources.map((resource) => ({
    id: resource.id,
    key: resource.attributes[attrName],
    label: resource.attributes[attrName],
    value: resource.attributes[attrName],
    name: resource.attributes[attrName],
    isSelected: true,
  }));
  return mappedIncludedResources;
}

const updatedPersonsAtRiskValues = (initialArray, updatedArray) => {
  if (initialArray.length === 0) return updatedArray;
  const collectedAllNameValues = [];

  const initialValuesIncludingDestroyKey = initialArray.reduce(
    (accumulator, currentValue) => {
      collectedAllNameValues.push(currentValue.name);

      if (updatedArray.find((newObj) => newObj.name === currentValue.name)) {
        accumulator.push(currentValue);
      } else {
        const duplicateObj = { ...currentValue };
        duplicateObj['_destroy'] = '1';
        accumulator.push(duplicateObj);
      }
      return accumulator;
    },
    [],
  );

  const newValues = updatedArray.filter(
    (obj) =>
      !collectedAllNameValues.includes(obj.name) &&
      !Boolean(obj.id) &&
      obj.name !== undefined,
  );
  return [...initialValuesIncludingDestroyKey, ...newValues];
};

const defaultCoshhDocument = {
  id: null,
  title: '',
  reference: '',
  quantityOnSite: '',
  quantityToBeUsed: '',
  substanceQuantityPerDay: '',
  uses: '',
  staffNumber: '',
  personsAtRisk: [],
  personsAtRiskOther: '',
  workerExposureTime: '',
  exposureFrequency: '',
  exposureFrequencyOther: '',
  location: '',
  levelOfRisk: '',
  additionalInformation: '',
  longTermExposureLimit: '',
  shortTermExposureLimit: '',
  manufacturer: '',
};

export {
  uniquePersonsAtRiskOptions,
  formatPersonsAtRiskValues,
  updatedPersonsAtRiskValues,
  defaultCoshhDocument,
};
