import React from 'react';
import PropTypes from 'prop-types';
import RowBar from 'components/application/RowBar';
import SearchField from 'components/application/SearchField';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import AddIcon from '-!svg-react-loader?name=AddIcon!icons/add.svg';

export default function RolesBar(props) {
  return (
    <RowBar
      actions={
        props.actionButtonsVisible && (
          <OutlinedButton color='grey' onClick={props.onNewRole} size='sm'>
            <AddIcon className='m-l--8' height={24} width={24} />
            <span>Add new role</span>
          </OutlinedButton>
        )
      }
      content={
        <SearchField
          additionalClasses='search-field__lg'
          name='rolesSearch'
          onChange={props.onSearchInputChange}
          onReset={props.onSearchReset}
          placeholder='Search your roles...'
          value={props.rolesSearch}
        />
      }
      modifiers={[
        'border-top-curved',
        'border-bottom-none',
        'flex-align-items-ie-fix',
      ]}
    />
  );
}

RolesBar.propTypes = {
  onNewRole: PropTypes.func.isRequired,
};
