import React from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';

export default function CategoriesAffectedTooltip({
  categories,
  division,
  riskAssessment,
}) {
  return (
    <div className='ta-l'>
      <span>
        {riskAssessment.attributes.name} is being used by{' '}
        {division.attributes.name} in the following categories:
        <ul className='p-l-20'>
          {categories.map((category) => (
            <li key={`categoryAffected--${category.id}`}>
              {category.attributes.name}
            </li>
          ))}
        </ul>
      </span>
    </div>
  );
}

CategoriesAffectedTooltip.propTypes = {
  riskAssessment: resourceShape('masterActivity'),
  division: resourceShape('company'),
  categories: PropTypes.arrayOf(resourceShape('trade')).isRequired,
};
