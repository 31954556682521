import MobileDetect from 'mobile-detect';

$(document).ready(function () {
  $.scrollIt();

  if ($('input').length !== 0) {
    $('input, textarea').placeholder(); // IE placeholder fix
  }

  $('html').on('click', function (e) {
    if (typeof $(e.target).data('original-title') == 'undefined') {
      $('[data-original-title]').popover('hide');
    }
  });

  if ($('.search-field__clear').length !== 0) {
    $('.search-field__clear').on('click', function (_e) {
      const input = $(this).parent().prev();
      input.val('');
      input.trigger('input');
      $(this).hide();
    });
  }

  const md = new MobileDetect(window.navigator.userAgent);

  if (!md.mobile()) {
    document.documentElement.className += ' no-touch';
  } else {
    document.documentElement.className += ' touch';

    $('.modal').attr('data-backdrop', 'false');
  }
});

function clearButton(clearableInput) {
  const clearInputEl = $('.search-field__clear');
  $(clearableInput).val().length === 0 ?
    clearInputEl.hide()
  : clearInputEl.show();
}

export { clearButton };
