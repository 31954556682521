import React, { useState, useRef } from 'react';
import { focusField } from 'components/helpers/refs';
import SidePanel from 'components/application/SidePanel';
import FormFooter from 'components/sidepanels/FormFooter';
import ShowFooter from 'components/sidepanels/ShowFooter';
import SubcontractorsForm from './SubcontractorsForm';
import SubcontractorsShow from './SubcontractorsShow';
import PanelIcon from 'components/application/PanelIcon';
import SubcontractorIcon from '-!svg-react-loader?name=SubcontractorIcon!icons/ic-sub-contractors-large.svg';

export default function SubcontractorsSidePanel(props) {
  const {
    allowShowFooterContents,
    closeSidePanel,
    createSubcontractor,
    currentSubcontractor,
    onDeleteClick,
    onSubcontractorInputChange,
    removeErrorStyling,
    requestError,
    setSidePanelContext,
    sidePanelContext,
    sidePanelIsOpen,
    submitDisabled,
    updateSubcontractor,
  } = props;

  const [scrolledAmount, setScrolledAmount] = useState(0);
  const sidePanelBodyRef = useRef();
  const sidePanelFieldRef = useRef();
  const openEditSubcontractor = () => {
    setSidePanelContext('edit');
  };

  const iconHeaderContent = (
    <PanelIcon Icon={SubcontractorIcon} scrolledAmount={scrolledAmount} />
  );

  const titleHeaderContent = (
    <div className='popup__title popup__title--tertiary'>
      <h1 className='tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-900'>
        {sidePanelContext === 'edit' ?
          'Edit sub-contractor'
        : 'Add new sub-contractor'}
      </h1>
    </div>
  );

  const showBodyContent = (
    <SubcontractorsShow
      currentSubcontractor={currentSubcontractor}
      sidePanelIsOpen={sidePanelIsOpen}
    />
  );

  const formBodyContent = (
    <SubcontractorsForm
      currentSubcontractor={currentSubcontractor}
      onSubcontractorInputChange={onSubcontractorInputChange}
      removeErrorStyling={removeErrorStyling}
      requestError={requestError}
      sidePanelFieldRef={sidePanelFieldRef}
      submitDisabled={submitDisabled}
    />
  );

  const newFooterContent = (
    <FormFooter
      onCancel={closeSidePanel}
      onSubmit={createSubcontractor}
      submitButtonDisabled={submitDisabled}
      text='Add new sub-contractor'
    />
  );

  const showFooterContent = allowShowFooterContents && (
    <ShowFooter
      deleteDisabled={currentSubcontractor.isDeleteProhibited}
      deleteDisabledTooltip='Sub-contractors with personnel cannot be deleted'
      onDeleteClick={onDeleteClick}
      onEditClick={openEditSubcontractor}
      resource={currentSubcontractor.name}
      type='sub-contractor'
    />
  );

  const editFooterContent = (
    <FormFooter
      onCancel={closeSidePanel}
      onSubmit={updateSubcontractor}
      submitButtonDisabled={submitDisabled}
      text='Save changes'
    />
  );

  const footerContent = () => {
    switch (sidePanelContext) {
      case 'new':
        return newFooterContent;
      case 'show':
        return showFooterContent;
      case 'edit':
        return editFooterContent;
    }
  };

  const onBackdropClick = () => {
    const panelLocked = ['new', 'edit'].includes(sidePanelContext);
    if (!panelLocked) {
      closeSidePanel();
    }
  };

  return (
    <SidePanel
      bodyContent={
        sidePanelContext === 'show' ? showBodyContent : formBodyContent
      }
      bodyRef={sidePanelBodyRef}
      closeCallback={closeSidePanel}
      color={sidePanelContext === 'show' ? 'secondary' : 'tertiary'}
      contentContext={sidePanelContext}
      displayClose={sidePanelContext === 'show'}
      footerContent={footerContent()}
      headerContent={
        sidePanelContext === 'show' ? iconHeaderContent : titleHeaderContent
      }
      isOpen={sidePanelIsOpen}
      onBackdropClick={onBackdropClick}
      onOpen={() => sidePanelContext === 'new' && focusField(sidePanelFieldRef)}
      scrollHeader={sidePanelContext === 'show'}
      scrolledAmount={scrolledAmount}
      setScrolledAmount={setScrolledAmount}
      submitDisabled={submitDisabled}
    />
  );
}
