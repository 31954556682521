import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { resourceShape } from 'components/helpers/serialisableResources';
import { groupAttachmentsByAttachableId } from 'components/helpers/resources/attachments';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrackedPersonnelContext } from 'components/contexts/TrackedPersonnelContext';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

import useToggle from 'components/hooks/useToggle';
import { prioritisedAutoEnrolmentBlockers as prioritisedAutoEnrolmentBlockersList } from 'components/helpers/resources/autoEnrolments';

import TrainingRow from 'components/training/TrainingRow';
import TrainingStatus from 'components/training/TrainingStatus';
import ELearningBookings from 'components/training/ELearningBookings';
import TrainingBookings from 'components/training/TrainingBookings';
import TrainingOptions from 'components/training/TrainingOptions';
import MinimalTrainingOptions from 'components/training/MinimalTrainingOptions';

export default function CourseTraining({
  attachments,
  autoEnrolmentConfiguration,
  autoEnrolmentExclusion,
  booker,
  booking,
  course,
  creditInfoUnavailable,
  currentPersonnel,
  eLearningAllowance,
  eLearningCourse,
  hasTrainingEditableAccess,
  isAdditional,
  isRequired,
  isRequiredByAnotherRole,
  isRequiredByAnything,
  isWithinRoleTable,
  onAddBooking,
  onAddELearningBooking,
  onBookingReminderClick,
  onCreateAutoEnrolmentExclusion,
  onCreateUserCourse,
  onDeleteAutoEnrolmentExclusion,
  onDeleteCourseClick,
  onDeleteTrainingClick,
  onEditTrainingClick,
  onNewTrainingClick,
  onRemoveBooking,
  onShowBooking,
  onUpdateUserCourse,
  personnelId,
  registration,
  requiredSource,
  setModalBoxData,
  training,
  userCourse,
}) {
  const currentActor = useCurrentActor();
  const trackedPersonnelContext = useTrackedPersonnelContext();
  const {
    hasBookingEditableAccess,
    isAutoEnrolmentIncludingMissingTraining,
    isViewingOwnProfile,
  } = useTrainingRegisterResources();

  const attachmentsByAttachableId = groupAttachmentsByAttachableId({
    attachments: attachments,
  });
  const isArchived = !!currentPersonnel.archivedAt;
  const isReadOnlyOrArchived = !hasTrainingEditableAccess || isArchived;
  const courseHasELearningCourse =
    currentActor.isAllowedFeature('e_learning') && !!eLearningCourse;
  const isELearningNonCustom =
    courseHasELearningCourse && !eLearningCourse.attributes.custom;
  const latestTraining = training[0];
  const isBookingAllowedWithinTrackedPersonnelLimits =
    !trackedPersonnelContext.hasReachedLimit ||
    trackedPersonnelContext.isCurrentPersonnelTracked;

  const [isRequiredUserSelection, setIsRequiredUserSelection] = useState(
    !!(isWithinRoleTable ? isRequired : (
      !!userCourse && userCourse.attributes.required
    )),
  );
  const [
    currentAutoEnrolmentExclusionUserSelection,
    setCurrentAutoEnrolmentExclusionUserSelection,
  ] = useState(!!autoEnrolmentExclusion);
  const [isHistoricalTrainingVisible, toggleHistoricalTraining] =
    useToggle(false);

  const handleIsRequiredUserSelectionChange = (isRequiredUserSelection) =>
    setIsRequiredUserSelection(isRequiredUserSelection);
  const handleCurrentAutoEnrolmentExclusionUserSelectionChange = (
    currentAutoEnrolmentExclusionUserSelection,
  ) =>
    setCurrentAutoEnrolmentExclusionUserSelection(
      currentAutoEnrolmentExclusionUserSelection,
    );
  const isEligibleForAutoEnrolment =
    eLearningCourse &&
    currentActor.isAllowedFeature([
      'e_learning_auto_enrol',
      'e_learning',
      'training_register',
    ]);

  const prioritisedAutoEnrolmentBlockers =
    isEligibleForAutoEnrolment ?
      prioritisedAutoEnrolmentBlockersList({
        autoEnrolmentConfiguration: autoEnrolmentConfiguration,
        isExpiringCourse: course.attributes.expires,
        training: latestTraining,
        isSubcontractor: !!currentPersonnel.company.subcontractorId,
        isRequired: isRequiredUserSelection || isRequiredByAnotherRole,
        isAutoEnrolmentIncludingMissingTraining,
        isBookingAllowedWithinTrackedPersonnelLimits,
        isELearningNonCustom,
        isCreditInfoUnavailable: creditInfoUnavailable,
        isPersonnelEmailUnavailable: !currentPersonnel.email,
        currentELearningCredits: eLearningAllowance?.currentCredits,
        isFromAutoEnrollableDivision:
          currentPersonnel.isFromAutoEnrollableDivision,
      })
    : null;

  const handleTrainingOptionsClose = () => {
    manageUserCourses();
    if (isEligibleForAutoEnrolment) {
      manageAutoEnrolmentExclusions();
    }
  };

  const manageUserCourses = () => {
    if (isWithinRoleTable) return;

    if (!userCourse && isRequiredUserSelection) {
      onCreateUserCourse({ course, params: { required: true } });
    } else if (
      userCourse &&
      userCourse.attributes.required !== isRequiredUserSelection
    ) {
      onUpdateUserCourse({
        userCourse,
        params: { required: isRequiredUserSelection },
      });
    }
  };

  const manageAutoEnrolmentExclusions = () => {
    if (prioritisedAutoEnrolmentBlockers.length === 0) {
      if (
        !currentAutoEnrolmentExclusionUserSelection &&
        autoEnrolmentExclusion
      ) {
        onDeleteAutoEnrolmentExclusion({ autoEnrolmentExclusion });
      } else if (
        currentAutoEnrolmentExclusionUserSelection &&
        !autoEnrolmentExclusion
      ) {
        onCreateAutoEnrolmentExclusion({
          autoEnrolmentConfigurationId: autoEnrolmentConfiguration.id,
          personnelId: currentPersonnel.id,
        });
      }
    }
  };

  const renderStatus = ({ currentTrainingStatus }) => {
    return (
      <TrainingStatus
        currentTrainingStatus={isArchived ? 'archived' : currentTrainingStatus}
        isRequired={isRequired}
        requiredSource={requiredSource}
      />
    );
  };

  const renderBookings = ({ currentTrainingStatus }) => {
    if (isArchived) return null;

    const isEligibleForManualBooking =
      (isRequired || isRequiredByAnything) &&
      isBookingAllowedWithinTrackedPersonnelLimits &&
      hasTrainingEditableAccess &&
      !currentPersonnel.company.subcontractorId &&
      !['valid', 'expiryDateMissing'].includes(currentTrainingStatus);

    return courseHasELearningCourse ?
        <ELearningBookings
          autoEnrolmentConfiguration={autoEnrolmentConfiguration}
          booker={booker}
          booking={booking}
          course={course}
          currentAutoEnrolmentExclusionUserSelection={
            currentAutoEnrolmentExclusionUserSelection
          }
          currentPersonnel={currentPersonnel}
          currentTrainingStatus={currentTrainingStatus}
          eLearningCourse={eLearningCourse}
          isCreditInfoUnavailable={
            isELearningNonCustom && creditInfoUnavailable
          }
          isELearningLaunchable={isViewingOwnProfile}
          isEligibleForManualBooking={
            isEligibleForManualBooking && hasBookingEditableAccess
          }
          latestTraining={latestTraining}
          onAddELearningBooking={onAddELearningBooking}
          prioritisedAutoEnrolmentBlockers={prioritisedAutoEnrolmentBlockers}
          registration={registration}
        />
      : <TrainingBookings
          booking={booking}
          course={course}
          currentTrainingStatus={currentTrainingStatus}
          isBookingTooltipDisplayable={
            hasBookingEditableAccess || hasTrainingEditableAccess
          }
          isEligibleForManualBooking={
            isEligibleForManualBooking && hasBookingEditableAccess
          }
          onAddBooking={onAddBooking}
          onShowBooking={onShowBooking}
        />;
  };

  const renderRegularTrainingActions = ({ training: optionsTraining }) => {
    if (isReadOnlyOrArchived && training.length <= 1) return null;

    return (
      <TrainingOptions
        booking={booking}
        course={course}
        courseHasELearningCourse={courseHasELearningCourse}
        creditInfoUnavailable={isELearningNonCustom && creditInfoUnavailable}
        currentAutoEnrolmentExclusionUserSelection={
          currentAutoEnrolmentExclusionUserSelection
        }
        currentPersonnel={currentPersonnel}
        eLearningCourse={eLearningCourse}
        isAdditional={isAdditional}
        isHistoricalTrainingVisible={isHistoricalTrainingVisible}
        isMultipleTraining={training.length > 1}
        isRequired={isRequired}
        isRequiredByAnotherRole={isRequiredByAnotherRole}
        isRequiredUserSelection={isRequiredUserSelection}
        isWithinRoleTable={isWithinRoleTable}
        onAddBooking={onAddBooking}
        onAddELearningBooking={onAddELearningBooking}
        onBookingReminderClick={onBookingReminderClick}
        onCurrentAutoEnrolmentExclusionUserSelectionChange={
          handleCurrentAutoEnrolmentExclusionUserSelectionChange
        }
        onDeleteCourseClick={onDeleteCourseClick}
        onDeleteTrainingClick={onDeleteTrainingClick}
        onEditTrainingClick={onEditTrainingClick}
        onIsRequiredUserSelectionChange={handleIsRequiredUserSelectionChange}
        onNewTrainingClick={onNewTrainingClick}
        onRemoveBooking={onRemoveBooking}
        onTrainingOptionsClose={handleTrainingOptionsClose}
        personnelId={personnelId}
        prioritisedAutoEnrolmentBlockers={prioritisedAutoEnrolmentBlockers}
        registration={registration}
        showHistoricalTrainingOnly={isReadOnlyOrArchived}
        toggleHistoricalTraining={toggleHistoricalTraining}
        training={optionsTraining}
        userCourse={userCourse}
      />
    );
  };

  const renderHistoricalTrainingActions = ({ training }) => {
    if (isReadOnlyOrArchived) return null;
    return (
      <MinimalTrainingOptions
        onDeleteTrainingClick={() =>
          onDeleteTrainingClick({ training, course })
        }
        onEditTrainingClick={() => onEditTrainingClick({ training, course })}
      />
    );
  };

  useEffect(() => {
    if (training.length <= 1 && isHistoricalTrainingVisible)
      toggleHistoricalTraining(false);
  }, [JSON.stringify(training)]);

  return (
    <Fragment>
      <TrainingRow
        attachments={
          latestTraining && attachmentsByAttachableId[latestTraining.id]
        }
        course={course}
        hasTrainingEditableAccess={hasTrainingEditableAccess}
        isArchived={isArchived}
        isRequired={isRequired}
        renderActions={renderRegularTrainingActions}
        renderBookings={renderBookings}
        renderStatus={renderStatus}
        setModalBoxData={setModalBoxData}
        training={latestTraining}
      />
      {isHistoricalTrainingVisible &&
        training.length > 1 &&
        training.slice(1).map((historicalTraining) => {
          return (
            <TrainingRow
              attachments={attachmentsByAttachableId[historicalTraining.id]}
              course={course}
              isArchived={isArchived}
              isHistorical={true}
              isRequired={isRequired}
              key={`historicalTraining--${historicalTraining.id}`}
              renderActions={renderHistoricalTrainingActions}
              setModalBoxData={setModalBoxData}
              training={historicalTraining}
            />
          );
        })}
    </Fragment>
  );
}

CourseTraining.propTypes = {
  isRequired: PropTypes.bool,
  isWithinRoleTable: PropTypes.bool,
  course: resourceShape('course').isRequired,
  autoEnrolmentConfiguration: resourceShape('autoEnrolmentConfiguration'),
  autoEnrolmentExclusion: resourceShape('autoEnrolmentExclusion'),
  eLearningCourse: resourceShape('eLearningCourse'),
  eLearningAllowance: PropTypes.object,
  training: PropTypes.array,
  booker: PropTypes.object,
  booking: PropTypes.object,
  registration: PropTypes.object,
  attachments: PropTypes.array,
  creditInfoUnavailable: PropTypes.bool,
  onBookingReminderClick: PropTypes.func.isRequired,
  onShowBooking: PropTypes.func.isRequired,
  onRemoveBooking: PropTypes.func.isRequired,
  onCreateAutoEnrolmentExclusion: PropTypes.func,
  onDeleteAutoEnrolmentExclusion: PropTypes.func,
  currentPersonnel: PropTypes.shape({
    archivedAt: PropTypes.node,
    company: PropTypes.object,
    email: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  personnelId: PropTypes.string,
  userCourse: resourceShape('userCourse'),
  hasTrainingEditableAccess: PropTypes.bool,
  isRequiredByAnything: PropTypes.bool,
  isRequiredByAnotherRole: PropTypes.bool,
  requiredSource: PropTypes.string,
  isAdditional: PropTypes.bool,
  onAddBooking: PropTypes.func,
  onAddELearningBooking: PropTypes.func,
  onNewTrainingClick: PropTypes.func,
  onEditTrainingClick: PropTypes.func,
  onDeleteTrainingClick: PropTypes.func,
  onDeleteCourseClick: PropTypes.func,
  onUpdateUserCourse: PropTypes.func,
  onCreateUserCourse: PropTypes.func,
  onRemoveBooking: PropTypes.func,
  onBookingReminderClick: PropTypes.func,
  setModalBoxData: PropTypes.func,
  onCreateAutoEnrolmentExclusion: PropTypes.func,
  onDeleteAutoEnrolmentExclusion: PropTypes.func,
};

CourseTraining.defaultProps = {
  hasTrainingEditableAccess: true,
  isRequired: true,
  isWithinRoleTable: true,
  attachments: [],
  training: [],
  booking: null,
  registration: null,
};
