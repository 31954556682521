$(function () {
  if ($('[data-inline-editable]').length !== 0) {
    const inlineEditing = {
      editableElements: $('*').filter(function () {
        return $(this).data('inline-editable') !== undefined;
      }),

      setup: function () {
        this.enableClickText();
        this.enableActions();
        this.checkEditing();
      },

      enableClickText: function () {
        const _this = this;

        [].slice
          .call(this.editableElements)
          .forEach(function (editableElement) {
            $($(editableElement).find('.input-text')).click(function () {
              _this.editableOff();
              _this.editableOn($(editableElement));
              _this.disableClickText();
            });
          });
      },

      disableClickText: function () {
        [].slice
          .call(this.editableElements)
          .forEach(function (editableElement) {
            $($(editableElement).find('.input-text')).unbind();
          });
      },

      editableOff: function () {
        const _this = this;

        [].slice
          .call(this.editableElements)
          .forEach(function (editableElement) {
            _this.toggleEdit(editableElement, false);
          });
      },

      editableOn: function (editableElement) {
        this.toggleEdit(editableElement, true);
        this.focusField(editableElement);
      },

      toggleEdit: function (editableElement, on) {
        $(editableElement)
          .find('.input-text')
          .css('display', on ? 'none' : 'flex');
        $(editableElement)
          .find('.input-field, .actions')
          .css('display', on ? 'flex' : 'none');
      },

      focusField: function (editableElement) {
        $(editableElement).find('.input-field input').focus();
      },

      performAction: function (editableElement, perform) {
        perform ?
          this.updateFields($(editableElement))
        : this.updateFieldsNot($(editableElement));

        this.editableOff();
        this.enableClickText();
      },

      enableActions: function () {
        const _this = this;

        [].slice
          .call(this.editableElements)
          .forEach(function (editableElement) {
            $(editableElement)
              .find('.save')
              .click(function () {
                _this.performAction(editableElement, true);
              });

            $(editableElement)
              .find('form')
              .submit(function (_event) {
                _this.performAction(editableElement, true);
              });

            $(editableElement)
              .find('.input-field input')
              .keyup(function (event) {
                const keycode = event.keyCode ? event.keyCode : event.which;

                if (keycode == '13') {
                  _this.performAction(editableElement, true);
                } else if (keycode == '27') {
                  _this.performAction(editableElement, false);
                }

                $('.ra-heading').trigger('inputkeyup');

                event.stopPropagation();
              });

            $(editableElement)
              .find('.cancel')
              .click(function () {
                _this.performAction(editableElement, false);
              });
          });
      },

      updateFields: function (editableElement) {
        const fieldValue = editableElement.find('input').val();
        const targetField = editableElement.find('input')[0].id;
        const placeholder = editableElement.data('placeholder');
        const textValue = editableElement.find('p,span,h1,h2,h3,h4').eq(0);

        $('form')
          .find('#' + targetField)
          .val(fieldValue)
          .trigger('change');

        textValue.attr('data-content', fieldValue);

        if (fieldValue !== '') {
          textValue.text(fieldValue);
          fieldValue.trim().length > 52 ?
            addPopover(textValue)
          : textValue.popover('destroy');
        } else {
          textValue.text(placeholder);
          textValue.popover('destroy');
        }
      },

      updateFieldsNot: function (editableElement) {
        const textValue = editableElement
          .find('p,span,h1,h2,h3,h4')
          .eq(0)
          .text()
          .trim();
        const placeholder = editableElement.data('placeholder');

        if (textValue === placeholder) {
          editableElement.find('input').val('');
        } else {
          editableElement.find('input').val(textValue);
        }
      },

      checkEditing: function () {
        const _this = this;

        [].slice.call(this.editableElements).forEach(function (ee) {
          if ($(ee).data('editing') !== undefined) {
            _this.editableOn($(ee));
          }
        });
      },
    };

    inlineEditing.setup();
  }
});
