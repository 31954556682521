import React from 'react';

import Tooltip from 'components/application/Tooltip';
import SmallStubPill from 'components/application/pills/SmallStubPill';

export default function ELearningCourseEnrolledPill() {
  return (
    <Tooltip placement='top' tooltip='eLearning enrolled' trigger='hover'>
      <SmallStubPill className='tw-bg-green-025 tw-align-middle tw-text-green-800'>
        eLearning enrolled
      </SmallStubPill>
    </Tooltip>
  );
}
