import React from 'react';
import { resourceShape } from 'components/helpers/serialisableResources';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import ConditionalWrapper from 'components/application/ConditionalWrapper';
import Tooltip from 'components/application/Tooltip';
import classNames from 'classnames';

import PersonnelCourseTrainingStatusIndicator from 'components/personnel/PersonnelCourseTrainingStatusIndicator';

export default function SelectedMembersList({
  domainPersonnelCollection,
  isFetching = false,
  onBookingGroupMemberDelete,
  selectedMemberIDs,
  shouldBlockFullRemoval = false,
}) {
  const queryClient = useQueryClient();

  const collectedAssignableMembers = queryClient
    .getQueriesData(['assignableBookees'])
    .map((queryResult) => {
      const [_queryKey, queryData] = queryResult;
      if (!queryData) return null;
      return queryData.pages.map((pageData) => pageData.data).flat();
    })
    .flat()
    .filter(Boolean);

  const currentBookingGroupMembers = selectedMemberIDs
    .map((personnelId) => {
      const persistedPersonnel = domainPersonnelCollection?.find(
        (personnel) => personnel.id === personnelId,
      );
      const selectedMember = (
        !!persistedPersonnel ?
          domainPersonnelCollection
        : collectedAssignableMembers).find(
        (assignableMember) => assignableMember.id === personnelId,
      );
      if (!selectedMember) return null;

      return {
        id: personnelId,
        externalId: selectedMember.attributes.externalId,
        trainingStatus: selectedMember.attributes.trainingStatus,
        name: `${selectedMember.attributes.firstName} ${selectedMember.attributes.lastName}`,
      };
    })
    .filter(Boolean);

  const isBlockingLastMemberRemoval =
    shouldBlockFullRemoval && currentBookingGroupMembers.length === 1;

  return (
    <div className='form-container'>
      {isFetching && (
        <div
          className='tw-group/pill chip-item-pill w-100 tw-relative tw-mb-2 tw-flex tw-h-10 tw-items-center tw-justify-between tw-rounded-md tw-bg-grey-050 tw-px-3 tw-py-2'
          key={'loading-pill'}
        >
          <div className='tw-flex tw-items-center tw-py-2'>
            <PersonnelCourseTrainingStatusIndicator status={5} />
            <div className='tw-ml-2 tw-mr-2 tw-text-s tw-font-medium tw-text-grey-900'>
              Loading...
            </div>
          </div>
        </div>
      )}
      {currentBookingGroupMembers?.map((member) => (
        <div
          className='tw-group/pill chip-item-pill w-100 tw-relative tw-mb-2 tw-flex tw-h-10 tw-items-center tw-justify-between tw-rounded-md tw-bg-grey-050 tw-px-3 tw-py-2'
          key={member.id}
        >
          <div className='tw-flex tw-items-center tw-py-2'>
            <PersonnelCourseTrainingStatusIndicator
              status={member.trainingStatus}
            />
            <div className='tw-ml-2 tw-mr-2 tw-text-s tw-font-medium tw-text-grey-900'>
              {member.name}
            </div>
            {member.externalId && (
              <div className='tw-text-s tw-font-normal tw-text-grey-500'>
                {member.externalId}
              </div>
            )}
          </div>
          {!(
            shouldBlockFullRemoval && domainPersonnelCollection?.length === 1
          ) && (
            <ConditionalWrapper
              condition={isBlockingLastMemberRemoval}
              wrapper={(children) => (
                <Tooltip
                  placement='top'
                  tooltip={'Requires at least 1 personnel'}
                  trigger='hover'
                >
                  {children}
                </Tooltip>
              )}
            >
              <div
                className={classNames(
                  "circle--remove tw-ml-2 before:tw-content-[''] after:tw-content-['']",
                  isBlockingLastMemberRemoval ?
                    'before:tw-bg-grey-300 after:tw-bg-grey-300 hover:before:tw-bg-grey-300 hover:after:tw-bg-grey-300'
                  : 'before:tw-bg-grey-900 after:tw-bg-grey-900 hover:before:tw-bg-white hover:after:tw-bg-white',
                )}
                data-element-name='member-removal-icon'
                onClick={() =>
                  !isBlockingLastMemberRemoval &&
                  onBookingGroupMemberDelete(member.id)
                }
              />
            </ConditionalWrapper>
          )}
        </div>
      ))}
    </div>
  );
}

SelectedMembersList.propTypes = {
  selectedMemberIDs: PropTypes.array.isRequired,
  domainPersonnelCollection: PropTypes.arrayOf(resourceShape('personnel')),
  onBookingGroupMemberDelete: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  shouldBlockFullRemoval: PropTypes.bool,
};
