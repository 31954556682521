import React, { useReducer } from 'react';
import { enableAllPlugins } from 'immer';
enableAllPlugins();
import { produce } from 'immer';
import moment from 'moment';

import validationErrorsReducer, {
  initialValidationErrorsState,
  mapValidationErrorsDispatch,
} from 'components/validationErrors/reducers/validationErrorsReducer';
import { validFromDateSchema } from 'components/trainingRegisterReports/schemas/dateSchema';
import {
  validateForm,
  buildFormValues,
} from 'components/trainingRegisterReports/helpers';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import useReportForm, {
  initialDateState,
  initialEstimatesState,
  initialFiltersState,
} from 'components/trainingRegisterReports/hooks/useReportForm';
import useWindowStorage from 'components/hooks/useWindowStorage';

import Report from 'components/trainingRegisterReports/reports/Report';
import DatePeriod from 'components/trainingRegisterReports/components/DatePeriod';

const initialFormValues = {
  validFromDate: initialDateState,
  estimates: initialEstimatesState,
  filters: initialFiltersState,
};

const additionalValidators = [
  { schema: validFromDateSchema, key: 'validFromDate' },
];

const periodOptions = [
  'withinTheLast',
  'thisWeek',
  'lastWeek',
  'thisMonth',
  'lastMonth',
  'thisQuarter',
  'lastQuarter',
  'thisYear',
  'lastYear',
  'today',
  'yesterday',
  'before',
  'after',
  'onExactDate',
  'customDateRange',
];

export default function CompletedTrainingReport() {
  const currentActor = useCurrentActor();

  const [getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem] =
    useWindowStorage(
      `completedTrainingReport|${currentActor.user.id}|${currentActor.division.id}`,
    );
  const {
    formValues,
    handleDateChange,
    handleDateRangeChange,
    handleEstimatesChange,
    handleFilterAddition,
    handleFilterChange,
    handleFilterDeselection,
    handleFilterRemoval,
    handleFilterSelection,
  } = useReportForm('completedTraining', initialFormValues, (initialState) => {
    const computedFormValues = produce(initialState, (draftState) => {
      draftState.validFromDate.period = 'withinTheLast';
      draftState.validFromDate.withinTheLastFrequency = '12';
      draftState.validFromDate.withinTheLastInterval = 'weeks';
      draftState.validFromDate.before = moment().toDate();
      draftState.validFromDate.after = moment().toDate();
      draftState.validFromDate.onExactDate = moment().toDate();
      draftState.validFromDate.customDateRangeFrom = moment()
        .subtract(3, 'months')
        .toDate();
      draftState.validFromDate.customDateRangeTo = moment().toDate();
    });

    return buildFormValues({
      type: 'completedTraining',
      formValues: computedFormValues,
      additionalValidators,
      getLocalStorageItem,
      removeLocalStorageItem,
    });
  });

  const [validationErrors, validationErrorsDispatch] = useReducer(
    validationErrorsReducer,
    initialValidationErrorsState,
  );
  const validationErrorsHandlers = mapValidationErrorsDispatch(
    validationErrorsDispatch,
  );

  const handleValidate = () => {
    return validateForm({ formValues, additionalValidators, safe: true });
  };

  return (
    <Report
      formValues={formValues}
      onEstimatesChange={handleEstimatesChange}
      onFilterAddition={handleFilterAddition}
      onFilterChange={handleFilterChange}
      onFilterDeselection={handleFilterDeselection}
      onFilterRemoval={handleFilterRemoval}
      onFilterSelection={handleFilterSelection}
      onValidate={handleValidate}
      onValidationErrorsAssignment={
        validationErrorsHandlers.handleValidationErrorsAssignment
      }
      onValidationErrorsReset={
        validationErrorsHandlers.handleValidationErrorsReset
      }
      onValidationErrorsUnhighlight={
        validationErrorsHandlers.handleValidationErrorsUnhighlight
      }
      setLocalStorageItem={setLocalStorageItem}
      title='Completed training report'
      tooltip='The completed training report includes the current and historical recorded training for each course'
      type='completedTraining'
      validationErrors={validationErrors}
    >
      <label className='tw-mb-1 tw-font-medium'>Valid from date</label>
      <div className='tw-mb-6'>
        <DatePeriod
          afterProps={{ latestDate: moment().toDate() }}
          beforeProps={{ latestDate: moment().toDate() }}
          customDateRangeFromProps={{ latestDate: moment().toDate() }}
          customDateRangeToProps={{ latestDate: moment().toDate() }}
          datePeriod={formValues.validFromDate}
          fieldError={validationErrors.validFromDate}
          name='valid_from_date'
          onChange={handleDateChange}
          onDateRangeChange={handleDateRangeChange}
          onExactDateProps={{ latestDate: moment().toDate() }}
          onFocus={() => {
            if (validationErrors.validFromDate)
              validationErrorsHandlers.handleValidationErrorsUnhighlight(
                'validFromDate',
              );
          }}
          periodOptions={periodOptions}
        />
      </div>
    </Report>
  );
}
