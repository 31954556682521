import HandshqApp from '../handshq-app';

HandshqApp.jsErrors = {
  setup: function () {
    const _this = this;

    this.container = $('.js__errors');
    this.close = $('.js__errors-close');
    this.list = $('.js__errors ul');

    _this.close.on('click', function (event) {
      _this.hideErrors();

      event.stopPropagation();
    });
  },

  displayErrors: function (errors) {
    const _this = this;

    _this.list.empty();
    errors.forEach(function (error) {
      _this.list.append('<li>' + error + '</li>');
    });
    _this.container.show();
  },

  hideErrors: function () {
    const _this = this;

    _this.container.hide();
    _this.list.empty();
  },
};

$(function () {
  if ($('.js__errors').length) {
    HandshqApp.jsErrors.setup();
  }
});
