import React from 'react';
import PropTypes from 'prop-types';
import ModalBox from 'components/application/ModalBox';

import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function BookingELearningNoCreditsModal(props) {
  const { isOpen, setIsClosed } = props;

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton
        className='m-r-12'
        color='blue'
        onClick={setIsClosed}
        size='md'
      >
        Cancel
      </TextButton>
      <FilledButton color='mint' id='intercom-launcher' onClick={setIsClosed}>
        Speak to support team
      </FilledButton>
    </div>
  );

  return (
    <ModalBox
      customFooter={customFooter}
      isOpen={isOpen}
      mode='flexible'
      onClose={setIsClosed}
    >
      <div className='p-32'>
        <h2 className='p-0 m-t-0 m-b-20 tw-text-l tw-font-semibold tw-text-grey-900'>
          Top up eLearning credits to make a booking
        </h2>
        <p className='m-0'>
          You don’t have any eLearning credits now. Speak to our support team to
          increase your allowance.
        </p>
      </div>
    </ModalBox>
  );
}

BookingELearningNoCreditsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsClosed: PropTypes.func.isRequired,
};
