import { clearButton } from '../main';

$(function () {
  $('.project-search').each(function () {
    new ProjectSearch($(this));
  });
});

function ProjectSearch(searchForm) {
  this.form = searchForm;
  this.filters = this.form.find('.project-search-filter');
  this.input = this.form.find('.project-search-input');
  this.timer = null;
  this.init();
}

ProjectSearch.prototype.init = function () {
  this.submitOnInput();
  this.submitOnFilter();
};

ProjectSearch.prototype.submitOnInput = function () {
  const _this = this;

  this.input.on('input', function () {
    clearButton(_this.input);
    clearTimeout(_this.timer);
    _this.timer = setTimeout(function () {
      _this.form.submit();
    }, 200);
  });
};

ProjectSearch.prototype.submitOnFilter = function () {
  const _this = this;
  this.filters.on('change', function () {
    _this.form.submit();
  });
};
