import React from 'react';
import PropTypes from 'prop-types';

export default function CustomELearningCourseCard({
  courseCost,
  courseDuration,
  courseName,
  courseProvider,
}) {
  return (
    <div className='content-box tw-border-grey-100 tw-bg-grey-025 tw-p-3'>
      <div className='tw-text-m tw-font-semibold tw-text-grey-700'>
        {courseName}
      </div>
      {courseProvider && (
        <div className='tw-py-1 tw-text-s tw-font-normal tw-text-grey-700'>
          {courseProvider}
        </div>
      )}
      {(courseDuration || courseCost) && (
        <div className='tw-text-s tw-font-normal tw-text-grey-700'>
          {courseDuration}
          {courseDuration && courseCost && <span> &#183; </span>}
          {courseCost}
        </div>
      )}
    </div>
  );
}

CustomELearningCourseCard.propTypes = {
  courseName: PropTypes.string.isRequired,
  courseProvider: PropTypes.string.isRequired,
  courseDuration: PropTypes.string,
  courseCost: PropTypes.string,
};
