$(function () {
  const newProjectBtn = '.new-project';
  const duplicateProjectBtns = '.duplicate_project';

  // New project button event
  if (newProjectBtn.length > 0) {
    $(document).on('click', newProjectBtn, checkDocumentAllowance);
  }

  // Duplicate project buttons event
  if (duplicateProjectBtns.length > 0) {
    $(document).on(
      'click',
      duplicateProjectBtns,
      { duplicateProject: true },
      checkDocumentAllowance,
    );
  }
});

function checkDocumentAllowance(e) {
  const _this = this;
  const data = e.data || {};
  e.preventDefault();
  check();

  function check() {
    $.ajax({
      type: 'GET',
      url: '/document_allowance/',
      dataType: 'json',
      success: function (response) {
        if (response.attributes.isDocumentLimitReached) {
          $('#update-payment-modal').modal();
        } else {
          if (typeof data.duplicateProject !== 'undefined') {
            $($(_this).attr('id')).modal();
          } else {
            window.location.href = $(_this).attr('href');
          }
        }
      },
    });
  }
}
