import React, { useCallback } from 'react';
import CrossIcon from '-!svg-react-loader?name=CrossIcon!icons/ic-close.svg';
import Tooltip from 'components/application/Tooltip';
import classNames from 'classnames';

export default function CollectionInput(props) {
  const {
    collectionErrors,
    deleteAllowed,
    index,
    onChange,
    onDelete,
    removeErrorStyling,
    value,
  } = props;

  const fieldRef = useCallback((node) => {
    node !== null && node.focus();
  }, []);

  const deleteIcon = (
    <CrossIcon className='sortable-collection__icon-delete-inner [&_polygon]:tw-fill-white' />
  );

  const onFocus = () => {
    if (collectionErrors.errorList[index]) {
      return removeErrorStyling(index);
    }
  };

  const isInvalid = !!collectionErrors.errorList[index]?.fieldHighlighted;

  const inputClassName =
    isInvalid ?
      'tw-border-red-600 hover:tw-border-red-600 focus-within:tw-border-red-600 hover:focus-within:tw-border-red-600'
    : 'tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300 placeholder:tw-text-grey-300';

  return (
    <React.Fragment>
      <div className='sortable-collection__input-container'>
        <input
          className={`sortable-collection__input field__input tw-font-inter tw-text-m tw-font-normal tw-tracking-auto ${inputClassName}`}
          id={`sortable-collection__input-${index}`}
          name={'name'}
          onChange={(event) => {
            onChange(event, { index: index });
          }}
          onFocus={onFocus}
          ref={fieldRef}
          type='text'
          value={value}
        />
      </div>
      <div
        className={classNames(
          'sortable-collection__icon-delete',
          deleteAllowed ? 'tw-bg-red-600' : 'tw-bg-grey-300',
        )}
        onClick={
          deleteAllowed ?
            () => {
              onDelete(index);
            }
          : () => {}
        }
      >
        {deleteAllowed ?
          deleteIcon
        : <Tooltip
            className='pos-t--4'
            placement='top'
            tooltip='There must be at least one entry'
            trigger='hover'
          >
            {deleteIcon}
          </Tooltip>
        }
      </div>
    </React.Fragment>
  );
}
