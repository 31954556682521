import React from 'react';
import AppWrapper from 'components/apps/AppWrapper';
import Manager from './components/inputFieldManagement/Manager';

export default function Wrapper({ contextProps, wrappedProps }) {
  return (
    <AppWrapper {...contextProps}>
      <Manager {...wrappedProps} />
    </AppWrapper>
  );
}
