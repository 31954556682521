import React, { Fragment } from 'react';
import Table from 'components/application/Table';
import TrainingEventRow from 'components/trainingEvents/TrainingEventRow';
import Paginator from 'components/application/Paginator';

export default function TrainingEventsTab(props) {
  const handlePageChange = (event) => {
    const page = event.currentTarget.getAttribute('data-page');
    props.fetchPersonnelEvents(page);
  };

  const triggererInitialsAndPhoto = (event) => {
    if (event.attributes.activity.includes('via_api')) {
      return { initials: 'API', userPhotoPath: null };
    }

    const triggerUser =
      event?.relationships?.triggerer?.data?.id ?
        props.users[event.relationships.triggerer.data.id]
      : null;

    const initials =
      triggerUser ?
        `${triggerUser.attributes.firstName[0]}${triggerUser.attributes.lastName[0]}`
      : null;

    const triggerUserPhoto =
      triggerUser ?
        Object.values(props.profilePhotos).find(
          (profilePhoto) =>
            profilePhoto.relationships?.personnel?.data?.id ===
              triggerUser.relationships?.personnel?.data?.id &&
            profilePhoto.type === 'profilePhoto',
        )
      : null;

    const userPhotoPath = triggerUserPhoto?.links?.croppedUrl;

    return { initials, userPhotoPath };
  };

  return (
    <>
      <Table
        headers={
          <tr>
            <th className='w-30 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
              Date
            </th>
            <th className='w-70 tw-pl-12 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
              Change
            </th>
          </tr>
        }
        rows={props.personnelEvents.map((event, index) => {
          const { initials, userPhotoPath } = triggererInitialsAndPhoto(event);
          return (
            <TrainingEventRow
              key={index}
              personnelEvent={event.attributes}
              userInitials={initials}
              userPhotoPath={userPhotoPath}
            />
          );
        })}
      />
      {props.metaData.totalPages > 1 && (
        <div className='m-t-80 text-center'>
          <Paginator
            currentPage={props.metaData.currentPage}
            onClick={handlePageChange}
            totalPages={props.metaData.totalPages}
          />
        </div>
      )}
    </>
  );
}
