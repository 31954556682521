import HandshqApp from '../handshq-app';

HandshqApp.configureRecurly = function () {
  recurly.configure({
    publicKey: HandshqApp.rails.recurlyPublicKey,
    required: ['cvv'],
    fields: {
      all: {
        style: {
          fontFamily: 'Inter',
          fontSize: '14px',
          fontColor: '#2f3641',
          fontWeight: '400',
          lineHeight: '20px',
          placeholder: {
            color: '#C0C0C0',
          },
        },
      },
      number: {
        selector: '#payment_details_card_number',
        style: {
          placeholder: {
            content: '0000 0000 0000 0000',
          },
        },
      },
      month: {
        selector: '#payment_details_card_month',
        style: {
          placeholder: {
            content: '11',
          },
        },
      },
      year: {
        selector: '#payment_details_card_year',
        style: {
          placeholder: {
            content: '22',
          },
        },
      },
      cvv: {
        selector: '#payment_details_card_cvv',
        style: {
          placeholder: {
            content: '123',
          },
        },
      },
    },
  });
};
