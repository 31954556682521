import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import arrayMove from 'array-move';

import { initialMember } from 'components/helpers/resources/attachments';
import { genericErrorMessage } from 'components/helpers/errors';

import Attachments from 'components/attachments/Attachments';

export default function AttachmentList({
  addError,
  areAttachmentsVisible,
  attachments,
  dispatch,
  methodStepId,
  previewerLocation,
  setAttachmentsInvisible,
  setAttachmentsVisible,
}) {
  const createAttachment = ({ attachment, fileType, uploadKey }) => {
    axios
      .post('/attachments', {
        additional_processing: fileType === 'pdf',
        attachment: {
          attachable_type: 'MethodStep',
          attachable_id: methodStepId,
          attached_key: uploadKey,
        },
        type: fileType,
      })
      .then((response) => {
        dispatch({
          type: 'REPLACE_NEW_ATTACHMENT',
          methodStepId: methodStepId,
          attachmentId: response.data.data.id,
          attachmentKey: attachment.key,
          payload: response.data,
        });
      })
      .catch(handleError);
  };

  const bulkUpdateAttachments = ({ params }) => {
    axios
      .post(`/method_steps/${methodStepId}/attachment_bulk_updates`, {
        attachments: params,
      })
      .catch(handleError);
  };

  const updateAttachment = ({ attachment, params }) => {
    axios
      .patch(`/attachments/${attachment.hashid}`, {
        type: attachment.recordType,
        attachment: params,
      })
      .then((response) => {
        dispatch({ type: 'UPDATE_RESOURCE', payload: response.data });
      })
      .catch(handleError);
  };

  const deleteAttachment = ({ attachment }) => {
    axios
      .delete(`/attachments/${attachment.hashid}`, {
        params: { type: attachment.recordType },
      })
      .then((_response) =>
        dispatch({
          type: 'REMOVE_ATTACHMENT_FROM_LIST',
          methodStepId: methodStepId,
          attachmentId: attachment.id,
        }),
      )
      .catch(handleError);
  };

  const handleError = ({ fullMessage }) =>
    addError(uuidv4(), { fullMessage: fullMessage || genericErrorMessage });

  const handleUpload = (file) => {
    const key = uuidv4();
    dispatch({
      type: 'ADD_NEW_ATTACHMENT',
      methodStepId: methodStepId,
      attachmentKey: key,
      payload: { ...initialMember, key: key, file: file },
    });
  };

  const handleChange = (event, { attachment }) => {
    dispatch({
      type: 'UPDATE_ATTACHMENT',
      attachmentId: attachment.id,
      name: event.target.name,
      value: event.target.value,
    });
  };

  const handleSortEnd = ({ newIndex, oldIndex }) => {
    let newCollection = [...attachments];
    newCollection = arrayMove(newCollection, oldIndex, newIndex);

    const attachmentAttributes = newCollection.reduce((obj, item, index) => {
      return { ...obj, [item.id]: { position: index } };
    }, {});

    dispatch({
      type: 'UPDATE_ATTACHMENT_ORDERING',
      methodStepId: methodStepId,
      newOrder: newCollection.map((member) => member.id),
    });
    bulkUpdateAttachments({
      params: { attachment_attributes: attachmentAttributes },
    });
  };

  const handlePollingSuccess = ({ response }) => {
    dispatch({ type: 'UPDATE_RESOURCE', payload: response });
  };

  const handlePollingFailure = ({ response }) => {
    response.status !== 404 && handleError();
  };

  useEffect(() => {
    attachments.length === 0 ?
      setAttachmentsInvisible()
    : setAttachmentsVisible();
  }, [JSON.stringify(attachments)]);

  return areAttachmentsVisible ?
      <div className='m-t-10 m-b-10'>
        <Attachments
          attachments={attachments}
          createAttachment={createAttachment}
          deleteAttachment={deleteAttachment}
          onChange={handleChange}
          onError={handleError}
          onPollingFailure={handlePollingFailure}
          onPollingSuccess={handlePollingSuccess}
          onSortEnd={handleSortEnd}
          onUpload={handleUpload}
          previewerLocation={previewerLocation}
          setInvisible={setAttachmentsInvisible}
          setVisible={setAttachmentsVisible}
          updateAttachment={updateAttachment}
        />
      </div>
    : null;
}
