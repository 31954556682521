import { v4 as uuidv4 } from 'uuid';

const reducer = (state, action) => {
  const copiedCollection = [...state.collection];
  const index =
    action.key &&
    state.collection.findIndex((member) => member.key === action.key);

  switch (action.type) {
    case 'LOAD':
      const loadedCollection = action.payload.map((item) => {
        return { ...item, key: uuidv4() };
      });

      return { loaded: true, collection: loadedCollection };
    case 'RELOAD':
      const reloadedCollection = action.payload.map((item) => {
        const existingMember = state.collection.find(
          (member) => member[action.attr] === item[action.attr],
        );

        return { ...item, key: existingMember ? existingMember.key : uuidv4() };
      });

      return { loaded: true, collection: reloadedCollection };
    case 'CHANGE':
      copiedCollection[index] = {
        ...copiedCollection[index],
        [action.event.target.name]: action.event.target.value,
      };
      return { ...state, collection: copiedCollection };
    case 'ADD':
      copiedCollection.push({ ...action.payload, key: uuidv4() });
      return { ...state, collection: copiedCollection };
    case 'REMOVE':
      copiedCollection.splice(index, 1);
      return { ...state, collection: copiedCollection };
    case 'REPLACE':
      if (!copiedCollection[index]) {
        return state;
      }
      copiedCollection[index] = {
        ...action.payload,
        key: copiedCollection[index].key,
      };
      return { ...state, collection: copiedCollection };
    default:
      return state;
  }
};

const initialState = {
  loaded: false,
  collection: [],
};

export { reducer, initialState };
