import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import { resourceShape } from 'components/helpers/serialisableResources';
import { useStepper } from 'components/hooks/useStepper';
import useSwipe from 'components/mobileTrainingProfile/hooks/useSwipe.jsx';

import ChevronLeftIcon from '-!svg-react-loader?name=ChevronLeftIcon!icons/chevron-left.svg';
import ChevronRightIcon from '-!svg-react-loader?name=ChevronRightIcon!icons/chevron-right.svg';
import FilesIcon from '-!svg-react-loader?name=FilesIcon!icons/ic-files.svg';

import Download from 'components/attachments/components/Download';

export default function Evidence({ attachments, coverImagesIndexedById }) {
  const totalSteps = attachments.length;

  const [step, stepDown, stepUp, resetStep] = useStepper(totalSteps);
  const [timestamp, setTimestamp] = useState(null);
  const [isFullScreenEvidence, setIsFullScreenEvidence] = useState(false);

  const isStepDownDisabled = step === 1;
  const isStepUpDisabled = step === totalSteps;

  const toggleFullScreenEvidence = () => {
    setIsFullScreenEvidence((prevState) => !prevState);
  };

  const { handleTouchEnd, handleTouchStart } = useSwipe({
    onSwipeLeft: () => !isStepUpDisabled && stepUp(),
    onSwipeRight: () => !isStepDownDisabled && stepDown(),
  });

  useEffect(() => {
    setTimestamp(moment().unix());
    resetStep();
  }, []);

  if (attachments.length === 0) {
    return (
      <div className='tw-rounded-lg tw-border-1 tw-border-solid tw-border-grey-100 tw-bg-white'>
        <div className='tw-flex tw-items-center tw-justify-between tw-border-0 tw-border-b-1 tw-border-solid tw-border-grey-100 tw-p-4'>
          <h3 className='tw-m-0 tw-text-l tw-font-semibold tw-text-grey-900'>
            Evidence
          </h3>
        </div>
        <div className='tw-flex tw-h-90 tw-w-full tw-flex-col tw-items-center tw-justify-center'>
          <FilesIcon height={32} width={38} />
          <div className='tw-mt-6 tw-text-center tw-text-grey-700'>
            No evidence added for course
          </div>
        </div>
      </div>
    );
  }

  const currentAttachment = attachments[step - 1];
  const currentAttachmentRecordType = currentAttachment.attributes.recordType;
  const currentAttachmentAttachedUrl = currentAttachment.attributes.attachedUrl;
  const currentAttachmentHashid = currentAttachment.attributes.hashid;
  const currentAttachmentFilename = currentAttachment.attributes.filename;
  const currentCoverImage =
    coverImagesIndexedById[currentAttachment.relationships.coverImage.data?.id];
  const currentCoverImageAttachedUrl =
    currentCoverImage ? currentCoverImage.attributes.attachedUrl : null;

  return isFullScreenEvidence ?
      <>
        <div className='tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[999] tw-bg-grey-900'></div>
        <div
          className='tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[9999] tw-flex tw-animate-fade-in-quick tw-items-center tw-justify-center'
          data-element-name='full-screen-evidence-wrapper'
        >
          <div className='tw-flex tw-h-full tw-w-full tw-flex-col'>
            {currentAttachmentRecordType === 'pdf' ?
              <Pdf
                currentAttachmentAttachedUrl={currentAttachmentAttachedUrl}
                timestamp={timestamp}
              />
            : <Image
                currentAttachmentAttachedUrl={currentAttachmentAttachedUrl}
                timestamp={timestamp}
              />
            }
            <div className='tw-absolute tw-left-0 tw-right-0 tw-top-0 tw-flex tw-h-20 tw-justify-end tw-px-3 tw-py-5'>
              <div
                className='tw-relative tw-h-10 tw-w-10'
                data-element-name='full-screen-evidence-close'
                onClick={toggleFullScreenEvidence}
              >
                <div className="tw-absolute tw-left-[13px] tw-top-[14px] tw-h-[14px] tw-w-[14px] tw-cursor-pointer before:tw-absolute before:tw-left-1.5 before:tw-h-[14px] before:tw-w-[2px] before:tw-rotate-45 before:tw-bg-grey-300 before:tw-content-[''] after:tw-absolute after:tw-left-1.5 after:tw-h-[14px] after:tw-w-[2px] after:-tw-rotate-45 after:tw-bg-grey-300 after:tw-content-['']"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    : <div className='tw-rounded-lg tw-border-1 tw-border-solid tw-border-grey-100 tw-bg-white'>
        <div className='tw-flex tw-items-center tw-justify-between tw-border-0 tw-border-b-1 tw-border-solid tw-border-grey-100 tw-p-4'>
          <h3 className='tw-m-0 tw-text-l tw-font-semibold tw-text-grey-900'>
            Evidence
          </h3>
          <Download
            buttonText='Download'
            disabled={!currentAttachmentAttachedUrl}
            filename={currentAttachmentFilename}
            hashid={currentAttachmentHashid}
          />
        </div>
        <div
          className='tw-flex tw-h-90'
          data-element-name='standard-evidence-wrapper'
          onTouchEnd={handleTouchEnd}
          onTouchStart={handleTouchStart}
        >
          <div
            className='tw-flex tw-w-12 tw-flex-none tw-cursor-pointer tw-items-center tw-justify-center data-[disabled=true]:tw-pointer-events-none [&_svg_polygon]:tw-fill-grey-700 [&_svg_polygon]:data-[disabled=true]:tw-fill-grey-300'
            data-disabled={isStepDownDisabled}
            data-element-name='standard-evidence-step-down'
            onClick={isStepDownDisabled ? undefined : stepDown}
          >
            <ChevronLeftIcon height={32} width={32} />
          </div>
          <div className='tw-flex tw-h-full tw-w-full tw-flex-col'>
            <div
              className='tw-flex tw-flex-auto tw-items-center tw-justify-center tw-pt-10'
              onClick={toggleFullScreenEvidence}
            >
              <img
                className='tw-max-h-[280px] tw-max-w-full'
                src={`${currentAttachmentRecordType === 'pdf' ? currentCoverImageAttachedUrl : currentAttachmentAttachedUrl}?timestamp=${timestamp}`}
              />
            </div>
            <div
              className={classNames(
                'tw-flex tw-h-10 tw-items-center tw-justify-center tw-font-medium',
                totalSteps === 1 ? 'tw-text-grey-300' : 'tw-text-grey-700',
              )}
            >{`${step} of ${totalSteps}`}</div>
          </div>
          <div
            className='tw-flex tw-w-12 tw-flex-none tw-cursor-pointer tw-items-center tw-justify-center data-[disabled=true]:tw-pointer-events-none [&_svg_polygon]:tw-fill-grey-700 [&_svg_polygon]:data-[disabled=true]:tw-fill-grey-300'
            data-disabled={isStepUpDisabled}
            data-element-name='standard-evidence-step-up'
            onClick={isStepUpDisabled ? undefined : stepUp}
          >
            <ChevronRightIcon height={32} width={32} />
          </div>
        </div>
      </div>;
}

Evidence.propTypes = {
  attachments: PropTypes.arrayOf(resourceShape('attachment')).isRequired,
  coverImagesIndexedById: PropTypes.object.isRequired,
};

function Pdf({ currentAttachmentAttachedUrl, timestamp }) {
  return (
    <div className='tw-absolute tw-left-0 tw-right-0 tw-top-0 tw-h-full tw-px-6 tw-pt-20'>
      <iframe
        frameBorder='0'
        height='100%'
        id='hqPdfViewer'
        scrolling='no'
        src={`/pdfjs/web/mobile_viewer.html?file=${encodeURIComponent(currentAttachmentAttachedUrl)}&_=${timestamp}#zoom=auto`}
        width='100%'
      />
    </div>
  );
}

Pdf.propTypes = {
  currentAttachmentAttachedUrl: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
};

function Image({ currentAttachmentAttachedUrl, timestamp }) {
  return (
    <div className='tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-flex tw-h-full tw-items-center tw-justify-center tw-p-6'>
      <img
        className='tw-max-h-full tw-max-w-full'
        src={`${currentAttachmentAttachedUrl}?timestamp=${timestamp}`}
      />
    </div>
  );
}

Image.propTypes = {
  currentAttachmentAttachedUrl: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
};
