import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  fieldTypeOption,
  fieldTypeOptions,
} from 'components/helpers/resources/fieldAttributes';

import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';

import ErrorMessage from 'components/application/ErrorMessage';
import TextField from 'components/application/TextField';
import CollectionSelect from 'components/application/CollectionSelect';
import Tooltip from 'components/application/Tooltip';
import RadioField from 'components/application/RadioField';
import FieldOptions from 'components/settings/personnelInformation/FieldOptions';
import AddButton from 'components/buttons/AddButton';
import { focusField } from '../../helpers/refs';

export default function FieldForm(props) {
  const {
    fieldType,
    name,
    onFormOptionAppend,
    onFormOptionDelete,
    onFormOptionOrderChange,
    onFormOptionValueChange,
    onFormValueChange,
    options,
    removeErrorStyling,
    requestError,
    restricted,
    sidePanel,
  } = props;

  const nameInputRef = useRef();

  const onNameChange = ({ target }) => {
    onFormValueChange({ key: 'name', value: target.value });
  };

  const removeNameErrorStyling = () => {
    removeErrorStyling({ key: 'name' });
  };

  const onFieldTypeChange = ({ value }) => {
    onFormValueChange({ key: 'fieldType', value: value });
  };

  const onVisibilityChange = ({ target }) => {
    onFormValueChange({
      key: 'restricted',
      value: target.value === 'restricted',
    });
  };

  const removeFieldOptionsErrorStyling = () => {
    removeErrorStyling({ key: 'fieldOptions' });
  };

  useEffect(() => {
    if (sidePanel.isOpen) {
      focusField(nameInputRef);
    }
  }, [sidePanel.isOpen]);

  return (
    <div className='popup__body-form popup__body-form--no-side-padding'>
      <ErrorMessage
        isFallback={requestError.isFallback}
        modifiers='p-12'
        validationErrors={requestError.validationErrors}
        wrapperClassName='form-container'
      />
      <div className='form-container m-b-0'>
        {sidePanel.context === 'edit' && (
          <div className='m-0 m-r-20 m-b-20 m-l-20 tw-flex tw-rounded-lg tw-border-0 tw-bg-cyan-025 tw-p-3 tw-text-cyan-800'>
            <div className='m-r-12 fh-20'>
              <InfoIcon
                className='[&_path]:tw-fill-cyan-800'
                height={20}
                width={20}
              />
            </div>
            <p className='m-0'>
              Changes made here will affect existing records for all personnel
              in the company
            </p>
          </div>
        )}
        <div className='form-container-section'>
          <TextField
            fieldError={requestError.validationErrors.name}
            inputRef={nameInputRef}
            isRequired={true}
            label='Name'
            name='name'
            onChange={onNameChange}
            removeErrorStyling={removeNameErrorStyling}
            value={name}
          />
          <div className='tooltip-parent'>
            <CollectionSelect
              isDisabled={sidePanel.context === 'edit'}
              isRequired={true}
              label='Field type'
              name='fieldType'
              onChange={
                sidePanel.context === 'edit' ? () => void 0 : onFieldTypeChange
              }
              options={fieldTypeOptions}
              placeholder='Select type'
              value={fieldType ? fieldTypeOption(fieldType) : null}
            />
            {sidePanel.context === 'edit' && (
              <Tooltip
                className='pos-t-14 tooltip-dark--max-w-xxs'
                placement='left'
                tooltip="Field type can't be changed"
                trigger='hover'
              />
            )}
          </div>
          <label className='field__label tw-font-medium' htmlFor='visibility'>
            Visibility
          </label>
          <RadioField
            checked={restricted}
            label='Restricted'
            name='visibility'
            onChange={onVisibilityChange}
            value='restricted'
          />
          <div className='m-t--16 m-l-28 tw-text-s tw-font-normal tw-tracking-wide'>
            Only personnel editors can view and edit
          </div>
          <RadioField
            checked={!restricted}
            label='Visible'
            name='visibility'
            onChange={onVisibilityChange}
            value='visible'
          />
          <div className='m-t--16 m-l-28 tw-text-s tw-font-normal tw-tracking-wide'>
            All users can view
          </div>
        </div>
        {fieldType === 'select' && (
          <>
            <hr className='tw-mb-8 tw-mt-8 tw-h-px tw-border-0 tw-bg-grey-100' />
            <div className='m-r-20 m-b-20 m-l-20'>
              <label className='field__label m-b-0 tw-font-medium'>
                Dropdown list
              </label>
            </div>
            {sidePanel.context === 'edit' && (
              <div className='m-0 m-r-20 m-b-20 m-l-20 tw-flex tw-rounded-lg tw-border-0 tw-bg-cyan-025 tw-p-3 tw-text-cyan-800'>
                <div className='m-r-12 fh-20'>
                  <InfoIcon
                    className='[&_path]:tw-fill-cyan-800'
                    height={20}
                    width={20}
                  />
                </div>
                <p className='m-0'>
                  Deleting items from the dropdown list will delete any existing
                  data
                </p>
              </div>
            )}
            <FieldOptions
              fieldError={requestError.validationErrors.fieldOptions}
              onOptionDelete={onFormOptionDelete}
              onOptionOrderChange={onFormOptionOrderChange}
              onOptionValueChange={onFormOptionValueChange}
              options={options}
              removeErrorStyling={removeFieldOptionsErrorStyling}
            />
            <div className='m-t-20 m-r-20 m-l-20'>
              <AddButton onClick={onFormOptionAppend} text='Add another' />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

FieldForm.propTypes = {
  fieldSettingId: PropTypes.string,
  fieldAttributeId: PropTypes.string,
  name: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  restricted: PropTypes.bool.isRequired,
  options: PropTypes.object.isRequired,
  requestError: PropTypes.exact({
    isFallback: PropTypes.bool.isRequired,
    validationErrors: PropTypes.object.isRequired,
  }).isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  sidePanel: PropTypes.exact({
    context: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
  }).isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  onFormOptionValueChange: PropTypes.func.isRequired,
  onFormOptionAppend: PropTypes.func.isRequired,
  onFormOptionDelete: PropTypes.func.isRequired,
  onFormOptionOrderChange: PropTypes.func.isRequired,
};
