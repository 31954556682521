import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import RowBar from 'components/application/RowBar';
import SegmentedControls from 'components/application/SegmentedControls';
import SearchField from 'components/application/SearchField';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import PersonIcon from '-!svg-react-loader?name=PersonIcon!icons/person.svg';
import TrainingMatrixDownloader from 'components/training/trainingMatrixDownload/TrainingMatrixDownloader';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

export default function PersonnelBar(props) {
  const {
    actionButtonsVisible,
    isArchivedToggled,
    onArchiveToggle,
    onNewPersonnel,
    onSearchInputChange,
    onSearchReset,
    personnelSearch,
  } = props;
  const activeToggleChoice = !isArchivedToggled ? 'Current' : 'Archived';
  const currentActor = useCurrentActor();
  const { hasArchivedPersonnelAccess } = useTrainingRegisterResources();

  const handleSegmentItemClick = (selection) => {
    if (selection !== activeToggleChoice) onArchiveToggle();
  };

  return (
    <Fragment>
      <RowBar
        actions={
          <Fragment>
            {currentActor.isAllowedFeature('training_register') &&
              actionButtonsVisible && <TrainingMatrixDownloader />}
            {actionButtonsVisible && (
              <OutlinedButton color='grey' onClick={onNewPersonnel} size='sm'>
                <PersonIcon className='m-l--8' height={24} width={24} />
                <span>Add new personnel</span>
              </OutlinedButton>
            )}
          </Fragment>
        }
        content={
          <span className='separated-items flex flex--vertically-centered'>
            {hasArchivedPersonnelAccess && (
              <SegmentedControls
                activeChoice={activeToggleChoice}
                choices={['Current', 'Archived']}
                onItemClick={handleSegmentItemClick}
              />
            )}
            <SearchField
              name='personnelSearch'
              onChange={onSearchInputChange}
              onReset={onSearchReset}
              placeholder={`Search ${isArchivedToggled ? 'archived... ' : 'personnel or ID...'}`}
              value={personnelSearch}
            />
          </span>
        }
        modifiers={[
          'border-top-curved',
          'border-bottom-none',
          'flex-align-items-ie-fix',
        ]}
      />
    </Fragment>
  );
}

PersonnelBar.propTypes = {
  actionButtonsVisible: PropTypes.bool,
  isArchivedToggled: PropTypes.bool,
  onArchiveToggle: PropTypes.func,
  personnelSearch: PropTypes.string,
  onNewPersonnel: PropTypes.func.isRequired,
  onSearchInputChange: PropTypes.func.isRequired,
  onSearchReset: PropTypes.func.isRequired,
};
