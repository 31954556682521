import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { asyncScrollToTop } from 'components/helpers/scrolling';

import HomeScreen from 'components/mobileTrainingProfile/components/HomeScreen';
import PersonnelDetailsScreen from 'components/mobileTrainingProfile/components/PersonnelDetailsScreen';
import CourseDetailsScreen from 'components/mobileTrainingProfile/components/CourseDetailsScreen';

export default function MobileTrainingProfilePage({
  appLogoImgSrc,
  isProcoreSession,
  personnelId,
}) {
  const [screen, setScreen] = useState('home');
  const [currentCourse, setCurrentCourse] = useState({
    id: null,
    isRequired: false,
  });

  const handleViewPersonnelDetailsClick = () => {
    asyncScrollToTop().then(() => setScreen('personnelDetails'));
  };

  const handleViewCourseDetailsClick = ({ id, isRequired }) => {
    asyncScrollToTop().then(() => {
      setCurrentCourse({ id, isRequired });
      setScreen('courseDetails');
    });
  };

  const handleViewHomeClick = () => {
    asyncScrollToTop().then(() => setScreen('home'));
  };

  return (
    <div className='tw-relative tw-m-auto tw-min-h-screen tw-max-w-3xl tw-pb-25'>
      {screen === 'home' && (
        <HomeScreen
          appLogoImgSrc={appLogoImgSrc}
          isProcoreSession={isProcoreSession}
          onViewCourseDetailsClick={handleViewCourseDetailsClick}
          onViewPersonnelDetailsClick={handleViewPersonnelDetailsClick}
          personnelId={personnelId}
        />
      )}
      {screen === 'personnelDetails' && (
        <PersonnelDetailsScreen
          onViewHomeClick={handleViewHomeClick}
          personnelId={personnelId}
        />
      )}
      {screen === 'courseDetails' && (
        <CourseDetailsScreen
          courseId={currentCourse.id}
          isRequired={currentCourse.isRequired}
          onViewHomeClick={handleViewHomeClick}
          personnelId={personnelId}
        />
      )}
    </div>
  );
}

MobileTrainingProfilePage.propTypes = {
  personnelId: PropTypes.string.isRequired,
  appLogoImgSrc: PropTypes.string.isRequired,
  isProcoreSession: PropTypes.bool.isRequired,
};
