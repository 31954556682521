import React from 'react';
import PropTypes from 'prop-types';

import NoDataIcon from '-!svg-react-loader?name=NoDataIcon!icons/nodata.svg';

export default function NoDataMessage({ message }) {
  return (
    <div className='tw-absolute tw-bottom-[86px] tw-left-0 tw-right-0 tw-flex tw-flex-col tw-items-center tw-justify-end'>
      <NoDataIcon
        className='tw-mb-4 [&_path]:tw-fill-grey-300'
        height={41}
        width={64}
      />
      <div className='tw-mb-1 tw-font-medium tw-text-grey-700'>
        No data to display
      </div>
      <div className='tw-text-grey-500'>{message}</div>
    </div>
  );
}

NoDataMessage.propTypes = {
  message: PropTypes.node.isRequired,
};
