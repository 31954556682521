function generateMeter(companyMeter) {
  const max = companyMeter.data('max');
  const value = companyMeter.data('value');

  companyMeter.progressbar({
    max: max === 0 ? undefined : max,
    value: value,
  });
}
export { generateMeter };
