import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/application/Tooltip';

export default function TruncatableText({ className = '', text = '' }) {
  const [withTooltip, setWithTooltip] = useState(false);

  const ref = useCallback((node) => {
    if (node !== null) {
      setWithTooltip(node.offsetWidth < node.scrollWidth);
    }
  }, []);

  return (
    <div className={`tooltip-parent tw-truncate ${className}`} ref={ref}>
      <span>{text}</span>
      {withTooltip && (
        <Tooltip placement='top' tooltip={text} trigger='hover' />
      )}
    </div>
  );
}

TruncatableText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};
