import React from 'react';
import ReactDOM from 'react-dom';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import ActionButton from 'components/buttons/ActionButton';

export default function MessageFooter({
  data,
  onRequestError,
  projectId,
  resetRequestError,
  submitDisabled,
  user,
  versionId,
}) {
  const buildDispatchObjects = () => {
    const emails = data.form.to.map((to) => {
      return { email: to.value };
    });

    if (data.form.send_to_self) {
      emails.push({ email: user.attributes.email });
    }

    return emails;
  };

  const messageParams = {
    subject: data.form.subject,
    message: data.form.message,
    rams_included: data.form.ramsIncluded,
    training_report_included: data.form.trainingReportIncluded,
    send_to_self: data.form.sendToSelf,
    dispatches_attributes: buildDispatchObjects(),
  };

  const returnToShowPage = () => {
    window.location.assign(`/projects/${projectId}`);
  };

  const submitMessage = (_arg) => {
    resetRequestError();
    axios
      .post(`/messages`, {
        version_id: versionId,
        message: messageParams,
        password_required: data.form.passwordRequired,
        password: data.form.password,
        evidence_included: data.form.evidenceIncluded,
      })
      .then(returnToShowPage)
      .catch(onRequestError);
  };

  return (
    <PortaledButtons>
      <OutlinedButton
        className='m-r-12'
        color='blue-grey'
        href={`/projects/${projectId}`}
        onDark={true}
        size='md'
      >
        Cancel
      </OutlinedButton>
      <ActionButton
        buttonText='Send'
        disabled={submitDisabled}
        disabledClasses='tw-pointer-events-none tw-text-grey-600 tw-bg-transparent tw-border-grey-600'
        onClick={submitMessage}
        styleClasses='tw-apply-filled-button tw-apply-filled-button--md tw-apply-filled-button--mint tw-apply-filled-button--on-dark'
      />
    </PortaledButtons>
  );
}

const PortaledButtons = (props) => {
  const destination = document.getElementsByClassName('message-actions');
  return ReactDOM.createPortal(props.children, destination[0]);
};
