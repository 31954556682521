import moment from 'moment';

export function getBookingDate(booking) {
  if (!booking) return null;
  return moment.parseZone(booking.attributes.date);
}

export function checkIsDateReached(booking) {
  if (!booking) return null;
  return moment().isSameOrAfter(getBookingDate(booking), 'day');
}
