import React from 'react';
import PropTypes from 'prop-types';

export default function ReadOnlyField({ name, value }) {
  return (
    <input
      className='field__input tw-border-grey-300 tw-bg-grey-050 tw-font-inter tw-text-m tw-font-normal tw-tracking-auto placeholder:tw-text-grey-300 focus-within:tw-border-blue-300 hover:tw-border-grey-400 hover:focus-within:tw-border-blue-300'
      id={name}
      name={name}
      readOnly='readonly'
      type='text'
      value={value}
    />
  );
}

ReadOnlyField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
