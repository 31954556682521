import HandshqApp from '../../handshq-app/handshq-app';

HandshqApp.RiskAssessmentsDownloader = function (options) {
  if (this instanceof HandshqApp.RiskAssessmentsDownloader) {
    let generatePoller, statusPoller, downloadType;
    const requestSelectedTrigger = options['requestSelectedTrigger'];
    const requestAllTrigger = options['requestAllTrigger'];
    const _selectedOnly = options['selectedOnly'];
    const modalEl = options['modal'];

    const modal = new HandshqApp.PdfRetrievalModal({
      el: modalEl,
      onRetry: start,
    });

    this.init = function () {
      visualiseDownloadAvailability();
      modalEl.on('hide.bs.modal', stop);

      riskAssessmentCheckboxes().on('change', function () {
        riskAssessmentCheckboxes()
          .filter('[value=' + $(this).val() + ']')
          .prop('checked', $(this).is(':checked'));
        visualiseDownloadAvailability();
      });

      requestSelectedTrigger.add(requestAllTrigger).on('click', function () {
        downloadType = $(this).data('downloadType');
        start();
      });
    };

    function start() {
      modal.showAsPreparing();
      requestPdfGeneration();

      function requestPdfGeneration() {
        $.ajax({
          url: '/risk_assessments_pdfs/',
          dataType: 'json',
          method: 'POST',
          data: pdfGenerationParams(),
          success: function (response) {
            pollForGeneratedPdf(response['url']);
          },
          error: modal.showAsError,
        });
      }

      function pdfGenerationParams() {
        const onlySelected = downloadType === 'selected';
        const selectedCheckboxes = riskAssessmentCheckboxes({
          selected: onlySelected,
        }).get();
        const selectedCheckboxValues = selectedCheckboxes.map(
          (checkbox) => checkbox.value,
        );
        const uniqueCheckboxValues = [...new Set(selectedCheckboxValues)];

        const masterActivityParams = uniqueCheckboxValues.map(function (id, i) {
          return { id: id, selected_order: i };
        });

        const raPdfParams = {
          risk_assessments_pdf: {
            master_activities: masterActivityParams,
          },
        };

        return raPdfParams;
      }

      function pollForGeneratedPdf(url) {
        generatePoller = new HandshqApp.Polling({
          url: url,
          maxTime: 15000,
          responseCheck: function (response) {
            return response.export_url;
          },
          successCallback: function (response) {
            pollForPdfStatus(response.export_url);
          },
          failureCallback: modal.showAsError,
        });
        generatePoller.start();
      }

      function pollForPdfStatus(statusUrl) {
        statusPoller = new HandshqApp.Polling({
          url: statusUrl,
          maxTime: 15000,
          responseCheck: function (response) {
            return response.url;
          },
          successCallback: function (_response) {
            completed(statusUrl + '.pdf');
          },
          failureCallback: function (_response) {
            modal.showAsError;
          },
        });

        statusPoller.start();
      }
    }

    function stop() {
      if (generatePoller) {
        generatePoller.end();
      }

      if (statusPoller) {
        statusPoller.end();
      }
    }

    function completed(pdfUrl) {
      riskAssessmentCheckboxes().prop('checked', false);
      visualiseDownloadAvailability();
      modal.showAsCompleted(pdfUrl);
    }

    function visualiseDownloadAvailability() {
      requestSelectedTrigger.attr(
        'data-disabled',
        riskAssessmentCheckboxes({ selected: true }).length === 0,
      );
    }

    function riskAssessmentCheckboxes(options) {
      let checkboxSelector = '.master-activity__download input';

      if (options !== undefined && options['selected']) {
        checkboxSelector += ':checked';
      }

      return $(checkboxSelector);
    }
  }
};
