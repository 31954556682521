import React from 'react';
import PropTypes from 'prop-types';

import { personDisplayName } from 'components/helpers/users';

import ModalBox from 'components/application/ModalBox';

import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function SendInvitationModal(props) {
  const { isOpen, onClose, onSendConfirmationClick, personnel, user } = props;

  if (!personnel || !user) return null;

  const wasUserNotInvited = user.attributes.invitationStatus === 'none';

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton className='m-r-12' color='blue' onClick={onClose} size='md'>
        Cancel
      </TextButton>
      <FilledButton color='red' onClick={onSendConfirmationClick}>
        {wasUserNotInvited ? 'Send' : 'Resend'}
      </FilledButton>
    </div>
  );

  return (
    <ModalBox
      customFooter={customFooter}
      isOpen={isOpen}
      mode='flexible'
      onClose={onClose}
    >
      <>
        <div className='modalbox-header tw-border-grey-100'>
          <h2 className='modalbox-header__title truncated-text-container tw-text-l tw-font-semibold tw-text-grey-900'>
            {wasUserNotInvited ? 'Send' : 'Resend'} the invitation to{' '}
            {personDisplayName(personnel.attributes)}?
          </h2>
        </div>
        <div className='modalbox-body'>
          An email will be sent to {personnel.attributes.email} with a link to
          accept the invite. Once accepted, they can view their profile.
          {wasUserNotInvited ? '' : ' The old link will no longer be valid.'}
        </div>
      </>
    </ModalBox>
  );
}

SendInvitationModal.propsTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  personnel: PropTypes.object.isRequired,
  onSendConfirmationClick: PropTypes.func.isRequired,
};
