import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useForm from 'components/hooks/useForm';
import useModal from 'components/hooks/useModal';
import useJobProcessor from 'components/hooks/useJobProcessor';
import Portal from 'components/application/Portal';
import DuplicationWithOutOfDateActivitiesModal from 'components/projectDuplication/DuplicationWithOutOfDateActivitiesModal';
import DuplicationRequestedModal from 'components/projectDuplication/DuplicationRequestedModal';
import DuplicationProcessingModal from 'components/projectDuplication/DuplicationProcessingModal';
import DuplicationFailedModal from 'components/projectDuplication/DuplicationFailedModal';
import ProjectDuplicationRow from 'components/projectDuplication/ProjectDuplicationRow';
import ActionsPill from 'components/application/ActionsPill';
import LoadingIcon from '-!svg-react-loader?name=LoadingIcon!icons/loading.svg';

const initialDuplicateProject = { projectName: '' };
const initialFieldErrors = {
  error: { code: '', detail: '' },
  fieldHighlighted: false,
};

export default function ProjectDuplicationManager(props) {
  const {
    closeDuplicationProcess,
    originalProject,
    outOfDateActivities,
    projectTableWidth,
    ramsSingularName,
  } = props;

  const [
    canDuplicateWithOutOfDateActivities,
    setCanDuplicateWithOutOfDateActivities,
  ] = useState(false);
  const [fieldError, setFieldError] = useState(initialFieldErrors);
  const [duplicateProject, setDuplicateValues, handleDuplicateInputChange] =
    useForm(initialDuplicateProject);
  const [isModalOpen, _setIsModalOpen] = useModal(true);
  const [duplicationStatus, setDuplicationStatus] = useState(null);
  const [jobStatus, setUrl, resourceId] = useJobProcessor();

  const createDuplicationRequest = () => {
    axios
      .post(`/projects/${originalProject.id}/duplication_process`, {
        project: { name: duplicateProject.projectName },
      })
      .then(handleDuplicationRequestSuccess)
      .catch(handleDuplicationRequestFailure);
  };

  const resetDuplicationRequest = () => {
    axios
      .delete(`/projects/${originalProject.id}/duplication_process`)
      .then((_response) => {
        if (jobStatus === 'completed') {
          window.location.href = `/projects/${resourceId}/edit`;
        }
      });
  };

  useEffect(() => {
    if (jobStatus === 'completed' && resourceId) {
      handleDuplicationSuccess();
    }
    if (jobStatus === 'failed') {
      handleDuplicationFailure();
    }
  }, [jobStatus, resourceId]);

  const handleDuplicationRequest = () => {
    if (duplicateProject.projectName.trim() === '') {
      setDuplicateValues(initialDuplicateProject);
      setErrors('record_invalid', "RAMS name can't be blank");
    } else if (duplicateProject.projectName.length > 255) {
      setErrors(
        'record_invalid',
        "RAMS name can't be longer than 255 characters",
      );
    } else {
      setDuplicationStatus('requested');
      createDuplicationRequest();
    }
  };

  const handleDuplicationRequestSuccess = (response) =>
    setUrl(`/jobs/status/${response.data.data.attributes.jobId}`);

  const handleDuplicationRequestFailure = () => {
    setDuplicationStatus('setupError');
    resetDuplicationRequest();
  };

  const handleDuplicationSuccess = () => resetDuplicationRequest();

  const handleDuplicationFailure = () => resetDuplicationRequest();

  const handleConfirmDupWithOutOfDateActivities = () =>
    setCanDuplicateWithOutOfDateActivities(true);

  const removeErrorStyling = () => setFieldError(initialFieldErrors);

  const setErrors = (code, detail) => {
    setFieldError({
      error: { code: code, detail: detail },
      fieldHighlighted: true,
    });
  };

  const processingActionsPill = () => {
    return (
      <div className='l-if m-l-12'>
        <ActionsPill
          color='grey'
          displayText={`Creating ${ramsSingularName}`}
          icon={<LoadingIcon height={12} width={12} />}
          iconStyling='tw-apply-loading-spinner--grey-heavy'
        />
      </div>
    );
  };

  const displayStatus = () => {
    if (jobStatus === 'failed' || duplicationStatus === 'setupError') {
      return 'failed';
    } else if (duplicationStatus === 'requested') {
      return 'requested';
    } else if (
      outOfDateActivities.length > 0 &&
      !canDuplicateWithOutOfDateActivities
    ) {
      return 'unconfirmed';
    } else if (
      (outOfDateActivities.length > 0 && canDuplicateWithOutOfDateActivities) ||
      outOfDateActivities.length === 0
    ) {
      return 'confirmed';
    }
  };

  return (
    <>
      {
        {
          unconfirmed: (
            <DuplicationWithOutOfDateActivitiesModal
              closeModal={closeDuplicationProcess}
              fieldError={fieldError}
              isOpen={isModalOpen}
              onClick={handleConfirmDupWithOutOfDateActivities}
              originalProjectName={originalProject.name}
              outOfDateActivities={outOfDateActivities}
              ramsSingularName={ramsSingularName}
            />
          ),
          confirmed: (
            <DuplicationRequestedModal
              closeModal={closeDuplicationProcess}
              duplicateProject={duplicateProject}
              fieldError={fieldError}
              isOpen={isModalOpen}
              onChange={handleDuplicateInputChange}
              onDuplication={handleDuplicationRequest}
              originalProjectName={originalProject.name}
              ramsSingularName={ramsSingularName}
              removeErrorStyling={removeErrorStyling}
            />
          ),
          failed: (
            <DuplicationFailedModal
              closeModal={closeDuplicationProcess}
              isOpen={isModalOpen}
              originalProjectName={originalProject.name}
              ramsSingularName={ramsSingularName}
            />
          ),
          requested: (
            <>
              <DuplicationProcessingModal
                closeModal={closeDuplicationProcess}
                modifiers={['no-footer']}
                originalProjectName={originalProject.name}
                ramsSingularName={ramsSingularName}
              />
              <Portal containerSelector='#project-duplication'>
                <ProjectDuplicationRow
                  actionsPill={processingActionsPill()}
                  name={duplicateProject.projectName}
                  projectTableWidth={projectTableWidth}
                />
              </Portal>
            </>
          ),
        }[displayStatus()]
      }
    </>
  );
}

ProjectDuplicationManager.defaultProps = {
  ramsSingularName: 'RAMS',
};

ProjectDuplicationManager.propTypes = {
  originalProject: PropTypes.object.isRequired,
  ramsSingularName: PropTypes.string,
  projectTableWidth: PropTypes.number.isRequired,
  closeDuplicationProcess: PropTypes.func.isRequired,
  outOfDateActivities: PropTypes.array.isRequired,
};
