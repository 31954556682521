import React from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';
import ModalBox from 'components/application/ModalBox';

import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function BookingReminderModal({
  booking,
  course,
  isOpen,
  onClose,
  onSendReminderConfirmation,
  personnel,
}) {
  const onSendReminder = () => {
    onClose();
    onSendReminderConfirmation({ booking, course });
  };

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton className='m-r-12' color='blue' onClick={onClose} size='md'>
        Cancel
      </TextButton>
      <FilledButton color='mint' onClick={onSendReminder}>
        Send reminder
      </FilledButton>
    </div>
  );

  return (
    <ModalBox
      customFooter={customFooter}
      isOpen={isOpen}
      mode='flexible'
      onClose={onClose}
    >
      <div className='p-32'>
        <h2 className='m-0 p-0 tw-text-l tw-font-semibold tw-text-grey-900'>
          Are you sure you want to send a reminder to{' '}
          {personnel && personnel.firstName} to take{' '}
          {course && course.attributes.name}?
        </h2>
        <p className='tw-mb-0 tw-ml-0 tw-mr-0 tw-mt-6 tw-p-0 tw-text-m tw-font-normal tw-text-grey-700'>
          An email notification will be sent to personnel with email addresses.
        </p>
      </div>
    </ModalBox>
  );
}

BookingReminderModal.propTypes = {
  personnel: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  booking: resourceShape('booking'),
  course: resourceShape('course'),
  onSendReminderConfirmation: PropTypes.func.isRequired,
};
