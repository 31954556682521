import pluralize from 'pluralize';

function RemoveTradeModal({ onConfirm, onHidden, selector }) {
  const _this = this;
  const modal = $(selector);
  const modalBody = modal.find('.modal-body');
  const header = modal.find('[data-identity="header"]');
  const uniqueMethodStatementList = modal.find(
    '[data-identity="unique_method_statement_list"]',
  );
  const nonUniqueMethodStatementList = modal.find(
    '[data-identity="non_unique_method_statement_list"]',
  );
  const confirmButton = modal.find('[data-identity="confirm_button"]');

  this.setup = () => {
    modal.on('shown.bs.modal', () => modalBody.scrollTop(0));
    modal.on('hidden.bs.modal', (event) => {
      _this.dehydrate();
      onHidden(event);
    });
    confirmButton.on('click', onConfirm);
  };

  this.setModalShown = (boolean) => {
    modal.modal(boolean ? 'show' : 'hide');
  };

  this.dehydrate = () => {
    header.empty();
    uniqueMethodStatementList.empty();
    nonUniqueMethodStatementList.empty();
    confirmButton.empty();
  };

  this.hydrate = ({ categoryName, methodStatements }) => {
    _this.dehydrate();
    _this.hydrateHeader(categoryName);
    _this.hydrateUniqueMethodStatementList(methodStatements.unique);
    _this.hydrateNonUniqueMethodStatementList(methodStatements.nonUnique);
    _this.hydrateConfirmButton(methodStatements.unique.length);
  };

  this.hydrateHeader = (categoryName) => {
    header.html(
      $(
        `<h2 class='tw-text-xl tw-text-grey-900 tw-font-semibold tw-tracking-tight'>Are you sure you want to remove ${categoryName}?</h2>`,
      ),
    );
  };

  this.displayOtherUniqueMethodStatements = () => {
    uniqueMethodStatementList.find('button').addClass('hidden');
    uniqueMethodStatementList.find('li').removeClass('hidden');
  };

  this.hydrateUniqueMethodStatementList = (methodStatements) => {
    const methodStatementCount = methodStatements.length;
    if (methodStatementCount === 0) return;

    const otherBreakPoint = 3;
    const remainingCount = methodStatementCount - otherBreakPoint;

    uniqueMethodStatementList.html(
      `<div class='tw-font-semibold m-t-24 m-b-16'>${methodStatementCount} Method statement ${pluralize('section', methodStatementCount)} unique to this category will be deleted:</div><ul class='app-ul'></ul>`,
    );

    if (methodStatementCount > otherBreakPoint) {
      uniqueMethodStatementList.append(
        $(
          `<button class='app-link tw-align-baseline tw-bg-transparent tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300 p-8'>See ${remainingCount} ${pluralize('other', remainingCount)}</button>`,
        ).on('click', _this.displayOtherUniqueMethodStatements),
      );
    }

    methodStatements.forEach((methodStatement, index) => {
      uniqueMethodStatementList
        .find('ul')
        .append(
          $(
            `<li class='tw-bg-grey-050 truncated-text-container br-3 p-8 ${index > otherBreakPoint - 1 ? 'hidden' : ''}'>${methodStatement.attributes.name}</li>`,
          ),
        );
    });
  };

  this.hydrateNonUniqueMethodStatementList = (methodStatements) => {
    const methodStatementCount = methodStatements.length;
    if (methodStatementCount === 0) return;

    nonUniqueMethodStatementList.html(
      `<div class='tw-font-semibold m-t-24 m-b-16'>${methodStatementCount} Shared method statement ${pluralize('section', methodStatementCount)} could partially lose content:</div><ul class='app-ul'></ul>`,
    );

    methodStatements.forEach((methodStatement) => {
      nonUniqueMethodStatementList
        .find('ul')
        .append(
          $(
            `<li class='tw-bg-grey-050 truncated-text-container br-3 p-8'>${methodStatement.attributes.name}</li>`,
          ),
        );
    });
  };

  this.hydrateConfirmButton = (methodStatementCount) => {
    confirmButton.html(
      `Permanently delete ${methodStatementCount > 0 ? `${methodStatementCount}+` : 'related'} items`,
    );
  };
}

export default RemoveTradeModal;
