import { useEffect, useState } from 'react';
import axios from 'axios';

export default function useELearningCourse(eLearningCourseId, onError) {
  const [eLearningCourse, setELearningCourse] = useState(null);

  const getELearningCourse = () => {
    axios
      .get(`/e_learning_courses/${eLearningCourseId}`)
      .then((response) => setELearningCourse(response.data.data))
      .catch(() => onError());
  };

  useEffect(() => {
    eLearningCourseId ? getELearningCourse() : setELearningCourse(null);
  }, [eLearningCourseId]);

  return eLearningCourse;
}
