import React from 'react';

import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from 'react-query-settings/queryClient';
import AppWrapper from 'components/apps/AppWrapper';
import PersonnelPage from 'components/pages/PersonnelPage';

export default function PersonnelIndexApp({ contextProps, wrappedProps }) {
  return (
    <QueryClientProvider client={queryClient}>
      <AppWrapper {...contextProps}>
        <PersonnelPage {...wrappedProps} />
      </AppWrapper>
    </QueryClientProvider>
  );
}
