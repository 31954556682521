import React, { useRef, useState, useEffect } from 'react';
import useOutsideClick from 'components/hooks/useOutsideClick';
import usePrevious from 'components/hooks/usePrevious';
import OtherOptions from 'components/OtherOptions';
import classNames from 'classnames';

export default function Options(props) {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const prevIsOpen = usePrevious(isOpen);

  useOutsideClick(() => {
    setIsOpen(false);
  }, ref);

  const handleOptionClick = (optionCallback = () => {}, _child) => {
    optionCallback(setIsOpen);
  };

  const handleToggle = () => {
    if (!props.buttonDisabled) {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    if (prevIsOpen && !isOpen && props.onClose) props.onClose();
    if (!prevIsOpen && isOpen && props.onOpen) props.onOpen();
  }, [prevIsOpen, isOpen]);

  const handleTopLevelOptionClick = (event) => event.stopPropagation();

  const optionsAlignment = (() => {
    switch (props.align) {
      case 'left':
        return 'tw-justify-start';
      case 'center':
        return 'tw-justify-center';
      default:
        return 'tw-justify-end';
    }
  })();

  const optionsListAlignment = (() => {
    switch (props.align) {
      case 'left':
        return 'tw-left-0';
      case 'center':
        return 'tw-left-1/2 -tw-translate-x-1/2';
      default:
        return 'tw-right-0';
    }
  })();

  return (
    <div
      className={`options ${optionsAlignment}${props.toggleStyle ? ` ${props.toggleStyle}` : ''}`}
      onClick={handleTopLevelOptionClick}
      ref={ref}
    >
      <div
        className={classNames(
          'options__toggle',
          props.btnToggle ?
            'options__toggle--btn tw-border-transparent tw-bg-transparent'
          : 'tw-border-white tw-bg-white group-hover/row:tw-border-grey-100',
          props.btnToggle &&
            props.buttonDisabled &&
            'options__toggle--btn-disabled',
          isOpen && 'options--open',
          props.darkHover && 'options__toggle--dark-hover',
        )}
        onClick={handleToggle}
      >
        {props.btnToggle ?
          <React.Fragment>{props.btnToggle(isOpen)}</React.Fragment>
        : <OtherOptions blink={false} />}
      </div>
      {isOpen && (
        <ul
          className={classNames(
            'options__list tw-border-grey-100 tw-bg-white tw-shadow-sm',
            optionsListAlignment,
            props.listStyle,
          )}
        >
          {React.Children.toArray(props.children)
            .filter(Boolean)
            .map((child, index) => (
              <li
                className={classNames(
                  'options__choice tw-border-grey-100',
                  child.props.hoverClassName || 'hover:tw-bg-blue-025',
                  child.props.disabled ?
                    'tw-cursor-not-allowed'
                  : 'tw-cursor-pointer',
                )}
                key={index}
                onClick={() => {
                  if (!child.props.disabled)
                    handleOptionClick(child.props.onClick, child);
                }}
              >
                <div className={child.props.disabled ? 'tw-opacity-30' : ''}>
                  {child}
                </div>
              </li>
            ))}
        </ul>
      )}

      {isOpen && props.additional && (
        <div className='options__additional'>{props.additional}</div>
      )}
    </div>
  );
}
