import React from 'react';
import BlockList from 'components/application/BlockList';

export default function RolesSidePanelCoursesTab({ courseCollection }) {
  // order courseCollection alphabetically
  const orderedCourseCollection = [...courseCollection].sort((a, b) =>
    a.courseName.localeCompare(b.courseName, 'en', { sensitivity: 'base' }),
  );

  return (
    <div key='coursesTab' label='courses'>
      <BlockList
        collection={orderedCourseCollection}
        onItemDisplay={(course) => {
          return (
            <div className='tw-flex tw-flex-col'>
              <span className='tw-text-grey-700'>{course.courseName}</span>
              <span className='tw-text-s tw-text-grey-500'>
                {course.required ? 'Required' : 'Optional'}
              </span>
            </div>
          );
        }}
      />
    </div>
  );
}
