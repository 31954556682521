$(function () {
  $('.review__nav-action').on('click', function (_e) {
    disableReviewNavOptions();
  });

  // submitting the form for when the disable ordering interferes with the usual submit
  $('.review__rejection--send').on('click', function () {
    submitReviewRejectionForm(this);
  });

  const rejectionReasonText = $('#review_rejection_reason');
  if (rejectionReasonText.length > 0) {
    rejectionReasonText.on('keyup', function () {
      toggleReviewReject(this);
    });
  }

  function disableReviewNavOptions() {
    $('.review__nav-action, #review__document_link').attr('disabled', true);
  }

  function submitReviewRejectionForm(btn) {
    const formId = $(btn).attr('form');
    $('#' + formId).submit();
  }

  function toggleReviewReject(textArea) {
    const rejectReviewBtn = $('#reject-review');
    const rejectIsEmpty = textArea.value.length === 0;
    rejectReviewBtn.prop('disabled', rejectIsEmpty);
  }
});
