import React from 'react';
import PropTypes from 'prop-types';

const colourClassName = {
  success: 'tw-text-cyan-800 tw-bg-cyan-025',
  danger: 'tw-text-red-800 tw-bg-red-025',
};

export default function Alert({ children, type }) {
  return (
    <div
      className={`tw-rounded-lg tw-border-0 tw-p-3 ${colourClassName[type]}`}
    >
      {children}
    </div>
  );
}

Alert.propTypes = {
  type: PropTypes.oneOf(['success', 'danger']).isRequired,
};
