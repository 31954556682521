import React from 'react';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import PersonnelTrainingStatusIndicator from 'components/personnel/PersonnelTrainingStatusIndicator';
import PersonIcon from '-!svg-react-loader?name=PersonIcon!icons/person.svg';
import CircleQuestion from 'components/application/CircleQuestion';
import Tooltip from 'components/application/Tooltip';
import classNames from 'classnames';

export default function TeamMembersList({ teamMembers }) {
  const currentActor = useCurrentActor();
  return (
    <ul className='block-list tw-border-grey-100'>
      {teamMembers.collection.map((member) => {
        const memberName = `${member.attributes.firstName} ${member.attributes.lastName}`;
        const isClickableMember =
          member.relationships.personnel.data &&
          currentActor.isAllowedFeature('training_register');
        const membershipDescription = (() => {
          if (member.relationships.teamManager.data) {
            if (member.relationships.teamPersonnel.data) {
              return 'Manager';
            } else {
              return (
                <div className='flex items-center'>
                  <span>Manager only</span>
                  <div className='tw-ml-2'>
                    <Tooltip
                      placement='top'
                      tooltip={`${memberName} can't be made a member as they do not have a personnel profile in this division`}
                      trigger='hover'
                    >
                      <CircleQuestion size='sm' />
                    </Tooltip>
                  </div>
                </div>
              );
            }
          } else if (member.relationships.teamPersonnel.data) {
            return 'Member';
          }
        })();

        return (
          <li
            className={classNames(
              'block-list__item tw-border-grey-100 tw-py-4',
              isClickableMember && 'tw-cursor-pointer hover:tw-bg-grey-025',
            )}
            key={`block-list__item--${member.id}`}
            onClick={() => {
              if (isClickableMember) {
                window.location.replace(
                  `personnel/${member.relationships.personnel.data.id}`,
                );
              }
            }}
          >
            <div className='block-list__item--flex-container'>
              <div className='block-list__item--align-start'>
                {currentActor.isAllowedFeature('training_register') &&
                  (member.relationships.personnel.data ?
                    <PersonnelTrainingStatusIndicator
                      status={member.attributes.trainingStatus}
                    />
                  : <div className='tw-w-2.5' />)}
              </div>
              <div
                className={classNames(
                  'block-list__item--text mw-300',
                  !currentActor.isAllowedFeature('training_register') &&
                    'tw-ml-0',
                )}
              >
                <span className='truncated-text-container'>
                  {`${memberName}`}
                </span>
                <div className='truncated-text-container tw-inline-flex'>
                  {member.relationships.teamManager.data && (
                    <PersonIcon
                      className='m-l--6 [&_path]:tw-fill-grey-500'
                      height={24}
                      width={24}
                    />
                  )}
                  <span className='tw-self-center tw-text-s tw-text-grey-500'>
                    {membershipDescription}
                  </span>
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
