import React from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { useCurrentActor } from 'components/contexts/CurrentActor';

import SidePanel from 'components/application/SidePanel';
import FormFooter from 'components/sidepanels/FormFooter';
import ShowFooter from 'components/sidepanels/ShowFooter';
import CourseSidePanelShow from 'components/courses/sidepanel/CourseSidePanelShow';
import CourseSidePanelForm from 'components/courses/sidepanel/CourseSidePanelForm';
import TrainingRegisterReportsSelector from 'components/trainingRegisterReports/TrainingRegisterReportsSelector';
import classNames from 'classnames';

export default function CourseSidePanel(props) {
  const {
    allowShowFooterContents,
    assignableRoles,
    autoEnrolmentConfiguration,
    closeCallback,
    createCourse,
    currentCourse,
    domainCourse,
    eLearningCourse,
    isLoadMorePersonnelActive,
    isOpen,
    loadMoreRef,
    onCourseCompanyRoleDelete,
    onCourseCompanyRoleRequiredChange,
    onCourseRolesOptionChange,
    onELearningProviderSelectedChange,
    onInputChange,
    onOptionChange,
    originalCourse,
    personnel,
    removeErrorStyling,
    requestError,
    setSidePanelContext,
    sidePanelContext,
    submitDisabled,
    updateCourse,
  } = props;

  const openEditCourse = () => {
    setSidePanelContext('edit');
  };
  const bodyRef = React.useRef();
  const currentActor = useCurrentActor();
  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();
  const reportsTabViewable =
    currentActor.isAllowedFeature('training_register') &&
    trainingRegisterResourceManagementContext.hasPersonnelViewableAccess;

  let heading = '';
  switch (sidePanelContext) {
    case 'edit':
      heading = 'Edit Course';
      break;
    case 'new':
      heading = 'Add new course';
      break;
    case 'show':
      heading = 'Course';
      break;
  }

  const headerContent = (
    <>
      <div className='popup__title popup__title--tertiary'>
        <h1
          className={classNames(
            sidePanelContext === 'show' ?
              'tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-500'
            : 'tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-900',
          )}
        >
          {heading}
        </h1>
      </div>
      {sidePanelContext === 'show' && (
        <div>
          <h2
            className={classNames(
              'tw-mt-2 tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-700',
              reportsTabViewable ? 'tw-mb-4' : 'tw-mb-0',
            )}
          >
            {currentCourse.name}
          </h2>
          {reportsTabViewable && (
            <div>
              <TrainingRegisterReportsSelector
                filter={{ courseId: { eq: currentCourse.id } }}
              />
            </div>
          )}
        </div>
      )}
      {sidePanelContext === 'edit' &&
        trainingRegisterResourceManagementContext.isMultiDivisionAccount && (
          <p className='m-0 m-t-20 tw-rounded-lg tw-border-0 tw-bg-amber-025 tw-p-3 tw-text-amber-800'>
            Changes could affect personnel training records related to this
            course. These might include personnel in divisions you do not have
            access to.
          </p>
        )}
    </>
  );

  const bodyContent =
    sidePanelContext === 'show' ?
      domainCourse && (
        <CourseSidePanelShow
          assignableRoles={assignableRoles}
          autoEnrolmentConfiguration={autoEnrolmentConfiguration}
          currentCourse={currentCourse}
          domainCourse={domainCourse}
          eLearningCourse={eLearningCourse}
          isLoadMorePersonnelActive={isLoadMorePersonnelActive}
          loadMoreRef={loadMoreRef}
          originalCourse={originalCourse}
          personnel={personnel}
          sidePanelIsOpen={isOpen}
        />
      )
    : <CourseSidePanelForm
        assignableRoles={assignableRoles}
        bodyRef={bodyRef}
        currentCourse={currentCourse}
        eLearningCourse={eLearningCourse}
        onCourseCompanyRoleDelete={onCourseCompanyRoleDelete}
        onCourseCompanyRoleRequiredChange={onCourseCompanyRoleRequiredChange}
        onCourseRolesOptionChange={onCourseRolesOptionChange}
        onELearningProviderSelectedChange={onELearningProviderSelectedChange}
        onInputChange={onInputChange}
        onOptionChange={onOptionChange}
        removeErrorStyling={removeErrorStyling}
        requestError={requestError}
      />;

  const footerContent = () => {
    switch (sidePanelContext) {
      case 'new':
        return (
          <FormFooter
            onCancel={closeCallback}
            onSubmit={createCourse}
            submitButtonDisabled={submitDisabled}
            text='Add new course'
          />
        );
      case 'show':
        return (
          allowShowFooterContents && (
            <ShowFooter
              deleteDisabled={currentCourse.trainingCount > 0}
              deleteDisabledTooltip='You cannot delete courses with recorded training, including training of personnel that were archived'
              onDeleteClick={props.onDeleteClick}
              onEditClick={openEditCourse}
              resource={currentCourse.name}
              type='course'
            />
          )
        );
      case 'edit':
        return (
          <FormFooter
            onCancel={closeCallback}
            onSubmit={updateCourse}
            submitButtonDisabled={submitDisabled}
            text='Save changes'
          />
        );
    }
  };

  return (
    <SidePanel
      bodyContent={bodyContent}
      bodyRef={bodyRef}
      closeCallback={closeCallback}
      color={'tertiary'}
      contentContext={sidePanelContext}
      displayClose={sidePanelContext === 'show'}
      footerContent={footerContent()}
      headerContent={headerContent}
      isOpen={isOpen}
      scrollHeader={false}
      submitDisabled={submitDisabled}
    />
  );
}

CourseSidePanel.propTypes = {
  allowShowFooterContents: PropTypes.bool.isRequired,
  currentCourse: PropTypes.object.isRequired,
  domainCourse: resourceShape('course').isRequired,
  originalCourse: resourceShape('originalCourse'),
  eLearningCourse: resourceShape('eLearningCourse'),
  autoEnrolmentConfiguration: resourceShape('autoEnrolmentConfiguration'),
  requestError: PropTypes.object.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  sidePanelContext: PropTypes.string.isRequired,
  setSidePanelContext: PropTypes.func.isRequired,
  updateCourse: PropTypes.func.isRequired,
  createCourse: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  onRequestError: PropTypes.func.isRequired,
  onELearningProviderSelectedChange: PropTypes.func.isRequired,
  assignableRoles: PropTypes.array.isRequired,
  isLoadMorePersonnelActive: PropTypes.bool.isRequired,
  personnel: PropTypes.object.isRequired,
  onCourseCompanyRoleRequiredChange: PropTypes.func.isRequired,
  onCourseCompanyRoleDelete: PropTypes.func.isRequired,
  onCourseRolesOptionChange: PropTypes.func.isRequired,
};
