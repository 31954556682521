import React, { useContext } from 'react';
import { ProjectPersonnelContext } from 'components/contexts/ProjectPersonnelContext';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import moment from 'moment';
import voca from 'voca';
import StatusIndicator from 'components/application/indicators/StatusIndicator';

export default function ProjectPersonnelTrainingStatusDetails(props) {
  const currentActor = useCurrentActor();
  const projectPersonnelContext = useContext(ProjectPersonnelContext);

  const inFuture =
    projectPersonnelContext.projectStartDate &&
    moment(projectPersonnelContext.projectStartDate) > moment();

  const ramsName = currentActor.division.attributes.singularRAMSName || 'RAMS';

  const trainingStatusOptions = {
    0: {
      status: 'high',
      label: 'Missing training',
      labelStyling: 'tw-text-red-500',
    },
    1: {
      status: 'high',
      label: 'Expiry date is missing',
      labelStyling: 'tw-text-red-500',
    },
    2: {
      status: 'high',
      label: inFuture ? `Expires before ${ramsName}` : 'Expired',
      labelStyling: 'tw-text-red-500',
    },
    3: {
      status: 'medium',
      label: `Expires during ${ramsName}`,
      labelStyling: 'tw-text-amber-600',
    },
    4: {
      status: 'low',
      label: `Valid for ${ramsName}`,
      labelStyling: 'tw-text-green-400',
    },
    5: {
      status: 'grey',
      label: 'No required training',
      labelStyling: 'tw-text-grey-300',
    },
    6: {
      status: 'grey',
      label: `${voca.capitalize(ramsName)} ended`,
      labelStyling: 'tw-text-grey-300',
    },
    7: {
      status: 'grey',
      label: `${voca.capitalize(ramsName)} dates not set`,
      labelStyling: 'tw-text-grey-300',
    },
  }[props.status];

  const status = trainingStatusOptions && trainingStatusOptions['status'];

  return (
    <div className='fh-32 flex flex--vertically-centered'>
      <StatusIndicator status={status} />
      <div>
        <span
          className={`m-l-8 tw-text-s tw-font-medium tw-tracking-wide ${trainingStatusOptions.labelStyling}`}
        >
          {trainingStatusOptions.label}
        </span>
      </div>
    </div>
  );
}
