import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ModalBox from 'components/application/ModalBox';
import { personDisplayName } from 'components/helpers/users';
import classNames from 'classnames';
import ProfilePhotoDisplay from 'components/application/ProfilePhotoDisplay';

export default function ReviewModalBox({ review }) {
  const [isOpen, setIsOpen] = useState(false);

  const openModalBox = () => {
    setIsOpen(true);
  };
  const closeModalBox = () => {
    setIsOpen(false);
  };
  const displayDateTime = (date) =>
    moment.parseZone(date).format('DD MMM YY [at] HH:mm');

  const reviewState = review.loaded && review.member.attributes.state;
  const reviewDate =
    review.loaded &&
    (reviewState === 'accepted' ?
      review.member.attributes.signedAt
    : review.member.attributes.rejectedAt);
  const versionDetails =
    review.loaded &&
    `Version ${review.includedReviewedVersion.attributes.displayNumber}${reviewDate ? ` on ${displayDateTime(reviewDate)}` : ''}`;

  const acceptedHeader =
    reviewState === 'accepted' &&
    `${personDisplayName(review.includedPersonnel.attributes)} signature`;
  const acceptedBody = reviewState === 'accepted' && (
    <React.Fragment>
      {review.includedPhoto && (
        <div className='popup-photo'>
          <div className='popup-photo__wrapper tw-border-grey-100'>
            <img
              alt='popup-photo__image'
              src={review.includedPhoto.attributes.attachedUrl}
            />
          </div>
        </div>
      )}
      {review.includedSignature && (
        <div
          className={classNames(
            'popup-signature',
            review.includedPhoto && 'popup-signature--popup-photo',
          )}
        >
          <div className='popup-signature__wrapper tw-border-grey-100'>
            <img
              alt='popup-signature__image'
              src={review.includedSignature.attributes.attachedUrl}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );

  const rejectedHeader =
    reviewState === 'rejected' &&
    `${personDisplayName(review.includedPersonnel.attributes)} rejection reason`;
  const rejectedBody = reviewState === 'rejected' && (
    <p className='m-0 p-0'>{review.member.attributes.rejectionReason}</p>
  );

  useEffect(() => {
    if (review.loaded) {
      openModalBox();
    }
  }, [review.loaded]);

  return (
    <ModalBox isOpen={isOpen} onClose={closeModalBox}>
      <React.Fragment>
        <div className='tw-flex tw-items-center tw-border-b-1 tw-border-t-1 tw-border-solid tw-border-grey-100 tw-px-6 tw-py-3'>
          <div className='tw-mr-3'>
            {review.loaded && (
              <ProfilePhotoDisplay
                componentSize='md'
                photoUrl={review.includedProfilePhoto?.links?.croppedUrl}
                profileInitials={`${review.includedPersonnel.attributes.firstName[0]}${review.includedPersonnel.attributes.lastName[0]}`}
              />
            )}
          </div>
          <div>
            <h2 className='tw-m-0 tw-font-inter tw-text-l tw-font-semibold tw-tracking-auto tw-text-grey-900'>
              {acceptedHeader || rejectedHeader}
            </h2>
            <p className='tw-m-0'>{versionDetails}</p>
          </div>
        </div>

        <div className='popup__body'>
          <div className='popup__body-light'>
            {acceptedBody || rejectedBody}
          </div>
        </div>
      </React.Fragment>
    </ModalBox>
  );
}
