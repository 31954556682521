import React, { Fragment, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import DropIcon from '-!svg-react-loader?name=DropIcon!icons/ic-drop.svg';
import FilesIcon from '-!svg-react-loader?name=FilesIcon!icons/ic-files.svg';
import ValidationErrorMessage from 'components/uploader/components/ValidationErrorMessage';
import classNames from 'classnames';

const isCancellable = { default: true, compact: false, solo: false };
const supportedFileTypes = {
  pdf: 'application/pdf',
  png: 'image/jpeg',
  jpg: 'image/png',
};

export default function Uploader(props) {
  const {
    className,
    fieldInvalid,
    mode,
    onCancel,
    onError,
    onUpload,
    uploadsCount,
  } = props;

  const acceptedFileTypes = props.fileTypes.filter(
    (fileType) => supportedFileTypes[fileType],
  );
  const acceptedMimeTypes = acceptedFileTypes
    .map((fileType) => supportedFileTypes[fileType])
    .join(', ');

  const minSize = 0;
  const maxSize = props.maxFileSize || 10000000;
  const maxUploads = props.maxUploads || 9;
  const maxSizeDisplay = maxSize / 1000000;
  const maxFileSizeMessage = `Max file size ${maxSizeDisplay}MB`;
  const centerPlaceholder = props.centerPlaceholder || false;

  const onDrop = useCallback(
    (acceptedFiles) => {
      const maxCountReached = uploadsCount + acceptedFiles.length > maxUploads;

      if (maxCountReached) {
        onError({
          fullMessage: (
            <Fragment>
              There is a maximum of{' '}
              <span className='tw-font-medium'>{`${maxUploads} files`}</span>{' '}
              per item
            </Fragment>
          ),
        });
      } else {
        acceptedFiles.forEach((file) => onUpload(file));
      }
    },
    [uploadsCount],
  );

  const { getInputProps, getRootProps, isDragActive, open, rejectedFiles } =
    useDropzone({
      onDrop: onDrop,
      accept: acceptedMimeTypes,
      minSize: minSize,
      maxSize: maxSize,
      noClick: true,
      noKeyboard: true,
    });

  useEffect(() => {
    rejectedFiles.forEach((file) => {
      onError({
        fullMessage: (
          <ValidationErrorMessage
            filename={file.name}
            maxSizeDisplay={maxSizeDisplay}
            maxSizeReached={file.size > maxSize}
            typeNotAccepted={
              !file.type || !acceptedMimeTypes.includes(file.type)
            }
          />
        ),
      });
    });
  }, [rejectedFiles]);

  // initialValue is provided though not used, in order to not skip the first iteration
  const formattedFileTypes = acceptedFileTypes.reduce(
    (accumulator, currentValue, index, collection) => {
      const formattedFileType = currentValue.toUpperCase();
      if (index === 0) return formattedFileType;
      if (index === collection.length - 1)
        return `${accumulator} or ${formattedFileType}`;
      return `${accumulator}, ${formattedFileType}`;
    },
    true,
  );

  return (
    <div
      {...getRootProps({
        className: `attachments__item attachments__item--${mode}-mode${props.mode !== 'solo' ? ' attachments__item--static' : ''}${!!className ? ` ${className}` : ''}`,
      })}
    >
      <div
        className={classNames(
          'attachments__uploader tw-border-grey-300',
          isDragActive && 'tw-border-solid tw-border-grey-400',
          fieldInvalid && 'tw-border-red-600',
        )}
      >
        <input {...getInputProps(props.externalInputProps)} />
        <div
          className={`${centerPlaceholder ? 'tw-absolute tw-left-1/2 tw-top-1/2 tw-m-0 -tw-translate-x-1/2 -tw-translate-y-1/2' : ''}`}
        >
          {isDragActive ?
            <>
              <div className='attachments__uploader-instructions'>
                <DropIcon
                  className='attachments__drop-icon [&_circle]:tw-fill-blue-500'
                  height={40}
                  width={40}
                />
                Drop files here to
                <br />
                upload them
              </div>
              <div className='attachments__uploader-information tw-text-s tw-tracking-wide tw-text-grey-500'>
                You can drop multiple files
                <br />
                at the same time
              </div>
            </>
          : <>
              <div className='attachments__uploader-instructions'>
                {props.showIcon && (
                  <FilesIcon
                    className='attachments__files-icon'
                    height={32}
                    width={38}
                  />
                )}
                Drag & drop files here or
                <br />
                <a
                  className='attachments__uploader-open tw-font-medium tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
                  onClick={open}
                >
                  upload from device
                </a>
              </div>
              <div className='attachments__uploader-information tw-text-s tw-tracking-wide tw-text-grey-500'>
                {formattedFileTypes}
                <br />
                {maxFileSizeMessage}
              </div>
            </>
          }
        </div>

        {isCancellable[mode] && uploadsCount === 0 && (
          <div className='attachments__uploader-cancellation'>
            <a
              className='attachments__uploader-cancel tw-font-medium tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
              onClick={onCancel}
            >
              Cancel
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

Uploader.propTypes = {
  externalInputProps: PropTypes.object,
  mode: PropTypes.string,
  uploadsCount: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onUpload: PropTypes.func.isRequired,
  maxUploads: PropTypes.number,
};

Uploader.defaultProps = {
  mode: 'default',
  fileTypes: ['png', 'jpg', 'pdf'],
  static: true,
  showIcon: true,
  externalInputProps: {},
};
