import React from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import DownloadIcon from '-!svg-react-loader?name=AddIcon!icons/ic-download.svg';

export default function Download(props) {
  const { buttonText, disabled, filename, hashid } = props;
  const handleClick = () => saveAs(`/attachments/${hashid}/download`, filename);

  return (
    <OutlinedButton
      color='grey'
      disabled={disabled}
      onClick={handleClick}
      size='sm'
    >
      <DownloadIcon className='m-l--8' height={24} width={24} />
      <span>{buttonText}</span>
    </OutlinedButton>
  );
}

Download.propTypes = {
  hashid: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
};

Download.defaultProps = {
  buttonText: 'Download original',
};
