import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CollectionSelect from 'components/application/CollectionSelect';
import Tooltip from 'components/application/Tooltip';
import { formatCompanyOption } from 'components/helpers/companies';
import { useCurrentActor } from 'components/contexts/CurrentActor';

export default function SelectDivision(props) {
  const currentActor = useCurrentActor();
  const isNotPrimaryDivision = !currentActor.division.attributes.primary;
  const primaryDivision = props.companies.find(
    (company) => company.attributes.primary,
  );

  const {
    assignedDivision,
    collectionSelectProps = {},
    companies,
    label,
    onChange,
    sidePanelContext,
  } = props;

  useEffect(() => {
    if (sidePanelContext === 'new') {
      onChange(
        { value: { companyId: currentActor.division.id, subcontractorId: '' } },
        { action: 'select-option', option: undefined, name: 'company' },
      );
    }
  }, []);

  const assignableOptions = () => {
    return companies.map((company) => formatCompanyOption(company));
  };

  const companyValue =
    assignedDivision ? formatCompanyOption(assignedDivision) : '';

  return (
    <div className={`m-l-30 tooltip-parent`}>
      <CollectionSelect
        isDisabled={isNotPrimaryDivision}
        isRequired={true}
        label={label}
        modifiers={['typeable-search']}
        name='company'
        onChange={onChange}
        options={assignableOptions()}
        value={companyValue}
        {...collectionSelectProps}
      />
      {isNotPrimaryDivision && (
        <Tooltip
          className='pos-t-24'
          placement='top'
          tooltip={`This can only be changed from the ${primaryDivision.attributes.name} division`}
          trigger='hover'
        />
      )}
    </div>
  );
}

SelectDivision.propTypes = {
  sidePanelContext: PropTypes.string.isRequired,
  companies: PropTypes.array.isRequired,
  collectionSelectProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};
