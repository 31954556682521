import React from 'react';
import PropTypes from 'prop-types';
import voca from 'voca';
import classNames from 'classnames';

import ChevronUpIcon from '-!svg-react-loader?name=ChevronUpIcon!icons/chevron-up.svg';
import ChevronDownIcon from '-!svg-react-loader?name=ChevronDownIcon!icons/chevron-down.svg';
import AddIcon from '-!svg-react-loader?name=AddIcon!icons/add.svg';
import AddedIcon from '-!svg-react-loader?name=AddedIcon!icons/ic-added.svg';

import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';
import CheckboxField from 'components/application/CheckboxField';

import Select, { components } from 'react-select';
import Async from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import SelectCreatable from 'react-select/creatable';
import CreatableSelect from 'react-select/creatable';

export const MultiValueLabel = (props) => {
  return (
    <components.MultiValueLabel {...props}>
      {props.data.label}
      {props.data.sublabel && (
        <div className='collection-select-multivalue__subtext-container'>
          <span className='truncated-text-container truncated-text-container--auto collection-select-multivalue__subtext'>
            {props.data.sublabel}
          </span>
        </div>
      )}
    </components.MultiValueLabel>
  );
};

export const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <span className="collection-select__multivalue-remove circle--remove tw-bg-transparent before:tw-bg-grey-700 before:tw-content-[''] after:tw-bg-grey-700 after:tw-content-[''] hover:tw-bg-red-600 hover:before:tw-bg-white hover:after:tw-bg-white"></span>
    </components.MultiValueRemove>
  );
};

export const multiRemoveCustomStyles = {
  valueContainer: (provided, _state) => ({
    ...provided,
    textOverflow: 'ellipsis',
    maxWidth: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'initial',
    padding: '9px 12px !important',
  }),
};

export const MultiValueContainer = ({ data, selectProps }) => {
  const values = selectProps.value;

  if (values) {
    return values[values.length - 1].label === data.label ?
        data.label
      : data.label + ', ';
  } else {
    return null;
  }
};

export const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <div className='tw-text-grey-400'>No matching results.</div>
      {props.selectProps.additionalNoOptionsMessage && (
        <div className='tw-text-grey-400'>
          {props.selectProps.additionalNoOptionsMessage}
        </div>
      )}
    </components.NoOptionsMessage>
  );
};

export const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <span className='collection-select__clear-indicator circle--remove'></span>
    </components.ClearIndicator>
  );
};

export const DropdownIndicator = (props) => {
  if (props.noDropdown) {
    return null;
  } else {
    const chevronClassName =
      props.isDisabled ?
        '[&_polygon]:tw-fill-grey-300'
      : '[&_polygon]:tw-fill-grey-700';

    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ?
            <ChevronUpIcon
              className={chevronClassName}
              height={24}
              width={24}
            />
          : <ChevronDownIcon
              className={chevronClassName}
              height={24}
              width={24}
            />
          }
        </components.DropdownIndicator>
      )
    );
  }
};

export const Option = (props) => {
  const isCreate = voca.startsWith(props.children, 'Create');

  return (
    <components.Option {...props}>
      <div
        className={classNames(
          'collection-select__option_container',
          isCreate && 'collection-select__option_container--creatable',
        )}
      >
        {isCreate && (
          <div className='flex'>
            <span className='icon-span'>
              <AddIcon
                className='[&_path]:tw-fill-blue-500'
                height={24}
                width={24}
              />
            </span>
            <span className='truncated-text-container truncated-text-container--width-auto'>
              {props.children.slice(0, -1)}
            </span>
            <span>"</span>
          </div>
        )}
        {props.isDisabled && (
          <div className='collection-select__custom-value-container'>
            <span className='collection-select__auto-content-wrapper'>
              {props.children}
            </span>
            <Tooltip
              className='tooltip-dark--max-w-xxs'
              placement='left'
              tooltip='Already added'
              trigger='hover'
            >
              <span className='collection-select__added-icon-wrapper'>
                <AddedIcon
                  className='[&_circle]:tw-fill-cyan-400'
                  height={16}
                  width={16}
                />
              </span>
            </Tooltip>
          </div>
        )}
        {!isCreate && !props.isDisabled && !props.selectProps.checkboxes && (
          <span>{props.children}</span>
        )}
        {props.data.sublabel && (
          <div className='truncated-text-container collection-select-option__subtext'>
            {props.data.sublabel}
          </div>
        )}
        {props.selectProps.checkboxes && (
          <CheckboxField
            checked={props.isSelected}
            disabled={false}
            isInputLabelSibling={true}
            label={props.label}
            name={props.data.name}
            onChange={props.setValue}
            value={props.value}
          />
        )}
      </div>
    </components.Option>
  );
};

export default function CollectionSelect(props) {
  const defaultProps = {
    id: voca.snakeCase(props.name),
    className: 'collection-select__select-container',
    classNamePrefix: 'collection-select',
    name: props.name,
    value: props.value,
    placeholder: props.placeholder,
    isMulti: props.isMulti,
    isAsync: props.isAsync,
    isClearable: props.isClearable,
    isSearchable: props.isSearchable,
    isDisabled: props.isDisabled,
    autoFocus: props.autoFocus,
    onChange: props.onChange,
    maxMenuHeight: props.maxMenuHeight,
    checkboxes: props.checkboxes,
  };

  const assignedProps = Object.assign(defaultProps, props.externalProps);

  const selectDefaultComponentProps = {
    MultiValueLabel,
    NoOptionsMessage,
    ClearIndicator,
    MultiValueRemove,
    DropdownIndicator,
    Option,
  };
  const selectWithCheckboxComponentProps = {
    MultiValueLabel,
    MultiValueContainer,
    NoOptionsMessage,
    ClearIndicator,
    MultiValueRemove,
    DropdownIndicator,
    Option,
  };

  const asyncComponent = (
    <Async
      {...assignedProps}
      components={{
        NoOptionsMessage,
        ClearIndicator,
        DropdownIndicator,
        Option,
      }}
      loadOptions={props.options}
    />
  );
  const asyncCreatableComponent = (
    <AsyncCreatable
      {...assignedProps}
      components={{
        NoOptionsMessage,
        ClearIndicator,
        DropdownIndicator,
        Option,
      }}
      loadOptions={props.options}
    />
  );
  const selectComponent = (
    <Select
      {...assignedProps}
      components={
        props.checkboxes ?
          selectWithCheckboxComponentProps
        : selectDefaultComponentProps
      }
      options={props.options}
      styles={props.checkboxes && multiRemoveCustomStyles}
    />
  );
  const selectCreatableComponent = (
    <SelectCreatable
      {...assignedProps}
      components={{
        NoOptionsMessage,
        MultiValueRemove,
        DropdownIndicator,
        Option,
      }}
      options={props.options}
    />
  );
  const creatableSelect = (
    <CreatableSelect
      {...assignedProps}
      autoFocus={true}
      components={{ NoOptionsMessage, DropdownIndicator, Option }}
      options={props.options}
    />
  );

  const renderComponent =
    props.isAsync ?
      props.isCreatable ?
        asyncCreatableComponent
      : asyncComponent
    : props.isCreatable ?
      props.isSingleSelect ?
        creatableSelect
      : selectCreatableComponent
    : selectComponent;

  const modifierClassNames = props.modifiers
    .map((modifier) => ` collection-select--${modifier}`)
    .join('');

  return (
    <div
      className={`collection-select${props.modifiers.length > 0 ? `${modifierClassNames}` : ''}`}
    >
      <div
        className={`form-group${props.customMargin ? ` ${props.customMargin}` : ''}`}
      >
        {props.label && (
          <label
            className={
              'collection-select__label tw-font-medium' +
              (props.isRequired ? ' collection-select__required' : '')
            }
            htmlFor={props.name}
          >
            {props.label}
            {props.tooltip && (
              <Tooltip
                className={props.tooltipClassName}
                placement='top'
                tooltip={props.tooltip}
                trigger='hover'
              >
                <CircleQuestion />
              </Tooltip>
            )}
          </label>
        )}
        {renderComponent}
      </div>
    </div>
  );
}

CollectionSelect.propTypes = {
  externalProps: PropTypes.object,
  isRequired: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isAsync: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  modifiers: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  isValidNewOption: PropTypes.bool,
  subfieldName: PropTypes.string,
  subfieldValue: PropTypes.string,
  subfieldPlaceholder: PropTypes.string,
  subfieldHandleChange: PropTypes.func,
  displayOtherField: PropTypes.bool,
  checkboxes: PropTypes.bool,
  maxMenuHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alignTooltipCenter: PropTypes.bool,
};

CollectionSelect.defaultProps = {
  placeholder: null,
  isMulti: false,
  isAsync: false,
  isCreatable: false,
  isSingleSelect: false,
  isClearable: false,
  isSearchable: true,
  isDisabled: false,
  isRequired: false,
  autoFocus: false,
  modifiers: [],
  externalProps: {},
  noDropdown: false,
  isValidNewOption: null,
  subfieldPlaceholder: '',
  alignTooltipCenter: true,
  displayOtherField: false,
  checkboxes: false,
  maxMenuHeight: 138,
};
