import React, { useRef } from 'react';
import { focusField } from 'components/helpers/refs';
import PropTypes from 'prop-types';

import SidePanel from 'components/application/SidePanel';
import ShowFooter from 'components/sidepanels/ShowFooter';
import FormFooter from 'components/sidepanels/FormFooter';
import CoshhShow from 'components/projectCoshh/CoshhShow';
import CoshhForm from 'components/projectCoshh/CoshhForm';
import classNames from 'classnames';

export default function ProjectCoshhSidePanel(props) {
  const {
    closeSidePanel,
    coshhDocument,
    exposureFrequencyOptions,
    locationOptions,
    methodOfUseOptions,
    onCoshhInputChange,
    onCoshhOptionChange,
    onDeleteProjectCoshhClick,
    onNewCoshhDocument,
    onOptionSelect,
    onUpdateCoshhDocument,
    personsAtRiskOptions,
    removeErrorStyling,
    requestError,
    secondaryFieldDefaultValue,
    secondaryFieldLabel,
    setSidePanelContext,
    sidePanelContext,
    sidePanelIsOpen,
    submitDisabled,
    workerExposureTimeOptions,
  } = props;

  const openEditCoshhDocument = () => {
    setSidePanelContext('edit');
  };
  const sidePanelBodyRef = useRef();
  const sidePanelFieldRef = useRef();

  let heading = '';
  switch (sidePanelContext) {
    case 'edit':
      heading = 'Edit COSHH assessment';
      break;
    case 'new':
      heading = 'Add COSHH assessment';
      break;
    case 'show':
      heading = 'COSHH assessment';
      break;
  }

  const headingStyle =
    sidePanelContext === 'show' ?
      'tw-text-s tw-font-medium tw-tracking-tight m-b-8'
    : 'tw-text-grey-900 tw-text-xl tw-font-semibold tw-tracking-tight';

  const titleHeaderContent = (
    <div className='popup__title popup__title--tertiary-split'>
      <h1 className={classNames(headingStyle)}>{heading}</h1>
      <h2
        className={
          'tw-mt-2 tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-700'
        }
      >
        {coshhDocument.title}
      </h2>
      {sidePanelContext === 'show' && (
        <>
          {coshhDocument.manufacturer && (
            <p
              className={classNames(
                'tw-mt-2 tw-text-l tw-font-normal tw-text-grey-500',
                coshhDocument.reference && 'tw-mb-2',
              )}
            >
              {coshhDocument.manufacturer.toUpperCase()}
            </p>
          )}
          {coshhDocument.reference && (
            <p className='tw-mb-0 tw-mt-2 tw-text-l tw-font-normal tw-text-grey-500'>
              REF: {coshhDocument.reference.toUpperCase()}
            </p>
          )}
        </>
      )}
    </div>
  );

  const showBodyContent = (
    <CoshhShow
      coshhDocument={coshhDocument}
      secondaryFieldDefaultValue={secondaryFieldDefaultValue}
      secondaryFieldLabel={secondaryFieldLabel}
    />
  );

  const formBodyContent = (
    <CoshhForm
      coshhDocument={coshhDocument}
      exposureFrequencyOptions={exposureFrequencyOptions}
      locationOptions={locationOptions}
      methodOfUseOptions={methodOfUseOptions}
      onCoshhInputChange={onCoshhInputChange}
      onCoshhOptionChange={onCoshhOptionChange}
      onOptionSelect={onOptionSelect}
      personsAtRiskOptions={personsAtRiskOptions}
      removeErrorStyling={removeErrorStyling}
      requestError={requestError}
      secondaryFieldLabel={secondaryFieldLabel}
      sidePanelFieldRef={sidePanelFieldRef}
      workerExposureTimeOptions={workerExposureTimeOptions}
    />
  );

  const showFooterContent = (
    <ShowFooter
      editType={'assessment'}
      onDeleteClick={() => onDeleteProjectCoshhClick(true)}
      onEditClick={openEditCoshhDocument}
      resource={'resource'}
      type={'assessment'}
    ></ShowFooter>
  );

  const formFooterContent = (
    <FormFooter
      onCancel={closeSidePanel}
      onSubmit={
        sidePanelContext === 'new' ? onNewCoshhDocument : onUpdateCoshhDocument
      }
      submitButtonDisabled={submitDisabled}
      text={sidePanelContext === 'new' ? 'Add COSHH' : 'Save changes'}
    />
  );

  const footerContent = () => {
    switch (sidePanelContext) {
      case 'show':
        return showFooterContent;
      case 'edit':
        return formFooterContent;
      case 'new':
        return formFooterContent;
    }
  };

  const onBackdropClick = () => {
    const panelLocked = ['edit', 'new'].includes(sidePanelContext);
    if (!panelLocked) {
      closeSidePanel();
    }
  };

  return (
    <SidePanel
      bodyContent={
        sidePanelContext === 'show' ? showBodyContent : formBodyContent
      }
      bodyRef={sidePanelBodyRef}
      closeCallback={closeSidePanel}
      color={'tertiary'}
      contentContext={sidePanelContext}
      displayClose={sidePanelContext === 'show'}
      footerContent={footerContent()}
      headerContent={titleHeaderContent}
      isOpen={sidePanelIsOpen}
      onBackdropClick={onBackdropClick}
      onOpen={() => sidePanelContext === 'new' && focusField(sidePanelFieldRef)}
      scrollHeader={false}
      submitDisabled={false}
    />
  );
}

ProjectCoshhSidePanel.propTypes = {
  coshhDocument: PropTypes.object.isRequired,
  sidePanelContext: PropTypes.string.isRequired,
  closeSidePanel: PropTypes.func.isRequired,
  sidePanelIsOpen: PropTypes.bool.isRequired,
  setSidePanelContext: PropTypes.func.isRequired,
  onDeleteProjectCoshhClick: PropTypes.func,
  onUpdateCoshhDocument: PropTypes.func,
  onNewCoshhDocument: PropTypes.func,
  onCoshhInputChange: PropTypes.func.isRequired,
  onCoshhOptionChange: PropTypes.func.isRequired,
  workerExposureTimeOptions: PropTypes.array.isRequired,
  exposureFrequencyOptions: PropTypes.array.isRequired,
  locationOptions: PropTypes.array.isRequired,
  methodOfUseOptions: PropTypes.array.isRequired,
  secondaryFieldLabel: PropTypes.string.isRequired,
  secondaryFieldDefaultValue: PropTypes.string.isRequired,
};
