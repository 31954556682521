import React from 'react';
import PeopleDuoIcon from '-!svg-react-loader?name=PeopleDuoIcon!icons/people-duo.svg';

export default function BlankPersonnelSetSection(props) {
  return (
    <div className='section section--notice br-tl-0 br-tr-0 tw-border-grey-100 tw-bg-white'>
      <PeopleDuoIcon
        className='[&_path]:tw-fill-grey-500'
        height={64}
        width={64}
      />
      <h2 className='section__header tw-text-l tw-font-semibold tw-text-grey-900'>{`No ${props.setName} personnel`}</h2>
    </div>
  );
}
