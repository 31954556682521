import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { titleize } from 'components/helpers/strings';
import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';
import ReadOnlyField from 'components/application/ReadOnlyField';
import CopyableField from 'components/application/CopyableField';
import FilledButton from 'components/application/buttons/FilledButton';
import {
  DropdownIndicator,
  Option,
} from 'components/application/CollectionSelect';
import classNames from 'classnames';

function formatValue(value) {
  return { value: value, label: titleize(value) };
}

export default function EnableFormCard(props) {
  const {
    assertionConsumerServiceUrl,
    domainNames,
    formValues,
    identityProviderNames,
    isIdentityProviderEnforced,
    onInputChange,
    onNameChange,
    onSubmit,
    removeErrorStyling,
    requestError,
    serviceProviderMetadataUrl,
  } = props;

  const isFieldInvalid = (field) => {
    const validationError = requestError.validationErrors[field];

    return !!validationError && validationError.fieldHighlighted;
  };

  return (
    <div className='section p-40 tw-border-grey-100 tw-bg-white'>
      <h3 className='m-t-0 m-b-8 tw-text-l tw-font-semibold tw-tracking-auto tw-text-grey-900'>
        Single sign-on
      </h3>
      <p className='m-0 m-b-32'>
        <span>For help setting up single sign-on, read our </span>
        <a
          className='app-link va-baseline tw-font-medium tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
          href='https://intercom.help/hands-hq/en/articles/6887426-guide-to-single-sign-on-saml-sso'
          target='_blank'
        >
          help guide
        </a>
        <span>.</span>
      </p>
      <div className='m-0 form-group'>
        <label className='hhq-checkbox__label tooltip-parent' htmlFor='enabled'>
          <input
            checked={formValues.enabled}
            className='hhq-checkbox__input'
            disabled={isIdentityProviderEnforced}
            id='enabled'
            name='enabled'
            onChange={onInputChange}
            type='checkbox'
            value='enabled'
          />
          <span
            className={classNames(
              'hhq-checkbox__toggle tw-cursor-pointer tw-border-2 tw-font-bold tw-text-white',
              formValues.enabled ?
                'tw-border-blue-400 tw-bg-blue-400 hover:tw-border-blue-500 hover:tw-bg-blue-500'
              : 'tw-border-grey-300 tw-bg-white hover:tw-border-blue-300 hover:tw-bg-grey-025',
            )}
          ></span>
          {isIdentityProviderEnforced && (
            <div className='pos-abs pos-t-0 pos-l-0 fh-22 fw-22'>
              <Tooltip
                placement='top'
                tooltip='Single sign-on must not be enforced'
                trigger='hover'
              />
            </div>
          )}
          <span className='m-l-12 tw-font-normal tw-tracking-auto'>
            Enable single sign-on
          </span>
        </label>
      </div>
      {formValues.enabled && (
        <>
          <div className='m-t-32 m-b-32 form-group'>
            <label
              className='field__label tw-font-medium'
              htmlFor='domain_names'
            >
              <span>Domain names</span>
              <Tooltip
                placement='top'
                tooltip='These need to match the domain names in your identify provider. To change these, speak to your account manager.'
                trigger='hover'
              >
                <CircleQuestion />
              </Tooltip>
            </label>
            <div className='flex'>
              <ReadOnlyField name='domain_names' value={domainNames} />
              <div className='fw-40 fh-40'></div>
            </div>
          </div>
          <div className='m-b-32 form-group'>
            <CopyableUrl
              labelText='Service provider metadata URL'
              name='service_provider_metadata_url'
              tooltipText='The intended audience of the SAML Assertion'
              value={serviceProviderMetadataUrl}
            />
          </div>
          <div className='m-b-32 form-group'>
            <CopyableUrl
              labelText='Service provider single sign-on URL'
              name='assertion_consumer_service_url'
              tooltipText='The destination where the SAML Assertion is sent'
              value={assertionConsumerServiceUrl}
            />
          </div>
          <hr className='tw-m-0 tw-my-8 tw-h-px tw-border-0 tw-bg-grey-100' />
          <div className='w-50 m-b-32 form-group'>
            <label
              className="field__label tw-font-medium after:tw-absolute after:tw-right-0 after:tw-font-normal after:tw-text-grey-500 after:tw-content-['Required']"
              htmlFor='name'
            >
              <span>Identity provider</span>
            </label>
            <Select
              className='collection-select__select-container'
              classNamePrefix='collection-select'
              components={{ DropdownIndicator, Option }}
              id='name'
              name='name'
              onChange={onNameChange}
              options={identityProviderNames.map((name) => formatValue(name))}
              value={formatValue(formValues.name)}
            />
          </div>
          <div className='m-b-32 form-group'>
            <label
              className={classNames(
                "field__label tw-font-medium after:tw-absolute after:tw-right-0 after:tw-font-normal after:tw-text-grey-500 after:tw-content-['Required']",
                isFieldInvalid('targetUrl') && 'after:tw-text-red-600',
              )}
              htmlFor='targetUrl'
            >
              <span>Identity provider single sign-on URL</span>
              <Tooltip
                placement='top'
                tooltip='The destination of your identity provider sign-in'
                trigger='hover'
              >
                <CircleQuestion />
              </Tooltip>
            </label>
            <input
              className={classNames(
                'field__input tw-font-inter tw-text-m tw-font-normal tw-tracking-auto',
                isFieldInvalid('targetUrl') ?
                  'tw-border-red-600 focus-within:tw-border-red-600 hover:tw-border-red-600 hover:focus-within:tw-border-red-600'
                : 'tw-border-grey-300 focus-within:tw-border-blue-300 hover:tw-border-grey-400 hover:focus-within:tw-border-blue-300',
              )}
              id='targetUrl'
              name='targetUrl'
              onChange={onInputChange}
              onFocus={removeErrorStyling}
              type='text'
              value={formValues.targetUrl}
            />
          </div>
          <div className='form-group'>
            <label
              className={classNames(
                "field__label tw-font-medium after:tw-absolute after:tw-right-0 after:tw-font-normal after:tw-text-grey-500 after:tw-content-['Required']",
                isFieldInvalid('certificate') && 'after:tw-text-red-600',
              )}
              htmlFor='certificate'
            >
              <span>Identity provider certificate</span>
              <Tooltip
                placement='top'
                tooltip='The SAML signing certificate'
                trigger='hover'
              >
                <CircleQuestion />
              </Tooltip>
            </label>
            <textarea
              className={classNames(
                'field__input field__input--textarea fh-320 tw-font-inter tw-text-m tw-font-normal tw-tracking-auto',
                isFieldInvalid('certificate') ?
                  'tw-border-red-600 focus-within:tw-border-red-600 hover:tw-border-red-600 hover:focus-within:tw-border-red-600'
                : 'tw-border-grey-300 focus-within:tw-border-blue-300 hover:tw-border-grey-400 hover:focus-within:tw-border-blue-300',
              )}
              id='certificate'
              name='certificate'
              onChange={onInputChange}
              onFocus={removeErrorStyling}
              rows='12'
              spellCheck='false'
              value={formValues.certificate}
            />
          </div>
        </>
      )}
      <FilledButton className='m-t-40' color='blue' onClick={onSubmit}>
        Save changes
      </FilledButton>
    </div>
  );
}

const CopyableUrl = ({ labelText, name, tooltipText, value }) => {
  return (
    <>
      <label className='field__label tw-font-medium' htmlFor={name}>
        <span>{labelText}</span>
        <Tooltip placement='top' tooltip={tooltipText} trigger='hover'>
          <CircleQuestion />
        </Tooltip>
      </label>
      <CopyableField name={name} value={value} />
    </>
  );
};

EnableFormCard.propTypes = {
  domainNames: PropTypes.string.isRequired,
  serviceProviderMetadataUrl: PropTypes.string.isRequired,
  assertionConsumerServiceUrl: PropTypes.string.isRequired,
  identityProviderNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  formValues: PropTypes.object.isRequired,
  isIdentityProviderEnforced: PropTypes.bool.isRequired,
  requestError: PropTypes.object.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
