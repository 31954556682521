import React from 'react';
import InfoCard from 'components/application/cards/InfoCard';

export default function UserPermissionsAndDivisionAccessInfoCard() {
  return (
    <div>
      <InfoCard
        linkAddress='https://intercom.help/hands-hq/en/articles/5360816-user-permissions-and-division-access-explained'
        title='User permissions and division access explained'
      ></InfoCard>
    </div>
  );
}
