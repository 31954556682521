function findAssignedCompany(options = {}) {
  return options.assignableCompanies.find((company) => {
    if (options.subcontractorId) {
      return (
        company.attributes.resourceType === 'Subcontractor' &&
        company.id === String(options.subcontractorId)
      );
    } else {
      return (
        company.attributes.resourceType === 'Company' &&
        company.id === String(options.companyId)
      );
    }
  });
}

function formatCompanyOption(company) {
  return {
    value: {
      companyId: company.id,
      subcontractorId: '',
    },
    label: company.attributes.name,
  };
}

function formatSubcontractorOption(companyId, subcontractorId, name) {
  return {
    value: {
      companyId: companyId,
      subcontractorId: subcontractorId,
    },
    label: name,
  };
}

function subcontractorDisplayName(subcontractorId, includedSubcontractors) {
  const subcontractor =
    includedSubcontractors &&
    includedSubcontractors.find(
      (subcontractor) => subcontractor.id === String(subcontractorId),
    );

  if (subcontractor) {
    return subcontractor.attributes.name;
  }
}

export {
  findAssignedCompany,
  formatCompanyOption,
  formatSubcontractorOption,
  subcontractorDisplayName,
};
