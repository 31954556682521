import React from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';
import voca from 'voca';
import pluralize from 'pluralize';
import moment from 'moment';

import { resourcesAsOptions } from 'components/helpers/forms';
import { sendAnalytics } from 'components/helpers/analytics';
import {
  displayCurrencyString,
  currencyNumberToCurrencyString,
} from 'components/helpers/currency';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

import ErrorMessage from 'components/application/ErrorMessage';
import TextField from 'components/application/TextField';
import CheckboxField from 'components/application/CheckboxField';
import CollectionSelect from 'components/application/CollectionSelect';
import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';
import RadioField from 'components/application/RadioField';
import CustomELearningCourseCard from 'components/courses/CustomELearningCourseCard';
import CourseSidePanelProviderForm from 'components/courses/sidepanel/CourseSidePanelProviderForm';
import CourseSidePanelAutoEnrolmentForm from 'components/courses/sidepanel/CourseSidePanelAutoEnrolmentForm';

const renewalIntervalOptions = ['day', 'week', 'month', 'year'];

export default function CourseSidePanelForm(props) {
  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();
  const {
    assignableRoles,
    bodyRef,
    currentCourse,
    eLearningCourse,
    onCourseCompanyRoleDelete,
    onCourseCompanyRoleRequiredChange,
    onCourseRolesOptionChange,
    onELearningProviderSelectedChange,
    onInputChange,
    onOptionChange,
    removeErrorStyling,
    requestError,
  } = props;

  const currentActor = useCurrentActor();

  const currencyCode = currentActor.division.attributes.currencyCode;

  const convertValue = (value) => value === 'true';

  const expiryComparisonValuesAreSet =
    currentCourse.renewalFrequency &&
    currentCourse.renewalInterval &&
    currentCourse.expiringDuration;
  const shouldShowExpiryLengthWarning =
    expiryComparisonValuesAreSet &&
    moment.duration(
      currentCourse.renewalFrequency,
      currentCourse.renewalInterval,
    ) < moment.duration(currentCourse.expiringDuration, 'days');

  // filter out already selected courses
  const selectedRoleIds =
    currentCourse.courseCompanyRoles ?
      currentCourse.courseCompanyRoles.map((ccr) => ccr.companyRoleId)
    : [];
  const selectableRoles = assignableRoles.filter(
    (role) => !selectedRoleIds.includes(role.id),
  );

  const isELearningCustom =
    eLearningCourse && eLearningCourse.attributes.custom;
  const eLearningCourseCost =
    isELearningCustom &&
    currencyNumberToCurrencyString({
      number: eLearningCourse.attributes.cost,
      currencyCode,
    });

  // order courseCompanyRoles alphabetically
  const orderedCourseCompanyRoles = currentCourse.courseCompanyRoles
    .map((courseCompanyRole) => {
      const roleId = courseCompanyRole.companyRoleId;
      const rolePosition = assignableRoles.find((role) => role.id == roleId)
        .attributes.position;
      return {
        roleId: roleId,
        rolePosition: rolePosition,
        required: courseCompanyRole.required,
      };
    })
    .sort((a, b) =>
      a.rolePosition.localeCompare(b.rolePosition, 'en', {
        sensitivity: 'base',
      }),
    );

  const isCourseExpiryDateReminderEditable =
    trainingRegisterResourceManagementContext.isCourseExpiryDateEditable;

  return (
    <React.Fragment>
      <div className='popup__body-form p-b-0 m-b-32'>
        <ErrorMessage
          isFallback={requestError.isFallback}
          validationErrors={requestError.validationErrors}
          wrapperClassName='form-container'
        />
        <div className='form-container'>
          <TextField
            fieldError={requestError.validationErrors.name}
            isRequired={true}
            label='Course name'
            name='name'
            onChange={onInputChange}
            removeErrorStyling={removeErrorStyling}
            value={currentCourse.name}
          />
        </div>
      </div>

      <hr className='tw-m-0 tw-h-px tw-border-0 tw-bg-grey-100' />

      <div className='popup__body-form p-b-0 m-b-32'>
        <div className='form-container'>
          <label className='field__label tw-font-medium'>Expiration</label>
          <RadioField
            checked={currentCourse.expires}
            label={'Training expires'}
            name='expires'
            onChange={(event) => {
              onInputChange(event, convertValue);
            }}
            value={true}
          />
          {currentCourse.expires && (
            <div className='m-l-28'>
              <div className='side-panel__inline-container'>
                <div className='field--tab-inline m-r-6'>
                  <TextField
                    fieldError={requestError.validationErrors.renewalFrequency}
                    isDisabled={false}
                    isRequired={false}
                    label='Renewal frequency'
                    name='renewalFrequency'
                    onChange={onInputChange}
                    removeErrorStyling={removeErrorStyling}
                    value={currentCourse.renewalFrequency}
                  />
                </div>
                <div className='field--tab-inline m-l-6'>
                  <CollectionSelect
                    isDisabled={false}
                    isRequired={true}
                    isSearchable={false}
                    name='renewalInterval'
                    onChange={onOptionChange}
                    options={renewalIntervalOptions.map((option) => {
                      return {
                        label: pluralize(voca.titleCase(option)),
                        value: option,
                      };
                    })}
                    value={{
                      label: pluralize(
                        voca.titleCase(currentCourse.renewalInterval),
                      ),
                      value: currentCourse.renewalInterval,
                    }}
                  />
                </div>
              </div>
              <div className='tw-inline-flex tw-items-center tw-gap-2'>
                <div className='tw-w-[140px]'>
                  <TextField
                    fieldError={requestError.validationErrors.expiringDuration}
                    inputClassNames={'fw-72'}
                    isDisabled={!isCourseExpiryDateReminderEditable}
                    isRequired={false}
                    label='Expiry reminder'
                    min={1}
                    name='expiringDuration'
                    onChange={(e) => {
                      sendAnalytics('Course expiry reminder edited', {
                        currentUser: currentActor.user,
                      });
                      onInputChange(e);
                    }}
                    removeErrorStyling={removeErrorStyling}
                    tooltip={`The course status will turn amber and an email notification will be sent${isCourseExpiryDateReminderEditable ? '' : '. This can be customised by upgrading your plan.'}`}
                    type={'number'}
                    value={currentCourse.expiringDuration}
                  />
                </div>
                <div className='tw-text-m'>days before course expiry</div>
              </div>
              {eLearningCourse && (
                <div className='tw-mb-6 tw-flex'>
                  <CheckboxField
                    checked={currentCourse.certificateExpiryDateIncluded}
                    className='tw-w-auto'
                    label='Show expiry date on certificate'
                    name='certificateExpiryDateIncluded'
                    onChange={onInputChange}
                  />
                  <div className='tw-ml-2'>
                    <Tooltip
                      className='tooltip-dark--max-w-xxs'
                      placement='top'
                      tooltip='Expiry date is calculated based on renewal frequency at the time of certificate being awarded'
                      trigger='hover'
                    >
                      <CircleQuestion />
                    </Tooltip>
                  </div>
                </div>
              )}
              {shouldShowExpiryLengthWarning && (
                <div className='m-0 tw-rounded-lg tw-border-0 tw-bg-amber-025 tw-p-3 tw-text-amber-800'>
                  The expiry reminder is longer than the renewal frequency. This
                  might stop reminder emails being sent.
                </div>
              )}
            </div>
          )}
          <RadioField
            checked={!currentCourse.expires}
            label={'Training does not expire'}
            name='expires'
            onChange={(event) => {
              onInputChange(event, convertValue);
            }}
            value={false}
          />
        </div>
      </div>

      <hr className='tw-m-0 tw-h-px tw-border-0 tw-bg-grey-100' />

      <div className='popup__body-form p-b-0 m-b-32'>
        <div className='form-container'>
          <label
            className='field__label tw-font-medium'
            htmlFor='requiresEvidence'
          >
            Evidence
            <Tooltip
              placement='top'
              tooltip='E.g. certificates or cards'
              trigger='hover'
            >
              <CircleQuestion />
            </Tooltip>
          </label>
          <RadioField
            checked={currentCourse.requiresEvidence}
            label={'Requires evidence'}
            name='requiresEvidence'
            onChange={(event) => {
              onInputChange(event, convertValue);
            }}
            value={true}
          />
          <RadioField
            checked={!currentCourse.requiresEvidence}
            label={'Does not require evidence'}
            name='requiresEvidence'
            onChange={(event) => {
              onInputChange(event, convertValue);
            }}
            value={false}
          />
        </div>
      </div>

      <hr className='tw-m-0 tw-h-px tw-border-0 tw-bg-grey-100' />

      <div className='popup__body-form p-b-0 m-b-32'>
        <div className='form-container'>
          <CollectionSelect
            externalProps={{
              additionalNoOptionsMessage:
                'New company roles must be added from the course register.',
            }}
            label='Roles for this course'
            modifiers={['expandable']}
            name='companyRoleIds'
            onChange={(e) => onCourseRolesOptionChange(e.value.toString())}
            options={resourcesAsOptions({
              resourcesCollection: selectableRoles,
              resourceIdentifier: 'position',
            })}
            placeholder='Search roles...'
            value={null}
          />

          {currentActor.isAllowedFeature('training_register') && (
            <>
              <div className='popup__body-form p-0 m-b-32'>
                <div className='form-container'>
                  {orderedCourseCompanyRoles.map((courseCompanyRole) => {
                    return (
                      <div
                        className='tw-group/pill chip-item-pill w-100 py-10 px-12 m-b-8 tw-relative tw-flex tw-items-center tw-justify-between tw-rounded-md tw-bg-grey-050'
                        key={courseCompanyRole.roleId}
                      >
                        <div className='tw-text-s tw-font-medium tw-tracking-wide'>
                          {courseCompanyRole.rolePosition}
                        </div>

                        <div className='tw-flex tw-items-center'>
                          <div className='tw-w-28'>
                            <CollectionSelect
                              isSearchable={false}
                              modifiers={['pill']}
                              name='requiredCCR'
                              onChange={(e) =>
                                onCourseCompanyRoleRequiredChange(
                                  courseCompanyRole.roleId,
                                  e.value,
                                )
                              }
                              options={[
                                { value: true, label: 'Required' },
                                { value: false, label: 'Optional' },
                              ]}
                              value={
                                courseCompanyRole.required ?
                                  { value: true, label: 'Required' }
                                : { value: false, label: 'Optional' }
                              }
                            />
                          </div>
                          <span
                            className="collection-select__multivalue-remove m-l-8 circle--remove tw-bg-transparent before:tw-bg-grey-700 before:tw-content-[''] after:tw-bg-grey-700 after:tw-content-[''] hover:tw-bg-red-600 hover:before:tw-bg-white hover:after:tw-bg-white"
                            onClick={() =>
                              onCourseCompanyRoleDelete(
                                courseCompanyRole.roleId,
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <hr className='tw-m-0 tw-h-px tw-border-0 tw-bg-grey-100' />

      <div className='popup__body-form p-t-40 m-b-32'>
        {isELearningCustom ?
          <>
            <label className='field__label tw-mb-4 tw-font-medium'>
              Custom eLearning
              <Tooltip
                className='tooltip-dark--max-w-xxs'
                placement='top'
                tooltip='Speak to your account manager to remove or make edits'
                trigger='hover'
              >
                <CircleQuestion />
              </Tooltip>
            </label>
            <CustomELearningCourseCard
              courseCost={displayCurrencyString({
                string: eLearningCourseCost,
                currencyCode,
              })}
              courseDuration={eLearningCourse.attributes.duration}
              courseName={currentCourse.name}
              courseProvider={currentCourse.provider}
            />
            {currentActor.isAllowedFeature([
              'e_learning_auto_enrol',
              'e_learning',
              'training_register',
            ]) && (
              <CourseSidePanelAutoEnrolmentForm
                autoEnrolCourseRequirementScope={
                  currentCourse.autoEnrolCourseRequirementScope
                }
                autoEnrolEnrolleeScope={currentCourse.autoEnrolEnrolleeScope}
                hasAutoEnrolBeenActivelySelected={
                  currentCourse.hasAutoEnrolBeenActivelySelected
                }
                isExpiringCourse={currentCourse.expires}
                onInputChange={onInputChange}
                onOptionChange={onOptionChange}
              />
            )}
          </>
        : <CourseSidePanelProviderForm
            bodyRef={bodyRef}
            course={currentCourse}
            onELearningProviderSelectedChange={
              onELearningProviderSelectedChange
            }
            onInputChange={onInputChange}
            onOptionChange={onOptionChange}
            removeErrorStyling={removeErrorStyling}
            requestError={requestError}
          />
        }
      </div>
    </React.Fragment>
  );
}

CourseSidePanelForm.propTypes = {
  currentCourse: PropTypes.object.isRequired,
  eLearningCourse: resourceShape('eLearningCourse'),
  requestError: PropTypes.object.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  onELearningProviderSelectedChange: PropTypes.func.isRequired,
  assignableRoles: PropTypes.array.isRequired,
  bodyRef: PropTypes.object.isRequired,
};
