import { enableAllPlugins } from 'immer';
enableAllPlugins();
import { produce } from 'immer';

export const initialValidationErrorsState = {};

export function mapValidationErrorsDispatch(dispatch) {
  return {
    handleValidationErrorsReset: () =>
      dispatch({ type: 'RESET_VALIDATION_ERRORS' }),
    handleValidationErrorsAssignment: (messages) =>
      dispatch({ type: 'ASSIGN_VALIDATION_ERRORS', messages }),
    handleValidationErrorsUnhighlight: (key) =>
      dispatch({ type: 'UNHIGHLIGHT_VALIDATION_ERROR', key }),
  };
}

function handleValidationErrorsReset({ draftState }) {
  for (const key in draftState) delete draftState[key];
}

function handleValidationErrorsAssignment({ draftState, messages }) {
  Object.keys(messages).forEach((key) => {
    draftState[key] = { messages: messages[key], fieldHighlighted: true };
  });
}

function handleValidationErrorsUnhighlight({ draftState, key }) {
  if (draftState[key]) draftState[key].fieldHighlighted = false;
}

export default function reducer(state, action) {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'RESET_VALIDATION_ERRORS':
        handleValidationErrorsReset({ draftState });
        break;
      case 'ASSIGN_VALIDATION_ERRORS':
        handleValidationErrorsAssignment({
          draftState,
          messages: action.messages,
        });
        break;
      case 'UNHIGHLIGHT_VALIDATION_ERROR':
        handleValidationErrorsUnhighlight({ draftState, key: action.key });
        break;
      default:
        return state;
    }
  });
}
