import React, { useRef } from 'react';
import { focusField } from 'components/helpers/refs';
import PropTypes from 'prop-types';

import RolesShow from 'components/roles/RolesShow';
import RolesForm from 'components/roles/RolesForm';
import FormFooter from 'components/sidepanels/FormFooter';
import ShowFooter from 'components/sidepanels/ShowFooter';
import SidePanel from 'components/application/SidePanel';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import TrainingRegisterReportsSelector from 'components/trainingRegisterReports/TrainingRegisterReportsSelector';
import classNames from 'classnames';

export default function RolesSidePanel({
  allowShowFooterContents,
  assignableCourses,
  closeSidePanel,
  createRole,
  currentRole,
  onCourseCompanyRoleDelete,
  onCourseCompanyRoleRequiredChange,
  onDeleteClick,
  onRoleInputChange,
  onRoleOptionChange,
  removeErrorStyling,
  requestError,
  setSidePanelContext,
  sidePanelContext,
  sidePanelIsOpen,
  submitDisabled,
  updateRole,
}) {
  const openEditRole = () => {
    setSidePanelContext('edit');
  };
  const sidePanelFieldRef = useRef();
  const currentActor = useCurrentActor();
  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();
  const reportsTabViewable =
    currentActor.isAllowedFeature('training_register') &&
    trainingRegisterResourceManagementContext.hasPersonnelViewableAccess;

  let heading = '';
  switch (sidePanelContext) {
    case 'edit':
      heading = 'Edit Role';
      break;
    case 'new':
      heading = 'Add new role';
      break;
    case 'show':
      heading = 'Role';
      break;
  }

  const headingStyle =
    sidePanelContext === 'show' ?
      'tw-text-s tw-text-grey-500 tw-font-medium tw-tracking-wide'
    : 'tw-text-grey-900 tw-text-xl tw-font-semibold tw-tracking-tight';

  const titleHeaderContent = (
    <>
      <div className='popup__title popup__title--tertiary'>
        <h1 className={classNames(headingStyle)}>{heading}</h1>
      </div>
      {sidePanelContext === 'show' && (
        <div>
          <h2
            className={classNames(
              'tw-mt-2 tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-700',
              reportsTabViewable ? 'tw-mb-4' : 'tw-mb-0',
            )}
          >
            {currentRole.position}
          </h2>
          {reportsTabViewable && (
            <div>
              <TrainingRegisterReportsSelector
                filter={{ roleId: { eq: currentRole.id } }}
              />
            </div>
          )}
        </div>
      )}
      {sidePanelContext === 'edit' &&
        trainingRegisterResourceManagementContext.isMultiDivisionAccount && (
          <p className='m-0 m-t-20 tw-rounded-lg tw-border-0 tw-bg-amber-025 tw-p-3 tw-text-amber-800'>
            Changes could affect divisions and personnel you do not have access
            to.
          </p>
        )}
    </>
  );

  const showBodyContent = (
    <RolesShow
      assignableCourses={assignableCourses}
      currentRole={currentRole}
      sidePanelIsOpen={sidePanelIsOpen}
    />
  );

  const formBodyContent = (
    <RolesForm
      assignableCourses={assignableCourses}
      currentRole={currentRole}
      onCourseCompanyRoleDelete={onCourseCompanyRoleDelete}
      onCourseCompanyRoleRequiredChange={onCourseCompanyRoleRequiredChange}
      onInputChange={onRoleInputChange}
      onOptionChange={onRoleOptionChange}
      removeErrorStyling={removeErrorStyling}
      requestError={requestError}
      sidePanelFieldRef={sidePanelFieldRef}
      submitDisabled={submitDisabled}
    />
  );

  const newFooterContent = (
    <FormFooter
      onCancel={closeSidePanel}
      onSubmit={createRole}
      submitButtonDisabled={submitDisabled}
      text='Add role'
    />
  );

  const showFooterContent = allowShowFooterContents && (
    <ShowFooter
      deleteDisabled={currentRole.isDeleteProhibited}
      deleteDisabledTooltip='Roles with personnel cannot be deleted'
      onDeleteClick={onDeleteClick}
      onEditClick={openEditRole}
      requestError={requestError}
      resource={currentRole.position}
      type='role'
    />
  );

  const editFooterContent = (
    <FormFooter
      onCancel={closeSidePanel}
      onSubmit={updateRole}
      submitButtonDisabled={submitDisabled}
      text='Save changes'
    />
  );

  const footerContent = function () {
    switch (sidePanelContext) {
      case 'show':
        return showFooterContent;
      case 'edit':
        return editFooterContent;
      case 'new':
        return newFooterContent;
    }
  };

  const onBackdropClick = () => {
    const panelLocked = ['new', 'edit'].includes(sidePanelContext);
    if (!panelLocked) {
      closeSidePanel();
    }
  };

  return (
    <SidePanel
      bodyContent={
        sidePanelContext === 'show' ? showBodyContent : formBodyContent
      }
      closeCallback={closeSidePanel}
      color={'tertiary'}
      contentContext={sidePanelContext}
      displayClose={sidePanelContext === 'show'}
      footerContent={footerContent()}
      headerContent={titleHeaderContent}
      isOpen={sidePanelIsOpen}
      onBackdropClick={onBackdropClick}
      onOpen={() => sidePanelContext === 'new' && focusField(sidePanelFieldRef)}
      requestError={requestError}
      scrollHeader={false}
      submitDisabled={submitDisabled}
    />
  );
}

RolesSidePanel.propTypes = {
  setSidePanelContext: PropTypes.func.isRequired,
  sidePanelContext: PropTypes.string.isRequired,
  sidePanelIsOpen: PropTypes.bool.isRequired,
  closeSidePanel: PropTypes.func.isRequired,
};
