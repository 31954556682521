import React from 'react';
import PropTypes from 'prop-types';

const CROP_AREA_ASPECT = 1;

export default function PreviewCroppedProfilePhoto({
  croppedArea,
  imagePath,
  mediaSize,
  rotation,
}) {
  const scale = 100 / croppedArea.width;
  const translateX = `${-croppedArea.x * scale}%`;
  const translateY = `${-croppedArea.y * scale}%`;
  const ratio = (mediaSize.width / mediaSize.height) * 100;

  let height = 'auto';
  let width = '100%';
  let paddingTop = '0';
  let paddingLeft = '0';
  if (rotation % 360 === 90) {
    height = `${ratio}%`;
    width = `${ratio}%`;
  } else if (rotation % 360 === 270) {
    height = `${ratio}%`;
    if (ratio > 100) {
      // landscape
      // needs padding top to move it down
      // this is due to the rotation not being applied to the translate div
      width = `${ratio}%`;
      paddingTop = `${ratio - 100}%`;
    } else if (ratio < 100) {
      // portrait
      width = '100%';
      paddingLeft = `${100 - ratio}%`;
    }
  }

  return (
    <div className='tw-h-16 tw-w-16 tw-overflow-hidden tw-rounded-full'>
      <div
        className='tw-relative tw-overflow-hidden tw-rounded-full'
        style={{ paddingBottom: `${100 / CROP_AREA_ASPECT}%` }}
      >
        <div
          className='tw-absolute tw-left-0 tw-top-0 tw-origin-top-left'
          style={{
            transform: `translate3d(${translateX}, ${translateY}, 0) scale3d(${scale}, ${scale}, 1)`,
            height: height,
            paddingTop: paddingTop,
          }}
        >
          <div
            style={{
              transform: `rotate(${rotation}deg)`,
              transformOrigin: 'center',
            }}
          >
            <img
              src={imagePath}
              style={{ width: width, paddingLeft: paddingLeft }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

PreviewCroppedProfilePhoto.propTypes = {
  croppedArea: PropTypes.object,
  imagePath: PropTypes.string,
  rotation: PropTypes.number,
  mediaSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
};
