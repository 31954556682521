import React from 'react';
import PropTypes from 'prop-types';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useBreadBoard } from 'components/contexts/Toaster';
import RowBar from 'components/application/RowBar';
import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';
import ToggleableSelect from 'components/selectors/ToggleableSelect';
import GreyPlus from '-!svg-react-loader?name=GreyPlus!icons/add-grey.svg';
import AddButton from 'components/buttons/AddButton';

import { isCourseAssociated } from 'components/helpers/resources/courses';
import { sendAnalytics } from 'components/helpers/analytics';

export default function AdditionalTrainingBar(props) {
  const currentActor = useCurrentActor();
  const breadBoard = useBreadBoard();

  const { actionButtonsVisible, personnelId, roles, training, userCourses } =
    props;

  const handleChange = (selected) => {
    props.onCourseSelectChange({ selected });
  };

  const trainingTitle =
    props.roleHasRequiredCourses ? 'Additional training' : 'Training';

  const content =
    props.additionalCoursesCount > 0 ?
      <React.Fragment>
        <span className='m-r-8 tw-text-l tw-font-semibold'>
          {trainingTitle}
        </span>
        <Tooltip
          placement='top'
          tooltip='Training not specific to the role'
          trigger='hover'
        >
          <CircleQuestion />
        </Tooltip>
      </React.Fragment>
    : <div className='flex flex--vertically-centered m-l--4'>
        <GreyPlus className='[&_rect]:tw-fill-grey-300' />
        <span className='m-l-12'>{trainingTitle} can be added here</span>
      </div>;

  const disabledBtn = actionButtonsVisible && (
    <AddButton
      disabled={true}
      text='Add training course'
      tooltipText={
        !props.isLoading &&
        'Courses need to be added in the course register first'
      }
    />
  );

  const addCourseToggle = actionButtonsVisible && (
    <ToggleableSelect
      additionalNoOptionsMessage='New courses must be added from the course register.'
      buttonText='Add training course'
      isOptionDisabled={(course) =>
        isCourseAssociated(course, { training, roles, userCourses })
      }
      name='course'
      onChange={handleChange}
      onError={breadBoard.addInedibleToast}
      onToggleablePrimaryClick={() =>
        sendAnalytics('Add training course clicked', {
          currentUser: currentActor.user,
          personnelId: personnelId,
        })
      }
      placeholder='Search courses'
      url='/account_courses'
    />
  );

  return (
    <RowBar
      actions={
        props.coursesMeta.info.totalCount > 0 && !props.isLoading ?
          addCourseToggle
        : disabledBtn
      }
      content={!props.isLoading && content}
      modifiers={
        !props.isLoading && props.additionalCoursesCount > 0 ?
          ['large', 'border-bottom-none']
        : ['large']
      }
    />
  );
}

AdditionalTrainingBar.propTypes = {
  training: PropTypes.array.isRequired,
  userCourses: PropTypes.array.isRequired,
  courses: PropTypes.object.isRequired,
  onCourseSelectChange: PropTypes.func.isRequired,
  onCourseSelectError: PropTypes.func.isRequired,
  roleHasRequiredCourses: PropTypes.bool.isRequired,
};

AdditionalTrainingBar.defaultProps = {
  training: [],
  userCourses: [],
  courses: {},
  roles: [],
  isLoading: false,
  roleHasRequiredCourses: true,
  actionButtonsVisible: true,
};
