import React from 'react';
import ErrorMessage from 'components/application/ErrorMessage';
import TextField from 'components/application/TextField';

export default function SubcontractorsForm(props) {
  return (
    <div className='popup__body-form'>
      <ErrorMessage
        isFallback={props.requestError.isFallback}
        validationErrors={props.requestError.validationErrors}
        wrapperClassName='form-container'
      />
      <div className='form-container'>
        <TextField
          autoFocus={true}
          fieldError={props.requestError.validationErrors.name}
          inputRef={props.sidePanelFieldRef}
          isRequired={true}
          label='Sub-contractor company'
          name='name'
          onChange={props.onSubcontractorInputChange}
          removeErrorStyling={props.removeErrorStyling}
          value={props.currentSubcontractor.name}
        />
      </div>
    </div>
  );
}
