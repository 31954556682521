import React, { useState, useRef } from 'react';
import { focusField } from 'components/helpers/refs';
import PropTypes from 'prop-types';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { resourceShape } from 'components/helpers/serialisableResources';
import PersonnelForm from 'components/personnel/PersonnelForm';
import PersonnelShow from 'components/personnel/PersonnelShow';
import PersonnelSidePanelTabs from 'components/personnel/PersonnelSidePanelTabs';
import FormFooter from 'components/sidepanels/FormFooter';
import ShowFooter from 'components/sidepanels/ShowFooter';
import SidePanel from 'components/application/SidePanel';
import ArchiveButton from 'components/archive/ArchiveButton';
import ProfilePhotoDisplay from 'components/application/ProfilePhotoDisplay';

export default function PersonnelSidePanel({
  allowShowFooterContents,
  assignableCompanies,
  assignableRoles,
  availableFieldOptions,
  availableFields,
  closeSidePanel,
  createPersonnel = () => void 0,
  currentPersonnel,
  currentSubcontractor,
  division,
  domainProfilePhoto,
  isProfilePhotoLoading,
  isSubcontractor,
  lineManager,
  lineManagers,
  onArchiveButtonClick,
  onAssignableRoleAdd,
  onCancel,
  onCompanyRolesOptionChange,
  onDateFieldChange,
  onDeleteClick,
  onEditClick,
  onFieldOptionChange,
  onNewCompanyRoleAdd,
  onPersonnelDateChange,
  onPersonnelFieldChange,
  onPersonnelInputChange,
  onPersonnelOptionChange,
  onPersonnelUpdateSubmit,
  onPhotoModalOpen,
  onRemoveRole,
  onSelectPrimary,
  onSubcontractorSelect,
  onToggle,
  personnel,
  removeErrorStyling,
  requestError,
  roles,
  setCurrentPersonnel,
  setIsSubcontractor,
  sidePanelContext,
  sidePanelIsOpen,
  subcontractor,
  submitDisabled,
  teams,
  user,
}) {
  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();
  const [scrolledAmount, setScrolledAmount] = useState(0);
  const sidePanelBodyRef = useRef();
  const sidePanelFieldRef = useRef();

  const iconHeaderContent = (
    <div class='tw-border-4 tw-absolute tw-left-1/2 tw-top-full tw--translate-x-1/2 tw--translate-y-1/2 tw-transform tw-rounded-full tw-border-solid tw-border-white'>
      <ProfilePhotoDisplay
        componentSize='lg'
        photoUrl={domainProfilePhoto?.links?.croppedUrl}
        profileInitials={`${currentPersonnel.firstName[0]}${currentPersonnel.lastName[0]}`}
      />
    </div>
  );

  const titleHeaderContent = (
    <div className='popup__title popup__title--tertiary'>
      <h1 className='tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-900'>
        {sidePanelContext === 'edit' ? 'Edit personnel' : 'Add new personnel'}
      </h1>
    </div>
  );

  const formBodyContent = (
    <PersonnelForm
      assignableCompanies={assignableCompanies}
      assignableRoles={assignableRoles}
      availableFieldOptions={availableFieldOptions}
      availableFields={availableFields}
      currentPersonnel={currentPersonnel}
      currentSubcontractor={currentSubcontractor}
      domainProfilePhoto={domainProfilePhoto}
      isCreatableCompanyRole={
        trainingRegisterResourceManagementContext.hasRoleEditableAccess
      }
      isEditableProfileAccess={
        trainingRegisterResourceManagementContext.hasProfileAccessEditableAccess
      }
      isProfilePhotoLoading={isProfilePhotoLoading}
      isSubcontractor={isSubcontractor}
      lineManagers={lineManagers}
      onAssignableRoleAdd={onAssignableRoleAdd}
      onCompanyRolesOptionChange={onCompanyRolesOptionChange}
      onDateFieldChange={onDateFieldChange}
      onFieldOptionChange={onFieldOptionChange}
      onNewCompanyRoleAdd={onNewCompanyRoleAdd}
      onPersonnelDateChange={onPersonnelDateChange}
      onPersonnelFieldChange={onPersonnelFieldChange}
      onPersonnelInputChange={onPersonnelInputChange}
      onPersonnelOptionChange={onPersonnelOptionChange}
      onPhotoModalOpen={onPhotoModalOpen}
      onRemoveRole={onRemoveRole}
      onSelectPrimary={onSelectPrimary}
      onSubcontractorSelect={onSubcontractorSelect}
      onToggle={onToggle}
      personnel={personnel}
      removeErrorStyling={removeErrorStyling}
      requestError={requestError}
      setCurrentPersonnel={setCurrentPersonnel}
      setIsSubcontractor={setIsSubcontractor}
      sidePanelBodyRef={sidePanelBodyRef}
      sidePanelContext={sidePanelContext}
      sidePanelFieldRef={sidePanelFieldRef}
      sidePanelIsOpen={sidePanelIsOpen}
      submitDisabled={submitDisabled}
      user={user}
    />
  );

  const showBodyContent = (
    <>
      <PersonnelShow
        assignableCompanies={assignableCompanies}
        availableFieldOptions={availableFieldOptions}
        availableFields={availableFields}
        currentPersonnel={currentPersonnel}
        division={division}
        personnel={personnel}
        roles={roles}
        subcontractor={subcontractor}
      />
      <hr className='tw-m-0 tw-h-px tw-border-0 tw-bg-grey-100' />
      <PersonnelSidePanelTabs
        availableFieldOptions={availableFieldOptions}
        availableFields={availableFields}
        currentPersonnel={currentPersonnel}
        defaultDetails={[
          { label: 'Personnel ID', text: currentPersonnel.externalId },
          { label: 'Email', text: currentPersonnel.email },
        ]}
        isCompact={false}
        key={`currentPersonnelId: ${currentPersonnel.id}`}
        lineManager={lineManager}
        sidePanelIsOpen={sidePanelIsOpen}
        teams={teams}
      />
    </>
  );

  const newFooterContent = (
    <FormFooter
      onCancel={onCancel || closeSidePanel}
      onSubmit={createPersonnel}
      submitButtonDisabled={submitDisabled || isProfilePhotoLoading}
      text='Add personnel'
    />
  );

  const showFooterContent = allowShowFooterContents && (
    <ShowFooter
      isCompact={true}
      isEditButtonHidden={currentPersonnel.isArchived}
      onDeleteClick={onDeleteClick}
      onEditClick={onEditClick}
      requestError={requestError}
      resource={currentPersonnel.firstName}
    >
      <ArchiveButton
        disabled={
          !currentPersonnel.isArchived &&
          currentPersonnel.isParticipatingInOngoingProjects
        }
        disabledTooltip="Personnel on live or future RAMS can't be archived"
        isArchived={currentPersonnel.isArchived}
        isCompact={true}
        onClick={onArchiveButtonClick}
      />
    </ShowFooter>
  );

  const editFooterContent = (
    <FormFooter
      onCancel={onCancel || closeSidePanel}
      onSubmit={onPersonnelUpdateSubmit}
      submitButtonDisabled={submitDisabled || isProfilePhotoLoading}
      text='Save changes'
    />
  );

  const footerContent = () => {
    switch (sidePanelContext) {
      case 'new':
        return newFooterContent;
      case 'show':
        return showFooterContent;
      case 'edit':
        return editFooterContent;
    }
  };

  const onBackdropClick = () => {
    const panelLocked = ['new', 'edit'].includes(sidePanelContext);
    if (!panelLocked) {
      closeSidePanel();
    }
  };

  return (
    <SidePanel
      bodyContent={
        sidePanelContext === 'show' ? showBodyContent : formBodyContent
      }
      bodyRef={sidePanelBodyRef}
      closeCallback={closeSidePanel}
      color={sidePanelContext === 'show' ? 'secondary' : 'tertiary'}
      contentContext={sidePanelContext}
      displayClose={sidePanelContext === 'show'}
      footerContent={footerContent()}
      headerContent={
        sidePanelContext === 'show' ? iconHeaderContent : titleHeaderContent
      }
      isOpen={sidePanelIsOpen}
      onBackdropClick={onBackdropClick}
      onOpen={() => sidePanelContext === 'new' && focusField(sidePanelFieldRef)}
      scrollHeader={sidePanelContext === 'show'}
      scrolledAmount={scrolledAmount}
      setScrolledAmount={setScrolledAmount}
      submitDisabled={submitDisabled}
    />
  );
}
PersonnelSidePanel.propTypes = {
  allowShowFooterContents: PropTypes.bool,
  availableFields: PropTypes.shape({
    loaded: PropTypes.bool,
    collection: PropTypes.array,
  }),
  availableFieldOptions: PropTypes.shape({
    loaded: PropTypes.bool,
    collection: PropTypes.array,
  }),
  assignableCompanies: PropTypes.arrayOf(resourceShape('assignableCompany')),
  assignableRoles: PropTypes.arrayOf(resourceShape('assignableCompanyRole')),
  closeSidePanel: PropTypes.func,
  createPersonnel: PropTypes.func,
  currentPersonnel: PropTypes.object,
  user: resourceShape('user'),
  currentSubcontractor: PropTypes.object,
  division: resourceShape(['company', 'assignableCompany']),
  domainProfilePhoto: PropTypes.object,
  isSubcontractor: PropTypes.bool,
  lineManager: resourceShape('lineManager', 'personnel'),
  lineManagers: PropTypes.arrayOf(resourceShape('lineManager')),
  onArchiveButtonClick: PropTypes.func,
  onCancel: PropTypes.func,
  onCompanyRolesOptionChange: PropTypes.func,
  onDateFieldChange: PropTypes.func,
  onToggle: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onFieldOptionChange: PropTypes.func,
  onAssignableRoleAdd: PropTypes.func,
  onNewCompanyRoleAdd: PropTypes.func,
  onPersonnelDateChange: PropTypes.func,
  onPersonnelFieldChange: PropTypes.func,
  onPersonnelInputChange: PropTypes.func,
  onPersonnelOptionChange: PropTypes.func,
  onRemoveRole: PropTypes.func,
  onSelectPrimary: PropTypes.func,
  personnel: resourceShape('personnel'),
  removeErrorStyling: PropTypes.func,
  requestError: PropTypes.object,
  roles: PropTypes.arrayOf(resourceShape('companyRole')),
  onPersonnelUpdateSubmit: PropTypes.func,
  setCurrentPersonnel: PropTypes.func,
  setIsSubcontractor: PropTypes.func,
  onSubcontractorSelect: PropTypes.func,
  sidePanelContext: PropTypes.oneOf(['new', 'show', 'edit']),
  sidePanelIsOpen: PropTypes.bool,
  subcontractor: resourceShape(['subcontractor', 'assignableCompany']),
  submitDisabled: PropTypes.bool,
  teams: PropTypes.arrayOf(resourceShape('team')),
  onPhotoModalOpen: PropTypes.func,
  isProfilePhotoLoading: PropTypes.bool,
};
