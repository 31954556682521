import React, { useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { toggleGrabbing } from 'components/helpers/document';
import { genericErrorMessage } from 'components/helpers/errors';

import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';

import AvailableProjectTradeList from 'containers/AvailableProjectTradeList';
import AvailableErrorList from 'containers/AvailableErrorList';
import SequenceOfOperationsFooter from 'components/project/SequenceOfOperationsFooter';
import classNames from 'classnames';

export default function SequenceOfOperationsPage({
  addError,
  anySorting,
  anyUnsaved,
  containerStyling,
  dispatch,
  isAvailable,
  isFetching,
  isReviewing,
  projectId,
}) {
  const containerRef = useRef();

  useEffect(() => {
    requestProjectTrades();
  }, []);

  useEffect(() => {
    toggleGrabbing(anySorting);
  }, [anySorting]);

  const requestProjectTrades = () => {
    axios
      .get(`/projects/${projectId}/project_trades`)
      .then((response) =>
        dispatch({
          type: 'LOAD_COLLECTION',
          payload: response.data,
          resourceType: 'projectTrade',
        }),
      )
      .catch(() => addError(uuidv4(), { fullMessage: genericErrorMessage }));
  };

  return (
    <div className={classNames('p-t-30', anySorting && 'no-user-select')}>
      <div ref={containerRef} style={containerStyling}>
        <h2 className='m-t-0 tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-900'>
          Sequence of operations
        </h2>
        <div className='m-0 m-b-24 tw-flex tw-rounded-lg tw-border-0 tw-bg-cyan-025 tw-p-3 tw-text-cyan-800'>
          <div className='fh-20 m-r-12'>
            <InfoIcon
              className='[&_path]:tw-fill-cyan-800'
              height={20}
              width={20}
            />
          </div>
          <p className='m-0'>
            Select the type of work you'll be carrying out, and add, re-order
            and de-select any tasks which are not relevant
          </p>
        </div>
        {!isFetching &&
          (isAvailable ?
            <div>
              <h3 className='tw-text-l tw-font-semibold tw-text-grey-900'>
                No sequence of operations available
              </h3>
              <p>
                You haven't selected any trades with associated sequence of
                operations. Go back and add a trade or click next to continue
                without sequence of operations.
              </p>
            </div>
          : <AvailableProjectTradeList containerRef={containerRef} />)}
        <div id='modalRoot'></div>
        <AvailableErrorList />
        <SequenceOfOperationsFooter
          anyUnsaved={anyUnsaved}
          isReviewing={isReviewing}
          projectId={projectId}
        />
      </div>
    </div>
  );
}
