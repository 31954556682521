import React, { useState, useEffect } from 'react';

import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import DocumentGeneratorService from 'components/helpers/DocumentGeneratorService';
import Polling from 'handshq-app/utilities/polling';

import OutlinedButton from 'components/application/buttons/OutlinedButton';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import CheckboxField from 'components/application/CheckboxField';
import ActionModal from 'components/application/ActionModal';
import ActionModalContent from 'components/application/ActionModalContent';

import LoadingIcon from '-!svg-react-loader?name=LoadingIcon!icons/loading.svg';
import ErrorIcon from '-!svg-react-loader?name=ErrorIcon!icons/ic-error-large.svg';
import DownloadIcon from '-!svg-react-loader?name=DownloadIcon!icons/ic-download.svg';

export default function PersonnelTrainingDownload(props) {
  const { trainingReportPermittedOptions } = useTrainingRegisterResources();

  let generator;

  const generateUrl = `/personnel/${props.personnelId}/training_report_pdfs`;
  const evidenceStatusUrl = `/personnel/${props.personnelId}/training_evidence_status`;
  const defaultReportOptions = trainingReportPermittedOptions.reduce(
    (acc, option) => {
      acc[option] = false;
      return acc;
    },
    {},
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reportIsGenerating, setReportIsGenerating] = useState(false);
  const [reportOptions, setReportOptions] = useState(defaultReportOptions);
  const [reportHasErrored, setReportHasErrored] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    if (!reportIsGenerating || reportHasErrored) {
      reset();
    }
  };

  const reset = () => {
    setReportIsGenerating(false);
    setReportHasErrored(false);
    setReportOptions(defaultReportOptions);
  };

  useEffect(() => {
    if (!props.trainingEvidenceProcessed) {
      evidenceStatusPoller.start();
    }
    return () => {
      if (evidenceStatusPoller) {
        evidenceStatusPoller.end();
      }
    };
  }, [props.trainingEvidenceProcessed]);

  const evidenceStatusPoller = new Polling({
    interval: 1000,
    url: evidenceStatusUrl,
    responseCheck: function (response) {
      return response.data.processed;
    },
    successCallback: () => {
      props.onTrainingEvidenceProcessed();
    },
    failureCallback: () => {
      console.error('could not load attachments status');
    },
  });

  const handleChange = (event) => {
    const option = event.target.value;
    setReportOptions({ ...reportOptions, [option]: event.target.checked });
  };

  const checkboxProps = {
    height: 'short',
    onChange: handleChange,
    className: 'action-modal__form-checkbox',
  };

  const handleDownload = () => {
    generateReport(reportOptions);
    setReportIsGenerating(true);
  };

  const generateReport = (options) => {
    const generateParams = {
      ...options,
      personnel_id: props.personnelId,
      company_role_id: props.roleId,
    };

    generator = new DocumentGeneratorService({
      generateUrl,
      generateParams,
      successCallback,
      failureCallback,
    });
    generator.start();
  };

  const successCallback = (docUrl) => {
    closeModal();
    window.location.href = docUrl;
  };

  const failureCallback = () => {
    setReportHasErrored(true);
  };

  const formContent = (
    <div className='action-modal__box tw-min-h-fit tw-bg-white'>
      <section className='action-modal__header truncated-text-container tw-border-grey-100 tw-text-l tw-font-semibold tw-text-grey-900'>
        Download Training Report
      </section>
      <section className='action-modal__form-body tw-min-h-fit tw-border-grey-100'>
        Select any additional content you want in the report:
        {trainingReportPermittedOptions.includes('notes') && (
          <CheckboxField
            {...checkboxProps}
            checked={reportOptions.notes}
            label='Include notes'
            labelTextProps={{ className: 'tw-font-medium' }}
            name='notes'
            value='notes'
          />
        )}
        {trainingReportPermittedOptions.includes('evidence') && (
          <CheckboxField
            {...checkboxProps}
            checked={reportOptions.evidence}
            label='Include evidence'
            labelTextProps={{ className: 'tw-font-medium' }}
            name='evidence'
            value='evidence'
          />
        )}
        {trainingReportPermittedOptions.includes('history') && (
          <CheckboxField
            {...checkboxProps}
            checked={reportOptions.history}
            label='Include training history'
            labelProps={{ className: 'hhq-checkbox__label--w-auto' }}
            labelTextProps={{ className: 'tw-font-medium' }}
            name='history'
            tooltip='All previously recorded training is included for each course. Evidence is only included on the most recent training.'
            value='history'
          />
        )}
      </section>
      <section className='action-modal__form-footer'>
        <TextButton
          className='m-r-12'
          color='blue'
          onClick={closeModal}
          size='md'
        >
          Cancel
        </TextButton>
        <FilledButton color='mint' onClick={handleDownload}>
          Download now
        </FilledButton>
      </section>
    </div>
  );

  return (
    <>
      <OutlinedButton
        color='grey'
        disabled={props.disabled}
        onClick={openModal}
        size='sm'
      >
        <DownloadIcon className='m-l--8' height={24} width={24} />
        <span>Download training report</span>
      </OutlinedButton>
      {modalIsOpen && (
        <ActionModal
          closeModal={closeModal}
          closeable={true}
          isOpen={modalIsOpen}
        >
          {reportIsGenerating ?
            <ActionModalContent
              bodyIcon={
                reportHasErrored ?
                  <ErrorIcon
                    className='[&_path]:tw-fill-red-600'
                    height={48}
                    width={48}
                  />
                : <LoadingIcon height={48} width={48} />
              }
              bodyText={
                reportHasErrored ?
                  'Please try again or contact support for help'
                : 'This may take a few minutes. When it is finished, your report will automatically download so you can close this modal while you wait.'
              }
              bodyTitle={
                reportHasErrored ? 'Report not generated' : 'Generating report'
              }
              closeModal={closeModal}
              closeable={true}
              headerText='Download Training Report'
              iconStyling='tw-apply-loading-spinner--blue-light'
            />
          : formContent}
        </ActionModal>
      )}
    </>
  );
}
