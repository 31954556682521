import React from 'react';
import DatePickerField from 'components/application/DatePickerField';
import TextField from 'components/application/TextField';
import TextArea from 'components/application/TextArea';
import CollectionSelect from 'components/application/CollectionSelect';
import { findCurrentFieldValue } from 'components/helpers/personnel';
import voca from 'voca';

export default function PersonnelCustomFieldsForm(props) {
  const {
    availableFieldOptions,
    availableFields,
    currentPersonnel,
    onDateFieldChange,
    onFieldOptionChange,
    onPersonnelFieldChange,
    removeErrorStyling,
    requestError,
  } = props;

  const findFieldOptions = (field) => {
    const options = availableFieldOptions.collection.filter(
      (option) => option.relationships.fieldAttribute.data.id === field.id,
    );
    return options.map((option) => {
      return {
        value: {
          optionId: option.id,
        },
        label: option.attributes.value,
      };
    });
  };

  function findCurrentSelectFieldValue(fieldAttribute) {
    const currentValue = currentPersonnel.fieldValues[fieldAttribute.id];

    if (!currentValue) {
      return;
    } else if (currentValue.fieldOptionId) {
      const option = availableFieldOptions.collection.find(
        (option) => option.id === currentValue.fieldOptionId,
      );
      return { value: { optionId: option.id }, label: option.attributes.value };
    }
  }

  function findCurrentDateFieldValue(fieldAttribute) {
    const currentValue = currentPersonnel.fieldValues[fieldAttribute.id];

    if (!currentValue) {
      return;
    } else {
      return currentValue.value;
    }
  }

  const displayField = (field) => {
    const fieldProps = {
      label: field.attributes.name,
      name: field.id,
      value:
        findCurrentFieldValue(field, currentPersonnel, availableFieldOptions) ||
        '',
      onChange: onPersonnelFieldChange,
    };

    switch (field.attributes.formattedFieldType) {
      case 'singleLineText':
        return <TextField key={field.id} {...fieldProps} />;
      case 'multiLineText':
        return <TextArea key={field.id} {...fieldProps} />;
      case 'email':
        return (
          <TextField
            key={field.id}
            {...fieldProps}
            fieldError={
              requestError.validationErrors[
                voca.camelCase(field.attributes.name)
              ]
            }
            removeErrorStyling={() => {
              removeErrorStyling({
                target: { name: voca.camelCase(field.attributes.name) },
              });
            }}
          />
        );
      case 'date':
        return (
          <DatePickerField
            key={field.id}
            {...fieldProps}
            onChange={onDateFieldChange}
            value={findCurrentDateFieldValue(field)}
          />
        );
      case 'select':
        return (
          <CollectionSelect
            key={field.id}
            {...fieldProps}
            isClearable={true}
            onChange={onFieldOptionChange}
            options={findFieldOptions(field)}
            value={findCurrentSelectFieldValue(field)}
          />
        );
    }
  };

  return (
    <>
      {availableFields.loaded &&
        availableFields.collection.map((field) => displayField(field))}
    </>
  );
}
