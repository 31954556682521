import React from 'react';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import AddIcon from '-!svg-react-loader?name=AddIcon!icons/add.svg';
import Tooltip from 'components/application/Tooltip';

export default function AddButton(props) {
  const btnContents = (
    <React.Fragment>
      <AddIcon className='m-l--8' height={24} width={24} />
      <span>{props.text}</span>
    </React.Fragment>
  );

  return (
    <div className='tooltip-parent'>
      <OutlinedButton
        color='grey'
        disabled={props.disabled}
        onClick={props.onClick ? props.onClick : () => void 0}
        size='sm'
      >
        {btnContents}
      </OutlinedButton>
      {props.tooltipText && (
        <Tooltip placement='top' tooltip={props.tooltipText} trigger='hover' />
      )}
    </div>
  );
}

AddButton.defaultProps = {
  customStyleClasses: '',
};
