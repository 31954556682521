import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DatePickerField from 'components/application/DatePickerField';
import moment from 'moment';
import TextArea from 'components/application/TextArea';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import { resourceShape } from 'components/helpers/serialisableResources';

import { isExpiryDateAfterRenewalDate } from 'components/helpers/resources/training';

export default function TrainingFormFields({
  currentTraining,
  domainCourse,
  notesExplanatoryMessage,
  onInputChange,
  onTrainingCalendarClose,
  onTrainingDateChange,
  removeErrorStyling,
  requestError,
  submitDisabled,
}) {
  const courseInterval = `${domainCourse?.attributes?.renewalInterval}`;
  const courseIntervalS = `${domainCourse?.attributes?.renewalFrequency > 1 ? 's' : ''}`;
  const courseExpires = domainCourse?.attributes?.expires;

  return (
    <Fragment>
      {courseExpires && (
        <div className='m-b-24 tw-flex tw-rounded-lg tw-border-0 tw-bg-cyan-025 tw-p-3 tw-text-cyan-800'>
          <div className='fh-20 m-r-12'>
            <InfoIcon
              className='[&_path]:tw-fill-cyan-800'
              height={20}
              width={20}
            />
          </div>
          <p className='m-0'>
            Fill in <span className='tw-font-semibold'>either date</span> and
            the other will be automatically filled in based off a renewal
            frequency of{' '}
            <span className='tw-font-semibold'>
              {`${domainCourse?.attributes?.renewalFrequency} ${courseInterval}${courseIntervalS}`}
              .
            </span>
          </p>
        </div>
      )}
      <div className='flex flex--justify-content__space-between'>
        <div className='m-r-12'>
          <DatePickerField
            fieldError={requestError.validationErrors.startDate}
            isRequired={true}
            label='Valid from date'
            latestDate={moment().toDate()}
            name='startDate'
            onCalendarClose={onTrainingCalendarClose}
            onChange={onTrainingDateChange}
            removeErrorStyling={removeErrorStyling}
            submitDisabled={submitDisabled}
            value={currentTraining.startDate}
          />
        </div>
        <div>
          <DatePickerField
            disabledPlaceholderText='Does not expire'
            earliestDate={currentTraining.startDate}
            fieldError={requestError.validationErrors.expiryDate}
            isDisabled={!courseExpires}
            isRequired={courseExpires}
            label='Expiry date'
            name='expiryDate'
            onCalendarClose={onTrainingCalendarClose}
            onChange={onTrainingDateChange}
            placement='bottom-end'
            removeErrorStyling={removeErrorStyling}
            submitDisabled={submitDisabled}
            value={currentTraining.expiryDate}
          />
        </div>
      </div>

      {isExpiryDateAfterRenewalDate({
        startDate: currentTraining.startDate,
        expiryDate: currentTraining.expiryDate,
        renewalFrequency: domainCourse?.attributes?.renewalFrequency,
        renewalInterval: domainCourse?.attributes?.renewalInterval,
      }) && (
        <div className='tw-mb-6 tw-flex tw-rounded-lg tw-border-0 tw-bg-amber-025 tw-p-3 tw-text-amber-800'>
          <div className='tw-mr-3 tw-flex tw-h-5 tw-w-4 tw-items-center'>
            <InfoIcon
              className='[&_path]:tw-fill-amber-800'
              height={16}
              width={16}
            />
          </div>
          Selected dates exceed the recommended renewal frequency set for this
          course.
        </div>
      )}

      <TextArea
        additionalText={notesExplanatoryMessage}
        fieldError={requestError.validationErrors.notes}
        label='Notes'
        name='notes'
        onChange={onInputChange}
        value={currentTraining.notes}
      />
    </Fragment>
  );
}

TrainingFormFields.propTypes = {
  currentTraining: PropTypes.object,
  domainCourse: resourceShape('course'),
  requestError: PropTypes.object,
  onInputChange: PropTypes.func.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
  onTrainingDateChange: PropTypes.func.isRequired,
  onTrainingCalendarClose: PropTypes.func.isRequired,
  notesExplanatoryMessage: PropTypes.string,
};
