import tailwindConfig from 'stylesheets/tailwind.config.js';

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import voca from 'voca';
import qs from 'qs';

import { BarChart, XAxis, YAxis, Bar, Cell, Label, LabelList } from 'recharts';

import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { useRamsAnalyticsDashboardContext } from 'components/contexts/RamsAnalyticsDashboardContext';
import { useBreadBoard } from 'components/contexts/Toaster';
import useFilters from 'components/ramsAnalytics/hooks/useFilters';
import useKeyHighlight from 'components/ramsAnalytics/hooks/useKeyHighlight';
import useProjectsModal from 'components/ramsAnalytics/hooks/useProjectsModal';

import {
  filtersListFormatter,
  filtersFormatter,
} from 'components/ramsAnalytics/helpers/reportHeader';
import { formatPaginatedProjects } from 'components/ramsAnalytics/helpers/projectsModal';
import { getNextPageParam } from 'components/ramsAnalytics/helpers/reactQuery';

import Card from 'components/ramsAnalytics/components/Card';
import ReportHeader from 'components/ramsAnalytics/components/ReportHeader';
import ProjectsModal from 'components/ramsAnalytics/components/ProjectsModal';
import NoDataMessage from 'components/ramsAnalytics/components/NoDataMessage';

import Tooltip from 'components/application/Tooltip';

const colours = tailwindConfig.theme.colors;

export default function TopRisksCard() {
  const ramsAnalyticsDashboardContext = useRamsAnalyticsDashboardContext();
  const breadBoard = useBreadBoard();
  const { filters, handleFilterChange } = useFilters({
    live: true,
    future: true,
    endedAndArchived: false,
  });

  const filterParams = Object.keys(filters).reduce((acc, filter) => {
    filter
      .split('And')
      .forEach(
        (subFilter) => (acc[voca.lowerCase(subFilter)] = filters[filter]),
      );
    return acc;
  }, {});

  const fetchTopRisksReport = () =>
    axios.post('/rams_analytics/top_risks_report', {
      top_risks_report: filterParams,
    });

  const { data: topRisksReport } = useQuery({
    queryKey: ['topRisksReport', filterParams],
    queryFn: fetchTopRisksReport,
    keepPreviousData: true,
    onError: breadBoard.addInedibleToast,
  });

  const {
    countBreakdown = {},
    endedAndArchivedFilterCount,
    futureFilterCount,
    liveFilterCount,
  } = topRisksReport ? topRisksReport.data.data.attributes : {};

  const filterCounts = {
    live: liveFilterCount,
    future: futureFilterCount,
    endedAndArchived: endedAndArchivedFilterCount,
  };
  const filterDots = {
    live: 'green',
    future: 'amber',
    endedAndArchived: 'grey',
  };

  const chartProps = useMemo(() => {
    const data = Object.keys(countBreakdown).map((risk) => ({
      name: risk,
      count: countBreakdown[risk],
    }));
    for (let i = data.length; i < 5; i++)
      data.push({ name: null, count: null });

    const appliedFilters = Object.keys(filters).filter(
      (filter) => filters[filter],
    );
    const totalAppliedFilterCount = appliedFilters.reduce(
      (acc, filter) => acc + filterCounts[filter],
      0,
    );
    const xAxisDomain = [0, totalAppliedFilterCount];

    return { data, xAxisDomain };
  }, [JSON.stringify(topRisksReport)]);

  const { closeProjectsModal, openProjectsModal, projectsModal } =
    useProjectsModal();

  const fetchProjects = (page) => {
    const queryString = qs.stringify({
      filter: { ...filterParams, ...projectsModal.filters },
      page,
    });

    return axios.get(
      `/rams_analytics/top_risks_report/projects?${queryString}`,
    );
  };

  const {
    data: projects,
    fetchNextPage: handleFetchNextPageOfProjects,
    hasNextPage: hasNextPageOfProjects,
  } = useInfiniteQuery({
    queryKey: [
      'projects',
      'topRisksReport',
      { ...filterParams, ...projectsModal.filters },
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const projectsResponse = await fetchProjects(pageParam);
      return projectsResponse.data;
    },
    select: formatPaginatedProjects,
    getNextPageParam: getNextPageParam,
    enabled: projectsModal.isOpen,
    onError: breadBoard.addInedibleToast,
  });

  const handleSegmentClick = ({ entry }) => {
    openProjectsModal({
      title: `Top risks: ${entry.name}`,
      subTitle: ramsAnalyticsDashboardContext.pluralizeRamsCount({
        count: entry.count,
      }),
      appliedFilters: { activity_name: { eq: entry.name } },
    });

    ramsAnalyticsDashboardContext.sendAnalytics(
      'RAMS analytics: segment click',
      {
        chart: 'Top risks',
        segment: entry.name,
        filters: filters,
      },
    );
  };

  const handleProjectClick = ({ project }) => {
    ramsAnalyticsDashboardContext.sendAnalytics(
      'RAMS analytics: project click',
      {
        chart: 'Top risks',
        project: project.name,
        filters: filters,
      },
    );
  };

  return (
    <Card>
      <ReportHeader
        filters={filtersFormatter({ filters, filterCounts, filterDots })}
        minChecked={1}
        onFilterChange={handleFilterChange}
        subTitle={filtersListFormatter({
          filters,
          ramsPluralName: ramsAnalyticsDashboardContext.ramsPluralName,
        })}
        title='Top risks'
      />
      {topRisksReport &&
        (Object.keys(countBreakdown).length > 0 ?
          <ReportBody
            chartProps={chartProps}
            countBreakdown={countBreakdown}
            onSegmentClick={handleSegmentClick}
          />
        : <NoDataMessage message='Please check the applied filters' />)}
      <ProjectsModal
        closeModal={closeProjectsModal}
        hasNextPage={hasNextPageOfProjects}
        isOpen={projectsModal.isOpen}
        onFetchNextPage={handleFetchNextPageOfProjects}
        onProjectClick={handleProjectClick}
        projects={projects}
        subTitle={projectsModal.subTitle}
        title={projectsModal.title}
      />
    </Card>
  );
}

function ReportBody({ chartProps, countBreakdown, onSegmentClick }) {
  const ramsAnalyticsDashboardContext = useRamsAnalyticsDashboardContext();
  const { handleKeyHighlight, handleKeyUnhighlight, highlightedKey } =
    useKeyHighlight(null);

  const cellFill = (key) => {
    return highlightedKey && key !== highlightedKey ?
        colours.grey[100]
      : colours.amber[100];
  };

  return (
    <div className='tw-absolute tw-bottom-0 tw-left-0 tw-right-0 -tw-ml-1 -tw-mr-1 tw-pb-5 tw-pl-6 tw-pr-6'>
      <BarChart
        barSize={32}
        data={chartProps.data}
        height={212}
        layout='vertical'
        margin={{ top: 0, right: 6, left: 0, bottom: 0 }}
        onMouseLeave={handleKeyUnhighlight}
        // unhighlight when over axis (event exists only when over bar chart, not when over axis)
        onMouseMove={(event) => {
          if (!event && highlightedKey) handleKeyUnhighlight();
        }}
        width={522}
      >
        <XAxis
          axisLine={false}
          domain={chartProps.xAxisDomain}
          height={32}
          tick={false}
          tickLine={{ stroke: colours.grey[400], strokeWidth: 2 }}
          type='number'
        >
          <Label
            position='insideBottom'
            style={{
              fill: colours.grey[500],
              fontFamily: 'Inter',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
            }}
            value={`${voca.capitalize(ramsAnalyticsDashboardContext.ramsPluralName)} using risk assessment`}
          />
        </XAxis>
        <Bar background={{ fill: colours.grey['050'] }} dataKey='count'>
          {chartProps.data.map((entry) => (
            <Cell
              cursor={entry.count ? 'pointer' : 'default'}
              fill={cellFill(entry.name)}
              key={entry.name}
              onClick={() => onSegmentClick({ entry })}
              onMouseEnter={() => handleKeyHighlight(entry.name)}
            />
          ))}
          <LabelList
            content={(props) => (
              <CustomizedLabel {...props} countBreakdown={countBreakdown} />
            )}
            dataKey='name'
            position='right'
          />
        </Bar>
        <YAxis
          axisLine={{ stroke: colours.grey[400], strokeWidth: 1 }}
          tick={false}
          type='category'
          width={6}
        />
      </BarChart>
    </div>
  );
}

ReportBody.propTypes = {
  chartProps: PropTypes.shape({
    data: PropTypes.array.isRequired,
    xAxisDomain: PropTypes.array.isRequired,
  }),
  countBreakdown: PropTypes.object.isRequired,
  onSegmentClick: PropTypes.func.isRequired,
};

export function CustomizedLabel({ countBreakdown, height, value, x, y }) {
  return (
    <g className='tw-pointer-events-none'>
      <foreignObject height={height} width={510} x={x} y={y}>
        <div className='tw-flex tw-h-8 tw-items-center tw-px-3 tw-font-inter tw-text-s tw-font-medium tw-tracking-wide'>
          {value ?
            <>
              <div className='tooltip-parent tw-cursor-pointer tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-grey-700'>
                {value}
                {value.length > 64 && (
                  <Tooltip placement='top' tooltip={value} trigger='hover' />
                )}
              </div>
              <div className='tw-ml-3'>{countBreakdown[value]}</div>
            </>
          : <div className='tw-text-grey-400'>&ndash;</div>}
        </div>
      </foreignObject>
    </g>
  );
}

CustomizedLabel.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  value: PropTypes.string,
  countBreakdown: PropTypes.object.isRequired,
};
