import React from 'react';
import PropTypes from 'prop-types';

export const defaultContextValues = {
  user: {},
  division: {},
  account: {},
  subscription: {},
  features: [],
  isAllowedFeature: () => false,
};
export const isAllowedFeature = (features, featureKeys) => {
  const allowedFeatures = features.reduce((accumulated, feature) => {
    accumulated[feature.attributes.key] = true;
    return accumulated;
  }, {});

  if (Array.isArray(featureKeys)) {
    return featureKeys.every((featureKey) => !!allowedFeatures[featureKey]);
  }

  return !!allowedFeatures[featureKeys];
};

export const CurrentActorContext = React.createContext(defaultContextValues);

export default function CurrentActor({
  account,
  children,
  division,
  features,
  subscription,
  user,
}) {
  const values = {
    ...defaultContextValues,
    user,
    division,
    features,
    account,
    subscription,
    isAllowedFeature: (featureKeys) => isAllowedFeature(features, featureKeys),
  };

  return (
    <CurrentActorContext.Provider value={values}>
      {children}
    </CurrentActorContext.Provider>
  );
}

export const useCurrentActor = () => React.useContext(CurrentActorContext);

export const currentActorContextPropsStructure = {
  user: PropTypes.object.isRequired,
  division: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  features: PropTypes.array.isRequired,
};

CurrentActor.propTypes = currentActorContextPropsStructure;
