export default function useWindowStorageRemove(
  keys,
  callback,
  { store = window.localStorage } = {},
) {
  if (callback()) {
    keys.forEach((item) => {
      store.removeItem(item);
    });
  }
}
