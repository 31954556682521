import React from 'react';
import PropTypes from 'prop-types';
import { personDisplayName } from 'components/helpers/users';
import { resourceShape } from 'components/helpers/serialisableResources';
import Tooltip from 'components/application/Tooltip';
import classNames from 'classnames';

export default function PersonnelShow({
  division,
  isCompact = false,
  personnel,
  roles = [],
  subcontractor,
}) {
  return (
    <div className={classNames('popup__body-show', isCompact && 'p-b-0')}>
      <h2
        className={classNames(
          'popup__body-header tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-900',
          isCompact ? 'h-auto m-b-0' : (
            'popup__body-header--height-unset truncated-text-container--wrapped truncated-text-container--five-lines truncated-text-container--block mh-140'
          ),
        )}
      >
        {personDisplayName(personnel.attributes)}
      </h2>
      <h3 className='popup__body-header popup__body-header--sub truncated-text-container truncated-text-container--block tw-text-m tw-font-normal tw-tracking-auto'>
        {(subcontractor || division).attributes.name}
      </h3>
      <div
        className={classNames(
          'popup__body-content',
          isCompact ? 'p-t-4' : 'p-t-0',
        )}
      >
        {roles.length > 0 && (
          <ul
            className={classNames('pill-list', isCompact ? 'm-t-16' : 'm-t-24')}
          >
            {roles.map((role) => (
              <li className={'tooltip-parent'} key={role.id}>
                <span
                  className={classNames(
                    'pill tw-bg-grey-050',
                    role.attributes.position.length > 30 &&
                      'truncated-text-container',
                  )}
                >
                  {role.attributes.position}
                </span>
                {role.attributes.position.length > 30 ?
                  <Tooltip
                    placement='top'
                    tooltip={role.attributes.position}
                    trigger='hover'
                    type='dark'
                  />
                : ''}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

PersonnelShow.propTypes = {
  personnel: resourceShape('personnel').isRequired,
  division: resourceShape(['assignableCompany', 'company']),
  subcontractor: resourceShape(['assignableCompany', 'subcontractor']),
  roles: PropTypes.arrayOf(resourceShape('companyRole')),
  isCompact: PropTypes.bool,
};
