import React from 'react';
import PropTypes from 'prop-types';

export default function Modal({ children, closeModal, isOpen }) {
  const handleWrapperClick = (event) => {
    if (
      event.target.getAttribute('data-element-name') ===
      'mobile-modal-content-wrapper'
    )
      closeModal();
  };

  return (
    <div className={isOpen ? 'tw-visible' : 'tw-hidden'}>
      <div className='tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[999] tw-bg-grey-900 tw-opacity-75'></div>
      <div
        className='tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[9999] tw-flex tw-animate-fade-in-quick tw-items-center tw-justify-center'
        data-element-name='mobile-modal-content-wrapper'
        onClick={handleWrapperClick}
      >
        <div className='tw-w-full tw-max-w-3xl tw-p-6'>
          <div className='tw-rounded-2xl tw-bg-white'>{children}</div>
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
