import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ModalBox from 'components/application/ModalBox';
import Uploader from 'components/uploader/Uploader';
import Cropper from 'react-easy-crop';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import RangeField from 'components/application/RangeField';
import PreviewCroppedProfilePhoto from 'components/personnel/PreviewCroppedProfilePhoto';
import { useBreadBoard } from 'components/contexts/Toaster';

import RotatePhotoIcon from '-!svg-react-loader?name=RotatePhotoIcon!icons/rotate-photo.svg';
import RemovePhotoIcon from '-!svg-react-loader?name=RemovePhotoIcon!icons/remove-photo.svg';
import PhotoIcon from '-!svg-react-loader?name=PhotoIcon!icons/photo.svg';

export default function ProfilePhotoModal({
  currentPersonnel,
  domainProfilePhoto,
  onCropReady,
  onMediaLoaded,
  onPhotoModalClose,
  onPhotoRemoved,
  onProcessProfilePhoto,
}) {
  const breadBoard = useBreadBoard();

  // Internal states for the photo cropper
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [mediaSize, setMediaSize] = useState({ width: null, height: null });
  const [tempPhotoFile, setTempPhotoFile] = useState({
    file: null,
    path: null,
  });

  // External states to be returned when the image is cropped
  const rotation = currentPersonnel?.profilePhoto?.rotation || 0;
  const currentProfilePhoto = currentPersonnel?.profilePhoto;
  const croppedAreaPercentages = {
    width: currentProfilePhoto?.croppedAreaPercentageWidth,
    height: currentProfilePhoto?.croppedAreaPercentageHeight,
    x: currentProfilePhoto?.croppedAreaPercentageX,
    y: currentProfilePhoto?.croppedAreaPercentageY,
  };

  let initialCroppedAreaPixels = null;
  if (currentProfilePhoto && currentProfilePhoto.cropX) {
    initialCroppedAreaPixels = {
      x: currentProfilePhoto.cropX,
      y: currentProfilePhoto.cropY,
      width: currentProfilePhoto.cropDimension,
      height: currentProfilePhoto.cropDimension,
    };
  }

  const currentCroppablePhotoPath =
    tempPhotoFile?.path || domainProfilePhoto?.links?.originalUrl || null;

  return (
    <ModalBox
      customFooter={
        <div className='tw-flex tw-w-full tw-items-center tw-justify-end tw-space-x-3'>
          <TextButton color='blue' onClick={onPhotoModalClose} size='md'>
            Cancel
          </TextButton>

          <FilledButton
            color='mint'
            onClick={() => {
              onProcessProfilePhoto(tempPhotoFile.file);
            }}
            size='md'
          >
            Save photo
          </FilledButton>
        </div>
      }
      isOpen={true}
      mode='flexible'
      onClose={onPhotoModalClose}
      shouldCloseOnWrapperClick={false}
    >
      <div className='tw-p-6'>
        <div className='tw-mb-6 tw-flex tw-items-center tw-justify-between'>
          <h2 className='tw-m-0 tw-p-0 tw-text-xl tw-font-semibold tw-text-grey-900'>
            Edit photo for {currentPersonnel?.firstName}{' '}
            {currentPersonnel?.lastName}
          </h2>
          <div>
            {(
              (tempPhotoFile.file || currentCroppablePhotoPath) &&
              croppedAreaPercentages &&
              mediaSize.width
            ) ?
              <PreviewCroppedProfilePhoto
                croppedArea={croppedAreaPercentages}
                imagePath={currentCroppablePhotoPath}
                mediaSize={mediaSize}
                rotation={rotation}
              />
            : <div className='tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full tw-bg-blue-050'>
                <span className='tw-mt-0.5 tw-text-2xl tw-font-semibold tw-uppercase tw-text-grey-600'>
                  {currentPersonnel?.firstName[0]}
                  {currentPersonnel?.lastName[0]}
                </span>
              </div>
            }
          </div>
        </div>

        {currentCroppablePhotoPath ?
          <div className='tw-relative tw-flex tw-h-[300px] tw-w-full tw-justify-center tw-overflow-hidden tw-rounded-md'>
            <Cropper
              aspect={1}
              crop={crop}
              cropShape='round'
              image={currentCroppablePhotoPath}
              initialCroppedAreaPixels={initialCroppedAreaPixels}
              maxZoom={5}
              minZoom={1}
              onCropAreaChange={(croppedAreaPercentage, croppedAreaPixels) => {
                onCropReady({
                  croppedAreaPixels,
                  croppedAreaPercentage,
                  rotation,
                });
              }}
              onCropChange={setCrop}
              onMediaLoaded={(mediaSize) => {
                // set internally and externally to render both previews
                // - on the modal and on the side panel
                onMediaLoaded(mediaSize);
                setMediaSize(mediaSize);
              }}
              onZoomChange={setZoom}
              rotation={rotation}
              showGrid={false}
              style={{
                containerStyle: { height: '100%', width: '100%' },
                mediaStyle: { maxHeight: '100%', maxWidth: '100%' },
              }}
              zoom={zoom}
            />
          </div>
        : <Uploader
            centerPlaceholder={true}
            className='tw-relative tw-h-[300px]'
            externalInputProps={{ name: 'profilePhoto[file]' }}
            fileTypes={['png', 'jpg']}
            maxFileSize={5000000} // 5mb
            mode='solo'
            onError={breadBoard.addInedibleToast}
            onUpload={(file) => {
              const tempPath = URL.createObjectURL(file);
              const img = new Image();
              img.src = tempPath;
              img.onload = () => {
                if (img.width < 400 || img.height < 400) {
                  breadBoard.addInedibleToast({
                    fullMessage: 'Image must be at least 400x400 pixels',
                  });
                  return;
                }
                setTempPhotoFile({ file, path: tempPath });
              };
            }}
            showIcon={true}
          />
        }
        <div className='tw-mt-6 tw-flex tw-items-center'>
          <PhotoIcon className='tw-h-6 tw-w-6 tw-text-grey-100' />
          <RangeField
            disabled={!currentCroppablePhotoPath}
            label='Zoom'
            max={5}
            min={1}
            onChange={(e) => setZoom(e.target.value)}
            step={0.1}
            value={zoom}
          />
          <PhotoIcon className='tw-mr-6 tw-h-8 tw-w-8' />

          <a
            className={`tw-h-8 tw-cursor-pointer tw-rounded-lg hover:tw-bg-blue-025 tw-mr-3${!currentCroppablePhotoPath ? 'tw-bg-grey-050 tw-opacity-50' : ''}`}
            data-element-name='rotate-photo'
            onClick={() => {
              if (!currentCroppablePhotoPath) {
                return;
              }
              onCropReady({ rotation: rotation + 90 });
            }}
          >
            <RotatePhotoIcon />
          </a>

          <a
            className={`tw-h-8 tw-cursor-pointer hover:tw-bg-blue-025 tw-rounded-lg${!currentCroppablePhotoPath ? 'tw-bg-grey-050 tw-opacity-50' : ''}`}
            data-element-name='remove-photo'
            onClick={() => {
              if (!currentCroppablePhotoPath) {
                return;
              }
              onPhotoRemoved();
              setTempPhotoFile({ file: null, path: null });
            }}
          >
            <RemovePhotoIcon />
          </a>
        </div>
      </div>
    </ModalBox>
  );
}

ProfilePhotoModal.propTypes = {
  currentPersonnel: PropTypes.object,
  domainProfilePhoto: PropTypes.object,
  onProcessProfilePhoto: PropTypes.func.isRequired,
  onPhotoModalClose: PropTypes.func.isRequired,
  onCropReady: PropTypes.func.isRequired,
  onMediaLoaded: PropTypes.func.isRequired,
  onPhotoRemoved: PropTypes.func.isRequired,
};
