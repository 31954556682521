import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '-!svg-react-loader?name=SearchIcon!icons/search.svg';
import classNames from 'classnames';

export default function SearchField(props) {
  const inputRef = useRef();

  const handleKeyDown = (event) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      props.onReset(event);
      inputRef.current.blur();
    }
  };

  return (
    <div
      className={classNames(
        'search-field tw-border-grey-200 focus-within:tw-border-blue-300 hover:tw-border-grey-300 hover:focus-within:tw-border-blue-300',
        props.additionalClasses,
      )}
    >
      {!props.withoutIcon && (
        <span className='search-field__icon-container'>
          <SearchIcon
            className='search-field__icon [&_path]:tw-fill-grey-700'
            height={24}
            width={24}
          />
        </span>
      )}
      <input
        autoComplete='off'
        autoFocus={props.autoFocus}
        className='search-field__input tw-text-grey-900 placeholder:tw-text-grey-300'
        data-attr-name={props.name}
        name={props.name}
        onChange={props.onChange}
        onKeyDown={handleKeyDown}
        placeholder={props.placeholder}
        ref={inputRef}
        type='text'
        value={props.value}
      />
      <div className='search-field__clear-container'>
        {props.value.length > 0 && (
          <div
            className="search-field__clear circle--remove tw-bg-transparent before:tw-bg-grey-700 before:tw-content-[''] after:tw-bg-grey-700 after:tw-content-[''] hover:tw-bg-red-600 hover:before:tw-bg-white hover:after:tw-bg-white"
            data-attr-name={props.name}
            onClick={props.onReset}
          ></div>
        )}
      </div>
    </div>
  );
}

SearchField.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onReset: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  withoutIcon: PropTypes.bool,
  additionalClasses: PropTypes.string,
  autoFocus: PropTypes.bool,
};

SearchField.defaultProps = {
  autoFocus: false,
};
