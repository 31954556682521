import HandshqApp from '../handshq-app';

HandshqApp.Hazard = function (hazard) {
  const _this = this;

  this.checkbox = $(hazard).find('input:checkbox');
  this.project = this.checkbox.data('project');
  this.activity = this.checkbox.data('activity');
  this.hazard = $(this.checkbox).val();
  this.editButton = $(hazard).find('.edit-hazard');
  this.timer;

  this.checkbox.change(function () {
    // clearTimeout(_this.timer);
    const _checkbox = this;
    // _this.timer = setTimeout(function() {
    _this.toggle(_this.checkbox[0].checked);
    // }, 10);
  });

  this.toggle = function (checked) {
    checked ? _this.activate() : _this.deactivate();
  };

  this.toggleButton = function (enable) {
    if (enable) {
      _this.editButton.removeClass('disabled');
    } else {
      _this.editButton.addClass('disabled');
    }
  };

  this.activate = function () {
    _this.remoteUpdate({ active: true });
    _this.toggleButton(true);
  };

  this.deactivate = function () {
    _this.remoteUpdate({ active: false });
    _this.toggleButton(false);
  };

  this.remoteUpdate = function (opts) {
    $.ajax({
      url:
        '/projects/' +
        _this.project +
        '/activities/' +
        _this.activity +
        '/hazards/' +
        _this.hazard,
      type: 'PATCH',
      data: { hazard: opts },
      dataType: 'json',
    });
  };

  _this.toggleButton(this.checkbox.is(':checked'));
};
