import { useState } from 'react';

export default function useForm(defaultValues) {
  const [values, setValues] = useState(defaultValues);

  const handleInputChange = (event, valueModifier) => {
    const { checked, maxLength, name, type, value } = event.target;
    const slicedValue = maxLength > 0 ? value.slice(0, maxLength) : value;
    const modifierValue = valueModifier ? valueModifier(value) : slicedValue;
    const finalValue = type === 'checkbox' ? checked : modifierValue;
    setValues({ ...values, [name]: finalValue });
  };

  const selectedArray = (selected) =>
    selected ?
      selected.map((obj) => {
        return obj.value;
      })
    : [''];

  const selectedValue = (selected) => (selected ? selected.value : '');

  const handleOptionChange = (selected, actionMeta) => {
    const name = actionMeta.name;
    const value =
      Array.isArray(values[name]) ?
        selectedArray(selected)
      : selectedValue(selected);
    const newValues = { ...values, [name]: value };

    setValues(newValues);
  };

  const handleOptionSelect = (selected, actionMeta) => {
    const name = actionMeta.name;
    const value = selected ? selected : [''];
    const newValues = { ...values, [name]: value };

    setValues(newValues);
  };

  const handleElementToggle = (event) => {
    const target = event.target;

    const updatedArray = [...values[target.name]];
    const index = updatedArray.indexOf(target.value);
    const elementExistsInArray = index !== -1;

    if (target.checked) {
      if (!elementExistsInArray) updatedArray.push(target.value);
    } else {
      if (elementExistsInArray) updatedArray.splice(index, 1);
    }

    setValues({ ...values, [target.name]: updatedArray });
  };

  const handleDateChange = (name, date) => {
    setValues({ ...values, [name]: date });
  };

  return [
    values,
    setValues,
    handleInputChange,
    handleOptionChange,
    handleElementToggle,
    handleDateChange,
    handleOptionSelect,
  ];
}
