import { connect } from 'react-redux';

import { addError } from 'action-creators/ErrorActionCreators';

import ProjectTradeList from '../components/sequences/ProjectTradeList.jsx';

const mapStateToProps = (state, _ownProps) => {
  return {
    projectTradeIds: state.domain.projectTradeCollection.allDataIds,
    isSorting: state.ui.ordering.projectTrades,
    projectId: state.application.projectId,
  };
};

const mapDispatchToProps = (dispatch, _ownProps) => {
  return {
    dispatch: dispatch,
    addError: (errorKey, payload) => dispatch(addError(errorKey, payload)),
  };
};

const AvailableProjectTradeList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectTradeList);

export default AvailableProjectTradeList;
