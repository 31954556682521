import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RowBar from 'components/application/RowBar';
import SegmentedControls from 'components/application/SegmentedControls';
import SearchField from 'components/application/SearchField';
import SearchIcon from '-!svg-react-loader?name=SearchIcon!icons/search.svg';
import Select from 'react-select';
import Toggleable from 'components/application/Toggleable';
import AddButton from 'components/buttons/AddButton';
import {
  NoOptionsMessage,
  ClearIndicator,
  DropdownIndicator,
  Option,
} from 'components/application/CollectionSelect';
import { useBreadBoard } from 'components/contexts/Toaster';
import { useQuery } from '@tanstack/react-query';
import { useTabContext } from 'components/contexts/TabContext';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import useDebounce from 'components/hooks/useDebounce';

export default function BookingsBar({
  bookingSearch,
  onCourseOptionChange,
  onSearchInputChange,
  onSearchReset,
  onSegmentItemClick,
}) {
  const breadBoard = useBreadBoard();
  const tabContext = useTabContext();
  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();

  const [inputValue, setInputValue] = useState(null);
  const [isToggled, setIsToggled] = useState(false);
  const [debouncedInputValue] = useDebounce(inputValue, 250);

  const handleBlur = () => {
    setIsToggled(false);
  };

  const fetchAssignableCourses = () =>
    axios.get('/bookings/assignable_courses', {
      params: { search: debouncedInputValue },
    });

  const { data: assignableCourses } = useQuery({
    queryKey: ['assignableCourses', debouncedInputValue],
    queryFn: async () => {
      const response = await fetchAssignableCourses();
      return response.data;
    },
    onError: breadBoard.addInedibleToast,
    enabled: !!(debouncedInputValue && debouncedInputValue.length > 0),
  });

  const courseOptions =
    assignableCourses ?
      assignableCourses.data.map((course) => ({
        value: course.id,
        label: course.attributes.name,
      }))
    : [];

  return (
    <RowBar
      actions={
        (
          trainingRegisterResourceManagementContext.hasBookingGroupEditableAccess
        ) ?
          <Toggleable
            isToggled={isToggled}
            primary={<AddButton text='Add booking' />}
            secondary={
              <div className='tw-relative'>
                <SearchIcon
                  className='zi-1 m-t-5 m-l-5 search-field__toggleable-icon [&_path]:tw-fill-grey-700'
                  height={24}
                  width={24}
                />
                <div className='tw-w-100 max-w-1200:tw-w-66'>
                  <div className='collection-select collection-select--inline-form collection-select--inline-search collection-select--large-menu'>
                    <div className='form-group'>
                      <Select
                        autoFocus
                        className='collection-select__select-container'
                        classNamePrefix='collection-select'
                        components={{
                          NoOptionsMessage,
                          ClearIndicator,
                          DropdownIndicator,
                          Option,
                        }}
                        isSearchable
                        name='bookingGroupCourseSearch'
                        onBlur={handleBlur}
                        onChange={({ value }) =>
                          onCourseOptionChange({ courseOptionId: value })
                        }
                        onInputChange={(value) => setInputValue(value)}
                        options={courseOptions}
                        placeholder='Search all available courses...'
                        value={inputValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
            setIsToggled={setIsToggled}
          />
        : null
      }
      content={
        <span className='separated-items tw-flex tw-items-center'>
          <SegmentedControls
            activeChoice={tabContext.selectedTabName}
            choices={tabContext.tabNames}
            onItemClick={onSegmentItemClick}
          />
          <SearchField
            additionalClasses='tw-w-70'
            name='bookingSearch'
            onChange={onSearchInputChange}
            onReset={onSearchReset}
            placeholder='Search for personnel or course...'
            value={bookingSearch}
          />
        </span>
      }
      modifiers={[
        'border-top-curved',
        'border-bottom-none',
        'flex-align-items-ie-fix',
      ]}
    />
  );
}

BookingsBar.propTypes = {
  bookingSearch: PropTypes.string,
  onCourseOptionChange: PropTypes.func.isRequired,
  onSearchInputChange: PropTypes.func.isRequired,
  onSearchReset: PropTypes.func.isRequired,
  onSegmentItemClick: PropTypes.func.isRequired,
};
