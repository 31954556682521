import React from 'react';
import PropTypes from 'prop-types';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

import Table from 'components/application/Table';
import classNames from 'classnames';

export default function PersonnelTable({
  isActionsColumnVisible,
  isArchived,
  rows,
}) {
  const currentActor = useCurrentActor();
  const { hasTeamViewableAccess } = useTrainingRegisterResources();

  return (
    <Table
      headers={
        <tr>
          {currentActor.isAllowedFeature('training_register') && (
            <th className='fw-66 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
              Status
            </th>
          )}
          <th className='tw-w-auto tw-pl-12 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Name
          </th>
          <th className='tw-w-auto tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Role
          </th>
          {hasTeamViewableAccess && (
            <th className='tw-w-auto tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
              Team
            </th>
          )}
          <th className='tw-w-auto tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Company
          </th>
          {isActionsColumnVisible && (
            <th
              className={classNames(
                'tw-text-right tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900',
                isArchived ? 'fw-96' : 'fw-72',
              )}
            >
              Actions
            </th>
          )}
        </tr>
      }
      rows={rows}
    />
  );
}

PersonnelTable.propTypes = {
  isArchived: PropTypes.bool,
  isActionsColumnVisible: PropTypes.bool,
  rows: PropTypes.array.isRequired,
};
