import React from 'react';
import PropTypes from 'prop-types';
import InfoBanner from 'components/application/InfoBanner';
import ExpandableList from 'components/application/ExpandableList';
import DivisionItem from 'components/riskRegister/sharedResourceNotices/shared/DivisionItem';
import { resourceShape } from 'components/helpers/serialisableResources';

export default function SingleCategoryMultiDivisionsNonEditable({
  category,
  divisions,
  riskAssessment,
}) {
  return (
    <InfoBanner
      title={
        'You can’t edit this risk assessment as it will affect other divisions you may not have access to'
      }
    >
      {riskAssessment.attributes.name} belongs to {category.attributes.name},
      which is a category shared with:
      <ExpandableList
        collection={divisions}
        renderItem={(division) => (
          <li key={`li--${division.id}`}>
            <DivisionItem division={division} scopedCategories={[]} />
          </li>
        )}
      />
    </InfoBanner>
  );
}

SingleCategoryMultiDivisionsNonEditable.propTypes = {
  category: resourceShape('trade').isRequired,
  divisions: PropTypes.arrayOf(resourceShape('company')).isRequired,
  riskAssessment: resourceShape('masterActivity').isRequired,
};
