import React from 'react';
import PropTypes from 'prop-types';
import OtherOptions from 'components/OtherOptions';

export default function ProjectRowArchiveActions({ projectID }) {
  return (
    <>
      <div className='archive-actions'>
        <a
          className='project-restore app-link layout--ib va-middle tw-text-m tw-font-medium tw-tracking-auto tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
          data-method='put'
          data-remote='true'
          href={`/projects/${projectID}/restore`}
          rel='nofollow'
        >
          Restore
        </a>
      </div>
      <div className='pull-right pending-restore hide'>
        <OtherOptions blink={true} />
      </div>
    </>
  );
}

ProjectRowArchiveActions.propTypes = {
  projectID: PropTypes.number.isRequired,
};
