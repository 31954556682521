import { useEffect } from 'react';

export default function useBodyOverflowToggle(isOpen) {
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);
}
