import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Toast from 'components/application/Toast';
import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';
import OutlinedButton from 'components/application/buttons/OutlinedButton';

export default function UndoableToast(props) {
  const { onBurnToast, onUndoAction, toastKey, toastText } = props;

  const undo = () => {
    onBurnToast(toastKey);
    onUndoAction();
  };

  return (
    <Toast
      burn={onBurnToast}
      burnTimer={8000}
      contents={
        <Fragment>
          <div className='toast__icon tw-bg-green-500'>
            <TickIcon
              className='[&_polygon]:tw-fill-white'
              height={20}
              width={20}
            />
          </div>
          <div className='toast__text'>{toastText}</div>
          <div className='p-l-32 p-t-12'>
            <OutlinedButton color='grey' onClick={undo} size='sm'>
              Undo
            </OutlinedButton>
          </div>
        </Fragment>
      }
      modifiers='with-undo'
      toastKey={toastKey}
    />
  );
}

UndoableToast.propTypes = {
  toastKey: PropTypes.string,
  toastText: PropTypes.object.isRequired,
  onBurnToast: PropTypes.func.isRequired,
  onUndoAction: PropTypes.func.isRequired,
};
