import HandshqApp from '../handshq-app/handshq-app';

$(setUpTooltips);

export function setUpTooltips() {
  setupPopoverTooltips();
  setupAutoHoverPopover();
}

function setupAutoHoverPopover() {
  addPopover($('.hover-popover'));
}

function setupPopoverTooltips() {
  showTooltipsOnClick();
  hideTooltipsOnClickAway();
}

function showTooltipsOnClick() {
  $('.tooltip-popover').each(function () {
    const _this = this;
    const text = $(this).data('text');
    const withoutTitle = $(this).data('without-title');
    const title = $(this).data('text-title') || 'Tip';
    const tooltipDark = $(this).data('tooltip-dark');
    const trigger = $(this).data('trigger');
    const widePopover = $(this).data('wide-popover');
    const style = $(this).data('style');
    const size = $(this).data('size');
    const wrap = $(this).data('wrap');
    const hidePopover = function (_e) {
      $(_this).popover('hide');
    };

    $(this).popover({
      title: withoutTitle ? null : title,
      content: text,
      trigger: trigger ? trigger : 'click',
    });

    if (!withoutTitle) {
      $(this)
        .data('bs.popover')
        .tip()
        .find('.popover-title')
        .addClass('popover-title--text-left');
      $(this)
        .data('bs.popover')
        .tip()
        .find('.popover-content')
        .addClass('popover-content--text-left');
    }

    if (style) {
      $(this)
        .data('bs.popover')
        .tip()
        .find('.popover-content')
        .addClass(`popover-content--${style}`);
      $(this)
        .data('bs.popover')
        .tip()
        .find('.arrow')
        .addClass(`arrow--${style}`);
    }

    if (size) {
      $(this).data('bs.popover').tip().addClass(`popover--${size}`);
    }

    if (wrap) {
      $(this).data('bs.popover').tip().addClass('popover--pre-wrap');
    }

    if (tooltipDark) {
      $(this).data('bs.popover').tip().addClass('popover--dark');
      $(this).data('bs.popover').tip().find('.arrow').addClass('arrow--dark');
    }

    // Nadmin scss class
    if (widePopover) {
      $(this).data('bs.popover').tip().addClass('popover--wide');
    }

    $(this).on('shown.bs.popover', function () {
      $('.custom-tooltip__popover').find('.close').on('click', hidePopover);
    });

    $(this).on('hide.bs.popover', function () {
      $('.custom-tooltip__popover').find('.close').off('click', hidePopover);
    });
  });
}

function hideTooltipsOnClickAway() {
  $('.tooltip-popover').on('click', function (_e) {
    $('.tooltip-popover').not(this).popover('hide');
  });
}

function addPopover(element) {
  const trigger = HandshqApp.mobileDetect.mobile() ? 'click' : 'hover';
  $(element)
    .popover({ trigger: trigger })
    .each(function (i, el) {
      const tip = $(el).data('bs.popover').tip();

      if (!$(el).parents('.tooltip-container').length) {
        tip.addClass('custom-popover');
      }
    });
}

window.setupAutoHoverPopover = setupAutoHoverPopover;
window.addPopover = addPopover;
