import { connect } from 'react-redux';
import { addError } from 'action-creators/ErrorActionCreators';

import SequenceOfOperationsPage from 'components/pages/SequenceOfOperationsPage';

const mapStateToProps = (state, _ownProps) => {
  return {
    containerStyling: state.ui.styling.projectTrades,
    isFetching: state.application.pending.projectTradeFetch,
    isAvailable: state.domain.projectTradeCollection.allDataIds.length === 0,
    anySorting: state.ui.ordering.anyOrdering,
    anyUnsaved: Object.values(state.ui.toggling).some(Boolean),
    isReviewing: state.application.isReviewing,
  };
};

const mapDispatchToProps = (dispatch, _ownProps) => {
  return {
    dispatch: dispatch,
    addError: (errorKey, payload) => dispatch(addError(errorKey, payload)),
  };
};

const AvailableSequenceOfOperationsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SequenceOfOperationsPage);

export default AvailableSequenceOfOperationsPage;
