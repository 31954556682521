import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useBreadBoard } from 'components/contexts/Toaster';
import useModal from 'components/hooks/useModal';
import useOutsideClick from 'components/hooks/useOutsideClick';
import { personDisplayName } from 'components/helpers/users';
import { usePersonnel } from 'components/mobileTrainingProfile/hooks/reactQuery';
import {
  formatResources,
  filterIncluded,
} from 'components/mobileTrainingProfile/helpers/requests';

import PersonIcon from '-!svg-react-loader?name=PersonIcon!icons/person-lg.svg';
import SupportIcon from '-!svg-react-loader?name=SupportIcon!icons/icon-support.svg';
import LogOutIcon from '-!svg-react-loader?name=LogOutIcon!icons/icon-log-out.svg';

import Modal from 'components/mobileTrainingProfile/components/Modal';
import TrainingRecordsTabs from 'components/mobileTrainingProfile/components/homeScreen/TrainingRecordsTabs';
import SupportModalContent from 'components/mobileTrainingProfile/components/homeScreen/SupportModalContent';

export default function HomeScreen({
  appLogoImgSrc,
  isProcoreSession,
  onViewCourseDetailsClick,
  onViewPersonnelDetailsClick,
  personnelId,
}) {
  const currentActor = useCurrentActor();
  const breadBoard = useBreadBoard();

  const [isModalOpen, _setIsModallOpen, openModal, closeModal] =
    useModal(false);

  const {
    data: personnelQuery,
    isError: isPersonnelQueryError,
    isLoading: isPersonnelQueryLoading,
  } = usePersonnel({
    personnelId,
    select: personnelQuerySelector,
    onError: breadBoard.addInedibleToast,
  });

  const userMenuRef = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useOutsideClick(() => {
    setIsDropdownOpen(false);
  }, userMenuRef);

  const handleSupportClick = () => {
    if (currentActor.user.attributes.accessType === 'personnel') {
      openModal();
    } else {
      window.open('https://intercom.help/hands-hq', '_blank');
    }
  };

  const handleLogOutClick = () => {
    axios
      .delete(
        isProcoreSession ?
          '/users/integration_sessions/destroy'
        : '/users/sign_out',
      )
      .then(() => (window.location.href = '/users/sign_in'))
      .catch(breadBoard.addInedibleToast);
  };

  const toggleMenu = () => setIsDropdownOpen((prevState) => !prevState);

  if (isPersonnelQueryLoading || isPersonnelQueryError) return null;

  const personnel = personnelQuery.personnel.indexedById[personnelId];
  const lineManager =
    personnelQuery.lineManagers.indexedById[
      personnel.relationships.lineManager.data?.id
    ];

  return (
    <>
      <div className='tw-sticky tw-top-0 tw-z-[999] tw-flex tw-h-20 tw-w-full tw-items-center tw-bg-grey-900 tw-p-3'>
        <div className='tw-flex tw-h-full tw-w-13 tw-flex-none tw-items-center tw-border-0 tw-border-r-1 tw-border-solid tw-border-grey-500'>
          <img className='tw-ml-1 tw-h-6' src={appLogoImgSrc} />
        </div>
        <div className='tw-ml-4 tw-mr-4 tw-flex-auto tw-text-grey-025'>
          <h1 className='tw-m-0 tw-font-mark-pro tw-text-xl tw-font-bold tw-tracking-tight'>
            {personDisplayName(personnel.attributes)}
          </h1>
          <h2 className='tw-m-0 tw-text-m tw-font-normal tw-tracking-auto'>
            {currentActor.account.attributes.name}
          </h2>
        </div>
        <div className='tw-relative' ref={userMenuRef}>
          <div
            className={classNames(
              'tw-flex tw-h-10 tw-w-10 tw-flex-none tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-lg tw-border-1 tw-border-solid hover:tw-border-grey-500',
              isDropdownOpen ? 'tw-border-grey-500' : 'tw-border-transparent',
            )}
            data-element-name='user-menu-toggle'
            onClick={toggleMenu}
          >
            <div className='tw-flex tw-h-1 tw-w-4 tw-justify-between'>
              <div className='tw-h-1 tw-w-1 tw-rounded-full tw-bg-grey-025'></div>
              <div className='tw-h-1 tw-w-1 tw-rounded-full tw-bg-grey-025'></div>
              <div className='tw-h-1 tw-w-1 tw-rounded-full tw-bg-grey-025'></div>
            </div>
          </div>
          {isDropdownOpen && (
            <div
              className='tw-tw-w-60 tw-absolute tw-right-0 tw-top-11 tw-z-[9999] tw-rounded-lg tw-border-1 tw-border-solid tw-border-grey-100 tw-bg-white tw-shadow-lg'
              data-element-name='user-menu-dropdown'
            >
              <div className='tw-flex tw-w-54 tw-flex-col' onClick={toggleMenu}>
                <div
                  className='tw-flex tw-h-11 tw-cursor-pointer tw-items-center tw-border-grey-100 tw-px-3 tw-font-medium tw-text-grey-900 hover:tw-bg-blue-025'
                  onClick={onViewPersonnelDetailsClick}
                >
                  <PersonIcon
                    className='tw-ml-0.5 tw-mr-2 [&_polygon]:tw-fill-grey-900'
                    height={16}
                    width={16}
                  />
                  View personnel details
                </div>
                <div
                  className='tw-flex tw-h-11 tw-cursor-pointer tw-items-center tw-border-0 tw-border-t-1 tw-border-solid tw-border-grey-100 tw-px-3 tw-font-medium tw-text-grey-900 hover:tw-bg-blue-025'
                  onClick={handleSupportClick}
                >
                  <SupportIcon
                    className='tw-mr-2 [&_polygon]:tw-fill-grey-900'
                    height={20}
                    width={20}
                  />
                  Support
                </div>
                <div
                  className='tw-flex tw-h-11 tw-cursor-pointer tw-items-center tw-border-0 tw-border-t-1 tw-border-solid tw-border-grey-100 tw-px-3 tw-font-medium tw-text-grey-900 hover:tw-bg-blue-025'
                  onClick={handleLogOutClick}
                >
                  <LogOutIcon
                    className='tw-mr-2 [&_polygon]:tw-fill-grey-900'
                    height={20}
                    width={20}
                  />
                  Log out
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='tw-p-3'>
        <TrainingRecordsTabs
          onViewCourseDetailsClick={onViewCourseDetailsClick}
          personnelId={personnelId}
        />
      </div>
      <Modal closeModal={closeModal} isOpen={isModalOpen}>
        <SupportModalContent
          closeModal={closeModal}
          lineManager={lineManager}
        />
      </Modal>
    </>
  );
}

HomeScreen.propTypes = {
  personnelId: PropTypes.string.isRequired,
  appLogoImgSrc: PropTypes.string.isRequired,
  isProcoreSession: PropTypes.bool.isRequired,
  onViewPersonnelDetailsClick: PropTypes.func.isRequired,
  onViewCourseDetailsClick: PropTypes.func.isRequired,
};

const personnelQuerySelector = (response) => {
  const data = response.data;
  const included = response.included;

  return {
    personnel: formatResources({
      resources: [data],
      options: { indexedById: { path: 'id' } },
    }),
    lineManagers: formatResources({
      resources: filterIncluded({ included, type: 'lineManager' }),
      options: { indexedById: { path: 'id' } },
    }),
  };
};
