import { useState } from 'react';

export function useStepper(totalSteps, startingStep = 1) {
  const initialStep = 1;
  const [step, setStep] = useState(startingStep);

  const stepDown = () => {
    if (step === initialStep) {
      setStep(totalSteps);
    } else {
      setStep(step - 1);
    }
  };

  const stepUp = () => {
    if (step === totalSteps) {
      setStep(initialStep);
    } else {
      setStep(step + 1);
    }
  };

  const resetStep = () => {
    setStep(initialStep);
  };

  return [step, stepDown, stepUp, resetStep, setStep];
}
