import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useBreadBoard } from 'components/contexts/Toaster';
import {
  usePersonnel,
  usePersonnelTraining,
} from 'components/mobileTrainingProfile/hooks/reactQuery';
import {
  formatResources,
  filterIncluded,
} from 'components/mobileTrainingProfile/helpers/requests';
import { uniqueTrainingCollectionByCourse } from 'components/helpers/resources/training';
import { courseTrainingStatus } from 'components/helpers/resources/courses';
import { sortRolesByPrimaryAndPosition } from 'components/helpers/personnel';

import Polling from 'handshq-app/utilities/polling';

import Tabs from 'components/application/Tabs';
import PersonnelRoleTrainingStatusIndicator from 'components/training/PersonnelRoleTrainingStatusIndicator';
import TrainingRecordsTab from 'components/mobileTrainingProfile/components/homeScreen/TrainingRecordsTab';

export default function TrainingRecordsTabs({
  onViewCourseDetailsClick,
  personnelId,
}) {
  const breadBoard = useBreadBoard();

  const {
    data: personnelQuery,
    isError: isPersonnelQueryError,
    isLoading: isPersonnelQueryLoading,
  } = usePersonnel({
    personnelId,
    select: personnelQuerySelector,
    onError: breadBoard.addInedibleToast,
  });

  const {
    data: personnelTrainingQuery,
    isError: isPersonnelTrainingQueryError,
    isLoading: isPersonnelTrainingQueryLoading,
  } = usePersonnelTraining({
    personnelId,
    select: personnelTrainingQuerySelector,
    onError: breadBoard.addInedibleToast,
  });

  const [isTrainingEvidenceProcessed, setIsTrainingEvidenceProcessed] =
    useState(false);

  const evidenceStatusPoller = new Polling({
    interval: 1000,
    url: `/personnel/${personnelId}/training_evidence_status`,
    responseCheck: (response) => {
      return response.data.processed;
    },
    successCallback: () => {
      setIsTrainingEvidenceProcessed(true);
    },
  });

  useEffect(() => {
    evidenceStatusPoller.start();
    return () => {
      evidenceStatusPoller.end();
    };
  }, []);

  if (
    isPersonnelQueryLoading ||
    isPersonnelQueryError ||
    isPersonnelTrainingQueryLoading ||
    isPersonnelTrainingQueryError
  )
    return null;

  const courses = [
    ...personnelQuery.courses.resources,
    ...personnelTrainingQuery.courses.ids
      .filter((id) => !personnelQuery.courses.ids.includes(id))
      .map((id) => personnelTrainingQuery.courses.indexedById[id]),
  ];

  const individuallyRequiredCourses =
    personnelQuery.userCourses.resources.reduce((acc, uc) => {
      if (uc.attributes.required)
        acc.push(
          personnelQuery.courses.indexedById[uc.relationships.course.data.id],
        );
      return acc;
    }, []);

  const uniqueTrainingCollection = uniqueTrainingCollectionByCourse({
    training: personnelTrainingQuery.training.resources,
  });
  const personnelCompanyRoles = sortRolesByPrimaryAndPosition(
    personnelQuery.userCompanyRoles.resources,
    personnelQuery.companyRoles.resources,
  );

  const trainingTabs =
    personnelQuery.companyRoles.resources.length === 0 ?
      [
        <TrainingRecordsTab
          attachmentsGroupedByTrainingId={
            personnelTrainingQuery.attachments.groupedByTrainingId
          }
          bookings={personnelQuery.bookings.resources}
          bookingsIndexedByCourseId={personnelQuery.bookings.indexedByCourseId}
          companyRole={null}
          companyRoles={personnelQuery.companyRoles.resources}
          courseCompanyRoles={personnelQuery.courseCompanyRoles.resources}
          courses={courses}
          icon={null}
          isTrainingEvidenceProcessed={isTrainingEvidenceProcessed}
          key='noCompanyRoles'
          label={null}
          onViewCourseDetailsClick={onViewCourseDetailsClick}
          personnelId={personnelId}
          registrationsIndexedByCourseId={
            personnelQuery.registrations.indexedByCourseId
          }
          training={personnelTrainingQuery.training.resources}
          trainingGroupedByCourseId={
            personnelTrainingQuery.training.groupedByCourseId
          }
          trainingStatus={courseTrainingStatus(
            individuallyRequiredCourses,
            uniqueTrainingCollection,
          )}
          userCourses={personnelQuery.userCourses.resources}
          userCoursesIndexedByCourseId={
            personnelQuery.userCourses.indexedByCourseId
          }
        />,
      ]
    : personnelCompanyRoles.map((companyRole) => {
        const tabCompanyRoleCourses =
          companyRole.relationships.courses.data.map(
            (crc) => personnelQuery.courses.indexedById[crc.id],
          );
        const requiredRoleCourses = tabCompanyRoleCourses.reduce((acc, c) => {
          const currentCourseCompanyRole =
            personnelQuery.courseCompanyRoles.resources.find((ccr) => {
              return (
                ccr.relationships.companyRole.data.id == companyRole.id &&
                ccr.relationships.course.data.id == c.id
              );
            });

          if (currentCourseCompanyRole.attributes.required) {
            acc.push(
              personnelQuery.courses.indexedById[
                currentCourseCompanyRole.relationships.course.data.id
              ],
            );
          }

          return acc;
        }, []);

        const requiredCourses = [
          ...requiredRoleCourses,
          ...individuallyRequiredCourses,
        ];
        const roleTrainingStatus = courseTrainingStatus(
          requiredCourses,
          uniqueTrainingCollection,
        );

        return (
          <TrainingRecordsTab
            attachmentsGroupedByTrainingId={
              personnelTrainingQuery.attachments.groupedByTrainingId
            }
            bookings={personnelQuery.bookings.resources}
            bookingsIndexedByCourseId={
              personnelQuery.bookings.indexedByCourseId
            }
            companyRole={companyRole}
            companyRoles={personnelQuery.companyRoles.resources}
            courseCompanyRoles={personnelQuery.courseCompanyRoles.resources}
            courses={courses}
            icon={
              personnelCompanyRoles.length > 1 ?
                <div className='flex m-r-6'>
                  <PersonnelRoleTrainingStatusIndicator
                    status={roleTrainingStatus}
                  />
                </div>
              : undefined
            }
            isTrainingEvidenceProcessed={isTrainingEvidenceProcessed}
            key={`companyRoleTrainingTab--${companyRole.id}`}
            label={
              personnelCompanyRoles.length === 1 ?
                null
              : companyRole.attributes.position
            }
            onViewCourseDetailsClick={onViewCourseDetailsClick}
            personnelId={personnelId}
            registrationsIndexedByCourseId={
              personnelQuery.registrations.indexedByCourseId
            }
            training={personnelTrainingQuery.training.resources}
            trainingGroupedByCourseId={
              personnelTrainingQuery.training.groupedByCourseId
            }
            trainingStatus={roleTrainingStatus}
            userCourses={personnelQuery.userCourses.resources}
            userCoursesIndexedByCourseId={
              personnelQuery.userCourses.indexedByCourseId
            }
          />
        );
      });

  return trainingTabs.length > 1 ?
      <Tabs
        modifiers={['truncated']}
        navClassName='tw-overflow-y-hidden tw-whitespace-nowrap [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] tw-h-[22px] tw-my-3'
      >
        {trainingTabs}
      </Tabs>
    : trainingTabs;
}

TrainingRecordsTabs.propTypes = {
  personnelId: PropTypes.string.isRequired,
  onViewCourseDetailsClick: PropTypes.func.isRequired,
};

const personnelQuerySelector = (response) => {
  const included = response.included;

  return {
    courses: formatResources({
      resources: filterIncluded({ included, type: 'course' }),
      options: { resources: true, ids: true, indexedById: { path: 'id' } },
    }),
    companyRoles: formatResources({
      resources: filterIncluded({ included, type: 'companyRole' }),
      options: { resources: true },
    }),
    bookings: formatResources({
      resources: filterIncluded({ included, type: 'booking' }),
      options: {
        resources: true,
        indexedByCourseId: { path: 'relationships.course.data.id' },
      },
    }),
    registrations: formatResources({
      resources: filterIncluded({ included, type: 'registration' }),
      options: { indexedByCourseId: { path: 'relationships.course.data.id' } },
    }),
    userCourses: formatResources({
      resources: filterIncluded({ included, type: 'userCourse' }),
      options: {
        resources: true,
        indexedByCourseId: { path: 'relationships.course.data.id' },
      },
    }),
    userCompanyRoles: formatResources({
      resources: filterIncluded({ included, type: 'userCompanyRole' }),
      options: { resources: true },
    }),
    courseCompanyRoles: formatResources({
      resources: filterIncluded({ included, type: 'courseCompanyRole' }),
      options: { resources: true },
    }),
  };
};

const personnelTrainingQuerySelector = (response) => {
  const data = response.data;
  const included = response.included;

  return {
    training: formatResources({
      resources: data,
      options: {
        resources: true,
        groupedByCourseId: { path: 'relationships.course.data.id' },
      },
    }),
    courses: formatResources({
      resources: filterIncluded({ included, type: 'course' }),
      options: { ids: true, indexedById: { path: 'id' } },
    }),
    attachments: formatResources({
      resources: filterIncluded({ included, type: 'attachment' }),
      options: {
        groupedByTrainingId: { path: 'relationships.attachable.data.id' },
      },
    }),
  };
};
