import moment from 'moment';
import pluralize from 'pluralize';
import voca from 'voca';
import {
  currencyNumberToCurrencyString,
  currencyStringToCurrencyNumber,
} from 'components/helpers/currency';
import { sortAlphabetically, sortByDate } from 'components/helpers/arrays';

const defaultExpiringDurationLengthInDays = '60';

export const learningMethodMap = {
  internal: 'Internal',
  online: 'Online',
  approved_training_provider: 'ATP',
  e_learning: 'eLearning',
};

export const defaultCourse = {
  id: null,
  name: '',
  renewalFrequency: '',
  renewalInterval: 'year',
  expiringDuration: defaultExpiringDurationLengthInDays,
  expires: false,
  requiresEvidence: false,
  courseCompanyRoles: [],
  isELearningAvailable: false,
  isELearningProviderSelected: false,
  hasAutoEnrolBeenActivelySelected: false,
  autoEnrolEnrolleeScope: 'employees',
  autoEnrolCourseRequirementScope: 'required',
  eLearningCourseId: null,
  learningMethod: '',
  provider: '',
  costEstimate: '',
  timeAmount: '',
  timeInterval: 'hour',
  originalId: null,
  trainingCount: 0,
  bookingCount: 0,
  activeRegistrationCount: 0,
  defaultBookingNote: '',
};

export const courseAsParams = ({
  autoEnrolmentConfigurationParams = null,
  course,
  courseCompanyRolesParams,
  currencyCode,
  eLearningCourses,
}) => {
  const eLearningCourse = eLearningCourses[course.eLearningCourseId];

  const params = {
    course: {
      name: course.name.trim(),
      renewal_frequency: course.renewalFrequency,
      renewal_interval: course.renewalInterval,
      expires: course.expires,
      expiring_duration:
        course.expiringDuration &&
        moment.duration(course.expiringDuration, 'days').toISOString(),
      requires_evidence: course.requiresEvidence,
      default_booking_note: course.defaultBookingNote,
      course_company_roles_attributes: courseCompanyRolesParams,
    },
  };

  if (autoEnrolmentConfigurationParams) {
    params.course.auto_enrolment_configuration_attributes =
      autoEnrolmentConfigurationParams;
  }

  if (course.isELearningProviderSelected) {
    params.course.certificate_expiry_date_included =
      course.certificateExpiryDateIncluded;

    if (!eLearningCourse || !eLearningCourse.attributes.custom) {
      params.course = {
        ...params.course,
        should_validate_e_learning_course_id: true,
        e_learning_course_id: course.eLearningCourseId,
        learning_method: 'e_learning',
        provider: 'iHasco',
        cost_estimate: '',
        time_amount: '',
        time_interval: 'hour',
      };
    }
  } else {
    params.course = {
      ...params.course,
      e_learning_course_id: '',
      learning_method: course.learningMethod,
      provider: course.provider,
      cost_estimate: currencyStringToCurrencyNumber({
        string: course.costEstimate,
        currencyCode,
      }),
      time_amount: course.timeAmount,
      time_interval: course.timeInterval,
    };
  }

  if (course.originalId) {
    params.course.original_id = course.originalId;
  }

  return params;
};

export function asCourseObject({
  autoEnrolmentConfiguration = null,
  course,
  currencyCode,
  eLearningAllowed = false,
  originalCourse = null,
}) {
  const courseAttributes = course.attributes;
  const courseELearningCourseId =
    eLearningAllowed ? getELearningCourseId(course) : null;
  const originalCourseELearningCourseId =
    eLearningAllowed ? getELearningCourseId(originalCourse) : null;

  return {
    id: course.id,
    name: courseAttributes.name ? courseAttributes.name : '',
    renewalFrequency:
      courseAttributes.renewalFrequency ?
        courseAttributes.renewalFrequency.toString()
      : '',
    renewalInterval:
      courseAttributes.renewalInterval ?
        courseAttributes.renewalInterval.toString()
      : 'year',
    expires: courseAttributes.expires,
    certificateExpiryDateIncluded:
      courseAttributes.certificateExpiryDateIncluded,
    expiringDuration:
      moment.duration(courseAttributes.expiringDuration).asDays() ||
      defaultExpiringDurationLengthInDays,
    requiresEvidence: courseAttributes.requiresEvidence ? true : false,
    courseCompanyRoles: course.courseCompanyRoles || [],
    isELearningAvailable:
      !courseELearningCourseId && !!originalCourseELearningCourseId,
    isELearningProviderSelected: !!courseELearningCourseId,
    hasAutoEnrolBeenActivelySelected: !!autoEnrolmentConfiguration,
    autoEnrolmentConfigurationId: autoEnrolmentConfiguration?.id,
    autoEnrolEnrolleeScope:
      autoEnrolmentConfiguration?.attributes?.enrolleeScope || 'employees',
    autoEnrolCourseRequirementScope:
      autoEnrolmentConfiguration?.attributes?.courseRequirementScope ||
      'required',
    eLearningCourseId:
      courseELearningCourseId || originalCourseELearningCourseId,
    learningMethod:
      courseAttributes.learningMethod ?
        courseAttributes.learningMethod.toString()
      : '',
    provider: courseAttributes.provider ? courseAttributes.provider : '',
    costEstimate: currencyNumberToCurrencyString({
      number: courseAttributes.costEstimate,
      currencyCode,
    }),
    timeAmount:
      courseAttributes.timeAmount ? courseAttributes.timeAmount.toString() : '',
    timeInterval:
      courseAttributes.timeInterval ?
        courseAttributes.timeInterval.toString()
      : 'hour',
    trainingCount: courseAttributes.trainingCount || 0,
    bookingCount: courseAttributes.bookingCount || 0,
    activeRegistrationCount: courseAttributes.activeRegistrationCount || 0,
    defaultBookingNote:
      courseAttributes.defaultBookingNote ?
        courseAttributes.defaultBookingNote
      : '',
  };
}

export function hasCourseProviderTypeChanged(courseObject, courseCollection) {
  const course = courseCollection.find(
    (course) => course.id === courseObject.id,
  );
  const eLearningCourseId = getELearningCourseId(course);

  const otherProviderIsUnchanged =
    !courseObject.isELearningProviderSelected && eLearningCourseId === null;
  const eLearningProviderIsUnchanged =
    courseObject.isELearningProviderSelected &&
    courseObject.eLearningCourseId === eLearningCourseId;

  return !(otherProviderIsUnchanged || eLearningProviderIsUnchanged);
}

export function hasCourseBecomeELearning(courseObject, courseCollection) {
  const course = courseCollection.find(
    (course) => course.id === courseObject.id,
  );
  const eLearningCourseId = getELearningCourseId(course);

  return (
    courseObject.isELearningProviderSelected &&
    !eLearningCourseId &&
    !!courseObject.eLearningCourseId
  );
}

export function getELearningCourseId(course) {
  const eLearningCourse = course && course.relationships.eLearningCourse.data;
  const eLearningCourseId = eLearningCourse ? eLearningCourse.id : null;

  return eLearningCourseId;
}

export function isCourseAssociated(course, { roles, training, userCourses }) {
  const isCourseCompleted = training.some(
    (training) => training.relationships.course.data.id === course.id,
  );
  if (isCourseCompleted) return true;

  const isCourseIndividuallyRequired = userCourses.some(
    (userCourse) => userCourse.relationships.course.data.id === course.id,
  );
  if (isCourseIndividuallyRequired) return true;

  const isCourseRoleRequired = roles.some((companyRole) => {
    return companyRole.relationships.courses.data.some((companyRoleCourse) => {
      return companyRoleCourse.id === course.id;
    });
  });
  return isCourseRoleRequired;
}

export function courseTrainingStatus(requiredCourses, trainings) {
  if (requiredCourses.length === 0) return 'noRequirements';

  // valid is considered to be default status, and then invalidating this if it meets any checks below
  let status = 'valid';
  const trainingsMap = new Map();

  trainings.forEach((training) => {
    trainingsMap.set(training.relationships.course.data.id, training);
  });

  for (let i = 0; i < requiredCourses.length; i++) {
    const requiredCourse = requiredCourses[i];
    const requiredTraining = trainingsMap.get(requiredCourse.id);

    if (!requiredTraining) {
      status = 'invalid';
      break;
    }

    if (requiredCourse.attributes.expires) {
      if (!requiredTraining.attributes.expiryDate) {
        status = 'invalid';
        break;
      }

      if (
        moment().isSameOrAfter(requiredTraining.attributes.expiryDate, 'day')
      ) {
        status = 'invalid';
        break;
      }
    }

    if (status !== 'expiring') {
      const expiringPeriodStartDate = moment(
        requiredTraining.attributes.expiryDate,
      ).subtract(requiredCourse.attributes.expiringDuration);
      if (
        moment().isBetween(
          expiringPeriodStartDate,
          requiredTraining.attributes.expiryDate,
          'day',
          '[)',
        )
      ) {
        status = 'expiring';
      }
    }
  }

  return status;
}

export function isELearningAvailable({ course, currentActor, originalCourse }) {
  return (
    !!originalCourse?.relationships?.eLearningCourse?.data &&
    currentActor.isAllowedFeature('e_learning') &&
    !course.relationships.eLearningCourse.data
  );
}

export function isELearningProviderSelected({ course, currentActor }) {
  return (
    currentActor.isAllowedFeature('e_learning') &&
    !!course.relationships.eLearningCourse.data
  );
}

export function courseDuration({ course }) {
  return voca.isBlank(course.attributes.timeAmount) ? '' : (
      pluralize(
        course.attributes.timeInterval,
        parseInt(course.attributes.timeAmount),
        true,
      )
    );
}

export function courseLearningMethod({ course }) {
  return learningMethodMap[course.attributes.learningMethod];
}

export function courseRenewalFrequency({ course }) {
  if (!course.attributes.expires) return 'Does not expire';

  return voca.isBlank(course.attributes.renewalFrequency) ? '' : (
      pluralize(
        course.attributes.renewalInterval,
        parseInt(course.attributes.renewalFrequency),
        true,
      )
    );
}

export function isCourseEligibleForAutoEnrolment({
  course,
  currentActor,
  isAutoEnrolmentIncludingMissingTraining,
}) {
  return (
    currentActor.isAllowedFeature([
      'e_learning_auto_enrol',
      'e_learning',
      'training_register',
    ]) &&
    !!course.relationships?.eLearningCourse?.data &&
    !!course.relationships?.autoEnrolmentConfiguration?.data &&
    (isAutoEnrolmentIncludingMissingTraining || course.attributes.expires)
  );
}

export function orderedCoursesByRoleAndTraining(
  courses,
  roleCourseIds = new Set(),
  trainingByCourseId,
) {
  const isRoleCourse = (course) => roleCourseIds.has(course.id);

  return courses.sort((a, b) => {
    const latestTraining = (courseId) =>
      (trainingByCourseId[courseId] && trainingByCourseId[courseId][0]) || null;

    const latestTrainingA = latestTraining(a.id);
    const latestTrainingB = latestTraining(b.id);

    const roleCoursePriority =
      Number(isRoleCourse(b)) - Number(isRoleCourse(a));

    return (
      roleCoursePriority ||
      sortByDate(
        latestTrainingA?.attributes?.expiryDate,
        latestTrainingB?.attributes?.expiryDate,
      ) ||
      sortAlphabetically(a.attributes.name, b.attributes.name)
    );
  });
}
