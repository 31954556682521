import React from 'react';

import OutlinedButton from 'components/application/buttons/OutlinedButton';
import NoteIcon from '-!svg-react-loader?name=NoteIcon!icons/note-sm.svg';

export default function TrainingNotesIcon(props) {
  const { onNotesToggleClick } = props;

  return (
    <OutlinedButton color='grey' onClick={onNotesToggleClick} size='xs'>
      <NoteIcon height={16} width={16} />
    </OutlinedButton>
  );
}
