import HandshqApp from '../handshq-app';

HandshqApp.toggleHelpers = {
  setupReplaceToggle: function () {
    $("[data-toggle='replace'").on('click', function () {
      const targetSelector = $(this).data().target;
      $(targetSelector).toggleClass('hide');
      $(this).toggleClass('hide');
    });
  },

  setupRevealToggle: function () {
    if ($("[data-toggle='reveal']").length) {
      const eventTrigger = $("[data-toggle='reveal']").data().trigger;
      $("[data-toggle='reveal'").on(eventTrigger, function () {
        const targetSelector = $(this).data().target;
        $(targetSelector).removeClass('hide');
      });
    }
  },
};

$(function () {
  HandshqApp.toggleHelpers.setupReplaceToggle();
  HandshqApp.toggleHelpers.setupRevealToggle();
});
