import { z } from 'zod';
import voca from 'voca';

export const permittedPeriods = [
  'withinTheNext',
  'withinTheLast',
  'thisWeek',
  'nextWeek',
  'lastWeek',
  'thisMonth',
  'nextMonth',
  'lastMonth',
  'thisQuarter',
  'nextQuarter',
  'lastQuarter',
  'thisYear',
  'nextYear',
  'lastYear',
  'today',
  'tomorrow',
  'yesterday',
  'before',
  'after',
  'onExactDate',
  'customDateRange',
];

export const permittedIntervals = ['days', 'weeks', 'months', 'years'];

function cantBeBlankIssue(date) {
  return {
    code: z.ZodIssueCode.too_small,
    minimum: 1,
    type: 'string',
    inclusive: true,
    exact: false,
    message: `${voca.capitalize(voca.kebabCase(date).replace(/-/g, ' '))} can't be blank`,
    path: [date],
  };
}

export const validFromDateSchema = z
  .object({
    period: z.enum(permittedPeriods),
    withinTheLastFrequency: z.string(),
    withinTheLastInterval: z.enum(permittedIntervals),
    before: z.date().nullable(),
    after: z.date().nullable(),
    onExactDate: z.date().nullable(),
    customDateRangeFrom: z.date().nullable(),
    customDateRangeTo: z.date().nullable(),
  })
  .superRefine((schema, ctx) => {
    if (
      schema.period === 'withinTheLast' &&
      schema.withinTheLastFrequency.trim().length === 0
    ) {
      ctx.addIssue(cantBeBlankIssue('validFromDate'));
    }

    if (schema.period === 'before' && !schema.before) {
      ctx.addIssue(cantBeBlankIssue('validFromDate'));
    }

    if (schema.period === 'after' && !schema.after) {
      ctx.addIssue(cantBeBlankIssue('validFromDate'));
    }

    if (schema.period === 'onExactDate' && !schema.onExactDate) {
      ctx.addIssue(cantBeBlankIssue('validFromDate'));
    }

    if (
      schema.period === 'customDateRange' &&
      (!schema.customDateRangeFrom || !schema.customDateRangeTo)
    ) {
      ctx.addIssue(cantBeBlankIssue('validFromDate'));
    }
  });

export const expiryDateSchema = z
  .object({
    period: z.enum(permittedPeriods),
    withinTheNextFrequency: z.string(),
    withinTheNextInterval: z.enum(permittedIntervals),
    withinTheLastFrequency: z.string(),
    withinTheLastInterval: z.enum(permittedIntervals),
    before: z.date().nullable(),
    after: z.date().nullable(),
    onExactDate: z.date().nullable(),
    customDateRangeFrom: z.date().nullable(),
    customDateRangeTo: z.date().nullable(),
  })
  .superRefine((schema, ctx) => {
    if (
      schema.period === 'withinTheNext' &&
      schema.withinTheNextFrequency.trim().length === 0
    ) {
      ctx.addIssue(cantBeBlankIssue('expiryDate'));
    }

    if (
      schema.period === 'withinTheLast' &&
      schema.withinTheLastFrequency.trim().length === 0
    ) {
      ctx.addIssue(cantBeBlankIssue('expiryDate'));
    }

    if (schema.period === 'before' && !schema.before) {
      ctx.addIssue(cantBeBlankIssue('expiryDate'));
    }

    if (schema.period === 'after' && !schema.after) {
      ctx.addIssue(cantBeBlankIssue('expiryDate'));
    }

    if (schema.period === 'onExactDate' && !schema.onExactDate) {
      ctx.addIssue(cantBeBlankIssue('expiryDate'));
    }

    if (
      schema.period === 'customDateRange' &&
      (!schema.customDateRangeFrom || !schema.customDateRangeTo)
    ) {
      ctx.addIssue(cantBeBlankIssue('expiryDate'));
    }
  });
