import { useState } from 'react';

export default function useKeyHighlight(initialKey) {
  const [highlightedKey, setHighlightedKey] = useState(initialKey);

  const handleKeyHighlight = (key) => {
    setHighlightedKey(key);
  };

  const handleKeyUnhighlight = () => {
    setHighlightedKey(null);
  };

  return {
    highlightedKey,
    handleKeyHighlight,
    handleKeyUnhighlight,
  };
}
