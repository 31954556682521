import { connect } from 'react-redux';

import { removeError } from 'action-creators/ErrorActionCreators';

import ErrorList from 'components/sequences/ErrorList';

const mapStateToProps = (state, _ownProps) => {
  return {
    errorData: state.application.errors.data,
    errorKeys: state.application.errors.allDataKeys,
  };
};

const mapDispatchToProps = (dispatch, _ownProps) => {
  return {
    removeError: (errorKey) => dispatch(removeError(errorKey)),
  };
};

const AvailableErrorList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorList);

export default AvailableErrorList;
