import React from 'react';
import PropTypes from 'prop-types';

import OutlinedButton from 'components/application/buttons/OutlinedButton';
import AddIcon from '-!svg-react-loader?name=AddIcon!icons/add.svg';

export default function ProjectCoshhActionsBar({ masterCoshhIndexPath }) {
  return (
    <div className='project__actions tw-border-0 tw-border-l-1 tw-border-r-1 tw-border-t-1 tw-border-solid tw-border-grey-100 tw-bg-white'>
      <OutlinedButton color='grey' href={masterCoshhIndexPath} size='sm'>
        <AddIcon className='m-l--8' height={24} width={24} />
        <span>Add COSHH</span>
      </OutlinedButton>
    </div>
  );
}

ProjectCoshhActionsBar.propTypes = {
  masterCoshhIndexPath: PropTypes.string,
};
