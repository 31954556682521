import React, { useState } from 'react';
import OneTimePasswordSignInCard from './OneTimePasswordSignInCard';
import useOneTimePassword from '../../hooks/useOneTimePassword';
import ValidationErrorsMessages from 'components/validationErrors/components/ValidationErrorsMessages';

export default function OneTimePasswordSignInPage() {
  const {
    handleCleanupField,
    handleFormPasteCapture,
    handleInputClick,
    handleInputKeyUp,
    inputRefs,
    otpCode,
  } = useOneTimePassword();
  const [validationErrors, setValidationErrors] = useState({});

  const handleSubmit = (otpCode) => {
    const otpCodeStr = otpCode.join('');
    axios
      .post('/users/two_factor', { otp_attempt: otpCodeStr })
      .then(() => {
        window.location.href = '/';
      })
      .catch(() => {
        handleCleanupField();
        setValidationErrors({
          otp_attempt: { messages: ['Incorrect verification code'] },
        });
      });
  };

  return (
    <>
      {Object.keys(validationErrors).length > 0 && (
        <ValidationErrorsMessages
          className='tw-mb-6 tw-border-1 tw-border-solid tw-border-red-200'
          validationErrors={validationErrors}
        />
      )}

      <div className='form-container form-horizontal signin-form tw-border-white tw-bg-white'>
        <p className='tw-mb-5 tw-text-center tw-text-l tw-text-grey-700'>
          Use your authenticator app to get the <br />
          verification code and enter below
        </p>
        <OneTimePasswordSignInCard
          inputRefs={inputRefs}
          onFormPasteCapture={handleFormPasteCapture}
          onInputClick={handleInputClick}
          onInputKeyUp={handleInputKeyUp}
          onSubmit={handleSubmit}
          otpCode={otpCode}
        />
        <p className='tw-text-sm tw-text-center tw-text-grey-700'>
          Lost access to your authenticator app?{' '}
          <a
            className="tw-relative tw-font-semibold tw-text-grey-900 before:tw-pointer-events-none before:tw-absolute before:tw-left-0 before:tw-top-[20px] before:tw-h-0.5 before:tw-w-full before:tw-bg-mint-200 before:tw-content-[''] hover:tw-text-mint-200"
            href='https://intercom.help/hands-hq/'
            target='_blank'
          >
            Contact support
          </a>{' '}
          for help.
        </p>
      </div>
    </>
  );
}
