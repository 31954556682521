import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import Options from 'components/application/Options';
import OptionChoice from 'components/application/OptionChoice';
import CheckboxField from 'components/application/CheckboxField';
import {
  DropdownIndicator,
  Option,
} from 'components/application/CollectionSelect';

import ChevronRightIcon from '-!svg-react-loader?name=ChevronRightIcon!icons/chevron-right-md.svg';
import FunnelIcon from '-!svg-react-loader?name=FunnelIcon!icons/funnel.svg';

const dotClassNameMap = {
  green: 'tw-bg-green-400',
  amber: 'tw-bg-amber-300',
  grey: 'tw-bg-grey-500',
};

export default function ReportHeader(props) {
  const {
    dropdownOptions = [],
    dropdownValue,
    filters = [],
    minChecked = 0,
    onFilterChange,
    onSelectionChange,
    onTotalCountClick,
    subTitle,
    title,
    totalCount = 0,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const isMinChecked =
    filters.filter((filter) => filter.value).length === minChecked;

  return (
    <div className='tw-absolute tw-left-0 tw-right-0 tw-top-0 tw-flex tw-justify-between tw-pl-6 tw-pr-6 tw-pt-6'>
      <div>
        <h2 className='tw-m-0 tw-text-m tw-font-medium tw-tracking-auto'>
          {title}
        </h2>
        {totalCount > 0 && (
          <div
            className='tw-inline-flex tw-cursor-pointer tw-items-center'
            onClick={onTotalCountClick}
          >
            <h3 className='tw-m-0 tw-text-3xl tw-font-semibold tw-tracking-tighter'>
              {totalCount.toLocaleString()}
            </h3>
            <ChevronRightIcon
              className='[&_path]:tw-stroke-transparent group-hover/card:[&_path]:tw-stroke-grey-700'
              height={40}
              width={24}
            />
          </div>
        )}
        {subTitle && (
          <h3
            className={`tw-m-0 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-500 ${totalCount > 0 ? '' : 'tw-mt-1'}`}
          >
            {subTitle}
          </h3>
        )}
      </div>
      <div
        className={`tw-h-8 ${isOpen ? 'tw-flex' : 'tw-hidden'} group-hover/card:tw-flex`}
        id='report-header-filters-wrapper'
      >
        {dropdownOptions.length > 0 && (
          <div className='collection-select collection-select--inline-form tw-mr-2 tw-w-32'>
            <Select
              className='collection-select__select-container'
              classNamePrefix='collection-select'
              components={{ DropdownIndicator, Option }}
              controlShouldRenderValue={true}
              isSearchable={false}
              onChange={onSelectionChange}
              onMenuClose={() => setIsOpen(false)}
              onMenuOpen={() => setIsOpen(true)}
              options={dropdownOptions}
              value={dropdownValue}
            />
          </div>
        )}
        {filters.length > 0 && (
          <Options
            btnToggle={() => (
              <div className='tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-lg tw-border-1 tw-border-solid tw-border-grey-300 tw-bg-white hover:tw-border-grey-400 hover:tw-bg-grey-050'>
                <FunnelIcon height={12} width={12} />
              </div>
            )}
            listStyle='tw-min-w-[256px] [&_li]:tw-border-none [&_li]:tw-h-11 [&_li]:tw-cursor-default'
            onClose={() => setIsOpen(false)}
            onOpen={() => setIsOpen(true)}
          >
            {filters.map((filter) => {
              const isFilterDisabled = isMinChecked && filter.value;

              return (
                <OptionChoice
                  hoverClassName='hover:tw-bg-transparent'
                  key={filter.name}
                >
                  <div className='tw-flex tw-h-11 tw-items-center tw-pl-3 tw-pr-5'>
                    <CheckboxField
                      checked={filter.value}
                      disabled={isFilterDisabled}
                      label={
                        <div className='tw-flex tw-h-[22px] tw-items-center tw-justify-between tw-pl-3'>
                          <div className='tw-grey-900 tw-flex tw-items-center tw-text-s tw-font-medium tw-tracking-wide'>
                            {filter.dot && (
                              <span
                                className={`tw-inline-flex tw-h-2.5 tw-w-2.5 tw-rounded-full ${dotClassNameMap[filter.dot]} tw-mr-3`}
                              />
                            )}
                            {filter.label}
                          </div>
                          <div className='tw-ml-1 tw-text-s tw-font-normal tw-tracking-wide'>
                            {filter.count}
                          </div>
                        </div>
                      }
                      labelTextProps={{ className: 'tw-w-full tw-ml-0' }}
                      name={filter.name}
                      onChange={
                        isFilterDisabled ?
                          () => {}
                        : () => onFilterChange(filter.name)
                      }
                      value={filter.name}
                    />
                  </div>
                </OptionChoice>
              );
            })}
          </Options>
        )}
      </div>
    </div>
  );
}

ReportHeader.propTypes = {
  title: PropTypes.string.isRequired,
  totalCount: PropTypes.number,
  subTitle: PropTypes.string,
  filters: PropTypes.array,
  minChecked: PropTypes.number,
  dropdownOptions: PropTypes.array,
  dropdownValue: PropTypes.object,
  onTotalCountClick: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onFilterChange: PropTypes.func,
};
