import React, { useEffect, useRef } from 'react';
import { focusField } from 'components/helpers/refs';
import PropTypes from 'prop-types';

import CollectionSelect from 'components/application/CollectionSelect';
import TextField from 'components/application/TextField';
import TextArea from 'components/application/TextArea';
import ErrorMessage from 'components/application/ErrorMessage';
import LevelOfRiskIcon from 'components/projectCoshh/LevelOfRiskIcon';

import { uniquePersonsAtRiskOptions } from 'components/helpers/resources/coshhDocuments';

import usePrevious from 'components/hooks/usePrevious';

const levelOfRiskOptions = ['Low', 'Medium', 'High'].map((level) => {
  return { label: <LevelOfRiskIcon option={level} />, value: level };
});

export default function CoshhForm(props) {
  const {
    coshhDocument,
    exposureFrequencyOptions,
    locationOptions,
    methodOfUseOptions,
    onCoshhInputChange,
    onCoshhOptionChange,
    onOptionSelect,
    personsAtRiskOptions,
    removeErrorStyling,
    requestError,
    secondaryFieldLabel,
    sidePanelFieldRef,
    workerExposureTimeOptions,
  } = props;

  const durationOfExposureTooltip = (
    <>
      <p>
        How long the person is likely to be in contact with the product.
        Maximum/workplace exposure limit for product:
      </p>
      <ul className='p-l-15'>
        <li className='tw-font-medium'>
          Long term exposure limit (LTEL 8hr TWA):
        </li>
        <p className='m-0'>{coshhDocument.longTermExposureLimit || 'N/A'}</p>
        <li className='tw-font-medium'>
          Short term exposure limit (STEL 15min TWA):
        </li>
        <p className='m-0'>{coshhDocument.shortTermExposureLimit || 'N/A'}</p>
      </ul>
    </>
  );

  const controlMeasuresTooltip = (
    <>
      <p>Steps to reduce the level of risk. Control measures should include:</p>
      <ul className='p-l-15'>
        <li>Use of natural or local exhaust ventilation (LEV)</li>
        <li>Exposure monitoring</li>
        <li>Health surveillance</li>
        <li>Information, instruction and training</li>
        <li>Emergency actions</li>
      </ul>
    </>
  );

  const displayWorkerExposureTimeOtherField =
    coshhDocument.workerExposureTime === secondaryFieldLabel;
  const displayExposureFrequencyOtherField =
    coshhDocument.exposureFrequency === secondaryFieldLabel;
  const displayUsesOtherField = coshhDocument.uses === secondaryFieldLabel;

  const selectOtherFieldRef = useRef();
  const displayPersonsAtRiskOtherField = Boolean(
    coshhDocument.personsAtRisk.find(
      (persons) => persons.name === secondaryFieldLabel,
    ),
  );
  const previousDisplayPersonsAtRiskOtherField = usePrevious(
    displayPersonsAtRiskOtherField,
  );
  const personsAtRiskOtherOptionChangesToSelected =
    previousDisplayPersonsAtRiskOtherField === false &&
    displayPersonsAtRiskOtherField;

  useEffect(() => {
    if (personsAtRiskOtherOptionChangesToSelected) {
      focusField(selectOtherFieldRef);
    }
  }, [personsAtRiskOtherOptionChangesToSelected]);

  return (
    <div className='popup__body-form popup__body-form--no-side-padding'>
      <ErrorMessage
        isFallback={requestError.isFallback}
        validationErrors={requestError.validationErrors}
        withSeparateNestedErrors={true}
        wrapperClassName='form-container'
      />
      <div className='form-container'>
        <div className='form-container-section'>
          <TextField
            customMargin={'m-b-32'}
            inputRef={sidePanelFieldRef}
            label='Quantity kept on site'
            maxLength={255}
            name='quantityOnSite'
            onChange={onCoshhInputChange}
            placeholder='e.g. 10 litres'
            tooltip={'The average quantity being stored on site'}
            value={coshhDocument.quantityOnSite}
          />
          <TextField
            customMargin={'m-b-32'}
            label='Quantity expected to be used'
            maxLength={255}
            name='quantityToBeUsed'
            onChange={onCoshhInputChange}
            placeholder='e.g. 10 litres'
            tooltip={'How much will be used on the RAMS'}
            value={coshhDocument.quantityToBeUsed}
          />
          <TextField
            customMargin={'m-b-32'}
            label='Quantity used in one day'
            maxLength={255}
            name='substanceQuantityPerDay'
            onChange={onCoshhInputChange}
            placeholder='e.g. 1 litre'
            tooltip={'The average quantity used in one day'}
            value={coshhDocument.substanceQuantityPerDay}
          />
          <CollectionSelect
            customMargin={displayUsesOtherField ? 'm-b-8' : 'm-b-32'}
            isClearable={true}
            label='Method of use'
            maxMenuHeight={320}
            name='uses'
            onChange={onCoshhOptionChange}
            options={methodOfUseOptions.map((option) => ({
              label: option,
              value: option,
            }))}
            placeholder='Select method'
            tooltip={
              "How the product will be used. Select 'Other' to specify a method not included in the list"
            }
            value={
              coshhDocument.uses ?
                { label: coshhDocument.uses, value: coshhDocument.uses }
              : ''
            }
          />
          {displayUsesOtherField && (
            <TextField
              fieldError={requestError.validationErrors.methodOfUseOther}
              isRequired={false}
              label=''
              maxLength={255}
              name={'methodOfUseOther'}
              onChange={onCoshhInputChange}
              placeholder={'e.g. applied by hand'}
              removeErrorStyling={removeErrorStyling}
              value={coshhDocument.methodOfUseOther}
              withLabel={false}
            />
          )}
          <TextField
            customMargin={'m-b-32'}
            label='Number of people involved in activity'
            maxLength={255}
            name='staffNumber'
            onChange={onCoshhInputChange}
            placeholder='e.g. 2 personnel'
            tooltip={'The number of people who will carry out this task'}
            value={coshhDocument.staffNumber}
          />
          <CollectionSelect
            checkboxes={true}
            customMargin={displayPersonsAtRiskOtherField ? 'm-b-8' : 'm-b-32'}
            externalProps={{
              closeMenuOnSelect: false,
              hideSelectedOptions: false,
              backspaceRemovesValue: false,
            }}
            isMulti={true}
            isSearchable={false}
            label='Person(s) at risk'
            maxMenuHeight={'unset'}
            name='personsAtRisk'
            onChange={onOptionSelect}
            options={uniquePersonsAtRiskOptions(
              personsAtRiskOptions,
              coshhDocument.personsAtRisk,
              secondaryFieldLabel,
            )}
            placeholder='Select person(s)'
            tooltip={'People who could be harmed when using the product'}
            value={
              coshhDocument.personsAtRisk ? coshhDocument.personsAtRisk : ''
            }
          />
          {displayPersonsAtRiskOtherField && (
            <TextField
              fieldError={requestError.validationErrors.personsAtRiskOther}
              inputRef={selectOtherFieldRef}
              isRequired={false}
              label=''
              maxLength={255}
              name={'personsAtRiskOther'}
              onChange={onCoshhInputChange}
              placeholder={'e.g. Security'}
              removeErrorStyling={removeErrorStyling}
              value={coshhDocument.personsAtRiskOther}
              withLabel={false}
            />
          )}
          <CollectionSelect
            alignTooltipCenter={false}
            customMargin={
              displayWorkerExposureTimeOtherField ? 'm-b-8' : 'm-b-32'
            }
            isClearable={true}
            isSearchable={false}
            label='Duration of exposure'
            maxMenuHeight={'unset'}
            name='workerExposureTime'
            onChange={onCoshhOptionChange}
            options={workerExposureTimeOptions.map((option) => ({
              label: option,
              value: option,
            }))}
            placeholder='Select duration'
            tooltip={durationOfExposureTooltip}
            tooltipClassName='tooltip-dark--text-left'
            value={
              coshhDocument.workerExposureTime ?
                {
                  label: coshhDocument.workerExposureTime,
                  value: coshhDocument.workerExposureTime,
                }
              : ''
            }
          />
          {displayWorkerExposureTimeOtherField && (
            <TextField
              fieldError={requestError.validationErrors.workerExposureTimeOther}
              isRequired={false}
              label=''
              maxLength={255}
              name={'workerExposureTimeOther'}
              onChange={onCoshhInputChange}
              placeholder={'e.g. 16 hours'}
              removeErrorStyling={removeErrorStyling}
              value={coshhDocument.workerExposureTimeOther}
              withLabel={false}
            />
          )}
          <CollectionSelect
            customMargin={
              displayExposureFrequencyOtherField ? 'm-b-8' : 'm-b-32'
            }
            isClearable={true}
            isSearchable={false}
            label='Frequency of exposure'
            maxMenuHeight={'unset'}
            name='exposureFrequency'
            onChange={onCoshhOptionChange}
            options={exposureFrequencyOptions.map((option) => ({
              label: option,
              value: option,
            }))}
            placeholder='Select frequency'
            tooltip={
              'How often person(s) is/are likely to be in contact with the product'
            }
            value={
              coshhDocument.exposureFrequency ?
                {
                  label: coshhDocument.exposureFrequency,
                  value: coshhDocument.exposureFrequency,
                }
              : ''
            }
          />
          {displayExposureFrequencyOtherField && (
            <TextField
              fieldError={requestError.validationErrors.exposureFrequencyOther}
              isRequired={false}
              label=''
              maxLength={255}
              name={'exposureFrequencyOther'}
              onChange={onCoshhInputChange}
              placeholder={'e.g. 2 times a week'}
              removeErrorStyling={removeErrorStyling}
              value={coshhDocument.exposureFrequencyOther}
              withLabel={false}
            />
          )}
          <CollectionSelect
            customMargin={'m-b-32'}
            isClearable={true}
            isSearchable={false}
            label='Location of use'
            maxMenuHeight={'unset'}
            name='location'
            onChange={onCoshhOptionChange}
            options={locationOptions.map((option) => ({
              label: option,
              value: option,
            }))}
            placeholder='Select location'
            tooltip={'Where is the product going to be used'}
            value={
              coshhDocument.location ?
                { label: coshhDocument.location, value: coshhDocument.location }
              : ''
            }
          />
          <CollectionSelect
            customMargin={'m-b-32'}
            isClearable={true}
            isSearchable={false}
            label='Level of risk'
            maxMenuHeight={'unset'}
            modifiers={['enlarged']}
            name='levelOfRisk'
            onChange={onCoshhOptionChange}
            options={levelOfRiskOptions}
            placeholder='Select risk level'
            tooltip={
              'Risk level of using the product(s) before any controls, based upon the likelihood and severity of an incident'
            }
            value={
              coshhDocument.levelOfRisk ?
                {
                  label: (
                    <LevelOfRiskIcon
                      option={coshhDocument.levelOfRisk}
                      subtextProps={{ className: 'mw-200' }}
                    />
                  ),
                  value: coshhDocument.levelOfRisk,
                }
              : ''
            }
          />
          <TextArea
            label='Control measures and additional information'
            name='additionalInformation'
            onChange={onCoshhInputChange}
            resizeVertical={true}
            tooltip={controlMeasuresTooltip}
            tooltipClassName='tooltip-dark--text-left'
            value={coshhDocument.additionalInformation}
          />
        </div>
      </div>
    </div>
  );
}

CoshhForm.propTypes = {
  coshhDocument: PropTypes.object.isRequired,
  onCoshhInputChange: PropTypes.func.isRequired,
  onCoshhOptionChange: PropTypes.func.isRequired,
  workerExposureTimeOptions: PropTypes.array.isRequired,
  exposureFrequencyOptions: PropTypes.array.isRequired,
  locationOptions: PropTypes.array.isRequired,
  methodOfUseOptions: PropTypes.array.isRequired,
  secondaryFieldLabel: PropTypes.string.isRequired,
};
