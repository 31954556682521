import React from 'react';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import PropTypes from 'prop-types';

export default function AdditionalDestroyModalContent({
  hasELearningBookings,
}) {
  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();

  return (
    hasELearningBookings ?
      <p className='m-0 m-t-20 tw-rounded-lg tw-border-0 tw-bg-red-025 tw-p-3 tw-text-red-800'>
        This course has eLearning booked which will be cancelled. Any courses in
        progress will not be refunded.
      </p>
    : trainingRegisterResourceManagementContext.isMultiDivisionAccount ?
      <>
        <p className='fwt-600 m-t-24 tw-text-m tw-text-red-700'>
          You may lose any bookings made for this course
        </p>
        <ul className='m-0 m-t-8 tw-rounded-lg tw-border-0 tw-bg-red-025 tw-p-3 tw-text-red-800'>
          <li className='m-l-16'>
            Personnel training bookings related to this course will be lost
          </li>
          <li className='m-l-16'>
            These might include personnel in divisions you do not have access to
          </li>
        </ul>
      </>
    : null
  );
}

AdditionalDestroyModalContent.propTypes = {
  hasELearningBookings: PropTypes.bool.isRequired,
};
