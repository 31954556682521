import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatSubcontractorOption } from 'components/helpers/companies';
import CollectionSelect from 'components/application/CollectionSelect';

export default function SelectSubcontractor(props) {
  const {
    assignedSubcontractor,
    fieldError,
    isSubcontractor,
    label,
    onChange,
    onSubcontractorSelect,
    primaryDivision,
    removeErrorStyling,
    sidePanelContext,
    subcontractor,
    subcontractors,
  } = props;

  useEffect(() => {
    resetFields();

    return () => {
      resetFields();
    };
  }, [isSubcontractor]);

  const resetFields = () => {
    if (fieldError && sidePanelContext === 'new') {
      removeErrorStyling({ target: { name: 'name' } });
    }
    onSubcontractorSelect({ subcontractorName: '' });
  };

  const handleSelection = (selection, actionMeta) => {
    if (fieldError) {
      removeErrorStyling({ target: { name: 'name' } });
    }
    switch (actionMeta.action) {
      case 'select-option':
        onSubcontractorSelect({ subcontractorName: '' });
        onChange(selection, actionMeta);
        break;
      case 'create-option':
        onSubcontractorSelect({ subcontractorName: selection.value });
        break;
    }
  };

  const assignableOptions = () => {
    return subcontractors.map((subcon) =>
      formatSubcontractorOption(
        primaryDivision.id,
        subcon.id,
        subcon.attributes.name,
      ),
    );
  };

  const modifiers =
    fieldError ?
      fieldError.fieldHighlighted ?
        ['typeable-search', 'invalid']
      : ['typeable-search']
    : ['typeable-search'];
  const subcontractorValue =
    subcontractor.subcontractorName === '' ?
      (
        assignedSubcontractor &&
        assignedSubcontractor.attributes.resourceType === 'Subcontractor'
      ) ?
        formatSubcontractorOption(
          primaryDivision.id,
          assignedSubcontractor.id,
          assignedSubcontractor.attributes.name,
        )
      : ''
    : formatSubcontractorOption(
        primaryDivision.id,
        '',
        subcontractor.subcontractorName,
      );

  return (
    <div className={`m-l-30`}>
      <CollectionSelect
        isCreatable
        isRequired={isSubcontractor}
        isSingleSelect
        label={label}
        modifiers={modifiers}
        name='company'
        onChange={handleSelection}
        options={assignableOptions()}
        value={subcontractorValue}
      />
    </div>
  );
}

SelectSubcontractor.propTypes = {
  primaryDivision: PropTypes.object,
  subcontractors: PropTypes.array.isRequired,
  sidePanelContext: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

SelectSubcontractor.defaultProps = {
  subcontractor: { subcontractorName: '' },
};
