import HandshqApp from '../handshq-app';

HandshqApp.SortableRecords = function (node) {
  if (this instanceof HandshqApp.SortableRecords) {
    this.setup = function () {
      $(node).sortable({
        stop: droppedItem,
        update: updatePositions,
        helper: $(node).data('clone') || sortableHelper,
        axis: $(node).data('axis'),
        items: '> ' + $(node).data('items') + ':not(.disabled-sortable)',
        handle: $(node).data('handle'),
        tolerance: 'pointer',
      });
    };

    function droppedItem(e, ui) {
      ui.item.trigger('sortabledropped');
    }

    function updatePositions(_e, _ui) {
      $(this).sortable('disable');

      $.ajax({
        url: $(this).data('url'),
        type: 'PATCH',
        data: $(this).sortable('serialize'),
        success: $(this).sortable('enable'),
      });
    }

    function sortableHelper(e, ui) {
      const queue = [];

      if (ui[0].tagName === 'TBODY') {
        queue.push(ui.children().first());
      } else {
        queue.push(ui.children());
      }

      while (queue.length) {
        const el = queue.shift();
        const elWidth = $(el).width();
        const elChildren = $(el).children();

        elWidth !== 0 && $(el).width(elWidth);
        elWidth !== 0 &&
          elChildren.length !== 0 &&
          elChildren.each(function () {
            queue.push(this);
          });
      }

      return ui;
    }
  } else {
    throw new Error('HandshqApp.SortableRecords invoked without new');
  }
};

$(function () {
  if ($('.sortable-records').length) {
    $('.sortable-records').each(function () {
      new HandshqApp.SortableRecords(this).setup();
    });
  }
});
