import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { uniqueTrainingCollectionByCourse } from 'components/helpers/resources/training';
import { getTrainingStatus } from 'components/helpers/resources/training';
import { trainingStatusReport } from 'components/helpers/resources/training';

import Tabs from 'components/application/Tabs';
import PersonnelRoleTrainingStatusIndicator from 'components/training/PersonnelRoleTrainingStatusIndicator';
import RoleTrainingTab from 'components/training/RoleTrainingTab';
import TrainingEventsTab from 'components/trainingEvents/TrainingEventsTab';
import TrainingModalBox from 'components/training/TrainingModalBox';

import ClockIcon from '-!svg-react-loader?name=ClockIcon!icons/clock.svg';

export default function TrainingRecords(props) {
  const defaultModalBoxData = {
    training: null,
    course: null,
    loaded: false,
    attachments: [],
    context: 'notes',
  };

  const [modalBoxData, setModalBoxData] = useState(defaultModalBoxData);

  const isArchived = !!props.currentPersonnel.archivedAt;

  const individualCourseTrainingStatusIds = {
    missing: [],
    expiryDateMissing: [],
    expired: [],
    invalid: [],
    expiring: [],
    valid: [],
  };

  const uniqueTrainingCollection = uniqueTrainingCollectionByCourse({
    training: props.training,
  });

  const trainingsMap = new Map();
  uniqueTrainingCollection.forEach((training) => {
    trainingsMap.set(training.relationships.course.data.id, training);
  });

  const individuallyRequiredCourses = props.userCourses.reduce(
    (acc, userCourse) => {
      if (userCourse.attributes.required) {
        const requiredCourse =
          props.courses[userCourse.relationships.course.data.id];
        const requiredTraining = trainingsMap.get(requiredCourse.id);
        const status = getTrainingStatus({
          training: requiredTraining,
          course: requiredCourse,
        });

        individualCourseTrainingStatusIds[status].push(requiredCourse.id);
        acc.push(requiredCourse);
      }
      return acc;
    },
    [],
  );

  const trainingTabs =
    props.roles.length === 0 ?
      [
        <RoleTrainingTab
          attachments={props.attachments}
          autoEnrolmentConfigurations={props.autoEnrolmentConfigurations}
          autoEnrolmentExclusions={props.autoEnrolmentExclusions}
          bookers={props.bookers}
          courseCompanyRoles={props.courseCompanyRoles}
          courses={props.courses}
          coursesMeta={props.coursesMeta}
          creditInfoUnavailable={props.creditInfoUnavailable}
          currentBookings={props.currentBookings}
          currentPersonnel={props.currentPersonnel}
          currentRegistrations={props.currentRegistrations}
          eLearningAllowance={props.eLearningAllowance}
          eLearningCourses={props.eLearningCourses}
          hasTrainingEditableAccess={props.hasTrainingEditableAccess}
          isArchived={isArchived}
          key='blankRoleTrainingTab'
          label='Training'
          onAddBooking={props.onAddBooking}
          onAddELearningBooking={props.onAddELearningBooking}
          onAddRole={props.onAddRole}
          onBookingReminderClick={props.onBookingReminderClick}
          onCourseSelectChange={props.onCourseSelectChange}
          onCourseSelectError={props.onCourseSelectError}
          onCreateAutoEnrolmentExclusion={props.onCreateAutoEnrolmentExclusion}
          onCreateUserCourse={props.onCreateUserCourse}
          onDeleteAutoEnrolmentExclusion={props.onDeleteAutoEnrolmentExclusion}
          onDeleteCourseClick={props.onDeleteCourseClick}
          onDeleteTrainingClick={props.onDeleteTrainingClick}
          onEditTrainingClick={props.onEditTrainingClick}
          onNewTrainingClick={props.onNewTrainingClick}
          onRemoveBooking={props.onRemoveBooking}
          onShowBooking={props.onShowBooking}
          onTrainingEvidenceProcessed={props.onTrainingEvidenceProcessed}
          onUpdateUserCourse={props.onUpdateUserCourse}
          personnelId={props.personnelId}
          role={null}
          roles={props.roles}
          setModalBoxData={setModalBoxData}
          training={props.training}
          trainingEvidenceProcessed={props.trainingEvidenceProcessed}
          trainingStatusReport={trainingStatusReport({
            courses: props.courses,
            requiredCourses: individuallyRequiredCourses,
            courseTrainingStatusIds: individualCourseTrainingStatusIds,
            training: props.training,
          })}
          userCourses={props.userCourses}
        />,
      ]
    : props.roles.map((companyRole) => {
        // Clone the individualCourseTrainingStatusIds object to avoid copying over ids from the previous object when mapping over roles
        const requiredRoleCourseTrainingStatusIds = _.cloneDeep(
          individualCourseTrainingStatusIds,
        );
        const tabCompanyRoleCourses =
          companyRole.relationships.courses.data.map(
            (companyRoleCourse) => props.courses[companyRoleCourse.id],
          );
        const requiredRoleCourses = tabCompanyRoleCourses.reduce(
          (acc, course) => {
            const currentCcr = props.courseCompanyRoles.find(
              (ccr) =>
                ccr.relationships.companyRole.data.id == companyRole.id &&
                ccr.relationships.course.data.id == course.id,
            );

            if (currentCcr.attributes.required) {
              const requiredCourse =
                props.courses[currentCcr.relationships.course.data.id];
              const requiredTraining = trainingsMap.get(requiredCourse.id);
              const status = getTrainingStatus({
                training: requiredTraining,
                course: requiredCourse,
              });

              requiredRoleCourseTrainingStatusIds[status].push(
                requiredCourse.id,
              );
              acc.push(requiredCourse);
            }
            return acc;
          },
          [],
        );

        const requiredCourses = [
          ...requiredRoleCourses,
          ...individuallyRequiredCourses,
        ];
        const roleTrainingStatusReport =
          isArchived ?
            { status: 'archived', courses: [] }
          : trainingStatusReport({
              companyRole,
              courseTrainingStatusIds: requiredRoleCourseTrainingStatusIds,
              courses: props.courses,
              requiredCourses,
              training: props.training,
            });

        return (
          <div
            icon={
              props.roles.length > 1 ?
                <div className='flex m-r-6'>
                  <PersonnelRoleTrainingStatusIndicator
                    status={roleTrainingStatusReport.status}
                  />
                </div>
              : undefined
            }
            key={`companyRoleTrainingTab--${companyRole.id}`}
            label={
              props.roles.length === 1 ?
                'Training'
              : companyRole.attributes.position
            }
          >
            <RoleTrainingTab
              attachments={props.attachments}
              autoEnrolmentConfigurations={props.autoEnrolmentConfigurations}
              autoEnrolmentExclusions={props.autoEnrolmentExclusions}
              bookers={props.bookers}
              courseCompanyRoles={props.courseCompanyRoles}
              courses={props.courses}
              coursesMeta={props.coursesMeta}
              creditInfoUnavailable={props.creditInfoUnavailable}
              currentBookings={props.currentBookings}
              currentPersonnel={props.currentPersonnel}
              currentRegistrations={props.currentRegistrations}
              eLearningAllowance={props.eLearningAllowance}
              eLearningCourses={props.eLearningCourses}
              hasTrainingEditableAccess={props.hasTrainingEditableAccess}
              isArchived={isArchived}
              onAddBooking={props.onAddBooking}
              onAddELearningBooking={props.onAddELearningBooking}
              onBookingReminderClick={props.onBookingReminderClick}
              onCourseSelectChange={props.onCourseSelectChange}
              onCourseSelectError={props.onCourseSelectError}
              onCreateAutoEnrolmentExclusion={
                props.onCreateAutoEnrolmentExclusion
              }
              onCreateUserCourse={props.onCreateUserCourse}
              onDeleteAutoEnrolmentExclusion={
                props.onDeleteAutoEnrolmentExclusion
              }
              onDeleteCourseClick={props.onDeleteCourseClick}
              onDeleteTrainingClick={props.onDeleteTrainingClick}
              onEditTrainingClick={props.onEditTrainingClick}
              onNewTrainingClick={props.onNewTrainingClick}
              onRemoveBooking={props.onRemoveBooking}
              onShowBooking={props.onShowBooking}
              onTrainingEvidenceProcessed={props.onTrainingEvidenceProcessed}
              onUpdateUserCourse={props.onUpdateUserCourse}
              personnelId={props.personnelId}
              role={companyRole}
              roles={props.roles}
              setModalBoxData={setModalBoxData}
              training={props.training}
              trainingEvidenceProcessed={props.trainingEvidenceProcessed}
              trainingStatusReport={roleTrainingStatusReport}
              userCourses={props.userCourses}
            />
          </div>
        );
      });

  if (props.hasPersonnelEventViewableAccess) {
    trainingTabs.push(
      <TrainingEventsTab
        disabled={props.personnelEvents.collection.length === 0}
        fetchPersonnelEvents={props.fetchPersonnelEvents}
        icon={
          <span>
            <ClockIcon
              className='[&_path]:tw-fill-grey-500'
              height={16}
              width={16}
            />
          </span>
        }
        key='personnelEventsTab'
        label='activity'
        metaData={props.personnelEvents.meta}
        personnelEvents={props.personnelEvents.collection}
        personnelId={props.personnelId}
        profilePhotos={props.personnelEvents.profilePhotos}
        tooltipText={'No training activity'}
        users={props.personnelEvents.users}
      />,
    );
  }

  return (
    <>
      <Tabs modifiers={['truncated']}>{trainingTabs}</Tabs>
      <TrainingModalBox data={modalBoxData} />
    </>
  );
}

TrainingRecords.propTypes = {
  roles: PropTypes.array.isRequired,
  training: PropTypes.array.isRequired,
  bookers: PropTypes.object,
  personnelEvents: PropTypes.object.isRequired,
  profilePhotos: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  currentPersonnel: PropTypes.object.isRequired,
  currentBookings: PropTypes.array,
  currentRegistrations: PropTypes.array,
  attachments: PropTypes.array,
  courses: PropTypes.object,
  autoEnrolmentConfigurations: PropTypes.object,
  autoEnrolmentExclusions: PropTypes.object,
  eLearningCourses: PropTypes.object,
  eLearningAllowance: PropTypes.object,
  courseCompanyRoles: PropTypes.array,
  onBookingReminderClick: PropTypes.func.isRequired,
  hasTrainingEditableAccess: PropTypes.bool,
  onShowBooking: PropTypes.func.isRequired,
  onRemoveBooking: PropTypes.func.isRequired,
  onCreateAutoEnrolmentExclusion: PropTypes.func,
  onDeleteAutoEnrolmentExclusion: PropTypes.func,
  onAddBooking: PropTypes.func.isRequired,
  onAddELearningBooking: PropTypes.func.isRequired,
  onUpdateUserCourse: PropTypes.func.isRequired,
  onCreateUserCourse: PropTypes.func.isRequired,
  onNewTrainingClick: PropTypes.func.isRequired,
  onEditTrainingClick: PropTypes.func.isRequired,
  onDeleteTrainingClick: PropTypes.func.isRequired,
  onDeleteCourseClick: PropTypes.func.isRequired,
  onCourseSelectChange: PropTypes.func.isRequired,
  onCourseSelectError: PropTypes.func.isRequired,
  onTrainingEvidenceProcessed: PropTypes.func.isRequired,
  fetchPersonnelEvents: PropTypes.func.isRequired,
};

TrainingRecords.defaultProps = {
  personnelEvents: { collection: [], meta: {} },
  currentBookings: [],
  currentRegistrations: [],
};
