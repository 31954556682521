import React, { useState } from 'react';
import axios from 'axios';

import { urlsafeEncode64 } from 'components/helpers/base64';
import { submitSAMLRequest } from 'components/saml/helpers';

import useForm from 'components/hooks/useForm';

export default function SamlSignInPage() {
  const [formDetails, , handleInputChange] = useForm({ email: '' });
  const [isError, setIsError] = useState(false);

  const encodedDomain = () => {
    const emailParts = formDetails.email.split('@');
    const domain = emailParts[emailParts.length - 1];

    return urlsafeEncode64(domain);
  };

  const handleSubmit = () => {
    setIsError(false);

    axios
      .get(`/users/saml/domains/${encodedDomain()}`)
      .then((response) => {
        const identityProvider = response.data.included.find(
          (inclusion) => inclusion.type === 'identityProvider',
        );

        submitSAMLRequest({
          identityProviderUuid: identityProvider.attributes.uuid,
        });
      })
      .catch((_error) => setIsError(true));
  };

  return (
    <>
      {isError && (
        <div className='devise-errors alert alert-danger'>
          <p>
            Single sign-on has not been configured for your email address or
            domain yet. Try a different log in method or contact your
            administrator.
          </p>
        </div>
      )}
      <div className='form-container form-horizontal signin-form tw-border-white tw-bg-white'>
        <input
          autoFocus
          className='form-control tw-border-grey-300 tw-text-l tw-font-normal tw-tracking-auto placeholder:tw-text-grey-300 focus-within:tw-border-blue-300 hover:tw-border-grey-400 hover:focus-within:tw-border-blue-300'
          id='sso_email'
          name='email'
          onChange={handleInputChange}
          placeholder='Email'
          type='email'
          value={formDetails.email}
        />
        <fieldset className='form-actions'>
          {/* would be nice to add a size prop to FilledButton and import it */}
          <button
            className='tw-apply-filled-button tw-apply-filled-button--lg tw-apply-filled-button--mint tw-apply-filled-button--on-light tw-w-full tw-justify-center'
            onClick={handleSubmit}
            type='submit'
          >
            Log in with SSO
          </button>
        </fieldset>
        <div className='secondary-links secondary-links--centered tw-m-0 tw-mt-5 tw-text-grey-900'>
          <div>
            <a
              className="tw-font-semibold tw-text-grey-900 before:tw-bg-mint-200 before:tw-content-[''] hover:tw-text-mint-200"
              href='/'
            >
              Log in using a different method
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
