import React from 'react';
import PropTypes from 'prop-types';

import ModalBox from 'components/application/ModalBox';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function UpdateConfirmationModal(props) {
  const { displayText, isOpen, onClose, onSave } = props;

  const onSaveClick = () => {
    onClose();
    onSave();
  };

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton className='m-r-12' color='blue' onClick={onClose} size='md'>
        Cancel
      </TextButton>
      <FilledButton color='mint' onClick={onSaveClick}>
        Save changes
      </FilledButton>
    </div>
  );

  return (
    <ModalBox
      customFooter={customFooter}
      isOpen={isOpen}
      mode='flexible'
      onClose={onClose}
    >
      <div className='p-32'>
        <h2 className='m-0 p-0 tw-text-l tw-font-semibold tw-text-grey-900'>
          Are you sure you want to save changes?
        </h2>
        <p className='m-0 m-t-20 tw-rounded-lg tw-border-0 tw-bg-red-025 tw-p-3 tw-text-red-800'>
          {displayText}
        </p>
      </div>
    </ModalBox>
  );
}

UpdateConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  displayText: PropTypes.string.isRequired,
};
