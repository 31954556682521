import React from 'react';
import PropTypes from 'prop-types';

import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from 'react-query-settings/queryClient';

import { sendAnalytics } from 'components/helpers/analytics';

import { RamsAnalyticsDashboardContext } from 'components/contexts/RamsAnalyticsDashboardContext';

import AppWrapper from 'components/apps/AppWrapper';
import RamsAnalyticsDashboardPage from 'components/pages/RamsAnalyticsDashboardPage';

export default function RamsAnalyticsDashboardApp({
  contextProps,
  wrappedProps,
}) {
  const currentUser = contextProps.actors.user;
  const account = contextProps.actors.account;

  const { ramsPluralName, ramsSingularName } = wrappedProps;

  return (
    <AppWrapper {...contextProps}>
      <RamsAnalyticsDashboardContext.Provider
        value={{
          ...wrappedProps,
          sendAnalytics: (analyticTrackingId, args) =>
            sendAnalytics(analyticTrackingId, {
              ...args,
              currentUser,
              accountId: account.id,
              accountName: account.attributes.name,
            }),
          pluralizeRamsCount: (args) =>
            pluralizeRamsCount({ ...args, ramsSingularName, ramsPluralName }),
        }}
      >
        <QueryClientProvider client={queryClient}>
          <RamsAnalyticsDashboardPage />
        </QueryClientProvider>
      </RamsAnalyticsDashboardContext.Provider>
    </AppWrapper>
  );
}

RamsAnalyticsDashboardApp.propTypes = {
  wrappedProps: PropTypes.shape({
    isApprovalsEnabled: PropTypes.bool.isRequired,
    isDigitalSignaturesEnabled: PropTypes.bool.isRequired,
    isRiskRegisterEnabled: PropTypes.bool.isRequired,
    isProjectReviewDatesEnabled: PropTypes.bool.isRequired,
    isClientEnabled: PropTypes.bool.isRequired,
    ramsSingularName: PropTypes.string.isRequired,
    ramsPluralName: PropTypes.string.isRequired,
  }),
};

export function pluralizeRamsCount({
  count,
  prefix = null,
  ramsPluralName,
  ramsSingularName,
}) {
  const ramsName = count === 1 ? ramsSingularName : ramsPluralName;

  return `${count.toLocaleString()} ${prefix ? `${prefix} ` : ''}${ramsName}`;
}
