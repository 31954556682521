import moment from 'moment';

const shortMonthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function generateMonthsMap(startMonth = 1, endMonth = 12) {
  return shortMonthNames
    .slice(startMonth - 1, endMonth)
    .reduce((obj, month) => {
      obj[shortMonthNames.indexOf(month) + 1] = { count: 0, deletedCount: 0 };

      return obj;
    }, {});
}

export function generateChartProps(reportData) {
  const chartProps = {};
  const countsIndexedByDateRange = {};

  (function initialiseChartProps() {
    const current = moment();

    switch (reportData.data.attributes.dateRange) {
      case 'this_year':
        const currentAsYear = current.year();
        countsIndexedByDateRange[currentAsYear] = generateMonthsMap();
        chartProps.xAxisLabel = currentAsYear;
        break;
      case 'last_year':
        const lastYear = current
          .clone()
          .subtract(1, 'years')
          .startOf('year')
          .year();
        countsIndexedByDateRange[lastYear] = generateMonthsMap();
        chartProps.xAxisLabel = lastYear;
        break;
      case 'all_time':
        const earliestDate = moment(reportData.meta.accountCreatedAt);
        const yearsDiff = current
          .clone()
          .startOf('year')
          .diff(earliestDate.clone().startOf('year'), 'years');
        for (let i = 0; i <= yearsDiff; i++) {
          const startingMonth = i === yearsDiff ? earliestDate.month() + 1 : 1;
          const endingMonth = i === 0 ? current.month() + 1 : 12;
          const yearInScope = current.clone().subtract(i, 'years').year();
          countsIndexedByDateRange[yearInScope] = generateMonthsMap(
            startingMonth,
            endingMonth,
          );
        }
        chartProps.xAxisLabel = `${earliestDate.format('MMM')} ${earliestDate.year()} - ${current.format('MMM')} ${current.year()}`;
        break;
    }
  })();

  (function populateCountsIndexFromReport() {
    reportData.data.attributes.countBreakdown.forEach((rangeData) => {
      countsIndexedByDateRange[rangeData.year_range][rangeData.month_range] = {
        projectCount: rangeData.project_count,
        deletedProjectCount: rangeData.deleted_project_count,
      };
    });
  })();

  chartProps.data = (function constructdataFromProjectsCountIndex() {
    return Object.entries(countsIndexedByDateRange).reduce(
      (data, yearRange) => {
        const [year, monthCounts] = yearRange;

        Object.entries(monthCounts).forEach((monthRange) => {
          const [month, counts] = monthRange;
          const name = shortMonthNames[month - 1];
          const countKey = `${year}${month}`;
          const yearMonth = `${year}-${('0' + month).slice(-2)}`;

          data.push({
            countKey,
            yearMonth,
            name,
            count: counts.projectCount,
            deletedCount: counts.deletedProjectCount,
          });
        });

        return data;
      },
      [],
    );
  })();

  return chartProps;
}

export const defaultChartProps = { data: [], xAxisLabel: undefined };
