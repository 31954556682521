import { useRef, useState, useEffect } from 'react';

import { saveAs } from 'file-saver';

import Polling from 'handshq-app/utilities/polling';

export default function useReportGenerator() {
  const jobStatusPolling = useRef(null);
  const [reportGenerator, setReportGenerator] = useState({
    isOpen: false,
    progress: 'initial',
    reportUrl: null,
  });

  const setInitial = () =>
    setReportGenerator({ isOpen: false, progress: 'initial', reportUrl: null });
  const setGenerating = () =>
    setReportGenerator({
      isOpen: true,
      progress: 'generating',
      reportUrl: null,
    });
  const setCompleted = ({ reportUrl }) =>
    setReportGenerator({
      isOpen: true,
      progress: reportUrl ? 'downloadReady' : 'noResults',
      reportUrl: reportUrl,
    });
  const setFailed = () =>
    setReportGenerator({ isOpen: true, progress: 'failed', reportUrl: null });

  const startJobStatusPolling = ({ url }) => {
    jobStatusPolling.current = new Polling({
      interval: 1000,
      maxTime: 600000,
      url: url,
      responseCheck: handlePollingResponseCheck,
      successCallback: handlePollingSuccess,
      failureCallback: handlePollingFailure,
    });
    jobStatusPolling.current.start();
  };

  const handlePollingResponseCheck = (response) => {
    if (response.status === 'failed') {
      endJobStatusPolling();
      setFailed();
    } else {
      return response.status === 'completed';
    }
  };

  const handlePollingSuccess = (response) => {
    setCompleted({ reportUrl: response.report_url });
  };

  const handlePollingFailure = () => {
    setFailed();
  };

  const endJobStatusPolling = () => {
    if (jobStatusPolling.current) {
      jobStatusPolling.current.end();
      jobStatusPolling.current = null;
    }
  };

  const startReportGeneration = ({ params }) => {
    setGenerating();

    axios
      .post('/training_register_reports', params)
      .then((response) => {
        startJobStatusPolling({ url: response.data.links.job });
      })
      .catch(() => {
        setFailed();
      });
  };

  const endReportGeneration = () => {
    endJobStatusPolling();
    setInitial();
  };

  const downloadReport = () => {
    if (reportGenerator.reportUrl) {
      saveAs(reportGenerator.reportUrl);
      setInitial();
    } else {
      setFailed();
    }
  };

  useEffect(() => {
    return () => {
      endJobStatusPolling();
    };
  }, []);

  return [
    reportGenerator,
    startReportGeneration,
    endReportGeneration,
    downloadReport,
  ];
}
