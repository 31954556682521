import { APIProvider } from '@vis.gl/react-google-maps';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  formatOpeningHours,
  formatServiceAge,
  mapConfigurationShape,
} from './helpers';
import { resourceShape } from 'components/helpers/serialisableResources';
import CheckboxField from '../application/CheckboxField';
import CloseIcon from '-!svg-react-loader?name=CloseIcon!icons/ic-close.svg';
import HandshqApp from 'handshq-app/handshq-app';
import LocationOffIcon from '-!svg-react-loader?name=LocationOffIcon!icons/location-off.svg';
import MedicalLocationMap from './MedicalLocationMap';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import Tooltip from 'components/application/Tooltip';

export default function MedicalLocationCard({
  domainMapConfiguration,
  domainMapConfigurationImageUrl,
  isEditable,
  mapConfiguration,
  medicalLocation,
  onIncludeMapCheckboxChange,
  onMapCameraChanged,
  onRemoveClick,
  showMap,
  siteCoordinates,
}) {
  const [isEditing, setIsEditing] = useState(
    !domainMapConfigurationImageUrl ||
      medicalLocation.type === 'assignableMedicalLocation',
  );

  const openingHours = formatOpeningHours({
    hours: medicalLocation.attributes.openingHours,
  });

  return (
    <div className='tw-relative tw-flex tw-w-full tw-max-w-xl tw-gap-x-4 tw-rounded-lg tw-border-1 tw-border-solid tw-border-grey-200 tw-bg-white tw-p-5'>
      <CloseIcon
        className='tw-absolute tw-right-3 tw-top-3 tw-h-7 tw-w-7 tw-cursor-pointer [&_polygon]:tw-fill-grey-500'
        onClick={onRemoveClick}
      />
      <div className='tw-flex tw-w-full tw-flex-col tw-gap-y-4'>
        <div className='tw-flex tw-flex-col tw-gap-y-2'>
          <div>
            <p className='tw-m-0 tw-text-l tw-font-bold tw-text-grey-900'>
              {medicalLocation.attributes.name}
            </p>
            {!!openingHours && (
              <p className='tw-m-0 tw-text-m tw-text-grey-700'>
                {openingHours}
              </p>
            )}
          </div>
          {medicalLocation.attributes.source === 'nhs' && (
            <p className='tw-m-0 tw-text-s tw-text-grey-700'>
              Services for:{' '}
              {formatServiceAge({
                maxAge: medicalLocation.attributes.maxAge,
                minAge: medicalLocation.attributes.minAge,
              })}
            </p>
          )}
        </div>
        <div className='tw-flex tw-flex-col tw-gap-y-2 tw-text-s tw-text-grey-700'>
          <div>
            {!!medicalLocation.attributes.address1 && (
              <p className='tw-m-0'>{medicalLocation.attributes.address1},</p>
            )}
            {!!medicalLocation.attributes.address2 && (
              <p className='tw-m-0'>{medicalLocation.attributes.address2},</p>
            )}
            <p className='tw-m-0'>
              {!!medicalLocation.attributes.city &&
                `${medicalLocation.attributes.city}, `}
              {medicalLocation.attributes.postcode}
            </p>
          </div>
          {!!medicalLocation.attributes.phoneNumber && (
            <p className='tw-m-0'>{medicalLocation.attributes.phoneNumber}</p>
          )}
        </div>
        {medicalLocation.attributes.source === 'nhs' && (
          <img
            alt='NHS content'
            className='tw-h-8 tw-w-[122.23px] tw-border-1 tw-border-solid tw-border-grey-100 tw-bg-white tw-p-1'
            src={HandshqApp.rails.nhsImagePath}
          />
        )}
      </div>
      <div className='tw-flex tw-w-80 tw-flex-col tw-gap-y-2'>
        <CheckboxField
          checked={showMap}
          disabled={!isEditable}
          label='Include map'
          labelTextProps={{ className: 'tw-ml-3' }}
          name='includeMap'
          onChange={onIncludeMapCheckboxChange}
        />
        <Tooltip
          placement='bottom'
          tooltip='This feature is no longer enabled for this division/account'
          trigger={isEditable ? 'none' : 'hover'}
        >
          <div
            className={classNames('tw-h-60 tw-w-60', !showMap && 'tw-hidden')}
          >
            {isEditable && isEditing ?
              <APIProvider apiKey={HandshqApp.rails.googleMapsApiKey}>
                <MedicalLocationMap
                  domainMapConfiguration={domainMapConfiguration}
                  mapConfiguration={mapConfiguration}
                  medicalLocation={medicalLocation}
                  onCameraChanged={onMapCameraChanged}
                  siteCoordinates={siteCoordinates}
                />
              </APIProvider>
            : <div className='tw-pointer-events-auto tw-relative'>
                <img
                  alt='Map of Nearest A&E'
                  src={domainMapConfigurationImageUrl}
                />
                {isEditable && (
                  <div className='tw-absolute tw-top-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-bg-grey-900/30 tw-opacity-0 hover:tw-opacity-100'>
                    <OutlinedButton
                      color='white'
                      onClick={() => setIsEditing(true)}
                      size='sm'
                    >
                      Edit map
                    </OutlinedButton>
                  </div>
                )}
              </div>
            }
          </div>
          <div
            className={classNames(
              'tw-flex tw-h-60 tw-w-60 tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-rounded-lg tw-bg-grey-050',
              showMap && 'tw-hidden',
            )}
          >
            <LocationOffIcon />
            <p className='tw-m-0 tw-text-m tw-text-grey-500'>
              Map not included
            </p>
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

MedicalLocationCard.propTypes = {
  domainMapConfiguration: resourceShape('medicalLocationMapConfiguration'),
  domainMapConfigurationImageUrl: PropTypes.string,
  isEditable: PropTypes.bool.isRequired,
  mapConfiguration: PropTypes.shape(mapConfigurationShape).isRequired,
  medicalLocation: PropTypes.oneOfType([
    resourceShape('assignableMedicalLocation'),
    resourceShape('medicalLocation'),
  ]),
  onIncludeMapCheckboxChange: PropTypes.func.isRequired,
  onMapCameraChanged: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  showMap: PropTypes.bool.isRequired,
  siteCoordinates: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};
