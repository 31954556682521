import { connect } from 'react-redux';

import { getMember } from 'components/helpers/resources/attachments';
import { addError } from 'action-creators/ErrorActionCreators';

import AttachmentList from 'components/sequences/AttachmentList';

const mapStateToProps = (state, ownProps) => {
  const attachmentIds =
    state.ui.ordering[`methodStepAttachments--${ownProps.methodStepId}`].list;
  const existingAttachments = attachmentIds.map((attachmentId) =>
    getMember({
      attachment: state.domain.attachmentCollection.data[attachmentId],
      coverImages: state.domain.coverImageCollection.allDataIds.map(
        (coverImageId) => state.domain.coverImageCollection.data[coverImageId],
      ),
      attachments: state.domain.attachmentCollection.allDataIds.map(
        (attachmentId) => state.domain.attachmentCollection.data[attachmentId],
      ),
    }),
  );

  const attachmentKeys =
    state.ui.adding[`methodStepAttachmentKeys--${ownProps.methodStepId}`] || [];
  const newAttachments = attachmentKeys.map(
    (attachmentKey) => state.application.newAttachments[attachmentKey],
  );

  const attachments = [...existingAttachments, ...newAttachments];
  return { attachments };
};

const mapDispatchToProps = (dispatch, _ownProps) => {
  return {
    dispatch: dispatch,
    addError: (errorKey, payload) => dispatch(addError(errorKey, payload)),
  };
};

const AvailableAttachmentList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttachmentList);

export default AvailableAttachmentList;
