import React from 'react';
import PropTypes from 'prop-types';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import { formatOpeningHours, formatServiceAge } from './helpers';
import { resourceShape } from 'components/helpers/serialisableResources';

export default function MedicalLocationSelectItem({
  assignableMedicalLocation,
  onSelect,
}) {
  const openingHours = formatOpeningHours({
    hours: assignableMedicalLocation.attributes.openingHours,
  });

  return (
    <div className='tw-mt-2 tw-flex tw-w-full tw-max-w-xl tw-rounded-lg tw-border-1 tw-border-solid tw-border-grey-200 tw-bg-white tw-p-4'>
      <div className='tw-mr-5 tw-flex tw-w-2/4 tw-flex-col tw-justify-between tw-gap-4'>
        <div>
          <p className='tw-m-0 tw-text-l tw-font-bold tw-text-grey-900'>
            {assignableMedicalLocation.attributes.name}
          </p>
          <p className='tw-m-0 tw-text-m tw-text-grey-700'>
            {!!assignableMedicalLocation.attributes.distance &&
              `${parseFloat(assignableMedicalLocation.attributes.distance).toFixed(1)} miles`}
            {!!assignableMedicalLocation.attributes.distance &&
              !!openingHours &&
              '・'}
            {openingHours}
          </p>
        </div>
        {assignableMedicalLocation.attributes.source === 'nhs' && (
          <p className='tw-m-0 tw-text-s tw-text-grey-700'>
            This service is for:{' '}
            {formatServiceAge({
              maxAge: assignableMedicalLocation.attributes.maxAge,
              minAge: assignableMedicalLocation.attributes.minAge,
            })}
          </p>
        )}
      </div>

      <div className='tw-ml-2 tw-w-1/4'>
        <div className='tw-text-s tw-text-grey-700'>
          {!!assignableMedicalLocation.attributes.address1 && (
            <p className='tw-m-0'>
              {assignableMedicalLocation.attributes.address1},
            </p>
          )}
          {!!assignableMedicalLocation.attributes.address2 && (
            <p className='tw-m-0'>
              {assignableMedicalLocation.attributes.address2},
            </p>
          )}
          <p className='tw-m-0'>
            {!!assignableMedicalLocation.attributes.city &&
              `${assignableMedicalLocation.attributes.city}, `}
            {assignableMedicalLocation.attributes.postcode}
          </p>
        </div>
        {!!assignableMedicalLocation.attributes.phoneNumber && (
          <div className='tw-mt-2 tw-text-s'>
            {assignableMedicalLocation.attributes.phoneNumber}
          </div>
        )}
      </div>

      <div className='tw-flex tw-w-1/4 tw-justify-end'>
        <OutlinedButton
          className='tw-ml-2 tw-w-20 tw-justify-center'
          color='grey'
          onClick={() => onSelect(assignableMedicalLocation.id)}
          size='sm'
        >
          Select
        </OutlinedButton>
      </div>
    </div>
  );
}

MedicalLocationSelectItem.propTypes = {
  assignableMedicalLocation: resourceShape('assignableMedicalLocation')
    .isRequired,
  onSelect: PropTypes.func.isRequired,
};
