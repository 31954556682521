import React from 'react';

import { useRamsAnalyticsDashboardContext } from 'components/contexts/RamsAnalyticsDashboardContext';

import Grid from 'components/ramsAnalytics/components/Grid';
import CurrentProjectsCard from 'components/ramsAnalytics/components/CurrentProjectsCard';
import TopRisksCard from 'components/ramsAnalytics/components/TopRisksCard';
import ApprovalStatusCard from 'components/ramsAnalytics/components/ApprovalStatusCard';
import SignatureStatusCard from 'components/ramsAnalytics/components/SignatureStatusCard';
import ProjectsCreatedCard from 'components/ramsAnalytics/components/ProjectsCreatedCard';

export default function RamsAnalyticsDashboardPage() {
  const ramsAnalyticsDashboardContext = useRamsAnalyticsDashboardContext();

  return (
    <Grid>
      <CurrentProjectsCard />
      <TopRisksCard />
      {ramsAnalyticsDashboardContext.isApprovalsEnabled && (
        <ApprovalStatusCard />
      )}
      {ramsAnalyticsDashboardContext.isDigitalSignaturesEnabled && (
        <SignatureStatusCard />
      )}
      <ProjectsCreatedCard />
    </Grid>
  );
}
