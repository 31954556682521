import React from 'react';
import PropTypes from 'prop-types';
import ProcoreLogo from '-!svg-react-loader?name=ProcoreLogo!icons/procore_logo.svg';

export default function CardWrapper({ children, isInactive = false }) {
  return (
    <div
      className={`content-box fw-360 p-16 flex tw-border-grey-100 ${isInactive ? 'tw-bg-grey-025' : 'tw-bg-white'}`}
    >
      <div className='p-r-12'>
        <div className='flex flex--vertically-centered flex--justify-content__center bg-procore-orange tw-h-10 tw-w-10 tw-rounded-full'>
          <ProcoreLogo fill='white' height='16px' width='16px' />
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
}

CardWrapper.propTypes = {
  isInactive: PropTypes.bool,
  children: PropTypes.node,
};
