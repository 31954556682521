import { useState } from 'react';

export default function useFilters(initialFilters) {
  const [filters, setFilters] = useState(initialFilters);

  const handleFilterChange = (filter) => {
    setFilters({ ...filters, [filter]: !filters[filter] });
  };

  return {
    filters,
    handleFilterChange,
  };
}
