import HandshqApp from '../handshq-app';

HandshqApp.ToggleVisibility = function () {
  const toggleableSelector = $('.toggle-visibility--selector');
  const toggleableTarget = $('.toggle-visibility--target');

  if (toggleableSelector.length > 0) {
    toggleableSelector.on('click', toggleVisibility);
  }

  function toggleVisibility(_e) {
    toggleableTarget.toggle(toggleableSelector.is(':checked'));
  }
};

$(function () {
  HandshqApp.ToggleVisibility();
});
