import React from 'react';
import PropTypes from 'prop-types';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import Tooltip from 'components/application/Tooltip';

export default function FormFooter(props) {
  return (
    <div className='popup__footer-actions--flex transition-enabled tw-justify-end tw-border-t-grey-200'>
      <TextButton
        className='m-r-12'
        color='blue'
        onClick={props.onCancel}
        size='md'
      >
        Cancel
      </TextButton>
      <div className='tooltip-parent'>
        {props.onSubmitTooltip && (
          <Tooltip
            arrow
            placement='top'
            tooltip={props.onSubmitTooltip}
            trigger='hover'
          />
        )}
        <FilledButton
          color='mint'
          disabled={props.submitButtonDisabled}
          onClick={props.onSubmit}
        >
          {props.text}
        </FilledButton>
      </div>
    </div>
  );
}

FormFooter.propTypes = {
  text: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitTooltip: PropTypes.string,
};
