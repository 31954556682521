import { validateFileType, getUrlParameter } from './attachments';
import { browserBackButtonHandler } from './back-button';

$(document).ready(function () {
  const form = $('.upload-logo');

  addPopover('#company-documents .actions a');

  $('#companyLogoBtn').change(function (e) {
    const displayButton = e.currentTarget;
    validateFileType(this.children[0], form, displayButton);
  });

  // Checking to see if a new image has been added to the company logo and jump to it
  if (getUrlParameter('logo_upload')) {
    setTimeout(function () {
      window.location.hash = '#companyLogo';
    }, 50);
  }

  if ($('.user-profile').length != 0) {
    let profileChanged = false;
    // disable saving by default
    $('.save-button').addClass('disabled');

    const form_classes = ['.edit-user', '.edit-trades'];

    $.each(form_classes, function (index, name) {
      $(name + ' input').on('change keyup paste', function (_e) {
        profileChanged = true;
        $(name + ' .save-button').removeClass('disabled');
      });
    });

    window.onbeforeunload = function () {
      if (profileChanged) {
        return 'You are about to leave this page. Any changes you have made to the content will be lost. Are you sure you want to do this?';
      }
    };

    browserBackButtonHandler();
  }

  if ($('.context-switcher').length) {
    $('#viewable_company').change(function (_e) {
      window.location.href = '/companies/' + this.value;
    });
  }
});
