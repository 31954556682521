function createDocumentResource(resource) {
  return {
    attributes: {
      createdAt:
        resource.attributes.created_at || resource.attributes.createdAt,
      fileSize: resource.attributes.file_size || resource.attributes.fileSize,
    },
    links: {
      content: resource.links.content,
    },
  };
}

export { createDocumentResource };
