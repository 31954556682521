import React, { useState, useRef } from 'react';
import moment from 'moment';
import enGb from 'date-fns/locale/en-GB';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'style-loader!react-datepicker/dist/react-datepicker.min.css';
import classNames from 'classnames';

export function DatePicker(props) {
  const datePickerRef = props.inputRef || useRef();

  return <AppDatePicker {...props} datePickerRef={datePickerRef} />;
}

function AppDatePicker(props) {
  registerLocale('en-gb', enGb);

  const {
    className,
    datePickerRef,
    disabledPlaceholderText,
    earliestDate,
    fieldError,
    isDisabled,
    latestDate,
    name,
    onCalendarClose,
    onChange,
    placeholderText,
    placement,
    removeErrorStyling,
    value,
  } = props;

  const [picker, setPicker] = useState('day');

  const isDayPickerActive = picker === 'day';
  const isMonthPickerActive = picker === 'month';

  const handleDatePickerFocus = (event) => {
    setPicker('day');
    if (removeErrorStyling) removeErrorStyling(event);
  };

  const handleDatePickerChange = (date, event) => {
    if (isDayPickerActive) {
      onChange(name, date);
      // internal open state not available via prop, used class component API
      // would break if internals changed from class component to functional component
      if (event.type === 'click') datePickerRef.current.setOpen(false);
    } else {
      setPicker('day');
    }
  };

  const handleDatePickerHeaderButtonClick = () => {
    if (isDayPickerActive) setPicker('month');
  };

  const isInvalid = !!fieldError?.fieldHighlighted;

  const inputClassName = (() => {
    switch (true) {
      case isDisabled:
        return 'tw-bg-grey-025 tw-text-grey-400 tw-border-grey-200 hover:tw-border-grey-200 focus-within:tw-border-grey-200 hover:focus-within:tw-border-grey-200';
      case isInvalid:
        return 'tw-border-red-600 hover:tw-border-red-600 focus-within:tw-border-red-600 hover:focus-within:tw-border-red-600';
      default:
        return 'tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300 placeholder:tw-text-grey-300';
    }
  })();

  const handleDatePickerCalenderClose = () => {
    if (onCalendarClose) {
      onCalendarClose(name);
    }
  };

  return (
    <ReactDatePicker
      autoComplete='off'
      calendarClassName='datepicker-calendar'
      className={`datepicker-input field__input tw-font-inter tw-text-m tw-font-normal tw-tracking-auto ${inputClassName}${className ? ` ${className}` : ''}`}
      dateFormat={['dd/MM/yyyy', 'dd-MM-yyyy']}
      disabled={isDisabled}
      id={name}
      locale='en-gb'
      maxDate={latestDate}
      minDate={earliestDate}
      name={name}
      onCalendarClose={handleDatePickerCalenderClose}
      onChange={handleDatePickerChange}
      onFocus={handleDatePickerFocus}
      placeholderText={isDisabled ? disabledPlaceholderText : placeholderText}
      popperModifiers={{
        preventOverflow: { enabled: false },
        hide: { enabled: false },
      }}
      popperPlacement={placement}
      ref={datePickerRef}
      renderCustomHeader={(props) => {
        return CustomHeader({
          ...props,
          picker,
          onHeaderButtonClick:
            isDayPickerActive ? handleDatePickerHeaderButtonClick : undefined,
        });
      }}
      selected={value}
      shouldCloseOnSelect={false}
      showFullMonthYearPicker={isMonthPickerActive}
      showMonthYearPicker={isMonthPickerActive}
    />
  );
}

export default function DatePickerFieldWrapper(props) {
  const datePickerRef = props.inputRef || useRef();

  return <DatePickerField {...props} datePickerRef={datePickerRef} />;
}

function DatePickerField(props) {
  const { datePickerRef, fieldError, isRequired, label, name } = props;

  const isInvalid = !!fieldError?.fieldHighlighted;

  return (
    <div className='datepicker-field'>
      <div className='form-group'>
        <label
          className={classNames(
            'field__label tw-font-medium',
            isRequired &&
              "after:tw-absolute after:tw-right-0 after:tw-font-normal after:tw-text-grey-500 after:tw-content-['Required']",
            isInvalid && 'after:tw-text-red-600',
          )}
          htmlFor={name}
        >
          {label}
        </label>
        <div className='flex flex--vertically-centered pos-rel'>
          <AppDatePicker
            className='tw-min-w-[148px] tw-max-w-[192px]'
            datePickerRef={datePickerRef}
            disabledPlaceholderText={props.disabledPlaceholderText}
            earliestDate={props.earliestDate}
            fieldError={fieldError}
            isDisabled={props.isDisabled}
            latestDate={props.latestDate}
            name={name}
            onCalendarClose={props.onCalendarClose}
            onChange={props.onChange}
            placeholderText={props.placeholderText}
            placement={props.placement}
            removeErrorStyling={props.removeErrorStyling}
            value={props.value}
          />
        </div>
      </div>
    </div>
  );
}

DatePickerField.defaultProps = {
  isRequired: false,
  isDisabled: false,
  disabledPlaceholderText: '',
  placeholderText: '',
  placement: 'bottom-start',
  sumbitDisabled: false,
  fieldError: undefined,
};

const CustomHeader = function (props) {
  const {
    date,
    decreaseMonth,
    decreaseYear,
    increaseMonth,
    increaseYear,
    nextMonthButtonDisabled,
    nextYearButtonDisabled,
    onHeaderButtonClick,
    picker,
    prevMonthButtonDisabled,
    prevYearButtonDisabled,
  } = props;

  const pickerMap = {
    day: {
      dateFormat: 'MMMM YYYY',
      prevLabel: 'Previous Month',
      prevOnClick: decreaseMonth,
      prevDisabled: prevMonthButtonDisabled,
      nextLabel: 'Next Month',
      nextOnClick: increaseMonth,
      nextDisabled: nextMonthButtonDisabled,
    },
    month: {
      dateFormat: 'YYYY',
      prevLabel: 'Previous Year',
      prevOnClick: decreaseYear,
      prevDisabled: prevYearButtonDisabled,
      nextLabel: 'Next Year',
      nextOnClick: increaseYear,
      nextDisabled: nextYearButtonDisabled,
    },
  };

  return (
    <>
      {!pickerMap[picker].prevDisabled && (
        <button
          aria-label={pickerMap[picker].prevLabel}
          className='react-datepicker__navigation react-datepicker__navigation--previous'
          onClick={pickerMap[picker].prevOnClick}
          type='button'
        />
      )}
      <div className='react-datepicker__header'>
        <button
          className={classNames(
            'react-datepicker__header-button',
            onHeaderButtonClick && 'react-datepicker__header-button--clickable',
          )}
          onClick={onHeaderButtonClick}
        >
          {moment.parseZone(date).format(pickerMap[picker].dateFormat)}
        </button>
      </div>
      {!pickerMap[picker].nextDisabled && (
        <button
          aria-label={pickerMap[picker].nextLabel}
          className='react-datepicker__navigation react-datepicker__navigation--next'
          onClick={pickerMap[picker].nextOnClick}
          type='button'
        />
      )}
    </>
  );
};
