import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

const radiusSize = { small: '74%', medium: '84%', large: '104%' };

export default function TrainingStatusChart(props) {
  const { scopedCount, statusCounts, totalCount } = props;
  const [data, setData] = useState([]);

  const pieProps = {
    nameKey: 'name',
    dataKey: 'value',
    startAngle: 90,
    endAngle: -270,
    animationBegin: 150,
    animationDuration: 600,
    animationEasing: 'ease-in-out',
    data: data,
  };

  // using useEffect to force animation
  useEffect(() => {
    const chartData = [
      {
        name: 'No requirements',
        value: statusCounts.statusGreyCount,
        className: 'tw-fill-grey-200',
      },
      {
        name: 'Up to date',
        value: statusCounts.statusLowCount,
        className: 'tw-fill-green-400',
      },
      {
        name: 'Expiring soon',
        value: statusCounts.statusMediumCount,
        className: 'tw-fill-amber-300',
      },
      {
        name: 'Expired / Missing',
        value: statusCounts.statusHighCount,
        className: 'tw-fill-red-500',
      },
    ];
    setData(chartData);
  }, [statusCounts]);

  return (
    <div className='filter-container__chart'>
      <div className='filter-container__chart-block'>
        <h3 className='filter-container__info filter-container__info--black m-t--4 tw-text-3xl tw-font-semibold tw-tracking-tighter'>
          {scopedCount}
        </h3>
        <p className='filter-container__sub-info filter-container__sub-info--black'>
          of {totalCount} personnel
        </p>
      </div>
      <div className='filter-container__chart-container'>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              dataKey='value'
              endAngle={-270}
              innerRadius={radiusSize['small']}
              isAnimationActive={false}
              outerRadius={radiusSize['large']}
              startAngle={90}
            >
              <Cell className='tw-fill-grey-100' strokeWidth={0} value={1} />
            </Pie>
            <Pie
              {...pieProps}
              innerRadius={radiusSize['small']}
              isAnimationActive={true}
              outerRadius={radiusSize['large']}
            >
              {data.map((entry, index) => (
                <Cell
                  className={entry.className}
                  key={`cell-${index}`}
                  strokeWidth={0}
                />
              ))}
            </Pie>
            <Pie
              dataKey='value'
              endAngle={-270}
              innerRadius={radiusSize['small']}
              isAnimationActive={false}
              outerRadius={radiusSize['medium']}
              startAngle={90}
            >
              <Cell
                className='tw-fill-grey-900 tw-opacity-5'
                strokeWidth={0}
                value={1}
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

TrainingStatusChart.propTypes = {
  scopedCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  statusCounts: PropTypes.object,
};

TrainingStatusChart.defaultProps = {
  statusCounts: {
    statusGreyCount: 0,
    statusLowCount: 0,
    statusMediumCount: 0,
    statusHighCount: 0,
  },
};
