import { useState } from 'react';
import voca from 'voca';

export default function useRequestError() {
  const defaultValues = { validationErrors: {}, isFallback: false };
  const [values, setValues] = useState(defaultValues);

  const handleValidationErrors = function (errors) {
    const validationErrors = errors.reduce(function (map, obj) {
      if (obj.source) {
        const key = voca.camelCase(obj.source.attribute);
        map[key] = { error: obj, fieldHighlighted: true };
      }
      return map;
    }, {});

    setValues({ validationErrors: validationErrors, isFallback: false });
  };

  const removeErrorStyling = (event) => {
    if (!values.validationErrors[event.target.name]) {
      return true;
    }

    const field = event.target.name;
    const error = values.validationErrors[field].error;
    const validationErrors = { ...values.validationErrors };

    validationErrors[field] = { error: error, fieldHighlighted: false };
    setValues({ ...values, validationErrors: validationErrors });
  };

  const resetRequestError = () => {
    setValues(defaultValues);
  };

  const handleRequestError = (error) => {
    switch (error.response.status) {
      case 422:
        if (error.response.data.errors) {
          handleValidationErrors(error.response.data.errors);
          break;
        }
      default:
        setValues({ validationErrors: {}, isFallback: true });
    }
  };

  const validationErrors = Object.values(values.validationErrors);
  const submitDisabled =
    validationErrors.length > 0 &&
    validationErrors.every(
      (validationError) => validationError.fieldHighlighted,
    );

  return [
    values,
    submitDisabled,
    removeErrorStyling,
    resetRequestError,
    handleRequestError,
  ];
}
