import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from '-!svg-react-loader?name=CopyIcon!icons/copy.svg';
import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';
import classNames from 'classnames';

export default function PersonalInfo({
  copyable,
  hideIcon,
  isCompact,
  isTruncated,
  label,
  preserveWhitespace,
  text,
}) {
  const [copied, setCopied] = useState(false);

  function handleMouseEnter() {
    setCopied(false);
  }

  return (
    <div
      className={classNames(
        'personal-info',
        isCompact && 'personal-info--compact',
      )}
    >
      <div className='personal-info__info-container'>
        <div className='personal-info__label tw-font-medium tw-text-grey-900'>
          {label}
        </div>
        <div
          className={classNames(
            'personal-info__text',
            !text && 'personal-info__text--not-added tw-text-grey-500',
            isCompact && 'personal-info__text--sm',
            preserveWhitespace && 'personal-info__text--whitespace',
          )}
        >
          <span className={`${isTruncated ? 'truncated-text-container' : ''}`}>
            {text || 'Not added'}
          </span>
        </div>
      </div>
      {text && copyable && (
        <div
          className={classNames(
            'copyable copyable__hit-area tw-group/hit m-t-20 m-l-4 tw-text-s tw-font-medium tw-tracking-wide',
            hideIcon && 'copyable--hidden',
          )}
          onMouseEnter={handleMouseEnter}
        >
          <div className='copyable__copy-icon-wrapper'>
            <CopyToClipboard onCopy={() => setCopied(true)} text={text}>
              <CopyIcon
                className='[&_path]:tw-fill-grey-500 group-hover/hit:[&_path]:tw-fill-blue-500'
                height={20}
                width={20}
              />
            </CopyToClipboard>
          </div>
          <div
            className={classNames(
              'copyable__tooltip copyable__tooltip--right tw-bg-grey-900 tw-text-white',
              copied && 'copyable__tooltip--ease-out',
            )}
          >
            {copied ?
              <div className='copyable__tooltip-content'>
                <TickIcon className='copyable__tick-icon [&_polygon]:tw-fill-white' />
                <span>Copied to clipboard</span>
              </div>
            : <span className='copyable__tooltip-content'>
                <span>Click to copy to clipboard</span>
              </span>
            }
          </div>
        </div>
      )}
    </div>
  );
}

PersonalInfo.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.node,
  copyable: PropTypes.bool,
  isTruncated: PropTypes.bool,
};

PersonalInfo.defaultProps = {
  copyable: false,
  isCompact: false,
  isTruncated: true,
};
