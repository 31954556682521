import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { enableAllPlugins } from 'immer';
enableAllPlugins();
import { produce } from 'immer';

import { findAssignedCompany } from 'components/helpers/companies';
import { resourceShape } from 'components/helpers/serialisableResources';

import PersonnelCustomFieldsForm from 'components/personnel/PersonnelCustomFieldsForm';
import SelectSubcontractor from 'components/personnel/SelectSubcontractor';
import SelectDivision from 'components/personnel/SelectDivision';
import SelectCompanyRoles from 'components/personnel/SelectCompanyRoles';
import UserCompanyRolesList from 'components/personnel/UserCompanyRolesList';
import SelectLineManager from 'components/personnel/SelectLineManager';
import ErrorMessage from 'components/application/ErrorMessage';
import TextField from 'components/application/TextField';
import RadioField from 'components/application/RadioField';
import Switch from 'components/application/Switch';
import Tooltip from 'components/application/Tooltip';

import CircleQuestion from 'components/application/CircleQuestion';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import ProfilePhotoDisplay from 'components/application/ProfilePhotoDisplay';
import LoadingIcon from '-!svg-react-loader?name=LoadingIcon!icons/loading.svg';
import ImagesIcon from '-!svg-react-loader?name=ImagesIcon!icons/ic-images.svg';
import PreviewCroppedProfilePhoto from 'components/personnel/PreviewCroppedProfilePhoto';

const profileAccessToggleDisabledTooltip = {
  appUserPresent: <p className='tw-m-0'>Personnel is already a user</p>,
  emailNotPresent: (
    <p className='tw-m-0'>
      An email address is
      <br />
      required for the employee
    </p>
  ),
};

export default function PersonnelForm(props) {
  const {
    assignableCompanies,
    assignableRoles,
    availableFieldOptions,
    availableFields,
    currentPersonnel,
    currentSubcontractor,
    domainProfilePhoto,
    isCreatableCompanyRole,
    isEditableProfileAccess,
    isProfilePhotoLoading,
    isSubcontractor,
    lineManagers,
    onAssignableRoleAdd,
    onCompanyRolesOptionChange,
    onDateFieldChange,
    onFieldOptionChange,
    onNewCompanyRoleAdd,
    onPersonnelFieldChange,
    onPersonnelInputChange,
    onPersonnelOptionChange,
    onPhotoModalOpen,
    onRemoveRole,
    onSelectPrimary,
    onSubcontractorSelect,
    onToggle,
    personnel,
    removeErrorStyling,
    requestError,
    setCurrentPersonnel,
    setIsSubcontractor,
    sidePanelContext,
    sidePanelFieldRef,
    user,
  } = props;

  const previewablePhotoArea = {
    x: currentPersonnel.profilePhoto?.croppedAreaPercentageX,
    y: currentPersonnel.profilePhoto?.croppedAreaPercentageY,
    width: currentPersonnel.profilePhoto?.croppedAreaPercentageWidth,
    height: currentPersonnel.profilePhoto?.croppedAreaPercentageHeight,
  };
  const previewablePhotoPath = domainProfilePhoto?.links?.originalUrl;
  const previewablePhotoRotation = currentPersonnel.profilePhoto?.rotation;
  const previewablePhotoSize = {
    height: currentPersonnel.profilePhoto?.mediaHeight,
    width: currentPersonnel.profilePhoto?.mediaWidth,
  };

  const assignedDivision = findAssignedCompany({
    assignableCompanies: assignableCompanies,
    companyId: currentPersonnel.company.companyId,
  });

  const assignedSubcontractor = findAssignedCompany({
    assignableCompanies: assignableCompanies,
    subcontractorId: currentPersonnel.company.subcontractorId,
  });

  const primaryDivision = assignableCompanies.find(
    (company) =>
      company.attributes.resourceType === 'Company' &&
      company.attributes.primary,
  );
  const subcontractors = assignableCompanies.filter(
    (company) => company.attributes.resourceType === 'Subcontractor',
  );
  const companies = assignableCompanies.filter(
    (company) => company.attributes.resourceType === 'Company',
  );

  const focusableFields = ['firstName', 'lastName', 'externalId', 'email'];

  const firstEmptyField = focusableFields.find(
    (field) => !currentPersonnel[field],
  );

  const isPersonnelUserPresent =
    user && user.attributes.accessType === 'personnel';
  const isAppUserPresent = user && user.attributes.accessType !== 'personnel';

  const isDivisionTeamWarningVisible =
    !!personnel?.relationships?.teams &&
    personnel.relationships.teams.data.length > 0 &&
    currentPersonnel.company.companyId !=
      personnel.relationships.company.data.id;
  const isSubcontractorProfileWarningVisible =
    (!user || isPersonnelUserPresent) && currentPersonnel.profileAccessEnabled;
  const isEmailUsedForLogInPurposesWarningVisible =
    isPersonnelUserPresent &&
    currentPersonnel.email !== personnel.attributes.email;

  const isProfileAccessToggleVisible =
    !isSubcontractor && isEditableProfileAccess;

  let profileAccessToggleDisabledReason;
  if (isProfileAccessToggleVisible) {
    if (isAppUserPresent) {
      profileAccessToggleDisabledReason = 'appUserPresent';
    } else if (
      !currentPersonnel.profileAccessEnabled &&
      currentPersonnel.email.trim().length === 0
    ) {
      profileAccessToggleDisabledReason = 'emailNotPresent';
    }
  }

  useEffect(() => {
    if (sidePanelContext === 'new') {
      setCurrentPersonnel({
        ...currentPersonnel,
        lineManagerId: '',
        companyRoleIds: [],
      });
    }
  }, [currentPersonnel.company.companyId]);

  useEffect(() => {
    if (
      sidePanelContext === 'edit' &&
      currentPersonnel.company.subcontractorId
    ) {
      setIsSubcontractor(true);
    }
  }, []);

  const selectDivision = (
    <>
      <SelectDivision
        assignedDivision={assignedDivision}
        collectionSelectProps={{ customMargin: 'tw-mb-0' }}
        companies={companies}
        label={'Division'}
        onChange={onPersonnelOptionChange}
        sidePanelContext={sidePanelContext}
      />
      {isDivisionTeamWarningVisible && (
        <div className='m-l-30 tw-mt-4 tw-flex tw-rounded-lg tw-border-0 tw-bg-amber-025 tw-p-3 tw-text-amber-800'>
          <div className='tw-mr-3 tw-flex tw-h-5 tw-w-4 tw-items-center'>
            <InfoIcon
              className='[&_path]:tw-fill-amber-800'
              height={16}
              width={16}
            />
          </div>
          Personnel will be removed from existing teams when moving them to a
          new division.
        </div>
      )}
    </>
  );

  const selectSubcontractor = (
    <>
      {isSubcontractorProfileWarningVisible && (
        <div
          className={
            'm-l-30 tw-mb-4 tw-flex tw-rounded-lg tw-border-0 tw-bg-amber-025 tw-p-3 tw-text-amber-800'
          }
        >
          <div className='tw-mr-3 tw-flex tw-h-5 tw-w-4 tw-items-center'>
            <InfoIcon
              className='[&_path]:tw-fill-amber-800'
              height={16}
              width={16}
            />
          </div>
          Sub-contractors can’t view profiles
        </div>
      )}
      <SelectSubcontractor
        assignedSubcontractor={assignedSubcontractor}
        fieldError={requestError.validationErrors.name}
        isSubcontractor={isSubcontractor}
        label={'Subcontractor company'}
        onChange={onPersonnelOptionChange}
        onSubcontractorSelect={onSubcontractorSelect}
        primaryDivision={primaryDivision}
        removeErrorStyling={removeErrorStyling}
        sidePanelContext={sidePanelContext}
        subcontractor={currentSubcontractor}
        subcontractors={subcontractors}
      />
    </>
  );

  const formattedValidationErrors =
    requestError.validationErrors.email ?
      produce(requestError.validationErrors, (draftValidationErrors) => {
        draftValidationErrors.email.error.detail = `${draftValidationErrors.email.error.detail}. Please use a different email or contact support.`;
      })
    : requestError.validationErrors;

  return (
    <div className='popup__body-form popup__body-form--no-side-padding'>
      <div className='form-container'>
        <div className='form-container-section'>
          <ErrorMessage
            isFallback={requestError.isFallback}
            validationErrors={formattedValidationErrors}
            withSeparateNestedErrors={false}
            wrapperClassName='form-container'
          />
        </div>
        <div className='form-container-section'>
          <TextField
            fieldError={requestError.validationErrors.firstName}
            inputRef={
              firstEmptyField === 'firstName' ? sidePanelFieldRef : null
            }
            isRequired={true}
            label='First name'
            name='firstName'
            onChange={onPersonnelInputChange}
            removeErrorStyling={removeErrorStyling}
            value={currentPersonnel.firstName}
          />
          <TextField
            fieldError={requestError.validationErrors.lastName}
            inputRef={firstEmptyField === 'lastName' ? sidePanelFieldRef : null}
            isRequired={true}
            label='Last name'
            name='lastName'
            onChange={onPersonnelInputChange}
            removeErrorStyling={removeErrorStyling}
            value={currentPersonnel.lastName}
          />
          <TextField
            fieldError={requestError.validationErrors.externalId}
            inputRef={
              firstEmptyField === 'externalId' ? sidePanelFieldRef : null
            }
            label='Personnel ID'
            name='externalId'
            onChange={onPersonnelInputChange}
            removeErrorStyling={removeErrorStyling}
            value={currentPersonnel.externalId}
          />
          <div className='tooltip-parent'>
            <TextField
              fieldError={requestError.validationErrors.email}
              inputRef={firstEmptyField === 'email' ? sidePanelFieldRef : null}
              isDisabled={isAppUserPresent}
              label='Email address'
              name='email'
              onChange={(event) => {
                if (!isAppUserPresent) onPersonnelInputChange(event);
              }}
              removeErrorStyling={removeErrorStyling}
              value={currentPersonnel.email}
            />
            {isAppUserPresent && (
              <Tooltip
                className='tooltip-dark--max-w-none tw-top-6'
                placement='top'
                tooltip={
                  <p className='tw-m-0'>
                    As this personnel is also a user, their email address
                    <br />
                    can only be edited from the user settings
                  </p>
                }
                trigger='hover'
              />
            )}
          </div>
          {isEmailUsedForLogInPurposesWarningVisible && (
            <div className='-tw-mt-3 tw-flex tw-rounded-lg tw-border-0 tw-bg-amber-025 tw-p-3 tw-text-amber-800'>
              <div className='tw-mr-3 tw-flex tw-h-5 tw-w-4 tw-items-center'>
                <InfoIcon
                  className='[&_path]:tw-fill-amber-800'
                  height={16}
                  width={16}
                />
              </div>
              Please note this email is used for log in purposes.
            </div>
          )}
        </div>

        <div className='tw-flex'>
          <div className='tw-ml-5 tw-mr-4'>
            {(() => {
              if (isProfilePhotoLoading) {
                return (
                  <div className='tw-relative tw-h-16 tw-w-16'>
                    <div className='tw-apply-loading-spinner--blue-light tw-flex tw-items-center tw-justify-center'>
                      <LoadingIcon className='animated-spinner tw-absolute tw-top-0 tw-h-16' />
                    </div>
                    <div className='tw-absolute tw-top-0 tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full tw-opacity-50'>
                      <ImagesIcon className='[&_path]:tw-fill-grey-500' />
                    </div>
                  </div>
                );
              }

              if (
                !!previewablePhotoPath &&
                !!previewablePhotoSize.width &&
                (!!previewablePhotoArea?.x || !!previewablePhotoArea?.y)
              ) {
                return (
                  <PreviewCroppedProfilePhoto
                    croppedArea={previewablePhotoArea}
                    imagePath={previewablePhotoPath}
                    mediaSize={previewablePhotoSize}
                    rotation={previewablePhotoRotation}
                  />
                );
              }

              return (
                <ProfilePhotoDisplay
                  componentSize='md'
                  photoUrl={domainProfilePhoto?.links?.croppedUrl}
                  profileInitials={`${currentPersonnel.firstName[0]}${currentPersonnel.lastName[0]}`}
                />
              );
            })()}
          </div>
          <div className='tw-mb-1 tw-mt-2'>
            <a
              className={`tw-cursor-pointer tw-font-medium ${isProfilePhotoLoading ? 'tw-text-grey-400' : 'tw-text-blue-500'}`}
              onClick={() => {
                if (!isProfilePhotoLoading) onPhotoModalOpen();
              }}
            >
              {(
                domainProfilePhoto?.links?.croppedUrl ||
                previewablePhotoPath ||
                isProfilePhotoLoading
              ) ?
                'Edit photo'
              : 'Add photo'}
            </a>
            <p className='tw-mb-2 tw-mt-1 tw-text-grey-500'>
              PNG or JPG • Max file size 5MB
            </p>
          </div>
        </div>

        <hr className='tw-my-8 tw-h-px tw-border-0 tw-bg-grey-100' />

        <div className='form-container-section'>
          <label className='collection-select__label m-t-32 m-b-24 tw-font-medium'>
            Personnel Type
          </label>
          <div className={'side-panel__radio-buttons-container'}>
            <RadioField
              checked={!isSubcontractor}
              label={'Employee'}
              name={'personnelType'}
              onChange={() => {
                setIsSubcontractor(false);
              }}
              value='employee'
            />
            {!isSubcontractor && companies.length > 1 && selectDivision}
            {isProfileAccessToggleVisible && (
              <div
                className='m-l-30 tw-mt-4 tw-flex tw-cursor-pointer tw-justify-between'
                data-element-name='profile-access-toggle'
                onClick={() => {
                  if (!profileAccessToggleDisabledReason)
                    onToggle('profileAccessEnabled');
                }}
              >
                <div className='tw-flex'>
                  <span className='tw-mr-2'>
                    Allow employee to view their profile
                  </span>
                  <Tooltip
                    placement='top'
                    tooltip='Employee won’t be able to edit their profile'
                    trigger='hover'
                  >
                    <CircleQuestion />
                  </Tooltip>
                </div>
                <div
                  className={classNames(
                    'tooltip-parent',
                    profileAccessToggleDisabledReason &&
                      'tw-cursor-not-allowed tw-opacity-30',
                  )}
                >
                  <Switch
                    checked={
                      isAppUserPresent || currentPersonnel.profileAccessEnabled
                    }
                    className='app-switch--md fl-r'
                    disabled={!!profileAccessToggleDisabledReason}
                    offLabel='off'
                    onLabel='on'
                  />
                  {profileAccessToggleDisabledReason && (
                    <Tooltip
                      placement='top'
                      tooltip={
                        profileAccessToggleDisabledTooltip[
                          profileAccessToggleDisabledReason
                        ]
                      }
                      trigger='hover'
                    />
                  )}
                </div>
              </div>
            )}
            <RadioField
              checked={isSubcontractor}
              label={'Sub-contractor'}
              name={'personnelType'}
              onChange={() => {
                setIsSubcontractor(true);
              }}
              value='subcontractor'
            />
            {isSubcontractor && selectSubcontractor}
          </div>
          <SelectLineManager
            currentPersonnel={currentPersonnel}
            isDisabled={isSubcontractor}
            lineManagers={lineManagers}
            onChange={onPersonnelOptionChange}
          />
        </div>
        <hr className='tw-mt-8 tw-h-px tw-border-0 tw-bg-grey-100' />
        <div className='form-container-section'>
          <label className='collection-select__label m-t-32 tw-font-medium'>
            Roles
          </label>
          <SelectCompanyRoles
            assignableRolesCollection={assignableRoles}
            currentPersonnel={currentPersonnel}
            fieldError={requestError.validationErrors.base}
            isCreatable={isCreatableCompanyRole}
            onAssignableRoleAdd={onAssignableRoleAdd}
            onChange={onCompanyRolesOptionChange}
            onNewCompanyRoleAdd={onNewCompanyRoleAdd}
            primaryDivisionId={primaryDivision.id}
          />
          <UserCompanyRolesList
            assignableRolesCollection={assignableRoles}
            currentPersonnel={currentPersonnel}
            onRemoveRole={onRemoveRole}
            onSelectPrimary={onSelectPrimary}
          />
        </div>
        <hr className='tw-mt-8 tw-h-px tw-border-0 tw-bg-grey-100' />
        <div className='form-container-section'>
          <PersonnelCustomFieldsForm
            availableFieldOptions={availableFieldOptions}
            availableFields={availableFields}
            currentPersonnel={currentPersonnel}
            onDateFieldChange={onDateFieldChange}
            onFieldOptionChange={onFieldOptionChange}
            onPersonnelFieldChange={onPersonnelFieldChange}
            removeErrorStyling={removeErrorStyling}
            requestError={requestError}
          />
        </div>
      </div>
    </div>
  );
}

PersonnelForm.propTypes = {
  assignableCompanies: PropTypes.array,
  currentPersonnel: PropTypes.object,
  domainProfilePhoto: PropTypes.shape({
    id: PropTypes.string,
    originalUrl: PropTypes.string,
    croppedUrl: PropTypes.string,
  }).isRequired,
  user: resourceShape('user'),
  sidePanelContext: PropTypes.string,
  isSubcontractor: PropTypes.bool,
  setIsSubcontractor: PropTypes.func,
  requestError: PropTypes.object,
  removeErrorStyling: PropTypes.func,
  currentSubcontractor: PropTypes.object,
  onSubcontractorSelect: PropTypes.func,
  onPersonnelOptionChange: PropTypes.func,
  onPersonnelInputChange: PropTypes.func,
  isCreatableCompanyRole: PropTypes.bool,
  isEditableProfileAccess: PropTypes.bool,
  lineManagers: PropTypes.array,
  sidePanelBodyRef: PropTypes.any,
  setCurrentPersonnel: PropTypes.func,
  onNewCompanyRoleAdd: PropTypes.func,
  assignableRoles: PropTypes.array,
  onAssignableRoleAdd: PropTypes.func,
  availableFields: PropTypes.object,
  availableFieldOptions: PropTypes.object,
  onPersonnelFieldChange: PropTypes.func,
  onFieldOptionChange: PropTypes.func,
  onDateFieldChange: PropTypes.func,
  onToggle: PropTypes.func,
  onPhotoModalOpen: PropTypes.func,
};
