import React from 'react';
import Tooltip from 'components/application/Tooltip';

export default function ActionButton(props) {
  const {
    buttonIcon,
    buttonText,
    disabled,
    disabledClasses,
    onClick,
    style,
    styleClasses,
    tooltipRequired,
    tooltipText,
  } = props;

  return (
    <button
      className={`tw-font-medium ${styleClasses} ${disabled ? disabledClasses : ''}`}
      disabled={disabled}
      onClick={
        disabled ?
          () => {}
        : () => {
            onClick();
          }
      }
      style={style}
    >
      {tooltipRequired && disabled ?
        <Tooltip placement='top' tooltip={tooltipText} trigger='hover'>
          <div>
            {buttonIcon}
            {buttonText}
          </div>
        </Tooltip>
      : <div className='m-0'>
          {buttonIcon}
          {buttonText}
        </div>
      }
    </button>
  );
}

ActionButton.defaultProps = {
  styleClasses:
    'tw-border-0 tw-inline-flex tw-cursor-pointer tw-select-none tw-items-center tw-rounded-lg tw-h-10 tw-px-5 tw-py-0 tw-font-inter tw-text-m tw-font-medium tw-tracking-auto tw-shadow-none tw-transition-all tw-duration-200 tw-ease-in disabled:tw-opacity-30 disabled:tw-pointer-events-none data-[disabled=true]:tw-opacity-30 data-[disabled=true]:tw-pointer-events-none',
  style: {},
  disabled: false,
  tooltipRequired: false,
  icon: null,
};
