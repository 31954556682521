import React, { useEffect, useMemo } from 'react';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import DownloadIcon from '-!svg-react-loader?name=DownloadIcon!icons/ic-download.svg';
import Tooltip from 'components/application/Tooltip';
import useDownloadPolling from 'components/hooks/useDownloadPolling';
import ActivityTableData from 'components/projectActivity/ActivityTableData';
import DownloadModalWrapper from 'components/application/DownloadModalWrapper';
import Polling from 'handshq-app/utilities/polling';

export default function ProjectActivityRow(props) {
  const {
    currentUser,
    downloadDisabled,
    history,
    isVersionPdfsEnabled,
    projectId,
  } = props;

  const statusPoller = useMemo(() => {
    return new Polling({
      interval: 1000,
      url: `/versions/${history.versionId}`,
      responseCheck: function (response) {
        return response.data.relationships.version_pdf.data !== null;
      },
      successCallback: (response) => {
        setDownloadState({
          ...downloadState,
          downloadFailed: false,
          downloadInProgress: false,
          downloadSucceeded: true,
          downloadLink: response.included[0].links.content,
        });
      },
      failureCallback: (_response) => {
        setDownloadState({
          ...downloadState,
          downloadInProgress: false,
          downloadFailed: true,
        });
      },
    });
  }, []);

  const [
    onClick,
    startDownload,
    resetAction,
    closeModal,
    modalIsOpen,
    downloadState,
    setDownloadState,
  ] = useDownloadPolling(statusPoller);

  const downloadVersion = () => {
    window.open(downloadState.downloadLink, '_blank');
  };

  const downloadButton = (
    <OutlinedButton
      color='grey'
      disabled={downloadDisabled}
      onClick={downloadDisabled ? () => {} : onClick}
      size='sm'
    >
      <DownloadIcon className='m-l--8' height={24} width={24} />
      <span>Download</span>
    </OutlinedButton>
  );

  const analyticProps = {
    projectId: projectId,
    versionId: history.versionId,
    currentUser: currentUser,
  };

  useEffect(() => {
    return () => {
      statusPoller && statusPoller.end();
    };
  }, []);

  return (
    <tr className='handshq__tr--taller hover:tw-bg-grey-025'>
      <ActivityTableData
        downloadButtonVisible={isVersionPdfsEnabled && history.versionId}
        history={history}
        showVersionNumberColumn={isVersionPdfsEnabled}
      />
      {isVersionPdfsEnabled &&
        history.versionId &&
        (downloadDisabled ?
          <td className='handshq__td-button-cell--align-right tw-border-grey-100'>
            <Tooltip
              placement='top'
              tooltip='You cannot download a RAMS until it has been approved'
              trigger='hover'
            >
              {downloadButton}
            </Tooltip>
          </td>
        : <td className='handshq__td-button-cell--align-right tw-border-grey-100'>
            {downloadButton}
            {modalIsOpen && (
              <DownloadModalWrapper
                analyticProps={analyticProps}
                analyticTrackingId={'Project history version downloaded'}
                closeModal={closeModal}
                downloadAction={downloadVersion}
                downloadStatus={downloadState}
                downloadingBodyText='Downloading...'
                errorBodyText='There was a problem creating the PDF. Please try again'
                headerText='Download document'
                isLandscape={true}
                modalIsOpen={modalIsOpen}
                resetAction={resetAction}
                retryAction={startDownload}
                successBodyText='PDF ready to download'
              />
            )}
          </td>)}
    </tr>
  );
}
