import MobileDetect from 'mobile-detect';

$(function () {
  // risk register trades#index
  if ($('.risk-register').length) {
    $('tr.master-activity').click(function () {
      window.location = $(this).data('href');
    });

    $('.edit-category, .master-activity__download').click(function (event) {
      event.stopPropagation();
    });

    $('.category__drag-handle')
      .click(function (event) {
        event.stopPropagation();
      })
      .mousedown(function (_event) {
        $(this).closest('tbody').find('.category-body').hide();
      });

    $('.risk-assessment__drag-handle:not(.disabled-handle)').click(
      function (event) {
        event.stopPropagation();
      },
    );

    $('.sortable-category').on('sortabledropped', function (_event) {
      $(this).find('.category-body').show();
    });

    const mobileDetect = new MobileDetect(window.navigator.userAgent);

    if (mobileDetect.mobile()) {
      $(
        '.category__drag-handle img, .risk-assessment__drag-handle:not(.disabled-handle) img',
      ).removeClass('visibility-hidden');
    } else {
      dragHandleHoverEvent({
        element: '.category',
        toggle: '.category__drag-handle img',
      });

      dragHandleHoverEvent({
        element: '.master-activity',
        toggle: '.risk-assessment__drag-handle:not(.disabled-handle) img',
      });
    }

    function dragHandleHoverEvent(options) {
      $(options['element']).hover(
        function (_event) {
          $(this).find(options['toggle']).css('visibility', 'visible');
        },
        function (_event) {
          $(this).find(options['toggle']).css('visibility', 'hidden');
        },
      );
    }

    new HandshqApp.RiskAssessmentsDownloader({
      modal: $('.pdf-retrieval'),
      requestSelectedTrigger: $('.ra-download__request--selected'),
      requestAllTrigger: $('.ra-download__request--all'),
    }).init();
  }

  // risk assessment master_activities form
  if ($('.ra-form').length) {
    // fix for IE, submit is outside form
    $('.submit-form').click(function (_event) {
      $('form').submit();
    });
  }
});
