import React from 'react';
import StatusIndicator from 'components/application/indicators/StatusIndicator';
import Tooltip from 'components/application/Tooltip';

export default function PersonnelTrainingStatusIndicator(props) {
  const trainingStatusOptions = {
    0: {
      status: 'high',
      tooltipText: 'Training is missing',
    },
    1: {
      status: 'high',
      tooltipText: 'Expiry date is missing',
    },
    2: {
      status: 'high',
      tooltipText: 'Training has expired',
    },
    3: {
      status: 'medium',
      tooltipText: 'Training is expiring soon',
    },
    4: {
      status: 'low',
      tooltipText: 'Training is up to date',
    },
    5: {
      status: 'grey',
      tooltipText: 'No required training',
    },
    archived: {
      status: 'hollow',
      tooltipText: 'Personnel is archived',
    },
  }[props.status];

  const status = trainingStatusOptions && trainingStatusOptions['status'];

  return (
    <div className='tooltip-parent flex'>
      <StatusIndicator status={status} />
      <Tooltip
        placement='top'
        tooltip={trainingStatusOptions && trainingStatusOptions['tooltipText']}
        trigger='hover'
      />
    </div>
  );
}
