$(document).ready(setupIntercomLaunchers);

export function setupIntercomLaunchers() {
  $('[data-intercom-launcher]').each((_index, launcher) => {
    const message = $(launcher).data('message');

    $(launcher).on('click', () => {
      if (window.Intercom) {
        if (message) {
          window.Intercom('showNewMessage', message);
        } else {
          window.Intercom('show');
        }
      }
    });
  });
}
