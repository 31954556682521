import HandshqApp from '../handshq-app';

HandshqApp.ImageUploader = function (options) {
  if (this instanceof HandshqApp.ImageUploader) {
    const _this = this;

    this.node = $(options['node']);
    this.errorsModal = $('.image-uploader-errors.modal');
    this.form = _this.node.find('form');
    this.resourceType = _this.node.data('resource-type');
    this.resourceId = _this.node.data('resource-id');
    this.droparea = _this.node.find('.image-uploader__droparea');
    this.successCallback = options['successCallback'];

    this.setup = function () {
      preventBrowserDefault();
      _this.setupDropareaDragEvents();
      _this.setupFileUploader();
    };

    function preventBrowserDefault() {
      $(document).bind('drop dragover', function (event) {
        event.preventDefault();
      });
    }

    this.setupDropareaDragEvents = function () {
      _this.droparea
        .on('dragover', function (_event) {
          $(this).addClass('hover');
        })
        .on('dragleave', function (_event) {
          $(this).removeClass('hover');
        });
    };

    this.setupFileUploader = function () {
      $(_this.form).fileupload({
        acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
        maxFileSize: 10000000,
        minFileSize: undefined,
        dropZone: _this.droparea,
        drop: drop,
        beforeSend: beforeSend,
        submit: submit,
        done: done,
        processalways: processAlways,
      });
    };

    function drop(event, _data) {
      $(event.target).find('.image-uploader__droparea').removeClass('hover');
    }

    function beforeSend(xhr, _data) {
      xhr.setRequestHeader('X-Content-Type-Options', 'nosniff');
      xhr.setRequestHeader(
        'Cache-Control',
        'no-cache, no-store, max-age=0, must-revalidate',
      );
      xhr.setRequestHeader('Pragma', 'no-cache');
    }

    function submit(event, data) {
      const _this = $(this);

      event.preventDefault();

      $.ajax({
        url: '/images/new',
        dataType: 'json',
        type: 'GET',
        success: function (response) {
          data.formData = response;
          _this.fileupload('send', data);
        },
      });
    }

    function done(event, data) {
      const xmlData = $.parseXML(data.response().jqXHR.responseText);
      const key = $(xmlData).find('Key').text();
      const postData = {
        image: {
          attachable_id: _this.resourceId,
          attachable_type: _this.resourceType,
          key: key,
        },
      };

      $.ajax({
        url: '/images',
        dataType: 'json',
        type: 'POST',
        data: postData,
        success: function (response) {
          if (_this.successCallback) {
            _this.successCallback(_this, response);
          }
        },

        error: function (_response) {
          failureCallback();
        },
      });
    }

    function failureCallback() {
      if (!_this.errorsModal.is(':visible')) {
        _this.errorsModal.modal('show');
      }

      _this.errorsModal.find('.image-uploader-errors__standard-error').show();
    }

    function processAlways(event, data) {
      if (data.files.error) {
        if (!_this.errorsModal.is(':visible')) {
          _this.errorsModal.modal('show');
        }

        const file = data.files[data.index];
        const fileName = $('<li>').text(file.name);

        switch (file.error) {
          case 'File type not allowed':
            displayError('file-type', fileName);
            break;
          case 'File is too large':
            displayError('file-size', fileName);
            break;
        }
      }
    }

    function displayError(type, fileName) {
      _this.errorsModal.find('.image-uploader-errors__' + type).show();
      _this.errorsModal.find('ul.' + type + '-errors').append(fileName);
    }
  } else {
    throw new Error('HandshqApp.ImageUploader invoked without new');
  }
};
