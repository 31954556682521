import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import Upload from 'components/helpers/Upload';
import Polling from 'handshq-app/utilities/polling';

import Image from 'components/attachments/components/Image';
import Settings from 'components/attachments/components/Settings';
import Caption from 'components/attachments/components/Caption';

const isCaptionable = { default: true, compact: false };

export default function Viewer(props) {
  const {
    attachment,
    createAttachment,
    deleteAttachment,
    index,
    mode,
    onChange,
    onError,
    onPollingFailure,
    onPollingSuccess,
    openPreviewer,
    updateAttachment,
  } = props;

  const isProcessed = attachment.attachedProcessed === true;

  const upload = useMemo(() => {
    return (
      attachment.file &&
      new Upload({
        file: attachment.file,
        onUpload: (fileType, uploadKey) => {
          createAttachment({
            attachment: attachment,
            fileType: fileType,
            uploadKey: uploadKey,
          });
        },
        onError: (_error) => onError(),
      })
    );
  }, [attachment.file]);

  const polling = useMemo(() => {
    return (
      attachment.hashid &&
      new Polling({
        interval: 1000,
        maxTime: 600000,
        url: `/attachments/${attachment.hashid}`,
        responseCheck: (response) =>
          response.data.attributes.attachedProcessed === true,
        successCallback: (response) =>
          onPollingSuccess({ response, attachment }),
        failureCallback: (response) =>
          onPollingFailure ? onPollingFailure({ response }) : onError(),
      })
    );
  }, [attachment.hashid]);

  const previewAttachment = () => openPreviewer(index);

  useEffect(() => {
    return () => {
      polling && polling.end();
    };
  }, []);

  useEffect(() => {
    attachment.file && upload.start();
  }, []);

  useEffect(() => {
    !attachment.file &&
      !isProcessed &&
      !attachment.originalImageThumbUrl &&
      polling.start();
  }, [attachment.file]);

  const deleteAttachmentAndEndPolling = () => {
    polling && polling.end();
    deleteAttachment({ attachment });
  };

  return (
    <div
      className={`attachments__item attachments__item--${mode}-mode`}
      id={`attachments__item-${attachment.key}`}
    >
      <div className='attachments__viewer tw-border-grey-100 tw-bg-white'>
        <Image
          attachment={attachment}
          mode={mode}
          previewAttachment={previewAttachment}
        />
        <Settings
          attachment={attachment}
          deleteAttachment={deleteAttachmentAndEndPolling}
          index={index}
          mode={mode}
          onChange={onChange}
          updateAttachment={updateAttachment}
        />
        {isCaptionable[mode] && (
          <Caption
            attachment={attachment}
            index={index}
            onChange={onChange}
            updateAttachment={updateAttachment}
          />
        )}
      </div>
    </div>
  );
}

Viewer.propTypes = {
  index: PropTypes.number.isRequired,
  mode: PropTypes.string,
  attachment: PropTypes.object.isRequired,
  createAttachment: PropTypes.func.isRequired,
  updateAttachment: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  openPreviewer: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onPollingSuccess: PropTypes.func.isRequired,
  onPollingFailure: PropTypes.func,
};

Viewer.defaultProps = {
  mode: 'default',
};
