import React from 'react';
import BlockList from 'components/application/BlockList';
import PersonnelTrainingStatusIndicator from 'components/personnel/PersonnelTrainingStatusIndicator';
import { subcontractorDisplayName } from 'components/helpers/companies';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import 'intersection-observer';
import ProfilePhotoDisplay from 'components/application/ProfilePhotoDisplay';

export default function RolesSidePanelPersonnelTab(props) {
  const { isLoadMorePersonnelActive, loadMoreRef, personnel, personnelMeta } =
    props;

  const trainingRegisterResourceManagementContext =
    useTrainingRegisterResources();

  return (
    <div key='personnelTab' label='personnel'>
      {personnel.loaded && personnel.collection.length === 0 ?
        <li className='block-list__item tw-border-grey-100'>
          <div className='block-list__item--flex-container'>
            <div className='block-list__item--align-center'>
              <span>No personnel have this role</span>
            </div>
          </div>
        </li>
      : <>
          <BlockList
            blockListItemClasses={(person) =>
              person.attributes.subcontractorId ?
                ' block-list__item--double'
              : ''
            }
            collection={personnel.collection}
            onItemClick={
              (
                trainingRegisterResourceManagementContext.hasPersonnelViewableAccess
              ) ?
                (person) => window.location.replace(`personnel/${person.id}`)
              : undefined
            }
            onItemDisplay={(person) => {
              const profilePhoto = personnel.includedProfilePhotos.find(
                (profilePhoto) =>
                  profilePhoto.relationships.personnel.data.id === person.id,
              );
              return (
                <div className='block-list__item--flex-container tw-items-center'>
                  <div className='block-list__item--align-center tw-mr-3'>
                    <PersonnelTrainingStatusIndicator
                      status={person.attributes.trainingStatus}
                    />
                  </div>
                  <ProfilePhotoDisplay
                    componentSize='sm'
                    photoUrl={profilePhoto?.links.croppedUrl}
                    profileInitials={`${person.attributes.firstName[0]}${person.attributes.lastName[0]}`}
                  />
                  <div className='block-list__item--text mw-300'>
                    <span className='truncated-text-container'>
                      {`${person.attributes.firstName} ${person.attributes.lastName}`}
                    </span>
                    {person.attributes.subcontractorId && (
                      <span className='block-list__item--sub-text truncated-text-container tw-text-s tw-tracking-wide tw-text-grey-500'>
                        {subcontractorDisplayName(
                          person.attributes.subcontractorId,
                          personnel.includedSubcontractors,
                        )}
                      </span>
                    )}
                  </div>
                </div>
              );
            }}
          />
          {isLoadMorePersonnelActive && !personnelMeta.isLastPage && (
            <li
              className='block-list__item tw-border-grey-100'
              ref={loadMoreRef}
            >
              <div className='block-list__item--flex-container'>
                <div className='block-list__item--align-center'>
                  <span>Loading personnel...</span>
                </div>
              </div>
            </li>
          )}
        </>
      }
    </div>
  );
}
