import { useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { isValidPostcodeFormat, startPolling } from './helpers';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import HandshqApp from 'handshq-app/handshq-app';
import ConditionalWrapper from 'components/application/ConditionalWrapper';
import MedicalLocationCard from './MedicalLocationCard';
import MedicalLocationInputFields from './MedicalLocationInputFields';
import MedicalLocationSearchSelect from './MedicalLocationSearchSelect';
import MedicalLocationSelectItem from './MedicalLocationSelectItem';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import TextButton from 'components/application/buttons/TextButton';
import Tooltip from 'components/application/Tooltip';
import useDebounce from 'components/hooks/useDebounce';

export function LoadingSkeleton() {
  return (
    <>
      <div className='tw-mt-2 tw-flex tw-w-full tw-max-w-xl tw-rounded-lg tw-border-1 tw-border-solid tw-border-grey-300 tw-bg-grey-050 tw-p-4'>
        <div className='tw-mr-5 tw-w-2/4'>
          <div className='tw-animate-pulse tw-mb-2 tw-flex'>
            <div className='tw-h-5 tw-w-36 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-5 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
          <div className='tw-animate-pulse tw-mb-6 tw-flex'>
            <div className='tw-h-3 tw-w-40 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-10 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
          <div className='tw-animate-pulse tw-flex'>
            <div className='tw-h-3 tw-w-36 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
        </div>
        <div className='tw-ml-1 tw-w-2/4'>
          <div className='tw-animate-pulse tw-mb-2 tw-flex'>
            <div className='tw-h-3 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-10 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
          <div className='tw-animate-pulse tw-mb-2 tw-flex'>
            <div className='tw-h-3 tw-w-10 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-10 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
          <div className='tw-animate-pulse tw-mb-3 tw-flex'>
            <div className='tw-h-3 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-10 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
          <div className='tw-animate-pulse tw-flex'>
            <div className='tw-h-3 tw-w-16 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
        </div>
      </div>

      <div className='tw-mt-2 tw-flex tw-w-full tw-max-w-xl tw-rounded-lg tw-border-1 tw-border-solid tw-border-grey-300 tw-bg-grey-050 tw-p-4'>
        <div className='tw-mr-5 tw-w-2/4'>
          <div className='tw-animate-pulse tw-mb-2 tw-flex tw-animation-delay-[500ms]'>
            <div className='tw-h-5 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-5 tw-w-36 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
          <div className='tw-animate-pulse tw-mb-6 tw-flex tw-animation-delay-[500ms]'>
            <div className='tw-h-3 tw-w-40 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-10 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-8 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
          <div className='tw-animate-pulse tw-flex tw-animation-delay-[500ms]'>
            <div className='tw-h-3 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-36 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
        </div>
        <div className='tw-ml-1 tw-w-2/4'>
          <div className='tw-animate-pulse tw-mb-2 tw-flex tw-animation-delay-[500ms]'>
            <div className='tw-h-3 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-14 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
          <div className='tw-animate-pulse tw-mb-2 tw-flex tw-animation-delay-[500ms]'>
            <div className='tw-h-3 tw-w-16 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-10 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
          <div className='tw-animate-pulse tw-mb-3 tw-flex tw-animation-delay-[500ms]'>
            <div className='tw-h-3 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-6 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-6 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
          <div className='tw-animate-pulse tw-flex tw-animation-delay-[500ms]'>
            <div className='tw-h-3 tw-w-16 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
        </div>
      </div>

      <div className='tw-mt-2 tw-flex tw-w-full tw-max-w-xl tw-rounded-lg tw-border-1 tw-border-solid tw-border-grey-300 tw-bg-grey-050 tw-p-4'>
        <div className='tw-mr-5 tw-w-2/4'>
          <div className='tw-animate-pulse tw-mb-2 tw-flex tw-animation-delay-[1000ms]'>
            <div className='tw-h-5 tw-w-36 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-5 tw-w-24 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
          <div className='tw-animate-pulse tw-mb-6 tw-flex tw-animation-delay-[1000ms]'>
            <div className='tw-h-3 tw-w-40 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-6 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-6 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
          <div className='tw-animate-pulse tw-flex tw-animation-delay-[1000ms]'>
            <div className='tw-h-3 tw-w-36 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
        </div>
        <div className='tw-ml-1 tw-w-2/4'>
          <div className='tw-animate-pulse tw-mb-2 tw-flex tw-animation-delay-[1000ms]'>
            <div className='tw-h-3 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-10 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
          <div className='tw-animate-pulse tw-mb-2 tw-flex tw-animation-delay-[1000ms]'>
            <div className='tw-h-3 tw-w-10 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-10 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
          <div className='tw-animate-pulse tw-mb-3 tw-flex tw-animation-delay-[1000ms]'>
            <div className='tw-h-3 tw-w-20 tw-rounded-lg tw-bg-grey-200'></div>
            <div className='tw-ml-1 tw-h-3 tw-w-10 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
          <div className='tw-animate-pulse tw-flex tw-animation-delay-[1000ms]'>
            <div className='tw-h-3 tw-w-16 tw-rounded-lg tw-bg-grey-200'></div>
          </div>
        </div>
      </div>
    </>
  );
}

const postcodeId = 'postcode-field';

const startPollingForNearestMedicalLocations = async ({
  poller,
  search,
  searchService,
}) => {
  let result;

  try {
    result = await startPolling({
      poller,
      url: `/medical_location_search_services/${searchService}/assignable_medical_locations?search=${encodeURIComponent(search)}`,
    });
  } catch (error) {
    throw new Error();
  }

  // error on the external API
  if (result.data.meta.request_status === 'failed') throw new Error();

  return result.data.data;
};

export default function NearestMedicalLocationFormSection({
  domainMedicalLocation,
  domainMedicalLocationMapConfiguration,
  domainMedicalLocationMapConfigurationImageUrl,
}) {
  const currentActor = useCurrentActor();
  const isEditable = currentActor.isAllowedFeature('nearest_a_and_e');
  const isNhsApiLookupAllowed = currentActor.isAllowedFeature('nhs_api_lookup');

  const googlePoller = useRef(null);
  const nhsPoller = useRef(null);
  const postcodePoller = useRef(null);

  const [
    isFindNearestMedicalLocationButtonEnabled,
    setIsFindNearestMedicalLocationButtonEnabled,
  ] = useState(false);
  const [
    isNhsMedicalLocationFetchEnabled,
    setIsNhsMedicalLocationFetchEnabled,
  ] = useState(false);
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(
    !isNhsApiLookupAllowed,
  );
  const [showNhsBanner, setShowNhsBanner] = useState(false); // Show NHS box when postcode is valid, but then don't hide it if it changes.

  const [currentPostcode, setCurrentPostcode] = useState(null);
  const [debouncedCurrentPostcode, _resetCurrentPostcode] = useDebounce(
    currentPostcode,
    500,
  );
  const [currentTextQuery, setCurrentTextQuery] = useState('');
  const [debouncedCurrentTextQuery, _resetCurrentTextQuery] = useDebounce(
    currentTextQuery,
    500,
  );

  const [isRemovingDomainMedicalLocation, setIsRemovingDomainMedicalLocation] =
    useState(false);

  const [mapConfiguration, setMapConfiguration] = useState({
    centerLatitude:
      domainMedicalLocationMapConfiguration?.attributes?.centerLatitude,
    centerLongitude:
      domainMedicalLocationMapConfiguration?.attributes?.centerLongitude,
    id: domainMedicalLocationMapConfiguration?.id,
    zoomLevel: domainMedicalLocationMapConfiguration?.attributes?.zoomLevel,
  });

  const [selectedMedicalLocationId, setSelectedMedicalLocationId] =
    useState(null);
  const [showMap, setShowMap] = useState(
    !!domainMedicalLocation ? !!domainMedicalLocationMapConfiguration : true,
  );

  const isDebouncedCurrentPostcodeValidFormat = isValidPostcodeFormat({
    postcode: debouncedCurrentPostcode,
  });

  const { data: currentPostcodeCoordinates } = useQuery({
    enabled: isDebouncedCurrentPostcodeValidFormat && isEditable,
    queryKey: ['postcodeCoordinates', debouncedCurrentPostcode],
    queryFn: async () => {
      const response = await startPolling({
        poller: postcodePoller,
        url: `/medical_location_search_services/postcode_coordinates/${debouncedCurrentPostcode}`,
      });
      const { latitude, longitude } = response.data.data.attributes;
      return latitude == null || longitude == null ?
          null
        : { lat: latitude, lng: longitude };
    },
    retry: false,
  });

  const {
    data: assignableNhsMedicalLocations,
    error: nhsMedicalLocationsFetchError,
    isFetching: isFetchingNhsMedicalLocations,
    isSuccess: isNhsMedicalLocationFetchSuccess,
  } = useQuery({
    enabled:
      isDebouncedCurrentPostcodeValidFormat && isNhsMedicalLocationFetchEnabled,
    queryKey: ['nearestAssignableMedicalLocations', debouncedCurrentPostcode],
    queryFn: () => {
      return startPollingForNearestMedicalLocations({
        poller: nhsPoller,
        search: debouncedCurrentPostcode,
        searchService: 'nhs',
      });
    },
    retry: false,
  });

  const {
    data: assignableGoogleMedicalLocations,
    error: googleMedicalLocationsFetchError,
    isFetching: isFetchingGoogleMedicalLocations,
  } = useQuery({
    enabled: !!debouncedCurrentTextQuery,
    queryKey: ['nearestAssignableMedicalLocations', debouncedCurrentTextQuery],
    queryFn: () => {
      return startPollingForNearestMedicalLocations({
        poller: googlePoller,
        search: debouncedCurrentTextQuery,
        searchService: 'google',
      });
    },
    retry: false,
  });

  const handlePostcodeChange = (value) => {
    setIsNhsMedicalLocationFetchEnabled(false);
    const postcode = value.replace(/\s/g, '');
    const isValidFormat = isValidPostcodeFormat({ postcode });
    setCurrentPostcode(postcode);
    setIsFindNearestMedicalLocationButtonEnabled(isValidFormat);
    if (isValidFormat) setShowNhsBanner(true);
  };

  const isFindNearestMedicalLocationButtonDisabled =
    !isFindNearestMedicalLocationButtonEnabled ||
    !!nhsMedicalLocationsFetchError ||
    isSearchInputVisible;

  const queryClient = useQueryClient();

  const collectedAssignableMedicalLocations = queryClient
    .getQueriesData(['nearestAssignableMedicalLocations'])
    .map((queryResult) => {
      const [_queryKey, queryData] = queryResult;

      return queryData;
    })
    .flat()
    .filter(Boolean);

  const selectedMedicalLocation =
    selectedMedicalLocationId &&
    collectedAssignableMedicalLocations.find(
      (assignableMedicalLocation) =>
        assignableMedicalLocation.id === selectedMedicalLocationId,
    );

  const medicalLocation =
    selectedMedicalLocation ||
    (isRemovingDomainMedicalLocation ? null : domainMedicalLocation);

  // Add event listener to external postcode field
  useEffect(() => {
    const element = document.getElementById(postcodeId);

    if (element) {
      handlePostcodeChange(element.value);
      element.addEventListener('input', (event) =>
        handlePostcodeChange(event.target.value),
      );
    }

    return () => {
      if (element)
        element.removeEventListener('input', (event) =>
          handlePostcodeChange(event.target.value),
        );

      if (googlePoller.current) {
        googlePoller.current.endPolling();
        googlePoller.current = null;
      }

      if (nhsPoller.current) {
        nhsPoller.current.endPolling();
        nhsPoller.current = null;
      }
    };
  }, []);

  return (
    <>
      {!(!isEditable && isRemovingDomainMedicalLocation) && (
        <div className='field form-group'>
          <label className='col-sm-3 control-label tw-text-m tw-font-medium tw-tracking-auto'>
            Nearest A&E
          </label>
          <div className='col-sm-8'>
            <div className='tw-w-[80%] tw-max-w-[80%] tw-pt-2'>
              {!!medicalLocation ?
                <MedicalLocationCard
                  domainMapConfiguration={domainMedicalLocationMapConfiguration}
                  domainMapConfigurationImageUrl={
                    domainMedicalLocationMapConfigurationImageUrl
                  }
                  isEditable={isEditable}
                  mapConfiguration={mapConfiguration}
                  medicalLocation={medicalLocation}
                  onIncludeMapCheckboxChange={(event) =>
                    setShowMap(event.target.checked)
                  }
                  onMapCameraChanged={(event) => {
                    setMapConfiguration({
                      ...mapConfiguration,
                      centerLatitude: event.detail.center.lat,
                      centerLongitude: event.detail.center.lng,
                      zoomLevel: event.detail.zoom,
                    });
                  }}
                  onRemoveClick={() => {
                    if (!!domainMedicalLocation)
                      setIsRemovingDomainMedicalLocation(true);
                    if (!!selectedMedicalLocationId)
                      setSelectedMedicalLocationId(null);
                  }}
                  showMap={showMap}
                  siteCoordinates={currentPostcodeCoordinates}
                />
              : isEditable && (
                  <>
                    <div className='tw-flex tw-items-center tw-gap-x-2'>
                      {isNhsApiLookupAllowed && (
                        <>
                          <div
                            className={classNames({
                              'tw-bg-cyan-025':
                                !isSearchInputVisible &&
                                showNhsBanner &&
                                !nhsMedicalLocationsFetchError,
                              'tw-bg-red-025':
                                !isSearchInputVisible &&
                                showNhsBanner &&
                                nhsMedicalLocationsFetchError,
                              'tw-rounded-t-md tw-p-2':
                                !isSearchInputVisible && showNhsBanner,
                            })}
                          >
                            <ConditionalWrapper
                              condition={
                                isFindNearestMedicalLocationButtonDisabled
                              }
                              wrapper={(children) => (
                                <Tooltip
                                  placement='bottom'
                                  tooltip='Enter location postcode to find nearest A&E'
                                  trigger='hover'
                                >
                                  {children}
                                </Tooltip>
                              )}
                            >
                              <OutlinedButton
                                className='tw-w-39 tw-min-w-[156px] tw-justify-center tw-bg-white'
                                color='grey'
                                disabled={
                                  isFindNearestMedicalLocationButtonDisabled
                                }
                                onClick={() =>
                                  setIsNhsMedicalLocationFetchEnabled(true)
                                }
                                size='sm'
                              >
                                Find nearest A&E
                              </OutlinedButton>
                            </ConditionalWrapper>
                          </div>

                          <p className='tw-mb-0 tw-text-grey-700'>or</p>
                        </>
                      )}

                      {isSearchInputVisible ?
                        <MedicalLocationSearchSelect
                          assignableMedicalLocations={
                            assignableGoogleMedicalLocations
                          }
                          autoFocus={isNhsApiLookupAllowed}
                          errorMessage={
                            !!googleMedicalLocationsFetchError &&
                            'Service temporarily unavailable. Try again later.'
                          }
                          isLoading={isFetchingGoogleMedicalLocations}
                          onBlur={() => {
                            if (isNhsApiLookupAllowed)
                              setIsSearchInputVisible(false);
                          }}
                          onInputChange={(value) => setCurrentTextQuery(value)}
                          onSelect={(id) => {
                            setIsNhsMedicalLocationFetchEnabled(false);
                            setSelectedMedicalLocationId(id);
                          }}
                        />
                      : <TextButton
                          className='-tw-ml-3 tw-bg-transparent tw-text-blue-500'
                          onClick={() => setIsSearchInputVisible(true)}
                          size='sm'
                          type='button'
                        >
                          search hospital by address
                        </TextButton>
                      }
                    </div>

                    {isNhsApiLookupAllowed && !isSearchInputVisible && (
                      <>
                        {showNhsBanner && (
                          <div
                            className={classNames(
                              'tw-flex tw-items-center tw-rounded-b-md tw-rounded-tr-md tw-p-3',
                              !!nhsMedicalLocationsFetchError ?
                                'tw-bg-red-025 tw-text-red-800'
                              : 'tw-bg-cyan-025 tw-text-cyan-800',
                            )}
                          >
                            <img
                              alt='NHS content'
                              className='tw-h-8 tw-bg-white tw-p-1'
                              src={HandshqApp.rails.nhsImagePath}
                            />
                            <div className='tw-ml-3'>
                              <NhsBannerParagraph
                                assignableNhsMedicalLocations={
                                  assignableNhsMedicalLocations
                                }
                                nhsMedicalLocationsFetchError={
                                  nhsMedicalLocationsFetchError
                                }
                              />
                            </div>
                          </div>
                        )}
                        {isNhsMedicalLocationFetchEnabled &&
                          (isFetchingNhsMedicalLocations ?
                            <LoadingSkeleton />
                          : isNhsMedicalLocationFetchSuccess &&
                            assignableNhsMedicalLocations.map(
                              (assignableMedicalLocation) => (
                                <MedicalLocationSelectItem
                                  assignableMedicalLocation={
                                    assignableMedicalLocation
                                  }
                                  key={assignableMedicalLocation.id}
                                  onSelect={(id) =>
                                    setSelectedMedicalLocationId(id)
                                  }
                                />
                              ),
                            ))}
                      </>
                    )}
                  </>
                )
              }
            </div>
          </div>
        </div>
      )}
      {/* Check for id of domain medical location as it could be a 'domain' version of an unsaved medical location when there are validation failures */}
      {(!!domainMedicalLocation?.id || !!medicalLocation) && (
        <MedicalLocationInputFields
          domainMedicalLocationId={domainMedicalLocation?.id}
          mapConfiguration={mapConfiguration}
          medicalLocation={medicalLocation}
          showMap={showMap}
        />
      )}
    </>
  );
}

function NhsBannerParagraph({
  assignableNhsMedicalLocations,
  nhsMedicalLocationsFetchError,
}) {
  switch (true) {
    case !!nhsMedicalLocationsFetchError:
      return (
        <p className='tw-mb-0'>
          Please ensure the postcode is in England. If the issue continues, or
          for sites outside England, use "
          <strong>search hospital by address</strong>".
        </p>
      );
    case !!assignableNhsMedicalLocations &&
      !assignableNhsMedicalLocations.length:
      return (
        <p className='tw-mb-0'>
          There were no results for your search. Please check postcode or use "
          <strong>search hospital by address</strong>".
        </p>
      );
    default:
      return (
        <p className='tw-mb-0'>
          This feature uses the NHS database for hospitals in England.
          <br />
          For sites outside England, use "
          <strong>search hospital by address</strong>".
        </p>
      );
  }
}
