import React from 'react';
import PropTypes from 'prop-types';

import { SortableHandle } from 'react-sortable-hoc';

import Tooltip from 'components/application/Tooltip';

import DragIcon from '-!svg-react-loader?name=DragIcon!icons/ic-drag.svg';
import TrashIcon from '-!svg-react-loader?name=TrashIcon!icons/ic-trash.svg';
import classNames from 'classnames';

const DragHandle = SortableHandle(({ isSorting }) => (
  <DragIcon
    className={classNames(
      'drag-icon [&_path]:tw-fill-grey-300',
      isSorting && 'visibility-hidden',
    )}
    height={18}
    width={8}
  />
));

export default function FieldOption({
  isAutoFocused,
  isDeleteAllowed,
  isInvalid,
  isSorting,
  onOptionDelete,
  onOptionValueChange,
  option,
  removeErrorStyling,
}) {
  const inputClassName = (() => {
    switch (true) {
      case isInvalid:
        return 'tw-border-red-600 hover:tw-border-red-600 focus-within:tw-border-red-600 hover:focus-within:tw-border-red-600';
      default:
        return 'tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300 placeholder:tw-text-grey-300';
    }
  })();

  return (
    <li className='sortable-collection__member-wrapper'>
      <div className='sortable-collection__member'>
        <DragHandle isSorting={isSorting} />
        <div className={'sortable-collection__input-container'}>
          <input
            autoFocus={isAutoFocused}
            className={classNames(
              'sortable-collection__input field__input tw-font-inter tw-text-m tw-font-normal tw-tracking-auto',
              inputClassName,
            )}
            id={`sortable-collection__input-${option.id}`}
            onChange={(event) => {
              onOptionValueChange({ id: option.id, value: event.target.value });
            }}
            onFocus={removeErrorStyling}
            type='text'
            value={option.value}
          />
        </div>
        <div className='m-t-8 m-r-20 m-b-8 m-l-12'>
          <div
            className={classNames(
              'tooltip-parent tw-inline-flex tw-h-6 tw-w-6 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-lg tw-border-0 tw-p-0',
              isDeleteAllowed ?
                'tw-bg-transparent hover:tw-bg-red-025 active:tw-bg-red-050 [&_svg_path]:tw-fill-grey-500 [&_svg_path]:hover:tw-fill-red-600 [&_svg_path]:active:tw-fill-red-600'
              : 'tw-pointer-events-none tw-bg-grey-050 [&_svg_path]:tw-fill-grey-300',
            )}
          >
            <TrashIcon
              className='sortable-collection__delete-button'
              height={24}
              onClick={
                isDeleteAllowed ?
                  () => {
                    onOptionDelete({ id: option.id });
                  }
                : null
              }
              width={24}
            />
            {!isDeleteAllowed && (
              <Tooltip
                className='tooltip-dark--max-w-xxs'
                placement='left'
                tooltip='List must have two items'
                trigger='hover'
              />
            )}
          </div>
        </div>
      </div>
    </li>
  );
}

FieldOption.propTypes = {
  option: PropTypes.exact({
    id: PropTypes.string.isRequired,
    isNew: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  isSorting: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  isAutoFocused: PropTypes.bool.isRequired,
  isDeleteAllowed: PropTypes.bool.isRequired,
  onOptionValueChange: PropTypes.func.isRequired,
  onOptionDelete: PropTypes.func.isRequired,
};
