import React from 'react';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import { toggleGrabbing } from 'components/helpers/document';

import DragIcon from '-!svg-react-loader?name=DragIcon!icons/ic-drag.svg';
import CollectionInput from 'components/collections/CollectionInput';

const DragHandle = SortableHandle((props) => (
  <DragIcon
    className={`drag-icon [&_path]:tw-fill-grey-300 drag-handle-${props.sortIndex}`}
    height={18}
    width={8}
  />
));

const SortableItem = SortableElement(
  ({ collectionProps, sortIndex, value }) => (
    <li className='sortable-collection__member-wrapper'>
      <div className='sortable-collection__member'>
        <DragHandle sortIndex={sortIndex} />
        <CollectionInput
          collectionErrors={collectionProps.collectionErrors}
          deleteAllowed={collectionProps.collection.length > 1}
          index={sortIndex}
          onChange={collectionProps.onListInputChange}
          onDelete={collectionProps.onDelete}
          removeErrorStyling={collectionProps.removeErrorStyling}
          submitDisabled={collectionProps.submitDisabled}
          value={value.name}
        />
      </div>
    </li>
  ),
);

const SortableList = SortableContainer(({ collection, collectionProps }) => {
  return (
    <ul className='sortable-collection'>
      {collection.map((value, index) => {
        return (
          <SortableItem
            collectionProps={collectionProps}
            index={index}
            key={`item-${index}`}
            sortIndex={index}
            value={value}
          />
        );
      })}
    </ul>
  );
});

function SortableCollection({ collection, collectionProps }) {
  function handleSortStart({ _helper, _node }) {
    toggleGrabbing(true);
  }

  function handleSortEnd({ newIndex, oldIndex }) {
    toggleGrabbing(false);
    collectionProps.onSortEnd({ oldIndex, newIndex });
  }

  return (
    <SortableList
      axis='y'
      collection={collection}
      collectionProps={collectionProps}
      helperClass='sortable-collection__member-wrapper--active'
      lockAxis='y'
      lockToContainerEdges
      onSortEnd={handleSortEnd}
      onSortStart={handleSortStart}
      transitionDuration={400}
      useDragHandle
    />
  );
}

export default SortableCollection;
