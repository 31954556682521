export function masterActivityTradesAttributes({
  masterActivityTrades,
  selectableTradeIds,
  selectedTradeIds,
}) {
  const associatedTradeIds = masterActivityTrades.map(
    (masterActivityTrade) => masterActivityTrade.relationships.trade.data.id,
  );

  return selectableTradeIds.reduce((acc, tradeId, index) => {
    const isSelected = selectedTradeIds.includes(tradeId);
    const isAssociated = associatedTradeIds.includes(tradeId);

    switch (true) {
      case isSelected && !isAssociated:
        acc[index] = { trade_id: tradeId };
        break;
      case !isSelected && isAssociated:
        acc[index] = {
          id: masterActivityTrades.find(
            (join) => join.relationships.trade.data.id === tradeId,
          ).id,
          _destroy: '1',
        };
        break;
    }

    return acc;
  }, {});
}
