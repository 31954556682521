import React from 'react';
import PropTypes from 'prop-types';

const layout = {
  small: { textSize: 's', marginSize: '4' },
  medium: { textSize: 'm', marginSize: '8' },
};

const flavourClassName = {
  info: 'tw-bg-cyan-300',
  warning: 'tw-bg-red-300',
};

export default function InfoBanner({
  children,
  flavour = 'info',
  size = 'small',
  title,
}) {
  return (
    <div className='box-shadow flex m-b-24'>
      <div
        className={`${flavourClassName[flavour]} fw-4 h-100 br-tl-4 br-bl-4 flex-sh-0`}
      ></div>
      <div className='p-16 br-tr-4 br-br-4 flex-g-1 tw-bg-white'>
        <div
          className={`tw-text-l tw-font-semibold tw-text-grey-900 m-b-${layout[size]['marginSize']}`}
        >
          {title}
        </div>
        <div
          className={`tw-text-${layout[size]['textSize']} tw-tracking-wide tw-text-grey-700`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

InfoBanner.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  flavour: PropTypes.string,
  size: PropTypes.string,
};
