import { z } from 'zod';
import voca from 'voca';

export const permittedFilters = [
  'divisionFilter',
  'personnelTypeFilter',
  'roleFilter',
  'courseFilter',
  'courseRequirementFilter',
  'lineManagerFilter',
  'teamFilter',
];

function cantBeBlankIssue(filter) {
  return {
    code: z.ZodIssueCode.too_small,
    type: 'array',
    minimum: 1,
    inclusive: true,
    exact: false,
    message: `${voca.capitalize(voca.kebabCase(filter).replace(/-/g, ' '))} can't be blank`,
    path: [filter],
  };
}

export const filtersSchema = z
  .object({
    selectedFilters: z.array(z.enum(permittedFilters)),
    divisionFilter: z.object({
      selectedIds: z.array(z.string()),
    }),
    personnelTypeFilter: z.object({
      employees: z.boolean(),
      subcontractors: z.boolean(),
    }),
    roleFilter: z.object({
      selectedIds: z.array(z.string()),
      additionalTraining: z.boolean(),
    }),
    courseFilter: z.object({
      selectedIds: z.array(z.string()),
    }),
    courseRequirementFilter: z.object({
      required: z.boolean(),
      optional: z.boolean(),
    }),
    lineManagerFilter: z.object({
      selectedIds: z.array(z.string()),
    }),
    teamFilter: z.object({
      selectedIds: z.array(z.string()),
    }),
  })
  .superRefine((schema, ctx) => {
    if (
      schema.selectedFilters.includes('divisionFilter') &&
      schema.divisionFilter.selectedIds.length === 0
    ) {
      ctx.addIssue(cantBeBlankIssue('divisionFilter'));
    }

    if (
      schema.selectedFilters.includes('roleFilter') &&
      schema.roleFilter.selectedIds.length === 0
    ) {
      ctx.addIssue(cantBeBlankIssue('roleFilter'));
    }

    if (
      schema.selectedFilters.includes('courseFilter') &&
      schema.courseFilter.selectedIds.length === 0
    ) {
      ctx.addIssue(cantBeBlankIssue('courseFilter'));
    }

    if (
      schema.selectedFilters.includes('lineManagerFilter') &&
      schema.lineManagerFilter.selectedIds.length === 0
    ) {
      ctx.addIssue(cantBeBlankIssue('lineManagerFilter'));
    }

    if (
      schema.selectedFilters.includes('teamFilter') &&
      schema.teamFilter.selectedIds.length === 0
    ) {
      ctx.addIssue(cantBeBlankIssue('teamFilter'));
    }
  });
