import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  personDisplayName,
  accessTypeHierarchy,
} from 'components/helpers/users';
import Tooltip from 'components/application/Tooltip';
import ConditionalWrapper from 'components/application/ConditionalWrapper';
import { asDate } from 'components/helpers/dates';
import Options from 'components/application/Options';
import DisableableOptionChoice from 'components/application/DisableableOptionChoice';
import TruncatedPill from 'components/application/pills/TruncatedPill';
import SmallStubPill from 'components/application/pills/SmallStubPill';
import ResendInviteModal from 'components/users/ResendInviteModal';
import ResetPasswordModal from 'components/users/ResetPasswordModal';
import RemoveModal from 'components/users/RemoveModal';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import ToastRack from 'components/application/ToastRack';
import useToasts from 'components/hooks/useToasts';
import ProfilePhotoDisplay from 'components/application/ProfilePhotoDisplay';

const divisionBreakpoint = 2;

const removalBlockerTooltipText = {
  isPrimary: "The owner can't be removed",
  isSelf: "You can't remove yourself",
  lackPermission: "You don't have permission to remove this user",
};

const editBlockerTooltipText = {
  lackPermission: "You don't have permission to edit this user",
};

const passwordResetBlockerTooltipText = {
  samlSsoEnforced: "Password can't be reset as single sign-on is enforced",
};

UserManagementRow.propTypes = {
  id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  accessType: PropTypes.string.isRequired,
  invitationStatus: PropTypes.string.isRequired,
  currentSignInAt: PropTypes.string,
  invitationSentAt: PropTypes.string,
  divisions: PropTypes.array.isRequired,
  accessControl: PropTypes.object.isRequired,
  withDivisionInfo: PropTypes.bool.isRequired,
  onResendInvitation: PropTypes.func.isRequired,
  onPasswordReset: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
};

export default function UserManagementRow(props) {
  const currentActorContext = useCurrentActor();
  const {
    accessControl,
    accessType,
    currentSignInAt,
    divisions,
    email,
    firstName,
    id,
    invitationSentAt,
    invitationStatus,
    lastName,
    onEditClick,
    onPasswordReset,
    onRemoveUser,
    onResendInvitation,
    profilePhoto,
    withDivisionInfo,
  } = props;

  const [resendModalOpen, setResendModalOpen] = useState(false);
  const [resetPasswordModalOpen, setPasswordResetModalOpen] = useState(false);
  const [removeUserModalOpen, setRemoveUserModalOpen] = useState(false);
  const [toasts, _setToasts, _addToast, _handleBurnToast, addInedibleToast] =
    useToasts();

  const shouldHideDivisions = divisions.length > divisionBreakpoint;
  const viewableDivisions =
    shouldHideDivisions ? divisions.slice(0, 1) : divisions;
  const hasInviteExpired = invitationStatus == 'expired';
  const isAdminType = ['administrator', 'primary'].includes(accessType);
  const removalStrategy =
    (
      !currentActorContext.isManagingMultiDivisions &&
      divisions.length > 1 &&
      !isAdminType
    ) ?
      'revoke'
    : 'delete';

  const statusDescription = {
    accepted: 'Last seen',
    pending: 'Pending',
    expired: 'Invite expired',
  }[invitationStatus];

  const statusDate = {
    accepted: currentSignInAt,
    pending: invitationSentAt,
  }[invitationStatus];

  const accessDescription = {
    primary: 'Owner',
    administrator: 'Account admin',
  }[accessType];

  const statusBasedCellClassName = hasInviteExpired ? ' tw-bg-grey-025' : '';
  const isInviteResendable = ['pending', 'expired'].includes(invitationStatus);
  const isPasswordResettable = ['accepted', 'none'].includes(invitationStatus);

  const matchesOrExceedsAccessType =
    accessTypeHierarchy.indexOf(
      currentActorContext.user.attributes.accessType,
    ) >= accessTypeHierarchy.indexOf(accessType);

  let removalBlocker;
  if (!matchesOrExceedsAccessType) {
    removalBlocker = 'lackPermission';
  } else if (accessType === 'primary') {
    removalBlocker = 'isPrimary';
  } else if (id === currentActorContext.user.id) {
    removalBlocker = 'isSelf';
  }

  let editBlocker;
  if (!matchesOrExceedsAccessType) editBlocker = 'lackPermission';

  let passwordResetBlocker;
  if (accessType === 'regular' && currentActorContext.isSamlSsoEnforced) {
    passwordResetBlocker = 'samlSsoEnforced';
  }

  const hiddenDivisionsTooltipContent = () =>
    divisions.slice(divisionBreakpoint - 1).map((division) => (
      <>
        {division.attributes.name}
        <br />
      </>
    ));

  const handleResendInvitationClick = () => setResendModalOpen(true);
  const handlePasswordResetClick = () => setPasswordResetModalOpen(true);
  const handleRemoveClick = () => setRemoveUserModalOpen(true);

  const handleResendSubmit = () => {
    setResendModalOpen(false);
    axios
      .post(`/users/${id}/invites`)
      .then((_response) => {
        onResendInvitation({
          name: personDisplayName({ firstName, lastName }),
        });
      })
      .catch(() =>
        addInedibleToast({ errorTrigger: 'resending this invitation' }),
      );
  };

  const handlePasswordResetSubmission = () => {
    setPasswordResetModalOpen(false);
    axios
      .post('/users/password', { user: { email: email } })
      .then((_response) => {
        onPasswordReset({ name: personDisplayName({ firstName, lastName }) });
      })
      .catch(() =>
        addInedibleToast({ errorTrigger: 'resetting the password' }),
      );
  };

  const handleUserRemoval = () => {
    setRemoveUserModalOpen(false);
    axios
      .delete(`/users/${id}`)
      .then((_response) => onRemoveUser(id))
      .catch(() => addInedibleToast({ errorTrigger: 'removing this user' }));
  };

  const handleUserRevoke = () => {
    setRemoveUserModalOpen(false);
    axios
      .delete(`/access_controls/${accessControl.id}`)
      .then((_response) => onRemoveUser(id, true))
      .catch(() => addInedibleToast({ errorTrigger: 'removing this user' }));
  };

  return (
    <>
      <tr className='fh-70 tw-group/row hover:tw-bg-grey-025'>
        <td className={`tw-border-grey-100${statusBasedCellClassName}`}>
          <ProfilePhotoDisplay
            componentSize='sm'
            photoUrl={profilePhoto?.links?.croppedUrl}
            profileInitials={`${firstName[0]}${lastName[0]}`}
          />
        </td>
        <td className={`tw-border-grey-100${statusBasedCellClassName}`}>
          <div className='flex column'>
            <div className='truncated-text-container--outer-flex'>
              <span className='m-r-10 truncated-text-container--wrapped truncated-text-container--two-lines tw-text-m tw-font-medium tw-text-grey-900'>
                {personDisplayName({ firstName, lastName })}
              </span>
              {isAdminType && (
                <SmallStubPill className='tw-bg-cyan-025 tw-text-cyan-800'>
                  {accessDescription}
                </SmallStubPill>
              )}
            </div>
            <div className='truncated-text-container tw-text-s tw-font-normal tw-tracking-wide'>
              {email}
            </div>
          </div>
        </td>
        <td className={`tw-border-grey-100${statusBasedCellClassName}`}>
          <div className='flex column'>
            <div className='tw-text-m tw-font-medium'>{statusDescription}</div>
            <div className='tw-text-s tw-font-normal tw-tracking-wide'>
              {statusDate && asDate(statusDate)}
            </div>
          </div>
        </td>
        {withDivisionInfo && (
          <td className={`tw-border-grey-100${statusBasedCellClassName}`}>
            <span className='flex flex--vertically-centered'>
              {isAdminType ?
                <TruncatedPill className='tw-bg-grey-200 tw-text-grey-600'>
                  All divisions
                </TruncatedPill>
              : divisions.length === 0 ?
                <Tooltip
                  placement='top'
                  tooltip={
                    <p className='m-0 p-0'>
                      The user will not be able to access HandsHQ
                      <br />
                      until they have been given access to a division
                    </p>
                  }
                  trigger='hover'
                >
                  <TruncatedPill className='tw-bg-red-025 tw-text-red-800'>
                    No permissions
                  </TruncatedPill>
                </Tooltip>
              : <>
                  {viewableDivisions.map((division) => (
                    <DivisionPill
                      division={division}
                      key={`user--${id}_division--${division.id}`}
                    />
                  ))}
                  {shouldHideDivisions && (
                    <Tooltip
                      className='tooltip-dark--text-left tooltip-dark--max-w-xxs'
                      placement='left'
                      tooltip={hiddenDivisionsTooltipContent()}
                      trigger='hover'
                    >
                      <span>+ {divisions.length - 1} more</span>
                    </Tooltip>
                  )}
                </>
              }
            </span>
          </td>
        )}
        <td className={`p-0 tw-border-grey-100${statusBasedCellClassName}`}>
          <div className={'dropdown__container regular-actions'}>
            <div className='dropdown'>
              <Options>
                <DisableableOptionChoice
                  disabled={!!editBlocker}
                  disabledTooltipText={editBlockerTooltipText[editBlocker]}
                  label='Edit'
                  onClick={(setOptionsOpen) => {
                    onEditClick(id);
                    setOptionsOpen(false);
                  }}
                />
                {isInviteResendable && (
                  <DisableableOptionChoice
                    disabled={false}
                    label='Resend invitation'
                    onClick={(setOptionsOpen) => {
                      handleResendInvitationClick();
                      setOptionsOpen(false);
                    }}
                  />
                )}
                {isPasswordResettable && (
                  <DisableableOptionChoice
                    disabled={!!passwordResetBlocker}
                    disabledTooltipText={
                      passwordResetBlockerTooltipText[passwordResetBlocker]
                    }
                    label='Reset password'
                    onClick={(setOptionsOpen) => {
                      handlePasswordResetClick();
                      setOptionsOpen(false);
                    }}
                  />
                )}
                <DisableableOptionChoice
                  disabled={!!removalBlocker}
                  disabledTooltipText={
                    removalBlockerTooltipText[removalBlocker]
                  }
                  label='Remove'
                  onClick={(setOptionsOpen) => {
                    handleRemoveClick();
                    setOptionsOpen(false);
                  }}
                  warning={true}
                />
              </Options>
            </div>
          </div>
          {resendModalOpen && (
            <ResendInviteModal
              onClose={() => setResendModalOpen(false)}
              onResend={handleResendSubmit}
              {...{ firstName, lastName, email }}
            />
          )}
          {resetPasswordModalOpen && (
            <ResetPasswordModal
              onClose={() => setPasswordResetModalOpen(false)}
              onReset={handlePasswordResetSubmission}
              {...{ firstName, lastName, email }}
            />
          )}
          {removeUserModalOpen && (
            <RemoveModal
              {...{ firstName, lastName }}
              divisionName={currentActorContext.division.attributes.name}
              isTrainingRegisterAllowed={
                currentActorContext.isTrainingRegisterAllowed
              }
              onClose={() => setRemoveUserModalOpen(false)}
              onRemove={handleUserRemoval}
              onRevoke={handleUserRevoke}
              strategy={removalStrategy}
            />
          )}
        </td>
      </tr>
      <ToastRack toasts={toasts} />
    </>
  );
}

const DivisionPill = ({ division }) => {
  const name = division.attributes.name;
  const truncationLength = 10;

  return (
    <TruncatedPill className='tw-bg-grey-200 tw-text-grey-600'>
      <ConditionalWrapper
        condition={name.length > truncationLength}
        wrapper={(children) => (
          <Tooltip placement='left' tooltip={name} trigger='hover'>
            {children}
          </Tooltip>
        )}
      >
        {division.attributes.name}
      </ConditionalWrapper>
    </TruncatedPill>
  );
};
