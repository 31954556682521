import HandshqApp from '../handshq-app';

HandshqApp.CategoryForm = function (root) {
  if (this instanceof HandshqApp.CategoryForm) {
    const _this = this;

    this.root = $(root);
    this.categoryForm = this.root.find('.category__hidden-form');

    this.setup = function () {
      categoryNameChange();

      return this;
    };

    function categoryNameChange() {
      _this.categoryForm.find('#trade_name').on('change', function (_event) {
        _this.categoryForm.find("input[type='submit']").trigger('click');
      });
    }

    this.subTasksContainer = new HandshqApp.SubTasksContainer(_this).setup();
    this.tasksContainer = new HandshqApp.TasksContainer(_this).setup();
    this.sectionsContainer = new HandshqApp.SectionsContainer(_this).setup();
  } else {
    throw new Error('HandshqApp.CategoryForm invoked without new');
  }
};

HandshqApp.categoryFormHelper = {
  setupNewClickEvent: function (options) {
    options['new'].on('click', function (_event) {
      HandshqApp.domHelper.toggleElements(options['new'], options['footer']);
      $('html, body').animate({ scrollTop: $(document).height() }, 'slow');
      options['footerNew'].find('textarea').focus();
    });
  },

  setupDeleteEvent: function (options) {
    const contentNode = options['contentNode'] || options['root'];
    const modalNode = options['modalNode'] || options['root'];

    contentNode
      .find('.' + options['resource'] + '__show-delete')
      .on('click', function (event) {
        event.stopPropagation();

        const target = $(this).data('target');
        $(target).modal('show');
      });

    modalNode
      .find('.' + options['resource'] + '__delete')
      .on('click', function (_event) {
        const target = $(this).data('target');
        const callback = options['onDeleteCallback'];
        if (callback) {
          callback(target);
        }
        $(target).remove();
      });
  },

  setupDeleteModal: function (options) {
    this.setupDeleteEvent(options);

    options['modalNode'].find("*[data-dismiss='modal']").each(function () {
      $(this).click(function (_event) {
        options['modalNode'].modal('hide');
      });
    });
  },

  setupActions: function (options) {
    HandshqApp.domHelper.clickStopPropagation(options['container'].actions);
    this.setupNewClickEvent({
      new: options['container'].new,
      footer: options['container'].footer,
      footerNew: options['container'].footerNew,
    });
    this.setupDeleteEvent({
      resource: options['resource'],
      root: options['container'].parent.root,
    });
  },
};

$(function () {
  if ($('.category__form').length) {
    const $forms = $('.category__form');

    $forms.each(function () {
      new HandshqApp.CategoryForm(this).setup();
    });
  }
});
