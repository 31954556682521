import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell } from 'recharts';

export default function StatusChart({
  chartData,
  highlightedKey,
  onKeyHighlight,
  onKeyUnhighlight,
  onSegmentClick,
  totalCount,
}) {
  const cellClassName = ({ entry }) => {
    return highlightedKey && entry.countKey !== highlightedKey ?
        'tw-fill-grey-100'
      : entry.cellClassName;
  };

  return (
    <div className='tw-relative'>
      <div className='tw-absolute tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center'>
        <h4 className='tw-m-0 tw-text-3xl tw-font-semibold tw-tracking-tight tw-text-grey-700'>
          {totalCount}
        </h4>
      </div>
      <PieChart height={180} width={180}>
        <Pie
          animationBegin={150}
          animationDuration={600}
          animationEasing='ease-in-out'
          data={chartData}
          dataKey='count'
          endAngle={-270}
          innerRadius='58%'
          isAnimationActive={true}
          margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          nameKey='name'
          onMouseLeave={onKeyUnhighlight}
          outerRadius='104%'
          startAngle={90}
        >
          {chartData.map((entry) => (
            <Cell
              className={cellClassName({ entry })}
              cursor='pointer'
              key={entry.countKey}
              onClick={() => onSegmentClick({ entry })}
              onMouseEnter={() => onKeyHighlight(entry.countKey)}
              strokeWidth={2}
            />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
}

StatusChart.propTypes = {
  totalCount: PropTypes.number.isRequired,
  chartData: PropTypes.array.isRequired,
  highlightedKey: PropTypes.string,
  onKeyHighlight: PropTypes.func.isRequired,
  onKeyUnhighlight: PropTypes.func.isRequired,
  onSegmentClick: PropTypes.func.isRequired,
};
