import pluralize from 'pluralize';

$(function () {
  const $pluraliseFieldSource = $('.pluralise-field_source');

  if ($pluraliseFieldSource.length > 0) {
    $pluraliseFieldSource.blur(function (_event) {
      const singularField = $(this);
      const pluralField = $('.pluralise-field_target');

      singularField.val(singularField.val().trim());
      pluralField.val(pluralize(singularField.val()));
    });
  }
});
