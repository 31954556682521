import React from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';
import ModalBox from 'components/application/ModalBox';

import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import moment from 'moment';
import classNames from 'classnames';

export default function DestroyBookingSourceModal({
  bookingSource,
  course,
  isOpen,
  onClose,
  onDestroySubmit,
}) {
  const isBookingGroup = bookingSource.type === 'bookingGroup';
  const personnelCount = isBookingGroup && bookingSource.meta.personnelCount;
  const courseHasELearningCourse =
    course && !!course.relationships.eLearningCourse.data;
  const headerText =
    isBookingGroup ?
      `Are you sure you want to remove this ${courseHasELearningCourse ? 'enrolment' : 'booking'} for ${personnelCount} personnel?`
    : `Are you sure you want to remove the ${courseHasELearningCourse ? 'enrolment' : 'booking'} for ${course && course.attributes.name}?`;

  return (
    <ModalBox
      customFooter={
        <div className='modalbox__footer-actions modalbox__footer-actions--right'>
          <TextButton
            className='m-r-12'
            color='blue'
            onClick={onClose}
            size='md'
          >
            Cancel
          </TextButton>
          <FilledButton
            color='red'
            onClick={() => onDestroySubmit({ bookingSource, course })}
          >
            {isBookingGroup ?
              `Remove and notify ${personnelCount} personnel`
            : `Remove ${courseHasELearningCourse ? 'enrolment' : 'booking'}`}
          </FilledButton>
        </div>
      }
      isOpen={isOpen}
      mode='flexible'
      onClose={onClose}
    >
      <div className='tw-p-6'>
        <h2
          className={classNames(
            'm-0 p-0 tw-text-l tw-font-semibold tw-text-grey-900',
            isBookingGroup && 'tw-mb-6',
          )}
        >
          {headerText}
        </h2>
        {isBookingGroup && (
          <>
            <p className='m-0 p-0 tw-text-m tw-font-normal tw-text-grey-700'>
              Course:{' '}
              <span className='m-0 p-0 tw-text-m tw-font-medium tw-text-grey-700'>
                {course.attributes.name}
              </span>
            </p>
            <p className='m-0 p-0 tw-text-m tw-font-normal tw-text-grey-700'>
              Training Date:{' '}
              <span className='m-0 p-0 tw-text-m tw-font-medium tw-text-grey-700'>
                {moment
                  .parseZone(bookingSource.attributes.date)
                  .format('D MMM YYYY')}
              </span>
            </p>
          </>
        )}
        <br />
        <p className='m-0 p-0 tw-text-m tw-font-normal tw-text-grey-700'>
          {isBookingGroup ?
            `A notification will be sent to ${personnelCount} personnel and their line managers if they have email addresses.`
          : 'Email notifications will be sent to the personnel and their line manager if they have email addresses.'
          }
        </p>
      </div>
    </ModalBox>
  );
}

DestroyBookingSourceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bookingSource: resourceShape(['booking', 'bookingGroup']).isRequired,
  course: resourceShape('course').isRequired,
  onDestroySubmit: PropTypes.func.isRequired,
};
