import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useToasts from 'components/hooks/useToasts';

import ToastRack from 'components/application/ToastRack';
import Portal from 'components/application/Portal';
import MasterCoshhTable from 'components/masterCoshh/MasterCoshhTable';
import MasterCoshhBackButton from 'components/masterCoshh/MasterCoshhBackButton';

export default function MasterCoshhContainer(props) {
  const {
    exposureFrequencyOptions,
    locationOptions,
    methodOfUseOptions,
    personsAtRiskOptions,
    projectDisplayName,
    projectId,
    secondaryFieldDefaultValue,
    secondaryFieldLabel,
    workerExposureTimeOptions,
  } = props;

  const [coshhDocumentsChangeMade, setCoshhDocumentsChangeMade] =
    useState(false);
  const [toasts, _setToasts, _addToast, _handleBurnToast, addInedibleToast] =
    useToasts();

  const handleCoshDocumentChange = () => {
    setCoshhDocumentsChangeMade(true);
  };

  return (
    <>
      <MasterCoshhTable
        addInedibleToast={addInedibleToast}
        exposureFrequencyOptions={exposureFrequencyOptions}
        locationOptions={locationOptions}
        methodOfUseOptions={methodOfUseOptions}
        onCoshDocumentChange={handleCoshDocumentChange}
        personsAtRiskOptions={personsAtRiskOptions}
        projectId={projectId}
        secondaryFieldDefaultValue={secondaryFieldDefaultValue}
        secondaryFieldLabel={secondaryFieldLabel}
        workerExposureTimeOptions={workerExposureTimeOptions}
      />
      <Portal containerSelector='.coshh-back-button'>
        <MasterCoshhBackButton
          addInedibleToast={addInedibleToast}
          coshhDocumentsChangeMade={coshhDocumentsChangeMade}
          projectDisplayName={projectDisplayName}
          projectId={projectId}
        />
      </Portal>
      <ToastRack toasts={toasts} />
    </>
  );
}

MasterCoshhContainer.propTypes = {
  projectId: PropTypes.number.isRequired,
  workerExposureTimeOptions: PropTypes.array.isRequired,
  exposureFrequencyOptions: PropTypes.array.isRequired,
  locationOptions: PropTypes.array.isRequired,
  methodOfUseOptions: PropTypes.array.isRequired,
  personsAtRiskOptions: PropTypes.array.isRequired,
  projectDisplayName: PropTypes.string.isRequired,
  secondaryFieldLabel: PropTypes.string.isRequired,
  secondaryFieldDefaultValue: PropTypes.string.isRequired,
};
