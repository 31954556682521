import { useEffect } from 'react';

export default function useEscapeKey(callback, deps) {
  useEffect(() => {
    if (deps.every(Boolean)) {
      const handleEscape = (evt) =>
        (evt || window.event).keyCode == 27 && callback();

      window.addEventListener('keyup', handleEscape);

      return () => window.removeEventListener('keyup', handleEscape);
    }
  }, deps);
}
