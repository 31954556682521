import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useToasts from 'components/hooks/useToasts';
import useModal from 'components/hooks/useModal';
import useOneTimePassword from 'components/hooks/useOneTimePassword';
import useValidationErrors from 'components/hooks/useValidationErrors';

import OutlinedButton from 'components/application/buttons/OutlinedButton';
import EnableModal from 'components/settings/twoFactorAuthentication/EnableModal';
import DisableModal from 'components/settings/twoFactorAuthentication/DisableModal';
import ToastRack from 'components/application/ToastRack';
import SuccessToast from 'components/application/SuccessToast';
import Toast from 'components/application/Toast';

export default function ToggleTwoFactorAuthentication({ initialEnabledAt }) {
  const [enabledAt, setEnabledAt] = useState(initialEnabledAt);
  const [toasts, , addToast, handleBurnToast, addInedibleToast] = useToasts();

  const [isDisableModalOpen, , openDisableModal, closeDisableModal] = useModal(
    false,
    {},
  );
  const [isEnableModalOpen, , openEnableModal, closeEnableModal] = useModal(
    false,
    {},
  );
  const oneTimePasswordProps = useOneTimePassword();
  const validationErrorsProps = useValidationErrors({});
  const [provisioningUri, setProvisioningUri] = useState('');

  const handleReset = () => {
    oneTimePasswordProps.handleCleanupField();
    validationErrorsProps.handleValidationErrorsReset();
  };

  const handleCloseDisableModal = () => {
    handleReset();
    closeDisableModal();
  };

  const handleCloseEnableModal = () => {
    handleReset();
    closeEnableModal();
  };

  const handleDisableModalFormSubmit = (otpCode) => {
    const otpCodeStr = otpCode.join('');
    axios
      .post('/settings/two_factor/disablement', { otp_attempt: otpCodeStr })
      .then(() => {
        handleCloseDisableModal();
        setEnabledAt(null);
        addToast(
          <Toast
            burn={handleBurnToast}
            burnTimer={7000}
            contents='Two-factor authentication has been turned off for your account'
            modifiers='no-undo'
          />,
        );
      })
      .catch(() => {
        addInedibleToast();
      });
  };

  const handleEnableModalFormSubmit = (otpCode) => {
    const otpCodeStr = otpCode.join('');
    axios
      .post('/settings/two_factor/enablement', { otp_attempt: otpCodeStr })
      .then(() => {
        handleCloseEnableModal();
        setEnabledAt(
          new Date().toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          }),
        );
        addToast(
          <SuccessToast message='Two-factor authentication has been set up successfully for your account' />,
        );
      })
      .catch(() => {
        oneTimePasswordProps.handleCleanupField();
        validationErrorsProps.handleValidationErrorsAssignment({
          otp_attempt: ['Incorrect verification code'],
        });
      });
  };

  const handleOpenEnableModal = () => {
    // Fetch provisioningUri to render QR code
    axios
      .post('/settings/two_factor/provisionings/')
      .then((response) => {
        setProvisioningUri(response.data.data.attributes.uri);
        openEnableModal();
      })
      .catch(() => {
        addInedibleToast();
      });
  };

  return (
    <>
      {enabledAt ?
        <>
          <DisableModal
            closeModal={handleCloseDisableModal}
            isOpen={isDisableModalOpen}
            onFormSubmit={handleDisableModalFormSubmit}
            oneTimePasswordProps={oneTimePasswordProps}
            validationErrorsProps={validationErrorsProps}
          />
          <Message
            content={`Set up by you on ${enabledAt}. To learn more about two-factor authentication`}
          />
          <OutlinedButton
            className='tw-mb-5 tw-mt-6'
            color='red'
            onClick={openDisableModal}
            size='sm'
          >
            Turn off
          </OutlinedButton>
        </>
      : <>
          <EnableModal
            closeModal={handleCloseEnableModal}
            isOpen={isEnableModalOpen}
            onFormSubmit={handleEnableModalFormSubmit}
            oneTimePasswordProps={oneTimePasswordProps}
            provisioningUri={provisioningUri}
            validationErrorsProps={validationErrorsProps}
          />
          <Message content='You will need access to your authenticator app. For help setting up two-factor authentication' />
          <OutlinedButton
            className='tw-mb-5 tw-mt-6'
            color='grey'
            onClick={handleOpenEnableModal}
            size='sm'
          >
            Set up two-factor authentication
          </OutlinedButton>
        </>
      }
      <ToastRack toasts={toasts} />
    </>
  );
}

ToggleTwoFactorAuthentication.propTypes = {
  initialEnabledAt: PropTypes.string,
  provisioningUri: PropTypes.string.isRequired,
};

function Message({ content }) {
  return (
    <div className='tw-text-m tw-font-normal tw-tracking-auto'>
      {content}, read our&nbsp;
      <a
        className='app-link tw-text-blue-500 hover:tw-text-blue-300'
        href='https://intercom.help/hands-hq/en/articles/8862590-enable-2-factor-authentication-2fa'
        target='_blank'
      >
        help guide
      </a>
      .
    </div>
  );
}

Message.propTypes = {
  content: PropTypes.string.isRequired,
};
