import React from 'react';
import PropTypes from 'prop-types';

import Toast from 'components/application/Toast';
import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';

export default function FieldToast({
  message,
  onBurnToast,
  resource,
  toastKey,
}) {
  return (
    <Toast
      burn={onBurnToast}
      burnTimer={5000}
      contents={
        <>
          <div className='toast__icon tw-bg-green-500'>
            <TickIcon
              className='[&_polygon]:tw-fill-white'
              height={20}
              width={20}
            />
          </div>
          <div className='toast__text'>
            <span className='tw-font-medium'>{resource}</span>
            <span>&nbsp;{message}</span>
          </div>
        </>
      }
      modifiers='no-undo'
      toastKey={toastKey}
    />
  );
}

FieldToast.propTypes = {
  resource: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onBurnToast: PropTypes.func.isRequired,
};
