import React from 'react';
import { asDate } from 'components/helpers/dates';
import ClockIcon from '-!svg-react-loader?name=ClockIcon!icons/clock.svg';
import { escapeDoubleQuotes } from 'components/helpers/strings.js';
import ProfilePhotoDisplay from 'components/application/ProfilePhotoDisplay';
import HhqLogo from '-!svg-react-loader?name=HandsHQ!icons/hhq.svg';

export default function ActivityTableData(props) {
  const { downloadButtonVisible, history, showVersionNumberColumn } = props;

  let contentForActor;
  if (history.isApiActivity) {
    contentForActor = (
      <div className='tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-mint-050'>
        <span className='tw-text-sm tw-flex tw-items-center tw-justify-center tw-align-middle tw-font-semibold tw-uppercase tw-text-mint-700'>
          API
        </span>
      </div>
    );
  } else if (history.user && !history.nadminUser) {
    contentForActor = (
      <ProfilePhotoDisplay
        componentSize='sm'
        photoUrl={history.profilePhoto?.croppedUrl}
        profileInitials={`${history.user.firstName[0]}${history.user.lastName[0]}`}
      />
    );
  } else if (history.nadminUser) {
    contentForActor = <HhqLogo />;
  }

  return (
    <React.Fragment>
      {showVersionNumberColumn && (
        <td className='tw-border-grey-100 tw-font-medium tw-text-grey-900'>
          <div className='fh-32 flex flex--vertically-centered flex--justify-content__center'>
            {history.displayNumber}
          </div>
        </td>
      )}

      <td className='tw-border-grey-100'>
        <div className='fh-32 handshq__cell-content--near-spaced'>
          <ClockIcon
            className='m-r-4 [&_path]:tw-fill-grey-500'
            height={20}
            width={20}
          />
          <span>{asDate(history.createdAt)}</span>
        </div>
      </td>

      <td
        className='tw-border-grey-100'
        colSpan={downloadButtonVisible ? 1 : 2}
      >
        <div className='tw-flex tw-flex-wrap tw-items-center tw-justify-start'>
          <div className='tw-mr-2'>{contentForActor}</div>
          {history.nadminUser ?
            `${history.nadminUser} on behalf of ${escapeDoubleQuotes(history.reason)}`
          : escapeDoubleQuotes(history.reason)}
        </div>
      </td>
    </React.Fragment>
  );
}
