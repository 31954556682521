import React from 'react';
import PropTypes from 'prop-types';

import CheckboxFields from 'components/application/CheckboxFields';

export default function CheckboxFilter({ checkboxes, label, onRemove }) {
  return (
    <div className='tw-rounded-sm tw-border-1 tw-border-solid tw-border-grey-100 tw-bg-white tw-p-5'>
      <div className='tw-mb-4 tw-flex tw-items-center tw-justify-between'>
        <label className='tw-m-0 tw-font-medium'>{label}</label>
        <div
          className='tw-flex tw-h-5 tw-cursor-pointer tw-items-center tw-rounded-lg tw-border-0 tw-bg-white tw-px-2 tw-text-grey-500 hover:tw-bg-red-025 hover:tw-text-red-500'
          onClick={onRemove}
        >
          <span className='tw-text-s tw-font-medium tw-tracking-wide'>
            Remove filter
          </span>
        </div>
      </div>
      <CheckboxFields checkboxes={checkboxes} />
    </div>
  );
}

CheckboxFilter.propTypes = {
  label: PropTypes.string.isRequired,
  checkboxes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.bool,
      onChange: PropTypes.func.isRequired,
    }),
  ),
  onRemove: PropTypes.func.isRequired,
};
