export function filterOutNull(obj) {
  const filteredObj = Object.entries(obj).reduce(
    (a, [k, v]) => (v === null ? a : Object.assign({}, a, { [k]: v })),
    {},
  );

  return filteredObj;
}

export function convertNumbersToStrings(obj) {
  const manipulatedObj = Object.entries(obj).reduce(
    (a, [k, v]) =>
      Object.assign({}, a, { [k]: typeof v === 'number' ? String(v) : v }),
    {},
  );

  return manipulatedObj;
}

export function collectionsDiffer(collectionA, collectionB) {
  return JSON.stringify(collectionA) !== JSON.stringify(collectionB);
}

export function pick(obj, ...keys) {
  return keys.reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {});
}
