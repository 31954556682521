import React from 'react';
import PropTypes from 'prop-types';

import Table from 'components/application/Table';
import CourseRow from 'components/courses/CourseRow';

export default function CoursesTable({ courses, onRowClick, originalCourses }) {
  const formattedRows = courses.map((course) => {
    const originalCourseData = course.relationships.originalCourse.data;
    const originalCourse =
      originalCourseData ? originalCourses[originalCourseData.id] : null;

    return { course, originalCourse };
  });

  return (
    <Table
      headers={
        <tr>
          <th className='fw-66 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Status
          </th>
          <th className='w-45 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Course
          </th>
          <th className='w-10 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Method
          </th>
          <th className='w-10 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Provider
          </th>
          <th className='w-15 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Renewal frequency
          </th>
          <th className='w-10 tw-text-center tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Roles
          </th>
          <th className='w-10 tw-text-center tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Personnel
          </th>
        </tr>
      }
      rows={formattedRows.map((formattedRow) => (
        <CourseRow
          course={formattedRow.course}
          key={formattedRow.course.id}
          onRowClick={onRowClick}
          originalCourse={formattedRow.originalCourse}
        />
      ))}
    />
  );
}

CoursesTable.propTypes = {
  courses: PropTypes.array.isRequired,
  originalCourses: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
};
