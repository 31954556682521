import React from 'react';
import PropTypes from 'prop-types';

export default function Icon({
  checkboxSelected,
  disabled,
  iconId,
  iconImageAssetPath,
  iconName,
  onCheckboxChange,
}) {
  return (
    <label className='method_icon'>
      <img
        alt={iconName}
        className='tw-border-grey-100'
        height={75}
        src={iconImageAssetPath}
        width={75}
      />
      <input
        checked={checkboxSelected}
        className='icon_checkbox'
        disabled={disabled}
        id={iconId}
        onChange={onCheckboxChange}
        type='checkbox'
      />
      <span className='tw-font-normal'>{iconName}</span>
    </label>
  );
}

Icon.propTypes = {
  iconImageAssetPath: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  iconId: PropTypes.number.isRequired,
  checkboxSelected: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
};

Icon.defaultProps = {
  checkboxSelected: false,
};
