import React from 'react';
import PropTypes from 'prop-types';

import Table from 'components/application/Table';
import CoshhRow from 'components/projectCoshh/CoshhRow';

export default function ProjectCoshhTable({
  coshhDocuments,
  onCoshhDocumentRowClick,
  onDeleteClick,
  onDownloadClick,
}) {
  return (
    <Table
      headers={
        <tr className='handshq__tr--grey tw-bg-grey-050'>
          <th className='w-50 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            COSHH assessment
          </th>
          <th className='w-30 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Manufacturer
          </th>
          <th className='w-10 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Reference
          </th>
          <th className='w-10 tw-text-right tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
            Actions
          </th>
        </tr>
      }
      rows={coshhDocuments.map((coshhDocument) => (
        <CoshhRow
          coshhDocument={coshhDocument}
          key={coshhDocument.id}
          onCoshhDocumentRowClick={onCoshhDocumentRowClick}
          onDeleteClick={onDeleteClick}
          onDownloadClick={onDownloadClick}
        />
      ))}
    />
  );
}

ProjectCoshhTable.propTypes = {
  coshhDocuments: PropTypes.array.isRequired,
  onCoshhDocumentRowClick: PropTypes.func.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

ProjectCoshhTable.defaultProps = {
  coshhDocuments: [],
};
