import React from 'react';
import PropTypes from 'prop-types';

import LockIcon from '-!svg-react-loader?name=LockIcon!icons/ic-lock-10.svg';
import Tooltip from 'components/application/Tooltip';
import FilledButton from 'components/application/buttons/FilledButton';
import classNames from 'classnames';

export default function EnforceFormCard(props) {
  const {
    formValues,
    isIdentityProviderEnabled,
    isUpgradeRequired,
    onInputChange,
    onSubmit,
  } = props;

  return (
    <div className='section p-40 pos-rel tw-border-grey-100 tw-bg-white'>
      {isUpgradeRequired && (
        <div className='tooltip-parent m-0 tw-absolute -tw-top-3 tw-left-2 tw-flex tw-items-center tw-rounded-s-sm tw-border-0 tw-bg-mutedBlue-050 tw-px-2 tw-py-[6px] tw-text-mutedBlue-700'>
          <LockIcon
            className='m-r-8 [&_path]:tw-fill-mutedBlue-700'
            height={10}
            width={8}
          />
          <span className='tw-text-xs tw-font-semibold tw-tracking-wide'>
            Upgrade required
          </span>
          <Tooltip
            placement='top'
            tooltip='Speak to your account manager to upgrade your plan'
            trigger='hover'
          />
        </div>
      )}
      <h3 className='m-t-0 m-b-8 tw-text-l tw-font-semibold tw-tracking-auto tw-text-grey-900'>
        Enforce single sign-on
      </h3>
      <p className='m-0 m-b-32'>
        <span>
          Enabling this will stop users logging in via email and password.{' '}
        </span>
        <span>
          {isUpgradeRequired ?
            'Speak to your account manager about upgrading.'
          : "We don't recommend turning this on until you have tested SSO is working correctly to avoid users losing access."
          }
        </span>
      </p>
      <div className='m-0 form-group'>
        <label
          className='hhq-checkbox__label tooltip-parent'
          htmlFor='enforced'
        >
          <input
            checked={formValues.enforced}
            className='hhq-checkbox__input'
            disabled={isUpgradeRequired || !isIdentityProviderEnabled}
            id='enforced'
            name='enforced'
            onChange={onInputChange}
            type='checkbox'
            value='enforced'
          />
          <span
            className={classNames(
              'hhq-checkbox__toggle tw-cursor-pointer tw-border-2 tw-font-bold tw-text-white',
              formValues.enforced ?
                'tw-border-blue-400 tw-bg-blue-400 hover:tw-border-blue-500 hover:tw-bg-blue-500'
              : 'tw-border-grey-300 tw-bg-white hover:tw-border-blue-300 hover:tw-bg-grey-025',
            )}
          ></span>
          {!isIdentityProviderEnabled && (
            <div className='pos-abs pos-t-0 pos-l-0 fh-22 fw-22'>
              <Tooltip
                placement='top'
                tooltip='Single sign-on must be enabled first'
                trigger='hover'
              />
            </div>
          )}
          <span className='m-l-12 tw-font-normal tw-tracking-auto'>
            Enforce log in via single sign-on
          </span>
        </label>
      </div>
      <FilledButton
        className='m-t-40'
        color='blue'
        disabled={isUpgradeRequired || !isIdentityProviderEnabled}
        onClick={onSubmit}
      >
        Save changes
      </FilledButton>
    </div>
  );
}

EnforceFormCard.propTypes = {
  formValues: PropTypes.object.isRequired,
  isUpgradeRequired: PropTypes.bool.isRequired,
  isIdentityProviderEnabled: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
