import { useEffect } from 'react';

export default function useOutsideClick(callback, reference) {
  const handler = (event) => {
    if (reference.current && !reference.current.contains(event.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);
}
