import moment from 'moment';
import { orderedCoursesByRoleAndTraining } from 'components/helpers/resources/courses';

// reducer function candidate
export function inferredTrainingExpiryDates({
  dateToInferFrom,
  domainCourse,
  expiryDates,
}) {
  const inferredDates = { ...expiryDates };
  if (expiryDates.startDate != null || expiryDates.expiryDate != null) {
    if (domainCourse.attributes.expires) {
      const shouldAutoAssignExpiryDate =
        dateToInferFrom === 'startDate' && expiryDates.expiryDate === null;
      const shouldAutoAssignStartDate =
        dateToInferFrom === 'expiryDate' && expiryDates.startDate === null;

      if (shouldAutoAssignExpiryDate) {
        inferredDates.expiryDate = calculateTrainingExpiryDate({
          course: domainCourse,
          startDate: expiryDates.startDate,
        });
      } else if (shouldAutoAssignStartDate) {
        inferredDates.startDate = calculateTrainingStartDate({
          course: domainCourse,
          expiryDate: expiryDates.expiryDate,
        });
      }
    }
  }
  return inferredDates;
}

export function calculateTrainingExpiryDate({ course, startDate }) {
  return course.attributes.expires ?
      moment
        .parseZone(startDate)
        .add(
          course.attributes.renewalInterval,
          course.attributes.renewalFrequency,
        )
        .toDate()
    : null;
}

export function calculateTrainingStartDate({ course, expiryDate }) {
  const startDate =
    course.attributes.expires ?
      moment
        .parseZone(expiryDate)
        .subtract(
          course.attributes.renewalInterval,
          course.attributes.renewalFrequency,
        )
        .toDate()
    : null;

  return !!startDate && moment(startDate).isSameOrBefore(moment.now(), 'day') ?
      startDate
    : null;
}

export function defaultTrainingStartDate({ booking }) {
  return moment(booking.attributes.date).isSameOrBefore(moment.now(), 'day') ?
      moment.parseZone(booking.attributes.date).toDate()
    : null;
}

export function isExpiryDateAfterRenewalDate({
  expiryDate,
  renewalFrequency,
  renewalInterval,
  startDate,
}) {
  if (!startDate || !expiryDate) return false;

  const renewalDate = moment(startDate).add(renewalFrequency, renewalInterval);
  return moment(expiryDate).isAfter(renewalDate);
}

export function uniqueTrainingCollectionByCourse({ training }) {
  const map = new Map();

  return training.reduce((acc, member) => {
    if (!map.has(member.relationships.course.data.id)) {
      map.set(member.relationships.course.data.id, true);
      acc.push(member);
    }
    return acc;
  }, []);
}

export function groupTrainingByCourseId({ training }) {
  return training.reduce((acc, member) => {
    if (!acc.hasOwnProperty(member.relationships.course.data.id)) {
      acc[member.relationships.course.data.id] = [];
    }
    acc[member.relationships.course.data.id].push(member);
    return acc;
  }, {});
}

export function getTrainingStatus({ course, training }) {
  if (!training) {
    return 'missing';
  }

  const expiryDate =
    training.attributes.expiryDate ?
      moment.parseZone(training.attributes.expiryDate)
    : null;
  const expiringPeriodStartDate = moment(expiryDate).subtract(
    course.attributes.expiringDuration,
  );

  if (course.attributes.expires) {
    switch (true) {
      case moment().isBefore(expiringPeriodStartDate, 'day'):
        return 'valid';
      // moment what are these arguments... [] = inclusive, () = exclusive
      case moment().isBetween(expiringPeriodStartDate, expiryDate, 'day', '[)'):
        return 'expiring';
      case moment().isSameOrAfter(expiryDate, 'day'):
        return 'expired';
      case expiryDate === null:
        return 'expiryDateMissing';
      default:
        return 'invalid';
    }
  } else {
    return 'valid';
  }
}

export const courseTrainingStatusOrdering = ({
  course,
  isRequired,
  training,
}) => {
  const status = getTrainingStatus({ training, course });
  switch (status) {
    case 'missing':
      return isRequired ? 0 : 4;
    case 'expiring':
      return isRequired ? 2 : 3;
    case 'expired':
    case 'expiryDateMissing':
      return isRequired ? 1 : 4;
    case 'valid':
      return 3;
    case 'invalid':
      return 4;
    default:
      return 4;
  }
};

export function getTrainingExpiryDateLabel({ course, training }) {
  if (!training) {
    return 'Missing';
  }

  const expiryDate = moment.parseZone(training.attributes.expiryDate);

  return (
    course.attributes.expires ?
      training.attributes.expiryDate ?
        expiryDate.format('DD MMM YYYY')
      : 'Date missing'
    : 'Does not expire'
  );
}

export function trainingStatusReport({
  companyRole,
  courseTrainingStatusIds,
  courses,
  requiredCourses,
  training,
}) {
  if (requiredCourses.length === 0)
    return { status: 'noRequirements', courses: [] };

  const {
    expired,
    expiring: mediumPriorityStatusIds,
    expiryDateMissing,
    invalid,
    missing,
  } = { ...courseTrainingStatusIds };

  const highPriorityStatusIds = [
    ...missing,
    ...expiryDateMissing,
    ...expired,
    ...invalid,
  ];

  if (highPriorityStatusIds.length > 0 || mediumPriorityStatusIds.length > 0) {
    const roleCourseIds =
      companyRole &&
      new Set(companyRole.relationships.courses.data.map((c) => c.id));
    const trainingByCourseId = groupTrainingByCourseId({
      training,
    });

    if (highPriorityStatusIds.length > 0)
      return {
        status: 'invalid',
        courses: orderedCoursesByRoleAndTraining(
          highPriorityStatusIds.map((id) => courses[id]),
          roleCourseIds,
          trainingByCourseId,
        ),
      };
    if (mediumPriorityStatusIds.length > 0)
      return {
        status: 'expiring',
        courses: orderedCoursesByRoleAndTraining(
          mediumPriorityStatusIds.map((id) => courses[id]),
          roleCourseIds,
          trainingByCourseId,
        ),
      };
  }

  return { status: 'valid', courses: [] };
}
