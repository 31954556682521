import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function RangeField(props) {
  const {
    disabled = false,
    max = 2,
    min = 0,
    name,
    onChange,
    step,
    value,
  } = props;

  return (
    <div className='tw-relative tw-inline-flex tw-h-[10px] tw-w-full'>
      <div
        className={classNames(
          'tw-absolute tw-left-1/2 tw-top-[1px] tw--ml-[1px] tw-h-2 tw-w-[2px] tw-cursor-pointer',
          disabled ? 'tw-bg-grey-100' : 'tw-bg-grey-200',
        )}
      />
      <input
        className='tw-apply-range'
        disabled={disabled}
        max={max}
        min={min}
        name={name}
        onChange={onChange}
        step={step}
        type='range'
        value={value}
      />
    </div>
  );
}

RangeField.propTypes = {
  name: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
