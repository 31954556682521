import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function Switch(props) {
  const { checked, className, disabled, name, offLabel, onLabel, value } =
    props;

  return (
    <div className={classNames('app-switch', className)}>
      <input
        checked={checked}
        className='tw-peer'
        name={name}
        readOnly={true}
        type='checkbox'
        value={value}
      />
      <label
        className={classNames(
          "tw-bg-grey-200 tw-text-grey-700 after:tw-bg-white after:tw-content-[''] hover:tw-bg-blue-050 group-hover/option:tw-bg-blue-050 peer-checked:tw-bg-blue-400 peer-checked:tw-text-white peer-checked:hover:tw-bg-blue-500 group-hover/option:peer-checked:tw-bg-blue-500",
          disabled && 'tw-cursor-not-allowed',
        )}
        data-switch-off={offLabel}
        data-switch-on={onLabel}
      />
    </div>
  );
}

Switch.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
};

Switch.defaultProps = {
  disabled: false,
  onLabel: 'yes',
  offLabel: 'no',
  value: '',
  name: '',
};
