const isHrefOrOnClickRequired = (props, propName, componentName) => {
  if (
    typeof props.onClick === 'undefined' &&
    typeof props.href === 'undefined'
  ) {
    return new Error(
      `The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`undefined\`.`,
    );
  }
};

export { isHrefOrOnClickRequired };
