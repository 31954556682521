import React, { useState, useEffect } from 'react';
import { escapeDoubleQuotes } from 'components/helpers/strings.js';
import { asDate } from 'components/helpers/dates';
import DispatchRow from 'components/projectActivity/DispatchRow';
import ClockIcon from '-!svg-react-loader?name=ClockIcon!icons/clock.svg';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import DisableIcon from '-!svg-react-loader?name=DisableIcon!icons/ic-disable.svg';
import DisableLinkModal from 'components/projectActivity/DisableLinkModal';
import ProfilePhotoDisplay from 'components/application/ProfilePhotoDisplay';
import HhqLogo from '-!svg-react-loader?name=HandsHQ!icons/hhq.svg';

export default function ProjectMessageRow(props) {
  const { addInedibleToast, history, message, showVersionNumberColumn } = props;

  const [messageStatus, setMessageStatus] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    determineMessageStatus(message.message);
  }, []);

  const determineMessageStatus = (currentMessage) => {
    if (currentMessage.attributes['expired?']) {
      setMessageStatus('expired');
    } else if (currentMessage.attributes['disabled']) {
      setMessageStatus('disabled');
    } else {
      setMessageStatus('active');
    }
  };

  const disableLink = () => {
    axios
      .patch(`/messages/${history.messageId}`, { disabled: true })
      .then((response) => {
        determineMessageStatus(response.data.data);
      })
      .catch(addInedibleToast);
  };

  const disableLinkButton = (
    <OutlinedButton color='grey' onClick={openModal} size='sm'>
      <DisableIcon className='m-r-4 m-l--6' height={20} width={20} />
      <span>Disable link</span>
    </OutlinedButton>
  );

  let contentForActor;
  if (history.isApiActivity) {
    contentForActor = (
      <div className='tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-mint-050'>
        <span className='tw-text-sm tw-flex tw-items-center tw-justify-center tw-align-middle tw-font-semibold tw-uppercase tw-text-mint-700'>
          API
        </span>
      </div>
    );
  } else if (history.user && !history.nadminUser) {
    contentForActor = (
      <ProfilePhotoDisplay
        componentSize='sm'
        photoUrl={history.profilePhoto?.croppedUrl}
        profileInitials={`${history.user.firstName[0]}${history.user.lastName[0]}`}
      />
    );
  } else if (history.nadminUser) {
    contentForActor = <HhqLogo />;
  }

  return (
    <>
      <tr className='handshq__tr--top-aligned hover:tw-bg-grey-025'>
        {showVersionNumberColumn && (
          <td className='tw-border-grey-100 tw-font-medium tw-text-grey-900'>
            {history.displayNumber}
          </td>
        )}

        <td className='tw-border-grey-100'>
          <div className='handshq__cell-content--near-spaced'>
            <ClockIcon
              className='m-r-4 [&_path]:tw-fill-grey-500'
              height={20}
              width={20}
            />
            <span>{asDate(history.createdAt)}</span>
          </div>
        </td>

        <td className='tw-flex tw-flex-wrap tw-items-center tw-justify-start tw-border-grey-100'>
          <div className='tw-mr-2'>{contentForActor}</div>
          <div className='m-b-4'>
            {history.nadminUser ?
              `${history.nadminUser} on behalf of ${escapeDoubleQuotes(history.reason)}`
            : escapeDoubleQuotes(history.reason)}
          </div>
          {message &&
            message.dispatches.map((dispatch) => {
              return <DispatchRow dispatch={dispatch} key={dispatch.id} />;
            })}
        </td>
        {messageStatus === 'active' ?
          <td className='handshq__td-button-cell--align-right tw-border-grey-100'>
            {disableLinkButton}
          </td>
        : <td className='handshq__td-button-cell--align-right tw-border-grey-100'>
            <div>{`Link has ${messageStatus === 'expired' ? 'expired' : 'been disabled'}`}</div>
          </td>
        }
      </tr>
      <div>
        {modalIsOpen && (
          <DisableLinkModal
            disableLink={disableLink}
            isOpen={modalIsOpen}
            messageId={history.messageId}
            setModalIsOpen={setModalIsOpen}
          />
        )}
      </div>
    </>
  );
}
