import React from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';
import TextArea from 'components/application/TextArea';
import DatePickerField from 'components/application/DatePickerField';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import ErrorMessage from 'components/application/ErrorMessage';

export default function BookingSidePanelForm({
  currentBooking,
  onDateChange,
  onInputChange,
  removeErrorStyling,
  requestError,
}) {
  return (
    <div className='popup__body-form--light'>
      <div className='m-0 m-b-20 tw-flex tw-rounded-lg tw-border-0 tw-bg-cyan-025 tw-p-3 tw-text-cyan-800'>
        <div className='m-r-12 fh-20'>
          <InfoIcon
            className='[&_path]:tw-fill-cyan-800'
            height={20}
            width={20}
          />
        </div>
        <p className='m-0'>
          Email notifications will be sent to the personnel and their line
          manager.
        </p>
      </div>
      <div className={'m-b-24'}>
        <ErrorMessage
          isFallback={requestError.isFallback}
          modifiers='side-panel__alert side-panel__alert--danger side-panel__alert--wide'
          validationErrors={requestError.validationErrors}
          wrapperClassName='form-container'
        />
      </div>
      <div className='form-container'>
        <div className='flex flex--justify-content__flex-start'>
          <div className='m-r-12'>
            <DatePickerField
              earliestDate={new Date()}
              fieldError={requestError.validationErrors.date}
              isRequired={true}
              label='Date'
              name='date'
              onChange={onDateChange}
              removeErrorStyling={removeErrorStyling}
              value={currentBooking.date}
            />
          </div>
        </div>
        <TextArea
          label='Booking notes'
          name='notes'
          onChange={onInputChange}
          tooltip='Booking notes are included in the email'
          value={currentBooking.notes}
        />
      </div>
    </div>
  );
}

BookingSidePanelForm.propTypes = {
  requestError: PropTypes.object,
  currentBooking: resourceShape('booking').isRequired,
  removeErrorStyling: PropTypes.func,
  onDateChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
};
