import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { userParamsFromState } from 'components/helpers/users';
import { titleize } from 'components/helpers/strings';
import SearchImportablePersonnelField from 'components/users/SearchImportablePersonnelField';
import UserDetailsEditor from 'components/users/UserDetailsEditor';
import AccessManagerSection from 'components/users/AccessManagerSection';
import Tooltip from 'components/application/Tooltip';
import ToastRack from 'components/application/ToastRack';
import useToasts from 'components/hooks/useToasts';
import ErrorMessage from 'components/application/ErrorMessage';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import { scrollToTop } from 'components/helpers/scrolling';
import useUserForm, { defaultAccess } from 'components/users/hooks/useUserForm';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import _ from 'lodash';
import classNames from 'classnames';

export const isRemovingUserAccessToLastDivision = (
  divisionId,
  accessControls,
) => {
  const divisionIdsWithNonNullAccessControls = Object.entries(
    accessControls,
  ).reduce((accumulator, [divisionId, accessControls]) => {
    if (accessControls) {
      accumulator.push(divisionId);
    }

    return accumulator;
  }, []);

  return (
    divisionIdsWithNonNullAccessControls.length === 1 &&
    divisionIdsWithNonNullAccessControls == divisionId
  );
};

export const hasNoUserDivisionAccess = (accessControls) =>
  !Object.values(accessControls).some(Boolean);

export default function FormPage({
  context,
  currentUserId,
  divisions,
  domainUser,
  identityProvider,
  isSamlSsoAllowed,
  isTeamsAllowed,
  personnelText,
  projectDisplayNamePlural,
  projectDisplayNameSingular,
}) {
  // state
  const {
    formUser,
    handleRequestError,
    removeErrorStyling,
    requestError,
    resetRequestError,
    setFormUser,
  } = useUserForm({ seededUser: domainUser, seedType: 'domain' });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toasts, , , ,] = useToasts();
  const ref = useRef();

  //computed
  const isNew = !domainUser;
  const isUserEmail = (inputValue) => {
    return domainUser && inputValue === domainUser.data.attributes.email;
  };
  const handlePersonnelSelection = (option) => {
    const selectedAttributes =
      option.__isNew__ || option.isNotDerived ?
        { isDerived: false, email: option.value }
      : {
          isDerived: true,
          firstName: option.value.personnel.attributes.firstName,
          lastName: option.value.personnel.attributes.lastName,
          email: option.value.personnel.attributes.email,
          profilePhotoUrl: option.value.profilePhoto?.links?.croppedUrl,
        };
    resetRequestError();
    setFormUser((prevState) => ({
      ...prevState,
      isSelected: true,
      ...selectedAttributes,
    }));
  };
  const handleSelectionReset = (options = { emailOnly: false }) => {
    const resettingAttributes =
      options.emailOnly ?
        { email: '' }
      : { email: '', firstName: '', lastName: '' };

    setFormUser((prevState) => ({
      ...prevState,
      isDerived: false,
      isSelected: true,
      ...resettingAttributes,
    }));
  };
  const handleSelectionEdit = () =>
    setFormUser((prevState) => ({ ...prevState, isDerived: false }));
  const handleDetailsChange = (changeOptions) => {
    if (requestError.validationErrors[changeOptions.attribute]) {
      removeErrorStyling({ target: { name: changeOptions.attribute } });
    }
    setFormUser((prevState) => ({
      ...prevState,
      [changeOptions.attribute]: changeOptions.value,
    }));
  };
  const handleAccessTypeSelection = (accessSelection) =>
    setFormUser((prevState) => ({ ...prevState, accessType: accessSelection }));

  const handleDivisionSelection = ({ id, selected }) => {
    let isRolesAndCoursesEditor = formUser.rolesAndCoursesEditor;

    if (
      !selected &&
      isRolesAndCoursesEditor &&
      isRemovingUserAccessToLastDivision(id, formUser.accessControls)
    ) {
      isRolesAndCoursesEditor = false;
    }

    setFormUser((prevState) => {
      const accessControlsCopy = _.cloneDeep(prevState.accessControls);
      accessControlsCopy[id] = selected ? defaultAccess : null;

      return {
        ...prevState,
        accessControls: accessControlsCopy,
        rolesAndCoursesEditor: isRolesAndCoursesEditor,
      };
    });
  };

  const handleAccessChange = ({ accessSelection, id }) => {
    const relevantAccessControl = formUser.accessControls[id] || defaultAccess;
    setFormUser((prevState) => ({
      ...prevState,
      accessControls: {
        ...prevState.accessControls,
        [id]: { ...relevantAccessControl, ...accessSelection },
      },
    }));
  };

  const handleAccountRoleChange = (accountRole, isSelected, divisionId) => {
    setFormUser((prevState) => {
      const accessControlsCopy = _.cloneDeep(prevState.accessControls);

      if (isSelected && hasNoUserDivisionAccess(prevState.accessControls)) {
        accessControlsCopy[divisionId] = defaultAccess;
      }

      return {
        ...prevState,
        accessControls: accessControlsCopy,
        [accountRole]: isSelected,
      };
    });
  };

  const handleSubmission = () => {
    setIsSubmitting(true);
    // todo find snakecase utility
    isNew ? submitUserCreation() : submitUserUpdate();
  };

  const handleSubmissionError = (e) => {
    setIsSubmitting(false);
    handleRequestError(e);
    scrollToTop(ref);
  };

  const submitUserCreation = () => {
    axios
      .post('/users/creations', userParamsFromState({ domainUser, formUser }))
      .then((response) => {
        window.location.assign(
          `/settings/users?invited_user_id=${response.data.data.id}&personnel_generated=${!!response.data.meta.personnelGenerated}`,
        );
      })
      .catch(handleSubmissionError);
  };

  const submitUserUpdate = () => {
    axios
      .patch(
        `/users/${domainUser.data.id}`,
        userParamsFromState({ domainUser, formUser }),
      )
      .then((_response) => {
        window.location.assign(
          `/settings/users?updated_user_id=${domainUser.data.id}`,
        );
      })
      .catch(handleSubmissionError);
  };

  const hasAllRequiredAttributes = ['email', 'firstName', 'lastName'].every(
    (attr) => !!formUser[attr],
  );
  const submitContent = isNew ? 'Add user' : 'Save changes';

  return (
    <div ref={ref}>
      <h1 className='m-t-0 m-b-24 tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-900'>
        {context === 'new' ? 'Add user' : 'Edit user'}
      </h1>
      {Object.keys(requestError.validationErrors).length > 0 && (
        <div className='tw-mb-5'>
          <ErrorMessage
            isFallback={requestError.isFallback}
            validationErrors={requestError.validationErrors}
          />
        </div>
      )}
      <div
        className={'p-40 content-box--cornered tw-border-grey-100 tw-bg-white'}
      >
        {isSamlSsoAllowed && identityProvider && context === 'new' && (
          <div className='m-0 m-b-32 tw-flex tw-rounded-lg tw-border-0 tw-bg-cyan-025 tw-p-3 tw-text-cyan-800'>
            <div className='m-r-12 fh-20'>
              <InfoIcon
                className='[&_path]:tw-fill-cyan-800'
                height={20}
                width={20}
              />
            </div>
            <p className='m-0'>
              <span>
                The user must also be added in your identity provider{' '}
              </span>
              <span className='tw-font-semibold'>
                ({titleize(identityProvider.attributes.name)})
              </span>
              <span> for them to access HandsHQ.</span>
            </p>
          </div>
        )}
        <h2 className='m-t-0 m-b-32 tw-text-l tw-font-semibold tw-tracking-auto tw-text-grey-900'>
          User details
        </h2>
        <div className='fw-360 m-b-40'>
          {!formUser.email && (
            <div
              className={classNames(
                'form-group',
                requestError.validationErrors.email &&
                  requestError.validationErrors.email.fieldHighlighted &&
                  'field__invalid',
              )}
            >
              <SearchImportablePersonnelField
                isUserEmail={domainUser && isUserEmail}
                onPersonnelSelection={handlePersonnelSelection}
                removeErrorStyling={removeErrorStyling}
              />
            </div>
          )}
          {formUser.isSelected && (
            <UserDetailsEditor
              formContext={context}
              formUser={formUser}
              onDetailsChange={handleDetailsChange}
              onSelectionEdit={handleSelectionEdit}
              onSelectionReset={handleSelectionReset}
              removeErrorStyling={removeErrorStyling}
              requestError={requestError}
            />
          )}
        </div>
        {formUser.isSelected && (
          <>
            <hr className='tw-m-0 tw-h-px tw-border-0 tw-bg-grey-100' />
            <AccessManagerSection
              divisions={divisions}
              formUser={formUser}
              isEditingSelf={
                currentUserId === (domainUser && domainUser.data.id)
              }
              isTeamsAllowed={isTeamsAllowed}
              onAccessChange={handleAccessChange}
              onAccessTypeSelection={handleAccessTypeSelection}
              onAccountRoleChange={handleAccountRoleChange}
              onDivisionSelection={handleDivisionSelection}
              personnelText={personnelText}
              projectDisplayNamePlural={projectDisplayNamePlural}
              projectDisplayNameSingular={projectDisplayNameSingular}
            />
          </>
        )}
      </div>
      <div className='flex flex--vertically-centered flex--justify-content__flex-end content-box--cornered fh-72 p-t-16 p-r-40 p-b-16 p-l-40 b-t-none tw-border-grey-100 tw-bg-white'>
        <TextButton
          className='m-r-12'
          color='blue'
          href='/settings/users'
          size='md'
        >
          Cancel
        </TextButton>
        {!hasAllRequiredAttributes ?
          <Tooltip
            placement='top'
            tooltip='Missing required fields'
            trigger='hover'
          >
            <FilledButton color='mint' disabled={true} onClick={() => {}}>
              {submitContent}
            </FilledButton>
          </Tooltip>
        : <FilledButton
            color='mint'
            disabled={isSubmitting}
            onClick={handleSubmission}
          >
            {submitContent}
          </FilledButton>
        }
      </div>
      <ToastRack toasts={toasts} />
    </div>
  );
}

FormPage.propTypes = {
  context: PropTypes.string.isRequired,
  domainUser: PropTypes.object,
  divisions: PropTypes.array.isRequired,
  currentUserId: PropTypes.string.isRequired,
  personnelText: PropTypes.string.isRequired,
  isSamlSsoAllowed: PropTypes.bool.isRequired,
  isTeamsAllowed: PropTypes.bool.isRequired,
  identityProvider: PropTypes.object,
};
