import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/application/TextField';
import SelectedPersonnelCard from 'components/users/SelectedPersonnelCard';
import { validEmailRegex } from 'constants/regex';

UserDetailsEditor.propTypes = {
  formUser: PropTypes.object.isRequired,
  formContext: PropTypes.string.isRequired,
  requestError: PropTypes.object.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  onSelectionReset: PropTypes.func.isRequired,
  onSelectionEdit: PropTypes.func.isRequired,
  onDetailsChange: PropTypes.func.isRequired,
};

export default function UserDetailsEditor({
  formContext,
  formUser,
  onDetailsChange,
  onSelectionEdit,
  onSelectionReset,
  removeErrorStyling,
  requestError,
}) {
  const emailTextFieldFormContextProps =
    formContext === 'new' ?
      {
        isDisabled: true,
        clearable: true,
        onClear: () => onSelectionReset({ emailOnly: true }),
        onChange: () => void 0,
      }
    : {
        onChange: (e) =>
          onDetailsChange({ attribute: 'email', value: e.target.value }),
      };
  return (
    <>
      {formUser.isDerived && (
        <SelectedPersonnelCard
          email={formUser.email}
          firstName={formUser.firstName}
          lastName={formUser.lastName}
          onClose={() => onSelectionReset()}
          onEdit={onSelectionEdit}
          profilePhotoUrl={formUser.profilePhotoUrl}
        />
      )}
      {formUser.email && !formUser.isDerived && (
        <div className='tooltip-parent'>
          <TextField
            fieldError={requestError.validationErrors.email}
            isRequired={true}
            label='Email address'
            name='email'
            placeholder={''}
            removeErrorStyling={removeErrorStyling}
            value={formUser.email}
            {...emailTextFieldFormContextProps}
          />
          {!validEmailRegex.test(formUser.email) && (
            <div className='m-b-12 tw-text-s tw-tracking-wide tw-text-red-800'>
              This is not a valid email address
            </div>
          )}
        </div>
      )}
      {!formUser.isDerived && (
        <>
          <TextField
            fieldError={requestError.validationErrors.firstName}
            isRequired={true}
            label='First name'
            name='firstName'
            onChange={(e) =>
              onDetailsChange({ attribute: 'firstName', value: e.target.value })
            }
            removeErrorStyling={removeErrorStyling}
            value={formUser.firstName}
          />
          <TextField
            fieldError={requestError.validationErrors.lastName}
            isRequired={true}
            label='Last name'
            name='lastName'
            onChange={(e) =>
              onDetailsChange({ attribute: 'lastName', value: e.target.value })
            }
            removeErrorStyling={removeErrorStyling}
            value={formUser.lastName}
          />
        </>
      )}
      <TextField
        label='Position'
        name='user[position]'
        onChange={(e) =>
          onDetailsChange({ attribute: 'position', value: e.target.value })
        }
        placeholder={'e.g. Project owner'}
        value={formUser.position || ''}
      />
      <div className='m-t--6 tw-text-s tw-font-normal tw-tracking-wide'>
        The position is displayed at the top of any documents the user has
        created. It can be different from their role on the RAMS.
      </div>
    </>
  );
}
