import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { titleize } from 'components/helpers/strings';
import { userFormParams } from 'components/helpers/users';
import SidePanel from 'components/application/SidePanel';
import ErrorMessage from 'components/application/ErrorMessage';
import UserDetailsEditor from 'components/users/UserDetailsEditor';
import SearchImportablePersonnelField from 'components/users/SearchImportablePersonnelField';
import FormFooter from 'components/sidepanels/FormFooter';
import CheckboxField from 'components/application/CheckboxField';
import useUserForm from 'components/users/hooks/useUserForm';
import useRequestError from 'components/hooks/useRequestError';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import UserPermissionsAndDivisionAccessInfoCard from 'components/users/cards/UserPermissionsAndDivisionAccessInfoCard';
import TeamsAccessExplanationNotification from 'components/users/TeamsAccessExplanationNotification';
import classNames from 'classnames';

export default function UserSidePanel({
  assignablePermissions,
  context,
  currentUser,
  defaultUser,
  divisionId,
  divisionName,
  isCourseApplicableResource,
  isOpen,
  isPermissionsEditable,
  isTeamsAllowed,
  onClose,
  onCreate,
  onUpdate,
  projectDisplayNameSingular,
  selectedUserId,
}) {
  const currentActorContext = useCurrentActor();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [
    requestError,
    submitDisabled,
    removeErrorStyling,
    resetRequestError,
    handleRequestError,
  ] = useRequestError();
  const { formUser, resetUserForm, setFormUser } = useUserForm({
    seededUser: defaultUser,
    seedType: 'form',
  });

  useEffect(() => {
    setFormUser(defaultUser);
  }, [isOpen]);

  const isUserEmail = (inputValue) => {
    return defaultUser && inputValue === defaultUser.email;
  };
  const handlePersonnelSelection = (option) => {
    const selectedAttributes =
      option.__isNew__ || option.isNotDerived ?
        { isDerived: false, email: option.value }
      : {
          isDerived: true,
          firstName: option.value.personnel.attributes.firstName,
          lastName: option.value.personnel.attributes.lastName,
          email: option.value.personnel.attributes.email,
          profilePhotoUrl: option.value.profilePhoto?.links?.croppedUrl,
        };
    resetRequestError();
    setFormUser({ ...formUser, isSelected: true, ...selectedAttributes });
  };
  const handleSelectionReset = (options = { emailOnly: false }) => {
    const resettingAttributes =
      options.emailOnly ?
        { email: '' }
      : { email: '', firstName: '', lastName: '' };

    setFormUser({
      ...formUser,
      isDerived: false,
      isSelected: true,
      ...resettingAttributes,
    });
  };
  const handleSelectionEdit = () =>
    setFormUser({ ...formUser, isDerived: false });
  const handleDetailsChange = (changeOptions) =>
    setFormUser({
      ...formUser,
      [changeOptions.attribute]: changeOptions.value,
    });
  const isPrimaryUserViewingSelf =
    currentUser.attributes.accessType === 'primary' &&
    selectedUserId === currentUser.id;
  const isViewingAccountAdmin =
    defaultUser && defaultUser.accessType === 'administrator';
  const isSuperUser =
    currentUser.attributes.accessType === 'primary' ||
    currentUser.attributes.accessType === 'administrator';

  const handleSubmit = () => {
    setButtonDisabled(true);
    const promise = context === 'new' ? createPromise() : updatePromise();
    promise
      .then(() => {
        resetUserForm();
        setButtonDisabled(false);
      })
      .catch((e) => {
        handleRequestError(e);
        setButtonDisabled(false);
      });
  };

  const createPromise = () =>
    axios
      .post(
        '/users/creations',
        userFormParams(
          formUser,
          divisionId,
          isPermissionsEditable,
          currentUser,
        ),
      )
      .then((response) => onCreate(response.data));

  const updatePromise = () =>
    axios
      .patch(
        `/users/${selectedUserId}`,
        userFormParams(
          formUser,
          divisionId,
          isPermissionsEditable,
          currentUser,
        ),
      )
      .then((response) => onUpdate(response.data));

  const handleClose = () => {
    resetRequestError();
    onClose();
  };

  const noPermissonsReason = () => {
    if (isPrimaryUserViewingSelf) {
      return (
        <>
          <div className='m-b-4 tw-text-grey-900'>
            You are the account owner
          </div>
          <div className='tw-text-s tw-font-normal tw-tracking-wide'>
            To change the account owner, please contact the customer success
            team.
          </div>
        </>
      );
    } else if (isViewingAccountAdmin) {
      return (
        <>
          <div className='m-b-4 tw-text-grey-900'>
            This user is an account administrator
          </div>
          <div className='tw-text-s tw-font-normal tw-tracking-wide'>
            To downgrade their permissions, please contact the customer success
            team.
          </div>
        </>
      );
    }
  };

  function checkboxCheckedStatus(accessAttribute, formUserStatus) {
    if (!formUser) return false;

    switch (accessAttribute) {
      case 'administrator':
        return formUser && formUserStatus['administrator'];
      case 'rolesAndCoursesEditor':
        return formUserStatus['rolesAndCoursesEditor'];
      case 'personnelViewer':
        return (
          formUserStatus['personnelViewer'] ||
          formUserStatus['personnelEditor'] ||
          formUserStatus['administrator']
        );
      default:
        return (
          formUserStatus[accessAttribute] || formUserStatus['administrator']
        );
    }
  }

  function checkboxDisabledStatus(accessAttribute, formUserStatus) {
    if (!formUser) return false;

    switch (accessAttribute) {
      case 'administrator':
        return selectedUserId === currentUser.id;
      case 'personnelViewer':
        return (
          formUserStatus['personnelEditor'] || formUserStatus['administrator']
        );
      case 'rolesAndCoursesEditor':
        return !isSuperUser;
      default:
        return formUserStatus['administrator'];
    }
  }

  const tooltipProps = (accessAttribute) => {
    const tooltipProps = { tooltipType: 'hover', tooltipDelay: 500 };

    switch (true) {
      case 'rolesAndCoursesEditor' === accessAttribute && isSuperUser:
        tooltipProps.tooltip = `Can add, delete or modify roles${isCourseApplicableResource ? ' and courses' : ''}`;
        break;
      case 'rolesAndCoursesEditor' === accessAttribute && !isSuperUser:
        tooltipProps.tooltip = `This permission may only be set by account administrators as it lets users delete or modify roles${isCourseApplicableResource ? ' and courses' : ''} across all divisions`;
        break;
      case 'administrator' === accessAttribute &&
        selectedUserId === currentUser.id:
        tooltipProps.tooltip =
          'You cannot downgrade your own access and permissions';
        break;
      case 'administrator' === accessAttribute:
        tooltipProps.tooltip =
          'Has full access, can add, delete or modify users and assign them permissions';
        break;
      case formUser && formUser['administrator']:
        tooltipProps.tooltip =
          'Administrators have all division based permissions';
        break;
      case 'projectEditor' === accessAttribute:
        tooltipProps.tooltip =
          'All users with access to a division can edit projects';
        break;
      case 'projectApprover' === accessAttribute:
        tooltipProps.tooltip =
          'Can approve, reject or conditionally approve projects';
        break;
      case 'contentEditor' === accessAttribute:
        tooltipProps.tooltip = 'Can make changes to the content library';
        break;
      case 'personnelEditor' === accessAttribute && isCourseApplicableResource:
        tooltipProps.tooltip = 'Can manage personnel and their training';
        break;
      case 'personnelEditor' === accessAttribute:
        tooltipProps.tooltip = 'Can edit personnel details';
        break;
      case 'personnelViewer' === accessAttribute &&
        formUser &&
        formUser['personnelEditor']:
        tooltipProps.tooltip =
          'All users with permissions to edit personnel can view them';
        break;
      case 'personnelViewer' === accessAttribute:
        tooltipProps.tooltip = 'Can view personnel and their details';
        break;
    }
    return tooltipProps;
  };

  const header =
    context === 'new' ?
      <div className='popup__title popup__title--tertiary-split'>
        <h1 className='m-b-8 tw-text-s tw-font-medium tw-tracking-tight'>
          Add user to
        </h1>
        <h2 className='truncated-text-container--wrapped truncated-text-container--five-lines tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-900'>
          {divisionName}
        </h2>
      </div>
    : <div className='popup__title popup__title--tertiary'>
        <h2 className='truncated-text-container tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-900'>
          Edit user
        </h2>
      </div>;

  const body = (
    <div>
      <div className='popup__body-form p-t-0'>
        <div className='m-t-32 m-b-24'>
          {currentActorContext.isSamlSsoAllowed &&
            currentActorContext.identityProvider &&
            context === 'new' && (
              <div className='m-0 m-b-24 tw-flex tw-rounded-lg tw-border-0 tw-bg-cyan-025 tw-p-3 tw-text-cyan-800'>
                <div className='m-r-12 fh-20'>
                  <InfoIcon
                    className='[&_path]:tw-fill-cyan-800'
                    height={20}
                    width={20}
                  />
                </div>
                <p className='m-0'>
                  <span>
                    The user must also be added in your identity provider{' '}
                  </span>
                  <span className='tw-font-semibold'>
                    (
                    {titleize(
                      currentActorContext.identityProvider.attributes.name,
                    )}
                    )
                  </span>
                  <span> for them to access HandsHQ.</span>
                </p>
              </div>
            )}
          {Object.keys(requestError.validationErrors).length > 0 && (
            <div className='m-b-24'>
              <ErrorMessage
                isFallback={requestError.isFallback}
                modifiers='side-panel__alert side-panel__alert--danger side-panel__alert--wide'
                validationErrors={requestError.validationErrors}
              />
            </div>
          )}
          <h3 className='tw-text-l tw-font-semibold tw-tracking-auto'>
            User details
          </h3>
        </div>
        {(!formUser || (formUser && !formUser.email)) && (
          <div
            className={classNames(
              'form-group',
              requestError.validationErrors.email &&
                requestError.validationErrors.email.fieldHighlighted &&
                'collection-select--invalid',
            )}
          >
            <SearchImportablePersonnelField
              isUserEmail={defaultUser && isUserEmail}
              onPersonnelSelection={handlePersonnelSelection}
              removeErrorStyling={removeErrorStyling}
            />
          </div>
        )}
        {formUser && formUser.isSelected && (
          <UserDetailsEditor
            formContext={context}
            formUser={formUser}
            onDetailsChange={handleDetailsChange}
            onSelectionEdit={handleSelectionEdit}
            onSelectionReset={handleSelectionReset}
            removeErrorStyling={removeErrorStyling}
            requestError={requestError}
          />
        )}
        <hr className='m-t-32 m-b-32' />
        <h3 className='m-t-0 m-b-24 tw-text-l tw-font-semibold tw-tracking-auto'>
          Permissions
        </h3>
        <div className='m-b-24'>
          <UserPermissionsAndDivisionAccessInfoCard />
        </div>
        {isTeamsAllowed && <TeamsAccessExplanationNotification />}
        {isPrimaryUserViewingSelf || isViewingAccountAdmin ?
          noPermissonsReason()
        : <ul className='checkbox-collection list-unstyled'>
            <li className='checkbox-collection__item'>
              <CheckboxField
                checked={checkboxCheckedStatus('administrator', formUser)}
                disabled={checkboxDisabledStatus('administrator', formUser)}
                label={'Administrator'}
                labelTextProps={{ className: 'tw-font-medium' }}
                name={'user_administrator'}
                onChange={(e) =>
                  setFormUser({ ...formUser, administrator: e.target.checked })
                }
                {...tooltipProps('administrator')}
              />
            </li>
            <li className='checkbox-collection__item'>
              <CheckboxField
                checked={checkboxCheckedStatus(
                  'rolesAndCoursesEditor',
                  formUser,
                )}
                disabled={checkboxDisabledStatus(
                  'rolesAndCoursesEditor',
                  formUser,
                )}
                label={`Roles${isCourseApplicableResource ? ' & courses' : ''} editor`}
                labelProps={{
                  className:
                    isSuperUser ? '' : (
                      'hhq-checkbox__container--disabled-inactive-filled'
                    ),
                }}
                labelTextProps={{ className: 'tw-font-medium' }}
                name={'user_roles_and_courses_editor'}
                onChange={(e) =>
                  setFormUser({
                    ...formUser,
                    rolesAndCoursesEditor: e.target.checked,
                  })
                }
                {...tooltipProps('rolesAndCoursesEditor')}
              />
            </li>
            {assignablePermissions.includes('projectEditor') && (
              <li className='checkbox-collection__item'>
                <CheckboxField
                  checked={true}
                  disabled={true}
                  label={`${projectDisplayNameSingular} editor`}
                  labelTextProps={{ className: 'tw-font-medium' }}
                  name={'user_project_editor'}
                  onChange={() => void 0}
                  {...tooltipProps('projectEditor')}
                />
              </li>
            )}
            {assignablePermissions.includes('projectApprover') && (
              <li className='checkbox-collection__item'>
                <CheckboxField
                  checked={checkboxCheckedStatus('projectApprover', formUser)}
                  disabled={checkboxDisabledStatus('projectApprover', formUser)}
                  label={`${projectDisplayNameSingular} approver`}
                  labelTextProps={{ className: 'tw-font-medium' }}
                  name={'user_project_approver'}
                  onChange={(e) =>
                    setFormUser({
                      ...formUser,
                      projectApprover: e.target.checked,
                    })
                  }
                  {...tooltipProps('projectApprover')}
                />
              </li>
            )}
            {assignablePermissions.includes('contentEditor') && (
              <li className='checkbox-collection__item'>
                <CheckboxField
                  checked={checkboxCheckedStatus('contentEditor', formUser)}
                  disabled={checkboxDisabledStatus('contentEditor', formUser)}
                  label={'Content editor'}
                  labelTextProps={{ className: 'tw-font-medium' }}
                  name={'user_content_editor'}
                  onChange={(e) =>
                    setFormUser({
                      ...formUser,
                      contentEditor: e.target.checked,
                    })
                  }
                  {...tooltipProps('contentEditor')}
                />
              </li>
            )}
            <li className='checkbox-collection__item'>
              <CheckboxField
                checked={checkboxCheckedStatus('personnelEditor', formUser)}
                disabled={checkboxDisabledStatus('personnelEditor', formUser)}
                label={'Personnel editor'}
                labelTextProps={{ className: 'tw-font-medium' }}
                name={'user_personnel_editor'}
                onChange={(e) =>
                  setFormUser({
                    ...formUser,
                    personnelEditor: e.target.checked,
                  })
                }
                {...tooltipProps('personnelEditor')}
              />
            </li>
            <li className='checkbox-collection__item'>
              <CheckboxField
                checked={checkboxCheckedStatus('personnelViewer', formUser)}
                disabled={checkboxDisabledStatus('personnelViewer', formUser)}
                label={'Personnel viewer'}
                labelTextProps={{ className: 'tw-font-medium' }}
                name={'user_personnel_viewer'}
                onChange={(e) =>
                  setFormUser({
                    ...formUser,
                    personnelViewer: e.target.checked,
                  })
                }
                {...tooltipProps('personnelViewer')}
              />
            </li>
          </ul>
        }
      </div>
    </div>
  );

  const footer = (
    <FormFooter
      onCancel={handleClose}
      onSubmit={handleSubmit}
      submitButtonDisabled={submitDisabled || buttonDisabled}
      text={context === 'new' ? 'Add user' : 'Save changes'}
    />
  );

  return (
    <SidePanel
      bodyContent={body}
      closeCallback={handleClose}
      color='tertiary'
      footerContent={footer}
      headerContent={header}
      isOpen={isOpen}
    />
  );
}

UserSidePanel.propTypes = {
  context: PropTypes.string.isRequired,
  defaultUser: PropTypes.object,
  isPermissionsEditable: PropTypes.bool,
  selectedUserId: PropTypes.string,
  isOpen: PropTypes.bool,
  divisionName: PropTypes.string.isRequired,
  divisionId: PropTypes.string.isRequired,
  assignablePermissions: PropTypes.array.isRequired,
  isCourseApplicableResource: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  projectDisplayNameSingular: PropTypes.string.isRequired,
  isTeamsAllowed: PropTypes.bool.isRequired,
};
