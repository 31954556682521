$(function () {
  if ($('.master_activity-review-editing')) {
    const reviewEditing = {
      setup: function () {
        this.eventHandlers();
      },

      eventHandlers: function () {
        const _this = this;

        $('select#assignee_id').change(function () {
          _this.setValue.call(this, '#master_activity_assignee_id');
        });

        $('#review_date').change(function () {
          _this.setValue.call(this, '#master_activity_review_date');
        });

        $('#default-review-date').click(function () {
          $('#review_date').datepicker('update', $('#default_date').val());
        });

        $('#snapshot_comment_input').change(function () {
          _this.setValue.call(this, '#snapshot_comment');
        });
      },

      setValue: function (selector) {
        $(selector).val($(this).val());
      },
    };

    reviewEditing.setup();
  }
});
