import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { currencyCodes } from 'components/helpers/currency';

import useRequestError from 'components/hooks/useRequestError';
import useToasts from 'components/hooks/useToasts';
import useForm from 'components/hooks/useForm';

import ResourceChangedToast from 'components/application/ResourceChangedToast';
import ErrorMessage from 'components/application/ErrorMessage';
import ToastRack from 'components/application/ToastRack';
import TextField from 'components/application/TextField';
import Uploader from 'components/uploader/Uploader';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import FilledButton from 'components/application/buttons/FilledButton';
import CollectionSelect from 'components/application/CollectionSelect';

import TrashIcon from '-!svg-react-loader?name=TrashIcon!icons/ic-trash.svg';

const defaultFormData = {
  name: '',
  documentName: '',
  address1: '',
  address2: '',
  address3: '',
  town: '',
  county: '',
  postcode: '',
  currencyCode: '',
  phone: '',
  fax: '',
  website: '',
};

const defaultLogoData = {
  src: '',
  data: '',
};

function formatCurrencyOption(currencyCode) {
  if (!currencyCode) return null;

  return { value: currencyCode, label: currencyCodes[currencyCode].label };
}

const assignableCurrencyOptions = Object.keys(currencyCodes).map(
  (currencyCode) => formatCurrencyOption(currencyCode),
);

export default function BusinessDetailsForm({ company }) {
  const currentActor = useCurrentActor();
  const [
    requestError,
    submitDisabled,
    removeErrorStyling,
    resetRequestError,
    handleRequestError,
  ] = useRequestError();
  const [
    formDetails,
    setFormDetails,
    handleDetailsInputChange,
    handleDetailsOptionChange,
  ] = useForm(defaultFormData);
  const [toasts, , addToast, handleBurnToast] = useToasts();
  const [logo, setLogo] = useState(defaultLogoData);
  const [documentNameIsToggled, setDocumentNameIsToggled] = useState(false);
  const bodyRef = React.useRef();
  const hasLogoBeenModified = !!logo.src === !!logo.data;

  const parseDetails = (details) => {
    return {
      name: details.name || '',
      documentName: details.documentName || '',
      address1: details.address1 || '',
      address2: details.address2 || '',
      address3: details.address3 || '',
      town: details.town || '',
      county: details.county || '',
      postcode: details.postcode || '',
      currencyCode: details.currencyCode || '',
      phone: details.phone || '',
      fax: details.fax || '',
      website: details.website || '',
    };
  };

  useEffect(() => {
    setFormDetails(parseDetails(company.data.attributes));
    setLogo({ ...logo, src: company.data.attributes.companyLogo.url });
  }, []);

  useEffect(() => {
    if (submitDisabled) {
      bodyRef.current.scrollIntoView();
    }
  }, [submitDisabled]);

  const handleLogoUpload = (fileData) => {
    setLogo({ src: URL.createObjectURL(fileData), data: fileData });
  };

  const handleLogoDeletion = () => {
    setLogo({ src: '', data: '' });
  };

  const detailsAsFormData = () => {
    const formData = new FormData();

    appendDetails();
    if (hasLogoBeenModified) appendLogo();

    return formData;

    function appendDetails() {
      Object.entries(formDetails).forEach((entry) => {
        const [key, value] = entry;

        formData.append(`company[${companyKey(key)}]`, value);
      });
    }

    function appendLogo() {
      formData.append('company[company_logo]', logo.data);
    }

    // simply calling voca.snakeCase wouldn't work here (address1, address2, address3)
    function companyKey(key) {
      switch (key) {
        case 'documentName':
          return 'document_name';
        case 'currencyCode':
          return 'currency_code';
        default:
          return key;
      }
    }
  };

  const handleSubmit = () => {
    axios
      .patch(`/settings/business`, detailsAsFormData(), {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        resetRequestError();
        setFormDetails(parseDetails(response.data.data.attributes));
        setLogo({
          src: response.data.data.attributes.companyLogo.url,
          data: '',
        });
      })
      .then(() =>
        addToast(
          <ResourceChangedToast
            onBurnToast={handleBurnToast}
            plural={true}
            resource={'Business details'}
            status={'updated'}
          />,
        ),
      )
      .catch(handleRequestError);
  };

  return (
    <>
      <div
        className='form__outer-container tw-border-grey-100 tw-bg-white'
        ref={bodyRef}
      >
        <div className='form__inner-container'>
          <div className='form__outer-section'>
            <div className='form__inner-section'>
              <ErrorMessage
                isFallback={requestError.isFallback}
                validationErrors={requestError.validationErrors}
                wrapperClassName='form__error-container'
              />
            </div>

            <div className='form__inner-section'>
              <div className='m-t-0 m-b-24 tw-text-l tw-font-semibold tw-tracking-auto'>
                Business name and logo
              </div>
              <TextField
                fieldError={requestError.validationErrors.name}
                isRequired={true}
                label='Business'
                name='name'
                onChange={handleDetailsInputChange}
                removeErrorStyling={removeErrorStyling}
                value={formDetails.name}
              />
              {formDetails.documentName || documentNameIsToggled ?
                <TextField
                  label='Name on documents'
                  name='documentName'
                  onChange={handleDetailsInputChange}
                  value={formDetails.documentName}
                />
              : <div className='form-group'>
                  <div
                    className='app-link tw-font-medium tw-text-blue-500 hover:tw-text-blue-300'
                    onClick={() => setDocumentNameIsToggled(true)}
                  >
                    Add name to appear on documents
                  </div>
                </div>
              }
              <div className='field__label tw-font-medium'>Logo</div>
              <div className={'form__uploader'} id={'business-details__logo'}>
                {logo.src ?
                  <React.Fragment>
                    <div className='form__image-container tw-border-grey-100'>
                      <img
                        alt='Company logo'
                        className='image--bound-scaling'
                        src={logo.src}
                      />
                    </div>
                    <div className='form__image-action-buttons'>
                      <OutlinedButton
                        color='red'
                        onClick={handleLogoDeletion}
                        size='sm'
                      >
                        <TrashIcon className='m-r-4' height={20} width={20} />
                        <span>Delete logo</span>
                      </OutlinedButton>
                    </div>
                  </React.Fragment>
                : <Uploader
                    externalInputProps={{ name: 'company[logo]' }}
                    fieldInvalid={false}
                    fileTypes={['png', 'jpg']}
                    mode='solo'
                    onCancel={() => {}}
                    onError={() => {}}
                    onUpload={handleLogoUpload}
                    uploadsCount={0}
                  />
                }
              </div>
            </div>

            <hr className='tw-my-10 tw-h-px tw-border-0 tw-bg-grey-100' />

            <div className='form__inner-section'>
              <div className='m-t-0 m-b-24 tw-text-l tw-font-semibold tw-tracking-auto tw-text-grey-900'>
                Business address
              </div>
              <TextField
                label='Address line 1'
                name='address1'
                onChange={handleDetailsInputChange}
                value={formDetails.address1}
              />
              <TextField
                label='Address line 2'
                name='address2'
                onChange={handleDetailsInputChange}
                value={formDetails.address2}
              />
              <TextField
                label='Address line 3'
                name='address3'
                onChange={handleDetailsInputChange}
                value={formDetails.address3}
              />
              <TextField
                label='Town'
                name='town'
                onChange={handleDetailsInputChange}
                value={formDetails.town}
              />
              <TextField
                label='County'
                name='county'
                onChange={handleDetailsInputChange}
                value={formDetails.county}
              />
              <TextField
                label='Postcode'
                name='postcode'
                onChange={handleDetailsInputChange}
                value={formDetails.postcode}
              />
            </div>

            {currentActor.isAllowedFeature('training_register') &&
              currentActor.division.attributes.primary && (
                <>
                  <hr className='tw-my-10 tw-h-px tw-border-0 tw-bg-grey-100' />

                  <div className='form__inner-section'>
                    <div className='m-t-0 m-b-24 tw-text-l tw-font-semibold tw-tracking-auto tw-text-grey-900'>
                      Localisation
                    </div>
                    <CollectionSelect
                      label='Currency'
                      name='currencyCode'
                      onChange={handleDetailsOptionChange}
                      options={assignableCurrencyOptions}
                      value={formatCurrencyOption(formDetails.currencyCode)}
                    />
                    <p className='tw-mb-0 tw-mt-4 tw-text-s tw-tracking-wide'>
                      Please note the currency selected here only applies to the
                      app and{' '}
                      <span className='tw-font-semibold'>doesn't change</span>{' '}
                      the billing currency.
                    </p>
                  </div>
                </>
              )}

            <hr className='tw-my-10 tw-h-px tw-border-0 tw-bg-grey-100' />

            <div className='form__inner-section'>
              <div className='m-t-0 m-b-24 tw-text-l tw-font-semibold tw-tracking-auto tw-text-grey-900'>
                Business contact details
              </div>
              <TextField
                label='Business phone'
                name='phone'
                onChange={handleDetailsInputChange}
                value={formDetails.phone}
              />
              <TextField
                label='Fax'
                name='fax'
                onChange={handleDetailsInputChange}
                value={formDetails.fax}
              />
              <TextField
                label='Website'
                name='website'
                onChange={handleDetailsInputChange}
                value={formDetails.website}
              />
            </div>
          </div>
        </div>
        <div className='form__outer-section'>
          <div className='form__footer tw-border-grey-100'>
            <FilledButton
              color='blue'
              disabled={submitDisabled}
              onClick={handleSubmit}
            >
              Save changes
            </FilledButton>
          </div>
        </div>
      </div>
      <ToastRack toasts={toasts} />
    </>
  );
}
