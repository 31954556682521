import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VisibleIcon from '-!svg-react-loader?name=VisibleIcon!icons/ic-visible-16.svg';
import HiddenIcon from '-!svg-react-loader?name=HiddenIcon!icons/ic-restricted-16.svg';
import DefaultIcon from '-!svg-react-loader?name=DefaultIcon!icons/ic-check.svg';
import Select, { components } from 'react-select';
import { DropdownIndicator } from 'components/application/CollectionSelect';

const OptionValue = (props) => {
  const icon = {
    selected: <DefaultIcon height={16} width={16} />,
    visible: <VisibleIcon height={16} width={16} />,
    hidden: <HiddenIcon height={16} width={16} />,
  }[props.data.value];

  return (
    <div className='tw-flex tw-items-center'>
      {icon}
      <span className='tw-ml-2'>{props.children}</span>
    </div>
  );
};

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <OptionValue {...props} />
    </components.SingleValue>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className='collection-select__option_container'>
        <OptionValue {...props} />
      </div>
    </components.Option>
  );
};

const IconSelection = ({ icon, iconAllocation, index }) => {
  const initialState =
    iconAllocation ? iconAllocation.attributes.selectionState : 'visible';
  const [selectedState, setSelectedState] = useState(initialState);

  const options = [
    { label: 'Visible', value: 'visible' },
    { label: 'Selected', value: 'selected' },
    { label: 'Hidden', value: 'hidden' },
  ];

  const selectedOption = options.find(
    (option) => option.value === selectedState,
  );

  const handleChange = (selectedOption) => {
    setSelectedState(selectedOption.value);
  };

  const isPersisted = iconAllocation !== undefined;
  const isCreating = selectedState !== 'visible' && !isPersisted;
  const isUpdating = selectedState !== 'visible' && isPersisted;
  const isDeleting = selectedState === 'visible' && isPersisted;
  const hasIconChanged = selectedState !== initialState;

  const renderHiddenInputs = (() => {
    if (isCreating) {
      return (
        <>
          <input
            name={`project_configuration[icon_allocations_attributes][${index}][icon_id]`}
            type='hidden'
            value={icon.attributes.id}
          />
          <input
            name={`project_configuration[icon_allocations_attributes][${index}][selection_state]`}
            type='hidden'
            value={selectedState}
          />
        </>
      );
    } else if (isUpdating) {
      return (
        <>
          <input
            name={`project_configuration[icon_allocations_attributes][${index}][id]`}
            type='hidden'
            value={iconAllocation.id}
          />
          <input
            name={`project_configuration[icon_allocations_attributes][${index}][selection_state]`}
            type='hidden'
            value={selectedState}
          />
        </>
      );
    } else if (isDeleting) {
      return (
        <>
          <input
            name={`project_configuration[icon_allocations_attributes][${index}][id]`}
            type='hidden'
            value={iconAllocation.id}
          />
          <input
            name={`project_configuration[icon_allocations_attributes][${index}][_destroy]`}
            type='hidden'
            value='1'
          />
        </>
      );
    }
    return null;
  })();

  return (
    <div className='tw-flex tw-flex-col tw-items-center tw-rounded-lg tw-border-1 tw-border-solid tw-border-grey-100 tw-p-2'>
      <div className='tw-mt-2'>
        <img
          alt={icon.attributes.name}
          height={75}
          src={icon.attributes.imageAssetPath}
          width={75}
        />
      </div>
      <div className='tw-h-full tw-pb-2.5 tw-pt-2.5 tw-text-center tw-align-top'>
        {icon.attributes.name}
      </div>
      <div className='tw-mb-2 tw-h-10 tw-w-full'>
        <Select
          className='collection-select__select-container'
          classNamePrefix='collection-select'
          components={{ SingleValue, Option, DropdownIndicator }}
          onChange={handleChange}
          options={options}
          value={selectedOption}
        />
      </div>
      {/* Render hidden inputs for create, update, or destroy only if there's been a change */}
      {hasIconChanged && renderHiddenInputs}
    </div>
  );
};

IconSelection.propTypes = {
  icon: PropTypes.object.isRequired,
  iconAllocation: PropTypes.object, // This might be null/undefined if no allocation exists
  index: PropTypes.number.isRequired,
};

export default IconSelection;
