import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useForm from 'components/hooks/useForm';
import useSidePanel from 'components/hooks/useSidePanel';
import useDebounce from 'components/hooks/useDebounce';
import useRequestError from 'components/hooks/useRequestError';

import Table from 'components/application/Table';
import MasterCoshhRow from 'components/masterCoshh/MasterCoshhRow';
import MasterCoshhSearchBlankNotice from 'components/masterCoshh/MasterCoshhSearchBlankNotice';
import Paginator from 'components/application/Paginator';
import ProjectCoshhDestroyModal from 'components/projectCoshh/ProjectCoshhDestroyModal';
import ProjectCoshhSidePanel from 'components/projectCoshh/ProjectCoshhSidePanel';
import {
  defaultCoshhDocument,
  updatedPersonsAtRiskValues,
  formatPersonsAtRiskValues,
} from 'components/helpers/resources/coshhDocuments';
import SearchField from 'components/application/SearchField';
import RowBar from 'components/application/RowBar';

const initialMasterCoshhDocumentsState = {
  loaded: false,
  data: [],
  metaData: { currentPage: null, totalPages: null },
};
const initialCoshhDocumentsState = {
  loaded: false,
  data: [],
  included: [],
  allMasterCoshhDocumentIds: {},
};

export default function MasterCoshhTable(props) {
  const {
    addInedibleToast,
    exposureFrequencyOptions,
    locationOptions,
    methodOfUseOptions,
    onCoshDocumentChange,
    personsAtRiskOptions,
    projectId,
    secondaryFieldDefaultValue,
    secondaryFieldLabel,
    workerExposureTimeOptions,
  } = props;

  const [masterCoshhDocuments, setMasterCoshhDocuments] = useState(
    initialMasterCoshhDocumentsState,
  );
  const [coshhDocuments, setCoshhDocuments] = useState(
    initialCoshhDocumentsState,
  );
  const [
    requestError,
    submitDisabled,
    removeErrorStyling,
    resetRequestError,
    handleRequestError,
  ] = useRequestError();
  const [
    currentCoshhDocument,
    setCurrentCoshhDocument,
    handleCoshhInputChange,
    handleCoshhOptionChange,
    ,
    ,
    handleOptionSelect,
  ] = useForm(defaultCoshhDocument);
  const [initialPersonsAtRisk, setInitialPersonsAtRisk] = useState([]);

  const [isSidePanelSubmitDisabled, setIsSidePanelSubmitDisabled] =
    useState(false);
  const [
    sidePanelIsOpen,
    setSidePanelIsOpen,
    _openSidePanel,
    closeSidePanel,
    _resetSidePanelContext,
    sidePanelContext,
    setSidePanelContext,
  ] = useSidePanel(false, 'new');
  const [pageNumber, setPageNumber] = useState(1);
  const [coshhDestroyModalIsOpen, setCoshhDestroyModalIsOpen] = useState(false);

  const [currentSearch, setCurrentSearch, handleSearchInputChange] = useForm({
    masterCoshhSearch: '',
  });
  const [debouncedCurrentSearch, _resetDebouncedCurrentSearch] = useDebounce(
    currentSearch,
    250,
  );

  const handleSearchReset = (event) => {
    const name = event.target.getAttribute('data-attr-name');
    setCurrentSearch({ ...currentSearch, [name]: '' });
  };

  const fetchMasterCoshhDocuments = (page = 1) => {
    axios
      .get(`/projects/${projectId}/master_coshh`, {
        params: {
          search:
            currentSearch.masterCoshhSearch ?
              currentSearch.masterCoshhSearch
            : null,
          page: page,
        },
      })
      .then((response) => {
        setIsSidePanelSubmitDisabled(false);
        setMasterCoshhDocuments({
          loaded: true,
          data: response.data.data,
          metaData: response.data.meta,
        });
        setPageNumber(response.data.meta.currentPage);
        setCurrentCoshhDocument(defaultCoshhDocument);
      })
      .catch(() => addInedibleToast());
  };

  const fetchCoshhDocuments = () => {
    axios
      .get(`/projects/${projectId}/coshh`)
      .then((response) => {
        setSidePanelIsOpen(false);
        const allMasterCoshhDocumentIds = {};
        response.data.data.map(
          (obj) =>
            (allMasterCoshhDocumentIds[
              obj.relationships.masterCoshhDocument.data.id
            ] = obj.id),
        );

        setCoshhDocuments({
          loaded: true,
          data: response.data.data,
          included: response.data.included,
          allMasterCoshhDocumentIds: allMasterCoshhDocumentIds,
        });
      })
      .catch(() => addInedibleToast());
  };

  const refreshResources = (pageNumber = 1) => {
    fetchCoshhDocuments();
    fetchMasterCoshhDocuments(pageNumber);
  };

  useEffect(() => {
    if (masterCoshhDocuments.loaded && debouncedCurrentSearch) {
      fetchMasterCoshhDocuments();
    }
  }, [debouncedCurrentSearch]);

  useEffect(() => {
    refreshResources();
  }, []);

  const handlePageChange = (event) => {
    const page = event.currentTarget.getAttribute('data-page');
    fetchMasterCoshhDocuments(page);
  };

  const defaultCurrentCoshhDocument = (masterCoshhDocument) => {
    return {
      id:
        coshhDocuments.loaded &&
        coshhDocuments.allMasterCoshhDocumentIds[masterCoshhDocument.id],
      title: masterCoshhDocument.attributes.title,
      masterId: masterCoshhDocument.id,
      coshhVersionId:
        masterCoshhDocument.relationships.latestCoshhVersion.data.id,
      longTermExposureLimit:
        masterCoshhDocument.attributes.longTermExposureLimit,
      shortTermExposureLimit:
        masterCoshhDocument.attributes.shortTermExposureLimit,
      quantityOnSite: '',
      quantityToBeUsed: '',
      substanceQuantityPerDay: '',
      uses: '',
      methodOfUseOther: '',
      staffNumber: '',
      personsAtRisk: [],
      personsAtRiskOther: '',
      workerExposureTime: '',
      workerExposureTimeOther: '',
      exposureFrequency: '',
      exposureFrequencyOther: '',
      location: '',
      levelOfRisk: '',
      additionalInformation: '',
    };
  };

  const coshhDocumentCreateParams = {
    project_id: projectId,
    coshh_version_id: currentCoshhDocument.coshhVersionId,
    quantity_on_site: currentCoshhDocument.quantityOnSite,
    quantity_to_be_used: currentCoshhDocument.quantityToBeUsed,
    substance_quantity_per_day: currentCoshhDocument.substanceQuantityPerDay,
    uses: currentCoshhDocument.uses,
    method_of_use_other:
      currentCoshhDocument.uses === secondaryFieldLabel ?
        currentCoshhDocument.methodOfUseOther
      : '',
    staff_number: currentCoshhDocument.staffNumber,
    worker_exposure_time: currentCoshhDocument.workerExposureTime,
    worker_exposure_time_other:
      currentCoshhDocument.workerExposureTime === secondaryFieldLabel ?
        currentCoshhDocument.workerExposureTimeOther
      : '',
    exposure_frequency: currentCoshhDocument.exposureFrequency,
    exposure_frequency_other:
      currentCoshhDocument.exposureFrequency === secondaryFieldLabel ?
        currentCoshhDocument.exposureFrequencyOther
      : '',
    location: currentCoshhDocument.location,
    level_of_risk: currentCoshhDocument.levelOfRisk,
    additional_information: currentCoshhDocument.additionalInformation,
    assigned_persons_at_risks_attributes: currentCoshhDocument.personsAtRisk,
    persons_at_risk_other:
      (
        Boolean(
          currentCoshhDocument.personsAtRisk.find(
            (persons) => persons.name === secondaryFieldLabel,
          ),
        )
      ) ?
        currentCoshhDocument.personsAtRiskOther
      : '',
  };

  const coshhDocumentUpdateParams = () => {
    const updatedPersonsAtRisk = updatedPersonsAtRiskValues(
      initialPersonsAtRisk,
      currentCoshhDocument.personsAtRisk,
    );

    return {
      quantity_on_site: currentCoshhDocument.quantityOnSite,
      quantity_to_be_used: currentCoshhDocument.quantityToBeUsed,
      substance_quantity_per_day: currentCoshhDocument.substanceQuantityPerDay,
      uses: currentCoshhDocument.uses,
      method_of_use_other:
        currentCoshhDocument.uses === secondaryFieldLabel ?
          currentCoshhDocument.methodOfUseOther
        : '',
      staff_number: currentCoshhDocument.staffNumber,
      worker_exposure_time: currentCoshhDocument.workerExposureTime,
      worker_exposure_time_other:
        currentCoshhDocument.workerExposureTime === secondaryFieldLabel ?
          currentCoshhDocument.workerExposureTimeOther
        : '',
      exposure_frequency: currentCoshhDocument.exposureFrequency,
      exposure_frequency_other:
        currentCoshhDocument.exposureFrequency === secondaryFieldLabel ?
          currentCoshhDocument.exposureFrequencyOther
        : '',
      location: currentCoshhDocument.location,
      level_of_risk: currentCoshhDocument.levelOfRisk,
      additional_information: currentCoshhDocument.additionalInformation,
      assigned_persons_at_risks_attributes: updatedPersonsAtRisk,
      persons_at_risk_other:
        (
          Boolean(
            updatedPersonsAtRisk.find(
              (persons) =>
                persons.name === secondaryFieldLabel &&
                persons['_destroy'] === '1',
            ),
          )
        ) ?
          ''
        : currentCoshhDocument.personsAtRiskOther,
    };
  };

  const assignCoshhDocumentResponseData = (
    response,
    formattedPersonsAtRisk,
  ) => {
    const { attributes, id } = response;

    return {
      id: id,
      reference: attributes.reference || '',
      title: attributes.title || '',
      quantityOnSite: attributes.quantityOnSite || '',
      quantityToBeUsed: attributes.quantityToBeUsed || '',
      substanceQuantityPerDay: attributes.substanceQuantityPerDay || '',
      uses: attributes.uses || '',
      methodOfUseOther: attributes.methodOfUseOther || '',
      staffNumber: attributes.staffNumber || '',
      personsAtRisk: formattedPersonsAtRisk,
      personsAtRiskOther: attributes.personsAtRiskOther || '',
      workerExposureTime: attributes.workerExposureTime || '',
      workerExposureTimeOther: attributes.workerExposureTimeOther || '',
      exposureFrequency: attributes.exposureFrequency || '',
      exposureFrequencyOther: attributes.exposureFrequencyOther || '',
      location: attributes.location || '',
      levelOfRisk: attributes.levelOfRisk || '',
      additionalInformation: attributes.additionalInformation || '',
      longTermExposureLimit: attributes.longTermExposureLimit,
      shortTermExposureLimit: attributes.shortTermExposureLimit,
    };
  };

  const createCoshhDocument = () => {
    setIsSidePanelSubmitDisabled(true);

    axios
      .post(`/projects/${projectId}/coshh`, {
        master_id: currentCoshhDocument.masterId,
        coshh_document: coshhDocumentCreateParams,
      })
      .then(() => {
        onCoshDocumentChange();
        resetRequestError();
        refreshResources(pageNumber);
      })
      .catch(handleRequestError);
  };

  const handleAddCoshhOnClick = (masterCoshhDocument) => {
    setSidePanelContext('new');
    setCurrentCoshhDocument(defaultCurrentCoshhDocument(masterCoshhDocument));
    setSidePanelIsOpen(true);
  };

  const handleEditCoshhOnClick = (masterCoshhDocument) => {
    const coshhDocument = coshhDocuments.data.find(
      (coshhDoc) =>
        coshhDoc.relationships.masterCoshhDocument.data.id ===
        masterCoshhDocument.id,
    );
    const assignedPersonsAtRiskValues = coshhDocuments.included.filter(
      (includedResource) =>
        includedResource.relationships.personable.data.id === coshhDocument.id,
    );
    const formattedPersonsAtRisk = formatPersonsAtRiskValues(
      assignedPersonsAtRiskValues,
      'assignedPersonsAtRisk',
      'name',
    );

    setInitialPersonsAtRisk(formattedPersonsAtRisk);
    setCurrentCoshhDocument(
      assignCoshhDocumentResponseData(coshhDocument, formattedPersonsAtRisk),
    );
    setSidePanelContext('edit');
    setSidePanelIsOpen(true);
  };

  const handleUpdateCoshhDocument = () => {
    setIsSidePanelSubmitDisabled(true);

    axios
      .patch(`/projects/${projectId}/coshh/${currentCoshhDocument.id}`, {
        coshh_document: coshhDocumentUpdateParams(),
        skip_version_generation: true,
      })
      .then(() => {
        onCoshDocumentChange();
        refreshResources(pageNumber);
        setSidePanelIsOpen(false);
        resetRequestError();
      })
      .catch(handleRequestError);
  };

  const handleCloseProjectCoshhSidePanel = () => {
    resetRequestError();
    closeSidePanel();
  };

  const handleDeleteCoshhOnClick = (masterCoshhDocument) => {
    setCurrentCoshhDocument(defaultCurrentCoshhDocument(masterCoshhDocument));
    setCoshhDestroyModalIsOpen(true);
  };

  const deleteCoshhDocument = () => {
    axios
      .delete(`/projects/${projectId}/coshh/${currentCoshhDocument.id}`, {
        data: { skip_version_generation: true },
      })
      .then(() => {
        onCoshDocumentChange();
        refreshResources(pageNumber);
      })
      .catch(() => addInedibleToast());
  };

  useEffect(() => {
    if (requestError) {
      setIsSidePanelSubmitDisabled(false);
    }
  }, [requestError]);

  return (
    <>
      {masterCoshhDocuments.loaded && (
        <>
          <RowBar
            additionalClasses={'m-t-30'}
            content={
              <SearchField
                additionalClasses={'search-field__coshh'}
                autoFocus={true}
                name='masterCoshhSearch'
                onChange={handleSearchInputChange}
                onReset={handleSearchReset}
                placeholder='Search by substance, reference or manufacturer...'
                value={currentSearch.masterCoshhSearch}
              />
            }
            modifiers={['border-top-curved', 'border-bottom-none']}
          />
          <Table
            blankState={
              currentSearch.masterCoshhSearch && (
                <MasterCoshhSearchBlankNotice
                  masterCoshhSearch={currentSearch.masterCoshhSearch}
                />
              )
            }
            headers={
              <tr>
                <th className='w-5 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'></th>
                <th className='w-40 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
                  COSHH assessment
                </th>
                <th className='w-25 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
                  Manufacturer
                </th>
                <th className='w-10 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
                  Reference
                </th>
                <th className='w-20 tw-text-right tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
                  <span className='w-100 flex flex--justify-content__flex-end'>
                    <div className='fw-120 tw-text-center'>Actions</div>
                  </span>
                </th>
              </tr>
            }
            rows={masterCoshhDocuments.data.map(
              (masterCoshhDocument) =>
                masterCoshhDocument.attributes.published && (
                  <MasterCoshhRow
                    addedToProject={
                      coshhDocuments.loaded &&
                      coshhDocuments.allMasterCoshhDocumentIds.hasOwnProperty(
                        masterCoshhDocument.id,
                      )
                    }
                    key={masterCoshhDocument.id}
                    masterCoshhDocument={masterCoshhDocument}
                    onAdd={handleAddCoshhOnClick}
                    onDelete={handleDeleteCoshhOnClick}
                    onEdit={handleEditCoshhOnClick}
                  />
                ),
            )}
          />
          {masterCoshhDocuments.metaData.totalPages > 1 && (
            <div className='m-t-20 m-b-20 ta-center'>
              <Paginator
                currentPage={masterCoshhDocuments.metaData.currentPage}
                onClick={handlePageChange}
                totalPages={masterCoshhDocuments.metaData.totalPages}
              />
            </div>
          )}
          <ProjectCoshhDestroyModal
            currentCoshhDocument={currentCoshhDocument}
            deleteCoshhDocument={deleteCoshhDocument}
            isOpen={currentCoshhDocument.id ? coshhDestroyModalIsOpen : false}
            setIsOpen={setCoshhDestroyModalIsOpen}
          />
          <ProjectCoshhSidePanel
            closeSidePanel={handleCloseProjectCoshhSidePanel}
            coshhDocument={currentCoshhDocument}
            exposureFrequencyOptions={exposureFrequencyOptions}
            locationOptions={locationOptions}
            methodOfUseOptions={methodOfUseOptions}
            onCoshhInputChange={handleCoshhInputChange}
            onCoshhOptionChange={handleCoshhOptionChange}
            onNewCoshhDocument={createCoshhDocument}
            onOptionSelect={handleOptionSelect}
            onUpdateCoshhDocument={handleUpdateCoshhDocument}
            personsAtRiskOptions={personsAtRiskOptions}
            removeErrorStyling={removeErrorStyling}
            requestError={requestError}
            secondaryFieldDefaultValue={secondaryFieldDefaultValue}
            secondaryFieldLabel={secondaryFieldLabel}
            setSidePanelContext={setSidePanelContext}
            sidePanelContext={sidePanelContext}
            sidePanelIsOpen={
              currentCoshhDocument.title ? sidePanelIsOpen : false
            }
            submitDisabled={submitDisabled || isSidePanelSubmitDisabled}
            workerExposureTimeOptions={workerExposureTimeOptions}
          />
        </>
      )}
    </>
  );
}

MasterCoshhTable.propTypes = {
  projectId: PropTypes.number.isRequired,
  workerExposureTimeOptions: PropTypes.array.isRequired,
  exposureFrequencyOptions: PropTypes.array.isRequired,
  locationOptions: PropTypes.array.isRequired,
  methodOfUseOptions: PropTypes.array.isRequired,
  personsAtRiskOptions: PropTypes.array.isRequired,
  addInedibleToast: PropTypes.func.isRequired,
  onCoshDocumentChange: PropTypes.func.isRequired,
  secondaryFieldLabel: PropTypes.string.isRequired,
  secondaryFieldDefaultValue: PropTypes.string.isRequired,
};
