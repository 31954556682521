import React from 'react';
import qs from 'qs';

import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { useRamsAnalyticsDashboardContext } from 'components/contexts/RamsAnalyticsDashboardContext';
import { useBreadBoard } from 'components/contexts/Toaster';
import useFilters from 'components/ramsAnalytics/hooks/useFilters';
import useProjectsModal from 'components/ramsAnalytics/hooks/useProjectsModal';

import {
  filtersListFormatter,
  filtersFormatter,
} from 'components/ramsAnalytics/helpers/reportHeader';
import { formatPaginatedProjects } from 'components/ramsAnalytics/helpers/projectsModal';
import { getNextPageParam } from 'components/ramsAnalytics/helpers/reactQuery';

import Card from 'components/ramsAnalytics/components/Card';
import ReportHeader from 'components/ramsAnalytics/components/ReportHeader';
import StatusReportBody from 'components/ramsAnalytics/components/StatusReportBody';
import ProjectsModal from 'components/ramsAnalytics/components/ProjectsModal';
import NoDataMessage from 'components/ramsAnalytics/components/NoDataMessage';

const projectsModalAppliedFilters = {
  'Fully signed': { fully_signed_signatures: true },
  'Pending signatures': { pending_signatures: true },
  'Has rejections': { has_rejections_signatures: true },
  'Not requested': { not_requested_signatures: true },
};

const staticChartData = [
  {
    countKey: 'fullySignedSignaturesCount',
    cellClassName: 'tw-fill-green-400',
    dotClassName: 'tw-bg-green-400',
    name: 'Fully signed',
  },
  {
    countKey: 'pendingSignaturesCount',
    cellClassName: 'tw-fill-amber-300',
    dotClassName: 'tw-bg-amber-300',
    name: 'Pending signatures',
  },
  {
    countKey: 'hasRejectionsSignaturesCount',
    cellClassName: 'tw-fill-red-400',
    dotClassName: 'tw-bg-red-400',
    name: 'Has rejections',
  },
  {
    countKey: 'notRequestedSignaturesCount',
    cellClassName: 'tw-fill-mutedBlue-300',
    dotClassName: 'tw-bg-mutedBlue-300',
    name: 'Not requested',
  },
];

export default function SignatureStatusCard() {
  const ramsAnalyticsDashboardContext = useRamsAnalyticsDashboardContext();
  const breadBoard = useBreadBoard();
  const { filters, handleFilterChange } = useFilters({
    live: true,
    future: true,
  });

  const fetchSignatureStatusReport = () =>
    axios.post('/rams_analytics/signature_status_report', {
      signature_status_report: filters,
    });

  const { data: signatureStatusReport } = useQuery({
    queryKey: ['signatureStatusReport', filters],
    queryFn: fetchSignatureStatusReport,
    keepPreviousData: true,
    onError: breadBoard.addInedibleToast,
  });

  const attributes =
    signatureStatusReport ? signatureStatusReport.data.data.attributes : {};

  const {
    fullySignedSignaturesCount,
    futureFilterCount,
    hasRejectionsSignaturesCount,
    liveFilterCount,
    notRequestedSignaturesCount,
    pendingSignaturesCount,
  } = attributes ? attributes : {};

  const totalCount = Object.values({
    fullySignedSignaturesCount,
    pendingSignaturesCount,
    hasRejectionsSignaturesCount,
    notRequestedSignaturesCount,
  }).reduce((a, b) => a + b, 0);
  const filterCounts = { live: liveFilterCount, future: futureFilterCount };
  const filterDots = { live: 'green', future: 'amber' };

  const dynamicChartData = [
    fullySignedSignaturesCount,
    pendingSignaturesCount,
    hasRejectionsSignaturesCount,
    notRequestedSignaturesCount,
  ];

  const chartData = staticChartData.map((data, index) => {
    return { ...data, count: dynamicChartData[index] };
  });

  const { closeProjectsModal, openProjectsModal, projectsModal } =
    useProjectsModal();

  const fetchProjects = (page) => {
    const queryString = qs.stringify({
      filter: { ...filters, ...projectsModal.filters },
      page,
    });

    return axios.get(
      `/rams_analytics/signature_status_report/projects?${queryString}`,
    );
  };

  const {
    data: projects,
    fetchNextPage: handleFetchNextPageOfProjects,
    hasNextPage: hasNextPageOfProjects,
  } = useInfiniteQuery({
    queryKey: [
      'projects',
      'signatureStatusReport',
      { ...filters, ...projectsModal.filters },
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const projectsResponse = await fetchProjects(pageParam);
      return projectsResponse.data;
    },
    select: formatPaginatedProjects,
    getNextPageParam: getNextPageParam,
    enabled: projectsModal.isOpen,
    onError: breadBoard.addInedibleToast,
  });

  const handleSegmentClick = ({ entry }) => {
    openProjectsModal({
      title: `Signature status: ${entry.name}`,
      subTitle: ramsAnalyticsDashboardContext.pluralizeRamsCount({
        count: entry.count,
      }),
      appliedFilters: projectsModalAppliedFilters[entry.name],
    });

    ramsAnalyticsDashboardContext.sendAnalytics(
      'RAMS analytics: segment click',
      {
        chart: 'Signature status',
        segment: entry.name,
        filters: filters,
      },
    );
  };

  const handleProjectClick = ({ project }) => {
    ramsAnalyticsDashboardContext.sendAnalytics(
      'RAMS analytics: project click',
      {
        chart: 'Signature status',
        project: project.name,
        filters: filters,
      },
    );
  };

  return (
    <Card>
      <ReportHeader
        filters={filtersFormatter({ filters, filterCounts, filterDots })}
        minChecked={1}
        onFilterChange={handleFilterChange}
        subTitle={filtersListFormatter({
          filters,
          ramsPluralName: ramsAnalyticsDashboardContext.ramsPluralName,
        })}
        title='Signature status'
      />
      {signatureStatusReport &&
        (totalCount > 0 ?
          <StatusReportBody
            chartData={chartData}
            onSegmentClick={handleSegmentClick}
            totalCount={totalCount}
          />
        : <NoDataMessage message='Please check the applied filters' />)}
      <ProjectsModal
        closeModal={closeProjectsModal}
        hasNextPage={hasNextPageOfProjects}
        isOpen={projectsModal.isOpen}
        onFetchNextPage={handleFetchNextPageOfProjects}
        onProjectClick={handleProjectClick}
        projects={projects}
        subTitle={projectsModal.subTitle}
        title={projectsModal.title}
      />
    </Card>
  );
}
