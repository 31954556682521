import { useEffect, useState } from 'react';
import Polling from 'handshq-app/utilities/polling';

const initialJobStatus = null;

export default function useJobProcessor() {
  const [jobStatus, setJobStatus] = useState(initialJobStatus);
  const [url, setUrl] = useState(null);
  const [resourceId, setResourceId] = useState(null);

  useEffect(() => {
    if (url) {
      statusPoller.start();
      setJobStatus('working');
    }
    return () => statusPoller.end();
  }, [url]);

  let statusPoller = new Polling({
    interval: 1000,
    url: url,
    responseCheck: (response) => handleResponseCheck(response),
    successCallback: (response) => handlePollingSuccess(response),
    failureCallback: (_response) => handlePollingFailure(),
  });

  const handleResponseCheck = (response) => {
    if (response.status === 'completed') {
      return true;
    } else if (response.status === 'failed') {
      handlePollingFailure();
    }
  };

  const handlePollingSuccess = (response) => {
    setJobStatus('completed');
    setResourceId(response.duplicated_project_id);
  };

  const handlePollingFailure = () => {
    setJobStatus('failed');
    statusPoller.end();
  };

  return [jobStatus, setUrl, resourceId];
}
