function sendAnalytics(analyticTrackingId, { currentUser, ...analyticProps }) {
  const user = currentUser && (currentUser.data || currentUser);

  try {
    if (
      user &&
      user.attributes.email &&
      user.attributes.email.includes('@handshq.com')
    )
      return null;

    analytics.track(
      analyticTrackingId,
      user ?
        {
          userId: user && user.id,
          email: user && user.attributes.email,
          ...analyticProps,
        }
      : analyticProps,
    );
  } catch (error) {
    console.error(error);
  }
}

export { sendAnalytics };
