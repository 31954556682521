import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const reviewStatus = {
  pastReviewDueDate: {
    indicatorClassName: 'tw-bg-red-500',
    textClassName: 'tw-text-red-500',
  },
  beforeReminderDate: {
    indicatorClassName: 'tw-bg-green-400',
    textClassName: 'tw-text-green-400',
  },
  pastReminderDate: {
    indicatorClassName: 'tw-bg-amber-400',
    textClassName: 'tw-text-amber-600',
  },
};

export default function ReviewStatusIndicator({ dueDate, status }) {
  const displayableStatus = reviewStatus[status];

  return displayableStatus ?
      <div className='tw-flex tw-items-center'>
        <div
          className={classNames(
            'tw-inline-flex tw-h-2.5 tw-w-2.5 tw-rounded-full',
            displayableStatus.indicatorClassName,
          )}
        />
        <div
          className={classNames(
            'tw-ml-1 tw-text-s tw-font-medium tw-tracking-wide',
            displayableStatus.textClassName,
          )}
        >
          {dueDate}
        </div>
      </div>
    : null;
}

ReviewStatusIndicator.propTypes = {
  status: PropTypes.oneOf(Object.keys(reviewStatus)),
  dueDate: PropTypes.string,
};
