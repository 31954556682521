import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { sendAnalytics } from 'components/helpers/analytics';

import DocumentGeneratorService from 'components/helpers/DocumentGeneratorService';
import ActionModal from 'components/application/ActionModal';
import ActionModalContent from 'components/application/ActionModalContent';
import CheckboxField from 'components/application/CheckboxField';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

import LoadingIcon from '-!svg-react-loader?name=LoadingIcon!icons/loading.svg';
import ErrorIcon from '-!svg-react-loader?name=ErrorIcon!icons/ic-error-large.svg';

export default function ProjectCoshhDownloadModal(props) {
  const { currentCoshhDocument, currentUser, isOpen, projectId, setIsOpen } =
    props;

  let generator;
  const generateUrl = `/projects/${projectId}/coshh_document_downloads`;
  const defaultDocumentOptions = { sds: false };
  const [coshhDocumentIsGenerating, setCoshhDocumentIsGenerating] =
    useState(false);
  const [coshhDocumentHasErrored, setCoshhDocumentHasErrored] = useState(false);
  const [documentOptions, setDocumentOptions] = useState(
    defaultDocumentOptions,
  );

  const closeModal = () => {
    setIsOpen(false);
    if (!coshhDocumentIsGenerating || coshhDocumentHasErrored) {
      reset();
    }
  };

  const reset = () => {
    setCoshhDocumentIsGenerating(false);
    setCoshhDocumentHasErrored(false);
    setDocumentOptions(defaultDocumentOptions);
  };

  const handleDownloadClick = () => {
    generateCoshhDocumentPdf();
    setCoshhDocumentIsGenerating(true);
  };

  const successCallback = (docUrl) => {
    closeModal();
    sendAnalytics('Individual COSHH downloaded', {
      currentUser: currentUser,
      coshhDocumentTitle: currentCoshhDocument.title,
      coshhDocumentId: currentCoshhDocument.id,
    });
    window.location.href = docUrl;
  };

  const failureCallback = () => {
    setCoshhDocumentHasErrored(true);
  };

  const generateCoshhDocumentPdf = () => {
    const generateParams = {
      ...documentOptions,
      coshh_document_id: currentCoshhDocument.id,
    };

    generator = new DocumentGeneratorService({
      generateUrl,
      generateParams,
      successCallback,
      failureCallback,
    });
    generator.start();
  };

  const handleChange = (event) => {
    const option = event.target.value;
    setDocumentOptions({ ...documentOptions, [option]: event.target.checked });
  };

  const formContent = (
    <div className='action-modal__box action-modal__box--small tw-bg-white'>
      <section className='action-modal__form-body p-32 tw-border-grey-100'>
        <h2 className='modalbox-header__sub-title p-b-16 tw-font-inter tw-text-l tw-font-semibold tw-tracking-auto tw-text-grey-900'>
          Download COSHH for{' '}
          {currentCoshhDocument && currentCoshhDocument.title}
        </h2>
        <div>Do you want to include the manufacturer safety data sheet?</div>
        <CheckboxField
          checked={documentOptions.sds}
          className='action-modal__form-checkbox'
          customPadding='p-t-16'
          height='short'
          label='Include SDS'
          labelTextProps={{ className: 'tw-font-medium' }}
          name='sds'
          onChange={handleChange}
          value='sds'
        />
      </section>
      <section className='action-modal__form-footer'>
        <TextButton
          className='m-r-12'
          color='blue'
          onClick={closeModal}
          size='md'
        >
          Cancel
        </TextButton>
        <FilledButton color='mint' onClick={handleDownloadClick}>
          Download
        </FilledButton>
      </section>
    </div>
  );

  return (
    <>
      {isOpen && (
        <ActionModal closeModal={closeModal} closeable={true} isOpen={isOpen}>
          {coshhDocumentIsGenerating ?
            <ActionModalContent
              bodyIcon={
                coshhDocumentHasErrored ?
                  <ErrorIcon
                    className='[&_path]:tw-fill-red-600'
                    height={48}
                    width={48}
                  />
                : <LoadingIcon height={48} width={48} />
              }
              bodyText={
                coshhDocumentHasErrored ?
                  'Please try again or contact support for help'
                : 'This may take a few moments. When it is finished, your COSHH assessment will be downloaded.'
              }
              bodyTitle={
                coshhDocumentHasErrored ?
                  'COSHH assessment not generated'
                : 'Generating COSHH assessment'
              }
              closeButtonText={'Cancel'}
              closeModal={closeModal}
              closeable={true}
              iconStyling='tw-apply-loading-spinner--blue-light'
              modifiers={['small']}
              withHeader={false}
            />
          : formContent}
        </ActionModal>
      )}
    </>
  );
}

ProjectCoshhDownloadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  currentCoshhDocument: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
};
