import React from 'react';
import PropTypes from 'prop-types';

import CheckboxField from 'components/application/CheckboxField';
import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';

export default function CheckboxFields({
  checkboxes,
  inline = false,
  minChecked = 1,
}) {
  const isMinChecked = checkboxes.filter((f) => f.value).length === minChecked;

  return checkboxes.map((checkbox, index) => (
    <div
      className={`${inline ? 'tw-inline-flex' : 'tw-flex'} tw-items-center`}
      key={checkbox.name}
    >
      <CheckboxField
        checked={checkbox.value}
        className={index === 0 ? '' : 'tw-mt-4'}
        disabled={isMinChecked && checkbox.value}
        key={checkbox.name}
        label={checkbox.label}
        name={checkbox.name}
        onChange={checkbox.onChange}
        value={checkbox.name}
      />
      {checkbox.tooltip && (
        <div className='tw-ml-2'>
          <Tooltip placement='top' tooltip={checkbox.tooltip} trigger='hover'>
            <CircleQuestion />
          </Tooltip>
        </div>
      )}
    </div>
  ));
}

CheckboxFields.propTypes = {
  checkboxes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.bool,
      onChange: PropTypes.func.isRequired,
    }),
  ),
  inline: PropTypes.bool,
  minChecked: PropTypes.number,
};
