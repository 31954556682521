import React, { useState } from 'react';
import TextField from 'components/application/TextField';
import FilledButton from 'components/application/buttons/FilledButton';
import useToasts from 'components/hooks/useToasts';
import ToastRack from 'components/application/ToastRack';
import ResourceChangedToast from 'components/application/ResourceChangedToast';
import useRequestError from 'components/hooks/useRequestError';

export default function BillingForm(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [billingEmail, setBillingEmail] = useState(props.email);
  const [
    requestError,
    _submitDisabled,
    removeErrorStyling,
    resetRequestError,
    handleRequestError,
  ] = useRequestError();
  const [toasts, _setToasts, addToast, handleBurnToast, addInedibleToast] =
    useToasts();

  const handleSubmit = () => {
    setIsSubmitting(true);
    axios
      .patch('/billing_account', { billing_account: { email: billingEmail } })
      .then((_response) => {
        resetRequestError();
        addToast(
          <ResourceChangedToast
            onBurnToast={handleBurnToast}
            resource={`Billing email`}
            status={'updated'}
          />,
        );
      })
      .catch((e) => {
        handleRequestError(e);
        addInedibleToast({ errorTrigger: 'updating your billing email' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleEmailChange = (e) => {
    removeErrorStyling(e);
    setBillingEmail(e.target.value);
  };

  return (
    <>
      {/* pending move to parent toast handler */}
      <ToastRack toasts={toasts} />
      <div className='mw-300'>
        <TextField
          fieldError={requestError.validationErrors.billingEmail}
          label='Billing email'
          name='billing_email'
          onChange={handleEmailChange}
          value={billingEmail}
        />
        <FilledButton
          className='m-t-24'
          color='mint'
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          Save changes
        </FilledButton>
      </div>
    </>
  );
}
