import React from 'react';
import PropTypes from 'prop-types';

import RowBar from 'components/application/RowBar';
import Table from 'components/application/Table';
import UserManagementRow from 'components/users/UserManagementRow';
import NoResultsIcon from '-!svg-react-loader?name=NoResultsIcon!icons/noresults-xl.svg';
import DivisionFilter from 'components/application/DivisionFilter';
import SearchField from 'components/application/SearchField';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import PersonIcon from '-!svg-react-loader?name=PersonIcon!icons/person.svg';

UserManagementTable.propTypes = {
  users: PropTypes.array.isRequired,
  divisionSelectionOptions: PropTypes.object.isRequired,
  searchOptions: PropTypes.object.isRequired,
  onAddUserClick: PropTypes.func.isRequired,
  onResendInvitation: PropTypes.func.isRequired,
  onPasswordReset: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default function UserManagementTable(props) {
  const {
    divisionSelectionOptions,
    isAtUserCapacity,
    onAddUserClick,
    onEditClick,
    onPasswordReset,
    onRemoveUser,
    onResendInvitation,
    searchOptions,
    users,
  } = props;

  return (
    <>
      <TableHeader
        {...{
          divisionSelectionOptions,
          searchOptions,
          isAtUserCapacity,
          onAddUserClick,
        }}
      />
      <Table
        blankState={<NoUsersResult search={searchOptions.debouncedValue} />}
        headers={
          <tr>
            <th className='tw-w-13'></th>
            <th className='tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
              User
            </th>
            <th className='fw-190 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
              Status
            </th>
            {divisionSelectionOptions.isSelectable && (
              <th className='fw-280 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'>
                Division
              </th>
            )}
            <th className='fw-50 tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-900'></th>
          </tr>
        }
        rows={users.map((user) => (
          <UserManagementRow
            key={`UserManagementRow--${user.id}`}
            {...user}
            onEditClick={onEditClick}
            onPasswordReset={onPasswordReset}
            onRemoveUser={onRemoveUser}
            onResendInvitation={onResendInvitation}
            withDivisionInfo={divisionSelectionOptions.isSelectable}
          />
        ))}
      />
    </>
  );
}

function NoUsersResult({ search }) {
  return (
    <div
      className='section section--notice br-tl-0 br-tr-0 b-t-0 tw-border-grey-100 tw-bg-white'
      style={{ borderTop: 'none' }}
    >
      <NoResultsIcon
        className='[&_path]:tw-fill-grey-300'
        height={64}
        width={64}
      />
      <h2 className='section__header tw-text-l tw-font-semibold tw-text-grey-900'>
        No results found{search && <span> for '{search}'</span>}
      </h2>
    </div>
  );
}

export function TableHeader({
  divisionSelectionOptions,
  isAtUserCapacity,
  onAddUserClick,
  searchOptions,
}) {
  return (
    <RowBar
      actions={
        <OutlinedButton
          color='grey'
          disabled={isAtUserCapacity}
          onClick={!isAtUserCapacity && onAddUserClick}
          size='sm'
        >
          <PersonIcon className='m-l--8' height={24} width={24} />
          <span>Add user</span>
        </OutlinedButton>
      }
      content={
        <div className='flex flex--vertically-centered'>
          {divisionSelectionOptions.isSelectable && (
            <div className='fw-200 m-r-12'>
              <DivisionFilter
                divisions={divisionSelectionOptions.divisions}
                onChange={divisionSelectionOptions.onDivisionSelection}
                selectedDivisionId={divisionSelectionOptions.selectedDivisionId}
              />
            </div>
          )}
          <SearchField
            name={'user_search'}
            onChange={searchOptions.onChange}
            onReset={searchOptions.onReset}
            placeholder={'Search users…'}
            value={searchOptions.value}
          />
        </div>
      }
      modifiers={['border-top-curved', 'border-bottom-none', 'large']}
    />
  );
}
