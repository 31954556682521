import React, { useRef, useEffect, Fragment } from 'react';
import useForm from 'components/hooks/useForm';
import { personDisplayName } from 'components/helpers/users';
import CollectionSelect from 'components/application/CollectionSelect';
import ReviewStatus from 'components/reviews/ReviewStatus';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import PersonDetailsIcon from '-!svg-react-loader?name=PersonDetailsIcon!icons/person-details.svg';
import ProjectPersonnelRowOptions from 'components/projectPersonnel/ProjectPersonnelRowOptions';
import ProjectPersonnelTrainingStatusDetails from 'components/projectPersonnel/ProjectPersonnelTrainingStatusDetails';
import ProfilePhotoDisplay from 'components/application/ProfilePhotoDisplay';
import { useBreadBoard } from 'components/contexts/Toaster';
import { useCurrentActor } from 'components/contexts/CurrentActor';

export default function ProjectPersonnelRow(props) {
  const projectPersonnelId = props.formattedProjectPersonnel.id;
  const personnel = props.formattedProjectPersonnel.personnel;
  const companyRole = props.formattedProjectPersonnel.companyRole;
  const review = props.formattedProjectPersonnel.review;
  const reviewedVersion = props.formattedProjectPersonnel.reviewedVersion;
  const assignableCompanyRoles =
    props.formattedProjectPersonnel.assignableCompanyRoles;
  const handleShowPersonnel =
    props.formattedProjectPersonnel.handleShowPersonnel;
  const handleShowAcceptedReview =
    props.formattedProjectPersonnel.handleShowAcceptedReview;
  const handleShowRejectedReview =
    props.formattedProjectPersonnel.handleShowRejectedReview;

  const breadBoard = useBreadBoard();
  const currentActor = useCurrentActor();

  const isInitialMount = useRef(true);
  const displayName = personDisplayName(personnel.attributes);
  const [projectPersonnel, _setProjectPersonnel, , handleOptionChange] =
    useForm({
      companyRoleId: companyRole ? companyRole.id : null,
    });

  const updateProjectPersonnel = () => {
    axios
      .patch(`/project_personnel/${projectPersonnelId}`, {
        project_personnel: {
          company_role_id: projectPersonnel.companyRoleId,
        },
      })
      .then((response) => props.replaceProjectPersonnel(response.data.data))
      .catch((_error) => {
        breadBoard.addInedibleToast({
          fullMessage: (
            <Fragment>
              <span className='tw-font-semibold'>{displayName}</span> was not
              updated.
              <br />
              Please try again.
            </Fragment>
          ),
        });
      });
  };

  const companyRoleValue = () => {
    const assignedCompanyRole = assignableCompanyRoles.find(
      (assignableCompanyRole) =>
        projectPersonnel.companyRoleId === assignableCompanyRole.id,
    );

    return assignedCompanyRole ?
        {
          label: assignedCompanyRole.attributes.position,
          value: assignedCompanyRole.id,
        }
      : null;
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      updateProjectPersonnel();
    }
  }, [projectPersonnel.companyRoleId]);

  return (
    <tr className='options-toggle-container fh-49 tw-group/row hover:tw-bg-grey-025'>
      <td className='tw-w-[5%] tw-border-grey-100 tw-py-2 tw-pl-4 tw-pr-3'>
        <ProfilePhotoDisplay
          componentSize='sm'
          photoUrl={
            props.formattedProjectPersonnel.profilePhoto?.links?.croppedUrl
          }
          profileInitials={`${personnel.attributes.firstName[0]}${personnel.attributes.lastName[0]}`}
        />
      </td>
      <td className='tw-border-grey-100'>
        <div className='fh-32 flex flex--vertically-centered'>
          <span className='tw-font-medium tw-text-grey-900'>{displayName}</span>
          <OutlinedButton
            className='m-l-8'
            color='grey'
            onClick={() => {
              handleShowPersonnel(personnel.id);
            }}
            size='xs'
          >
            <PersonDetailsIcon className='m-l--4 m-r--4' />
          </OutlinedButton>
        </div>
      </td>
      <td className='tw-border-grey-100'>
        {assignableCompanyRoles.length > 1 ?
          <div className='mw-260'>
            <CollectionSelect
              isDisabled={false}
              isRequired={true}
              isSearchable={false}
              modifiers={['inline-form']}
              name='companyRoleId'
              onChange={handleOptionChange}
              options={assignableCompanyRoles.map((assignableCompanyRole) => {
                return {
                  label: assignableCompanyRole.attributes.position,
                  value: assignableCompanyRole.id,
                };
              })}
              placeholder='Select role...'
              value={companyRoleValue()}
            />
          </div>
        : <div className='fh-32 flex flex--vertically-centered'>
            <span className='m-l-1 p-l-10'>
              {companyRole ? companyRole.attributes.position : null}
            </span>
          </div>
        }
      </td>
      {currentActor.isAllowedFeature('training_register') && (
        <td className='tw-border-grey-100'>
          <ProjectPersonnelTrainingStatusDetails
            status={props.formattedProjectPersonnel.trainingStatus}
          />
        </td>
      )}
      <td className='tw-border-grey-100'>
        {currentActor.isAllowedFeature('digital_signatures') && (
          <div className='fh-32 flex flex--vertically-centered flex--justify-content__center'>
            <ReviewStatus
              latestVersion={props.latestVersion}
              onShowAcceptedReview={handleShowAcceptedReview}
              onShowRejectedReview={handleShowRejectedReview}
              personnel={personnel}
              review={review}
              reviewedVersion={reviewedVersion}
            />
          </div>
        )}
      </td>
      <td className='p-r-24 tw-border-grey-100'>
        <ProjectPersonnelRowOptions
          destroyProjectPersonnel={props.destroyProjectPersonnel}
          fetchProjectPersonnel={props.fetchProjectPersonnel}
          personnel={personnel}
          projectPersonnelId={projectPersonnelId}
          review={review}
        />
      </td>
    </tr>
  );
}
