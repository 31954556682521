import React from 'react';

import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from 'react-query-settings/queryClient';

import AppWrapper from 'components/apps/AppWrapper';
import MobileTrainingProfilePage from 'components/pages/MobileTrainingProfilePage';

export default function MobileTrainingProfileApp({
  contextProps,
  wrappedProps,
}) {
  return (
    <AppWrapper {...contextProps}>
      <QueryClientProvider client={queryClient}>
        <MobileTrainingProfilePage {...wrappedProps} />
      </QueryClientProvider>
    </AppWrapper>
  );
}
