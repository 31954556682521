import React from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';
import DestroyModal from 'components/application/DestroyModal';
import { useCurrentActor } from 'components/contexts/CurrentActor';

export default function BookingDestroyModal({
  autoEnrolmentExclusion,
  booking,
  course,
  isOpen,
  onClose,
  onDestroy,
  personnel,
}) {
  const currentActor = useCurrentActor();
  const courseHasELearningCourse = !!(
    course && course.relationships.eLearningCourse.data
  );
  const isAutoEnrollingCourse =
    courseHasELearningCourse &&
    currentActor.isAllowedFeature([
      'e_learning_auto_enrol',
      'e_learning',
      'training_register',
    ]) &&
    !!(course && course.relationships?.autoEnrolmentConfiguration?.data);

  const additionalDisplayText =
    courseHasELearningCourse ?
      'You will be refunded the credit as the course has not been started.'
    : 'Email notifications will be sent to the personnel and their line manager if they have email addresses.';

  return (
    <DestroyModal
      additionalContent={
        <div className='tw-rounded-l m-0 m-t-20 tw-border-0'>
          <p>{additionalDisplayText}</p>
          {isAutoEnrollingCourse && !autoEnrolmentExclusion && (
            <p>{`Auto-enrol will be turned off for this course to avoid ${personnel.firstName} ${personnel.lastName} being re-enrolled.`}</p>
          )}
        </div>
      }
      confirmationText='Remove booking'
      displayText={`Are you sure you want to remove the ${courseHasELearningCourse ? 'enrolment' : 'booking'} for ${course && course.attributes.name}?`}
      isOpen={isOpen}
      onClose={onClose}
      onDestroy={() => onDestroy({ bookingId: booking?.id, isNotified: true })}
    />
  );
}

BookingDestroyModal.propTypes = {
  personnel: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  booking: resourceShape('booking'),
  course: resourceShape('course'),
  autoEnrolmentExclusion: resourceShape('autoEnrolmentExclusion'),
  onDestroy: PropTypes.func.isRequired,
};
