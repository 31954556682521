import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { sendAnalytics } from 'components/helpers/analytics';
import SidePanel from 'components/application/SidePanel';
import ErrorMessage from 'components/application/ErrorMessage';
import TrainingFormFields from 'components/training/TrainingFormFields';
import FormFooter from 'components/sidepanels/FormFooter';
import AttachmentsField from 'components/attachments/AttachmentsField';

export default function TrainingSidePanel(props) {
  const [scrolledAmount, setScrolledAmount] = useState(0);
  const currentActor = useCurrentActor();

  const courseAttributes =
    (props.currentTraining.course && props.currentTraining.course.attributes) ||
    {};
  const currentTrainingAttachments = useMemo(() => {
    return props.includedAttachments.filter((a) =>
      props.currentTraining.attachmentIds.includes(Number(a.id)),
    );
  }, [props.includedAttachments, props.currentTraining.attachmentIds]);

  const onBackdropClick = () =>
    !['new', 'edit'].includes(props.sidePanelContext) && props.closeSidePanel();

  return (
    <SidePanel
      bodyContent={
        <div className='popup__body-form'>
          <ErrorMessage
            isFallback={props.requestError.isFallback}
            validationErrors={props.requestError.validationErrors}
            wrapperClassName='form-container'
          />
          <div className='form-container'>
            <TrainingFormFields
              currentTraining={props.currentTraining}
              domainCourse={props.domainCourse}
              includedAttachments={props.includedAttachments}
              includedCoverImages={props.includedCoverImages}
              onError={props.onError}
              onInputChange={props.onInputChange}
              onTrainingCalendarClose={props.onTrainingCalendarClose}
              onTrainingDateChange={props.onTrainingDateChange}
              personnelId={props.personnelId}
              removeErrorStyling={props.removeErrorStyling}
              requestError={props.requestError}
              sidePanelIsOpen={props.sidePanelIsOpen}
              submitDisabled={props.submitDisabled}
            />
            <AttachmentsField
              isVisible={props.sidePanelIsOpen}
              label='Upload evidence'
              maxUploads={25}
              name='attachmentIds'
              onChange={props.onInputChange}
              onError={props.onError}
              onUploadSuccess={() =>
                sendAnalytics('Training evidence uploaded', {
                  currentUser: currentActor.user,
                  personnelId: props.personnelId,
                })
              }
              seedAttachments={{
                data: currentTrainingAttachments,
                included: props.includedCoverImages,
              }}
              tooltip='E.g. certificates or cards'
              value={props.currentTraining.attachmentIds}
            />
          </div>
        </div>
      }
      closeCallback={props.closeSidePanel}
      color={props.sidePanelContext === 'show' ? 'secondary' : 'tertiary'}
      contentContext={props.sidePanelContext}
      displayClose={false}
      footerContent={
        <FormFooter
          onCancel={props.onCancelTraining}
          onSubmit={
            props.sidePanelContext === 'new' ?
              props.createTraining
            : props.updateTraining
          }
          submitButtonDisabled={props.submitDisabled}
          text={
            props.sidePanelContext === 'new' ?
              'Record training'
            : 'Save changes'
          }
        />
      }
      headerContent={
        <div className='popup__title popup__title--tertiary-split'>
          <h1 className='m-b-8 tw-text-s tw-font-medium tw-tracking-tight'>{`${props.sidePanelContext === 'new' ? 'Record new' : 'Edit'} training`}</h1>
          <h2 className='truncated-text-container--wrapped truncated-text-container--five-lines tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-900'>
            {courseAttributes.name}
          </h2>
        </div>
      }
      isOpen={props.sidePanelIsOpen}
      onBackdropClick={onBackdropClick}
      scrolledAmount={scrolledAmount}
      setScrolledAmount={setScrolledAmount}
      submitDisabled={props.submitDisabled}
    />
  );
}

TrainingSidePanel.propTypes = {
  domainCourse: resourceShape('course'),
  currentTraining: PropTypes.object.isRequired,
  sidePanelContext: PropTypes.string.isRequired,
  sidePanelIsOpen: PropTypes.bool.isRequired,
  closeSidePanel: PropTypes.func.isRequired,
  requestError: PropTypes.object.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  includedAttachments: PropTypes.array.isRequired,
  includedCoverImages: PropTypes.array.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  personnelId: PropTypes.string.isRequired,
  onTrainingDateChange: PropTypes.func.isRequired,
  onTrainingCalendarClose: PropTypes.func.isRequired,
  createTraining: PropTypes.func.isRequired,
  updateTraining: PropTypes.func.isRequired,
  onCancelTraining: PropTypes.func.isRequired,
};

TrainingSidePanel.defaultProps = {
  sidePanelContext: 'new',
};
