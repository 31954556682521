import moment from 'moment';

function findCurrentFieldValue(
  fieldAttribute,
  currentPersonnel,
  availableFieldOptions,
) {
  const currentValue = currentPersonnel.fieldValues[fieldAttribute.id];

  if (!currentValue) return;

  if (currentValue.fieldOptionId) {
    return availableFieldOptions.collection.find(
      (option) => option.id === currentValue.fieldOptionId,
    ).attributes.value;
  } else {
    switch (currentValue.valueType) {
      case 'text':
        return currentValue.value;
      case 'date':
        return moment.parseZone(currentValue.value).format('DD MMM YYYY');
    }
  }
}

function findLineManager({ currentPersonnel, lineManagers }) {
  return lineManagers.find(
    (personnel) => personnel.id === currentPersonnel.lineManagerId,
  );
}

function formatUserCompanyRolesAttributes(
  currentPersonnel,
  originalRoleIds = [],
  originalUserCompanyRoles = [],
  newCompanyRoles = [],
) {
  const additionalCompanyRoleIds = [];
  const currentCompanyRoleIds = new Set();
  const originalCompanyRoleIds = new Set(originalRoleIds);
  const newCompanyRoleIds = new Set(newCompanyRoles.map((role) => role.id));

  currentPersonnel.companyRoleIds.forEach((roleId) => {
    if (!originalCompanyRoleIds.has(roleId) && !newCompanyRoleIds.has(roleId)) {
      additionalCompanyRoleIds.push(roleId);
    }
    currentCompanyRoleIds.add(roleId);
  });

  const updatedCompanyRolesAttributes = originalUserCompanyRoles.map(
    (userCompanyRole) => {
      const relatedCompanyRoleId =
        userCompanyRole.relationships.companyRole.data.id;
      // A destroyed role is marked as primary:false to make it easier for Rails to handle the update transaction!
      return !currentCompanyRoleIds.has(relatedCompanyRoleId) ?
          { id: userCompanyRole.id, _destroy: true, primary: false }
        : {
            id: userCompanyRole.id,
            primary:
              currentPersonnel.primaryCompanyRoleId === relatedCompanyRoleId,
          };
    },
  );
  const newCompanyRolesAttributes = newCompanyRoles.map((role) => ({
    primary: currentPersonnel.primaryCompanyRoleId === role.id,
    company_role_attributes: { position: role.position },
  }));
  const additionalCompanyRolesAttributes = additionalCompanyRoleIds.map(
    (roleId) => ({
      company_role_id: roleId,
      primary: currentPersonnel.primaryCompanyRoleId === roleId,
    }),
  );

  return [
    ...updatedCompanyRolesAttributes,
    ...newCompanyRolesAttributes,
    ...additionalCompanyRolesAttributes,
  ];
}

function personnelParams(options = {}) {
  const currentPersonnel = options.currentPersonnel;

  const params = {
    first_name: currentPersonnel.firstName,
    last_name: currentPersonnel.lastName,
    external_id: currentPersonnel.externalId || null,
    email: currentPersonnel.email,
    line_manager_id: currentPersonnel.lineManagerId,
    company_id: currentPersonnel.company.companyId,
    subcontractor_id: '',
    user_company_roles_attributes: [],
  };

  if (currentPersonnel.profilePhoto) {
    params.profile_photo_attributes = {
      id: options.profilePhotos?.domain?.attributes?.hashid,
      crop_x: currentPersonnel.profilePhoto.cropX,
      crop_y: currentPersonnel.profilePhoto.cropY,
      crop_dimension: currentPersonnel.profilePhoto.cropDimension,
      rotation: currentPersonnel.profilePhoto.rotation,
    };
  } else if (options.profilePhotos?.existingFromRelationship) {
    // there's no current profile photo, but there's an existing one from the relationship
    // so we need to destroy it
    params.profile_photo_attributes = {
      id: options.profilePhotos.existingFromRelationship.attributes.hashid,
      _destroy: true,
    };
  }

  params['user_company_roles_attributes'] = formatUserCompanyRolesAttributes(
    currentPersonnel,
    options.originalCompanyRoleIds,
    options.originalUserCompanyRoles,
    options.newCompanyRoles,
  );

  const fieldValuesAttributes = Object.entries(
    options.currentPersonnel.fieldValues,
  ).map(([fieldAttributeId, fieldValueData]) => {
    return {
      id: fieldValueData.id,
      text_value:
        fieldValueData.valueType === 'text' ? fieldValueData.value : null,
      date_value:
        fieldValueData.valueType === 'date' ?
          moment.parseZone(fieldValueData.value).format('DD/MM/YYYY')
        : null,
      field_attribute_id: fieldAttributeId,
      field_option_id: fieldValueData.fieldOptionId,
      _destroy: !fieldValueData.value && !fieldValueData.fieldOptionId,
    };
  });

  params['field_values_attributes'] = fieldValuesAttributes;

  if (options.isSubcontractor) {
    if (
      options.currentPersonnel.company.subcontractorId &&
      !options.currentSubcontractor.subcontractorName
    ) {
      params['subcontractor_id'] =
        options.currentPersonnel.company.subcontractorId;
    } else {
      params['subcontractor_attributes'] = {
        name: options.currentSubcontractor.subcontractorName,
      };
    }
    params['line_manager_id'] = '';
  } else {
    params['profile_access_enabled'] = currentPersonnel.profileAccessEnabled;
  }

  return {
    personnel: params,
  };
}

function sortRolesByPrimaryAndPosition(userCompanyRoles, companyRoles) {
  let primaryCompanyRoleId;
  const primaryUserCompanyRole = userCompanyRoles.find(
    (role) => role.attributes.primary,
  );

  if (primaryUserCompanyRole) {
    primaryCompanyRoleId =
      primaryUserCompanyRole.relationships.companyRole.data.id;
  }

  return [...companyRoles].sort((a, b) => {
    if (a.id === primaryCompanyRoleId) {
      return -1;
    } else if (b.id === primaryCompanyRoleId) {
      return 1;
    } else {
      return a.attributes.position > b.attributes.position ? 1 : -1;
    }
  });
}

function sortRoleIdsByPosition(assignableRoles, roleIds) {
  const mappedAssignableRoles = new Map(
    assignableRoles.map((role) => [role.id, role]),
  );
  return [...roleIds].sort((a, b) => {
    const roleA = mappedAssignableRoles.get(a);
    const roleB = mappedAssignableRoles.get(b);
    if (roleA && roleB) {
      return roleA.attributes.position.localeCompare(roleB.attributes.position);
    } else {
      return 0;
    }
  });
}

function teamNamesForCurrentPersonnel(currentPersonnel, persistedTeams) {
  const teamIds = currentPersonnel.teamsIds || [];
  return teamIds
    .map((teamId) =>
      persistedTeams[teamId] && persistedTeams[teamId].attributes ?
        persistedTeams[teamId].attributes.name
      : '',
    )
    .filter((name) => name);
}

function haveProfilePhotoAttributesChanged(
  currentProfilePhoto,
  domainProfilePhoto,
) {
  return ['cropX', 'cropY', 'cropDimension', 'rotation'].some(
    (attr) => currentProfilePhoto[attr] != domainProfilePhoto[attr],
  );
}

export {
  findLineManager,
  personnelParams,
  findCurrentFieldValue,
  sortRolesByPrimaryAndPosition,
  sortRoleIdsByPosition,
  formatUserCompanyRolesAttributes,
  teamNamesForCurrentPersonnel,
  haveProfilePhotoAttributesChanged,
};
