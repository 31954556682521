import React from 'react';
import moment from 'moment';
import ProfilePhotoDisplay from 'components/application/ProfilePhotoDisplay';
import PropTypes from 'prop-types';

import TableCell from 'components/application/TableCell';
import ClockIcon from '-!svg-react-loader?name=ClockIcon!icons/clock.svg';
import HhqLogo from '-!svg-react-loader?name=HandsHQ!icons/hhq.svg';

export default function TrainingEventRow(props) {
  const date = moment
    .parseZone(props.personnelEvent.createdAt)
    .format('DD MMM YY [at] HH:mm');

  let photoBadgeContent;
  if (props.userInitials === 'API') {
    photoBadgeContent = (
      <div className='tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-mint-050'>
        <span className='tw-text-sm tw-flex tw-items-center tw-justify-center tw-align-middle tw-font-semibold tw-uppercase tw-text-mint-700'>
          API
        </span>
      </div>
    );
  } else if (!props.userInitials) {
    photoBadgeContent = <HhqLogo />;
  } else {
    photoBadgeContent = (
      <ProfilePhotoDisplay
        componentSize='sm'
        photoUrl={props.userPhotoPath}
        profileInitials={props.userInitials}
      />
    );
  }

  return (
    <tr>
      <TableCell>
        <ClockIcon
          className='layout--ib m-r-12 [&_path]:tw-fill-grey-500'
          height={20}
          width={20}
        />
        <span className='layout--ib tw-text-m tw-text-grey-900' height={20}>
          {date}
        </span>
      </TableCell>
      <TableCell>
        <div className='tw-mr-2'>{photoBadgeContent}</div>
        <span className='tw-text-m'>{props.personnelEvent.description}</span>
      </TableCell>
    </tr>
  );
}

TrainingEventRow.propTypes = {
  personnelEvent: PropTypes.object.isRequired,
  userInitials: PropTypes.string,
  userPhotoPath: PropTypes.string,
};
