import React from 'react';
import PropTypes from 'prop-types';

export default function ProjectDuplicationRow(props) {
  const { actions, actionsPill, name, projectTableWidth } = props;

  return (
    <tr className='fh-49'>
      <td className='live tw-border-grey-100 tw-bg-grey-025'>
        <div className='fh-32 flex flex--vertically-centered flex--justify-content__center'>
          <div className='circle circle--small grey'></div>
        </div>
      </td>
      <td
        className='project tw-border-grey-100 tw-bg-grey-025'
        colSpan={projectTableWidth - 2}
      >
        <div className='fh-32 flex flex--vertically-centered'>
          <div className='truncated-text-container truncated-text-container--auto'>
            {name}
          </div>
          {actionsPill}
        </div>
      </td>
      <td className='actions tw-border-grey-100 tw-bg-grey-025'>
        <div className='fh-32 flex flex--vertically-centered flex--justify-content__flex-end'>
          {actions}
        </div>
      </td>
    </tr>
  );
}

ProjectDuplicationRow.defaultProps = {
  ramsSingularName: 'project',
  actions: null,
};

ProjectDuplicationRow.propTypes = {
  name: PropTypes.string.isRequired,
  projectTableWidth: PropTypes.number.isRequired,
};
