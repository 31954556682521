import React from 'react';
import PropTypes from 'prop-types';
import ActionModal from 'components/application/ActionModal';
import ActionModalContent from 'components/application/ActionModalContent';
import LoadingIcon from '-!svg-react-loader?name=LoadingIcon!icons/loading.svg';

export default function DuplicationProcessingModal(props) {
  const { closeModal, modifiers, originalProjectName, ramsSingularName } =
    props;

  return (
    <React.Fragment>
      <ActionModal closeModal={closeModal} closeable={false} isOpen={true}>
        <ActionModalContent
          bodyIcon={<LoadingIcon height={48} width={48} />}
          bodyText={`This may take a few minutes. When it is finished, you will be redirected to the new ${ramsSingularName}.`}
          bodyTitle={`Creating ${ramsSingularName}`}
          closeModal={closeModal}
          closeable={false}
          headerText={`Duplicate ${originalProjectName}`}
          iconStyling='tw-apply-loading-spinner--blue-light'
          modifiers={modifiers}
        />
      </ActionModal>
    </React.Fragment>
  );
}

DuplicationProcessingModal.defaultProps = {
  ramsSingularName: 'project',
};

DuplicationProcessingModal.propTypes = {
  originalProjectName: PropTypes.string.isRequired,
  ramsSingularName: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  modifiers: PropTypes.array,
};
