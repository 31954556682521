import React from 'react';
import PropTypes from 'prop-types';
import CurrentActor, {
  currentActorContextPropsStructure,
} from 'components/contexts/CurrentActor';
import Toaster from 'components/contexts/Toaster';
import ErrorBoundary from 'components/application/ErrorBoundary';

export default function AppWrapper(props) {
  return (
    <ErrorBoundary>
      <CurrentActor {...props.actors}>
        <Toaster>{props.children}</Toaster>
      </CurrentActor>
    </ErrorBoundary>
  );
}
export const propsStructure = {
  actors: PropTypes.exact(currentActorContextPropsStructure),
  routerProps: PropTypes.object,
  children: PropTypes.node,
};

AppWrapper.propTypes = propsStructure;
