import React from 'react';
import ModalBox from 'components/application/ModalBox';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';

import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import moment from 'moment';
import NotificationsIcon from '-!svg-react-loader?name=notificationsIcon!icons/notifications.svg';

export default function BookingSourceReminderModal({
  bookingSource,
  course,
  domainPersonnelMeta,
  isOpen,
  onClose,
  onSubmit,
  personnel,
}) {
  return (
    <ModalBox
      customFooter={
        <div className='modalbox__footer-actions modalbox__footer-actions--right'>
          <TextButton
            className='tw-mr-3'
            color='blue'
            onClick={onClose}
            size='md'
          >
            Cancel
          </TextButton>
          <FilledButton
            color='mint'
            onClick={() => onSubmit({ bookingSource })}
          >
            {bookingSource.type === 'bookingGroup' ?
              `Send reminder to ${domainPersonnelMeta?.notifiableSetCount} personnel`
            : 'Send reminder'}
          </FilledButton>
        </div>
      }
      isOpen={isOpen}
      mode='flexible'
      onClose={onClose}
    >
      <div className='p-32'>
        {bookingSource.type === 'booking' && (
          <>
            <h2 className='m-0 p-0 tw-mb-6 tw-text-l tw-font-semibold tw-text-grey-900'>
              Are you sure you want to send a reminder to{' '}
              {personnel.attributes.firstName} to take {course.attributes.name}?
            </h2>
            <p className='m-0 p-0 tw-text-m tw-font-normal tw-text-grey-700'>
              An email notification will be sent to personnel with email
              addresses.
            </p>
          </>
        )}

        {bookingSource.type === 'bookingGroup' && (
          <>
            <h2 className='m-0 p-0 tw-mb-6 tw-text-l tw-font-semibold tw-text-grey-900'>
              Are you sure you want to send a reminder?
            </h2>
            <p className='m-0 p-0 tw-text-m tw-font-normal tw-text-grey-700'>
              Course:{' '}
              <span className='m-0 p-0 tw-text-m tw-font-medium tw-text-grey-700'>
                {course.attributes.name}
              </span>
            </p>
            <p className='m-0 p-0 tw-text-m tw-font-normal tw-text-grey-700'>
              Training Date:{' '}
              <span className='m-0 p-0 tw-text-m tw-font-medium tw-text-grey-700'>
                {moment
                  .parseZone(bookingSource.attributes.date)
                  .format('D MMM YYYY')}
              </span>
            </p>
            <br />
            <p className='m-0 p-0 tw-text-m tw-font-normal tw-text-grey-700'>
              A notification will be sent to personnel with email addresses
            </p>
            {bookingSource.attributes.notifiedAt && (
              <div className='tw-mt-6 tw-bg-cyan-025'>
                <div className='tw-flex tw-items-center tw-p-3'>
                  <div className='tw-relative tw-top-0.5'>
                    <NotificationsIcon
                      className='[&_path]:tw-fill-cyan-800'
                      height={16}
                      width={12}
                    />
                  </div>
                  <div className='tw-ml-4'>
                    <p className='tw-m-0 tw-p-0 tw-font-inter tw-font-normal tw-text-cyan-800'>
                      Last reminded{' '}
                      <span className='tw-font-medium'>
                        {moment
                          .parseZone(bookingSource.attributes.notifiedAt)
                          .fromNow()}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </ModalBox>
  );
}

BookingSourceReminderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bookingSource: resourceShape(['booking', 'bookingGroup']).isRequired,
  course: resourceShape('course').isRequired,
  personnel: resourceShape('personnel'),
  domainPersonnelMeta: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};
